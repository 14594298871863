<div class="modal-body">
    <div class="center text-sm grey">ADD A FRACTION STRIP</div>
    <ng-container *ngFor="let fraction of fractions">
        <table (click)="selectFractionStrip(fraction.fraction, fraction.colour)"
               class="ManipFractionStripColour{{fraction.fraction}} ManipFractionStripTable cursor">
            <tbody>
            <tr>
                <td *ngFor="let fractionText of getFractionsSet(fraction.fraction)"
                    class="ManipFractionStripStripSmall">
                    {{fractionText}}
                </td>
            </tr>
            </tbody>
        </table>
    </ng-container>
<!--    <div class="right">-->
<!--        <button (click)="cancel()" class="btn btn-sm btn-default">Cancel</button>-->
<!--    </div>-->
</div>
