<div class="scene">
  <div #batteryDiv>
    <ng-content></ng-content>
  </div>
  <svg #arenaMain class="arenaMain" viewBox="0 0 1280 720">
    <defs #assets>
      <image
        id="rollbot1"
        width="213"
        href="https://staticcontent.knowledgehook.com/learning-worlds/rollbot/rollbot1.png"
      ></image>
      <image
        id="rollbot2"
        width="325.6"
        href="https://staticcontent.knowledgehook.com/learning-worlds/rollbot/rollbot2.png"
      ></image>
      <image
        id="rollbot34"
        width="180.7"
        href="https://staticcontent.knowledgehook.com/learning-worlds/rollbot/rollbot34.png"
      ></image>
      <image
      id="arrow"
      width="40"
      href="images/rb-arrow-down.svg"
    ></image>
      <image
        id="rollbot12"
        width="131.7"
        href="https://staticcontent.knowledgehook.com/learning-worlds/rollbot/rollbot12.png"
      ></image>
      <image
        id="rollbot112"
        width="225.3"
        href="images/rb-rollbot112.png"
      ></image>
      <image
        id="controlsImage"
        width="500"
        height="100"
        href="images/rb-control-bar.svg"
      ></image>
      <image
        id="spaceship"
        width="300"
        href="https://staticcontent.knowledgehook.com/learning-worlds/rollbot/spaceship.svg"
      ></image>
      <image
        id="beam"
        width="60"
        href="https://staticcontent.knowledgehook.com/learning-worlds/rollbot/beam.svg"
      ></image>
      <image
        id="addgear"
        width="70"
        height="70"
        href="https://staticcontent.knowledgehook.com/learning-worlds/rollbot/images/rb-add-gear.png"
      ></image>
      <image
        id="subgear"
        width="70"
        height="70"
        href="images/rb-sub-gear.png"
      ></image>
      <image
        id="addpart"
        width="70"
        height="70"
        href="images/rb-add-part.png"
      ></image>
      <image
        id="reverse"
        width="70"
        height="70"
        href="images/rb-reverse.png"
      ></image>
      <image
        id="subpart"
        width="70"
        height="70"
        href="images/rb-sub-part.png"
      ></image>
      <image
        id="addpiece"
        width="70"
        height="70"
        href="images/rb-add-piece.png"
      ></image>
      <image
        id="subpiece"
        width="70"
        height="70"
        href="images/rb-sub-piece.png"
      ></image>
      <image
        id="battery"
        height="80"
        width="80"
        href="https://staticcontent.knowledgehook.com/learning-worlds/rollbot/gear2.png"
      ></image>
      <image
        id="ground"
        width="1280"
        href="https://staticcontent.knowledgehook.com/learning-worlds/rollbot/block-wall.png"
      ></image>
    </defs>
    <g #elbackground>
      <g
        id="sky_1_"
        transform="matrix(-0.613936,0,0,0.625815,1289.27,-30.9782)"
      >
        <rect
          x="0"
          y="0"
          width="2100"
          height="1200"
          style="fill: url(#_Linear1);"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1375.08,-18.744)">
        <path
          d="M655.942,176.467L646.221,174.489C644.393,174.117 642.965,172.689 642.593,170.861L640.615,161.14C640.167,158.941 637.024,158.941 636.577,161.14L634.599,170.861C634.227,172.689 632.799,174.117 630.971,174.489L621.25,176.467C619.051,176.915 619.051,180.057 621.25,180.505L630.971,182.483C632.799,182.855 634.227,184.283 634.599,186.111L636.577,195.832C637.025,198.031 640.168,198.031 640.615,195.832L642.593,186.111C642.965,184.283 644.393,182.855 646.221,182.483L655.942,180.505C658.141,180.057 658.141,176.914 655.942,176.467Z"
          style="fill: rgb(219, 188, 238); fill-rule: nonzero;"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1375.08,-18.744)">
        <path
          d="M1240.88,95.83L1233.01,94.228C1231.53,93.927 1230.37,92.77 1230.07,91.289L1228.47,83.415C1228.1,81.633 1225.56,81.633 1225.19,83.415L1223.59,91.289C1223.29,92.77 1222.13,93.927 1220.65,94.228L1212.78,95.83C1211,96.193 1211,98.738 1212.78,99.101L1220.65,100.703C1222.13,101.004 1223.29,102.161 1223.59,103.642L1225.19,111.516C1225.56,113.298 1228.1,113.298 1228.47,111.516L1230.07,103.642C1230.37,102.161 1231.53,101.004 1233.01,100.703L1240.88,99.101C1242.66,98.738 1242.66,96.192 1240.88,95.83Z"
          style="fill: rgb(140, 54, 163); fill-rule: nonzero;"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1375.08,-18.744)">
        <path
          d="M883.937,102.238L875.188,100.458C873.543,100.123 872.257,98.838 871.922,97.193L870.142,88.444C869.739,86.465 866.91,86.465 866.508,88.444L864.728,97.193C864.393,98.838 863.108,100.124 861.462,100.459L852.713,102.239C850.734,102.642 850.734,105.47 852.713,105.873L861.462,107.653C863.107,107.988 864.393,109.273 864.727,110.919L866.507,119.668C866.91,121.647 869.738,121.647 870.141,119.668L871.921,110.919C872.256,109.274 873.541,107.988 875.187,107.654L883.936,105.874C885.916,105.47 885.916,102.641 883.937,102.238Z"
          style="fill: rgb(140, 54, 163); fill-rule: nonzero;"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1375.08,-18.744)">
        <path
          d="M1433,295.529L1425.13,293.927C1423.64,293.626 1422.49,292.469 1422.19,290.988L1420.59,283.114C1420.22,281.332 1417.68,281.333 1417.31,283.114L1415.71,290.988C1415.41,292.469 1414.25,293.626 1412.77,293.927L1404.9,295.529C1403.12,295.891 1403.12,298.437 1404.9,298.8L1412.77,300.402C1414.25,300.703 1415.41,301.86 1415.71,303.341L1417.31,311.215C1417.68,312.997 1420.22,312.997 1420.59,311.215L1422.19,303.341C1422.49,301.86 1423.64,300.703 1425.13,300.402L1433,298.8C1434.78,298.438 1434.78,295.892 1433,295.529Z"
          style="fill: rgb(140, 54, 163); fill-rule: nonzero;"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1375.08,-18.744)">
        <path
          d="M1671.67,78.13L1666.51,77.079C1665.54,76.881 1664.78,76.122 1664.58,75.151L1663.53,69.985C1663.29,68.816 1661.62,68.816 1661.38,69.985L1660.33,75.151C1660.13,76.122 1659.37,76.882 1658.4,77.079L1653.24,78.13C1652.07,78.368 1652.07,80.038 1653.24,80.276L1658.4,81.327C1659.37,81.525 1660.13,82.284 1660.33,83.255L1661.38,88.421C1661.62,89.59 1663.29,89.59 1663.53,88.421L1664.58,83.255C1664.78,82.284 1665.54,81.524 1666.51,81.327L1671.67,80.276C1672.84,80.038 1672.84,78.367 1671.67,78.13Z"
          style="fill: rgb(140, 54, 163); fill-rule: nonzero;"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1375.08,-18.744)">
        <path
          d="M1132.02,284.629L1126.85,283.578C1125.88,283.38 1125.12,282.621 1124.92,281.65L1123.87,276.484C1123.63,275.315 1121.96,275.315 1121.72,276.484L1120.67,281.65C1120.48,282.621 1119.72,283.381 1118.75,283.578L1113.58,284.629C1112.41,284.867 1112.41,286.537 1113.58,286.775L1118.75,287.826C1119.72,288.024 1120.48,288.783 1120.67,289.754L1121.72,294.92C1121.96,296.089 1123.63,296.089 1123.87,294.92L1124.92,289.754C1125.12,288.783 1125.88,288.023 1126.85,287.826L1132.02,286.775C1133.18,286.537 1133.18,284.866 1132.02,284.629Z"
          style="fill: rgb(219, 188, 238); fill-rule: nonzero;"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1375.08,-18.744)">
        <path
          d="M1714.92,247.96L1709.76,246.909C1708.78,246.711 1708.02,245.952 1707.83,244.981L1706.78,239.815C1706.54,238.646 1704.87,238.646 1704.63,239.815L1703.58,244.981C1703.38,245.952 1702.62,246.712 1701.65,246.909L1696.49,247.96C1695.32,248.198 1695.32,249.868 1696.49,250.106L1701.65,251.157C1702.62,251.355 1703.38,252.114 1703.58,253.085L1704.63,258.251C1704.87,259.42 1706.54,259.42 1706.78,258.251L1707.83,253.085C1708.03,252.114 1708.78,251.354 1709.76,251.157L1714.92,250.106C1716.09,249.868 1716.09,248.198 1714.92,247.96Z"
          style="fill: rgb(219, 188, 238); fill-rule: nonzero;"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1375.08,-18.744)">
        <circle
          cx="544.444"
          cy="97.976"
          r="4.802"
          style="fill: rgb(126, 50, 166);"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1375.08,-18.744)">
        <circle
          cx="1406.13"
          cy="150.689"
          r="4.802"
          style="fill: rgb(122, 38, 166);"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1375.08,-18.744)">
        <circle
          cx="1991.11"
          cy="287.641"
          r="5.81"
          style="fill: rgb(126, 50, 166);"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1375.08,-18.744)">
        <circle
          cx="1984.95"
          cy="67.779"
          r="6.391"
          style="fill: rgb(219, 188, 238);"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1375.08,-18.744)">
        <path
          d="M1881.96,258.785C1871.35,269.394 1858.35,276.153 1844.68,279.056C1820.73,284.15 1794.76,277.393 1776.15,258.785C1761.2,243.835 1753.89,224.135 1754.25,204.541C1754.57,185.841 1761.88,167.24 1776.15,152.971C1782.73,146.39 1790.23,141.29 1798.24,137.675C1811.89,131.514 1827,129.655 1841.53,132.097C1856.34,134.587 1870.54,141.545 1881.96,152.971C1897.76,168.766 1905.01,189.869 1903.73,210.542C1902.87,224.504 1898.1,238.272 1889.44,250.078C1887.2,253.121 1884.71,256.033 1881.96,258.785Z"
          style="fill: rgb(153, 53, 166); fill-rule: nonzero;"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1375.08,-18.744)">
        <path
          d="M1844.68,279.056C1820.73,284.15 1794.76,277.393 1776.15,258.785C1761.2,243.835 1753.89,224.135 1754.25,204.541C1754.57,185.841 1761.88,167.24 1776.15,152.971C1782.73,146.39 1790.23,141.29 1798.24,137.675C1811.89,131.514 1827,129.655 1841.53,132.097C1840.46,135.025 1839.4,137.996 1839,141.117C1837.21,154.987 1851.48,159.99 1860.72,166.663C1869.12,172.735 1875.15,182.567 1875.08,192.937C1875.01,203.307 1867.99,213.692 1857.93,216.189C1847.87,218.689 1836.03,211.647 1834.73,201.359C1833.84,194.294 1837.51,187.178 1836.3,180.162C1835.06,173.008 1828.17,167.272 1820.91,167.336C1813.65,167.4 1806.87,173.255 1805.76,180.427C1803.12,197.347 1827.56,208.277 1827.38,225.396C1827.31,233.125 1821.28,240.379 1813.69,241.863C1806.11,243.35 1797.79,238.91 1794.8,231.784C1791.72,224.442 1794.1,216.091 1793.99,208.132C1793.89,200.171 1788.83,190.647 1780.92,191.499C1775.63,192.067 1771.87,197.416 1771.36,202.706C1770.85,207.999 1772.82,213.195 1774.95,218.068C1778.72,226.694 1783.24,235.266 1790.29,241.508C1797.34,247.751 1807.38,251.307 1816.38,248.53C1827.31,245.154 1836.34,233.107 1847.39,236.063C1853.38,237.664 1857.47,243.691 1858.18,249.852C1858.89,256.011 1856.74,262.187 1853.66,267.572C1851.22,271.844 1848.18,275.683 1844.68,279.056Z"
          style="fill: rgb(114, 49, 158); fill-rule: nonzero;"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1375.08,-18.744)">
        <path
          d="M1889.44,250.078C1882.79,250.903 1875.85,249.36 1870.71,245.103C1863.84,239.4 1861.28,228.605 1865.99,221.018C1869.34,215.61 1876.75,212.504 1882.43,215.371C1885.58,216.962 1888.02,220.155 1891.53,220.605C1895.63,221.129 1899.1,217.667 1901.43,214.258C1902.28,213.026 1903.04,211.786 1903.73,210.541C1902.87,224.504 1898.1,238.273 1889.44,250.078Z"
          style="fill: rgb(114, 49, 158); fill-rule: nonzero;"
        />
      </g>
      <g
        transform="matrix(-0.434114,-0.442514,-0.434114,0.442514,1124.82,837.342)"
      >
        <ellipse
          cx="1855.08"
          cy="191.932"
          rx="11.183"
          ry="10.979"
          style="fill: rgb(153, 53, 166);"
        />
      </g>
      <g
        transform="matrix(-0.568996,-0.235056,-0.230594,0.580006,1344.73,416.595)"
      >
        <ellipse
          cx="1809.23"
          cy="221.022"
          rx="6.303"
          ry="11.793"
          style="fill: rgb(153, 53, 166);"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1375.08,-18.744)">
        <path
          d="M1763.31,198.332C1761.39,201.609 1757.9,204.302 1754.25,204.542C1754.58,185.842 1761.88,167.241 1776.15,152.972C1782.73,146.391 1790.23,141.291 1798.24,137.676C1797.56,141.538 1797.84,145.545 1799.31,149.214C1801.09,153.692 1804.4,158.186 1803.13,162.835C1801.71,168.003 1795.46,170.048 1790.11,170.279C1784.76,170.508 1778.95,169.884 1774.47,172.817C1770.51,175.416 1768.61,180.2 1767.48,184.803C1766.35,189.404 1765.71,194.243 1763.31,198.332Z"
          style="fill: rgb(153, 53, 166); fill-rule: nonzero;"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1375.08,-18.744)">
        <path
          d="M1881.96,258.785C1871.35,269.394 1858.35,276.153 1844.68,279.056C1820.73,284.15 1794.76,277.393 1776.15,258.785C1772.76,255.394 1769.76,251.758 1767.15,247.934C1784.31,259.622 1805.25,263.38 1824.84,259.215C1838.5,256.313 1851.51,249.553 1862.12,238.944C1864.87,236.192 1867.36,233.279 1869.6,230.237C1878.26,218.432 1883.03,204.663 1883.89,190.701C1884.9,174.463 1880.64,157.96 1871.11,143.975C1874.93,146.579 1878.57,149.579 1881.96,152.97C1897.76,168.765 1905.01,189.868 1903.73,210.541C1902.86,224.503 1898.1,238.271 1889.44,250.077C1887.2,253.121 1884.71,256.033 1881.96,258.785Z"
          style="fill: rgb(7, 11, 40); fill-opacity: 0.2; fill-rule: nonzero;"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1331.51,-15.6134)">
        <path
          d="M350.123,309.321C337.679,321.765 324.216,332.575 310.009,341.75C290.676,354.229 269.956,363.686 248.521,370.112C216.901,379.601 183.719,382.495 151.151,378.801C98.529,372.838 47.486,349.674 7.131,309.319C-21.629,280.559 -41.657,246.37 -52.95,210.05C-63.454,176.275 -66.404,140.652 -61.799,105.841C-56.429,65.238 -40.782,25.738 -14.861,-8.456C-8.208,-17.232 -0.878,-25.662 7.13,-33.67C9.425,-35.965 11.758,-38.205 14.12,-40.392C38.828,-63.227 67.129,-79.985 97.117,-90.652C121.79,-99.443 147.613,-104.106 173.505,-104.65C237.268,-105.988 301.459,-82.33 350.119,-33.67C395.835,12.046 419.485,71.475 421.067,131.381C421.655,153.489 419.231,175.663 413.801,197.233C403.69,237.446 383.132,275.567 352.129,307.292C351.467,307.971 350.798,308.646 350.123,309.321Z"
          style="fill: rgb(114, 49, 158); fill-rule: nonzero;"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1331.51,-15.6134)">
        <path
          d="M352.13,307.291C222.733,208.123 188.731,13.561 14.121,-40.394C38.829,-63.229 67.13,-79.987 97.118,-90.654C156.052,51.935 315.533,93.977 413.802,197.232C403.691,237.445 383.133,275.566 352.13,307.291Z"
          style="fill: rgb(153, 53, 166); fill-rule: nonzero;"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1331.51,-15.6134)">
        <path
          d="M310.009,341.749C290.676,354.228 269.956,363.685 248.521,370.111C175.659,251.445 25.925,209.644 -61.799,105.84C-56.429,65.237 -40.782,25.737 -14.861,-8.457C126.417,75.288 159.636,267.089 310.009,341.749Z"
          style="fill: rgb(153, 53, 166); fill-rule: nonzero;"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1331.51,-15.6134)">
        <path
          d="M421.069,131.38C327.073,64.176 216.959,13.095 173.506,-104.652C237.269,-105.99 301.46,-82.332 350.12,-33.672C395.837,12.045 419.486,71.474 421.069,131.38Z"
          style="fill: rgb(153, 53, 166); fill-rule: nonzero;"
        />
      </g>
      <g transform="matrix(-0.613936,0,0,0.625815,1331.51,-15.6134)">
        <path
          d="M350.123,309.321C337.679,321.765 324.216,332.575 310.009,341.75C290.676,354.229 269.956,363.686 248.521,370.112C216.901,379.601 183.719,382.495 151.151,378.801C98.529,372.838 47.486,349.674 7.131,309.319C-4.103,298.085 -14.005,286.019 -22.575,273.324C10.328,295.534 47.474,308.792 85.444,313.095C118.012,316.789 151.194,313.895 182.814,304.406C204.25,297.98 224.97,288.523 244.302,276.044C258.509,266.869 271.972,256.06 284.416,243.616C285.091,242.941 285.76,242.266 286.423,241.586C317.426,209.861 337.983,171.74 348.095,131.527C353.526,109.957 355.949,87.783 355.361,65.675C354.168,20.494 340.424,-24.417 314.124,-63.376C326.821,-54.809 338.882,-44.907 350.119,-33.67C395.835,12.046 419.485,71.475 421.067,131.381C421.655,153.489 419.231,175.663 413.801,197.233C403.69,237.446 383.132,275.567 352.129,307.292C351.467,307.971 350.798,308.646 350.123,309.321Z"
          style="fill: rgb(7, 11, 40); fill-opacity: 0.2; fill-rule: nonzero;"
        />
      </g>
      <defs>
        <linearGradient
          id="_Linear1"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(7.15015e-14,1167.71,-1167.71,7.15015e-14,1050,-1.06411e-10)"
        >
          <stop
            offset="0"
            style="stop-color: rgb(76, 1, 83); stop-opacity: 1;"
          />
          <stop
            offset="1"
            style="stop-color: rgb(176, 66, 182); stop-opacity: 1;"
          />
        </linearGradient>
      </defs>
    </g>
  </svg>
</div>
