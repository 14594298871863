import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FabricExtendedCanvas } from '../../question/fabric/angular-fabric';
import { FabricDraggable } from './fabric-draggable';
import { DraggableGrid } from './models/draggable-grid';
import { DraggableGridLine } from './models/draggable-grid-line';

@UntilDestroy()
export class FabricDraggableGrid {
  public wiggleAnimation = false;
  public height: number = 0;
  public grid!: DraggableGrid;
  private originalState: FabricDraggable[] = [];
  private items: FabricDraggable[] = [];
  private gridSnapshot: DraggableGrid | null = null;
  private readonly padding = 15;

  constructor(
    private allItems: FabricDraggable[],
    private canvas: FabricExtendedCanvas,
    private top: number,
    private reuseObjects: boolean = false,
    private returnUnregistered: boolean = false
  ) {
    this.originalState = allItems.slice();

    //CALC max height
    this.items = allItems;
    this.grid = this.calcGrid();

    this.height = this.grid.height;

    this.originalState.forEach((originalState: FabricDraggable, index: number) => {
      originalState._id = index;
      this.applyEvents(originalState);
    });
  }

  public draggableMove(item: FabricDraggable): void {
    this.remove(item);

    if (!this.gridSnapshot) {
      this.snapshot();
    }

    let newLines = this.gridSnapshot?.lines;
    const itemHeight = item.height ?? 0;
    if (item.top + itemHeight > this.top + this.padding) {
      const items = this.shadowInsert(item);

      newLines = this.calcGrid(items).lines;
    }
    this.setItemPositions(newLines, true);
  }

  public reset(): void {
    this.items = this.originalState.slice();
    this.grid = this.calcGrid();
    this.height = this.grid.height;
  }

  public replaceByClone(item: FabricDraggable): FabricDraggable {
    let clone!: FabricDraggable;

    if (item.top + (item.height ?? 0) > this.top + this.padding) {
      const itemIndex = this.items.indexOf(item);

      item.clone((cloned: FabricDraggable) => {
        clone = cloned;
        clone.left = item.left;
        clone.top = item.top;
      });

      this.items.splice(itemIndex, 1, clone);
      item.canvas?.add(clone);
      this.grid = this.calcGrid();
      this.setItemPositions();
      this.applyEvents(clone);
      clone.canvas?.renderAll();
    }

    return clone;
  }

  public mergeWithClone(item: FabricDraggable, returnUnregistered?: boolean): void {
    const itemHeight = item.height ?? 0;
    const target = item;
    const canvasWidth = this.canvas.width ?? 0;

    if (!item.stickToRegion && (item.top + itemHeight > this.top + this.padding || item.left > canvasWidth / this.canvas.getZoom() || returnUnregistered)) {
      let itemIndex = -1;
      this.items.forEach((item, index) => {
        if (item._id === target._id){
          itemIndex = index;
        }
      });

      if (itemIndex >= 0) {
        const clone = this.items[itemIndex];

        item.off('mouseup');
        if (clone.left !== item.left || clone.top !== item.top) {
          item.animateTo(clone.left, clone.top, () => {
            //@ts-ignore
            this.canvas.remove(item);
            clone.active = true;
          });
        }
        else {
          //@ts-ignore
          this.canvas.remove(item);
          clone.active = true;
        }
      }
    }
  }

  public draggableModified(item: FabricDraggable, returnUnregistered?: boolean): void {
    const itemHeight = item.height ?? 0;
    const itemWidth = item.width ?? 0;
    const canvasWidth = this.canvas.width ?? 0;

    if (!item.stickToRegion &&
      (returnUnregistered ||
        (item.top + itemHeight > this.top + this.padding
          || item.left <= -itemWidth / 2
          || item.top <= -itemHeight / 2
          || (item.left - canvasWidth / this.canvas.getZoom()) >= -itemWidth / 2
        ))
    ) {
      const items = this.shadowInsert(item);
      items.forEach((copy, index) => {
        if (!copy.canvas) { // place object to shadow
          items[index] = item;
          this.items.splice(index, 0, item);
        }
      });
    }
    this.gridSnapshot = null;
    this.grid = this.calcGrid();
    this.setItemPositions(this.grid.lines, true);
  }

  public calcGrid(itemsList?: FabricDraggable[]): DraggableGrid {
    const items = itemsList ?? this.items;

    let width = -this.padding;
    let height = 0;
    const lines: DraggableGridLine[] = [];
    let lineItems: FabricDraggable[] = [];
    let areaHeight = this.padding;
    const canvasWidth = Math.round((this.canvas.width ?? 0) / this.canvas.getZoom());

    items.forEach((item: FabricDraggable) => {
      const itemWidth = item.width ?? 0;
      const itemHeight = item.height ?? 0;

      width += this.padding + (itemWidth);
      if (width < canvasWidth) {
        height = Math.max(height, itemHeight);
        lineItems.push(item);
      }
      else {
        lines.push({
          height: height,
          items: lineItems,
          width: width - this.padding - itemWidth
        });
        lineItems = [item];
        width = itemWidth;
        areaHeight += height + this.padding;
      }
    });

    if (lineItems.length > 0) {
      lines.push({
        height: height,
        items: lineItems,
        width: width
      });

      areaHeight += height + this.padding;
    }

    return {
      lines: lines,
      height: areaHeight
    };
  }

  public remove(item: FabricDraggable): void {
    const itemIndex = this.items.indexOf(item);
    if (itemIndex >= 0) {
      this.items.splice(itemIndex, 1);
      this.grid = this.calcGrid();
    }
  }

  public append(item: FabricDraggable): void {
    this.items.push(item);
    this.grid = this.calcGrid();
    this.setItemPositions(this.grid.lines, true);
  }

  public setItemPositions(lines?: DraggableGridLine[], animate?: boolean): void {
    const grid = lines ?? this.grid.lines;
    let yOffset = this.top + this.padding;

    grid.forEach((line: DraggableGridLine) => {
      const canvasWidth = this.canvas.width ?? 0;
      const margins = (canvasWidth / this.canvas.getZoom() - line.width) / 2;
      let xOffset = margins;

      line.items.forEach((item: FabricDraggable) => {
        const left = xOffset;
        const top = yOffset + (line.height - (item.height ?? 0)) / 2;
        this.setItemPosition(item, animate, left, top);
        xOffset += (item.width ?? 0) + this.padding;
      });

      yOffset += this.padding + line.height;
    });

    this.height = yOffset;
    this.canvas.renderAll();
  }

  public wiggleDraggables(allDraggables: FabricDraggable[]): void {
    const draggables = allDraggables;

    if (!this.wiggleAnimation) {

      this.wiggleAnimation = true;
      const animatePromises = draggables.map((draggable) => draggable.wiggle());

      forkJoin(animatePromises)
      .pipe(
        tap(() => (this.wiggleAnimation = false)),
        untilDestroyed(this)
      )
      .subscribe();
    }
  }

  public snapshot(): DraggableGrid {
    this.gridSnapshot = this.grid;
    return this.gridSnapshot;
  }

  public shadowInsert(item: FabricDraggable): FabricDraggable[] {
    // find place to insert
    // find the line to insert
    const itemHeight = item.height ?? 0;
    const itemCenter = item.top + itemHeight / 2;
    let distance = Infinity;
    let yOffset = this.top + this.padding;
    let lineToInsert = 0;

    this.gridSnapshot?.lines.forEach((line, index) => {
      const lineCenter = yOffset + line.height / 2;
      if (Math.abs(itemCenter - lineCenter) < distance) {
        lineToInsert = index;
        distance = Math.abs(itemCenter - lineCenter);
      }
      yOffset += line.height + this.padding;
    });

    // find a space between items to insert
    const insertLine: DraggableGridLine | undefined = this.gridSnapshot?.lines[lineToInsert];
    let insertIndex = 0;

    if (insertLine) {
      for (let i = 0; i < insertLine.items.length; i++) {
        const lineItem = insertLine.items[i];
        const lineItemCenter = lineItem.left + (lineItem.width ?? 0) / 2;
        if (item.left > lineItemCenter) {
          insertIndex = i + 1;
        } else {
          break;
        }
      }
    }

    // correct when insert after last
    const itemWidth = item.width ?? 0;
    const canvasWidth = this.canvas.width ?? 0;
    if (insertLine && insertIndex === insertLine.items.length && insertLine.width + this.padding + itemWidth > canvasWidth / this.canvas.getZoom()) {
      insertIndex--;
    }

    let insertPos = 0;
    for (let i = 0; i < lineToInsert; i++) {
      insertPos += this.gridSnapshot?.lines[i].items.length ?? 0;
    }
    insertPos += insertIndex;

    // create grid copy
    const itemsCopy = this.items.slice();

    //add transparent clone there
    item.clone((cloned: FabricDraggable) => {
      cloned.opacity = 0;
      itemsCopy.splice(insertPos, 0, cloned as FabricDraggable);
    });

    return itemsCopy;
  }

	public setDraggablePosition(allItems: FabricDraggable[], height: number) {
		allItems.forEach((draggable) => {
			if (draggable.initialPosition && draggable.height) {
				draggable.left = draggable.positionLeft;
				draggable.top = height - draggable.positionTop - draggable.height / 2;
			}
      this.groupFix(draggable);
		})
	}

  private setItemPosition(item: FabricDraggable, animate = false, left: number, top: number): void {
    if (animate) {
      item.animateTo(left, top, () => this.groupFix(item));
    }
    else {
      item.left = left;
      item.top = top;
      this.groupFix(item);
    }
  }

  private groupFix(item: FabricDraggable): void {
    if (item.addWithUpdate) {
      item.addWithUpdate();
    }
  }

  private applyEvents(obj: FabricDraggable): void {
    if (this.reuseObjects) {
      obj.on('mousedown', () => {
        obj.cloned = true;
        this.replaceByClone(obj);
        obj.off('mousedown');
      });
      obj.on('mouseup', () => {
        if (obj.cloned && !obj.stickToRegion) {
          this.mergeWithClone(obj);
        }
      });
      obj.on('modified', () => {
        if (obj.cloned && !obj.stickToRegion) {
          this.mergeWithClone(obj, this.returnUnregistered);
        }
      });
    }
    else {
      obj.on('moving', () => {
        this.draggableMove(obj);
      });
      obj.on('modified', (...some) => {
        this.draggableModified(obj, this.returnUnregistered);
      });
    }
  }
}
