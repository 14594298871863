import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minusToDash'
})
export class MinusToDashPipe implements PipeTransform {

  transform(value: string | number = ''): string {
    return String(value).replace(/-/g, '\u2013');
  }

}
