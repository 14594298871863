import { IAnalyticsService } from '../../services/IAnalyticsService';
export class PuzzleAnalytics {
  private userId: string;
  private analyticsService: IAnalyticsService;
  private questionId: string;
  private puzzleStartTime: number;

  public constructor(analyticsService: IAnalyticsService, userId: string, questionId: string) {
    this.userId = userId;
    this.questionId = questionId;
    this.analyticsService = analyticsService;
    this.puzzleStartTime = this.puzzleStartTime = Date.now();
  }

  public refreshPuzzleStartTime(): void {
    this.puzzleStartTime = Date.now();
  }

  public sendPuzzleCustom(eventType: string, jsonData: object): void {
    const data = {...jsonData, questionId: this.questionId};

    this.analyticsService.trackEvent({
      eventType: eventType,
      jsonData: data,
      studentId: this.userId
    });
  }

  public sendCompleteAnalytics(analyticsName: string, finishedKey: string, ttcKey: string, attemptsKey: string, numAttempts: 0): void {
    this.analyticsService.trackEvent({
      eventType: analyticsName,
      jsonData: {
        questionId: this.questionId,
        [finishedKey]: true,
        [ttcKey]: (Date.now() - this.puzzleStartTime) / 1000,
        [attemptsKey]: numAttempts
      },
      studentId: this.userId
    });
  }

  /**
   * This analytics event captures the time from the start of the puzzle until the play button is clicked
   */
  public sendAnalyticsTimeToPlay(): void {
    this.analyticsService.trackEvent({
      eventType: 'Puzzle-TimeToPlay',
      jsonData: {
        questionId: this.questionId,
        timeSeconds: (Date.now() - this.puzzleStartTime) / 1000
      },
      studentId: this.userId
    });
  }

  /**
   * This analytics event captures when submit is clicked after two incorrect attempts
   */
  public sendAnalyticsTwoAttempts(attempts: number): void {
    this.analyticsService.trackEvent({
      eventType: 'Puzzle-IncorrectAttempts',
      jsonData: {
        attemptCount: attempts,
        questionId: this.questionId
      },
      studentId: this.userId
    });
  }

  /**
   * This analytics event captures when the user has clicked the inactive submit button three times
   */
  public sendAnalyticsInactiveClicks(): void {
    this.analyticsService.trackEvent({
      eventType: 'Puzzle-InactiveSubmits',
      jsonData: {
        questionId: this.questionId
      },
      studentId: this.userId
    });
  }
}