import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TEMPLATE_PREFIX } from '../../../../environments/locale-config';
import { WindowsService } from '../../../../services/windows.service';

const ACTIVE_COLOR = '#0fade1';
const DEFAULT_COLOR = '#cccccc';

@Component({
  selector: 'kh-string-input',
  templateUrl: TEMPLATE_PREFIX + 'string-input.component.html',
  styleUrls: ['./string-input.component.less']
})
export class StringInputComponent implements OnInit {
  @ViewChild('stringInput') public stringInput!: ElementRef<HTMLInputElement>;

  @Input() public fontFamily = '';
  @Input() public maxLength = 40;
  @Input() public textSize = '80%';
  @Input() public frame!: string;
  @Input() public type = 'text';
  @Input() public name = '';
  @Input() public id = '';
  @Input() public borderRadius = 0;
  @Input() public borderStrokeSize = 0;
  @Input() public backgroundColor = '';
  @Input() public textAlignment = 'center';
  @Input() public fontStyle = 'normal';

  public fontSize = 16;
  public fontWeight = '';
  public borderColor = DEFAULT_COLOR;
  public value = '';

  public box = {
    left: 0,
    top: 0,
    width: 0,
    height: 0
  }

  public constructor(
    private readonly windowService: WindowsService,
    private readonly cdr: ChangeDetectorRef) { 
  }

  public onChangeValue(val: string): void {
    this.value=val.replace(/^"(.+)"$/,'$1');
    

    if (val.length > this.maxLength) {
      this.value = '';
      this.cdr.detectChanges();

      const substring = val.substring(0, this.maxLength);

      if (this.type === 'text') {
        this.value = substring;
      } else {
        this.value = parseFloat(substring).toString();
      }
    }    
    this.windowService.setKeyValueAnswer(this.id, this.value);
    this.removeStroke();
  }

  public ngOnInit(): void {
    this.removeStroke();
    this.fontWeight = this.fontStyle === 'bold' ? 'bold' : 'normal';
    const [left, top, width, height] = this.frame.split(',').map(Number);
    this.box = {
      left, top, width, height
    }
    this.fontSize = this.textSize.endsWith('%') ? Number(this.textSize.replace(/%$/, '')) / 100 * height : Number(this.textSize);
  }

  public setStroke(): void {
    this.borderColor = ACTIVE_COLOR;
  }

  public removeStroke(): void {
    this.borderColor = this.value ? ACTIVE_COLOR : DEFAULT_COLOR;
  }
}
