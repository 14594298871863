import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { TEMPLATE_PREFIX } from '../../../../environments/locale-config';
import { LOCALIZED_TOASTER_SERVICE_TOKEN, ILocalizedToasterService } from '../../../../services/ILocalizedToasterService';
import { NumWithSignPipe } from '../../../pipes';
import { AddingblocksRowsData } from './addingblocks-rows-data';
import { Inject } from '@angular/core';
import { QUESTION_EVENTS_SERVICE_TOKEN, IQuestionEventsService } from '../../../../services/IQuestionEvents';
import { AddingblocksBlockType } from './addingblocks-block-type.enum';

@Component({
  selector: 'kh-manip-addingblocks',
  templateUrl: TEMPLATE_PREFIX + 'manip-addingblocks.component.html',
  styleUrls: ['./manip-addingblocks.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NumWithSignPipe]
})
export class ManipAddingblocksComponent implements OnInit {
  public blockType!: AddingblocksBlockType;
  public blockValue!: number;
  public firstTerm!: number;
  public rowTiles!: number;
  public topValue = 0;
  public bottomValue = 0;
  public numZeroBlocks = 0;
  public total = 0;

  public topRow: AddingblocksRowsData[] = [];
  public bottomRow: AddingblocksRowsData[] = [];

  @Input() public term1?: string;
  @Output() public valueChange = new EventEmitter<any>();

  private val = '';
  private internallySet = false;

  constructor(
    @Inject(LOCALIZED_TOASTER_SERVICE_TOKEN) private readonly toaster: ILocalizedToasterService,
    private readonly numWithSign: NumWithSignPipe,
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) private readonly questionEventsService: IQuestionEventsService,
    private readonly changeDetectorRef: ChangeDetectorRef,) {
  }

  public get isOppositeSigns(): boolean {
    return this.blockType === AddingblocksBlockType.Opposite;
  }

  public get isSameSigns(): boolean {
    return this.blockType === AddingblocksBlockType.Same;
  }

  public get value(): string {
    return this.val;
  }

  @Input()
  public set value(value: string) {
    this.val = value;
  }

  public ngOnInit(): void {
    this.questionEventsService.hideInputOnlyForManip.next(true);

    if (!this.term1) {
      this.blockType = AddingblocksBlockType.Opposite;
    }
    else {
      this.firstTerm = parseInt(this.term1);

      this.blockType = AddingblocksBlockType.Same;
      this.blockValue = this.firstTerm > 0 ? 1 : -1;
    }

    if(!this.val) {
      this.Reset();
    }

    this.valueUpdated(this.val);
    
    this.changeDetectorRef.markForCheck();
  }

  public noZeroValue(value: number): boolean {
    return value !== 0;
  }

  public AddBlock(row: AddingblocksRowsData[], addValue: number): void {
    if (row.length >= 8) {
      this.toaster.pop('info', 'Cannot add more blocks');
      return;
    }

    row.push({value: addValue});
    this.updateResults();
  }

  public removeBlock(row: AddingblocksRowsData[], indexValue: number): void {
    row.splice(indexValue, 1);
    this.updateResults();
  }

  public Reset() {
    this.topRow = [];
    this.bottomRow = [];

    if (this.isSameSigns) {
      for (let i = 0; i < Math.abs(this.firstTerm); i++) {
        this.topRow.push({value: this.blockValue});
      }
    }

    this.updateResults();
  }

  private updateResults(): void {
    this.rowTiles = Math.max(this.topRow.length, this.bottomRow.length);
    switch (this.blockType) {
      case AddingblocksBlockType.Opposite:
        this.topValue = this.topRow.length;
        this.bottomValue = -this.bottomRow.length;

        this.numZeroBlocks = Math.min(this.topValue, -this.bottomValue);

        break;
      case AddingblocksBlockType.Same:
        this.topValue = this.topRow.length * this.blockValue;
        this.bottomValue = this.bottomRow.length * this.blockValue;

        this.numZeroBlocks = 0;
        break;
    }

    this.total = this.topValue + this.bottomValue;
    this.internallySet = true;
    this.val = '(' + this.numWithSign.transform(this.topValue) + ') + ' +
      '(' + this.numWithSign.transform(this.bottomValue) + ') = ' + this.numWithSign.transform(this.total);

    this.valueChange.emit(this.value);
  }

  private valueUpdated(value: string): void {
    if (this.internallySet) {
      this.internallySet = false;
      return;
    }

    if (value.indexOf('=') <= 0) {
      return;
    }

    const expression: (number | string)[] = (value.split(' = ')[0]).split(' + ');
    expression[0] = parseInt((expression[0] as string).replace('(', '').replace(')', ''));
    expression[1] = parseInt((expression[1] as string).replace('(', '').replace(')', ''));

    this.topRow = [];
    this.bottomRow = [];

    switch (this.blockType) {
      case AddingblocksBlockType.Opposite:
        for (let i = 0; i < expression[0]; i++) {
          this.topRow.push({value: 1});
        }

        for (let i = 0; i < -expression[1]; i++) {
          this.bottomRow.push({value: -1});
        }

        break;
      case AddingblocksBlockType.Same:
        for (let i = 0; i < Math.abs(expression[0]); i++) {
          this.topRow.push({value: this.blockValue});
        }

        for (let i = 0; i < Math.abs(expression[1]); i++) {
          this.bottomRow.push({value: this.blockValue});
        }

        break;
    }

    this.updateResults();
  }

}
