export const Tokenize = function Tokenize(Str: string, Sep = ',', Esc = '\\'): string[] {
  const brackets: Record<string, any> = {};
  const chunks: string[] = [];
  let currentChunk = '';
  const quotes: Record<string, any> = {};

  const MatchingBrackets = {
    ')': '(',
    ']': '[',
    '}': '{',
  };

  const IsSepEscaped = function () {
    let insideBrackets = false;
    let insideQuotes = false;

    for (let i in brackets) {
      //@ts-ignore
      insideBrackets |= brackets[i] > 0;
    }

    for (let i in quotes) {
      //@ts-ignore
      insideQuotes |= quotes[i];
    }

    return insideBrackets || insideQuotes;
  };

  if (Str) {
    for (let i = 0; i < Str.length; i++) {
      const char = Str[i];
      switch (char) {
        case '(':
        case '{':
        case '[':
          currentChunk += char;
          brackets[char] = (brackets[char] || 0) + 1;
          break;
        case ')':
        case '}':
        case ']':
          currentChunk += char;
          const Bracket = MatchingBrackets[char];
          //@ts-ignore
          brackets[Bracket] = (brackets[Bracket] || 0) - 1;
          break;
        case '\'':
        case '"':
          currentChunk += char;
          //@ts-ignore
          quotes[char] = !quotes[char];
          break;
        case Esc:
          currentChunk += char + Str[++i];
          break;
        case Sep:
          if (!IsSepEscaped()) {
            chunks.push(currentChunk);
            currentChunk = '';
          }
          else {
            currentChunk += char;
          }
          break;
        default:
          currentChunk += char;
          break;
      }
    }
  }

  if (currentChunk) {
    chunks.push(currentChunk);
  }

  return chunks;
};

export const DraggableTokenizer = function (answer: string): Record<string, any> {
  const Regions = Tokenize(answer);
  const Result: Record<string, any> = {};

  for (let i = 0; i < Regions.length; i++) {
    const RegionSplit = Tokenize(Regions[i], ':');
    const RegionDraggables = RegionSplit[1] ? Tokenize(RegionSplit[1].replace(/^\[|\]$/g, '')) : '';
    //@ts-ignore
    Result[RegionSplit[0]] = RegionDraggables;
  }

  return Result;
};
