import {
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import { IScorable, ScorableData } from "../kh-manip-scorable/IScorable";
import {
  DynamicContentChild,
  OnDynamicData,
  OnDynamicMount,
} from "ngx-dynamic-hooks";
import { KhManipulativeClickableRegionComponent } from "../kh-manip-scorable-region/kh-manip-scorable-region.component";
import { KhManipScorableIntInput } from "../kh-manip-scorable-int-input/kh-manip-scorable-int-input.component";
import { Inject } from '@angular/core';
import { QUESTION_EVENTS_SERVICE_TOKEN, IQuestionEventsService } from "../../../../services/IQuestionEvents";

@Component({
  selector: "kh-manipulative-clickable",
  templateUrl: "kh-manip-scorable-clickable.component.html",
  styleUrls: ["kh-manip-scorable-clickable.component.less"],
})
export class KhManipulativeClickableComponent implements IScorable, OnDynamicMount {
  @Input() public onChangeCallback: undefined | ((data: ScorableData) => void); // callback
  @Input("frame") public frame = "0 0 100 100";
  @Input("key") public key: string = "";
  @Input("background-image") public backgroundImage: string = "";
  @Input("clicked-image") public clickedImage: string = "";
  @Input("max-objects") public maxObjects!: string;
  @ViewChild("clickable-region") public regionElement!: ElementRef;

  public scorableData: ScorableData = { answer: {}, state: {} };
  public contentChild!: DynamicContentChild[];
  public regionsData: ScorableData[] = [];
  public box = { left: 0, top: 0, width: 0, height: 0, };
  private maxObjs: number = 0; // 0 treats as unlimited
  private clickableRegions: KhManipulativeClickableRegionComponent[] = [];

  public ngOnInit() {
    const [left, top, width, height] = this.frame.split(" ").map(Number);
    this.box = { left, top, width, height };
  }

  public constructor(@Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService) {
    this.questionEventsService.hideInputOnlyForManip.next(true);
  }

  public onDynamicMount(data: OnDynamicData): void {
    this.maxObjs = parseInt(this.maxObjects) || 0;
    const contentChildren: DynamicContentChild[] = data.contentChildren ?? [];
    this.contentChild = contentChildren;

    const countMap = this.getCountVariableMap();

    contentChildren.forEach((c, index) => {
      if (c.componentRef.instance instanceof KhManipulativeClickableRegionComponent) {
        const curComponent = c.componentRef.instance;
        curComponent.clickedImage = curComponent.clickedImage ? curComponent.clickedImage : this.clickedImage;
        curComponent.value = this.scorableData;
        curComponent.index = index;

        if (this.scorableData) {
          const countName = curComponent.countVarName;

          if (countMap.has(countName)) {
            const countVal = countMap.get(countName) || 0;

            curComponent.selected = countVal > 0;
            countMap.set(countName, countVal - 1);
          } else {
            curComponent.selected = this.scorableData.state[curComponent.key] ? true : false;
          }
        }

        curComponent.clickCallback = this.onClickChangeValue.bind(this);

        this.clickableRegions.push(curComponent);
      }
    });
  }

  private getCountVariableMap(): Map<string, number> {
    const countVariableMap = new Map<string, number>();

    this.contentChild.forEach((c) => {
      const curComponent = c.componentRef.instance;
      if (curComponent instanceof KhManipulativeClickableRegionComponent && curComponent.countVarName) {
        countVariableMap.set(curComponent.countVarName, this.scorableData.state[curComponent.countVarName] ? parseInt(this.scorableData.state[curComponent.countVarName]) : 0);
      }
    });

    return countVariableMap;
  }

  public onClickChangeValue(stateDelta: ScorableData, element: KhManipulativeClickableRegionComponent): void {
    if (this.maxObjects) {
      const key = Object.keys(stateDelta.answer)[0];
      const isDeselect = this.scorableData.state[key] === '1';
      const curSelectionCount = this.getNumSelected();

      if (this.maxObjs > 1 && curSelectionCount >= this.maxObjs && !isDeselect) {
        element.selected = false;
        return;
      } else if (this.maxObjs === 1 && curSelectionCount >= 1) {
        this.clearAllSelections();
        element.selected = !isDeselect;
      }

      this.setAnswerAndState(stateDelta);

      if (this.onChangeCallback) {
        this.onChangeCallback(this.getScorableData());
      }
    }
  }

  public onChangeValue(val: any): void {
    this.setAnswerAndState(val);

    if (this.onChangeCallback) {
      this.onChangeCallback(this.getScorableData());
    }
  }

  public getScorableData(): ScorableData {
    const data: ScorableData = {
      answer: this.scorableData.answer,
      state: this.scorableData.state,
    };

    return data;
  }

  public setScorableData(scorableDatata: ScorableData): void {
    this.scorableData = scorableDatata;
  }

  private getNumSelected(): number {
    return Object.values(this.scorableData.state).filter((v) => v === "1").length;
  }

  private clearAllSelections(): void {
    this.clickableRegions.forEach((r) => {
      r.selected = false;
    });

    this.scorableData.answer = {};
    this.scorableData.state = {};
  }

  /**
   * This just sets the state values for a single entry
   * @param data A single entry of the scorable data
   */
  private setAnswerAndState(data: ScorableData): void {
    const answerKeyToUpdate = Object.keys(data.answer)[0];
    const stateKeyToUpdate = Object.keys(data.state)[0];
    const isRegionActive = Object.values(data.state)[0] === '1';
    const currentRegionContent = this.clickableRegions.find(c => c.key.toString() === stateKeyToUpdate);

    if (isRegionActive) {
      if (currentRegionContent?.ignoreInScore?.toString() !== 'true') {

        // if this has a countVarName we incremenet that instead of using the key but only in the answer data
        if (currentRegionContent?.countVarName) {
            const curVal = this.scorableData.answer[currentRegionContent.countVarName];
            this.scorableData.answer[currentRegionContent.countVarName] = curVal ? (parseInt(curVal) + 1).toString() : '1';
        } else {
          this.scorableData.answer[answerKeyToUpdate] = '1';
        }
      }
      else {
        delete this.scorableData.answer[answerKeyToUpdate];
      }

      this.scorableData.state[stateKeyToUpdate] = '1';
    }

    if (!isRegionActive) {
      if (currentRegionContent?.countVarName) {
        const curVal = this.scorableData.answer[currentRegionContent.countVarName];
        this.scorableData.answer[currentRegionContent.countVarName] = (parseInt(curVal) - 1).toString();
      } else {
        delete this.scorableData.answer[answerKeyToUpdate];
      }

      delete this.scorableData.state[stateKeyToUpdate];
    }
  }
}
