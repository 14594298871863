import { ActivatedRoute } from '@angular/router';

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { range } from 'lodash-es';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Inject } from '@angular/core';
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from '../../../services/IQuestionEvents';
import { MODAL_SERVICE_TOKEN, IModalService } from '../../../services/IModalService';
import { MUSIC_SERVICE_TOKEN, IMusicService } from '../../../services/IMusicService';
import { STUDENT_MUSIC_SERVICE_TOKEN, IStudentMusicService } from '../../../services/IStudentMusicService';
import { TEMPLATE_PREFIX } from '../../../environments/locale-config';
import { ManipControlsAtBottomComponent } from '../../modals';

@UntilDestroy()
@Component({
  selector: 'kh-integer-input',
  styleUrls: ['./integer-input.component.less'],
  templateUrl: TEMPLATE_PREFIX + 'integer-input.component.html'
})
export class IntegerInputComponent implements OnInit {
  public questionClosed = false;
  public isClosedByTeacher = false;
  public initValue = false;

  @Input() public hideInput = false;
  @Input() public upLabel?: string;
  @Input() public downLabel?: string;
  @Input() public values = '';
  @Input() public itemIndex = 0;
  @Input() public defaultValue?: string;
  @Input() public reverseButtonOrder = false;
  @Input() public minValue = 0;
  @Input() public maxValue = 10;
  @Input() public diasabledInput = false;

  @Output() public submitAnswer = new EventEmitter<string>();
  @Output() public valueChange = new EventEmitter<string>();
  @Output() public itemIndexChange = new EventEmitter<number>();

  private val = '';
  private valuesList: string[] = [];

  public hideStudentManipControlBar = this.questionEventsService.hideStudentManipControlBar.asObservable();

  public constructor(
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) private readonly questionEventsService: IQuestionEventsService,
    @Inject(MODAL_SERVICE_TOKEN) private readonly modalService: IModalService,
    @Inject(STUDENT_MUSIC_SERVICE_TOKEN) private readonly studentMusicService: IStudentMusicService,
    @Inject(MUSIC_SERVICE_TOKEN) private readonly sharedMusicService: IMusicService,
    private readonly route: ActivatedRoute) {
  }

  public get inputValue(): string {
    return this.val !== '' ? this.val : '0';
  }

  @Input()
  public set value(value: string) {
    this.val = value ? value : '';
    this.updateItemIndex(this.val);
  }

  public get upIsPlus(): boolean {
    return this.upLabel === '+';
  }

  public get upIsNotPlus(): boolean {
    return Boolean(this.upLabel) && this.upLabel !== '+';
  }

  public get downIsMinus(): boolean {
    return this.downLabel === '-';
  }

  public get downIsNotMinus(): boolean {
    return Boolean(this.downLabel) && this.downLabel !== '-';
  }

  private get defaultVal(): string {
    return this.defaultValue || this.valuesList[0];
  }

  public ngOnInit(): void {
    this.initValue = !!this.val && this.val !== '0';
    this.questionEventsService.hideAllInputForManip.next(true);

    this.valuesList = this.values ? this.values.split(',')
      : range(this.minValue, this.maxValue + 1).map(val => val.toString());
    this.updateItemIndex(this.val);

    this.questionEventsService.questionClosed
      .pipe(
        tap((eventInfo: any) => {
          this.questionClosed = true;
          if(eventInfo?.byTeacher) {
            this.isClosedByTeacher = true;
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();

    if (!this.val) {
      this.val = this.defaultVal;
      this.valueChange.emit(this.defaultVal);
    }
  }

  public updateItemIndex(value?: string): void {
    this.itemIndex = 0;
    for (let i = 0; i < this.valuesList.length; i++) {
      if (this.valuesList[i] === value) {
        this.itemIndex = i;
        break;
      }
    }

    this.itemIndexChange.emit(this.itemIndex);
  }

  public showControlModal(): void {
    if(!this.diasabledInput){
      const modal = this.modalService.displayModal<ManipControlsAtBottomComponent>(ManipControlsAtBottomComponent, {}, 'sm', true);
      modal.content?.closed.pipe(
        tap(() => this.animateControlBar()),
        untilDestroyed(this)
      )
        .subscribe();
    }    
  }

  public animateControlBar(): void {
    //  TODO: need to use AngularX way @ViewChild
    let StudentControlBars = document.getElementsByClassName('ManipIntegerClickerBar');

    for (let i = 0; i < StudentControlBars.length; i++) {
      if (StudentControlBars[i].classList) {
        StudentControlBars[i].classList.add('animated');
        StudentControlBars[i].classList.add('bounceInDown');
      } else {
        StudentControlBars[i].className += ' animated bounceInDown';
      }
    }
    //  TODO: need to use AngularX way @ViewChild
    StudentControlBars = document.getElementsByClassName('ManipIntegerClickerBar');

    // Remove animated classes for re-use
    setTimeout(() => {
      const className = 'animated bounceInDown'; // previously "shake"
      for (let i = 0; i < StudentControlBars.length; i++) {
        if (StudentControlBars[i].classList) {
          StudentControlBars[i].classList.remove('animated');
          StudentControlBars[i].classList.remove('bounceInDown');
        }
        else {
          StudentControlBars[i].className =
            StudentControlBars[i].className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
      }
    }, 1000);
  }

  public addValue(change: number): void {
    // HACK: Temporary solution. Should be refactored to receive component mode (isPreview: true/false) as an input.
    const musicService = this.route.snapshot.url.find(segment => segment.path === 'student')
      ? this.studentMusicService
      : this.sharedMusicService;

    const newIndex = this.itemIndex + change;
    if (newIndex < 0 || newIndex >= this.valuesList.length) {
      musicService.playSound('bop.mp3');

      return;
    }

    musicService.playSound('pop.mp3');
    this.valueChange.emit(this.valuesList[newIndex]);
  }

  public submit(value: string): void {
    this.submitAnswer.emit(value);
  }
}
