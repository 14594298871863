import { Component, ViewChild, ElementRef, Input, Inject } from "@angular/core";
import { PuzzleFactoryLogic } from "./puzzleFactoryLogic";
import { PuzzleBaseComponent } from "../puzzle/puzzle.component";
import { PuzzleEventService } from "../services/puzzle-event.service";
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from "../../services/IQuestionEvents";
import { IAnalyticsService, ANALYTICS_SERVICE_TOKEN } from '../../services/IAnalyticsService';
import { IAuthenticationDataService, AUTH_DATA_SERVICE_TOKEN } from '../../services/IAuthenticationDataService';


@Component({
    selector: 'kh-puzzle-factory',
    templateUrl: './puzzle-factory.component.html',
    styleUrls: ['./puzzle-factory.component.less']
})
export class PuzzleFactoryComponent extends PuzzleBaseComponent {
    @Input('start') public start: number = 0;
    @Input('end') public end: number = 10;
    @Input('frequency') public frequency: number = 5;
    @Input('bearspacing') public bearSpacing: number | null = null;
    @Input('bearstart') public bearStart: number | null = null;
    @Input('target') public target: number = 5;
    @Input('endinputchoices') public endInputChoices: string = ''
    @Input('deltax') public deltaX: number = 86.5;
    @Input('compression') public compression: number = 1;
    @ViewChild('assets') public assets?: ElementRef<HTMLElement>;
    @ViewChild('arena') public arena!: ElementRef<SVGElement>;
    @ViewChild('container') public container?: ElementRef<HTMLElement>;

    public constructor(
        @Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService,
        protected puzzleEventService: PuzzleEventService,
        @Inject(ANALYTICS_SERVICE_TOKEN) protected readonly analyticsService: IAnalyticsService,
        @Inject(AUTH_DATA_SERVICE_TOKEN) protected readonly authenticationDataService: IAuthenticationDataService) {
        super(questionEventsService, puzzleEventService, analyticsService, authenticationDataService);
    }

    public ngAfterViewInit(): void {
        this.bearSpacing = this.bearSpacing == null ? this.frequency : this.bearSpacing;
        this.bearSpacing = this.frequency == null ? this.frequency : this.bearSpacing;
        this.bearStart = this.bearStart == null ? this.bearSpacing : this.bearStart;
        this.deltaX = this.deltaX*this.compression;
        const d = {assets: this.assets?.nativeElement,arena: this.arena?.nativeElement,container: this.container?.nativeElement};
        const p = {target: this.target, deltaX: this.deltaX*this.compression,bearStart: this.bearStart,endInputChoices: this.endInputChoices,start: this.start,end: this.end,frequency: this.frequency,bearSpacing: this.bearSpacing};
        const setup = {props: p,dom: d, arena: this.arena?.nativeElement};
        this.setPuzzle(new PuzzleFactoryLogic(setup));
    }
}
