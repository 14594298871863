import { Observable } from 'rxjs';
import { fabric } from 'fabric';
import { OnDynamicData, OnDynamicMount } from 'ngx-dynamic-hooks';

export abstract class ManipulativeChildObject implements OnDynamicMount {

  public abstract isLoaded: Observable<boolean>;
  public abstract fabricObject: Array<fabric.Object>;
  public abstract order: number;

  public onDynamicMount(data: OnDynamicData): void {
    (data.contentChildren || []).forEach(c => {
      if (c instanceof ManipulativeChildObject) {
        c.onDynamicMount({
          context: data.context,
          contentChildren: c.contentChildren
        })
      }
    })
  }
}
