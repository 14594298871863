import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  Renderer2,
  OnInit,
  HostListener,
  ChangeDetectorRef
} from '@angular/core';
import { DotConnectClass } from './api';

import { DynamicContentChild, OnDynamicData, OnDynamicMount } from 'ngx-dynamic-hooks';
import { NodeComponent } from '../node/node.component';
import { Inject } from '@angular/core';
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from '../../../../services/IQuestionEvents';
import { TEMPLATE_PREFIX } from '../../../../environments/locale-config';
import { Node } from './dot-connect-node';

@Component({
  selector: 'kh-manip-dot-connect',
  templateUrl: TEMPLATE_PREFIX + 'manip-dot-connect.component.html',
  styleUrls: ['./manip-dot-connect.component.less']
})
export class DotConnectComponent implements OnInit, OnDynamicMount {
  @ViewChild('element') public element!: ElementRef<SVGSVGElement>;
  @ViewChild('element', { read: ElementRef, static: true }) public mainElement!: ElementRef;

  @Input() public width = 1280;
  @Input() public height = 720;
  @Input() public backgroundImage = '';
  @Input() public dotColor = '#000000';
  @Input() public lineColor = '#000000';
  @Input() public dotSize = 10;
  @Input() public nodes = '';
  @Input() public reportMode = false;
  @Input() public value: any | null = null;

  @Output() public valueChange = new EventEmitter<string>();

  public constructor(
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) private readonly questionEventsService: IQuestionEventsService,
    public readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) { }

  public nodeList: Node[] = [];

  public ngOnInit() {
    this.resizeWorks();
    this.questionEventsService.hideInputOnlyForManip.next(true);
    this.changeDetectorRef.markForCheck();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    setTimeout(() => {
      this.resizeWorks();
    })
  }

  public resizeWorks(): void {
    const container = this.elementRef.nativeElement.closest('.gameplayQuestionText');
    const teacherContainer = this.elementRef.nativeElement.closest('.QuestionTextTeacher');
    const elemParentNode = this.elementRef.nativeElement.parentNode;
    let scale = 1;
    (this.elementRef.nativeElement as HTMLDivElement).hidden = true;
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');

    scale = Math.min(1, (elemParentNode.offsetWidth === 0 ? (container ? container.offsetWidth : teacherContainer.offsetWidth) : elemParentNode.offsetWidth) / this.width);

    (this.elementRef.nativeElement as HTMLDivElement).hidden = false;
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'flex');

    if (scale === 0) {
      setTimeout(() => {
        this.resizeWorks();
      }, 1000);
    } else {
      this.renderer.setStyle(this.elementRef.nativeElement, 'width', this.width * scale + 'px');
      this.renderer.setStyle(this.elementRef.nativeElement, 'height', this.height * scale + 'px');
      this.renderer.setStyle(this.mainElement?.nativeElement, 'background-size', 'cover');
    }
  }

  public onDynamicMount(data: OnDynamicData): void {
    const contentChildren: DynamicContentChild[] = data.contentChildren ?? [];
    contentChildren.forEach(item => {
      const instance = item.componentRef.instance;
      if (instance instanceof NodeComponent) {
        this.nodeList.push(instance.getNode());
      }
    });
    this.render();
  }

  public render() {
    const nodes = {} as { [key: string]: { x: number, y: number } }

    if (this.nodes) {
      const nodeString = this.nodes.replace(/[']/g, '').split(':');
      nodeString.forEach(a => {
        const arr = a.split(',');
        const id = arr[0];
        const x = Number(arr[1]);
        const y = Number(arr[2]);
        nodes[id] = { x: x, y: y };
      });
    } else {
      this.nodeList.forEach((a: { id: any; x: any; y: any; }) => {
        const id = a.id.replace(/[']/g, '');
        const x = Number(a.x);
        const y = Number(a.y);
        nodes[id] = { x: x, y: y };
      })
    }

    const setup = {
      width: this.width,
      height: this.height,
      dotColor: this.dotColor,
      lineColor: this.lineColor,
      dotSize: this.dotSize,
      nodes: nodes,
      answer: this.value,
      state: this.value?.state,
      reportMode: this.reportMode,
      background: this.backgroundImage
    };

    const dotConnect = new DotConnectClass(this.element.nativeElement, setup);

    if (this.reportMode) {
      this.renderer.setStyle(this.mainElement?.nativeElement, 'pointer-events', 'none');
    }

    if (!this.reportMode) {
      dotConnect.onAnswerUpdate = (ans: string) => {
        const answer = ans.split(',').sort();
        this.valueChange.emit(answer.join(','));
      }
    }
  }
}
