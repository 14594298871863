// @ts-nocheck
import { gsap } from "gsap/all"

export const svgns = "http://www.w3.org/2000/svg";

export function bringToFront(obj) {
  let parent = obj.parentElement;
  parent.removeChild(obj);
  parent.appendChild(obj);
}

export function applyDecimalOffset(num, dec) {
  let returnValue = num;

  if (dec != null) {
    const decimals = dec && dec;
    const divided = num / Math.pow(10, decimals);
    returnValue = divided.toFixed(dec);
  }

  return returnValue;
}

export function getBoundingBoxWithTransform(elem) {
  let bbox_elem = elem.getBBox();

  const elemX = bbox_elem.x + gsap.getProperty(elem, "translateX");
  const elemY = bbox_elem.y + gsap.getProperty(elem, "translateY");

  return { x: elemX, y: elemY };
}