import { gsap } from 'gsap/all'

const svgns = 'http://www.w3.org/2000/svg'

export interface ZoomSetup {
  image: string
  backgroundColor: string
  width: string
  height: string
  viewbox: string
}
export class ZoomClass {
  private setup: ZoomSetup;
  private fetchedSVG: SVGSVGElement;
  private fetchedSVGViewBox = { height: 0, width: 0, x: 0, y: 0 }
  private isDragging = false;
  private dragStart = { x: 0, y: 0 };
  private zoomLevel = 0;
  private zoomIncrementX: number[] = [];
  private zoomIncrementY: number[] = [];
  private zoomIn: SVGSVGElement;
  private zoomOut: SVGSVGElement;
  private center: SVGSVGElement;
  private ui: SVGSVGElement;
  private centerWidth = 0;
  private centerHeight = 0;

  public constructor(gsvg: SVGSVGElement, gsvgu: HTMLDivElement, setup: ZoomSetup) {
    this.setup = setup;
    this.fetchedSVG = gsvg

    this.zoomIn = gsvgu.querySelector('#zoomIn') as SVGSVGElement;
    this.zoomOut = gsvgu.querySelector('#zoomOut') as SVGSVGElement;
    this.ui = gsvgu.querySelector('#ui') as SVGSVGElement;
    this.center = gsvgu.querySelector('#center') as SVGSVGElement;

    this.init();
  }

  private zoomHelper(zoomIn: boolean, index: number): string {
    const offsetX = (this.centerWidth - this.fetchedSVGViewBox.width / 2);
    const offsetY = (this.centerHeight - this.fetchedSVGViewBox.height / 2);

    let width;
    let height;

    if (zoomIn) {
      width = Math.max((this.fetchedSVGViewBox.width - this.zoomIncrementX[index]), 5);
      height = Math.max((this.fetchedSVGViewBox.height - this.zoomIncrementY[index]), 5);
    }
    else {
      width = Math.max((this.fetchedSVGViewBox.width + this.zoomIncrementX[index]), 5);
      height = Math.max((this.fetchedSVGViewBox.height + this.zoomIncrementY[index]), 5);
    }

    const x = (this.centerWidth - width / 2) + (this.fetchedSVGViewBox.x - offsetX);
    const y = (this.centerHeight - height / 2) + (this.fetchedSVGViewBox.y - offsetY);

    const temp = `${x} ${y} ${width} ${height}`;

    return temp;
  }

  private handleZoomIn(): void {
    let index = 11;

    if (this.zoomLevel < 0) {
      index = Math.abs(this.zoomLevel) - 1;
    }
    else if (this.zoomLevel > 0) {
      index = this.zoomLevel + 11;
    }
    if (this.zoomLevel < 10) {
      const newViewBox = this.zoomHelper(true, index)
      gsap.set(this.fetchedSVG, { attr: { viewBox: newViewBox } });
      this.zoomLevel++;
    }
  }

  private handleZoomOut(): void {
    let index = 0;
    if (this.zoomLevel < 0) {
      index = Math.abs(this.zoomLevel);
    }
    else if (this.zoomLevel > 0) {
      index = this.zoomLevel + 11 - 1;
    }
    if (this.zoomLevel > -10) {
      const newViewBox = this.zoomHelper(false, index)
      gsap.set(this.fetchedSVG, { attr: { viewBox: newViewBox } });
      this.zoomLevel--;
    }
  }

  private handleCenter(): void {
    gsap.set(this.fetchedSVG, { attr: { viewBox: `0 0 ${this.centerWidth * 2} ${this.centerHeight * 2}` } });
    this.zoomLevel = 0;
  }

  private startDrag(e: MouseEvent): void {
    let pt = this.fetchedSVG.createSVGPoint();
    pt.x = e.clientX;
    pt.y = e.clientY;
    pt = pt.matrixTransform(this.fetchedSVG.getScreenCTM()?.inverse());

    this.dragStart.x = pt.x;
    this.dragStart.y = pt.y;
    this.isDragging = true;
  }

  private whileDrag(e: MouseEvent): void {
    if (this.isDragging) {
      let pt = this.fetchedSVG.createSVGPoint();
      pt.x = e.clientX;
      pt.y = e.clientY;
      pt = pt.matrixTransform(this.fetchedSVG.getScreenCTM()?.inverse());

      const x = this.fetchedSVGViewBox.x + (this.dragStart.x - pt.x)
      const y = this.fetchedSVGViewBox.y + (this.dragStart.y - pt.y)
      gsap.set(this.fetchedSVG, {
        attr: {
          viewBox: `${x} ${y} ${this.fetchedSVGViewBox.width} ${this.fetchedSVGViewBox.height}`
        }
      });
    }
  }

  private endDrag(): void {
    this.isDragging = false;
  }

  private calculateZoomIncrements(): void {
    let lastWidth = this.fetchedSVGViewBox.width;
    let lastHeight = this.fetchedSVGViewBox.height;
    for (let i = 0; i < 11; i++) {
      let temp = lastWidth / 3;
      this.zoomIncrementX.push(temp);
      lastWidth += temp;

      temp = lastHeight / 3;
      this.zoomIncrementY.push(temp);
      lastHeight += temp;
    }

    lastWidth = this.fetchedSVGViewBox.width;
    lastHeight = this.fetchedSVGViewBox.height;
    for (let i = 0; i < 11; i++) {
      let temp = lastWidth / 3;
      this.zoomIncrementX.push(temp);
      lastWidth -= temp;

      temp = lastHeight / 3;
      this.zoomIncrementY.push(temp);
      lastHeight -= temp;
    }
  }

  private init() {

    gsap.set(this.fetchedSVG, { attr: { height: this.setup.height, viewBox: this.setup.viewbox, width: this.setup.width } });

    const imageId = `image_${Math.random()}`;

    const image = document.createElementNS(svgns, 'image');
    gsap.set(image, { attr: { href: this.setup.image, id: imageId, width: this.setup.width, height: this.setup.height} });
    this.fetchedSVG.querySelector('defs')?.append(image)

    const use = document.createElementNS(svgns, 'use');
    gsap.set(use, { attr: { href: `#${imageId}` } });
    this.fetchedSVG.appendChild(use);

    this.fetchedSVGViewBox = this.fetchedSVG.viewBox.baseVal;

    this.centerHeight = this.fetchedSVGViewBox.height / 2;
    this.centerWidth = this.fetchedSVGViewBox.width / 2;

    this.calculateZoomIncrements();

    gsap.set(this.fetchedSVG, { backgroundColor: this.setup.backgroundColor });

    this.fetchedSVG.addEventListener('pointerdown', (e: MouseEvent) => this.startDrag(e));
    this.fetchedSVG.addEventListener('pointermove', (e: MouseEvent) => this.whileDrag(e));
    this.fetchedSVG.addEventListener('pointerup', () => this.endDrag());
    this.fetchedSVG.addEventListener('pointerleave', () => this.endDrag());

    this.zoomIn.addEventListener('pointerdown', () => this.handleZoomIn());
    this.zoomOut.addEventListener('pointerdown', () => this.handleZoomOut());
    this.center.addEventListener('pointerdown', () => this.handleCenter());
  }
}
