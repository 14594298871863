import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { applyFrameToElement } from '../../../../utils/utils';
import { OnDynamicMount } from 'ngx-dynamic-hooks';
import { KhManipulativeWindowComponent } from '../kh-manip-scorable-window/kh-manip-scorable-window.component';

@Component({
    selector: 'kh-manipulative-counter',
    template: '<div #counterElement></div>'
})
export class KhWindowsCounter implements OnDynamicMount {
    @ViewChild('counterElement') protected element: ElementRef<HTMLSelectElement> | undefined;
    @Input('frame') protected frame = '0 0 100 100';
    @Input('stroke-width') public strokeWidth: number = 2;
    @Input('stroke-color') public strokeColor: string = 'black';
    @Input('background-color') public bgColor: string = 'white';
    @Input('font-size') public fontSize: number = 12;
    @Input('font') public fontFamily: string = 'poppins';
    @Input('key') public key: string = '';
    @Input('controllers') public controllers: string = '';

    private controllerList: string[] = [];
    private windowList: KhManipulativeWindowComponent[] = [];

    onDynamicMount(): void {
        this.controllerList = this.controllers.split(',');

        if (this.element?.nativeElement) {            
            applyFrameToElement(this.element?.nativeElement, this.frame);

            this.element.nativeElement.style.fontFamily = this.fontFamily;
            this.element.nativeElement.style.fontSize = `${this.fontSize}px`;
            this.element.nativeElement.style.backgroundColor = this.bgColor;
            this.element.nativeElement.style.borderWidth = `${this.strokeWidth}px`;
            this.element.nativeElement.style.borderColor = this.strokeColor;
            this.element.nativeElement.style.borderStyle = 'solid';

            this.onChange();
        }
    }

    public addWindows(windows: KhManipulativeWindowComponent[]): void {
        this.windowList = [...this.windowList, ...windows];
    }

    public onChange(): void {
        let total = 0;

        this.windowList.forEach((window) => {
            total += window.getCurrentPane().order;
        });

        if (this.element?.nativeElement) {
            this.element.nativeElement.innerText = total.toString();
        }
    }      

}