import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ZoomClass, ZoomSetup } from './zoomAPI'

@Component({
  selector: 'kh-image-zoom',
  styleUrls: ['./image-zoom.component.less'],
  templateUrl: './image-zoom.component.html'
})
export class ImageZoomComponent implements AfterViewInit {

  @ViewChild('lowerRenderEl') public lowerRenderEl?: ElementRef<SVGSVGElement>;
  @ViewChild('upperRenderEl') public upperRenderEl?: ElementRef<HTMLDivElement>;

  @Input()
  private image = 'https://app.knowledgehook.com/Content/Images/6e2e3cac-30ed-ea11-974a-0050568c42b6/zoomboat-vecta (1).svg'

  @Input()
  private backgroundColor = 'rgb(169, 234, 252)'

  @Input()
  private width = '1300'

  @Input()
  private height = '920'

  @Input()
  private viewbox = '0 0 1300 920'

  public ngAfterViewInit(): void {
    const setup = {
      backgroundColor: this.backgroundColor,
      height: this.height,
      image: this.image,
      viewbox: this.viewbox,
      width: this.width
    } as ZoomSetup

    const gsvgu = this.upperRenderEl?.nativeElement!;
    const gsvg = this.lowerRenderEl?.nativeElement!


    const interactive = new ZoomClass(gsvg, gsvgu, setup);
  }
}
