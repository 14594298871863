import { Component, Input } from "@angular/core";
import { OnDynamicData, OnDynamicMount } from "ngx-dynamic-hooks";
import { KhManipulativePaneComponent } from "../kh-manip-pane/kh-manip-pane.component";
import { clamp } from "../../../../utils/utils";

@Component({
  selector: "kh-window",
  templateUrl: "kh-manip-scorable-window.component.html",
  styleUrls: ["kh-manip-scorable-window.component.less"],
})
export class KhManipulativeWindowComponent implements OnDynamicMount {
  @Input('key') public key: string = '';
  @Input("controllers") public controllers: string = '';
  @Input("start-key") public startKey!: number;
  @Input("index") public index!: number;
  @Input("value") public value!: string;
  @Input("frame") public frame = "0 0 100 100";
  @Input("image") public image?: string;

  public paneChildren: KhManipulativePaneComponent[] = [];
  private curIdx = 0;

  public onDynamicMount(data: OnDynamicData): void {
    const contentChildren = data.contentChildren ?? [];

    contentChildren.forEach(c => {
      if (c.componentRef.instance instanceof KhManipulativePaneComponent) {
        const curComponent: KhManipulativePaneComponent = c.componentRef.instance;
        curComponent.frame = this.frame;

        this.paneChildren.push(curComponent);
      }
    });

    this.sortPanes();

    if (this.startKey) {
      this.curIdx = this.getIdxOfKey(this.startKey.toString());
      this.setCurVisibility(true);
    }
  }

  public showNextPane(): void {
    this.switchToIdx(this.curIdx + 1);
  }

  public showPrevPane(): void {
    this.switchToIdx(this.curIdx - 1);
  }

  private switchToIdx(nextIdx: number): void {
    this.setCurVisibility(false);
    this.curIdx = clamp(nextIdx, 0, this.paneChildren.length - 1);
    this.setCurVisibility(true);
  }

  private setCurVisibility(visible: boolean): void {
    this.paneChildren[this.curIdx].visibility = visible ? 'visible' : 'hidden';
  }

  private sortPanes(): void {
    this.paneChildren = this.paneChildren.sort((a, b) => {
      return a.order - b.order;
    });
  }

  /**
   * Get the array index of the given pane key
   * @param key The pane key
   * @returns The index the key is in the array
   */
  private getIdxOfKey(key: string): number {
    return this.paneChildren.findIndex((pane) => pane.key === key);
  }

  public getCurKeypair(): {key: string, value: string} {
    return { key: this.key, value: this.paneChildren[this.curIdx].key };
  }

  public setPaneKey(key: string): void {
    const idx = this.getIdxOfKey(key);

    if (idx) {
      this.switchToIdx(idx);
    }
  }

  public getCurrentPane(): KhManipulativePaneComponent {
    return this.paneChildren[this.curIdx];
  }
}
