
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ScorableData } from '../kh-manip-scorable/IScorable';
import { OnDynamicMount } from 'ngx-dynamic-hooks';
import { applyFrameToElement } from '../../../../utils/utils';

@Component({
  selector: 'kh-manipulative-text',
  template: '<p #element><ng-content></ng-content></p>'
})
export class KhManipulativeText implements OnDynamicMount {
  public onChangeCallback: undefined | ((data: ScorableData) => void); // callback
  @ViewChild('element') public element: ElementRef<HTMLParagraphElement> | undefined;
  @Input('frame') public frame = '0 0 100 100';

  public onDynamicMount(): void {
    if (this.element?.nativeElement) {
      applyFrameToElement(this.element?.nativeElement, this.frame);
    }
  }
}
