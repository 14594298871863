/* eslint-disable complexity */
import { ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, ElementRef, Input, ViewChild, Inject } from '@angular/core';
import { GameInput, InputSetup, IntegerPlatfromClass } from './integer-platform2';
import { PuzzleBaseComponent } from '../puzzle/puzzle.component';
import { PuzzleEventService } from '../services/puzzle-event.service';
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN} from '../../services/IQuestionEvents';
import { ANALYTICS_SERVICE_TOKEN, IAnalyticsService } from '../../services/IAnalyticsService';
import { AUTH_DATA_SERVICE_TOKEN, IAuthenticationDataService } from '../../services/IAuthenticationDataService';

@Component({
  selector: 'kh-puzzle-minecart',
  styleUrls: ['./integer-platform.component.css'],
  templateUrl: './integer-platform.component.html'
})
export class MinecartPuzzleComponent extends PuzzleBaseComponent implements AfterViewInit {
  @ViewChild('arena') public arena?: ElementRef<HTMLElement>;
  @ViewChild('platform') public platform?: ElementRef<SVGSVGElement>;
  @ViewChild('controls') public controls?: ElementRef<HTMLElement>;
  @ViewChild('inputNums') public inputNums?: ElementRef<HTMLElement>;
  @ViewChild('inputBtns') public inputBtns?: ElementRef<HTMLElement>;
  @ViewChild('equation') public equation?: ElementRef<HTMLElement>;
  @ViewChild('equationInput') public equationInput?: ElementRef<HTMLDivElement>;
  @ViewChild('terms') public terms?: ElementRef<HTMLElement>;
  @ViewChild('numbers') public numbers?: ElementRef<SVGSVGElement>;
  @ViewChild('plus') public plusBtn?: ElementRef<SVGSVGElement>;
  @ViewChild('minus') public minusBtn?: ElementRef<SVGSVGElement>;
  @ViewChild('plusTxt') public plusTxt?: ElementRef<SVGSVGElement>;
  @ViewChild('minusTxt') public minusTxt?: ElementRef<SVGSVGElement>;
  @ViewChild('cover') public cover?: ElementRef<SVGSVGElement>;
  @ViewChild('cart') public cart?: ElementRef<SVGSVGElement>;
  @ViewChild('backwheel') public backWheel?: ElementRef<SVGSVGElement>;
  @ViewChild('frontwheel') public frontWheel?: ElementRef<SVGSVGElement>;
  @ViewChild('minecartSvg') public minecartSvg?: ElementRef<SVGElement>;
  @ViewChild('minecartOverlay') public minecartOverlay?: ElementRef<HTMLDivElement>;
  @ViewChild('minecartContainer') public minecartContainer?: ElementRef<HTMLDivElement>;
  @ViewChild('equationAddButton') public equationAddButton?: ElementRef<HTMLDivElement>;
  @ViewChild('equationRemoveButton') public equationRemoveButton?: ElementRef<HTMLDivElement>;

  @Input() public useImgs = true;
  @Input() public addRemove = true;
  @Input() public startBalloons = 2;
  @Input() public startSandbags = 3;
  @Input() public scrollbarRangeMax = 5;
  @Input() public scrollbarRangeMin = -5;
  @Input() public target = 1;
  @Input() public termLimit = 0;
  @Input() public inputs = 3;
  @Input() public choices = '+B2,-B1,+S2,-S1';

  public constructor(
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService,
    protected puzzleEventService: PuzzleEventService,
    @Inject(ANALYTICS_SERVICE_TOKEN) protected readonly analyticsService: IAnalyticsService,
    @Inject(AUTH_DATA_SERVICE_TOKEN) protected readonly authenticationDataService: IAuthenticationDataService,
    private route: ActivatedRoute) {

    super(questionEventsService, puzzleEventService, analyticsService, authenticationDataService);
  }

  public ngAfterViewInit(): void {
    if (!this.minecartSvg) {
      // the npx-dynamic-hooks package is causing double renders and it's super annoying.
      // Some renders are basically no-ops. This is a guard against it. I will have to fix this at a holistic level.
      // for now just guard.
      return;
    }

    const setup: InputSetup = {
      addButton: this.equationAddButton?.nativeElement,
      addRemove: this.addRemove,
      arena: this.arena?.nativeElement,
      backWheel: this.backWheel?.nativeElement,
      cart: this.cart?.nativeElement,
      controls: this.controls?.nativeElement,
      cover: this.cover?.nativeElement,
      choices: this.choices,
      equation: this.equation?.nativeElement,
      equationInput: this.equationInput?.nativeElement,
      frontWheel: this.frontWheel?.nativeElement,
      inputBtns: this.inputBtns?.nativeElement,
      inputNums: this.inputNums?.nativeElement,
      inputs: this.inputs,
      minecartContainer: this.minecartContainer?.nativeElement,
      minecartOverlay: this.minecartOverlay?.nativeElement,
      minecartSvg: this.minecartSvg?.nativeElement,
      minusBtn: this.minusBtn?.nativeElement,
      minusTxt: this.minusTxt?.nativeElement,
      numbers: this.numbers?.nativeElement,
      platform: this.platform?.nativeElement,
      plusBtn: this.plusBtn?.nativeElement,
      removeButton: this.equationRemoveButton?.nativeElement,
      plusTxt: this.plusTxt?.nativeElement,
      scrollbarRangeMax: this.scrollbarRangeMax,
      scrollbarRangeMin: this.scrollbarRangeMin,
      termLimit: this.termLimit,
      terms: this.terms?.nativeElement,
      useImgs: this.useImgs
    } as unknown as InputSetup

    const gameInput: GameInput = {
      goal: Number(this.target),
      startBalloons: this.startBalloons,
      startSandbags: this.startSandbags
    }

    const interactive = new IntegerPlatfromClass(setup, [gameInput]);
    this.setPuzzle(interactive);
    interactive.setupEls();
  }
}
