import { FractionStrip } from "../components/manipulatives/manip-fraction-strips/fraction-strip";


export const FractionsList: FractionStrip[] = [
  {fraction: 1, colour: '#FE7070'},
  {fraction: 2, colour: '#F983B2'},
  {fraction: 3, colour: '#FFB769'},
  {fraction: 4, colour: '#A0D412'},
  {fraction: 5, colour: '#22D48B'},
  {fraction: 6, colour: '#28E0DE'},
  {fraction: 8, colour: '#72BEEE'},
  {fraction: 10, colour: '#98A4EE'},
];