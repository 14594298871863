import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TEMPLATE_PREFIX } from '../../../../environments/locale-config';
import { MODAL_SERVICE_TOKEN, IModalService } from '../../../../services/IModalService';
import { QUESTION_EVENTS_SERVICE_TOKEN, IQuestionEventsService } from '../../../../services/IQuestionEvents';
import { FractionsList } from '../../../constants/fractions-list';
import { FractionStripAddComponent } from '../../../modals';
import { ModalComponent } from '../../../modals/modal/modal.component';
import { SimpleModalData } from '../../../models/simple-modal-data';
import { FractionParams } from './fraction-params';
import { FractionStrip } from './fraction-strip';
import { FractionStripsSum } from './fraction-strips-sum';
import { Inject } from '@angular/core';
import { tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'kh-manip-fraction-strips',
  templateUrl: TEMPLATE_PREFIX + 'manip-fraction-strips.component.html',
  styleUrls: ['./manip-fraction-strips.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManipFractionStripsComponent implements OnInit {
  public fractionParams: FractionParams = {
    targetLine: 0,
    showFraction: false
  };

  @Input() public sum: FractionStripsSum = {Numerator: 0, Denominator: 1};
  @Input() public strips: FractionStrip[] = [];
  @Input() public fraction: string = '0';
  @Input() public targetLine: number = 0;
  @Input() public showFraction: boolean = false;

  @Output() public sumChange = new EventEmitter<FractionStripsSum>();
  @Output() public fractionChange = new EventEmitter<string>();

  constructor(
    @Inject(MODAL_SERVICE_TOKEN) private readonly modalService: IModalService,
    private readonly cdRef: ChangeDetectorRef,
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) private readonly questionEventsService: IQuestionEventsService,
    private readonly changeDetectorRef: ChangeDetectorRef,) {
  }

  public ngOnInit(): void {
    this.questionEventsService.hideInputOnlyForManip.next(true);

    this.fractionParams = {
      targetLine: this.targetLine,
      showFraction: this.showFraction
    };

    this.resetStrips(this.fraction);

    this.changeDetectorRef.markForCheck();
  }

  public resetStrips(fraction?: string): void {
    const strips = [];

    if (fraction && fraction !== '0') {
      const [Numerator, Denominator] = fraction.trim().split('/').map((val) => parseInt(val, 10));
      const strip = FractionsList.find((fr) => fr.fraction === Denominator);
      if (strip) {
        strips.push(this.prepareStripe(strip));
      }
    }

    this.strips = strips;
    this.updateSum();
  };

  public addFractionStrip(): void {
    const modal = this.modalService.displayModal<FractionStripAddComponent>(FractionStripAddComponent, {}, 'md', true);

    modal.content?.selected.pipe(
      tap((strip: FractionStrip) => {
        if (100 / strip.fraction + 100 * this.sum.Numerator / this.sum.Denominator > 100) {

          this.modalService.displayModal<SimpleModalData>(ModalComponent, {
            title: 'Fraction bar is too full!',
            body: 'You can\'t add this strip otherwise it will be greater than 1 whole.',
            button1Text: 'OK'
          });

          return;
        }
        this.strips.push(this.prepareStripe(strip));

        this.updateSum();

        this.cdRef.markForCheck();
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  public drop(event: CdkDragDrop<FractionStrip[]>): void {
    moveItemInArray(this.strips, event.previousIndex, event.currentIndex);
  }

  private prepareStripe(strip: FractionStrip): FractionStrip {
    return {
      ...strip,
      width: (Math.floor(1000.0 / strip.fraction)) / 10,
      drag: true
    };
  }

  private getSum() {
    let sumNumerator = 0;
    let commonDenominator = 1;

    // Calculate the common denominator
    this.strips.forEach(strip => {
      // the .fraction is actually the denominator of the fraction and all fractions are assumed to be 1 / denominator.
      commonDenominator = this.lcm(commonDenominator, strip.fraction);
    });

    // Convert each fraction to the common denominator and add the numerators
    this.strips.forEach(strip => {
      // every fraction in the fraction strips manip is 1/n
      // so normally this would be numerator * (commonDenominator / strip.fraction);
      // but we'll just do it without the 1 * part
      sumNumerator += (commonDenominator / strip.fraction);
    });

    // Simplify the resulting fraction
    const gcd = this.gcd(sumNumerator, commonDenominator);
    const simplifiedNumerator = sumNumerator / gcd;
    const simplifiedDenominator = commonDenominator / gcd;

    return {
      Numerator: simplifiedNumerator,
      Denominator: simplifiedDenominator
    };
  }

  private updateSum(): void {
    this.sum = this.getSum();
    this.sumChange.emit(this.sum);

    this.cdRef.markForCheck();
    this.updateFraction();
  };

  private updateFraction(): void {
    this.fraction = this.sum.Numerator + '/' + this.sum.Denominator;
    if (this.fraction === '1/1') {
      this.fraction = '1';
    }

    this.fractionChange.emit(this.fraction);
  }

  // Helper function to calculate the greatest common divisor (GCD)
  gcd(a: number, b: number): number {
    return b === 0 ? a : this.gcd(b, a % b);
  }

  // Helper function to calculate the least common multiple (LCM)
  lcm(a: number, b: number): number {
    return (a * b) / this.gcd(a, b);
  }
}
