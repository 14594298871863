import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IEvent } from 'fabric/fabric-impl';
import { find } from 'lodash-es';
import { DynamicContentChild, OnDynamicData, OnDynamicMount } from 'ngx-dynamic-hooks';
import { defer, forkJoin, from, Observable, ReplaySubject } from 'rxjs';
import { IntInputRegionComponent } from '../int-input-region/int-input-region.component';
import { tap } from 'rxjs/operators';
import { FabricCanvas } from '../../../../services/fabric-canvas.service';
import { FabricConstantsService } from '../../../../services/fabric-constants.service';
import { Tokenize } from '../../../../utils/fabric-utils/fabric-draggable-utils';
import { FabricIntInputRectRegion } from '../../../../utils/fabric-utils/fabric-int-input-rect-region';
import { FabricRectRegion } from '../../../../utils/fabric-utils/fabric-rect-region';
import { FabricRegion } from '../../../../utils/fabric-utils/models/fabric-region';
import { FabricExtendedCanvas } from '../../../fabric/angular-fabric';
import { AngularFabricService } from '../../../fabric/angular-fabric.service';
import { RegionComponent } from '../region/region.component';
import { Inject } from '@angular/core';
import { QUESTION_EVENTS_SERVICE_TOKEN, IQuestionEventsService } from '../../../../services/IQuestionEvents';
import { TEMPLATE_PREFIX } from '../../../../environments/locale-config';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FabricCanvas, AngularFabricService],
  selector: 'kh-manip-clickable',
  styleUrls: ['./manip-clickable.component.less'],
  templateUrl: TEMPLATE_PREFIX + 'manip-clickable.component.html'
})
export class ManipClickableComponent implements OnInit, AfterViewInit, OnDynamicMount {
  @ViewChild('canvas') public canvasEl?: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasContainer') public canvasContainerElement?: ElementRef<HTMLDivElement>;
  public regions: FabricRegion[] = [];
  public regionsList: FabricRegion[] = [];
  public manipTextList: fabric.Object[] = [];
  public answer: any[] = [];
  public postAnswer: string | null = null;
  public Image: any;
  public Dot: any;
  public DefaultImg: any;
  public keyboards: any[] = [];

  @Input() public width = 0;
  @Input() public height = 0;
  @Input() public backgroundImage = '';
  @Input() public maxObjects = 0;
  @Input() public dotImage = '';
  @Input() public reportMode = false;
  @Input() public value: string | {answer: string; state: string} = '';
  @Input() public answerState = '';
  @Input() public sampleAnswer = '';
  @Input() public defaultImg = '';

  @Output() public valueChange = new EventEmitter<{ answer: string, state: string }>();

  private canvas!: FabricExtendedCanvas;
  private canRender$ = new ReplaySubject<void>(1);
  public State: any = {};
  public selected: string[] = [];

  public constructor(
    private readonly fabricConstants: FabricConstantsService,
    private readonly angularFabric: AngularFabricService,
    private readonly hostElement: ElementRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService) {
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    this.resizeWorks();
  }

  public resizeWorks(): void {
    if (this.canvasContainerElement && this.canvas) {
      const currentWidth = this.canvas.getWidth();
      if (currentWidth > 0) {
        const newWidth = this.canvasContainerElement.nativeElement.getBoundingClientRect().width;
        if (newWidth && newWidth <= this.width) {
          const scaleFactor = newWidth / currentWidth;
          this.angularFabric.setCanvasSize(currentWidth * scaleFactor, this.canvas.getHeight() * scaleFactor);
          this.canvas.setZoom(this.canvas.getZoom() * scaleFactor);
        }
      }
    }
  }

  public reset(): void {
    this.regions.forEach(region => region.reset());
    this.postAnswer = null;
  }

  public submitAnswer(): void {
    this.postAnswer = this.answer.join(', ');
  }

  public ngOnInit(): void {
    this.canRender$
      .pipe(
        tap(() => this.render()),
        untilDestroyed(this)
      )
      .subscribe();

    this.questionEventsService.hideInputOnlyForManip.next(true);
    this.changeDetectorRef.markForCheck();
  }

  public ngAfterViewInit(): void {
    this.initializeFabric();
    this.canvas = this.angularFabric.getCanvas();

    this.canvas.on('region:onChange', (e: IEvent) => {
      const target = e.target;
      const rIdx = this.regions.findIndex(r => r instanceof FabricIntInputRectRegion && target === r);
      if (rIdx >= 0) {
        const value = (target as FabricIntInputRectRegion).getText();
        if (value) {
          this.State[rIdx] = {
            active: true,
            value
          }
        } else {
          delete this.State[rIdx]
        }
      }

      let answer: any;
      if (this.getKeypadAnswer().length > 0) {
        answer = { answer: '[' + this.getKeypadAnswer().concat(this.answer)
          .join(',') + ']', state: JSON.stringify(this.State) }
      } else {
        answer = { answer: '[' + this.answer.join(',') + ']', state: JSON.stringify(this.State) }
      }
      this.valueChange.emit(answer);
    })

    this.resizeWorks();
    this.canvas.on('region:choosen', (e: IEvent) => {

      const target = (e.target as Record<string, any>);

      const rIdx = this.regions.findIndex(r => r instanceof FabricRectRegion && target === r);
      if (rIdx >= 0) {
        if (target.selected) {
          this.State[rIdx] = {
            active: true
          }
        } else {
          delete this.State[rIdx]
        }
      }

      const itemOrder = target.itemOrder || target._id;

      const region = e.target as FabricRectRegion;
      if (!region.ignoreInScore) {

        if (target.selected && this.maxObjects === 1) {
          this.answer[0] = itemOrder;
          target.changeChoice(this.regions);
        }
        else if (target.selected) {
          this.answer.push(itemOrder);
        }
        else {
          const answerIndex = this.answer.indexOf(itemOrder);
          if (answerIndex >= 0) {
            this.answer.splice(answerIndex, 1);
          }
        }

        if (this.maxObjects > 1 && this.answer.length >= this.maxObjects) {

          this.regions.forEach((region: any) => {
            if (region.type === 'region') {
              region.setEnabled(!!region.selected)
            }
          });
        }
        else {
          this.regions.forEach((region: any) => {
            if (region.type === 'region') {
              region.setEnabled(true)
            }
          });
        }
        let answer: any;

        if (this.getKeypadAnswer().length > 0) {
          answer = { answer: '[' + this.getKeypadAnswer().concat(this.answer)
            .join(',') + ']', state: JSON.stringify(this.State) }
        } else {
          answer = { answer: '[' + this.answer.join(',') + ']', state: JSON.stringify(this.State) }
        }
        this.valueChange.emit(answer);
      }
    });
    this.canvas.on('keyboard:onOpen', (e: IEvent) => this.onKeyboardOpenHandler(e))
  }

  public onKeyboardOpenHandler(e: IEvent): void {
    for (const i in this.keyboards) {
      const keyboard = this.keyboards[i];
      if (keyboard !== e.target) {
        keyboard.close();
      }
    }
  }

  public onDynamicMount(data: OnDynamicData): void {
    const contentChildren: DynamicContentChild[] = data.contentChildren ?? [];

    this.regionsList = this.getRegions(contentChildren);

    const Loaders = [];

    Loaders.push(this.loadImg(this.backgroundImage, 'Image'));
    const regionImageLoader = this.regionsList.map(region => region.preload());
    Loaders.push(this.loadImg(this.dotImage, 'Dot'));
    if (this.defaultImg) {
      Loaders.push(this.loadImg(this.defaultImg, 'DefaultImg'));
    }

    forkJoin([...Loaders, ...regionImageLoader])
      .pipe(
        tap(() => this.canRender$.next()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public getKeypadAnswer() {
    const answer: string[] = [];
    this.regions.forEach(region => {
      if (region.type === 'intInputRegion') {
        const intRegion = region as FabricIntInputRectRegion;
        if (intRegion.getText()) {
          answer.push(intRegion.itemOrder + ':' + intRegion.getText());
        }
      }
    });

    return answer;
  }

  private getRegions(contentChildren: DynamicContentChild[], list: (FabricRegion)[] = []): (FabricRegion)[] {
    contentChildren.forEach((item) => {
      const region = item.componentRef.instance;
      if (region instanceof RegionComponent || region instanceof IntInputRegionComponent) {
        list.push(region.getRegion(this.height));
        list.forEach((region) => {
          if (region.type === 'intInputRegion') {
            this.keyboards.push(region);
          }
        })
      }

      if (item.contentChildren.length) {
        this.getRegions(item.contentChildren, list);
      }
    });

    return list;
  }

  private initializeFabric(): void {
    const options = {
      JSONExportProperties: this.fabricConstants.JSONExportProperties,
      shapeDefaults: this.fabricConstants.shapeDefaults,
      textDefaults: this.fabricConstants.textDefaults
    };

    const canvas = this.canvasEl?.nativeElement as HTMLCanvasElement;

    this.angularFabric.initialize(options, canvas);

    this.angularFabric.setCanvasSize(this.width, this.height);
  }

  private loadImg(img: string, key: string): Observable<any> {
    return defer(() =>
      from(this.angularFabric.loadImageURL(img).then(Image => {
        // @ts-ignore
        this[key] = Image;
        Image.selectable = false;
        this.changeDetectorRef.markForCheck();
      }))
    );
  }
  
  /**
   * Set the regions variable with all the fabric regions in this manipulative.
   * This is called in the render function
   */
  private setupRenderRegions(): void {
    // Iterate over regionsList and process each region
    this.regions = this.regionsList.map((region: any) => {
      if (region instanceof FabricIntInputRectRegion) {
        // Add region to the canvas if it is an instance of FabricIntInputRectRegion
        this.canvas.add(region);
      } else {
        // Make region clickable and set the Dot property
        region.setClickable(true);
        region.setDot(this.Dot);
        region.left = region.left - region.width / 2;
        if (this.DefaultImg) {
          region.setDefaultImg(this.DefaultImg);
        }
        // Add region to the canvas
        this.canvas.add(region);
      }
  
      return region;
    });
  }

  /**
   * If this is in report mode we want to disable click inputs on the canvas and the fabric objects
   */
  private disableInputsForReportMode(): void {
    if (this.reportMode) {
      // Disable selection and events for all objects in the canvas
      this.canvas.getObjects().forEach(obj => {
        obj.selectable = false;
        obj.evented = false;
      });
  
      // Set the cursor to default in report mode
      this.canvas.hoverCursor = 'default';
    }
  }

  /**
   * Handle any resizing that might happen
   */
  private handleRenderResize(): void {
    // Deselect any active object in the Angular Fabric component
    this.angularFabric.deselectActiveObject();
  
    let scale = 1;
    const canvasWidth = this.canvas.width ?? 0;
  
    // Determine the scale based on the canvas width and parent container's width
    const parent = this.hostElement.nativeElement.closest('ngx-dynamic-hooks');
    if (canvasWidth > parent.clientWidth * 0.9 || this.reportMode) {
      scale = Math.min(this.width, parent.clientWidth) / this.width;
    }
  
    scale ||= 1; // Set scale to 1 if it is still falsy
    this.angularFabric.setCanvasSize(this.width * scale, this.height * scale); // Set the canvas size based on the scale
    this.canvas?.setZoom(scale); // Set the zoom level of the canvas
    this.resizeWorks(); // Perform necessary work for resizing
  }
  
  /**
   * The state the question should display in isn't as simple as it should be. It can come from answerState, sampleAnswer, or value
   * To make matters worse the format for these isn't the same. This funciton tries to wrangle all that together and give you a single
   * state to display in the format of {[arrayPosition]: string}. So, for example if a clickable had 4 of 10 items selected it might be like
   * { "0": "1", "4": "1", "5": 1, "6": 1}. In this case the 0th, 4th, 5th, and 6th items in the array of clickable manip regions should be
   * selected.
   * @returns 
   */
  private getRegionsToSelect(): FabricRegion[] {
    const inputAnswer = this.sampleAnswer ? this.sampleAnswer : this.answerState;
    const foundRegions: FabricRegion[]= [];

    // if input answer is set we want to strip the keys from it and select those fabric regions
    if (inputAnswer) { 
      // input answer is formatted as {"1": {"active": true}, "2": {"active": true}}
      const answerKeys = Object.keys(JSON.parse(inputAnswer));

      // for each key we just get the region
      answerKeys.forEach((key: any) => {
        foundRegions.push(this.regions[Number(key)]);
      });

    } else if (this.value) {
      // if instead value is set (it has more than one format :( ) we want to find the first region that matches the answer in the array
      let selected: string[] = [];
      const valueToTokenize: string = typeof this.value === 'string' ? this.value : this.value.answer;

      // remove any square brackets
      const selectedStr = valueToTokenize.replace(/^\[|\]$/gi, '');
      selected = Tokenize(selectedStr); //selected should now be an array like [1,1,1] or [a,b,c]

      selected.forEach(item => {

        // if an item is a FabricIntInputRectRegion, instead of adding it for selection we set its value
        if (item.includes(':')) {
          const splitVal = item.split(':');
          if (splitVal.length > 1) {
            const key = splitVal[0];
            const val = splitVal[1];

            const rectRegion: FabricIntInputRectRegion = find(this.regions, region => region instanceof FabricIntInputRectRegion && region.itemOrder?.toString() === key && !foundRegions.includes(region)) as FabricIntInputRectRegion;
            rectRegion.setText(val);
          }
        } else {
          const foundRegion = find(this.regions, region => region instanceof FabricRectRegion && region.itemOrder === item && !foundRegions.includes(region));
         
          if (foundRegion) {
            foundRegions.push(foundRegion);
          }
        }        
       
       
      });
    }

    return foundRegions;
  }


  private render(): void {
    this.canvas.add(this.Image);
    this.canvas.add(...this.manipTextList);
    this.setupRenderRegions();

    const selectedRegions = this.getRegionsToSelect();
  
    if (selectedRegions) {
      // Create a dummy event object for mouse down event
      const dummyClickEvent = {e: { type: 'mousedown' }};
  
      // Trigger mouse down event for each item in the state
      selectedRegions.forEach(region => {
        (region as FabricRectRegion).mousedown();
      });
    }

    // it's important to do this AFTER we render the render state.
    this.disableInputsForReportMode();
    this.handleRenderResize();

    // Mark the component for change detection
    this.changeDetectorRef.markForCheck();
  }
}
