<div class="container">
   <svg #lowerRenderEl class="lowerRenderEl" id="lowerRender">
      <defs></defs>
   </svg>
   <div #upperRenderEl class="upperRenderEl">
      <div class="bg-secondary rounded-pill d-flex px-2 py-1">
         <button class="border-0 p-0 mx-2 my-0 bg-transparent">
            <svg width="35" height="37.7202" viewBox="0 0 35 37.720198" style="display: block; pointer-events: all">
               <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1"
                  transform="translate(-130.4307,-20.0729)">
                  <g id="zoomIn" transform="matrix(1.3006968,0,0,1.3006966,-39.220102,-6.0358516)">
                     <rect
                        style="fill:#d3e7c9;fill-opacity:0;stroke:none;stroke-width:0.988356;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                        id="rect3743-4" width="26.908653" height="29" x="130.43071" y="20.072899" rx="1.4268409"
                        ry="0" />
                     <circle
                        style="fill:none;fill-opacity:1;stroke:#000000;stroke-width:2.47504;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                        id="path1401-8" cx="140.51868" cy="31.456528" r="8.1919394" />
                     <path
                        style="fill:none;stroke:#000000;stroke-width:1.90387;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                        d="m 136.27788,31.456535 h 8.48159" id="path1700-43" />
                     <path
                        style="fill:none;stroke:#000000;stroke-width:1.90387;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                        d="m 140.51867,27.215736 v 8.481596" id="path1700-1-1" />
                     <path
                        style="fill:none;stroke:#000000;stroke-width:3.0462;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                        d="m 147.37961,37.817451 7.77816,7.77817" id="path1700-1-6-7" />
                  </g>
               </g>
            </svg>
         </button>
         <button class="border-0 p-0 mx-2 my-0 bg-transparent">
            <svg width="35" height="37.720211" viewBox="0 0 35 37.720213" style="display: block; pointer-events: all">
               <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1"
                  transform="translate(-255.12163,-128.84468)">
                  <g id="zoomOut" transform="matrix(1.4000003,0,0,1.4000003,-102.04873,-51.537914)">
                     <rect
                        style="fill:#d3e7c9;fill-opacity:0;stroke:none;stroke-width:0.918251;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                        id="rect3743-9-1-3" width="24.999994" height="26.943001" x="255.12163" y="128.84468"
                        rx="1.3256336" ry="0" />
                     <circle
                        style="fill:none;fill-opacity:1;stroke:#000000;stroke-width:2.29948;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                        id="path1401-3-3-3" cx="264.49405" cy="139.42087" r="7.6108766" />
                     <path
                        style="fill:none;stroke:#000000;stroke-width:1.76883;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                        d="m 260.55404,139.42086 h 7.87998" id="path1700-6-3-9" />
                     <path
                        style="fill:none;stroke:#000000;stroke-width:2.83013;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                        d="m 270.86831,145.33059 7.22645,7.22646" id="path1700-1-6-8-6-4" />
                  </g>
               </g>
            </svg>
         </button>
         <button class="border-0 p-0 mx-2 my-0 bg-transparent">
            <svg width="35" height="35.134735" viewBox="0 0 35 35.134736" style="display: block; pointer-events: all">
               <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1"
                  transform="translate(-130.8566,-150.68811)">
                  <g id="center" transform="matrix(1.3040395,0,0,1.3040395,-39.785572,-45.815136)">
                     <g id="g4778" transform="matrix(1.0754748,0,0,1.0754748,-266.74776,-111.1975)">
                        <g id="g4415">
                           <path
                              style="fill:none;stroke:#000000;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                              d="m 370.95123,253.07268 v -8.31571 h 8.09312" id="path2170-4" />
                           <path
                              style="fill:none;stroke:#000000;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                              d="m 393.40735,253.07268 v -8.31571 h -8.09312" id="path2170-7-54" />
                           <path
                              style="fill:none;stroke:#000000;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                              d="m 370.95123,258.99345 v 8.31571 h 8.09312" id="path2170-6-5" />
                           <path
                              style="fill:none;stroke:#000000;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                              d="m 393.40735,258.99345 v 8.31571 h -8.09312" id="path2170-7-5-8" />
                        </g>
                        <circle
                           style="fill:#000000;fill-opacity:1;stroke:#000000;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                           id="path4186-9" cx="382.17929" cy="256.03305" r="4" />
                     </g>
                     <rect
                        style="opacity:0;fill:#000000;fill-opacity:1;stroke:#000000;stroke-width:1.35461;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                        id="rect4594-5" width="24.456781" height="25.532257" x="132.04803" y="151.39348" />
                  </g>
               </g>
            </svg>
         </button>
      </div>
   </div>
</div>