import { Injectable } from '@angular/core';
import { KhAudio } from 'app/core/audio/kh-audio';
import { MusicService } from 'app/shared/services/music.service';
import { StudentSchoolTimeCheckerService } from 'app/student/services/time-checker/student-school-time-checker.service';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudentMusicService {
  private readonly preloadedAudios: string[] = ['half-cheers.mp3', 'full-cheers.mp3', 'bot-intro-SFX.mp3'];

  public constructor(
    private readonly timeCheckerService: StudentSchoolTimeCheckerService,
    private readonly musicService: MusicService) {
    this.musicService.preloadAudio(this.preloadedAudios);
  }

  public playSound(path: string, volume?: number): void {
    this.playSoundObservable(path, volume)
      .pipe(first())
      .subscribe();
  }

  public playSoundObservable(path: string, volume?: number): Observable<KhAudio> {
    return this.timeCheckerService.isSchoolHoursObservable()
      .pipe(
        map(isSchoolHours => {
          return !isSchoolHours
            ? this.musicService.playSound(path, volume)
            : new KhAudio('');
        })
      );
  }

  public playSoundInfinitelyObservable(path: string, volume?: number, isAllowSchoolHours = false): Observable<KhAudio> {
    return this.timeCheckerService.isSchoolHoursObservable()
      .pipe(
        map(isSchoolHours => {
          return !isSchoolHours || isAllowSchoolHours
            ? this.musicService.playSoundInfinitely(path, volume)
            : new KhAudio('');
        })
      );
  }
}
