/* eslint-disable max-len */
import { gsap } from 'gsap/all';
import { PuzzleAttempt, PuzzleState } from './puzzle.model';
import { PuzzleFocusElement } from './PuzzleFocusElement';
import { PuzzleHandPointer } from './PuzzleHandPointer';
import { ConfettiPiece } from '../spotlight/Confetti';
import { SvgRenderer } from '../svg-components/SvgRenderer';
import { KHPoint } from '../pixi-puzzle-framework/PuzzleEngine/KHPoint';


export abstract class PuzzleBase<U extends PuzzleAttempt = PuzzleAttempt, T extends PuzzleState<U> = PuzzleState<U>> {
  public puzzleState: T;
  protected initialState: T;
  protected confettiColors = ['#f89523ff', '#ff5353ff', '#21bbedff', '#fde855ff', '#9760a7ff', '#85c668ff'];

  public readonly svgns = 'http://www.w3.org/2000/svg';
  protected puzzleConnectedToOverlay = false;
  protected puzzleUiOverlay: HTMLDivElement | undefined;
  protected puzzleUiFixedOverlay: HTMLDivElement | undefined;
  protected puzzleUiCameraOverlay: HTMLDivElement | undefined;
  private resizeObserver: ResizeObserver | undefined;
  private targetContainer: HTMLElement | SVGElement | undefined;
  private displayedPointerHand: PuzzleHandPointer | null = null;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected svgRenderer!: SvgRenderer;
  public onPuzzleAnimationEnd: null | (() => void) = null;
  public onAnswerUpdate?: (ans: string, state: T) => void;
  public onPlayEnable: null | ((enabled: boolean) => void) = null;

  public constructor(initialState: T, svgElement?: HTMLElement | SVGElement) {
    this.initialState = { ...initialState };
    this.puzzleState = initialState;

    this.targetContainer = svgElement

    if (svgElement instanceof SVGElement) {
      this.svgRenderer = new SvgRenderer(svgElement)
      this.createUiOverlay(svgElement);
      svgElement.classList.add('quicksand-bold');
    }
  }

  public focusElement(el: HTMLElement | SVGElement): void {
    const container = this.getPuzzleOverlayElement();

    if (container) {
      new PuzzleFocusElement(el, container);
    }
  }

  public pointerHand(el: HTMLElement, parent: HTMLDivElement, position: KHPoint): void {
    if (!this.displayedPointerHand) {
      this.displayedPointerHand = new PuzzleHandPointer(parent, el, position, () => {
        this.displayedPointerHand = null;
      });
    }
  }

  public abstract onPuzzlePlay(): void;
  public abstract onPuzzleTryAgain(): void;
  public abstract onPuzzleHelp(): void;

  public destroy(): void {
    gsap.killTweensOf('*');

    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  protected dropConfetti(): void {
    ConfettiPiece.dropConfetti(this.svgRenderer.svg.node, this.confettiColors);
  }

  protected setPuzzleState(newState: T) {
    this.puzzleState = newState;
  }

  public onPuzzleOverlayConnect(): void {
    this.puzzleConnectedToOverlay = true;
  }

  /**
     * Called to reset the entire puzzle back to the initial state. Will discard any attempt history.
     */
  public onPuzzleReset(): void {
    this.puzzleState = this.initialState;
  }

  public getPuzzleOverlayElement(): HTMLDivElement | undefined {
    if (this.svgRenderer && this.svgRenderer.svg) {
      return this.svgRenderer.svg.node.closest('.puzzleContainer')?.parentElement?.getElementsByClassName('puzzleOverlay')[0] as HTMLDivElement;
    }
    else if (this.targetContainer) {
      let el = this.targetContainer;

      while (el) {
        if (el.classList.contains('puzzleContainer')) {

          const children = el.children;
          for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (child.classList.contains('puzzleOverlay')) {
              return child as HTMLDivElement;
            }
          }

          return undefined;
        }

        el = el.parentElement as any;
      }
    }

    return undefined; // Not found
  }

  public recordAnswerAttempt(isCorrect: boolean): void {
    const curNumAttepts = this.puzzleState.attempts.length;
    const newAttempt: PuzzleAttempt = {
      attemptNumber: curNumAttepts + 1,
      correct: isCorrect
    };

    this.puzzleState.attempts.push(newAttempt as U);

    const ans = this.getSessionString();

    if (this.onAnswerUpdate) {
      this.onAnswerUpdate(ans, this.puzzleState);
    }
  }

  public getIncorrectIndex(): number {
    return this.puzzleState.attempts.findIndex(attempt => !attempt.correct);
  }

  protected getSessionString(): string {
    const firstCorrectAttempt = this.puzzleState.attempts.findIndex(attempt => attempt.correct) + 1;
    const isAnyAttemptCorrect = firstCorrectAttempt > 0;

    let text = '';

    if (isAnyAttemptCorrect) {
      if (firstCorrectAttempt === 1) {
        text = 'First Try';
      } else if (firstCorrectAttempt === 2) {
        text = 'Second Try';
      } else if (firstCorrectAttempt === 3) {
        text = 'Third Try';
      } else if (firstCorrectAttempt === 4) {
        text = 'Fourth Try';
      } else {
        text = 'More Than Four Tries';
      }
    } else {
      text = 'No Correct Tries';
    }

    return text;
  }

  /**
   * This is the default animation to re-use for any item requiring user attention. Just pass it in the HTML element to animate and it will do it.
   * However, some puzzles might want their own animations based on how they're designed (eg, the spotlight puzzle will wiggle instead).
   *
   * As such this animation function isn't defaulted into the puzzle help call but is provided as a convineince function for puzzles to call if needed
   * @param element
   */
  protected animateUserAttention(element: HTMLElement | SVGElement): void {
    element.style.animation = 'bounceAnimation 0.5s ease-in-out';
    element.addEventListener('animationend', () => {
      element.style.animation = '';
    });
  }

  private createUiOverlay(svgTarget: HTMLElement | SVGElement): void {
    this.puzzleUiFixedOverlay = document.createElement('div');
    this.puzzleUiCameraOverlay = document.createElement('div');
    this.puzzleUiOverlay = document.createElement('div');

    this.puzzleUiOverlay.appendChild(this.puzzleUiCameraOverlay);
    this.puzzleUiOverlay.appendChild(this.puzzleUiFixedOverlay);
    svgTarget.parentElement?.insertBefore(this.puzzleUiOverlay, svgTarget);

    let cameraOriginalSize = {x: 1280, y: 720};

    if (this.svgRenderer?.camera) {
      cameraOriginalSize = this.svgRenderer.camera.originalSize;
    }

    this.puzzleUiOverlay.style.position = 'absolute';
    this.puzzleUiOverlay.style.overflow = 'hidden';
    this.puzzleUiOverlay.style.width = `${cameraOriginalSize.x}px`;
    this.puzzleUiOverlay.style.height = `${cameraOriginalSize.y}px`;
    this.puzzleUiOverlay.style.pointerEvents = 'none';
    this.puzzleUiOverlay.style.transformOrigin = 'top left';
    this.puzzleUiOverlay.style.overflow = 'hidden';

    this.puzzleUiFixedOverlay.style.position = 'absolute';
    this.puzzleUiFixedOverlay.style.overflow = 'hidden';
    this.puzzleUiFixedOverlay.style.width = `${cameraOriginalSize.x}px`;
    this.puzzleUiFixedOverlay.style.height = `${cameraOriginalSize.y}px`;
    this.puzzleUiFixedOverlay.style.pointerEvents = 'none';
    this.puzzleUiFixedOverlay.style.transformOrigin = 'top left';
    this.puzzleUiFixedOverlay.style.overflow = 'hidden';

    this.puzzleUiCameraOverlay.style.position = 'absolute';
    this.puzzleUiCameraOverlay.style.overflow = 'hidden';
    this.puzzleUiCameraOverlay.style.width = `${cameraOriginalSize.x}px`;
    this.puzzleUiCameraOverlay.style.height = `${cameraOriginalSize.y}px`;
    this.puzzleUiCameraOverlay.style.pointerEvents = 'none';
    this.puzzleUiCameraOverlay.style.transformOrigin = 'top left';
    this.puzzleUiCameraOverlay.style.overflow = 'hidden';

    this.svgRenderer.camera.addOnScaleChangeListener(this.calculateUiSizes.bind(this));
    this.svgRenderer.camera.addOnPositionChangeListener(this.calculateUiSizes.bind(this));

    this.resizeObserver = new ResizeObserver(this.calculateUiSizes.bind(this));
    this.resizeObserver.observe(svgTarget);
    // svgTarget.addEventListener('resize', this.calculateUiSizes.bind(this));
  }

  private calculateUiSizes(): void {
    const og = this.svgRenderer.camera.originalSize;
    const domScale = this.svgRenderer.element.clientWidth / og.x;
    const scaleString = `scale(${domScale},${domScale})`;

    if (this.puzzleUiOverlay) {
      this.puzzleUiOverlay.style.width = `${og.x * domScale}px`;
      this.puzzleUiOverlay.style.height = `${og.y * domScale}px`;
    }

    if (this.puzzleUiFixedOverlay) {
      this.puzzleUiFixedOverlay.style.transform = scaleString;
    }

    if (this.puzzleUiCameraOverlay) {
      const pos = this.svgRenderer.camera.getPosition();
      const camScale = 1 / this.svgRenderer.camera.getScale();
      const totalScale = camScale * domScale;
      this.puzzleUiCameraOverlay.style.transform = `translate(${pos.x * totalScale * -1}px, ${pos.y * totalScale * -1}px) scale(${totalScale}) `;
    }
  }
}
