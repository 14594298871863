import {
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import { IScorable, ScorableData } from "../kh-manip-scorable/IScorable";
import {
  DynamicContentChild,
  OnDynamicData,
  OnDynamicMount,
} from "ngx-dynamic-hooks";
import { KhManipulativeClickableRegionComponent } from "../kh-manip-scorable-region/kh-manip-scorable-region.component";
import { KhManipScorableIntInput } from "../kh-manip-scorable-int-input/kh-manip-scorable-int-input.component";
import { Inject } from '@angular/core';
import { QUESTION_EVENTS_SERVICE_TOKEN, IQuestionEventsService } from "../../../../services/IQuestionEvents";

@Component({
  selector: "kh-manipulative-clickable",
  templateUrl: "kh-manip-scorable-clickable.component.html",
  styleUrls: ["kh-manip-scorable-clickable.component.less"],
})
export class KhManipulativeClickableComponent implements IScorable, OnDynamicMount {
  @Input() public onChangeCallback: undefined | ((data: ScorableData) => void); // callback
  @Input("frame") public frame = "0 0 100 100";
  @Input("key") public key: string = "";
  @Input("backgroundImage") public backgroundImage: string = "";
  @Input("clickedImage") public clickedImage: string = "";
  @Input("max-objects") public maxObjects!: number;
  @ViewChild("kh-manipulative-region") public regionElement!: ElementRef;
  public scorableData: ScorableData = { answer: {}, state: {} };
  public contentChild!: DynamicContentChild[];
  public regionsData: ScorableData[] = [];
  public box = { left: 0, top: 0, width: 0, height: 0, };

  public ngOnInit() {
    const [left, top, width, height] = this.frame.split(" ").map(Number);
    this.box = { left, top, width, height };
  }

  public constructor(@Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService) {
    this.questionEventsService.hideInputOnlyForManip.next(true);
  }

  public onDynamicMount(data: OnDynamicData): void {
    const contentChildren: DynamicContentChild[] = data.contentChildren ?? [];
    this.contentChild = contentChildren;

    const countMap = this.getCountVariableMap();

    contentChildren.forEach((c, index) => {
      if (c.componentRef.instance instanceof KhManipulativeClickableRegionComponent) {
        const curComponent = c.componentRef.instance;
        curComponent.clickedImage = curComponent.clickedImage ? curComponent.clickedImage : this.clickedImage;
        curComponent.value = this.scorableData;
        curComponent.index = index;

        if (this.scorableData) {
          const countName = curComponent.countVarName;

          if (countMap.has(countName)) {
            const countVal = countMap.get(countName) || 0;

            curComponent.selected = countVal > 0;
            countMap.set(countName, countVal - 1);
          } else {
            curComponent.selected = this.scorableData.state[curComponent.key] ? true : false;
          }

        }

        curComponent.clickCallback = this.onClickChangeValue.bind(this);
      }
    });
  }

  private getCountVariableMap(): Map<string, number> {
    const countVariableMap = new Map<string, number>();

    this.contentChild.forEach((c) => {
      const curComponent = c.componentRef.instance;
      if (curComponent instanceof KhManipulativeClickableRegionComponent && curComponent.countVarName) {
        countVariableMap.set(curComponent.countVarName, this.scorableData.state[curComponent.countVarName] ? parseInt(this.scorableData.state[curComponent.countVarName]) : 0);
      }
    });

    return countVariableMap;
  }

  public onClickChangeValue(val: any): void {
    this.setAnswerAndState(val);

    if (this.onChangeCallback) {
      this.onChangeCallback(this.getScorableData());
    }
  }

  public onChangeValue(val: any): void {
    this.setAnswerAndState(val);

    if (this.onChangeCallback) {
      this.onChangeCallback(this.getScorableData());
    }
  }

  public getScorableData(): ScorableData {
    const data: ScorableData = {
      answer: this.scorableData.answer,
      state: this.scorableData.state,
    };

    return data;
  }

  public setScorableData(scorableDatata: ScorableData): void {
    this.scorableData = scorableDatata;
  }

  private setAnswerAndState(data: ScorableData): void {
    const currentRegionAnswerKey = Object.keys(data.answer)[0];
    const currentRegionStateKey = Object.keys(data.state)[0];
    const isRegionActive = Object.values(data.state)[0] === '1';
    const currentRegionContent = this.contentChild.find(c => c.componentRef.instance.key.toString() === currentRegionStateKey)?.componentRef.instance;

    if (isRegionActive) {
      if (currentRegionContent?.ignoreInScore?.toString() !== 'true') {

        // if this has a countVarName we incremenet that instead of using the key but only in the answer data
        if (currentRegionContent?.countVarName) {
            const curVal = this.scorableData.answer[currentRegionContent.countVarName];
            this.scorableData.answer[currentRegionContent.countVarName] = curVal ? (parseInt(curVal) + 1).toString() : '1';
        } else {
          this.scorableData.answer[currentRegionAnswerKey] = '1';
        }
      }
      else {
        delete this.scorableData.answer[currentRegionAnswerKey];
      }

      this.scorableData.state[currentRegionStateKey] = '1';
    }

    if (!isRegionActive) {
      if (currentRegionContent?.countVarName) {
        const curVal = this.scorableData.answer[currentRegionContent.countVarName];
        this.scorableData.answer[currentRegionContent.countVarName] = (parseInt(curVal) - 1).toString();
      } else {
        delete this.scorableData.answer[currentRegionAnswerKey];
      }

      delete this.scorableData.state[currentRegionStateKey];
    }
    
  }
}
