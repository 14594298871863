<input 
  #stringInput
  class="position-absolute"
  (focus)="setStroke()"
  (blur)="removeStroke()"
  [(ngModel)]="value"
  [type]="type"
  [name]="name"
  onwheel="blur()"
  [attr.maxlength]="maxLength"
  (ngModelChange)="onChangeValue($event)"
  [ngStyle]="{
    'font-family': fontFamily, 
    'font-size.px': fontSize, 
    'top.px': box.top, 
    'width.px': box.width, 
    'height.px': box.height, 
    'left.px': box.left,
    'background-color': backgroundColor,
    'border-color': borderColor,
    'border-width.px': borderStrokeSize,
    'border-radius.px': borderRadius,
    'text-align': textAlignment,
    'font-style': fontStyle,
    'font-weight': fontWeight
  }"
/>
