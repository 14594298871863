import { SvgRenderElement, SvgRenderElementType } from './SvgRenderElement';
import { SvgRenderer } from './SvgRenderer';

export type SvgCircleConfig = SvgRenderElementType & {
    x: number;
    y: number;
    r: number;
    stroke?: string;
    strokeWidth?: number;
    fill?: string;
    opacity?: number;
}

const svgCircleConfigDefault: Partial<SvgCircleConfig> = {
  fill: 'black',
  maintainScale: false,
  stroke: 'black',
  strokeWidth: 2,
  opacity: 1
}

export class SvgCircle extends SvgRenderElement<SvgCircleConfig> {
  public constructor(renderer: SvgRenderer, parent: SVGElement, config: SvgCircleConfig) {
    super(renderer, parent, config, svgCircleConfigDefault);
  }

  public createElement(): void {
    this.element = document.createElementNS(SvgRenderer.svgns, 'circle');
  }

  protected onMaintainScale(newScale: number): void {
    const newRadius = this.getConfigValue('r') * newScale;
    this.element.setAttribute('r', newRadius.toString());
  }

  public applyConfigProperties(): void {
    this.setElementAttribute('cx', 'x');
    this.setElementAttribute('cy', 'y');
    this.setElementAttribute('r', 'r');
    this.setElementAttribute('fill', 'fill');
    this.setElementAttribute('stroke', 'stroke');
    this.setElementAttribute('stroke-width', 'strokeWidth');
    this.setElementAttribute('opacity', 'opacity');

    if(this.config.maintainScale) {
      this.onMaintainScale(this.renderer.camera.getScale());
    }
  }
}
