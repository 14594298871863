import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IScorable, ScorableData } from '../kh-manip-scorable/IScorable';
import { applyFrameToElement } from '../../../../utils/utils';

@Component({
  selector: 'kh-manipulative-dropdown',
  template: '<select #selectElement>'
})
export class KhManipulativeDropdown implements AfterViewInit, IScorable {
  public onChangeCallback: undefined | ((data: ScorableData) => void); // callback
  @ViewChild('selectElement') protected element: ElementRef<HTMLSelectElement> | undefined;
  @Input('frame') protected frame = '0 0 100 100';
  @Input('key') protected key = '';
  @Input('value') protected value = '';
  @Input('font-size') protected fontSize = 12;
  @Input('font-family') protected fontFamily = 'arial';
  @Input('strings') protected strings: string = '';

  public ngAfterViewInit(): void {
    if (this.element?.nativeElement) {
      this.element.nativeElement.style.fontFamily = this.fontFamily;
      this.element.nativeElement.style.fontSize = `${this.fontSize}px`;
    }

    this.buildOptions();

    if (this.element?.nativeElement) {
      applyFrameToElement(this.element?.nativeElement, this.frame);
      this.element.nativeElement.addEventListener('change', () => this.onChange());
      this.element.nativeElement.value = this.value;
    }
  }

  private buildOptions(): void {
    const options = this.strings.split(',');

    options.forEach(s => {
      const o = document.createElement('option');
      o.value = s;
      o.innerHTML = s;

      this.element?.nativeElement.appendChild(o);
    });
  }

  public getScorableData(): ScorableData {
    const data: ScorableData = {
      answer: {[this.key]: this.value},
      state: {[this.key]: this.value}
    };

    return data;
  }

  public setScorableData(data: ScorableData): void {
    const keys = Object.keys(data.state);

    for(let i = 0; i < keys.length; i++) {
      if (keys[i] === this.key) {
        this.value = data.state[keys[i]];

        if (this.element) {
          this.element.nativeElement.value = this.value;
        }

        return; // the dropdown only has one kvp so just end the search
      }
    }
  }

  private onChange(): void {
    if (this.element) {
      this.value = this.element?.nativeElement.value;
    }

    if (this.onChangeCallback) {
      this.onChangeCallback(this.getScorableData());
    }
  }
}
