<div class="ManipEquivFractionsContainer ManipulativeContainer">
    <div class="row w-100 images-row">
        <div class="block_image" [ngClass]="blockImageClasses" [ngStyle]="{ height: target.height }">
            <canvas *ngIf="isPieChart"
                    class="chart chart-pie"
                    baseChart
                    [data]="getChartData(target)"
                    [labels]="pieChartLabels"
                    [type]="pieChartType"
                    [options]="pieChartOptions"></canvas>

            <table *ngIf="!isPieChart" class="ManipEquivFractionsTargetTable" [ngClass]="{isHoriz: target.isHoriz}">
                <tr *ngFor="let fracRow of target.grid">
                    <td *ngFor="let fracCol of fracRow"
                        [ngStyle]="getGridStyles(fracCol, target)">&nbsp;
                    </td>
                </tr>
            </table>
        </div>

        <div class="block_image" [ngClass]="blockImageClasses" [ngStyle]="{ height: target.height }">
            <canvas *ngIf="isPieChart" class="chart chart-pie"
                    baseChart
                    [data]="getChartData(userInput)"
                    [labels]="pieChartLabels"
                    [type]="pieChartType"
                    [options]="pieChartOptions"></canvas>

            <table *ngIf="!isPieChart" class="ManipEquivFractionsUserTable" [ngClass]="{isHoriz: target.isHoriz}">
                <tr *ngFor="let fracRow of userInput.grid">
                    <td *ngFor="let fracCol of fracRow"
                        [ngStyle]="getGridStyles(fracCol, userInput)">&nbsp;
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <table class="ManipEquivFractionsFormula">
        <tr>
            <td class="cell_target" [ngClass]="{isVert: !target.isHoriz}">
                <div class="label_numerator">{{target.numerator}}</div>
                <div class="label_denominator">{{target.denominator}}</div>
            </td>
            <td class="cell_equals">=</td>
            <td class="cell_user">
                <div class="label_numerator">
                    <kh-integer-input
                            [maxValue]="userInput.denominator"
                            [value]="numerator"
                            (valueChange)="numeratorChanged($event)"
                            (submitAnswer)="submit($event)"></kh-integer-input>
                </div>
                <div class="label_denominator">
                    {{userInput.denominator}}
                </div>
            </td>
        </tr>
    </table>
</div>
