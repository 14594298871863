<span>
	<span *ngIf="!hideInput">
        <span id="ManipIntegerClickerValue"
              class="ManipIntegerClickerValue cursor"
              (click)="showControlModal()">{{inputValue}}</span>
    </span>

	<span *ngIf="!(hideStudentManipControlBar | async)" id="ManipIntegerClickerBar" class="ManipIntegerClickerBar StudentManipControlBar">
		<span *ngIf="!reverseButtonOrder">
			<button class="btn btn-primary btn-md" (click)="addValue(1)" [disabled]="questionClosed">
				<fa-icon [icon]="['fas', 'angle-up']" *ngIf="!upLabel"></fa-icon>
				<fa-icon [icon]="['fas','plus']" *ngIf="upIsPlus"></fa-icon>
				<span *ngIf="upIsNotPlus" [innerHTML]="upLabel | unsafeHtml"></span>
			</button>&nbsp;
		</span>
		<button class="btn btn-primary btn-md" (click)="addValue(-1)" [disabled]="questionClosed">
			<fa-icon [icon]="['fas','angle-down']" *ngIf="!downLabel"></fa-icon>
			<fa-icon [icon]="['fas','minus']" *ngIf="downIsMinus"></fa-icon>
			<span *ngIf="downIsNotMinus" [innerHtml]="downLabel | unsafeHtml"></span>
		</button>&nbsp;
		<span *ngIf="reverseButtonOrder">
			<button class="btn btn-primary btn-md" (click)="addValue(1)" [disabled]="questionClosed">
				<fa-icon [icon]="['fas', 'angle-up']" *ngIf="!upLabel"></fa-icon>
				<fa-icon [icon]="['fas','plus']" *ngIf="upIsPlus"></fa-icon>
				<span *ngIf="upIsNotPlus" [innerHtml]="upLabel | unsafeHtml"></span>
			</button>&nbsp;
		</span>
		<button class="btn btn-primary btn-md manipulative-submit-button"
                (click)="submit(inputValue)"
                [disabled]="questionClosed">
			<ng-container *ngIf="!isClosedByTeacher">
				<span *ngIf="initValue">Change Answer</span>
				<span *ngIf="!initValue">Submit</span>
			</ng-container>
			<span *ngIf="isClosedByTeacher">Submit</span>
		</button>
	</span>
</span>