import { MinecartTermSelectionMenuItem } from "./MinecartSelectionMenuTerm";
import { MinecartTermType } from "./MinecartTerm";

export class MinecartTermSelectionMenu {
  private menuElement: HTMLElement;
  private menuItems: MinecartTermSelectionMenuItem[] = [];
  private selectedItem: MinecartTermSelectionMenuItem | null = null;
  private onSelectionChange: (item: MinecartTermSelectionMenuItem) => void;

  public constructor(menuElement: HTMLElement, minValue: number, maxValue: number, onSelectionChange: (item: MinecartTermSelectionMenuItem) => void) {
    this.menuElement = menuElement;
    this.onSelectionChange = onSelectionChange;

    this.populateMenu(minValue, maxValue);
  }

  public getSelectedTerm(): MinecartTermSelectionMenuItem | null {
    return this.selectedItem;
  }

  private populateMenu(min: number, max: number): void {
    for (let i = max; i >= min; i--) {

      if (i !== 0) {
        this.createMenuItem(Math.abs(i), i > 0 ? 'balloon' : 'sandbag');
      }
    }
  }

  public deselect(): void {
    this.selectedItem = null;
    this.elementSelected(null);
  }

  public findAndSetTerm(value: number, type: MinecartTermType) {
    for (let i = 0; i < this.menuItems.length; i++) {
      const curItem = this.menuItems[i];
      if (curItem.value === value && curItem.type === type) {
        curItem.setSelected(true);
        curItem.element.scrollIntoView();
      }
    }
  }

  private createMenuItem(value: number, type: MinecartTermType): MinecartTermSelectionMenuItem {
    const menuItem = new MinecartTermSelectionMenuItem(value, type, this.menuElement, this.elementSelected.bind(this));
    this.menuItems.push(menuItem);

    return menuItem;
  }

  private elementSelected(menuItem: MinecartTermSelectionMenuItem | null): void {
    for (let i = 0; i < this.menuItems.length; i++) {
      const curItem = this.menuItems[i];

      if (curItem === menuItem) {
        this.selectedItem = curItem;
      }

      curItem.setSelected(curItem === menuItem);
    }

    if (this.selectedItem) {
      this.onSelectionChange(this.selectedItem);
    }
  }

}