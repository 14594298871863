import { SvgRenderElement, SvgRenderElementType } from './SvgRenderElement';
import { SvgRenderer } from './SvgRenderer';

export type SvgGroupConfig = SvgRenderElementType & {
    x?: number,
    y?: number,
    width?: number,
    height?: number
}

const svgGroupConfigDefault: Partial<SvgGroupConfig> = {

}


export class SvgGroup extends SvgRenderElement<SvgGroupConfig> {
  public constructor(renderer: SvgRenderer, parent: SVGElement, config: SvgGroupConfig) {
    super(renderer, parent, config, svgGroupConfigDefault);
  }

  public createElement(): void {
    this.element = document.createElementNS(SvgRenderer.svgns, 'g');
  }

  protected onMaintainScale(newScale: number): void {
    this.element.setAttribute('scale', newScale.toString());
  }

  public applyConfigProperties(): void {
    const xPos = this.config.x || 0;
    const yPos = this.config.y || 0;
    this.element.setAttribute('transform', `translate(${xPos},${yPos})`);

    if (this.config.width) {
      this.element.setAttribute('width', this.config.width.toString());
    }

    if (this.config.height) {
      this.element.setAttribute('height', this.config.height.toString());
    }
  }

  public addChild(el: SvgRenderElement<any>): void {
    this.element.appendChild(el.element);
  }
}
