/* eslint-disable indent */
import { Container } from "pixi.js";
import { GameComponent } from "./GameComponent";
import { Game } from "./Game";

export abstract class GameEntity extends Container {
    public components: GameComponent[] = [];
    public gameInstance: Game;

    public abstract update(deltaTimeSeconds: number): void;
    public abstract start(): void;

    public addComponent<T extends GameComponent>(componentType: new () => T): T {
        const c = new componentType();
        c.gameInstance = this.gameInstance;        
        c.entity = this;
        this.components.push(c);
        c.start();

        return c;
    }

    public removeComponent(component: GameComponent) {
        const index = this.components.indexOf(component);
        if (index !== -1) {
            this.components.splice(index, 1);
        }
    }

    public getComponent<T extends GameComponent>(type: new (...args: any[]) => T): T {
        return this.components.find(c => c instanceof type) as T;
    }
}
