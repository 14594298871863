<div id="ManipAlgebraTilesContainer" #container>
    <canvas id="AlgebraTilesCanvas" class="ManipAlgebraTilesCanvas" #canvas></canvas>

    <div *ngIf="displaySummary" class="label_expression RenderMath" [innerText]="expression"></div>

    <div *ngIf="!(hideStudentManipControlBar | async)" class="ManipAlgebraTilesBar StudentManipControlBar StudentManipControlBarBottomOnMobile">
        <button type="button" class="btn btn-primary btn-md" (click)="openAddTileModal()">Add Tile</button>&nbsp;

        <div class="SelectedTileButtons" *ngIf="selectedObject">
            <button type="button" class="btn btn-invis btn-md hidden-xs" (click)="rotate()">
                <fa-icon [icon]="['fas','redo-alt']"></fa-icon>
            </button>
            <button type="button" class="btn btn-invis btn-md font-weight-bold hidden-xs" (click)="toggleNegative()">+/-</button>
            <button type="button" class="btn btn-invis btn-md hidden-xs" (click)="duplicate()">
                <fa-icon [icon]="['fas','copy']"></fa-icon>
            </button>
            <button type="button" class="btn btn-invis btn-md" (click)="delete()">
                <fa-icon [icon]="['fas','trash-alt']"></fa-icon>
            </button>
        </div>

        <button type="button" class="btn btn-primary btn-md manipulative-submit-button"
                (click)="submit(expression)"
                [disabled]="questionClosed">
            <span *ngIf="initValue">Change Answer</span>
            <span *ngIf="!initValue">Submit</span>
        </button>

    </div>
</div>
