import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';

import { combineLatest, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { DynamicContentChild, OnDynamicData, OnDynamicMount } from 'ngx-dynamic-hooks';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { fabric } from 'fabric';

import { forOwn } from 'lodash-es';
import { IEvent } from 'fabric/fabric-impl';
import { StringInputComponent } from '../string-input/string-input.component';
import { WindowsService } from '../../../../services/windows.service';
import { FabricDraggable } from '../../../../utils/fabric-utils/fabric-draggable';
import { DraggableTokenizer } from '../../../../utils/fabric-utils/fabric-draggable-utils';
import { FabricIntInputRectRegion } from '../../../../utils/fabric-utils/fabric-int-input-rect-region';
import { FabricRectRegion } from '../../../../utils/fabric-utils/fabric-rect-region';
import { FabricRegion } from '../../../../utils/fabric-utils/models/fabric-region';
import { FabricExtendedCanvas } from '../../../fabric/angular-fabric';
import { AngularFabricService } from '../../../fabric/angular-fabric.service';
import { ManipulativeChildObject } from '../../../models/manipulative-child-object.model';
import { IntInputRegionComponent, RegionOriginMode } from '../int-input-region/int-input-region.component';
import { RegionComponent } from '../region/region.component';
import { FabricCanvas } from '../../../../services/fabric-canvas.service';
import { FabricConstantsService } from '../../../../services/fabric-constants.service';
import { Inject } from '@angular/core';
import { QUESTION_EVENTS_SERVICE_TOKEN, IQuestionEventsService } from '../../../../services/IQuestionEvents';

@UntilDestroy()
@Component({
  selector: 'kh-manip-windows',
  styleUrls: ['./kh-manip-windows.component.less'],
  templateUrl: './kh-manip-windows.component.html',
  viewProviders: [FabricCanvas, AngularFabricService]
})
export class KhManipWindowsComponent implements OnInit, AfterViewInit, OnDynamicMount {

  @ViewChild('canvas') public canvasEl?: ElementRef<HTMLCanvasElement>;
  @ViewChild('htmlOverlay') public htmlOverlay!: ElementRef<HTMLDivElement>;

  public options = {
    backgroundColor: '',
    canvas: undefined,
    fontFamily: '',
    fontSize: 0,
    frame: '',
    strokeColor: '',
    strokeWidth: 0
  };


  @Input() public size = '';
  @Input() public backgroundImage = '';
  @Input() public reportMode = false;
  @Input() public value = '';
  @Input() public sampleAnswer = '';
  @Output() public valueChange = new EventEmitter<{answer: string, state: string}>();

  private canvas!: FabricExtendedCanvas;
  private canRender$ = new ReplaySubject<void>(1);

  private fabricComponents = [] as fabric.Object[];
  public serviceSubscription: Subscription | undefined;
  public contentChild: DynamicContentChild[] | undefined;
  public keyboards: FabricIntInputRectRegion[] = [];
  public regions: FabricRegion[] = [];
  public regionsList: (RegionComponent | IntInputRegionComponent)[] = [];
  public answer: Record<string, any> = {};
  public state: Record<string, any> = {};
  public isSingleMode = true;
  public regionsAnswer: string[] = [];
  public windowAnswer: string[] = [];
  public windowState: string[] = [];
  public regionsState: string[] = [];

  public constructor(
    private angularFabric: AngularFabricService,
    private readonly fabricConstants: FabricConstantsService,
    public readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly windowService: WindowsService,
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService
  ) {}

  @HostListener('window:resize', ['$event'])
  public onResize() {
    setTimeout(() => {
      this.resizeWorks();
    })
  }

  public resizeWorks(): void {
    const container = this.elementRef.nativeElement.closest('.gameplayQuestionText');
    const teacherContainer = this.elementRef.nativeElement.closest('.QuestionTextTeacher');
    const [ sWidth, sHeight ] = this.size.replace(/[']/g, '').split(',')
      .map(Number);
    const elemParentNode = this.elementRef.nativeElement.parentNode;
    let scale = 1;
    (this.elementRef.nativeElement as HTMLDivElement).hidden = true;
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');

    scale = Math.min(1, (elemParentNode.offsetWidth === 0 ? (container ? container.offsetWidth : teacherContainer.offsetWidth) : elemParentNode.offsetWidth) / sWidth);

    (this.elementRef.nativeElement as HTMLDivElement).hidden = false;
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'flex');

    if (scale === 0) {
      setTimeout(() => {
        this.resizeWorks();
      }, 1000);
    } else {
      this.angularFabric.setCanvasSize(sWidth * scale, sHeight * scale);
      this.canvas.setZoom(scale);
      this.canvas.renderAll();

      this.htmlOverlay.nativeElement.style.transform = `scale(${scale})`;
    }
  }

  public ngOnInit(): void {
    this.canRender$
      .pipe(
        tap(() => this.render()),
        untilDestroyed(this)
      )
      .subscribe();

    this.questionEventsService.hideInputOnlyForManip.next(true);
    if (!this.reportMode) {
      this.windowService.windowAnswers.pipe(untilDestroyed(this)).subscribe(answer => {

        const answerText = [];
        this.windowAnswer = [];
        for (const [key, value] of Object.entries(answer)) {
          answerText.push(key + ':' + value);
          this.windowAnswer.push(key + ':' + value);
        }
        if (this.regionsAnswer.length) {
          answerText.push(this.regionsAnswer.join(','));
        }
        const allState = this.windowState.concat(this.regionsState);
        const answers = {answer: answerText.join(','), state: allState.join(',')};
        this.valueChange.emit(answers)
      })

      this.windowService.windowState.pipe(untilDestroyed(this)).subscribe(state => {
        const stateText = [];
        this.windowState = [];
        for (const [key, value] of Object.entries(state)) {
          stateText.push(key + ':' + value);
          this.windowState.push(key + ':' + value);
        }
        const allAnswers = this.windowAnswer.concat(this.regionsAnswer);
        const allState = stateText.concat(this.regionsState);
        const answers = {answer: allAnswers.join(','), state: allState.join(',')};
        this.valueChange.emit(answers)
      })
    }

    const inputAnswer = this.sampleAnswer ? this.sampleAnswer : this.value;

    if (this.reportMode && inputAnswer && typeof inputAnswer === 'string') {
      const ngModelAnswer = inputAnswer.split(',');
      ngModelAnswer.forEach(answer => {
        const [windowId, paneId] = answer.split(':');
        this.windowService.setWindows(windowId, paneId);
      })
    }
  }

  public ngAfterViewInit(): void {
    this.initializeFabric();
    this.canvas = this.angularFabric.getCanvas();
    this.canvas.on({
      'keyboard:onOpen': (e: IEvent) => this.onKeyboardOpenHandler(e),
      'region:onChange': ({target}) => this.onRegionChange(target as FabricRectRegion)
    });
  }

  private setRegionAnswer(region: FabricRegion): void {
    const parsedQuestionAnswers = DraggableTokenizer(this.sampleAnswer ? this.sampleAnswer : this.value);

    for (const regionId in parsedQuestionAnswers) {
      if (region.itemOrder === regionId && region.type === 'intInputRegion') {
        (region as FabricIntInputRectRegion).setText(parsedQuestionAnswers[regionId][0]);
      }
    }
  }

  /**
   * Do known input types that are not Fabric inputs (for report mode)
   */
  private setElementAnswers(): void {
    const parsedQuestionAnswers = DraggableTokenizer(this.sampleAnswer ? this.sampleAnswer : this.value);

    // do non fabric inputs
    this.contentChild?.forEach(c => {
      if (c.componentRef.instance instanceof StringInputComponent) {
        const curComponent = c.componentRef.instance as StringInputComponent

        for (const regionId in parsedQuestionAnswers) {
          if (curComponent.id === regionId) {
            curComponent.onChangeValue(parsedQuestionAnswers[regionId][0].toString());
            break;
          }
        }
      }
    });
  }

  private onRegionChange(region: FabricRectRegion): void {
    this.setRegionState(region);

    if (!region.ignoreInScore) {
      const itemOrder = region.itemOrder ?? region._id;
      const activeDraggables: FabricDraggable[] = region.activeDraggables;

      if (activeDraggables.length === 0) {
        delete this.answer[itemOrder];
      } else {
        this.answer[itemOrder] = this.isSingleMode
          ? activeDraggables[0].name
          : activeDraggables.map(({name}) => name);
      }

      let regions: string[] = [];
      this.regionsAnswer = [];

      forOwn(this.answer, (Values, Key) => {
        let region = Key + ':';

        if (!Array.isArray(Values)) {
          region += Values;
        } else if (Values.length === 1) {
          region += Values[0];
        } else {
          region += `[${Values.join()}]`;
        }
        regions.push(region);
        this.regionsAnswer.push(region);
      });
      regions.push(this.windowAnswer.join(','));
      const allState = this.windowState.concat(this.regionsState);
      regions = regions.filter(Boolean);
      const answers = {answer: regions.join(','), state: allState.join(',')};
      this.valueChange.emit(answers);
    }
  }

  public setRegionState(region: FabricRectRegion): void {
    const itemOrder = region.itemOrder ?? region._id;
    const activeDraggables: FabricDraggable[] = region.activeDraggables;

    if (activeDraggables.length === 0) {
      delete this.state[itemOrder];
    } else {
      this.state[itemOrder] = this.isSingleMode
        ? activeDraggables[0].name
        : activeDraggables.map(({name}) => name);
    }

    const regions: string[] = [];
    this.regionsState = [];

    forOwn(this.state, (Values, Key) => {
      let region = Key + ':';

      if (!Array.isArray(Values)) {
        region += Values;
      } else if (Values.length === 1) {
        region += Values[0];
      } else {
        region += `[${Values.join()}]`;
      }
      regions.push(region);
      this.regionsState.push(region);
      const allState = this.windowState.concat(this.regionsState);
      const regionAnswer = this.regionsAnswer.concat(this.windowAnswer.join(','));
      const answers = {answer: regionAnswer.join(','), state: allState.join(',')};
      this.valueChange.emit(answers);
    });
  }

  private initializeFabric(): void {
    const options = {
      JSONExportProperties: this.fabricConstants.JSONExportProperties,
      shapeDefaults: this.fabricConstants.shapeDefaults,
      textDefaults: this.fabricConstants.textDefaults
    };
    const [ width, height ] = this.size.replace(/[']/g, '').split(',')
      .map(Number);
    const canvas = this.canvasEl?.nativeElement as HTMLCanvasElement;
    this.angularFabric.initialize(options, canvas);
    this.angularFabric.setCanvasSize(width, height);
  }

  public onDynamicMount(data: OnDynamicData): void {
    const contentChildren: DynamicContentChild[] = data.contentChildren ?? [];
    this.contentChild = contentChildren;

    const fabricComponents = contentChildren.reduce((acc, c) => {
      if (c.componentRef.instance instanceof ManipulativeChildObject) {
        return [...acc, c.componentRef.instance ]
      }

      return acc;
    }, [] as ManipulativeChildObject[] );

    if (!fabricComponents.length) {
      this.canRender$.next();
    }

    contentChildren.forEach(c => {
      const inst = c.componentRef.instance;

      if (inst instanceof IntInputRegionComponent) {
        this.regionsList.push(inst);
        inst.setOriginMode(RegionOriginMode.TOP_LEFT);
      }
    });

    combineLatest(fabricComponents.map(c => c.isLoaded)).pipe(
      map(loading => loading.every(l => l)),
      distinctUntilChanged(),
      tap(loading => {
        if (loading) {
          this.fabricComponents =
            fabricComponents.reduce((acc, c) => [...acc, ...c.fabricObject], [] as fabric.Object[])
          this.canRender$.next();
        }
      }),
      untilDestroyed(this))
      .subscribe()
  }

  private registerRegion(regionEl: RegionComponent | IntInputRegionComponent, areaHeight: number): FabricRegion {
    const region = regionEl.getRegion(areaHeight);

    if (region.type === 'intInputRegion') {
      this.keyboards.push(region as FabricIntInputRectRegion);
    }

    this.regions.push(region);

    if (region.setObjectsCoords) {
      region.setObjectsCoords();
    }

    return region;
  }

  private render(): void {
    const [ Width, Height ] = this.size.replace(/[']/g, '').split(',')
      .map(Number);

    if (this.reportMode) {
      this.fabricComponents.forEach(item => {
        item.selectable = false;
        item.evented = false;
      })
    }

    this.canvas.add(...this.fabricComponents);
    this.canvas.setBackgroundImage(this.backgroundImage, this.canvas.renderAll.bind(this.canvas));

    const regions = this.regionsList.map((regionEl: RegionComponent | IntInputRegionComponent) => {
      const region = this.registerRegion(regionEl, this.canvas.getHeight());

      if (region.top && region.height) {
        region.top = -region.top + Height - region.height / 2;
      }

      this.canvas.add(region);

      return region;
    });

    if (this.reportMode) {
      regions.forEach(region => {
        this.setRegionAnswer(region);
        region.selectable = false;
        region.evented = false;
      });

      this.setElementAnswers();
    }

    this.resizeWorks();
    this.canvas.renderAll();
  }

  private onKeyboardOpenHandler(e: IEvent): void {
    for (const i in this.keyboards) {
      const keyboard = this.keyboards[i];
      if (keyboard !== e.target) {
        keyboard.close();
      }
    }
  }
}
