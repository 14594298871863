

export type ManipFrame = { x: number, y: number, width: number, height: number };

/**
 * Get a random number from the range
 * @param min Min number for range (inclusive)
 * @param max Max number for range (inclusive)
 * @returns A random number in the range
 */
export function randomIntegerFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function parseFrameString(frameString: string): ManipFrame {
  // Use the replace() method with a regular expression to replace multiple spaces with a single space
  const cleanString = frameString.replace(/\s+/g, ' ');

  // Splitting on either a space or a comma
  const values = cleanString.split(/[ ,]+/);

  // Convert each value to a number and handle missing values
  const x: number = values.length > 0 ? parseFloat(values[0]) : 0;
  const y: number = values.length > 1 ? parseFloat(values[1]) : 0;
  const width: number = values.length > 2 ? parseFloat(values[2]) : 0;
  const height: number = values.length > 3 ? parseFloat(values[3]) : 0;

  return { x: x, y: y, width: width, height: height };
}

export function applyFrameToElement(element: HTMLElement | SVGElement, inputFrame: string | ManipFrame): ManipFrame {
  let frame: ManipFrame;

  if (typeof inputFrame === 'string') {
    frame = parseFrameString(inputFrame);
  } else {
    frame = inputFrame;
  }

  element.style.position = 'absolute';
  element.style.left = `${frame.x}px`;
  element.style.top = `${frame.y}px`;
  element.style.width = `${frame.width}px`;
  element.style.height = `${frame.height}px`;

  return frame;
}

/**
 * @param min min number that can show up (inclusive)
 * @param max max number that can show up (inclusive)
 */
export function randomIntFromInterval(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Clamps a value between a min and max value (inclusive)
 * @param value The value to clamp
 * @param min Minimum value
 * @param max Maximum value
 * @returns The clamped value
 */
export function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}
