<div
     class="int-input-region__keyboard-wrapper"
     [ngStyle]="wrapperStyles()"
     #keyboard
>
    <kh-input-int-keypad
            *ngIf="showInputKeypad"
            [result]="value"
            (resultChange)="valueChanged($event)"
            [maxDigits]="maxDigits"
            [brokenKeys]="brokenKeys"
            [font]="fontFamily"
            (onAfterInit)="setupPosition()"
            (onClose)="onClose()"
            [khClickOutside]="onClose"
    ></kh-input-int-keypad>
</div>