import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from 'app/core/http/url.service';
/*import { IGameUserInfo } from 'app/student/game-section/models/game-user/game-user-info.dto';*/
/*TODO: removing this feature, check the impact */
/*import { GameServerGateway } from 'app/student/game-section/services/gateways/game-server-gateway';*/
/*import { GameUserHeaderCreator } from 'app/student/game-section/services/login-utility/login-header-creator.service';*/
import { IStudentTimeState } from 'app/student/services/time-checker/dto/student-time-state.dto';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudentTimeCheckerHttpService {

  private readonly BASE_URL = 'users';


  public constructor(/*private readonly gameServerGateway: GameServerGateway,*/
                     private readonly urlService: UrlService) {
  }

  /*TODO: changed this feature, check the impact */
  public getInfo(): Observable<IStudentTimeState> {
    const url: string = this.urlService.buildGameServerApiUrl(this.BASE_URL, 'in-school')
    const studentTimeState: IStudentTimeState = {
      IsSchoolHours: false,
      StartOfSchoolHoursUtc: null,
      EndOfSchoolHoursUtc: null,
      SchoolHoursEndMs: 0,
      CurrentUserLocalTime: new Date()
    };
    return of(studentTimeState);
    /*return this.gameServerGateway.get<IStudentTimeState>(url);*/
  }
}
