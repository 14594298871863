/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, ElementRef, Input, ViewChild, Inject } from '@angular/core';
import { MaterialBridgeAPI } from './material-bridge';
import { PuzzleBaseComponent } from '../puzzle/puzzle.component';
import { PuzzleEventService } from '../services/puzzle-event.service';
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from '../../services/IQuestionEvents';
import { IAnalyticsService, ANALYTICS_SERVICE_TOKEN } from '../../services/IAnalyticsService';
import { IAuthenticationDataService, AUTH_DATA_SERVICE_TOKEN } from '../../services/IAuthenticationDataService';


@Component({
  selector: 'kh-puzzle-material-bridge',
  styleUrls: ['./material-bridge.component.css'],
  templateUrl: './material-bridge.component.html'
})
export class MaterialBridgeComponent extends PuzzleBaseComponent implements AfterViewInit {
  @ViewChild('svg') public svg?: ElementRef<HTMLElement>;
  @ViewChild('arena') public arena?: ElementRef<HTMLElement>;
  @ViewChild('input') public input?: ElementRef<HTMLElement>;
  @ViewChild('inputImg') public inputImg?: ElementRef<HTMLElement>;
  @ViewChild('inputSize') public inputSize?: ElementRef<HTMLElement>;
  @ViewChild('inputPieces') public inputPieces?: ElementRef<HTMLElement>;
  @ViewChild('inputText') public inputText?: ElementRef<HTMLElement>;
  @ViewChild('orderBtn') public orderBtn?: ElementRef<HTMLElement>;
  @ViewChild('retryBtn') public retryBtn?: ElementRef<HTMLElement>;
  @ViewChild('nextBtn') public nextBtn?: ElementRef<HTMLElement>;
  @ViewChild('boat') public boat?: ElementRef<HTMLElement>;
  @ViewChild('frontWater') public frontWater?: ElementRef<HTMLElement>;
  @ViewChild('lightning') public lightning?: ElementRef<HTMLElement>;
  @ViewChild('bridgeOverlay') public bridgeOverlay: ElementRef<HTMLElement>;
  @ViewChild('usability') public usability?: ElementRef<HTMLElement>;
  @ViewChild('helpBtn') public helpBtn?: ElementRef<HTMLElement>;

  @Input('upperBridge') public upperBridge: string;
  @Input('lowerBridge') public lowerBridge: string;
  @Input('fractionRange') public fractionRange: string;
  @Input('limits') public limits: string;
  @Input('strokeColor') public strokeColor = '#b92c28';
  @Input('strokeHighlightColor') public strokeHighlighColor = '#b92c28';

  public constructor(private route: ActivatedRoute,
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService,
    protected puzzleEventService: PuzzleEventService,
    @Inject(ANALYTICS_SERVICE_TOKEN) protected readonly analyticsService: IAnalyticsService,
    @Inject(AUTH_DATA_SERVICE_TOKEN) protected readonly authenticationDataService: IAuthenticationDataService) {
    super(questionEventsService, puzzleEventService, analyticsService, authenticationDataService);

    this.puzzleEventService.disablePlay.next(true);
  }

  ngAfterViewInit(): void {
    if (!this.arena) {
      return;
    }

    const setup = {
      arena: this.arena?.nativeElement,
      boat: this.boat?.nativeElement,
      frontWater: this.frontWater?.nativeElement,
      // help: this.help,
      helpBtn: this.helpBtn?.nativeElement,
      input: this.input?.nativeElement,
      inputImg: this.inputImg?.nativeElement,
      inputPieces: this.inputPieces?.nativeElement,
      inputSize: this.inputSize?.nativeElement,
      inputText: this.inputText?.nativeElement,
      lightning: this.lightning?.nativeElement,
      nextBtn: this.nextBtn?.nativeElement,
      orderBtn: this.orderBtn?.nativeElement,
      overlay: this.bridgeOverlay?.nativeElement,
      retryBtn: this.retryBtn?.nativeElement,
      svg: this.svg?.nativeElement,
      usability: this.usability?.nativeElement
    }

    const interactive = new MaterialBridgeAPI(setup, [{
      fractionRange: this.fractionRange ? this.parseBridgeString(this.fractionRange) : [],
      limits: this.limits ? this.parseArrayOfArrays(this.limits) : [],
      lowerBridge: this.lowerBridge ? this.parseBridgeString(this.lowerBridge) : undefined,
      upperBridge: this.upperBridge ? this.parseBridgeString(this.upperBridge) : undefined
    }], this.puzzleEventService);

    this.setPuzzle(interactive);
    this.playEnabled(false);
  }

  private parseBridgeString(s: string): number[] {
    const numbers = s.replace(/'/g, '')
      .replace(/\[/g, '')
      .replace(/\]/g, '')
      .split(',');

    const returnArray: number[] = [];

    numbers.forEach(n => {
      returnArray.push(Number(n.trim()));
    });

    return returnArray;
  }

  private parseArrayOfArrays(input: string): number[][] {
    const arrays = input.split('],[');
    const result: number[][] = [];

    arrays.forEach(arrayString => {
      result.push(this.parseBridgeString(arrayString));
    });

    return result;
  }

}
