/* eslint-disable indent */
import { Camera } from './Camera';
import { SVG, Svg } from '@svgdotjs/svg.js';
import { SvgCircle, SvgCircleConfig } from './SvgCircle';
import { SvgGroup, SvgGroupConfig } from './SvgGroup';
import { SvgLine, SvgLineConfig } from './SvgLine';
import { SvgRasterImage, SvgRasterImageConfig } from './SvgRasterImage';
import { SvgRenderElement } from './SvgRenderElement';
import { SvgText, SvgTextConfig } from './SvgText';
import { KHPoint } from '../pixi-puzzle-framework/PuzzleEngine/KHPoint';

export class SvgRenderer {
    public static readonly svgns = 'http://www.w3.org/2000/svg';
    public svg: Svg;
    public camera: Camera;
    public element: SVGElement;

    public constructor(element: HTMLElement | SVGElement, width = 1280, height = 720) {
        this.svg = SVG(element) as Svg;
        this.camera = new Camera(this.svg, width, height);
        this.element = this.svg.node;
    }


    public svgLine(config: SvgLineConfig, parent: SVGElement = this.svg.node): SvgLine {
        return new SvgLine(this, parent, config);
    }

    public svgText(config: SvgTextConfig, parent: SVGElement = this.svg.node): SvgText {
        return new SvgText(this, parent, config);
    }

    public svgGroup(config: SvgGroupConfig, parent: SVGElement = this.svg.node): SvgGroup {
        return new SvgGroup(this, parent, config);
    }

    public svgCircle(config: SvgCircleConfig, parent: SVGElement = this.svg.node): SvgCircle {
        return new SvgCircle(this, parent, config);
    }

    public svgRasterImage(config: SvgRasterImageConfig, parent: SVGElement = this.svg.node): SvgRasterImage {
        return new SvgRasterImage(this, parent, config);
    }

    public setClickable(svge: SvgRenderElement<any>, onClick: (m: MouseEvent) => void) {
        svge.element.addEventListener('click', onClick);
    }

    public setDraggable(svge: SvgRenderElement<any>,
        onDragStart?: (pos: KHPoint) => void,
        onDrag?: (pos: KHPoint) => void,
        onDragEnd?: () => void) {
        let isDragging = false;

        if (onDragStart) {
            const dragStartFn = (e: MouseEvent | TouchEvent) => {
                e.preventDefault();
                isDragging = true;

                if (e instanceof MouseEvent) {
                    onDragStart({x: e.clientX, y: e.clientY});
                } else if (e instanceof TouchEvent) {
                    onDragStart({x: e.touches[0].clientX, y: e.touches[0].clientY});
                }
            }

            svge.element.addEventListener('mousedown', dragStartFn);
            svge.element.addEventListener('touchstart', dragStartFn);
        }

        if (onDrag) {
            const dragFn = (e: MouseEvent | TouchEvent) => {
                if (isDragging) {
                    if (e instanceof MouseEvent) {
                        onDrag({x: e.clientX, y: e.clientY});
                    } else if (e instanceof TouchEvent) {
                        onDrag({x: e.touches[0].clientX, y: e.touches[0].clientY});
                    }
                }
            }

            document.addEventListener('mousemove', dragFn);
            document.addEventListener('touchmove', dragFn);
        }

        if (onDragEnd) {
            const dragEndFn = (e: MouseEvent | TouchEvent) => {
                isDragging = false;
                if (e instanceof MouseEvent) {
                    onDragEnd();
                } else if (e instanceof TouchEvent) {
                    onDragEnd();
                }
            }

            document.addEventListener('mouseup', dragEndFn);
            document.addEventListener('touchend', dragEndFn);
            document.addEventListener('touchcancel', dragEndFn);
        }
    }
}
