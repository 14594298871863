<div class="modal-body">

    <div class="center text-sm">ADD AN ALGEBRA TILE</div>
    <div class="container ">
        <div class="row justify-content-center gx-5">
            <div class="col-6 right spacer-md p-1 ">
                <div class="text-sm grey italic">+ Positive</div>
            </div>
            <div class="col-6 spacer-md p-1 bg-transparent">
                <div class="text-sm grey italic">- Negative</div>
            </div>
        </div>
        <div class="row justify-content-center gx-5">
            <div class="col-6 right p-1 bg-transparent">
                <kh-img (click)="addTile('1')" path="tiles/1.svg"></kh-img>
            </div>
            <div class="col-6 p-1 bg-transparent">
                <kh-img (click)="addTile('-1')" path="tiles/-1.svg"></kh-img>
            </div>
        </div>
        <div class="row justify-content-center gx-5">
            <div class="col-6 right spacer-md p-1 bg-transparent">
                <kh-img (click)="addTile('x')" path="tiles/x.svg"></kh-img>
            </div>
            <div class="col-6 spacer-md p-1 bg-transparent">
                <kh-img (click)="addTile('-x')" path="tiles/-x.svg"></kh-img>
            </div>
        </div>
        <div class="row justify-content-center gx-5">
            <div class="col-6 right p-1 bg-transparent">
                <kh-img (click)="addTile('x^2')" path="tiles/x2.svg"></kh-img>
            </div>
            <div class="col-6 p-1 bg-transparent">
                <kh-img (click)="addTile('-x^2')" path="tiles/-x2.svg"></kh-img>
            </div>
        </div>
        <br/>
        <!--
        <div class="right">
            <button ng-click="Cancel()" class="btn btn-md btn-info">Cancel</button>
        </div>
        -->
    </div>
</div>
