import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Inject, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Answers, SpotlightInputType, SpotlightSetup } from './spotlight-models';
import { gsap } from 'gsap/all'
import { PuzzleSpotlight } from './spotlight';
import { PuzzleBaseComponent } from '../puzzle/puzzle.component';
import { PuzzleEventService } from '../services/puzzle-event.service';
import { PuzzleBase } from '../puzzle/puzzleBase';
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from '../../services/IQuestionEvents';
import { IAnalyticsService, ANALYTICS_SERVICE_TOKEN } from '../../services/IAnalyticsService';
import { IAuthenticationDataService, AUTH_DATA_SERVICE_TOKEN } from '../../services/IAuthenticationDataService';

@Component({
  providers: [ {provide: PuzzleBaseComponent}],
  selector: 'kh-puzzle-spotlight',
  styleUrls: ['./spotlight.component.less'],
  templateUrl: './spotlight.component.html'
})
export class SpotlightComponent extends PuzzleBaseComponent implements AfterViewInit, OnInit {

  @ViewChild('lowerRenderEl') public lowerRenderEl!: ElementRef<SVGSVGElement>;
  @ViewChild('upperRenderEl') public upperRenderEl?: ElementRef<SVGSVGElement>;
  @ViewChild('goBtnRenderEl') public goBtnRenderEl?: ElementRef<SVGSVGElement>;
  @ViewChild('correct') public correct!: ElementRef<SVGSVGElement>;
  @ViewChild('tryAgain') public tryAgain!: ElementRef<SVGSVGElement>;
  @ViewChild('correctBtn') public correctBtn!: ElementRef<SVGSVGElement>;
  @ViewChild('tryAgainBtn') public tryAgainBtn!: ElementRef<SVGSVGElement>;

  @Input() public target: number | string = 0
  @Input() public maxDigits?: number;
  @Input() public inputType: SpotlightInputType = 'spotlight';
  @Input() private descriptor: string | undefined;
  @Input() private min = 0
  @Input() private max: number | string = 0
  @Input() private spotlightWidth = 0.10
  @Input() private denominator: number | null = null
  @Input() private value: Answers | null = null
  @Input() private decimals: number | null = null
  @Input() private tickStepSize: number | null = null
  @Input() private labelTicks: boolean | null = null
  @Input() private timeStep = 1
  @Input() private hints: string | null = null
  @Input() private majorJumps: number | null = null
  @Input() private padding = 0.10
  @Input() private reduceTicks = false
  @Input() private zoom = true
  @Input() private reduceHints = false
  @Input() private reduceEndpoints = false
  @Input() private reduceTarget = false
  @Input() private labelJumps = false;
  @Input() private width = 1280;
  @Input() private height = 720;
  @Input() private jumpStart: number | null = null;

  @Output() public valueChange = new EventEmitter<{ answer: string, state: string }>();

  public alternateTimeline = gsap.timeline();

  public constructor(
    private readonly hostElement: ElementRef,
    private readonly renderer: Renderer2,
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService,
    protected puzzleEventService: PuzzleEventService,
    @Inject(ANALYTICS_SERVICE_TOKEN) protected readonly analyitcsService: IAnalyticsService,
    @Inject(AUTH_DATA_SERVICE_TOKEN) protected readonly authenticationDataService: IAuthenticationDataService
  ) {
    super(questionEventsService, puzzleEventService, analyitcsService, authenticationDataService);
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    this.resizeWorks();
  }

  public resizeWorks(): void {
    // this.renderer.setStyle(this.hostElement.nativeElement.children[0]!, 'height', this.lowerRenderEl!.nativeElement.clientHeight + 'px');
  }

  public ngAfterViewChecked(): void {
    // When dynamically switching between questions the size can get wonky on the puzzle and messing up dom layouts.
    // As such we need to recalculate it after the view has been checked.
    this.resizeWorks();
  }

  public ngAfterViewInit(): void {
    // this.renderer.setStyle(this.correct?.nativeElement, 'visibility', 'hidden');
    // this.renderer.setStyle(this.tryAgain?.nativeElement, 'visibility', 'hidden');

    this.resizeWorks();

    const hintArray : Array<number> = []

    if (this.hints) {
      const a = this.hints.split(',')
      a.map(h => { hintArray.push(Number(h))})
    }

    const setup = {
      answer: this.value?.answer,
      decimals: this.decimals,
      denominator: this.denominator,
      hints: hintArray,
      labelTicks: this.labelTicks,
      majorJumps: Number(this.majorJumps),
      max: this.max,
      min: this.min,
      inputType: this.inputType,
      padding: this.padding,
      customDescriptor: this.descriptor,
      target: this.target,
      maxInputDigits: this.maxDigits? this.maxDigits : 3,
      reduceEndpoints: this.reduceEndpoints,
      reduceHints: this.reduceHints,
      spotlightWidth: this.spotlightWidth,
      reduceTarget: this.reduceTarget,
      reduceTicks: this.reduceTicks,
      tickStepSize: this.tickStepSize,
      timeStep: this.timeStep,
      zoom: this.zoom,
      labelJumps: this.labelJumps,
      jumpStart: this.jumpStart !== null ? Number(this.jumpStart) : this.min // if jump start isn't defined, start it at the min value

    } as unknown as SpotlightSetup
    const elements: Array<SVGSVGElement | undefined> = [this.lowerRenderEl?.nativeElement, this.upperRenderEl?.nativeElement]

    if (this.lowerRenderEl) {
      const puzzle = PuzzleSpotlight(elements, setup)
      this.setPuzzle(puzzle as PuzzleBase);
      puzzle.onSuccess = () => {};
    }
  }

  public closeTryAgainModal(): void {
    gsap.set(this.tryAgain.nativeElement, { visibility: 'hidden' });
  }

  public closeCorrectModal(): void {
    gsap.set(this.correct.nativeElement, { visibility: 'hidden' });
  }

  public calculatePosition(): number[] {
    const left = (this.lowerRenderEl.nativeElement.getBoundingClientRect().width / 2) - (this.tryAgain.nativeElement.getBoundingClientRect().width / 2);
    const top = (this.lowerRenderEl.nativeElement.getBoundingClientRect().height / 2) - (this.tryAgain.nativeElement.getBoundingClientRect().height / 2);

    return [left, top];
  }
}
