import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import { IScorable, ScorableData } from "../kh-manip-scorable/IScorable";
import { RegionOriginMode } from "../int-input-region/int-input-region.component";
import { Inject } from '@angular/core';
import { QUESTION_EVENTS_SERVICE_TOKEN, IQuestionEventsService } from "../../../../services/IQuestionEvents";
import { InputColor } from "../../../enums/input-color.enum";

@Component({
  selector: "kh-manipulative-num-input",
  templateUrl: "kh-manip-scorable-int-input.component.html",
  styleUrls: ["kh-manip-scorable-int-input.component.less"],
})
export class KhManipScorableIntInput implements IScorable {
  public onChangeCallback: undefined | ((data: ScorableData) => void); // callback
  @Input("value") public value: string = "";
  @Input("name") public name = "";
  @Input("key") public key: string = "";
  @Input("frame") public frame = "0 0 100 100";
  @ViewChild("input") public inputElement!: ElementRef<HTMLInputElement>;
  @Input("font-family") protected fontFamily = "arial";
  @Input("max-digits") public maxLength = 40;
  @Input("text-size") public textSize = "80%";
  @Input("border-stroke-size") public borderStrokeSize = 3;
  @Input("background-color") public backgroundColor = "transparent";
  @Input("text-alignment") public textAlignment = "center";
  @Input("text-style") public textStyle = "normal";
  @Input("shape") public shape?: string = "rectagle";
  @Input("broken-keys") public brokenKeys: number[] = [];
  @Input("stick-to") public stickTo: string="";
  @Input("border-radius") public borderRadius = "";
  @ViewChild("keyboard") public keyboard!: ElementRef<HTMLElement>;

  public borderColor = InputColor.DEFAULT_COLOR;
  public fontSize = 16;
  public type = "number";

  public fontWeight = "normal";
  public fontStyle = "normal";

  public box = {
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  };
  public showInputKeypad = false;
  public keyboardPositionSet = false;
  public keyboardLeft = 0;
  public keyboardTop = 0;

  private x = 0;
  private y = 0;
  private origin = RegionOriginMode.BOT_LEFT;
  private parentOffset: number = 0;

  public ngOnInit() {
    if (!this.borderRadius) {
      this.borderRadius = this.shape?.toLocaleLowerCase() === "oval" ? "50%" : "5px";
    } else {
      this.borderRadius = this.borderRadius.endsWith("%") ? this.borderRadius : `${parseFloat(this.borderRadius)}px`;
    }

    const [left, top, width, height] = this.frame.split(" ").map(Number);

    this.box = {
      left,
      top,
      width,
      height,
    };

    this.fontSize = this.textSize.endsWith("%") ? (Number(this.textSize.replace("%", "")) / 100) * height : Number(this.textSize);
    this.maxLength = parseInt(this.maxLength.toString());

    if (this.textStyle === "bold") {
      this.fontWeight = "bold";
    } else {
      this.fontStyle = this.textStyle;
    }

  }

  ngAfterViewInit(): void {
    const [left, top, width, height] = this.frame.split(" ").map(Number);
    if (this.stickTo) {
      this.keyboardLeft = Number(this.stickTo.replace(/[']/g, '').split(',')[0]);
      this.keyboardTop = Number(this.stickTo.replace(/[']/g, '').split(',')[1]);
    }
    else{
      this.keyboardTop = top + height;
      this.keyboardLeft = left + (this.inputElement.nativeElement.offsetWidth/2) - 200>=0 ? left + (this.inputElement.nativeElement.offsetWidth/2) - 200:0;
    }

    // Prevents the input from popping up
    this.inputElement.nativeElement.addEventListener("focus", (e: FocusEvent) => {
      e.preventDefault();
    });
  }

  public constructor(private readonly cdr: ChangeDetectorRef, @Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService) {
    this.questionEventsService.hideInputOnlyForManip.next(true);
  }
  
  public onChangeValue(val: string): void {
    this.value = val.replace(/^"(.+)"$/, "$1");

    if (val.length > this.maxLength) {
      this.value = "";
      this.cdr.detectChanges();
      const substring = val.substring(0, this.maxLength);
      this.value = parseInt(substring).toString();
    }
    if (this.onChangeCallback) {
      this.onChangeCallback(this.getScorableData());
    }
    this.removeStroke();

    this.inputElement.nativeElement.value = this.value;
  }

  public getScorableData(): ScorableData {
    const data: ScorableData = {
      answer: { [this.key]: this.value },
      state: { [this.key]: this.value },
    };

    return data;
  }

  public setScorableData(data: ScorableData): void {
    const keys = Object.keys(data.answer);

    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === this.key) {
        this.value = data.answer[keys[i]];
        this.inputElement.nativeElement.value = this.value;
        return;
      }
    }
  }
  public showInput() {
    this.setStroke();
    this.showInputKeypad = true;
  }

  public valueChanged(val: string): void {
    let text;

    if (!val) {
      text = "";
    } else if (val == "00" || val == "0") {
      text = "0";
    } else {
      text =  val.replace(/^[0.]+/, "").toString();
    }

    this.value = text;

    if (val.length > this.maxLength) {
      this.value = "";
      this.cdr.detectChanges();
      const substring = val.substring(0, this.maxLength);
      this.value = parseInt(substring).toString();
    }

    if (this.onChangeCallback) {
      this.onChangeCallback(this.getScorableData());
    }

    this.inputElement.nativeElement.value = this.value;
  }

  public KeyboardStyles(): Record<string, any> {
    return {
      left: `${this.keyboardLeft}px`,
      top: `${this.keyboardTop}px`,
    };
  }

  public onClose = () => {
    this.showInputKeypad = false;
    this.keyboardPositionSet = false;
  };

  public setOriginMode(origin: RegionOriginMode): void {
    this.origin = origin;
  }

  public setOffset(offset: number): void {
    this.parentOffset = offset;
  }

  public setStroke(): void {
    this.borderColor = InputColor.ACTIVE_COLOR;
  }

  public removeStroke(): void {
    this.borderColor = this.value
      ? InputColor.ACTIVE_COLOR
      : InputColor.DEFAULT_COLOR;
  }
}
