import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FabricDirtyStatusService {

	constructor() { }


	dirty: boolean = false;

	private checkSaveStatus() {
		if (this.isDirty()) {
			return "Oops! You have unsaved changes.\n\nPlease save before leaving so you don't lose any work.";
		}
		return undefined;
	}

	private endListening = () => {
		window.onbeforeunload = null;
		window.onhashchange = null;
	};

	public startListening = () => {
		window.onbeforeunload = this.checkSaveStatus;
		window.onhashchange = this.checkSaveStatus;
	};

	public isDirty() {
		return this.dirty;
	};

	public setDirty(value: boolean) {
		this.dirty = value;
	};


}
