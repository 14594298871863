import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TEMPLATE_PREFIX } from '../../../../environments/locale-config';
@Component({
  selector: 'kh-manip-imageswap',
  templateUrl: TEMPLATE_PREFIX + 'manip-imageswap.component.html',
  styleUrls: ['./manip-imageswap.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ManipImageswapComponent {
  public imageSources: string[] = [];
  public displayControlBarTour = false;

  @Input() public itemIndex: number = 0;
  @Input() public minHeight: number = 0;
  @Input() public reportMode: boolean = false;
  @Output() public itemIndexChange: EventEmitter<number> = new EventEmitter<number>();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  @Input() public set imageSource(text: string) {
    this.imageSources = text.split(',').map((url) => url.trim());
  }

  public showControlModal(): void {
    this.displayControlBarTour = true;
    setTimeout(() => {
      this.displayControlBarTour = false;
      this.changeDetectorRef.markForCheck();
    }, 3500);
  }

  public animateControlBar(): void {
    //  TODO: need to use AngularX way @ViewChild
    let studentControlBars = document.getElementsByClassName('ManipIntegerClickerBar');

    for (let i = 0; i < studentControlBars.length; i++) {
      if (studentControlBars[i].classList) {
        studentControlBars[i].classList.add('animated');
        studentControlBars[i].classList.add('bounceInDown');
      }
      else studentControlBars[i].className += ' animated bounceInDown';
    }
    //  TODO: need to use AngularX way @ViewChild
    studentControlBars = document.getElementsByClassName('ManipIntegerClickerBar');

    setTimeout(() => { // Remove animated classes for re-use
      const className = 'animated bounceInDown'; // previously "shake"
      for (let i = 0; i < studentControlBars.length; i++) {
        if (studentControlBars[i].classList) {
          studentControlBars[i].classList.remove('animated');
          studentControlBars[i].classList.remove('bounceInDown');
        }
        else {
          studentControlBars[i].className = studentControlBars[i].className
          .replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
      }

      this.changeDetectorRef.markForCheck();
    }, 1000);
  }

  public canShowImage(index: number): boolean {
    return index == this.itemIndex;
  }

  public hideControlBarTour(): void {
    this.displayControlBarTour = false;
  }
}