import { GameEntity } from "../GameEntity";
import { Text } from "pixi.js";

export class FpsDisplay extends GameEntity {
    private fpsText: Text;
    private accumulatedTime = 0;
    private frames: number = 0;

    constructor() {
        super();

        this.fpsText = new Text('FPS: 0', { fill: 0xffffff });
        this.addChild(this.fpsText);
        this.gameInstance.addEntity(this, true);
    }

    public start(): void {

    }

    public update(deltaTime: number): void {
        this.frames++;
        this.accumulatedTime += deltaTime;
        if (this.accumulatedTime >= 1) {
            this.accumulatedTime -= 1;

            this.fpsText.text = `FPS: ${this.frames}`;
            this.frames = 0;
        }
    }
}