/* eslint-disable indent */
import { GamepadEventKey } from './GamepadEventKeys';
import { KeyboardEventKey } from './KeyboardEventKeys';

export const enum InputBindingType {
    Keyboard = 'keyboard',
    Mouse = 'mouse',
    Gamepad = 'gamepad'
}

type KeyboardState = {
    [key in KeyboardEventKey]?: boolean;
}

export class InputManager<T extends number> {
    private eventMap: Map<T, Set<KeyboardEventKey>>= new Map();
    private keyboardState: KeyboardState = {};

    public constructor() {
        window.addEventListener('keydown', (event: KeyboardEvent) => {
            this.keyboardState[event.key as KeyboardEventKey] = true;
        });

        window.addEventListener('keyup', (event: KeyboardEvent) => {
            this.keyboardState[event.key as KeyboardEventKey] = false;
        });
    }

    public setKeyboardBinding(eventName: T, eventKey: KeyboardEventKey | KeyboardEventKey[]) {
        if (Array.isArray(eventKey)) {
            eventKey.forEach(key => {
                this.setKeyboardBinding(eventName, key);
            });
        } else {
            if (this.eventMap.has(eventName)) {
                this.eventMap.get(eventName)?.add(eventKey);
            } else {
                this.eventMap.set(eventName, new Set([eventKey]));
            }
        }
    }

    public removeKeyboardBinding(eventName: T, eventKey: KeyboardEventKey) {
        if (this.eventMap.has(eventName)) {
            this.eventMap.get(eventName)?.delete(eventKey);
        }
    }

    public isEvent(event: T) {
        if (this.eventMap.has(event)) {
            const e = this.eventMap.get(event);

            if (e) {
                return Array.from(e).some(key => this.keyboardState[key]);
            }
        }

        return false;
    }

    public isAnyEvent(event: T[]) {
        return event.some(e => this.isEvent(e));
    }
}
