import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild
} from "@angular/core";
import { IScorable, ScorableData } from "../kh-manip-scorable/IScorable";
import { Inject } from '@angular/core';
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from "../../../../services/IQuestionEvents";
import { applyFrameToElement } from "../../../../utils/utils";
import { InputColor } from "../../../enums/input-color.enum";

@Component({
  selector: "kh-manipulative-input",
  templateUrl: "kh-manip-scorable-input.component.html",
  styleUrls: ["kh-manip-scorable-input.component.less"],
})
export class KhManipScorableInput implements IScorable, AfterViewInit {
  
  @Input("value") public value: string = "";
  @Input("key") public key: string = "";
  @Input("name") public name = "";
  @Input("id") public id = "";
  @Input("frame") public frame = "0 0 100 100";
  @Input("font-family") protected fontFamily = "arial";
  @Input("strings") protected strings: string = "";
  @Input("max-length") public maxLength = 40;
  @Input("text-size") public textSize = "80%";
  @Input("type") public type = "text";
  @Input("border-radius") public borderRadius = 0;
  @Input("border-stroke-size") public borderStrokeSize = 0;
  @Input("background-color") public backgroundColor = "";
  @Input("text-alignment") public textAlignment = "center";
  @Input("text-style") public fontStyle = "normal"; 
  @Input("broken-keys") public brokenKeys: number[] = []; 
  @ViewChild("scorableInput") public inputElement!: ElementRef; 
  public onChangeCallback: undefined | ((data: ScorableData) => void); // callback 

  public fontWeight = "";
  public borderColor = InputColor.DEFAULT_COLOR;
  public fontSize = 16;

  public constructor(@Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService) {
    this.questionEventsService.hideInputOnlyForManip.next(true);
  }

  public ngOnInit() {
    this.removeStroke();
    const [left, top, width, height] = this.frame.split(' ').map(Number);
    this.fontSize = this.textSize.endsWith('%') ? Number(this.textSize.replace('%', '')) / 100 * height : Number(this.textSize);
  }

  public ngAfterViewInit(): void {
    if (this.inputElement?.nativeElement) {
      this.inputElement.nativeElement.value=this.value;
      this.inputElement.nativeElement.key=this.key;
      this.inputElement.nativeElement.name=this.name;
      this.inputElement.nativeElement.id=this.id;
      this.inputElement.nativeElement.style.fontFamily = this.fontFamily;
      this.inputElement.nativeElement.style.fontSize = `${this.fontSize}px`;
      this.inputElement.nativeElement.style.backgroundColor = this.backgroundColor;
      this.inputElement.nativeElement.style.borderColor = this.borderColor;
      this.inputElement.nativeElement.style.borderRadius = `${this.borderRadius}px`;
      this.inputElement.nativeElement.style.borderWidth = `${this.borderStrokeSize}px`;
      this.inputElement.nativeElement.style.textAlignment = this.textAlignment;
      this.inputElement.nativeElement.style.fontStyle = this.fontStyle;
      this.inputElement.nativeElement.style.fontWeight =  this.fontStyle;
      this.inputElement.nativeElement.type = this.type;
      this.inputElement.nativeElement.maxLength = this.maxLength;
    }

    if (this.inputElement?.nativeElement) {
      applyFrameToElement(this.inputElement.nativeElement, this.frame);
      this.inputElement.nativeElement.addEventListener("input", () =>
        this.onChange()
      );
      this.inputElement.nativeElement.value = this.value;
      this.inputElement.nativeElement.addEventListener("focus", () =>
        this.setStroke()
      );
      this.inputElement.nativeElement.addEventListener("blur", () =>
        this.removeStroke()
      );
      this.inputElement.nativeElement.addEventListener("onwheel", () =>
        this.removeStroke()
      );
    }
  }

  private onChange(): void {
    this.value = this.inputElement?.nativeElement.value;

    if (this.onChangeCallback) {
      this.onChangeCallback(this.getScorableData());
    }
    this.removeStroke();
  }

  public getScorableData(): ScorableData {
    const data: ScorableData = {
      answer: { [this.key]: this.value },
      state: { [this.key]: this.value },
    };

    return data;
  }

  public setScorableData(data: ScorableData): void {

    const keys = Object.keys(data.answer);

    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === this.key) {
        this.value = data.answer[keys[i]];
        this.inputElement.nativeElement.value = this.value;

        return;
      }
    }
  }

  public setStroke(): void {
    this.borderColor = InputColor.ACTIVE_COLOR;
  }

  public removeStroke(): void {
    this.borderColor = this.value ? InputColor.ACTIVE_COLOR : InputColor.DEFAULT_COLOR;
  }
}
