import { KHPoint } from "../../../LearningWorlds/pixi-puzzle-framework/PuzzleEngine/KHPoint";
import { SvgCircle, SvgGroup, SvgText, SvgRenderer } from "../../../LearningWorlds/svg-components";
import { ManipFrame } from "../../../utils/utils";

export type SvgGraphingDragPointConfig = {
    x: number;
    y: number;
    radius: number;
    color: string;
    xSnapInterval: number;
    ySnapInterval: number;
    onClick?: () => void;
    onChangePosition?: (x: number, y: number) => void;
    attractMode?: boolean; // should this display a highlight that goes away on first interaction?
    label: string;
    key?: string;
    highlightColor?: string;
    bounds?: ManipFrame;
    originX: number;
    originY: number;
}

export class SvgGraphingDragPoint {
  private point: SvgCircle;
  private group: SvgGroup;
  private highlight: SvgCircle;
  private label: SvgText;
  private selected = false;
  private renderer: SvgRenderer;
  private xOffset = 0;
  private yOffset = 0;
  private config: SvgGraphingDragPointConfig;

  public constructor(renderer: SvgRenderer, parent: SVGElement, config: SvgGraphingDragPointConfig) {
    this.config = config;
    this.renderer = renderer;

    this.xOffset = this.config.x % this.config.xSnapInterval;
    this.yOffset = this.config.y % this.config.ySnapInterval;

    this.group = new SvgGroup(renderer, parent, {
      x: config.x,
      y: config.y
    });

    this.highlight = new SvgCircle(renderer, this.group.element, {
      fill: 'red',
      maintainScale: true,
      opacity: 0.5,
      r: this.config.radius * 2,
      stroke: 'red',
      x: 0,
      y: 0
    });

    this.point = new SvgCircle(renderer, this.group.element, {
      fill: this.config.color,
      maintainScale: true,
      r: this.config.radius,
      stroke: this.config.color,
      x: 0,
      y: 0});

    this.label = new SvgText(renderer, this.group.element, {
      maintainScale: true,
      text: this.config.label,
      x: 0,
      y: -20
    });

    renderer.setClickable(this.point, this.onClick.bind(this));
    renderer.setClickable(this.highlight, this.onClick.bind(this));
    renderer.setDraggable(this.point, this.onStartDrag.bind(this), this.onDrag.bind(this), this.onDragEnd.bind(this));
    renderer.setDraggable(this.highlight, this.onStartDrag.bind(this), this.onDrag.bind(this), this.onDragEnd.bind(this));
  }

  public setOnClick(onClick: () => void): SvgGraphingDragPoint {
    this.config.onClick = onClick;

    return this;
  }

  public setOnChangePos(onNewPos: (x: number, y: number) => void): SvgGraphingDragPoint {
    this.config.onChangePosition = onNewPos;

    return this;
  }

  private onClick(): void {
    if (this.highlight) {
      this.highlight.element.style.opacity = '0';
    }

    if (this.config.onClick) {
      this.config.onClick();
    }
  }

  public setSelected(selected: boolean): void {
    this.selected = selected;
    this.point.setConfigValue('stroke', this.selected ? 'red' : 'black');
  }

  private onStartDrag(): void {
    this.onClick();
    this.renderer.camera.canPan = false;
  }

  private onDragEnd(): void {
    this.renderer.camera.canPan = true;
  }

  private onDrag(ev: KHPoint): void {
    const newCoords = this.renderer.camera.getWorldCoordinates({x: ev.x - this.xOffset, y: ev.y - this.yOffset});
    if (this.inFrame(newCoords)) {
      const roundX = Math.round(newCoords.x / this.config.xSnapInterval) * this.config.xSnapInterval + this.xOffset;
      const roundY = Math.round(newCoords.y / this.config.ySnapInterval) * this.config.ySnapInterval + this.yOffset;

      // ensure we're actually changing position (eg, x and y aren't the same as they arelady are)
      if (this.config.onChangePosition && (this.point.getConfig().x !== roundX || this.point.getConfig().y !== roundY)) {
        this.changePosition(roundX, roundY);
      }
    }
  }

  private inFrame(coordinates: KHPoint) {
    if (this.config.bounds) {
      return coordinates.x >= this.config.bounds.x && coordinates.x <= this.config.bounds.width &&
        coordinates.y >= this.config.bounds.y && coordinates.y <= this.config.bounds.height;
    }

    return true;
  }

  private changePosition(newX: number, newY: number): void {
    this.config.x = newX;
    this.config.y = newY;
    this.group.setConfigValue('x', newX);
    this.group.setConfigValue('y', newY);
    this.signalChange();
  }

  public setPosX(integerX: number): void {
    const coords = this.getGraphCoordinates({x: integerX, y: 0});
    this.config.x = coords.x;
    this.group.setConfigValue('x', coords.x);
    this.signalChange();
  }

  public setPosY(integerY: number): void {
    const coords = this.getGraphCoordinates({x: 0, y: integerY});
    this.config.y = coords.y;
    this.group.setConfigValue('y', coords.y);
    this.signalChange();
  }

  private signalChange(): void {
    if (this.config.onChangePosition) {
      this.config.onChangePosition(this.config.x, this.config.y );
    }
  }

  public getPos(): {x: number, y: number} {
    return {x: (this.config.x) / this.config.xSnapInterval,
      y: (this.config.y * -1) / this.config.ySnapInterval};
  }

  /**
   * Given the graph origin, we can determine the actual value of this point on the graph
   * @param originX origin x of the graph
   * @param originY origin y of the graph
   */
  public getGraphValue(): KHPoint {
    const pXval = Math.round((this.config.x - this.xOffset) / this.config.xSnapInterval);
    const pYval = Math.round((this.config.y * -1 - this.yOffset) / this.config.ySnapInterval);
    const originOffsetX = (this.config.originX - this.xOffset)/ this.config.xSnapInterval;
    let originOffsetY = (this.config.originY + this.yOffset) / this.config.ySnapInterval;

    // if originOffsetY is not 0, then we need to flip the sign.
    // Remember that in JS you can have "-0" cause the language is horrifying, so we can't just blindly multiply by -1
    if (originOffsetY !== 0) {
      originOffsetY *= -1;
    }

    return {x: Math.round(pXval - originOffsetX), y: Math.round(pYval - originOffsetY)};
  }

  public getGraphCoordinates(position: KHPoint): { x: number, y: number } {
    const screenX = position.x * this.config.xSnapInterval + this.xOffset + this.config.originX;
    const screenY = -position.y * this.config.ySnapInterval + this.yOffset + this.config.originY;

    return { x: screenX, y: screenY };
  }

  public getKey(): string {
    if (this.config.key) {
      return this.config.key;
    }

    return '';
  }
}
