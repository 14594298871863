import { Component } from '@angular/core';
import { BackwardComponent } from '../backward/backward.component';

@Component({
  selector: 'forward',
  template: '',
  styleUrls: []
})
export class ForwardComponent extends BackwardComponent {

  public override click(): void {
    this.onChange.next(1);
  }

}
