import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { TEMPLATE_PREFIX } from '../../../../environments/locale-config';

@Component({
  selector: 'kh-manip-equiv-fractions',
  templateUrl: TEMPLATE_PREFIX + 'manip-equiv-fractions.component.html',
  styleUrls: ['./manip-equiv-fractions.component.less']
})
export class ManipEquivFractionsComponent implements OnInit {
  public target: Record<string, any> = {};
  public userInput: Record<string, any> = {};

  public pieChartLabels = ['Selected', 'Not Selected'];
  public pieChartOptions: any = {responsive: true};
  public pieChartType: string = 'pie';

  @Input() public isHoriz!: boolean;
  @Input() public height!: string;
  @Input() public isPieChart = false;
  @Input() public targetNumRows!: number;
  @Input() public targetNumCols!: number;
  @Input() public targetColour!: string;
  @Input() public targetNumerator!: number;
  @Input() public userNumRows!: number;
  @Input() public userNumCols!: number;
  @Input() public userColour!: string;

  @Output() public numeratorChange = new EventEmitter<string>();
  @Output() public submitAnswer = new EventEmitter<string>();

  private readonly defaultTargetColour = '#CCF139'; // light green
  private readonly defaultUserColour = '#01B4FF'; // aqua
  private userNumerator = 0;

  public get numerator(): string {
    return this.userNumerator.toString();
  }

  @Input()
  public set numerator(value: string) {
    this.userNumerator = value ? parseInt(value, 10) : 0;
    this.userInput['numerator'] = this.userNumerator;
  }

  public get blockImageClasses(): Record<string, boolean> {
    return {
      'col-6': this.target.isHoriz,
      'col-12': !this.target.isHoriz
    };
  }

  public ngOnInit(): void {
    const targetGrid = Array(this.targetNumRows);

    for (let i = 0; i < targetGrid.length; i++) {
      targetGrid[i] = Array(this.targetNumCols);

      for (let j = 0; j < targetGrid[i].length; j++) {
        targetGrid[i][j] = i * this.targetNumCols + j + 1;
      }
    }

    this.target = {
      numRows: this.targetNumRows,
      numCols: this.targetNumCols,
      colour: !this.targetColour ? this.defaultTargetColour : this.targetColour,
      numerator: this.targetNumerator,
      isHoriz: this.isHoriz,
      height: this.height,
      chart: [1, 3],
      denominator: this.targetNumRows * this.targetNumCols,
      grid: targetGrid
    };

    const userGrid = Array(this.targetNumRows);

    for (let i = 0; i < userGrid.length; i++) {
      userGrid[i] = Array(this.userNumCols);

      for (let j = 0; j < userGrid[i].length; j++) {
        userGrid[i][j] = i * this.userNumCols + j + 1;
      }
    }

    this.userInput = {
      numRows: this.userNumRows,
      numCols: this.userNumCols,
      colour: !this.userColour ? this.defaultUserColour : this.userColour,
      denominator: this.userNumRows * this.userNumCols,
      grid: userGrid,
      numerator: this.userNumerator
    };
  }

  public getGridStyles(fracCol: any, group: Record<string, any>): Record<string, string> {
    return {
      'background-color': (fracCol <= group.numerator) ? group.colour : '#FFFFFF',
      width: 100 / group.numCols + '%'
    };
  }

  public getChartData(group: Record<string, any>): any[] {
    return [group.numerator, group.grid.length * group.grid[0].length - group.numerator];
  }

  public numeratorChanged(numerator: string) {
    this.numeratorChange.emit(numerator);
  }

  public submit(answer: string) {
    this.submitAnswer.emit(answer);
  }
}
