import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { TEMPLATE_PREFIX } from '../../../../environments/locale-config';
import { NumWithSignPipe } from '../../../pipes';
import { BlockRowData } from './block-row-data';
import { Inject } from '@angular/core';
import { QUESTION_EVENTS_SERVICE_TOKEN, IQuestionEventsService } from '../../../../services/IQuestionEvents';

@Component({
  selector: 'kh-manip-subtractingblocks',
  templateUrl: TEMPLATE_PREFIX + 'manip-subtractingblocks.component.html',
  styleUrls: ['./manip-subtractingblocks.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NumWithSignPipe]
})
export class ManipSubtractingblocksComponent implements OnInit {
  public term: number[] = [];
  public sign: number[] = [];
  public singleRow = false;
  public topRow: BlockRowData[] = [];
  public bottomRow: BlockRowData[] = [];
  public fixedBlocks: BlockRowData[] = [];

  public expression = '';
  public numZeroPairs = 0;
  public numRemoved = 0;

  @Input() public term1!: number;
  @Input() public term2!: number;

  @Output() public valueChange = new EventEmitter<number>();

  private internallySet = false;
  private val: number = 0;

  constructor(
    private readonly numWithSign: NumWithSignPipe,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) private readonly questionEventsService: IQuestionEventsService) {
  }

  public get canSqueezeBlocks(): boolean {
    return this.fixedBlocks.length + this.topRow.length >= 5 || this.fixedBlocks.length + this.bottomRow.length >= 5;
  }

  public get value(): number {
    return this.val;
  }

  @Input()
  public set value(value: number) {
    this.val = value;
    this.valueUpdated(value);
  }

  public ngOnInit(): void {
    this.questionEventsService.hideInputOnlyForManip.next(true);

    this.term = [this.term1, this.term2];
    this.sign = [Math.sign(this.term[0]), Math.sign(this.term[1])];

    this.singleRow = (Math.abs(this.term[0]) > Math.abs(this.term[1])) && (this.sign[0] === this.sign[1]);

    const skipUpdateResults = !(!this.value && this.value !== 0);
    this.reset(skipUpdateResults);
    this.changeDetectorRef.markForCheck();
  }

  public addZeroPair(skipUpdateResults?: boolean): void {
    if (this.topRow.length + this.fixedBlocks.length >= 8 || this.bottomRow.length + this.fixedBlocks.length >= 8) {
      return;
    }

    this.topRow.push({value: this.sign[0]});
    this.bottomRow.push({value: -this.sign[0]});
    this.numZeroPairs++;
    if (!skipUpdateResults) {
      this.updateResults();
    }
  };

  public removeBlock(skipUpdateResults?: boolean): void {
    if (this.singleRow) {
      for (let i = 0; i < this.topRow.length; i++) {
        if (this.topRow[i].value !== 0) {
          this.numRemoved += this.topRow[i].value;
          this.topRow[i].value = 0;
          break;
        }
      }
    }
    else {
      if (this.sign[0] === this.sign[1] && Math.abs(this.term[0]) < Math.abs(this.term[1])) {
        let Removed = false;
        for (let i = 0; i < this.fixedBlocks.length; i++) {
          if (this.fixedBlocks[i].value !== 0) {
            this.numRemoved += this.fixedBlocks[i].value;
            this.fixedBlocks[i].value = 0;
            Removed = true;
            break;
          }
        }

        if (!Removed) {
          for (let i = 0; i < this.topRow.length; i++) {
            if (this.topRow[i].value !== 0) {
              this.numRemoved += this.topRow[i].value;
              this.topRow[i].value = 0;
              break;
            }
          }
        }
      }
      else {
        for (let i = 0; i < this.bottomRow.length; i++) {
          if (this.bottomRow[i].value !== 0) {
            this.numRemoved += this.bottomRow[i].value;
            this.bottomRow[i].value = 0;
            break;
          }
        }
      }
    }
    if (!skipUpdateResults) {
      this.updateResults();
    }
  };

  public reset(skipUpdateResults?: boolean) {
    this.numZeroPairs = 0;
    this.numRemoved = 0;
    this.fixedBlocks = [];
    this.topRow = [];
    this.bottomRow = [];

    if (this.singleRow) {
      for (let i = 0; i < Math.abs(this.term[0]); i++) {
        this.topRow.push({value: this.sign[0]});
      }

    }
    else {
      for (let i = 0; i < Math.abs(this.term[0]); i++) {
        this.fixedBlocks.push({value: this.sign[0]});
      }
    }

    if (!skipUpdateResults) {
      this.updateResults();
    }
  }

  private updateResults() {
    if (!this.singleRow) {
      for (let i = 0; i < Math.min(this.topRow.length, this.bottomRow.length); i++) {
        const isZeroPair = (this.topRow[i].value + this.bottomRow[i].value === 0);
        this.topRow[i].isZeroPair = isZeroPair;
        this.bottomRow[i].isZeroPair = isZeroPair;
      }
    }

    this.expression = '(' + this.numWithSign.transform(this.term[0]) + ')';
    if (this.numZeroPairs > 0) {
      this.expression += ' + (+' + this.numZeroPairs + ') + (-' + this.numZeroPairs + ')';
    }

    if (this.numRemoved !== 0) {
      this.expression += ' - (' + this.numWithSign.transform(this.numRemoved) + ')';

      if (this.numRemoved < 0) {
        this.expression += ' = (' + this.numWithSign.transform(this.term[0]) + ') + (' + this.numWithSign.transform(-this.numRemoved) + ')';
      }
    }

    this.internallySet = true;

    let value = 0;
    this.fixedBlocks.forEach((block) => {
      value += block.value;
    });
    this.topRow.forEach((block) => {
      value += block.value;
    });
    this.bottomRow.forEach((block) => {
      value += block.value;
    });

    this.val = value;
    this.valueChange.emit(this.val);
  };

  private valueUpdated(value: number): void {
    if (this.internallySet) {
      this.internallySet = false;
      return;
    }
    if (!value && value !== 0) {
      return;
    }

    // TODO: ask what does it means
    return; //Until we can fix the directive not destroying. This means reports will not populate
    const subtract = this.term[0] - value;

    if (this.singleRow) {
      for (let i = 0; i < Math.abs(subtract); i++) {
        this.removeBlock(true);
      }
    }
    else {
      let numZeroPairsToAdd = this.sign[0] === Math.sign(subtract) ?
        Math.abs(subtract) - this.fixedBlocks.length : Math.abs(subtract);

      for (let i = 0; i < numZeroPairsToAdd; i++) {
        this.addZeroPair(true);
      }

      for (let i = 0; i < Math.abs(subtract); i++) {
        this.removeBlock(true);
      }
    }

    this.updateResults();
  }
}
