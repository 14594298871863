import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild} from '@angular/core';

import { DraggableChildren } from './draggable-children';
import { DraggableComponent } from '../draggable/draggable.component';
import { DynamicContentChild, OnDynamicData, OnDynamicMount } from 'ngx-dynamic-hooks';
import { forOwn } from 'lodash-es';
import { IEvent, Image, Point } from 'fabric/fabric-impl';
import { IntInputRegionComponent } from '../int-input-region/int-input-region.component';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FabricCanvas } from '../../../../services/fabric-canvas.service';
import { FabricConstantsService } from '../../../../services/fabric-constants.service';
import { FabricDraggable } from '../../../../utils/fabric-utils/fabric-draggable';
import { FabricDraggableGrid } from '../../../../utils/fabric-utils/fabric-draggable-grid';
import { DraggableTokenizer } from '../../../../utils/fabric-utils/fabric-draggable-utils';
import { FabricIntInputRectRegion } from '../../../../utils/fabric-utils/fabric-int-input-rect-region';
import { FabricOvalRegion } from '../../../../utils/fabric-utils/fabric-oval-region';
import { FabricRectRegion } from '../../../../utils/fabric-utils/fabric-rect-region';
import { FabricRegion } from '../../../../utils/fabric-utils/models/fabric-region';
import { FabricExtendedCanvas } from '../../../fabric/angular-fabric';
import { AngularFabricService } from '../../../fabric/angular-fabric.service';
import { RegionComponent } from '../region/region.component';
import { TEMPLATE_PREFIX } from '../../../../environments/locale-config';
import { Inject } from '@angular/core';
import { QUESTION_EVENTS_SERVICE_TOKEN, IQuestionEventsService } from '../../../../services/IQuestionEvents';
import { Observable, forkJoin, from, ReplaySubject } from 'rxjs';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FabricCanvas, AngularFabricService],
  selector: 'kh-manip-draggable',
  styleUrls: ['./manip-draggable.component.less'],
  templateUrl: TEMPLATE_PREFIX + 'manip-draggable.component.html'
})
export class ManipDraggableComponent implements OnInit, AfterViewInit, OnDynamicMount {
  @ViewChild('canvas') public canvasEl?: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasContainer') public canvasContainerElement?: ElementRef<HTMLDivElement>;

  public regionsList: (RegionComponent | IntInputRegionComponent)[] = [];
  public regions: FabricRegion[] = [];
  public answer: Record<string, any> = {};
  public keyboards: FabricIntInputRectRegion[] = [];
  public rendered = false;
  public image!: Image;
  public grid!: FabricDraggableGrid;
  public manipTextList: fabric.Object[] = [];

  @Input() public src = '';
  @Input() public width = 0;
  @Input() public height = 0;
  @Input() public isSingleMode = true;
  @Input() public backgroundImage = '';
  @Input() public reuseObjects = false;
  @Input() public debug = false;
  @Input() public reportMode = false;
  @Input() public wiggle = false;
  @Input() public returnUnregistered = true;
  @Input() public svgOnly = false;
  @Input() public value = '';
  @Input() public sampleAnswer = '';

  @Output() public valueChange = new EventEmitter<string>();

  private draggables: FabricDraggable[] = [];
  private canvas!: FabricExtendedCanvas;
  private canRender$ = new ReplaySubject<void>(1);
  private touched = false;
  private isInit = true;

  public constructor(
    public readonly fabricConstants: FabricConstantsService,
    private readonly angularFabric: AngularFabricService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public readonly elementRef: ElementRef,
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) private readonly questionEventsService: IQuestionEventsService) {
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    this.resizeWorks();
  }

  public ngOnInit(): void {
    this.canRender$
      .pipe(
        tap(() => {
          this.render();
          this.resizeWorks();
          this.canvas.renderAll();
          this.isInit = false;
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.questionEventsService.hideInputOnlyForManip.next(true);
    this.changeDetectorRef.markForCheck();
  }

  public resizeWorks(): void {
    if (this.canvasContainerElement && this.canvas) {
      const newWidth = this.canvasContainerElement.nativeElement.getBoundingClientRect().width;
      if (newWidth <= this.width) {
        const scaleFactor = newWidth / this.width;
        this.angularFabric.setCanvasSize(
          this.width * scaleFactor,
          Math.max(this.height, this.grid.height) * scaleFactor);
        this.canvas.setZoom(scaleFactor);
      }
    }
  }


  public ngAfterViewInit(): void {
    this.initializeFabric();

    this.canvas = this.angularFabric.getCanvas();
    this.canvas.on({
      'keyboard:onOpen': (e: IEvent) => this.onKeyboardOpenHandler(e),
      'mouse:down': (e: IEvent) => this.onMouseDownHandler(e),
      'object:modified': (e: IEvent) => this.onObjectModifiedHandler(e),
      'region:onChange': ({ target }) => this.onRegionChange(target as FabricRectRegion),
      'object:moving': (e: IEvent) => this.onMovingDraggable(e)
    });
  }

  public onDynamicMount(data: OnDynamicData): void {
    const contentChildren: DynamicContentChild[] = data.contentChildren ?? [];

    const children: DraggableChildren = {
      draggables: this.draggables,
      regions: this.regions
    };

    this.registerChildren(contentChildren, children);

    const loaders: Observable<any>[] = [];
    loaders.push(this.loadBackground(this.backgroundImage));
    // Preload all required images and wait till it completes
    this.draggables.forEach(draggable => {
      loaders.push(draggable.load(this.svgOnly));
    });

    forkJoin(loaders)
      .pipe(
        tap(() => {
          this.debugLog('Image Preload complete');
          this.canRender$.next();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }


  public reset(): void {

    this.canvas = this.angularFabric.getCanvas();

    this.regions.forEach(region => region.reset());

    this.grid.reset();

    if (this.canvas.height && (this.canvas.height < this.height + this.grid.height)) {
      this.angularFabric.setCanvasSize(this.width, this.height + this.grid.height);
    }

    this.grid.setItemPositions(this.grid.grid.lines, true);
    this.answer = {};
  }

  private debugLog(...args: any[]): void {
    if (this.debug) {
      Array.prototype.unshift.call(args, 'KhManipDraggable');
    }
  }

  private initializeFabric(): void {
    const options = {
      JSONExportProperties: this.fabricConstants.JSONExportProperties,
      shapeDefaults: this.fabricConstants.shapeDefaults,
      textDefaults: this.fabricConstants.textDefaults
    };

    const canvas = this.canvasEl?.nativeElement as HTMLCanvasElement;

    this.angularFabric.initialize(options, canvas);

    this.angularFabric.setCanvasSize(this.width, this.height);
  }

  private registerChildren(contentChildren: DynamicContentChild[], children: DraggableChildren): void {
    contentChildren.forEach(item => {
      const instance = item.componentRef.instance;

      if (instance instanceof RegionComponent || instance instanceof IntInputRegionComponent) {
        this.regionsList.push(instance);
      }

      if (instance instanceof DraggableComponent) {
        this.registerDraggable(instance.getDraggable());
      }

      if (item.contentChildren.length) {
        this.registerChildren(item.contentChildren, children);
      }
    });
  }

  private registerDraggable(draggable: FabricDraggable): void {
    draggable.index = this.draggables.length + 1;
    this.draggables.push(draggable);
    this.debugLog('register new Draggable Definition');
  }

  private registerRegion(regionEl: RegionComponent | IntInputRegionComponent, areaHeight: number): FabricRegion {
    const region = regionEl.getRegion(areaHeight);

    if (region.type === 'intInputRegion') {
      this.keyboards.push(region as FabricIntInputRectRegion);
    }

    this.regions.push(region);

    // for draggable targets we want to use their default values
    if (region instanceof FabricRectRegion || region instanceof FabricOvalRegion) {
      this.setDefaultAnswerForRegion(region);
    }

    if (region.setObjectsCoords) {
      region.setObjectsCoords();
    }

    this.debugLog('register new Region Definition');

    return region;
  }

  private render(): void {
    this.debugLog('Render all objects on Canvas');
    this.rendered = true;
    this.canvas.add(this.image);

    this.regionsList.forEach((regionEl: RegionComponent | IntInputRegionComponent) => {
      const region = this.registerRegion(regionEl, this.height);
      
      if (regionEl instanceof IntInputRegionComponent) {
        regionEl.setOffset(15); // I have no idea why, but draggables always have an extra 15px height that needs to be accounted for
      }
      this.canvas.add(region);
    });

    // FabricDraggableGroup
    this.draggables.forEach((draggable: FabricDraggable) => {
      this.canvas.add(draggable);
      draggable.reset();
    });

    let top = 0;
    this.draggables.forEach(draggable => {
      if (draggable.initialPosition && draggable.height && draggable.positionTop) {
        top = Math.min(top, draggable.positionTop - draggable.height / 2 - 3);
      }
    });

    this.grid = new FabricDraggableGrid(
      this.draggables.filter(draggable => !draggable.initialPosition),
      this.canvas,
      this.height,
      this.reuseObjects,
      this.returnUnregistered
    );

    if (this.canvas.height) {
      this.grid.setDraggablePosition(this.draggables, this.height);
    }

    this.grid.setItemPositions();
    this.angularFabric.forceRefresh();

    if (this.canvas.height) {
      this.angularFabric.setCanvasSize(this.width, Math.max(this.height, this.grid.height));
    }

    if (this.sampleAnswer) {
      this.setInitialAnswers(this.sampleAnswer);
    } else if (this.value) {
      this.setInitialAnswers(this.value);
    }

    if (this.reportMode) {
      this.canvas.skipTargetFind = true;
      this.canvas.selection = false;

      this.canvas.hoverCursor = 'default';
    }

    this.manipTextList.forEach((manipText) => {
      this.canvas.add(manipText);
      this.canvas.bringToFront(manipText);
    })

    this.angularFabric.deselectActiveObject();
  }

  private loadBackground(img: string): Observable<any> {
    return from(this.angularFabric.loadImageURL(img).then(image => {
      this.image = image;
      image.selectable = false;
      this.changeDetectorRef.markForCheck();
    }));
  }

  private calculateDropRegion(region: any, draggables: any, answers: any): Record<string, number> {
    if (answers && answers.length > 0) {
      const answerWidth = Math.max(...answers.map((answer: any) => parseInt(draggables[answer]?.width, 10)));
      const answerHeight = Math.max(...answers.map((answer: any) => parseInt(draggables[answer]?.height, 10)));

      // vien diagram indicator. Should be replaced whith a better one when we have it.
      if (Math.trunc(region.width / answerWidth) > 1 && Math.trunc(region.height / answerHeight) > 1) {
        const lineItems = Math.ceil(Math.sqrt(answers.length));
        const centerRegion: Record<string, number> = {height: answerHeight * lineItems, width: answerWidth * lineItems};
        centerRegion.left = region.left + (region.width - centerRegion.width) / 2;
        centerRegion.top = region.top + (region.height - centerRegion.height) / 2;

        return centerRegion;
      }
    }

    return region;
  }

  private setInitialAnswers(currentAnswersString: string): void {
    const parsedQuestionAnswers = DraggableTokenizer(currentAnswersString);
    const draggables: Record<string, FabricDraggable> = {};

    this.draggables.forEach((draggable: FabricDraggable) => {
      draggables[draggable.value] = draggable;
    });

    const regions: Record<string, FabricRegion> = {};
    this.regions.forEach((region: FabricRegion) => {
      regions[region.itemOrder] = region;
    });

    for (const regionId in parsedQuestionAnswers) {
      const region = regions[regionId];
      if (!region) {continue;}
      if (region.type === 'intInputRegion') {
        (region as FabricIntInputRectRegion).setText(parsedQuestionAnswers[regionId][0]);
        continue;
      }

      const regionAnswers = parsedQuestionAnswers[regionId];
      const dropRegion = this.calculateDropRegion(region, draggables, regionAnswers);

      regionAnswers.forEach((regionAnswer: string, index: number) => {
        const currentDraggable: FabricDraggable = draggables[regionAnswer];

        if (!currentDraggable) {
          return;
        }

        if (this.reuseObjects) {
          draggables[regionAnswer] = this.grid.replaceByClone(currentDraggable);
        } else {
          this.grid.remove(currentDraggable);
        }

        this.setAnswerPosition(dropRegion, currentDraggable, index);
        this.grid.draggableModified(currentDraggable);
        region.drop(currentDraggable, this.isSingleMode);
        region.setStroke(true);
      });
    }
  }

  private setAnswerPosition(region: any, draggable: any, index: number): void {
    const answersPerLine = Math.max(Math.trunc(region.width / draggable.width), 1);
    const currentLine = Math.trunc(index / answersPerLine);
    draggable.left = region.left + draggable.width * (index - answersPerLine * currentLine);
    draggable.top = region.top + draggable.height * currentLine;
  }

  /**
   * Default the asnwer to be all 0 for all regions instead of 
   */
  private setDefaultAnswerForRegion(region: FabricRectRegion): void {
    const regionId = region.itemOrder ?? region._id;;

    if (region.defaultValue) {
      this.answer[regionId] = region.defaultValue;
    }    
  }

  private onRegionChange(region: FabricRectRegion): void {
    if (!region.ignoreInScore) {
      const itemOrder = region.itemOrder ?? region._id;
      const activeDraggables: FabricDraggable[] = region.activeDraggables;

      if (activeDraggables.length === 0) {
        // if a region hsa a default value, set that in the answer. Otherwise, delete it
        if (region.defaultValue) {
          this.answer[itemOrder] = region.defaultValue;
        } else {
          delete this.answer[itemOrder];
        }        
      } else {
        if (this.isSingleMode) {
          // turns out the variable called "activeDraggables" can be any manipulative not just draggables. Fun.
          // Some manips still use itemOrder isntead of value - this uses value if possible.
          this.answer[itemOrder] = activeDraggables[0].value ? activeDraggables[0].value : activeDraggables[0].name;
        } else {
          this.answer[itemOrder] = activeDraggables.map(({ value, name }) => value ? value : name);
        }
      }

      const regions: string[] = [];

      forOwn(this.answer, (Values, Key) => {
        let regionAswer = Key + ':';

        if (!Array.isArray(Values)) {
          regionAswer += Values;
        } else if (Values.length === 1) {
          regionAswer += Values[0];
        } else {
          regionAswer += `[${Values.join()}]`;
        }
        regions.push(regionAswer);
      });

      if (this.isInit) {
        return;
      }

      this.valueChange.emit(regions.join());
    }
  }

  private onMovingDraggable(event: IEvent): void {
    const regions: FabricRectRegion[] = this.canvas.getObjects('region') as FabricRectRegion[];
    let alreadySet = false;
    const pointer = event.pointer as Point;
    const target = event.target as FabricDraggable;

    for (let i = regions.length - 1; i >= 0; i--) {
      const region = regions[i];
      const x = pointer.x;
      const y = pointer.y;

      const left = region.left ?? 0;
      const width = region.width ?? 0;
      const top = region.top ?? 0;
      const height = region.height ?? 0;

      if (!alreadySet && x >= left && x <= left + width && y >= top && y <= top + height) {
        if (!this.isSingleMode || region.activeDraggables.length === 0) {
          alreadySet = true;
          region.dragIn(target);
        }
      } else {
        if (region.active) {
          region.dragOut(target);
        }
        region.setStroke(false);
      }
    }
  }

  private onMouseDownHandler(e: IEvent): void {
    const target = e.target as any;

    if (this.wiggle && !this.value && !this.touched && (!target || !target.insideDraggableArea)) {
      this.grid.wiggleDraggables(this.draggables);
    } else if (target?.insideDraggableArea) {
      this.touched = true;
    }

    if (target?.region) {
      target.region.active = true;
    }
  }

  private onObjectModifiedHandler(e: IEvent): void {
    const target = e.target as FabricDraggable;
    target.initialPositionModifieds(target, this.height);
    const activeRegion: FabricRectRegion | undefined =
      this.regions.find(region => (region instanceof FabricRectRegion) && region.active) as FabricRectRegion;

    if (activeRegion) {
      activeRegion.drop(target, this.isSingleMode);
    }
  }

  private onKeyboardOpenHandler(e: IEvent): void {
    this.keyboards.forEach(k => {
      if (k !== e.target) {
        k.close()
      }
    })
  }
}
