<div class="ManipMultiply">
    <div class="block_images" [ngStyle]="{minHeight: minHeight}">
        <kh-img *ngFor="let image of images; trackBy: trackByIndex" [path]="imageSource" cssClass="multiply-img" alt=""></kh-img>
    </div>

    <div class="block_expression" *ngIf="displayExpression">
        <kh-integer-input [value]="val" (valueChange)="valueChanged($event)" [maxValue]="maxValue" [minValue]="minValue" [defaultValue]="defaultVal" [(itemIndex)]="itemIndex" (submitAnswer)="submit($event)"></kh-integer-input>
        &times;
        <span class="yVal" [ngStyle]="{color: multiplierColour}" [innerText]="multiplier"></span>
        =
        <span class="Result">{{product}}</span>
    </div>
</div>