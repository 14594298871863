/* eslint-disable max-len */
/* eslint-disable indent */
import { KHPoint } from '../../../LearningWorlds/pixi-puzzle-framework/PuzzleEngine/KHPoint';
import { SvgLine, SvgRenderer } from '../../../LearningWorlds/svg-components';
import { ManipFrame } from '../../../utils/utils';
import { IScorable, ScorableData } from '../manipulatives/kh-manip-scorable/IScorable';
import { SvgGraphingDragPoint } from './SvgGraphingDragPoint';

export type LinePoint = {
    x: number;
    y: number;
};

export type GraphLineType = 'x' | 'y';

export type GraphAxisData = {
  type: GraphLineType;
  unit: string;
  labelInterval: number;
  tickInterval: number;
  strokeWidth: number;
}

export type GraphPointData = {
  key: string;
  label: string;
  alias: string;
  x: number;
  y: number;
  radius: number;
  strokeWidth: number;
  color: string;
  scoreBy: string;
  highlightColor: string;
}

export type GraphLineData = {
  key: string;
  segment: boolean;
  score: string;
  point1: string;
  point2: string;
  strokeWidth: number;
  color: string;
  scoreBy: string;
}

export type GraphingConfig = {
    axisX: GraphAxisData;
    axisY: GraphAxisData;
    originX: number;
    originY: number;
    points: GraphPointData[];
    lines: GraphLineData[];
    camera: 'locked' | 'free';
    graphFrame: ManipFrame;
    parentFrame: ManipFrame;
    backgroundImage: string;
    color: string;
    hideGrid: boolean;
}

export class SvgGraphing implements IScorable {
    public onChangeCallback: undefined | ((data: ScorableData) => void); // callback
    private xAxis: SvgLine;
    private yAxis: SvgLine;
    private svgr: SvgRenderer;
    private gridGroup: SVGElement;
    private parent: SVGElement;
    private gridElements: SvgLine[] = [];

    private xSize = 1000;
    private ySize = 1000;
    private xSpacing = 102;
    private ySpacing = 102;

    private points: Map<string, SvgGraphingDragPoint> = new Map<string, SvgGraphingDragPoint>();
    private lineElements: Map<string, SvgLine> = new Map<string, SvgLine>();
    private lineLength = 1000;

    private config: GraphingConfig;

    public constructor(svgRenderer: SvgRenderer, parent: SVGElement, config: GraphingConfig) {
        this.svgr = svgRenderer;
        this.parent = parent;
        this.config = config;

        this.gridGroup = document.createElementNS(SvgRenderer.svgns, 'g');
        this.parent.appendChild(this.gridGroup);

        this.xSize = this.config.graphFrame.width;
        this.ySize = this.config.graphFrame.height;

        this.createGraph();
    }

    private createGraph(): void {
        this.xSpacing = parseFloat(this.config.axisX.unit);
        this.ySpacing = parseFloat(this.config.axisY.unit);

        this.setupClipPath();
        this.createBackground();

        if (!this.config.hideGrid) {
            this.createGridFromConfig();
            this.createAxis();
        }

        this.createLinesFromConfig();
        this.createPointsFromConfig();
        this.updateLines();

        if (this.config.camera === 'free') {
            this.svgr.camera.enableFreeCamera();
        }
    }

    private setupClipPath(): void {
        const defs = document.createElementNS(SvgRenderer.svgns, 'defs');
        const clipPath = document.createElementNS(SvgRenderer.svgns, 'clipPath');
        clipPath.setAttribute('id', 'grid-frame');
        const rect = document.createElementNS(SvgRenderer.svgns, 'rect');
        rect.setAttribute('x', this.config.graphFrame.x.toString());
        rect.setAttribute('y', this.config.graphFrame.y.toString());
        rect.setAttribute('width', this.config.graphFrame.width.toString());
        rect.setAttribute('height', this.config.graphFrame.height.toString());
        this.svgr.element.appendChild(defs);

        defs.appendChild(clipPath);
        clipPath.appendChild(rect);
        this.gridGroup.setAttribute('clip-path', 'url(#grid-frame)');
    }

    private createBackground(): void {
        if (this.config.backgroundImage) {
            // this.svgr.svgRasterImage({height: 765, imgUrl: 'images/RasteredCity.png', width: 1024, x: -160, y: -680}, this.parent)
            const img = this.svgr.svgRasterImage({imgUrl: this.config.backgroundImage, width: this.config.parentFrame.width, x: 0, y: 0}, this.parent);
            this.parent.insertBefore(img.element, this.gridGroup);
        }
    }

    private createAxis(): void {
        // horizontal
        this.xAxis = new SvgLine(this.svgr, this.gridGroup, {
            nonScalingStroke: true,
            stroke: 'black',
            strokeWidth: this.config.axisX.strokeWidth,
            x1: -this.xSize + this.config.originX,
            x2: this.xSize + + this.config.originX,
            y1: this.config.originY,
            y2: this.config.originY
        });

        // vertical
        this.yAxis = new SvgLine(this.svgr, this.gridGroup, {
            nonScalingStroke: true,
            stroke: 'black',
            strokeWidth: this.config.axisY.strokeWidth,
            x1: this.config.originX,
            x2: this.config.originX,
            y1: -this.ySize + this.config.originY,
            y2: this.ySize + this.config.originY
        });
    }

    private createGridFromConfig(): void {
        const MAX_LINES = 1000;
        let lineCount = 0;

        // draw the vertial lines along the x axis
        for(let i = this.xSpacing; i < this.xSize; i += this.xSpacing) {
            // make positive and negative elements at the same time

            // vertical lines along the X axis
            this.gridElements.push(this.svgr.svgLine({
                nonScalingStroke: true,
                stroke: this.config.color,
                x1: i + this.config.originX,
                x2: i + this.config.originX,
                y1: -this.ySize + this.config.originY,
                y2: this.ySize + this.config.originY
            }, this.gridGroup));

            // and negative axis
            this.gridElements.push(this.svgr.svgLine({
                nonScalingStroke: true,
                stroke: this.config.color,
                x1: -i + this.config.originX,
                x2: -i + this.config.originX,
                y1: -this.ySize + this.config.originY,
                y2: this.ySize + this.config.originY
            }, this.gridGroup));

            const labelVal = i / this.xSpacing;
            if (this.config.axisX.labelInterval && (labelVal % this.config.axisX.labelInterval !== 0)) {
                continue;
            }

            this.svgr.svgText({maintainScale: true, text: labelVal.toString(), x: i + this.config.originX, y: 20 + this.config.originY}, this.gridGroup);
            this.svgr.svgText({maintainScale: true, text: (-labelVal).toString(), x: -i + this.config.originX, y: 20 + this.config.originY}, this.gridGroup);

            if (++lineCount > MAX_LINES) { return; }
        }

        // draw horizontal lines along the y axis
        for(let i = this.ySpacing; i < this.ySize; i += this.ySpacing) {
            // make positive and negative elements at the same time
            this.gridElements.push(this.svgr.svgLine({
                nonScalingStroke: true,
                stroke: this.config.color,
                x1: -this.xSize,
                x2: this.xSize + this.config.originX,
                y1: i + this.config.originY,
                y2: i+ this.config.originY}, this.gridGroup));

            this.gridElements.push(this.svgr.svgLine({
                nonScalingStroke: true,
                stroke: this.config.color,
                x1: -this.xSize + this.config.originX,
                x2: this.xSize + this.config.originX,
                y1: -i + this.config.originY,
                y2: -i + + this.config.originY}, this.gridGroup));

                const labelVal = i / this.ySpacing;
                if (this.config.axisY.labelInterval && (labelVal % this.config.axisY.labelInterval !== 0)) {
                    continue;
                }

            this.svgr.svgText({maintainScale: true, text: (-labelVal).toString(), x: 10 + this.config.originX, y: i + this.config.originY}, this.gridGroup);
            this.svgr.svgText({maintainScale: true, text: labelVal.toString(), x: 10 + this.config.originX, y: -i + this.config.originY}, this.gridGroup);

            if (++lineCount > MAX_LINES) { return; }
        }
    }

    private createPointsFromConfig(): void {
        this.config.points.forEach(p => {
            const newPoint = new SvgGraphingDragPoint(
                this.svgr, this.gridGroup, {
                bounds: this.config.graphFrame,
                color: p.color || 'black',
                highlightColor: p.highlightColor,
                key: p.key,
                label: p.label,
                originX: this.config.originX,
                originY: this.config.originY,
                radius: p.radius || 10,
                x: p.x * this.xSpacing + this.config.originX,
                xSnapInterval: this.xSpacing,
                y: p.y * this.ySpacing + this.config.originY,
                ySnapInterval: this.ySpacing
            });

            newPoint.setOnClick(() => {
                newPoint.setSelected(true);
                newPoint.setSelected(false);
            });

            newPoint.setOnChangePos(this.updateLines.bind(this));
            this.points.set(p.key, newPoint);
        });
    }

    private getPointConfigData(key: string): GraphPointData | null {
        for(let i = 0; i < this.config.points.length; i++) {
            if (this.config.points[i].key === key) {
                return this.config.points[i];
            }
        }

        return null;
    }

    private createLinesFromConfig(): void {
        this.config.lines.forEach(l => {
            const p1 = this.getPointConfigData(l.point1);
            const p2 = this.getPointConfigData(l.point2);

            if (p1 && p2) {
                const newLine = this.svgr.svgLine({nonScalingStroke: true, stroke: l.color, strokeWidth: l.strokeWidth, x1: p1.x, x2: p2.x, y1: p1.y, y2: p2.y}, this.gridGroup);
                this.lineElements.set(l.key, newLine);
            }
        });
    }

    private updateLines(): void {
        this.config.lines.forEach(l => this.updateLine(l));

        if (this.onChangeCallback) {
            this.onChangeCallback(this.getScorableData());
        }
    }

    private updateLine(line: GraphLineData): void {
        const p1 = this.points.get(line.point1);
        const p2 = this.points.get(line.point2);
        const svgLine = this.lineElements.get(line.key);

        if (p1 && p2 && svgLine) {
            let newPoint1: LinePoint = {x: 0, y: 0};
            let newPoint2: LinePoint = {x: 0, y: 0};

            if (line.segment) {
                const p1Pos = p1.getPos();
                const p2Pos = p2.getPos();

                p1Pos.x *= this.xSpacing;
                p2Pos.x *= this.xSpacing;
                p2Pos.y *= this.ySpacing;
                p1Pos.y *= this.ySpacing;

                newPoint1 = p1Pos;
                newPoint2 = p2Pos;
            } else {
                const midpoint: LinePoint = {
                    x: (this.xSpacing * (p1.getPos().x + p2.getPos().x)) / 2,
                    y: (this.ySpacing * (p1.getPos().y + p2.getPos().y)) / 2
                };

                const angle = this.getAngle(p1, p2);

                const halfLengthCos = this.lineLength * Math.cos(angle);
                const halfLengthSin = this.lineLength * Math.sin(angle);

                newPoint1 = {x: midpoint.x - halfLengthCos, y: midpoint.y - halfLengthSin};
                newPoint2 = {x: midpoint.x + halfLengthCos, y: midpoint.y + halfLengthSin};
            }

            svgLine.setConfigValue('x1', newPoint1.x);
            svgLine.setConfigValue('x2', newPoint2.x);
            svgLine.setConfigValue('y1', newPoint1.y * -1);
            svgLine.setConfigValue('y2', newPoint2.y * -1);
        }
    }

    private getDistance(p1: KHPoint, p2: KHPoint): number {
        const xdiff = (p1.x - p1.y);
        const ydiff = (p2.y - p2.y);

        return Math.sqrt((xdiff * xdiff) + (ydiff * ydiff));
    }

    private getAngle(p1: SvgGraphingDragPoint, p2: SvgGraphingDragPoint): number {
        return Math.atan2(p2.getPos().y - p1.getPos().y, p2.getPos().x - p1.getPos().x);
    }

    public getScorableData(): ScorableData {
        const data: ScorableData = {answer: {}, state: {}};

        // point data
        this.points.forEach((v, k) => {
            const configData = this.getPointConfigData(k);
            const scoreByKeys = configData?.scoreBy.split(',');
            const pos = v.getGraphValue();

            data.state[`${configData?.key}_x`] = pos.x.toString();
            data.state[`${configData?.key}_y`] = pos.y.toString();

            if (scoreByKeys?.includes('x')) {
                data.answer[`${configData?.key}_x`] = pos.x.toString();
            }
            if (scoreByKeys?.includes('y')) {
                data.answer[`${configData?.key}_y`] = pos.y.toString();
            }
        });

        // line data
        this.config.lines.forEach(l => {
            const slopeData = this.getSlopeValues(l);
            const lineKey = l.key;

            if (slopeData) {
                data.state[`${lineKey}dx`] = slopeData.dx.toString();
                data.state[`${lineKey}b`] = slopeData.b.toString();
                data.state[`${lineKey}dy`] = slopeData.dy.toString();

                if (l.scoreBy === 'slope') {
                    data.answer[`${lineKey}dx`] = slopeData.dx.toString();
                    data.answer[`${lineKey}b`] = slopeData.b.toString();
                    data.answer[`${lineKey}dy`] = slopeData.dy.toString();
                }
            }
        });

        return data;
    }

    public setScorableData(data: ScorableData): void {
        const keys = Object.keys(data.state);

        for (let i = 0; i < keys.length; i++) {
            this.setPointKeyValue(keys[i], Number(data.state[keys[i]]));
        }
    }

    private setPointKeyValue(key: string, value: number): void {
        const split = key.split('_');

        if (split.length >= 2) {
            const pointKey = split[0];
            const pointProp = split[1];

            if (this.points.has(pointKey)) {
                const pt = this.points.get(pointKey);

                if (pointProp === 'x') {
                    pt?.setPosX(value);
                } else if (pointProp === 'y') {
                    pt?.setPosY(value);
                }
            }
        }
    }

    private getSlopeValues(line: GraphLineData): {dx: number, dy: number, b: number} | null {
        const point1 = this.points.get(line.point1)?.getPos();
        const point2 = this.points.get(line.point2)?.getPos();

        if (point1 && point2) {
            const dx: number = point2.x - point1.x;
            const dy: number = point2.y - point1.y;
            let slope = 0;

            // avoid divide by zero. If it's 0 then the slope will result in 0
            if (dx !== 0) {
                slope = dy / dx;
            }

            const b: number = point1.y - slope * point1.x;

            return { b, dx, dy};
        }

        return null;
    }
}
