import { Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FabricWindowPane } from '../../../../utils/fabric-utils/fabric-window-pane';
import { ManipulativeChildObject } from '../../../models/manipulative-child-object.model';
@UntilDestroy()
@Component({
  selector: 'pane',
  styleUrls: ['./pane.component.less'],
  templateUrl: './pane.component.html'
})
export class PaneComponent extends ManipulativeChildObject {
  private pane!: FabricWindowPane;

  @Input() public img?: string;
  @Input() public order = 0;
  @Input() public id = '';

  public isLoaded = new BehaviorSubject(false);
  private frame!: number[];

  public getOrders(){
    return {id: this.id, order: this.order};
  }

  public setFrame(frame: number[]): void {
    this.frame = frame;
  }

  public override onDynamicMount(): void {
    this.pane = new FabricWindowPane({
      frame: this.frame,
      id: this.id.replace(/[']/g, ''),
      img: this.img?.replace(/[']/g, ''),
      order: this.order
    })
    this.pane.isLoading$.pipe(
      tap(() => this.isLoaded.next(true)),
      untilDestroyed(this)
    ).subscribe()
  }

  public get fabricObject(): FabricWindowPane[] {
    return [this.pane];
  }
}
