import { ActiveToast } from 'ngx-toastr/toastr/toastr.service';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

declare type ToastType = 'success' | 'info' | 'warning' | 'error';

@Injectable({
  providedIn: 'root'
})
export class LocalizedToasterService {
  public constructor(
    private readonly toastr: ToastrService,
    private readonly translateService: TranslateService) { }

  private translate(value: string): string {
    return value ? this.translateService.instant(value) : '';
  }

  public pop(type: ToastType, title: string, body = ''): ActiveToast<any> {
    return this.toastr[type](this.translate(body), this.translate(title));
  }

  public success(title: string, body?: string): ActiveToast<any> {
    return this.pop('success', title, body);
  }

  public persistedSuccess(title: string, body?: string): ActiveToast<any> {
    return this.toastr.success(body, title, { disableTimeOut: true });
  }

  public info(title: string, body?: string): ActiveToast<any> {
    return this.pop('info', title, body);
  }

  public error(title: string, body?: string): ActiveToast<any> {
    return this.pop('error', title, body);
  }

  public persistedError(title: string, body?: string): ActiveToast<any> {
    return this.toastr.error(body, title, { disableTimeOut : true});
  }

  public khError(data: any): ActiveToast<any> {
    let message = Array.isArray(data?.error?.Errors) && data.error.Errors.length > 0 ? data.error.Errors[0] : '';
    if(!message){
      message = data?.ExceptionMessage ?? '';
    }

    return this.error('Error', message);
  }
}
