import { Component, ContentChildren, ElementRef, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChild, Inject } from '@angular/core';
import { DynamicContentChild, OnDynamicData, OnDynamicMount } from 'ngx-dynamic-hooks';
import { ScorableTracker } from './ScorableTracker';
import { UntilDestroy } from '@ngneat/until-destroy';
import { QUESTION_EVENTS_SERVICE_TOKEN, IQuestionEventsService } from '../../../../services/IQuestionEvents';
import { ManipFrame, parseFrameString } from '../../../../utils/utils';
import { IScorable, ScorableData } from './IScorable';
import { ManipulativeAnswer } from '../../../models/manipulative-answer';
import { DynamicContextType } from '../../../models/dynamic-context-type';


@UntilDestroy()
@Component({
  selector: 'kh-manipulative',
  styleUrls: ['./kh-manip-scorable.component.less'],
  templateUrl: './kh-manip-scorable.component.html'
})
export class KhManipScorableComponent implements OnDynamicMount {
  @Output() public valueChange = new EventEmitter<{answer: string, state: string}>();
  @Input() public value: any;
  @Input() public reportMode = false;
  @Input() public width = 500;
  @Input() public height = 500;
  @ViewChild('scaleDiv') public scaleDiv: ElementRef<HTMLDivElement>;
  @ContentChildren(ElementRef) angularChildren!: QueryList<ElementRef>;
  @Input() public sampleAnswer = '';

  private scorableTracker: ScorableTracker;
  private loadedData: {answer: string, best: string, first: string} = {answer: '', best: '', first: ''};
  private parentElement: HTMLElement | null;
  private dynamicMountContext: DynamicContextType;

  public constructor(@Inject(QUESTION_EVENTS_SERVICE_TOKEN) public readonly questionEventsService: IQuestionEventsService) {
    this.scorableTracker = new ScorableTracker();
    this.questionEventsService.hideInputOnlyForManip.next(false);
  }

  /**
   * TS has no native way to runtime check interface implementation, but you can use user defined type guards
   * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
   * @param object
   */
  private isScorable(object: IScorable): object is IScorable {
    return object.getScorableData !== undefined && object.setScorableData !== undefined;
  }

  public onDynamicMount(data: OnDynamicData): void {
    const contentChildren: DynamicContentChild[] = data.contentChildren ?? [];

    for (let i = 0; i < contentChildren.length; i++) {
      if (this.isScorable(contentChildren[i].componentRef.instance)) {
        this.scorableTracker.addScorable(contentChildren[i].componentRef.instance);
      }
    }

    this.setupQuestionData(data);

    if(this.reportMode) {
      const scroableData = this.getDataAsScorable();

      if (Object.keys(scroableData.state).length === 0) {
        scroableData.state = scroableData.answer;
      }

      this.scorableTracker?.setData(scroableData);
    }

    if (this.scaleDiv) {
      this.scaleDiv.nativeElement.style.width = `${this.width}px`;
      this.scaleDiv.nativeElement.style.height = `${this.height}px`;
    }

    this.setupChangeListeners();
    this.setupResize();
    this.doResize();
  }

  private calculateManipulativeSize(children: DynamicContentChild[]): ManipFrame {
    const manipFrame: ManipFrame = {
      width: 0,
      height: 0,
      x: 0,
      y: 0
    };

    if (this.scaleDiv) {

      for (const child of children) {
        const frame = child.componentRef.instance?.frame;
        if (frame) {
          const parsedFrame = parseFrameString(frame);
          manipFrame.width = Math.max(manipFrame.width, parsedFrame.width);
          manipFrame.height = Math.max(manipFrame.height, parsedFrame.height);
          manipFrame.x = Math.max(manipFrame.x, parsedFrame.x);
          manipFrame.y = Math.max(manipFrame.y, parsedFrame.y);
        }
      }
    }

    this.height = manipFrame.height;
    this.width = manipFrame.width;

    return manipFrame;
  }


  private getAnswerData(): ManipulativeAnswer {
    const answerData = this.scorableTracker?.getAnswerData();

    const manipAnswer = {answer: '', state: ''};

    if (answerData) {
      const answerKeys = Object.keys(answerData.answer);
      const stateKeys = Object.keys(answerData.state);

      // stringify answer keys
      for (let i = 0; i < answerKeys.length; i++) {
        if (i > 0) {
          manipAnswer.answer += ',';
        }

        const curKey = answerKeys[i];
        manipAnswer.answer += `${curKey}:${answerData.answer[curKey]}`;
      }

      // stringify state keys
      for (let i = 0; i < stateKeys.length; i++) {
        if (i > 0) {
          manipAnswer.state += ',';
        }

        const curKey = stateKeys[i];
        manipAnswer.state += `${curKey}:${answerData.state[curKey]}`;
      }
    }

    return manipAnswer;
  }

  private getAnswerObject(): ManipulativeAnswer {
    return this.getAnswerData();
  }

  private setupChangeListeners(): void {
    if (this.scorableTracker) {
      this.scorableTracker.scorableObjects.forEach(so => {
        so.onChangeCallback = this.onChange.bind(this);
      })
    }
  }

  private onChange(): void {
    const answers = this.getAnswerObject();
    this.valueChange.emit(answers);

    if (this.dynamicMountContext?.answerChanged) {
      this.dynamicMountContext.answerChanged(answers);
    }
  }

  private getDataAsScorable(): ScorableData {
    const datoid: ScorableData = {answer: {}, state: {}};
    datoid.answer = this.getStringAsKVP(this.loadedData.answer);
    datoid.state = this.getStringAsKVP(this.loadedData.best);

    return datoid;
  }

  private getStringAsKVP(s: string): {[key: string]: string} {
    const result: {[key: string]: string} = {};

    // const split = s.split(',');
    const split = this.splitStringIgnoreCurlies(s, ',');

    for(let i = 0; i < split.length; i++) {
      const kvpSplit = this.splitStringIgnoreCurlies(split[i], ':');

      if (kvpSplit.length >= 2) {
        const key = kvpSplit[0]
        const val = kvpSplit[1];
        result[key] = val;
      }
    }

    return result;
  }

  // split strings on commas but don't split them inside curly braces
  private splitStringIgnoreCurlies(input: string, delimiter: string) {
    const result = [];
    let buffer = '';
    let braceLevel = 0;

    for (const char of input) {
      if (char === '{') {
        braceLevel++;
      } else if (char === '}') {
        braceLevel--;
      } else if (char === delimiter && braceLevel === 0) {
        result.push(buffer.trim());
        buffer = '';
        continue;
      }
      buffer += char;
    }

    if (buffer) {
      result.push(buffer.trim());
    }

    return result;
  }

  private setupQuestionData(data: OnDynamicData): void {
    // the question data in here is not typed anywhere :(
    const questionData = data.context?.Question;

    this.dynamicMountContext = data.context as DynamicContextType;

    if (questionData && this.reportMode) {    
       this.loadedData.answer = this.sampleAnswer?this.sampleAnswer:questionData.AnswerText || '';
      if (questionData.StudentsSelectedBestAttempt && questionData.StudentsSelectedBestAttempt.length > 0) {
        this.loadedData.best = questionData.StudentsSelectedBestAttempt[0].AnswerState || '';
      }  else if (questionData.AnswerState) {
        this.loadedData.best = questionData.AnswerState;
      }

      if (questionData.StudentsSelectedBestAttempt && questionData.StudentsSelectedFirstAttempt.length > 0) {
        this.loadedData.first = questionData.StudentsSelectedFirstAttempt[0].AnswerState || '';
      } else if (questionData.AnswerState) {
        this.loadedData.first = questionData.AnswerState;
      }
    }
  }

  private setupResize(): void {
    if (this.scaleDiv.nativeElement) {
      window.addEventListener('resize', this.doResize.bind(this));
      const parent = this.getGameplayQuestionContainer();
    
      if (parent) {
        this.parentElement = parent;
      } else {
        throw Error('Could not find parent for manipulative.');
      }
    }
  }

  private doResize(): void {
    if (this.parentElement) {
      const rect = this.parentElement.getBoundingClientRect();
      const ratio = Math.min(rect.width / this.width, 1);

      this.scaleDiv.nativeElement.style.width = `${this.width * ratio}px`;
      this.scaleDiv.nativeElement.style.height = `${this.height * ratio}px`;

      (this.scaleDiv.nativeElement as HTMLDivElement).style.transform = `scale(${ratio})`;
    }
  }

  private getGameplayQuestionContainer(): HTMLElement | null {
    if (this.scaleDiv.nativeElement) {
      let el: HTMLElement | null = this.scaleDiv.nativeElement;

      while (el) {
        if (el.classList.contains('gameplayQuestionText') || el.classList.contains('QuestionText')) {
          return el;
        }

        el = el.parentElement;
      }
    }

    return null; // Not found
  }
}
