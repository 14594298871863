import { KHPoint } from '../../pixi-puzzle-framework/PuzzleEngine/KHPoint';
/* eslint-disable sort-keys-fix/sort-keys-fix */


export const enum FarmUnits {
  ONES = 'ones',
  TENS = 'tens',
  HUNDREDS = 'hundreds',
  THOUSANDS = 'thousands',
  TEN_THOUSANDS = 'ten-thousands',
  HUNDRED_THOUSANDS = 'hundred-thousands',
  MILLIONS = 'millions'
}

export type FarmUnitEntry = {
  value: number;
  imageUrl: string;
  nextFrameUrl: string;
  animationPositions: KHPoint[];
  nextType?: FarmUnits;
  width: number;
  height: number;
  childWidth: number;
  childHeight: number;
}

export const FarmUnitData: { [key in FarmUnits]: FarmUnitEntry } = {
  [FarmUnits.ONES]: {
    value: 1,
    imageUrl: 'farm-grape.svg',
    nextFrameUrl: 'farm-bunch-empty.svg',
    nextType: FarmUnits.TENS,
    animationPositions: [
      { x: 73.1, y: 42.2 },
      { x: 48.8, y: 46.6 },
      { x: 24.4, y: 50.9 },
      { x: 0, y: 56.1 },
      { x: 65.5, y: 62.7 },
      { x: 41.2, y: 67.1 },
      { x: 16.8, y: 71.3 },
      { x: 58.7, y: 81.6 },
      { x: 34.3, y: 86 },
      { x: 50.9, y: 102.2 }
    ],
    width: 100,
    height: 100,
    childWidth: 100,
    childHeight: 100
  },
  [FarmUnits.TENS]: {
    value: 10,
    nextType: FarmUnits.HUNDREDS,
    imageUrl: 'farm-bunch-full.svg',
    nextFrameUrl: 'farm-basket-empty.svg',
    animationPositions: [
      { x: 126.2, y: 0 },
      { x: 100.3, y: 17 },
      { x: 71.8, y: 31.6 },
      { x: 42.7, y: 46.1 },
      { x: 13.1, y: 63.3 },
      { x: 155, y: 18 },
      { x: 129.2, y: 33.5 },
      { x: 100.6, y: 48.4 },
      { x: 70.8, y: 62.4 },
      { x: 42.4, y: 80.8 }
    ],
    width: 100,
    height: 130,
    childWidth: 33,
    childHeight: 33
  },
  [FarmUnits.HUNDREDS]: {
    value: 100,
    nextType: FarmUnits.THOUSANDS,
    imageUrl: 'farm-basket-full.svg',
    nextFrameUrl: 'farm-tray-empty.svg',
    animationPositions: [
      {x: 142.8, y: 0}, 
      {x: 196.1, y: 30.7}, 
      {x: 250.1, y: 61.5}, 
      {x: 302.3, y: 91.8}, 
      {x: 356.2, y: 122.4}, 
      {x: 8, y: 78.1}, 
      {x: 61.3, y: 108.8}, 
      {x: 115.3, y: 139.5}, 
      {x: 167.6, y: 169.8}, 
      {x: 221.5, y: 200.5}
    ],
    width: 202.5,
    height: 141.9,
    childWidth: 38,
    childHeight: 43.2
  },
  [FarmUnits.THOUSANDS]: {
    value: 1000,
    nextType: FarmUnits.TEN_THOUSANDS,
    imageUrl: 'farm-tray-full.svg',
    nextFrameUrl: 'farm-crate-empty.svg',
    animationPositions: [
      {x: 15.7, y: 464.6}, 
      {x: 16.3, y: 412.9}, 
      {x: 16.8, y: 361.8}, 
      {x: 16.8, y: 308.2}, 
      {x: 16.3, y: 255.7}, 
      {x: 19.7, y: 203.4}, 
      {x: 20.3, y: 151.8}, 
      {x: 20.8, y: 100.6}, 
      {x: 20.8, y: 47}, 
      {x: 20.3, y: -5.5}
    ],
    width: 547,
    height: 348,
    childWidth: 180.6,
    childHeight: 125.7
  },
  [FarmUnits.TEN_THOUSANDS]: {
    value: 10000,
    nextType: FarmUnits.HUNDRED_THOUSANDS,
    imageUrl: 'farm-crate-full.svg',
    nextFrameUrl: 'farm-truck-empty.svg',
    animationPositions: [
      {x: 0, y: 147.9}, 
      {x: 242.4, y: 46.9}, 
      {x: 337, y: 93.8}, 
      {x: 432.1, y: 142}, 
      {x: 527.9, y: 183.6}, 
      {x: 32.9, y: 65.9}, 
      {x: 127.3, y: 112.8}, 
      {x: 222, y: 159.7}, 
      {x: 317, y: 208}, 
      {x: 412.8, y: 249.5}
    ],
    width: 1000,
    height: 1103.5,
    childWidth: 964.5,
    childHeight: 629.8
  },
  [FarmUnits.HUNDRED_THOUSANDS]: {
    value: 100000,
    nextType: FarmUnits.MILLIONS,
    imageUrl: 'farm-truck-full.svg',
    nextFrameUrl: 'farm-boat-empty.svg',
    animationPositions: [
      {x: 691.3, y: 31.2}, 
      {x: 633.3, y: 63.1}, 
      {x: 573.7, y: 94}, 
      {x: 513.9, y: 124.3}, 
      {x: 454.7, y: 156.7}, 
      {x: 372.2, y: 193.2}, 
      {x: 314.2, y: 225}, 
      {x: 254.5, y: 256}, 
      {x: 194.8, y: 286.3}, 
      {x: 135.5, y: 318.7}
    ],
    width: 1000,
    height: 762,
    childWidth: 172,
    childHeight: 187.5
  },
  [FarmUnits.MILLIONS]: {
    value: 1000000,
    imageUrl: 'farm-boat-full.png', // 'farm-boat-full.svg'
    nextFrameUrl: '',
    animationPositions: [],
    width: 1000,
    height: 609.9,
    childWidth: 206.5,
    childHeight: 157.4
  }
}

export function getFarmSpriteURL(unit: FarmUnits): string {
  if (FarmUnitData[unit].imageUrl === '') {
    return '';
  }

  return `images/puzzles/farm/${FarmUnitData[unit].imageUrl}`;
}

export function getFarmSpriteDimensions(unit: FarmUnits): KHPoint {
  return { x: FarmUnitData[unit].width, y: FarmUnitData[unit].height };
}

export function getFarmSpriteChildDimensions(unit: FarmUnits): KHPoint {
  return { x: FarmUnitData[unit].childWidth, y: FarmUnitData[unit].childHeight };
}

export function getFarmFrameURL(unit: FarmUnits): string {
  if (FarmUnitData[unit].nextFrameUrl) {
    return `images/puzzles/farm/${FarmUnitData[unit].nextFrameUrl}`;
  }

  return '';
}

export function getUnitValue(unit: FarmUnits): number {
  return FarmUnitData[unit].value;
}

export function getRegroupCoords(unit: FarmUnits): KHPoint[] {
  return FarmUnitData[unit].animationPositions;
}

export function getNextType(unit: FarmUnits): FarmUnits | undefined {
  return FarmUnitData[unit].nextType;
}
