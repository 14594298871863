import { SvgRenderElement, SvgRenderElementType } from './SvgRenderElement';
import { SvgRenderer } from './SvgRenderer';

export type SvgTextConfig = SvgRenderElementType & {
    text: string;
    x?: number;
    y?: number;
    fontFamily?: string;
    fontSize?: number;
}

const svgTextConfigDefault: Partial<SvgTextConfig> = {
  fontFamily: 'sans-serif',
  fontSize: 16,
  text: '',
  x: 0,
  y: 0
};

export class SvgText extends SvgRenderElement<SvgTextConfig> {
  public constructor(renderer: SvgRenderer, parent: SVGElement, config: SvgTextConfig) {
    super(renderer, parent, config, svgTextConfigDefault);
  }

  public createElement(): void {
    this.element = document.createElementNS(SvgRenderer.svgns, 'text');
  }

  protected onMaintainScale(newScale: number): void {
    this.element.setAttribute('font-size', (newScale * this.getConfigValue('fontSize')).toString());
  }

  public applyConfigProperties(): void {
    this.setElementAttribute('x', 'x');
    this.setElementAttribute('y', 'y');
    this.setElementAttribute('font-family', 'fontFamily');
    this.setElementAttribute('font-size', 'fontSize');
    this.element.style.userSelect = 'none';

    this.element.innerHTML = this.config.text;
  }
}
