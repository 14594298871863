<div class="ManipSubtractingBlocks">
    <div>
        <div [class.Squeeze]="canSqueezeBlocks">
            <div class="TopRow">
                <div class="block_counter" [ngClass]="{grey: value === 0, IsNeg: value < 0}">
                    {{value | numWithSign | minusToDash}}
                </div>

                <div class="BlockContainer" *ngFor="let block of fixedBlocks"
                     [ngClass]="{IsZeroPair: block.isZeroPair}">
                    <div class="Block" [ngClass]="{BlockHidden: block.value === 0, IsNeg: block.value === -1}">
                        <span *ngIf="block.value !== 0">{{block.value | numWithSign | minusToDash}}</span>
                        <span *ngIf="block.value === 0">X</span>
                    </div>
                </div>
                <div class="BlockContainer" *ngFor="let block of topRow" [ngClass]="{IsZeroPair: block.isZeroPair}">
                    <div class="Block animated bounceIn"
                         [ngClass]="{BlockHidden: block.value === 0, IsNeg: block.value === -1}">
                        <span *ngIf="block.value !== 0">{{block.value | numWithSign | minusToDash}}</span>
                        <span *ngIf="block.value === 0">X</span>
                    </div>
                </div>
            </div>

            <div *ngIf="!singleRow" class="BottomRow">
                <div class="block_counter">&nbsp;</div>
                <div class="BlockContainer" *ngFor="let block of fixedBlocks">
                    <div class="Block Empty">&nbsp;</div>
                </div>

                <div *ngFor="let block of bottomRow" class="BlockContainer" [ngClass]="{IsZeroPair: block.isZeroPair}">
                    <div class="Block animated bounceIn"
                         [ngClass]="{BlockHidden: block.value === 0, IsNeg: block.value === -1}">
                        <span *ngIf="block.value !== 0">{{block.value | numWithSign | minusToDash}}</span>
                        <span *ngIf="block.value === 0">X</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="BlockSummary">
            {{expression | minusToDash}} = {{value | numWithSign | minusToDash}}
        </div>
    </div>

    <button (click)="removeBlock()" class="btn btn-sm btn-primary">
        Subtract
        <b>
            <span *ngIf="term[1] < 0">–</span>
            <span *ngIf="term[1] > 0">+</span>
            1
        </b>
    </button>
    <button *ngIf="!singleRow" (click)="addZeroPair()" class="btn btn-sm btn-primary">Add Zero Pair</button>

    <a (click)="reset()" class="cursor reset-btn">Reset</a>
</div>
