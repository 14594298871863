import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ManipTextComponent } from '../manip-text/manip-text.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, of, Subject } from 'rxjs';
import { fabric } from 'fabric';
import { WindowsService } from '../../../../services/windows.service';
import { FabricManipText } from '../../../../utils/fabric-utils/FabricManipText';
import { ManipulativeChildObject } from '../../../models/manipulative-child-object.model';

@UntilDestroy()
@Component({
  selector: 'counter',
  styleUrls: ['./counter.component.less'],
  templateUrl: './counter.component.html'
})
export class CounterComponent extends ManipulativeChildObject implements OnInit {
  public isLoaded = of(true);

  public order = 0;

  @Input() public id = '';
  @Input() public font = '';
  @Input() public backgroundColor = '';
  @Input() public strokeColor = '';
  @Input() public strokeWidth = '';
  @Input() public frame = '';
  @Input() public fontSize = '';
  @Input() public controllers = '';
  @Input() public maxDecimals = 0;
  @Input() public minDecimals = 0;
  @ViewChild(ManipTextComponent)
  public viewChild: ManipTextComponent | undefined;

  public count = 0;
  private fabricComponents: FabricManipText | undefined;
  private fabricCounter = new Subject<FabricManipText>();
  public textElem = this._elementRef.nativeElement as HTMLElement;

  public constructor(private _elementRef: ElementRef, private readonly windowsService: WindowsService) {
    super();
  }

  public ngOnInit(): void {
    const counterControllers = this.controllers.split(',');
    const ctrls = counterControllers.map(controllers => this.windowsService.getControllerById(controllers));
    combineLatest([this.fabricCounter, ...ctrls]).pipe(untilDestroyed(this))
      .subscribe(([counter, ...values]) => {
        const value = (values as number[]).reduce((acc, val) => acc + val, 0);
        const formatValue = new Intl.NumberFormat('en-US', {
          maximumFractionDigits: Math.max(this.maxDecimals, this.minDecimals),
          minimumFractionDigits: this.minDecimals
        });
        (counter as FabricManipText).fabricText.set('text', formatValue.format(value).toString());
        (counter as FabricManipText).canvas?.renderAll();
      })
  }

  public get fabricObject(): fabric.Object[] {
    this.fabricComponents = this.viewChild!.fabricObject[0];
    this.fabricCounter.next(this.fabricComponents);

    return [this.fabricComponents];
  }
}
