<input #input class="position-absolute high-z" (focus)="showInput()" (blur)="removeStroke()" [(ngModel)]="value"
  [type]="type" [name]="name" onwheel="blur()" [attr.maxlength]="maxLength" [id]="key" data-testid="input-textbox"
  (ngModelChange)="onChangeValue($event)" [ngStyle]="{
    'font-family': fontFamily,
    'font-size.px': fontSize,
    'top.px': box.top,
    'width.px': box.width,
    'height.px': box.height,
    'left.px': box.left,
    'background-color': backgroundColor,
    'border-color': borderColor,
    'border-width.px': borderStrokeSize,
    'border-radius': borderRadius,
    'text-align': textAlignment,
    'font-style': fontStyle,
    'font-weight': fontWeight,
  }" />
<div #keyboard  *ngIf="showInputKeypad" class="manip-int-input-region" [ngStyle]="KeyboardStyles()"  data-testid="manip-int-input-keypad">
  <div appDragging>
    <div class="keyboard-handle" appDraggingHandle>
      <kh-input-int-keypad [result]="value" (resultChange)="valueChanged($event)" [maxDigits]="maxLength"
        [brokenKeys]="brokenKeys" [font]="fontFamily" (onClose)="onClose()"
        [khClickOutside]="onClose"></kh-input-int-keypad>
    </div>
  </div>
</div>