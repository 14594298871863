/* eslint-disable indent */
import { gsap } from 'gsap';
import { Path, StrokeData, Svg } from '@svgdotjs/svg.js';
import { SvgRenderer } from '../svg-components/SvgRenderer';
import { KHPoint } from '../pixi-puzzle-framework/PuzzleEngine/KHPoint';

export type NumberLineJumpConfig = {
    startPoint: KHPoint;
    endPoint: KHPoint;
    stroke: StrokeData;
    fontSize: number;
    showNumbers: boolean;
    jumpEndValue: number;
    scaleDirection: number;
}

export class NumberLineJump {
    private config: NumberLineJumpConfig;
    private path: Path;
    private renderer: SvgRenderer;
    private tween: gsap.core.Tween;
    private listenerId: number | undefined;
    private strokeData: StrokeData;
    private pathScale: number;
    private text?: SVGTextElement;
    private readonly LABEL_OFFSET_Y = 10;

    public constructor(renderer: SvgRenderer, config: NumberLineJumpConfig) {
        this.config = config;
        this.renderer = renderer;
        this.strokeData = {...this.config.stroke};
    }

    public render(): void {
        const unscaledSize = 100;
        const {startPoint, endPoint, stroke} = this.config;
        const radius = unscaledSize / 2;
        this.pathScale = Math.abs(startPoint.x - endPoint.x) / unscaledSize;

        this.path = this.renderer.svg.path(
            `M ${0} ${0}
             A ${radius} ${radius} 0 0 1 ${unscaledSize} ${0}`
            );

        this.path.attr('display', 'none');
        this.path.attr('transform', this.getPathTransformString(this.pathScale));
        // this.path.node.setAttribute('vector-effect', 'non-scaling-stroke');
        this.path.stroke(stroke);
        this.path.fill('none');
        this.path.scale(this.config.scaleDirection, 1);
        const curScale = this.renderer.camera.getScale();

        this.setupCameraListener();

        this.onScaleChange(curScale);

        if (this.config.showNumbers) {
            this.text = this.makeText(this.config.jumpEndValue.toString(),
             {
                x: this.config.endPoint.x,
                y: (this.config.endPoint.y) + (this.config.fontSize * curScale) + (this.LABEL_OFFSET_Y * curScale)
            });
        }
    }

    public getJumpDistance(): number {
        return Math.abs(this.config.startPoint.x - this.config.endPoint.x);
    }

    public getEndPoint(): KHPoint {
        return this.config.endPoint;
    }

    public async animate(): Promise<void> {
        (this.strokeData.dasharray as any) = this.path.length();
        this.strokeData.dashoffset = this.path.length();
        this.path.stroke(this.strokeData);

        return new Promise<void>(resolve => {
            this.tween = gsap.to(this.path.node, {duration: 1, ease: 'none', strokeDashoffset: 0,
            onStart: () =>{
                this.path.attr('display', 'inline');
            },
            onComplete: () => {
                    this.path.attr('stroke-dasharray', null);
                    this.revealNumber();
                    resolve();
                }}
            );
        });
    }

    public destroyText() {
        if (this.text) {
            this.text.remove();
        }
    }

    public destroy() {
        if (this.tween) {
            this.tween.kill();
        }

        if (this.path) {
            this.path.remove();
        }

        if (this.text) {
            this.text.remove();
        }

        if (this.listenerId) {
            this.renderer.camera.removeOnScaleChangeListener(this.listenerId);
        }
    }

    public getPathTransformString(newScale: number): string {
        return `translate(${this.config.startPoint.x} ${this.config.startPoint.y}) scale(${newScale} ${newScale})`;
    }

    public setupCameraListener(): void {
        this.listenerId = this.renderer.camera.addOnScaleChangeListener(this.onScaleChange.bind(this));
    }

    public revealNumber(): void {
        if (this.text) {
            gsap.to(this.text, {'opacity': 1, 'duration': 0.5});
        }
    }

    private onScaleChange(newScale: number): void {
        this.strokeData.width = (this.config.stroke.width as number) * newScale * (1 / this.pathScale);
        this.path.stroke(this.strokeData);
        const xPos = this.config.endPoint.x;
        const yPos = this.config.endPoint.y + (this.config.fontSize * newScale) + (this.LABEL_OFFSET_Y * newScale);
        this.text?.setAttribute('transform', `translate(${xPos} ${yPos}) scale(${newScale} ${newScale})`);
    }

    private makeText(value: string, position: KHPoint): SVGTextElement {
        const t = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        const curScale = this.renderer.camera.getScale();

        t.innerHTML = value;
        t.setAttribute('font-size', this.config.fontSize.toString());
        t.setAttribute('text-anchor', 'middle');
        t.setAttribute('font-family', 'Quicksand');
        t.setAttribute('fill', 'white');
        t.setAttribute('transform', `translate(${position.x} ${position.y}) scale(${curScale} ${curScale})`);
        t.setAttribute('opacity', '0');

        this.renderer.svg.node.appendChild(t);

        return t;
    }
}
