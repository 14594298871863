/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { gsap } from 'gsap/all';
import { Power1, Power2 } from 'gsap';
import { PuzzleBase } from '../puzzle/puzzleBase';
import { PuzzleEventService } from '../services/puzzle-event.service';
import { randomIntegerFromInterval } from '../../utils/utils';

const svgns = 'http://www.w3.org/2000/svg';

export interface GameInput {
    // bridgeArr: number[][];
    lowerBridge: number[];
    upperBridge: number[];
    fractionRange: number[]; // [ min, max ]
    limits: number[][]
    // limitedFractions : number[];
    // fractions : number[]
    // limits: number[]
}

interface Game {
    bridgeArr: number[][];
    // limitedFractions: number[];
    // fractions : number[]
    // limits: number[];
    fractionRange: number[]; // [ min, max ]
    limits: { [key: number]: number }; // number[][]

    attempts: number;
    complete: boolean
}

interface Order {
    num: number;
    pieces: number;
    size: number;
}

interface Block extends SVGRectElement {
    num: number;
    size: number;
}

interface Space { // extends SVGRectElement{
    num: number;
    size: number; // 0 - 1
    board: number;
    xVal: number;
    yVal: number;
}


export class MaterialBridgeAPI extends PuzzleBase {

    arena: HTMLElement
    svg: HTMLElement

    game: Game
    gameIndex: number;
    order: Order;
    orderBtn: HTMLElement;

    input: HTMLElement;
    inputImg: HTMLElement;
    currentImg: SVGUseElement
    inputSize: HTMLSelectElement;
    inputPieces: HTMLSelectElement;
    inputText: HTMLElement;
    // retryBtn: HTMLElement;
    // nextBtn: HTMLElement;
    boat: HTMLElement;
    overlay: HTMLElement;

    usability: HTMLElement;

    frontWater: HTMLElement;
    lightning: HTMLElement;

    bigBoat: SVGUseElement;
    smallBoat: SVGUseElement;

    background: SVGUseElement;
    bridge: SVGUseElement
    boatY = 375;

    wholeSize = 1028;
    height = 45;
    xVal = 125
    yVal: number // = 209
    delta = 115

    animationEls: (SVGUseElement | SVGRectElement)[]

    games: Game[]
    targets: SVGRectElement[];

    spaces: Space[]
    ogSpaces: Space[]
    originalSpaces: Space[]
    blocks: Block[]

    finishedAttempt: boolean;

    fallingBlocks: SVGRectElement[]

    tl: gsap.core.Timeline;
    tl2: any[]
    pointer: SVGUseElement;
    helpBtn: HTMLElement;
    help: boolean;
    canOrder: boolean;
    popup: SVGUseElement;
    popuptext: SVGUseElement;

    tools: SVGUseElement;
    puzzleEventService: PuzzleEventService;

    constructor(setup: any, games: any, puzzleEventService: PuzzleEventService) {
        super({ attempts: [] });

        this.puzzleEventService = puzzleEventService;
        this.arena = setup.arena;
        this.svg = setup.svg;
        this.games = this.createGames(games);
        this.game = this.games[0];
        this.gameIndex = 0;
        this.input = setup.input;
        this.inputImg = setup.inputImg;
        this.inputSize = setup.inputSize;
        this.inputPieces = setup.inputPieces;
        this.inputText = setup.inputText;
        this.orderBtn = setup.orderBtn;
        this.boat = setup.boat;
        this.frontWater = setup.frontWater;
        this.lightning = setup.lightning;
        this.overlay = setup.overlay;

        this.targets = [];
        //this.retryBtn = setup.retryBtn;
        //this.nextBtn = setup.nextBtn;
        this.animationEls = [];

        this.spaces = [];
        this.blocks = [];
        this.ogSpaces;

        this.tl = gsap.timeline();
        this.tl2 = [];

        this.finishedAttempt = false;

        this.fallingBlocks = [];

        this.usability = setup.usability;
        this.helpBtn = setup.helpBtn;
        this.help = setup.help;

        this.canOrder = true;


        this.init();

    }

    // initial setup (create elements)
    init() {
        this.background = document.createElementNS(svgns, 'use')
        this.arena.appendChild(this.background)
        gsap.set(this.background, { x: 0, y: 0 })

        this.bridge = document.createElementNS(svgns, 'use')
        this.arena.appendChild(this.bridge)

        // small boat
        this.smallBoat = document.createElementNS(svgns, 'use')
        this.smallBoat.setAttribute('href', '#smallBoat')
        this.arena.appendChild(this.smallBoat)
        gsap.set(this.smallBoat, { x: -400, y: this.boatY + 152 })

        this.tools = document.createElementNS(svgns, 'use')
        this.tools.setAttribute('href', '#tools')
        this.arena.appendChild(this.tools)
        gsap.set(this.tools, { x: 40, y: this.boatY + 78 })
        gsap.set(this.tools, { scale: 0, transformOrigin: '50% 100%' })

        // create boat
        this.bigBoat = document.createElementNS(svgns, 'use')
        this.arena.appendChild(this.bigBoat)
        this.bigBoat.setAttribute('href', '#boat')
        gsap.set(this.bigBoat, { x: 10 - 1300, y: this.boatY })

        // add water
        const water = document.createElementNS(svgns, 'use')
        this.frontWater.appendChild(water)
        water.setAttribute('href', '#waterNight')
        gsap.set(water, { x: -5, y: 697 })

        this.setBackground()
        this.setBridge()
        this.setupInput()
        this.setupTargets()
        this.setupButtons()

        if (this.help) { this.setupUsability() }
        else { gsap.set([this.usability, this.helpBtn], { visibility: 'hidden' }) }

        this.startAnimation();
    }

    setBackground() {
        this.background.setAttribute('href', '#backNight')
    }

    setBridge() {
        if (this.game.bridgeArr.length === 1) {
            this.bridge.setAttribute('href', '#bridge1');
            gsap.set(this.bridge, { x: -1, y: 100 });
            this.yVal = 247;
        }
        else if (this.game.bridgeArr.length === 2) {
            this.bridge.setAttribute('href', '#bridge2');
            gsap.set(this.bridge, { x: -1, y: 50 });
            this.yVal = 197;
        }
    }

    // take game input and create games array
    createGames(gIns: GameInput[]) {
        const gs: any[] = []

        gIns.forEach(gIn => {
            // setup bridge array
            const arr = []
            if (gIn.upperBridge) {
                // if(gIn.upperBridge.length === 0) {arr.push([0])}
                arr.push(gIn.upperBridge);

            }
            if (gIn.lowerBridge) {
                if (gIn.lowerBridge.length === 0) { arr.push([0]) }
                else { arr.push(gIn.lowerBridge) }
            }

            // setup limits dictionary
            // turn it into a dictionary first??

            const limitsDict = {}
            for (let i = 0; i < gIn.limits.length; i++) {
                limitsDict[gIn.limits[i][0]] = gIn.limits[i][1]
            }

            for (let i = gIn.fractionRange[0]; i <= gIn.fractionRange[1]; i++) {
                if (limitsDict[i] === undefined) {
                    limitsDict[i] = i * 2
                }
            }

            const g = {
                attempts: 0,
                bridgeArr: arr,
                complete: false,
                // limitedFractions: gIn.limitedFractions,
                // limits: gIn.limits,
                fractionRange: gIn.fractionRange,
                limits: limitsDict
            }

            gs.push(g)
        });

        return gs
    }

    // setup the spaces array based on the input
    setupSpaces() {

        this.spaces = []
        const self = this
        const xVal = this.xVal
        const yVal = this.yVal
        const delta = this.delta
        let count = 0

        // iterate over the different bridges
        for (let i = 0; i < this.game.bridgeArr.length; i++) {
            // iterate over the different spaces
            const l = this.game.bridgeArr[i].length
            for (let j = 0; j < this.game.bridgeArr[i].length; j++) {
                if (this.game.bridgeArr[i][j] <= 0) {
                    // space
                    const temp = {
                        board: i,
                        num: count,
                        size: 1 / l,
                        xVal: xVal + (self.wholeSize / l) * j,
                        yVal: yVal + i * delta
                    }
                    this.spaces.push(temp)
                }
                count++;
            }
        }

        this.ogSpaces = JSON.parse(JSON.stringify(this.spaces));


        let spacesTemp: Space[] = [];
        // combine the spaces (side-by-side spaces considered as a single space)
        if (this.spaces.length > 0) {
            spacesTemp = [this.spaces[0]];

            let index = 0
            for (let i = 1; i < this.spaces.length; i++) {
                if (this.spaces[i].num - 1 === this.spaces[i - 1].num && this.spaces[i].board === this.spaces[i - 1].board) {
                    spacesTemp[index].num += 1
                    spacesTemp[index].size += this.spaces[i].size
                }
                else {
                    spacesTemp.push(this.spaces[i])
                    index++;
                }
            }
        }

        this.spaces = spacesTemp;

    }

    // according to the input fill in spaces in the bridge
    setupTargets() {
        const self = this
        const xVal = this.xVal
        const yVal = this.yVal

        const height = this.height
        const delta = this.delta

        // var count = 0

        // rotate between the different bridges
        for (let i = 0; i < this.game.bridgeArr.length; i++) {
            // rotate between the different spaces
            const rect = document.createElementNS(svgns, 'rect')
            this.arena.appendChild(rect)
            gsap.set(rect, { fill: '#ffffff', fillOpacity: 0.01, height: height, rx: 2, stroke: '#b92c28ff', strokeWidth: 4, width: this.wholeSize, x: xVal, y: yVal + i * delta })

            this.targets.push(rect)

            const l = this.game.bridgeArr[i].length
            for (let j = 0; j < this.game.bridgeArr[i].length; j++) {
                const smallRect = document.createElementNS(svgns, 'rect')
                this.arena.appendChild(smallRect)
                gsap.set(smallRect, { height: height, rx: 2, stroke: '#b92c28ff', strokeWidth: 4, width: this.wholeSize / l, x: xVal + (this.wholeSize / l) * j, y: yVal + i * delta })

                this.targets.push(smallRect)

                if (this.game.bridgeArr[i][j] > 0) {
                    gsap.set(smallRect, { fill: '#f75c00ff', fillOpacity: 1 })
                }
                else {
                    // space
                    gsap.set(smallRect, { fill: '#ffffff', fillOpacity: 0.01 })

                    // var temp = {
                    //     num : count,
                    //     size : 1/l,
                    //     board : i,
                    //     xVal : xVal + (self.wholeSize/l)*j,
                    //     yVal : yVal + i*delta,
                    // }
                    // this.spaces.push(temp)
                }

                // count++;

            }
        }

        this.setupSpaces()


        // //combine the spaces
        // if (this.spaces.length > 0) {
        //     var spacesTemp = [this.spaces[0]]
        //     var index = 0
        //     for(var i = 1; i < this.spaces.length;i++) {
        //         if (this.spaces[i].num - 1 == this.spaces[i-1].num && this.spaces[i].board == this.spaces[i-1].board) {
        //             spacesTemp[index].num += 1
        //             spacesTemp[index].size += this.spaces[i].size
        //         }
        //         else {
        //             spacesTemp.push(this.spaces[i])
        //             index++;
        //         }
        //     }
        // }

        // this.spaces = spacesTemp
        // this.originalSpaces = this.deepCopy(this.spaces)
    }


    // setup the input
    setupInput() {
        const self = this
        // gsap.set(this.input, { x: "30%", y: "100%" })
        // gsap.set(this.input, { x: '39vh', y: '70vh' }) // to do: FIX

        this.currentImg = document.createElementNS(svgns, 'use')
        this.inputImg.appendChild(this.currentImg)
        this.currentImg.setAttribute('href', '#one')
        gsap.set(this.currentImg, { x: 0, y: '60%' })

        this.popup = document.createElementNS(svgns, 'use')
        this.inputImg.appendChild(this.popup)
        this.popup.setAttribute('href', '#outOfStockPopUp')
        // gsap.set(this.popup, {x : "1875%", y : "25%", visibility:"hidden"})
        gsap.set(this.popup, { visibility: 'hidden', x: '93vh', y: '1vh' }) // to do: FIX


        // this.orderBtn.onpointerdown = function() {
        //     window.alert("This piece is out of stock")
        // }

        this.setupSizes();


        this.inputSize.onchange = function (e) {
            const val = Number(self.inputSize.value);
            self.changeSizeImg(val);
            self.checkStock();
        }

        this.inputPieces.onchange = function(e) {
            self.checkStock(false);
        }

        this.popuptext = document.createElementNS(svgns, 'use')
        this.frontWater.appendChild(this.popuptext)
        this.popuptext.setAttribute('href', '#popuptext')
        gsap.set(this.popuptext, { visibility: 'hidden', x: '515', y: '370' })

        this.popuptext.onpointerdown = function () {
            gsap.set(self.popuptext, { visibility: 'hidden' })
        }

        this.orderBtn.onpointerdown = () => {
            this.puzzleEventService.puzzlePlayButtonClicked();
            this.doOrder();
        }
    }

    doOrder() {
        if (!this.finishedAttempt && this.canOrder) {
            this.order = { num: 0, pieces: Number(this.inputPieces.value), size: Number(this.inputSize.value) }
            gsap.set(this.popuptext, { visibility: 'hidden' })
            this.playAnimation()
        }
        else if (!this.canOrder) {
            // window.alert("This piece is out of stock")
            gsap.set(this.popuptext, { visibility: 'visible' })
        }
    }

    changeSizeImg(val: number) {
        if (val === 1) {
            this.currentImg.setAttribute('href', '#one')
            this.inputText.textContent = 'One Whole'
        }
        else if (val === 0.5) {
            this.currentImg.setAttribute('href', '#half')
            this.inputText.textContent = 'One Half'
        }
        else if (val === 0.33333) {
            this.currentImg.setAttribute('href', '#third')
            this.inputText.textContent = 'One Third'
        }
        else if (val === 0.25) {
            this.currentImg.setAttribute('href', '#fourth')
            this.inputText.textContent = 'One Fourth'
        }
        else if (val === 0.2) {
            this.currentImg.setAttribute('href', '#fifth')
            this.inputText.textContent = 'One Fifth'
        }
        else if (val === 0.16667) {
            this.currentImg.setAttribute('href', '#sixth')
            this.inputText.textContent = 'One Sixth'
        }
        else if (val === 0.14286) {
            this.currentImg.setAttribute('href', '#seventh')
            this.inputText.textContent = 'One Seventh'
        }
        else if (val === 0.125) {
            this.currentImg.setAttribute('href', '#eighth')
            this.inputText.textContent = 'One Eighth'
        }
        else if (val === 0.11111) {
            this.currentImg.setAttribute('href', '#ninth')
            this.inputText.textContent = 'One Ninth'
        }

    }

    private setupSizes() {

        // use fraction range
        // sizes


        this.inputSize.innerHTML = '';

        for (let i = this.game.fractionRange[0]; i <= this.game.fractionRange[1]; i++) {
            const txt = (i == 1) ? '1' : '1/' + (i).toString()
            const val = parseFloat((1 / i).toFixed(5));
            const op = new Option(txt, val.toString())

            this.inputSize.add(op, undefined)
        }

        this.changeSizeImg(parseFloat((1 / this.game.fractionRange[0]).toFixed(5)))

    }

    private populatePieceOptions(limit: number): void {
        // first remove all options
        var i, L = this.inputPieces.options.length - 1;
        for(i = L; i >= 0; i--) {
            this.inputPieces.remove(i);
        }

        // add the options up to the limit
        for (let i = 1; i <= limit; i++ ) {
            const o = new Option(`${i}`, `${i}`); // make a new option with text and value i
            this.inputPieces.add(o);
        }
    }

    private checkStock(repopulatePieces = true) {
        // var fraction = this.inputSize.selectedIndex + 1
        const strFraction = this.inputSize.options[this.inputSize.selectedIndex].text;
        let fraction;
        if (strFraction === '1') { fraction = 1 }
        else {
            try {
                fraction = Number(strFraction.slice(2))
            }
            catch {
                fraction = 0
            }
        }

        const lim = this.game.limits[fraction]

        if (repopulatePieces) {
            this.populatePieceOptions(lim);
        }

        const pieces = this.inputPieces.value

        if (!!!pieces || Number(pieces) > lim) {
            this.canOrder = false

            gsap.set(this.popup, { visibility: 'visible' })

            if (!(this.inputText?.textContent?.slice(-1) === ')')) { this.inputText.textContent += ' (Out of Stock)' }
        }
        else {
            //gsap.set(this.orderBtn, { backgroundColor: '#22c060', borderColor: '#22c060' })
            this.canOrder = true
            gsap.set(this.popup, { visibility: 'hidden' })
            gsap.set(this.popuptext, { visibility: 'hidden' })
        }

        // if we can't order disable the order button and also hide the play button
        this.orderBtn.setAttribute('class', this.canOrder ? 'bridgeOrderButton' : 'bridgeOrderButtonDisabled');

        if (this.onPlayEnable) {
            this.onPlayEnable(this.canOrder);
        }
    }

    private createRandomCar(): SVGImageElement {
        const carNames = ['bridge-banana-car.png', 'bridge-candy-car.png', 'bridge-tomato-car.png', 'bridge-hamburger-car.svg', 'bridge-carrot-car.svg'];

        const img = document.createElementNS(svgns, 'image');
        img.setAttribute('href', `images/${(carNames[randomIntegerFromInterval(0, carNames.length -1)])}`);

        return img;
    }

    // eslint-disable-next-line complexity
    playAnimation(): void {
        const self = this

        this.game.attempts++;
        this.finishedAttempt = true

        // remove fallen blocks
        this.fallingBlocks.forEach(b => {
            self.boat.removeChild(b)
        });
        this.fallingBlocks = []

        // reset fallen block timelines
        this.tl2.forEach(el => {
            el.t.clear()
        });
        this.tl2 = []

        gsap.set(this.input, { visibility: 'hidden' })


        // setup input blocks
        const size = this.order.size
        const num = this.order.pieces

        const ogX = 150 // this.xVal
        let xVal = ogX
        let yVal = this.boatY + 220


        const startScale = 1 // 0.5
        const endScale = 1

        const blocks: any[] = []

        for (let i = 0; i < num; i++) {
            // make the block

            if (xVal + this.wholeSize * size * startScale > ogX + this.wholeSize * startScale + 1) {
                xVal = ogX
                yVal -= this.height * startScale
            }

            const rect = document.createElementNS(svgns, 'rect')
            this.boat.appendChild(rect)
            gsap.set(rect, { fill: '#f75c00ff', height: this.height, rx: 2, scale: startScale, stroke: '#b92c28ff', strokeWidth: 4, width: this.wholeSize * size, x: xVal, y: yVal })
            this.animationEls.push(rect)

            blocks.push({ el: rect, size: size, used: false })

            xVal += this.wholeSize * size * startScale
        }

        // add front of boat
        const front = document.createElementNS(svgns, 'use')
        front.setAttribute('href', '#front')
        this.boat.appendChild(front)
        gsap.set(front, { x: 10, y: this.boatY })
        this.animationEls.push(front)


        gsap.set(this.boat, { x: '-=' + (1300) })
        this.tl.to(this.tools, { duration: 0.2, scale: 0 })
        this.tl.to(this.tools, { duration: 0.2 })
        this.tl.to(this.smallBoat, { duration: 3, ease: 'linear', x: '+= ' + 1300 })
        this.tl.to([this.boat, this.bigBoat], { duration: 3, ease: 'linear', x: '+=' + 1300 }, '<1')
        this.tl.to(this.boat, { duration: 1 })


        if (this.spaces.length > 0) {
            let finalBlock = blocks.length - 1
            let complete = false
            let space = this.spaces[0]
            let spaceIndex = 0;

            const moveSpeed = 1

            let fallenX = 0

            let notFilledExtras = false

            for (let i = blocks.length - 1; i >= 0; i--) {
                if (space.size > blocks[i].size && Math.abs(space.size - blocks[i].size) > 0.001) {
                    // move to
                    this.tl.to(blocks[i].el, { duration: moveSpeed, scale: endScale, x: space.xVal, y: space.yVal })
                    // update the size
                    space.size -= blocks[i].size
                    space.xVal += this.wholeSize * blocks[i].size

                    blocks[i].used = true

                    complete = false
                }

                else if (Math.abs(space.size - blocks[i].size) <= 0.001) {
                    // equal

                    // fill and move on to next space
                    this.tl.to(blocks[i].el, { duration: moveSpeed, scale: endScale, x: space.xVal, y: space.yVal })
                    blocks[i].used = true
                    spaceIndex++;
                    if (spaceIndex < this.spaces.length) { space = this.spaces[spaceIndex] }
                    else {
                        complete = true
                        finalBlock = i
                        break;
                    }
                }

                else if (space.size > 0) {
                    complete = false
                    // space is smaller than the block
                    // move block and let it fall

                    this.tl.to(blocks[i].el, {
                        duration: moveSpeed, onComplete: function () {
                            // move block to front
                            self.boat.removeChild(this.targets()[0])
                            self.boat.appendChild(this.targets()[0])
                        }, scale: endScale, x: space.xVal, y: space.yVal
                    })
                    this.tl.to(blocks[i].el, { ease: 'linear', rotation: 30 })
                    this.tl.to(blocks[i].el, { ease: 'linear', rotation: 90, x: '+=' + fallenX, y: 700 })

                    fallenX += this.height * 1.6

                    this.tl.to(blocks[i].el, {
                        duration: 1, ease: 'linear', onComplete: function () {
                            self.bobBlock(this.targets()[0])
                        }, y: '-=' + 50
                    })

                    notFilledExtras = true

                    break;
                }
            }

            this.tl.to(this.boat, { duration: 1 })


            // check if all blocks have been used
            // check if all spaces have been filled (spaceIndex == this.spaces.length)

            let check = true
            blocks.forEach(element => {
                if (!element.used) { check = false }
            });


            if (check && spaceIndex === this.spaces.length) {
                this.game.complete = true
                // cars driving accross for success animation
                this.tl.to(this.boat, {
                    duration: 0, onComplete: function () {
                        self.boat.removeChild(front);
                        self.animationEls.splice(self.animationEls.length - 1, 1);
                        self.successAnimation().then(() => {
                            self.recordAnswerAttempt(true);
                            self.showEndGameButtons();
                        });
                    }
                })
            }

            else if (spaceIndex < this.spaces.length) {
                // zoom in on gaps and have them flash red

                let minX = this.spaces[spaceIndex].xVal
                let minY = this.spaces[spaceIndex].yVal
                let maxX = minX + this.spaces[spaceIndex].size * this.wholeSize
                let maxY = minY + this.height

                for (let i = spaceIndex + 1; i < this.spaces.length; i++) {
                    if (this.spaces[i].xVal < minX) { minX = this.spaces[i].xVal }

                    if (this.spaces[i].xVal + this.spaces[i].size * this.wholeSize > maxX) { maxX = this.spaces[i].xVal + this.spaces[i].size * this.wholeSize }

                    if (this.spaces[i].yVal < minY) { minY = this.spaces[i].yVal }

                    if (this.spaces[i].yVal + this.height > maxY) { maxY = this.spaces[i].yVal + this.height }
                }
                // find the smallest x, smallest y
                // find farthest x and farthest y

                const vb = (minX - 10).toString() + ' ' + (minY - 10).toString() + ' ' + (maxX - minX + 20).toString() + ' ' + ((maxY - minY + 20)).toString()
                const highlights: any[] = []
                this.tl.to(this.svg, {
                    attr: { }, duration: 1, onComplete: function () {
                        // draw new rectangles to highlight

                        for (let i = spaceIndex; i < self.spaces.length; i++) {

                            const space = self.spaces[i]
                            const highlightRect = document.createElementNS(svgns, 'rect')
                            self.boat.appendChild(highlightRect)
                            self.animationEls.push(highlightRect)
                            gsap.set(highlightRect, { fillOpacity: 0.01, height: self.height, rx: 2, stroke: '#b92c28ff', strokeWidth: 4, width: self.wholeSize * space.size, x: space.xVal, y: space.yVal })

                            highlights.push(highlightRect)

                        }

                        self.tl.to(highlights, { duration: 1, strokeWidth: '+=4' })
                        self.tl.to(highlights, { duration: 1, stroke: '#ae002bff' }, '<')
                        self.tl.to(highlights, { duration: 1, strokeWidth: '-=4' })
                        self.tl.to(highlights, { duration: 1, stroke: '#b92c28ff' }, '<')
                        // self.tl.to(highlights, { stroke: "#ae002bff", duration: 1, onUpdate : function() {
                        //     gsap.set(highlights, {strokeWidth : "+="+ 0.5})
                        // } })
                        // self.tl.to(highlights, { stroke: "#b92c28ff", duration: 1 })
                        self.tl.to(self.svg, { attr: { viewBox: '0 0 1280 720' }, duration: 1 })

                        // big boat drives off
                        if (!notFilledExtras) { self.tl.to([self.bigBoat, front], { duration: 3, ease: 'linear', x: '+= 2000' }) }

                        self.tl.then(() => {
                            self.showEndGameButtons();
                        });
                    }
                })

                self.recordAnswerAttempt(false);
            }
            else {
                // have cars bump into them

                let car = document.createElementNS(svgns, 'use');
                const yDiff = this.boatY + 220 - (this.yVal - this.height)
                const xDiff = ogX - this.xVal

                this.tl.to(blocks[0].el, {
                    duration: moveSpeed, onComplete: () => {

                        // create a single car
                        const carYVal = this.yVal - 52;
                        const carsTop = [];

                        car.setAttribute('href', '#car');
                        this.boat.appendChild(car);
                        this.animationEls.push(car);
                        carsTop.push(car);
                        gsap.set(car, { x: -200, y: carYVal });

                        const rails = document.createElementNS(svgns, 'use');
                        rails.setAttribute('href', '#rails');
                        this.boat.appendChild(rails);
                        this.animationEls.push(rails);
                        gsap.set(rails, { x: 45, y: this.yVal - 12 });

                        // randomly change one of the cars to be one of the "special" cars
                        const randomIdx = randomIntegerFromInterval(0, carsTop.length -1);
                        carsTop[randomIdx] = this.createRandomCar();
                    },
                    onStart: function () {
                        for (let i = finalBlock - 1; i >= 0; i--) {
                            // re-add blocks to the front
                            this.boat.removeChild(blocks[i].el);
                            this.boat.appendChild(blocks[i].el);
                        }
                    }
                })

                for (var i = finalBlock - 1; i >= 0; i--) {
                    this.tl.to(blocks[i].el, { duration: moveSpeed, x: '-=' + xDiff, y: '-=' + (yDiff + 20) }, '<');
                }

                this.tl.to(blocks[0].el, { duration: moveSpeed / 2 });

                for (var i = finalBlock - 1; i >= 0; i--) {
                    this.tl.to(blocks[i].el, { duration: moveSpeed / 2, y: '+=' + 20 }, '<');
                }

                // big boat drives off
                self.tl.to([self.bigBoat, front], { duration: 3, ease: 'linear', x: '+= 2000'});


                this.tl.to(car, { duration: 1.5, ease: Power1.easeIn, x: this.xVal - 118 });
                this.tl.to(car, { duration: 1, ease: Power1.easeOut, x: '-=' + 50, onComplete: () => {
                    this.showEndGameButtons();
                } });
            }
        }
        else {
            this.showEndGameButtons()
        }
    }

    // add a timeline to bob the blocks that fall
    bobBlock(b: any) {
        const self = this
        this.tl2.push({ bobVal: 35, t: gsap.timeline() })

        const index = this.tl2.length - 1
        this.tl2[index].t.to(b, { duration: 1, ease: Power2.easeInOut, y: '+=' + this.tl2[index].bobVal })
        this.tl2[index].t.to(b, {
            duration: 1.2, ease: Power2.easeInOut, onComplete: function () {
                if (self.tl2[index].bobVal > 10) { self.tl2[index].bobVal -= 10 }
                else if (self.tl2[index].bobVal > 5) { self.tl2[index].bobVal -= 5 }
            }, y: '-=' + this.tl2[index].bobVal
        })

        this.tl2[index].t.repeat(-1)
    }

    async successAnimation(): Promise<void> {
        // create 6 cars
        const yVal = this.yVal - 52
        let specialCarIdx = randomIntegerFromInterval(0, 2);
        const carsTop = []
        for (let i = 0; i < 3; i++) {
            let car = null;

            if (specialCarIdx === i) {
                car = this.createRandomCar();
                car.setAttribute('height', '50px'); // the yellow cars are standardized at 50px
            } else {
                car = document.createElementNS(svgns, 'use')
                car.setAttribute('href', '#car')
            }

            this.boat.appendChild(car)
            this.animationEls.push(car)
            carsTop.push(car)
            gsap.set(car, { x: 300 + i * 300 - 1200, y: yVal })
        }

        const carsBottom = []

        specialCarIdx = randomIntegerFromInterval(0, 2);

        if (this.game.bridgeArr.length > 1) {
            for (let i = 0; i < 3; i++) {
                let car = null;

                if (specialCarIdx === i) {
                    car = this.createRandomCar();
                    car.setAttribute('height', '50px'); // the yellow cars are standardized at 50px
                } else {
                    car = document.createElementNS(svgns, 'use')
                    car.setAttribute('href', '#car')
                }

                this.boat.appendChild(car)
                this.animationEls.push(car)
                carsBottom.push(car)
                gsap.set(car, { scaleX: -1, x: 300 + i * 300 + 1200, y: yVal + (this.delta + 2) })
            }
        }

        // create rocks on side if double bridge
        if (this.game.bridgeArr.length == 2) {
            const blocks = document.createElementNS(svgns, 'use')
            blocks.setAttribute('href', '#blocks')
            this.boat.appendChild(blocks)
            this.animationEls.push(blocks)
            gsap.set(blocks, { x: -1, y: 50 + 146 })

        }

        // create rails
        for (var i = 0; i < this.game.bridgeArr.length; i++) {
            const rails = document.createElementNS(svgns, 'use')
            rails.setAttribute('href', '#rails')
            this.boat.appendChild(rails)
            this.animationEls.push(rails)
            gsap.set(rails, { x: 45, y: this.yVal - 12 + (this.delta - 1) * i })
        }


        // big boat drives off
        this.tl.to(this.bigBoat, { duration: 3, ease: 'linear', x: '+= 2000' })

        // fade to day
        this.tl.to([this.background, this.frontWater], { duration: 2, opacity: 0 })

        // move cars
        this.tl.to(carsTop, { duration: 5, ease: 'linear', x: '+= 2400' })
        this.tl.to(carsBottom, { duration: 5, ease: 'linear', x: '-= 2400' }, '<')

        return new Promise((resolve) => {
            this.tl.then(() => {
                resolve();
            });
        });
    }

    showEndGameButtons() {
        if (this.onPuzzleAnimationEnd) {
            this.onPuzzleAnimationEnd();
        }
    }

    private openDialog(): void {
        if (!this.finishedAttempt ) {
            this.input.style.visibility = 'visible';
            this.checkStock();
        }
    }

    setupButtons() {
        this.smallBoat.onpointerdown = this.openDialog.bind(this);
        // same as above
        this.tools.onpointerdown = this.openDialog.bind(this);

        addEventListener('resize', e => {
            this.onResize();
        });

        this.onResize();
    }

    private onResize() {
        const currentPuzzleWidth = this.svg.getBoundingClientRect().width;
        const curScale = currentPuzzleWidth / 1280;
        this.overlay.style.scale = curScale.toString();
        this.overlay.style.width = `${(currentPuzzleWidth * (1 / curScale))}px`;
    }

    startAnimation() {
        const self = this

        gsap.set(this.input, { visibility: 'hidden' });

        // lightning bolt
        const allPathInfo: any[] = []
        const allPaths = this.lightning.childNodes

        allPaths.forEach(p => {
            allPathInfo.push({ length: (p as SVGPathElement).getTotalLength(), path: p })
        });
        allPathInfo.forEach(el => {
            gsap.set(el.path, { opacity: 1, strokeDasharray: el.length, strokeDashoffset: el.length })
        });

        this.tl.to(allPaths, { duration: 1 })
        this.tl.to(allPaths, { duration: 0.3 })
        allPathInfo.forEach(el => {
            this.tl.to(el.path, { duration: 0.2, strokeDashoffset: 0 }, '<')
        });
        this.tl.to(allPaths, { duration: 0.8, opacity: 0 })

        // blocks fall
        for (let i = 0; i < this.ogSpaces.length; i++) {

            const space = this.ogSpaces[i]
            const fallBlock = document.createElementNS(svgns, 'rect')
            this.boat.appendChild(fallBlock)
            gsap.set(fallBlock, { fill: '#e74c00ff', height: this.height, rx: 2, stroke: '#b92c28ff', strokeWidth: 4, width: this.wholeSize * space.size, x: space.xVal, y: space.yVal })

            this.fallingBlocks.push(fallBlock)

        }

        this.tl.to(this.fallingBlocks, { duration: 1.2, ease: Power2.easeInOut, y: 700 }, '<')
        this.tl.to(this.fallingBlocks, {
            duration: 1, ease: 'linear', onComplete: () => {
                this.bobBlock(self.fallingBlocks)
            }, y: '-=' + 50
        })

        this.tl.to(this.fallingBlocks, { duration: 3, ease: 'linear', x: '+=' + 1300 }, '<')

        // move in little boat
        this.tl.to(this.smallBoat, { duration: 2, ease: 'linear', x: 53 }, '<');
        this.tl.to(this.tools, { duration: 1, ease: 'elastic', scale: 1});
    }

    reset() {
        this.tl.clear()

        this.finishedAttempt = false

        gsap.set(this.bigBoat, { x: 10 - 1300 })
        gsap.set(this.smallBoat, { x: -400 })

        // fade to day
        gsap.set([this.background, this.frontWater], { opacity: 1 })

        this.animationEls.forEach(el => {
            this.boat.removeChild(el)
        });
        this.animationEls = [] // remove all elements

        // gsap.set(this.retryBtn, { scale: 0 })

        //if (this.game.complete || this.game.attempts >= 3) { gsap.set(this.nextBtn, { scale: 1 }) }
        //else { gsap.set(this.nextBtn, { scale: 0 }) }

        if (this.onPlayEnable) {
            this.onPlayEnable(false);
        }

        this.setupSpaces();

        this.startAnimation();

    }

    nextGame() {
        const self = this
        this.gameIndex = (this.gameIndex + 1) % this.games.length
        this.game = this.games[this.gameIndex]

        this.targets.forEach(element => {
            self.arena.removeChild(element)
        });
        this.targets = []

        this.setBridge()

        this.setupTargets()
        this.setupSizes()

        this.reset()

    }

    // usability functions

    setupUsability() {
        const self = this
        // add pointer

        this.pointer = document.createElementNS(svgns, 'use')
        this.pointer.setAttribute('href', '#pointer')
        this.usability.appendChild(this.pointer)

        gsap.set(this.usability, { visibility: 'hidden' })
        gsap.set(this.pointer, { transformOrigin: '0% 100%', x: 0, y: 0 })

        // setup btn

        this.helpBtn.onpointerdown = function () {
            if (!self.tl.isActive()) { self.pointTo(90, 493, self.smallBoat) }
        }
    }

    pointTo(x: number, y: number, el: HTMLElement | SVGUseElement) {
        const self = this
        gsap.set(this.usability, { visibility: 'visible' })

        this.tl.pause()
        const tempT = gsap.timeline()

        const w = 80
        const dist = Math.sqrt(x ** 2 + y ** 2)

        if (el !== null) {
            const clone = el.cloneNode(true)
            this.usability.appendChild(clone)
        }


        const c = document.createElementNS(svgns, 'circle')
        this.usability.appendChild(c)
        gsap.set(c, { attr: { cx: x, cy: y, r: 0 }, fillOpacity: 0, stroke: '#fff' })

        this.usability.appendChild(this.pointer)

        tempT.to(this.pointer, { duration: dist * (1.2 / 500), ease: 'bounce', x: x - 0.24 * w, y: y + 0.02 * w })
        tempT.to(this.pointer, { duration: 0.15, ease: 'bounce', scaleY: 1.1 })
        tempT.to(this.pointer, { duration: 0.15, ease: 'bounce', scaleX: 0.8 }, '<')
        // start circles
        tempT.to(c, { alpha: 0, attr: { r: 50 } })
        tempT.to(this.pointer, { duration: 1.2, ease: 'elastic', scaleX: 1 })
        tempT.to(this.pointer, { duration: 1.2, ease: 'elastic', scaleY: 1 }, '<')

        tempT.to(this.pointer, {
            duration: 0.5, onComplete: function () {
                // end
                gsap.set(self.usability, { visibility: 'hidden' })
                gsap.set(self.pointer, { x: 0, y: 0 })
                self.usability.removeChild(c)
            self.tl.play()
            }
        });
    }

    public onPuzzlePlay(): void {
        this.doOrder();
    }

    public onPuzzleTryAgain(): void {
        this.reset();
    }

    public onPuzzleHelp(): void {
        if (this.input.style.visibility === 'hidden' && this.tools.style.visibility !== 'hidden') {
            this.openDialog();
        } else if (this.tools.style.visibility !== 'hidden') {
            this.animateUserAttention(this.input);
        }
    }
}
