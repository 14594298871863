import { Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FabricSprite } from '../../../../utils/fabric-utils/FabricSprite';
import { ManipulativeChildObject } from '../../../models/manipulative-child-object.model';

@UntilDestroy()
@Component({
  selector: 'sprite',
  styleUrls: ['./kh-sprite.component.less'],
  templateUrl: './kh-sprite.component.html'
})

export class KhSpriteComponent extends ManipulativeChildObject {
  private sprite!: FabricSprite;
  public order = 1;
  public isLoaded = new BehaviorSubject(false);

  @Input() public img = '';
  @Input() public frame = '';

  public override onDynamicMount(): void {
    this.sprite = new FabricSprite({
      backgroungImage: this.img.replace(/'/g, ''),
      frame: this.frame.replace(/'/g, '').split(',')
        .map(Number)
    })
    this.sprite.isLoading$.pipe(
      tap(() => this.isLoaded.next(true)),
      untilDestroyed(this)
    ).subscribe()
  }

  public get fabricObject(): FabricSprite[] {
    return [this.sprite];
  }
}
