/* eslint-disable indent */

import { gsap } from 'gsap';
import { Line, StrokeData } from '@svgdotjs/svg.js';
import { NumberLineJump } from './NumberLineJump';
import { NumberLineMarker } from './NumberLineMarker';
import { SvgRenderer } from '../svg-components/SvgRenderer';
import { KHPoint } from '../pixi-puzzle-framework/PuzzleEngine/KHPoint';

export type NumberLineMarkerData = {
    value: number;
    label: string;
}

export type NumberlineConfig = {
    startPosition: KHPoint;
    endPosition: KHPoint;
    target: number;
    stroke: StrokeData;
    markers: NumberLineMarkerData[];
    constantJumpSize?: number;
    labelJumps: boolean;
    divisor: number;
    startJumpNumber?: number;
}

export class NumberLine {
    private config: NumberlineConfig;
    private line: Line;
    private renderer: SvgRenderer;
    private jumpBuckets: NumberLineJump[][] = [];
    private startNumber: number;
    private endNumber: number;
    private padding = 65;
    private startPointWithPadding: KHPoint;
    private endPointWithPadding: KHPoint;
    private listenerId: number | undefined;
    private strokeData: StrokeData;

    public constructor(renderer: SvgRenderer, config: NumberlineConfig) {
        this.renderer = renderer;
        this.config = config;
        const paddingPoints = this.getPointsWithPadding();

        this.startPointWithPadding = paddingPoints.start;
        
        this.endPointWithPadding = paddingPoints.end;
        this.strokeData = {...this.config.stroke};
    }

    public render(): NumberLine {
        this.line = this.renderer.svg.line(
            this.config.startPosition.x,
            this.config.startPosition.y,
            this.config.endPosition.x,
            this.config.endPosition.y);

        this.line.stroke(this.config.stroke);

        this.startNumber = Number(this.config.markers[0].value);
        this.endNumber = Number(this.config.markers[this.config.markers.length -1].value);

        this.createMarkers();
        this.buildJumpBuckets();
        this.setupCameraListener();

        return this;
    }

    private createMarkers(): void {
        const width = this.getLineWidthPadding();

        for (let i = 0; i < this.config.markers.length; i++) {
            const curMarker = this.config.markers[i];
            const curMarkerValue = Number(curMarker.value);

            // find the x position on the line
            const xPos = (width * (curMarkerValue / this.endNumber)) + this.startPointWithPadding.x;

            new NumberLineMarker(this.renderer, {
                fontSize: 30,
                lineLength: 20,
                position: {x: xPos, y: this.config.startPosition.y},
                stroke: {color: 'white', linecap: 'round', width: 5},
                value: curMarker.label
            }).render();
        }
    }

    private isValueInMarker(val: number): boolean {
        for (let i = 0; i < this.config.markers.length; i++) {
            const curMarker = this.config.markers[i];

            // if the value is the same AND it's been labelled at all, then consider it already labelled
            if (curMarker.value === val && curMarker.label) {
                return true;
            }
        }

        return false;
    }

    private getDivisor(): number {
        return this.config.divisor ? this.config.divisor : 1;
    }

    public async animate() {
        let lastPoint = null;
        let lastJumpSize = null;

        for (let i = 0; i < this.jumpBuckets.length; i++) {
            if (lastPoint && lastJumpSize) {
                await this.zoomCamera(lastPoint, lastJumpSize);
            }

            const curBucket = this.jumpBuckets[i];
            let curJump: NumberLineJump | null = null;
        
            for (let j = 0; j < curBucket.length; j++) {
                curJump = curBucket[j];
                curJump.render();
                lastPoint = curJump.getEndPoint();
                lastJumpSize = curJump.getJumpDistance();
                await curJump.animate();
            }

            // if we hit a 0, just divide by 10 to zoom in
            if (curBucket.length === 0 && lastJumpSize) {
                lastJumpSize = lastJumpSize / 10;
            }
        }
    }

    public reset() {
        this.jumpBuckets.forEach(bucket => {
            bucket.forEach(jump => {
                jump.destroy();
            })
        });

        this.jumpBuckets = [];

        this.buildJumpBuckets();
    }

    private buildJumpBuckets(){
        if (this.config.constantJumpSize)
        {
            this.createJumpBucketsConstant(this.config.target, this.config.constantJumpSize);
        } else {
            this.createJumpBuckets(this.config.target);
        }
    }

    private getPointsWithPadding(): {start: KHPoint, end: KHPoint} {
        return {
            end: {x: this.config.endPosition.x - this.padding, y: this.config.endPosition.y},
            start: {x: this.config.startPosition.x + this.padding, y: this.config.startPosition.y}
        }
    }

    private async zoomCamera(point: KHPoint, jumpSize: number) {
        const cam = this.renderer.camera;
        let direction = 1;

        if (this.config.startJumpNumber) {
            direction = this.getTarget() < this.config.startJumpNumber ? -1 : 1;
        }

        // this 10 is a fudge us fit 9 jumps on the screen at max zoom
        const newScale = (jumpSize + (10 * cam.getScale())) / this.renderer.camera.originalSize.x;

        // determine if camera is going left or right
        const camCenter = cam.getCenter();
        const diff = point.x - camCenter.x;

        // const newScale = this.renderer.camera.getScale() / 9;
        const p = {...point};
        const pointOffsetInBaseSpace = 440 * direction;
        p.x += pointOffsetInBaseSpace * newScale;

        const tweenObjFrom = {scale: cam.getScale(), x: cam.getCenter().x, y: cam.getCenter().y};
        const tweenObjTo = {scale: newScale, x: p.x, y: p.y};

        return new Promise<void>(resolve => {
            gsap.to(tweenObjFrom, {...tweenObjTo, duration: 2,
                onComplete: (() => {
                resolve();
            }),
            onUpdate: (() => {
                cam.setScale(tweenObjFrom.scale);
                cam.centerOn({x: tweenObjFrom.x, y: tweenObjFrom.y});
            })})});
    }

    private getLineWidthPadding(): number {
        return Math.abs(this.endPointWithPadding.x - this.startPointWithPadding.x);
    }

    private lineRatio(): number {
        return this.getLineWidthPadding() / this.endNumber;
    }

    private createJumpBucketsConstant(jumpToValue: number, jumpSize: number): void {
        this.jumpBuckets = [];
        let startValue = this.startNumber;

        if (this.config.startJumpNumber) {
            startValue = this.config.startJumpNumber;
        }

        const totalJumps = Math.ceil(Math.abs(startValue - jumpToValue)) / jumpSize;
        const bucket: NumberLineJump[] = [];
        const jumpValue = (Math.abs(startValue - this.getTarget()) / totalJumps);
        let spacing = (this.endPointWithPadding.x - this.startPointWithPadding.x) / Math.abs(this.endNumber - this.startNumber);

        const directionMultiplier = startValue > jumpToValue ? -1 : 1;

        spacing *= jumpSize * directionMultiplier;

        let curX = 0 + this.startPointWithPadding.x;

        if (this.config.startJumpNumber) {
            curX = this.getPointForNumber(this.config.startJumpNumber).x;
        }

        for (let i = 0; i < totalJumps; i++) {
            const jumpEndValue = startValue + jumpValue * (i + 1) * directionMultiplier;

                bucket.push(new NumberLineJump(this.renderer, {
                    endPoint: {x: curX + spacing, y: this.config.startPosition.y},
                    startPoint: {x: curX, y: this.config.startPosition.y},
                    stroke: {color: 'white', width: 4},
                    showNumbers: this.config.labelJumps && !this.isValueInMarker(jumpEndValue),
                    jumpEndValue: jumpEndValue,
                    fontSize: 30,
                    scaleDirection: directionMultiplier
            }));

            curX += spacing;
        }

        this.jumpBuckets.push(bucket);
    }

    private getIntegerSizing(num: number): number[] {
        const result: number[] = [];
        let divisor = 1;

        // Handle negative numbers
        if (num < 0) {
          num = Math.abs(num);
          result.push(-1); // Indicate negative sign
        }

        while (num >= 1) {
          const digit = Math.floor((num % 10) * divisor);
          result.unshift(digit);
          num = Math.floor(num / 10);
          divisor *= 10;
        }

        return result;
    }

    private createJumpBuckets(jumpToValue: number): void {
        this.jumpBuckets = [];
        const buckets = [];
        let jumpTargetVal = jumpToValue;

        if (this.config.startJumpNumber) {
            jumpTargetVal -= this.config.startJumpNumber;
        }

        const directionMultiplier = jumpTargetVal < 0 ? -1 : 1;
        const valueAsString = Math.abs(jumpTargetVal).toString().replace('.', '');

        // instead of doing math just stringify the number and convert the string at each index to be a number.
        // this gives us the number of jumps at each size and scales basically infinitely (or at least as far as we'll ever need)
        for (let i = 0; i < valueAsString.length; i ++) {
            buckets[i] = Number(valueAsString[i]);
        }

        let curX = 0 + this.startPointWithPadding.x;
        let curValue = this.startNumber;

        if (this.config.startJumpNumber) {
            curValue = this.config.startJumpNumber;
            curX = this.getPointForNumber(this.config.startJumpNumber).x;
        }

        for (let i = 0; i < buckets.length; i++) {
            const curNum = buckets[i];
            const curSize = Math.pow(10, buckets.length - 1 - i);
            const spacing = this.lineRatio() * curSize * directionMultiplier;
            const newBucket: NumberLineJump[] = [];

            for (let j = 0; j < curNum; j++) {
                curValue += curSize * directionMultiplier;

                newBucket.push(new NumberLineJump(this.renderer,
                    {
                    endPoint: {x: curX + spacing, y: this.config.startPosition.y},
                    startPoint: {x: curX, y: this.config.startPosition.y},
                    stroke: {color: 'white', width: 4},
                    fontSize: 30,
                    showNumbers: this.config.labelJumps && !this.isValueInMarker(curValue),
                    jumpEndValue: curValue / this.getDivisor(),
                    scaleDirection: directionMultiplier
                }));

                curX += spacing;
            }

            this.jumpBuckets.push(newBucket);
        }
    }

    /*
    * Linearly interpolates between two values based on the given factor.
    * 
    * @param x - Start point.
    * @param y - End point.
    * @param t - Interpolation factor in the closed interval [0, 1].
    * @returns The interpolated value.
    */
     private lerp(x: number, y: number, t: number): number {
       return x + t * (y - x);
   }

    public getPointForNumber(num: number): KHPoint {
        const xval = this.lerp(this.startPointWithPadding.x, this.endPointWithPadding.x, num / (this.endNumber - this.startNumber));

        return {x: xval, y: this.startPointWithPadding.y};
    }

    public setTarget(num: number) {
        this.config.target = num;
        this.buildJumpBuckets();
    }

    public getTarget(): number {
        return Number(this.config.target);
    }

    public getPathTransformString(newScale: number): string {
        return `scale(${newScale} ${newScale})`;
    }

    public setupCameraListener(): void {
        this.listenerId = this.renderer.camera.addOnScaleChangeListener(this.onScaleChange.bind(this));
    }

    private onScaleChange(newScale: number): void {
        this.strokeData.width = (this.config.stroke.width as number) * newScale;
        this.line.stroke(this.strokeData);
    }

    public destroy() {
        if (this.line) {
            this.line.remove();
        }

        if (this.listenerId) {
            this.renderer.camera.removeOnScaleChangeListener(this.listenerId);
        }
    }

    public doCorrect(): void {
        if (!this.config.constantJumpSize) {
            this.jumpBuckets.forEach(b => b.forEach(jump => jump.destroyText()));
        }
    }
}
