import { gsap, Linear } from "gsap";
import { Draggable } from "gsap/Draggable";
import { OverlayNumberInput } from "../puzzle-ui/OverlayNumberInput";
import { Sprite, World } from "./factoryApi";
import { PuzzleBase } from "../puzzle/puzzleBase";
import { SvgNumberInput } from "../svg-components/SvgNumberInput";
import { SvgDropdownInput } from "../svg-components/SvgDropdownInput";


const svgns = "http://www.w3.org/2000/svg";

interface Label extends SVGTextElement {
  value: number;
  initialValue: number;
  _width: number;
}

interface JellyBlock extends Sprite {
  initialValue: number;
  inBin: boolean;
}

interface FactoryPuzzleSetup {
  dom: any;
  props: any;
  arena: SVGElement;
}

gsap.registerPlugin(Draggable);

/**
 * A minimal implementation of a puzzle for you to work off of when you make a new puzzle. It is fully functional but not particularly deep.
 */
export class PuzzleFactoryLogic extends PuzzleBase {
  // API
  world: World | undefined;

  // DOM
  arena: Element;
  assets: SVGDefsElement;
  container: HTMLElement;

  // Dynamic Local UI Elements
  private teeth: SVGLineElement | undefined;
  private ticks: SVGLineElement | undefined;
  private gear?: Sprite;
  private header?: Sprite;
  private stamp?: Sprite;
  private bin?: Sprite;
  private walls: Sprite[] = [];
  private jellyblocks?: Array<JellyBlock> = [];
  private labels?: Array<Label> = [];
  private conveyor: SVGRectElement | undefined;
  private iconFreq: Sprite | undefined;
  private iconEnd: Sprite | undefined;
  private crateLines: Array<SVGLineElement> = [];
  private labelStart: SVGTextElement = document.createElementNS(svgns, "text");
  private labelEnd: SVGTextElement = document.createElementNS(svgns, "text");
  private crateBears: Sprite[] = [];
  private initialCrateBears: Sprite[] = [];

  // Input Elements
  private inputStart: SvgNumberInput | undefined;
  private inputEnd: SvgDropdownInput | undefined;
  private inputFreq: OverlayNumberInput | undefined;

  // Constants
  private PLATFORM_Y: number = 450;
  private PLATFORM_STROKE: number = 50;
  private TOOTH_STROKE_SIZE: number = 20;
  private TOOTH_STROKE_COLOR: string = "#262E73";
  private NUMBER_LINE_TEXT_COLOR: string = "#ABFFF5";
  private INPUTS_START_X: number = 240;
  private INPUTS_START_Y: number = 15;
  private INPUTS_DELTA: number = 120;
  private TICK_STROKE_SIZE: number = 20;
  private CONVEYOR_HEIGHT: number = 65;
  private STAMP_CENTER: number = 245; // 75 is the stamp width
  private WALL_ORIGIN = { x: 70, y: 540 };
  private WALL_SPACING = { dx: 83, dy: 50 };
  private JELLY_BLOCK_FRAME = { width: 60, height: 92 };

  // Local Vars
  private numberLineMin: number = -3;
  private numberLineMax: number = 15;
  private deltaX: number = 65;
  private toothStrokeThickness: number = 0.15 * this.deltaX;
  private gummyWidth: number = this.deltaX;
  private gearDiameter: number = (this.deltaX * 10) / Math.PI;
  private gearCircumfrence: number = Math.PI * this.gearDiameter;
  private frequency: number = 3;
  private bearSpacing: number = 3;
  private startValue: number = 0;
  private bearStart: number = 3;
  private machineIsAt: number = this.startValue;
  private toothOffset: number = this.deltaX / 2;
  private endValue: number = 30;
  private stampDelta: number = 0.3 * this.PLATFORM_Y;
  private props: any;
  private endInputChoices: string[];
  private startXOffset: number = 0;
  private touchReceiver: SVGRectElement = document.createElementNS(svgns,'rect')

  // Style Properties
  private styleActiveNumberInput: string = `background-color: #284169; position: relative; border-width: 4px; color: #40e6ff; border-color: #40e6ff; border-radius: 10px;`;
  private styleInactiveNumberInput: string = `pointer-events: none;background-color: #11143d; border-style: none; position: relative; color: #40e6ff; border-radius: 10px;`;

  // Timelines
  feedback: gsap.core.Timeline = new gsap.core.Timeline({
    onComplete: this.onFeedbackComplete.bind(this),
    paused: true,
  });

  help: gsap.core.Timeline = new gsap.core.Timeline({
    paused: true,
  });

  public tryAgain(): void {
    throw new Error("Method not implemented.");
  }

  private currentSelected: "correct" | "incorrect" | "none" = "none";

  /**
   * Create a new puzzle that gets the button IDs from the DOM by svg node lookup
   */
  constructor(setup: FactoryPuzzleSetup) {
    super({ attempts: [] }, setup.arena);

    this.assets = setup.dom.assets;
    this.arena = setup.dom.arena;
    this.container = setup.dom.container;
    this.props = setup.props;

    this.startValue = this.props!.start;
    this.bearSpacing = this.props.bearSpacing;
    this.bearStart = this.props.bearStart;
    this.frequency = this.props.frequency;
    this.endInputChoices = this.props.endInputChoices.split(",");
    this.endValue = Number(this.endInputChoices[0]);
    this.numberLineMin = Number(this.numberLineMin) + Number(this.startValue);
    this.numberLineMax = Number(this.numberLineMax) + Number(this.startValue);
    this.deltaX = this.props.deltaX;
    gsap.set(this.touchReceiver,{fill: "white",fillOpacity: 0.001,attr: {x: 0, y: 0, width: 1280,height: 720}})

    //this.svgRenderer.camera.enableFreeCamera();
  }

  /**
   * STEP 2: This is called when the "play" button in the overlay is clicked
   */
  public onPuzzlePlay(): void {
    // Do things when the play button is clicked.

    // Play animation
    this.playAnimation(this.currentSelected === "correct");
  }

  /**
   * This is called when the user tries again
   */
  public onPuzzleTryAgain(): void {
    /*

    // Putting the hammer back.
    gsap.set(this.hammer,{rotation: 0})

    */

    // Reconstruct Scene:
    this.layoutView();

    //this.playIntroAnimation();
    this.currentSelected = "none";
  }

  /**
   * This is called if the user attempts to press "submit" while disabled
   */
  public onPuzzleHelp(): void {
    this.animateHelp()
  }

  public animateHelp(){
    if (this.inputEnd) {
      this.inputEnd.foreignObject.style.overflow = 'visible';
      this.animateUserAttention(this.inputEnd.element);
    }
    this.help.restart()
  }

  /**
   * Called once the overlay is successfully "hooked up" to this puzzle
   * If we move to having the overlay as part of the angular component this won't be neccessary anymore.
   *
   * Think of it as an "on load" or "on ready" call. Once this is done you are ready to manipulate the DOM.
   */
  public onPuzzleOverlayConnect(): void {
    super.onPuzzleOverlayConnect();
    this.init(this.playIntroAnimation);
  }

  /**
   * An animation that slides in the buttons.
   */
  private playIntroAnimation(): void {}

  private buildFeedbackTimeline(end: number) {
    this.crateBears = [...this.initialCrateBears];

    let dt = this.frequency / 6;

    let totalDistance = end - this.startValue;
    let cranks = Math.floor(totalDistance / this.frequency);

    let crankRemainder = totalDistance % this.frequency;

    let angle = (360 * this.frequency * this.deltaX) / this.gearCircumfrence;
    let translation = this.frequency * this.deltaX;

    for (let i = 0; i <= cranks; i++) {
      if (i == cranks) {
        translation = crankRemainder * this.deltaX;
        angle = (360 * translation) / this.gearCircumfrence;
      }

      const onLabelComplete = () => {
        this.labels?.forEach((l, i) => {
          let _x = Number(gsap.getProperty(l, "x"));
          let _textContent = Number(gsap.getProperty(l, "textContent"));

          if (_x < -30) {
            _textContent = _textContent + 30;
            l._width = l.getBBox().width;
            const jump = 30 * this.deltaX;

            gsap.set(l, { x: "+=" + jump, textContent: _textContent });
            let w = l.getBBox().width;
            let offset = l._width - w;
            l._width = w;

            gsap.set(l, { x: "+=" + offset / 2 });
          }
        });
      };

      let jb = this.jellyblocks!.find(
        (block) => block.initialValue == this.machineIsAt
      );

      if (jb != null) {
        let goTo = { x: 0, y: 0 };
        let currentBearLength = this.crateBears.length;
        goTo = this.getCrateMembersLocation(currentBearLength + 1);
        this.crateBears.push(jb as Sprite); // Don't push if there are too many!

        jb.inBin = true;
        this.feedback.to(this.stamp!, {
          duration: 0.2,
          y: "+=" + this.stampDelta,
        });

        // BEGIN CRATE ANIMATION (Only if there is space!)

        this.feedback.call(() => jb!.setAttribute("href", "#gummybear"));
        this.feedback.to(this.stamp!, {
          duration: 0.3,
          y: "-=" + this.stampDelta,
          ease: "elastic",
        });

        if (currentBearLength >= 10) {
          this.feedback.to(
            jb,
            { duration: 0.6, x: goTo.x, ease: Linear.easeNone },
            "-=0.3"
          );
          this.feedback.to(
            jb,
            { duration: 0.3, y: "-=65", ease: "sine.out" },
            "<"
          );
          this.feedback.to(
            jb,
            { duration: 1.2, y: goTo.y, ease: "bounce" },
            "-=0.3"
          );
          this.feedback.to(
            jb,
            { duration: 0.2, scaleY: 1.45, scaleX: 0.8 },
            "-=1.5"
          );
          this.feedback.to(
            jb,
            { duration: 1, scaleY: 1, scaleX: 1, ease: "elastic" },
            "-=1.2"
          );
        } else {
          this.feedback.to(
            jb,
            { duration: 0.6, x: goTo.x, ease: Linear.easeNone },
            "-=0.3"
          );
          this.feedback.to(
            jb,
            { duration: 0.3, y: "-=65", ease: "sine.out" },
            "<"
          );
          this.feedback.to(
            jb,
            { duration: 0.8, y: goTo.y, ease: "bounce" },
            "-=0.3"
          );

          this.feedback.to(
            jb,
            { duration: 0.2, scaleY: 1.45, scaleX: 0.8 },
            "-=1.2"
          );
          this.feedback.to(
            jb,
            { duration: 1, scaleY: 1, scaleX: 1, ease: "elastic" },
            "-=1"
          );
        }
      } else if (i != 0) {
        this.feedback.to(this.stamp!, {
          duration: 0.2,
          y: "+=" + this.stampDelta,
        });
        this.feedback.to(this.stamp!, {
          duration: 0.3,
          y: "-=" + this.stampDelta,
          ease: "elastic",
        });
      }

      if (i <= cranks) {
        let moveMe = this.jellyblocks!.filter((jb) => jb.inBin == false);

        this.feedback.to(this.gear!, { duration: dt, rotation: "-=" + angle });
        this.feedback.to(
          [this.teeth!, this.ticks!],
          { duration: dt, strokeDashoffset: "+=" + translation },
          "<"
        );
        this.feedback.to(
          this.labels!,
          { duration: dt, x: "-=" + translation, onComplete: onLabelComplete },
          "<"
        );
        this.feedback.to(moveMe, { duration: dt, x: "-=" + translation }, "<");
      }

      this.machineIsAt = Number(this.machineIsAt) + Number(this.frequency);
    }

    this.layerCrate();
    this.add(this.stamp!);
    this.add(this.header!);
    this.add(this.iconFreq!);
    this.add(this.iconEnd!);
    this.arena.appendChild(this.inputEnd!.foreignObject);
    //this.arena.appendChild(this.inputFreq!.foreignObject);
    this.crateLines.forEach((l) => this.add(l));
  }

  // Class should have an official completion handler.
  private onFeedbackComplete(isCorrect: boolean) {
    this.recordAnswerAttempt(isCorrect);

    if (this.onPuzzleAnimationEnd) {
      // Signal to the overlay the feedback animation is complete
      this.onPuzzleAnimationEnd();
    }

    // BEN!!!! Can you put a confetti drop here?

    this.feedback.kill();

    if (this.crateBears.length === 10) {
      this.recordAnswerAttempt(true);
      this.dropConfetti();
    } else {
      this.recordAnswerAttempt(false);
    }
  }

  private freqInputOnChange(value: number) {
    this.frequency = value;
  }

  private endInputOnChange() {
    this.endValue = Number(this.inputEnd!.value);
  }

  /**
   * Play the feedback animation. If correct we fade out the incorrect button
   * if incorrect (or "none") we fade out the correct button
   * @param isCorrect
   */

  // STEP 3 - write some animations here. You can delete my code.
  private playAnimation(isCorrect: boolean): void {
    // STEP 4 - Build you feedback timeline.

    this.buildFeedbackTimeline(this.endValue);

    setTimeout(() => {
      this.feedback.play();
    }, 50);
  }

  private onGearDrag(parent: PuzzleFactoryLogic) {
    const self = (this as any) as Draggable;

    gsap.set(parent.teeth!, { strokeDashoffset: (-600 * self.rotation) / 360 });
  }

  private add(child: SVGElement) {
    this.arena.appendChild(child);
  }

  private remove(child: SVGElement) {
    this.arena.removeChild(child);
  }

  private buildDropDownArray(start: number, end: number, step: number) {
    let a = [] as Array<String>;

    let total = start;

    while (total <= end) {
      let tmp = (total as unknown) as String;
      a.push(tmp);
      total += step;
    }
  }

  private layoutGummies(spacing: number) {
    this.bearSpacing = spacing;

    this.jellyblocks?.forEach((b, i) => {
      b._setTexture("jellyblock");
      b._refreshData();
      b.inBin = false;

      let ugh = Number(this.bearStart) - Number(this.startValue);
      let _x =
        this.STAMP_CENTER +
        ugh * this.deltaX +
        i * this.deltaX * this.bearSpacing -
        this.JELLY_BLOCK_FRAME.width / 2;

      gsap.set(b, {
        transformOrigin: "50% 100%",
        y: this.PLATFORM_Y - this.JELLY_BLOCK_FRAME.height,
        rotation: 0,
        x: _x,
      });

      b.initialValue = Number(this.bearStart) + Number(i * this.bearSpacing);
    });
  }

  private layerCrate() {
    let flyBears: Sprite[] = [];
    this.add(this.walls[0]);
    this.crateBears.forEach((b, i) => {
      this.add(b);
      if (i + 1 == 5) {
        this.add(this.walls[1]);
      }
      if (i >= 10) {
        this.add(b);
      }

      if (i >= 10) {
        flyBears.push(b);
      }
    });

    if (this.crateBears.length < 6) {
      this.add(this.walls[1]);
    }

    this.add(this.walls[2]);

    this.crateLines.forEach((l) => this.add(l));

    flyBears.forEach((fb) => {
      this.add(fb);
    });
  }

  private setCrateLocations() {
    let _x = this.WALL_ORIGIN.x + 15;
    let _y = this.WALL_ORIGIN.y;
    this.crateBears.forEach((b, i) => {
      gsap.set(b, { x: _x, y: _y });
      _x += this.WALL_SPACING.dx;
      if ((i + 1) % 5 == 0) {
        _y += this.WALL_SPACING.dy;
        _x = this.WALL_ORIGIN.x + 15;
      }
    });
  }

  private getCrateMembersLocation(position: number) {
    let _x =
      this.WALL_ORIGIN.x + ((position - 1) % 5) * this.WALL_SPACING.dx + 15;
    let _y =
      this.WALL_ORIGIN.y + Math.floor(position / 6) * this.WALL_SPACING.dy;

    if (position > 10) {
      _y = this.WALL_ORIGIN.y + this.WALL_SPACING.dy * 3;
      _x = this.WALL_ORIGIN.x + this.WALL_SPACING.dx * (2 + Math.random());
    }

    return { x: _x, y: _y };
  }

  private layoutView() {
    const push = 30;

    gsap.set(this.labelStart, {
      fontSize: 20,
      y: 125,
      x: this.INPUTS_START_X + this.INPUTS_DELTA + push,
      fill: this.NUMBER_LINE_TEXT_COLOR,
      textContent: "Start",
    });
    gsap.set(this.labelEnd, {
      fontSize: 20,
      y: 125,
      x: this.INPUTS_START_X + 1.25 * this.INPUTS_DELTA + push,
      fill: this.NUMBER_LINE_TEXT_COLOR,
      textContent: "End",
    });

    /*
    this.guideLazers.forEach((l,i)=>{
      gsap.set(l,{x: this.deltaX+i*this.deltaX*this.frequency})
    })
    */

    // Layering
    this.add(this.teeth!);
    this.add(this.conveyor!);
    this.add(this.ticks!);
    this.labels!.forEach((l) => this.add(l));
    this.jellyblocks!.forEach((l) => this.add(l));
    this.add(this.stamp!);
    this.add(this.header!);
    this.add(this.gear!);
    //this.add(this.labelStart)
    //this.add(this.labelEnd)
    this.add(this.iconEnd!);

    // Icons
    this.add(this.iconFreq!);
    this.add(this.inputEnd?.foreignObject!);
    //this.add(this.iconSpacing!)
    //this.add(this.iconTurns!)

    this.machineIsAt = this.startValue;

    this.header!._refreshData();
    gsap.set(this.header!, { x: 90 });

    // Gear
    this.gear!._refreshData();
    this.gear!._setWidth((this.deltaX * 10) / Math.PI);
    gsap.set(this.gear!, {
      rotation: -16,
      x: 640,
      y: this.PLATFORM_Y + this.CONVEYOR_HEIGHT,
      transformOrigin: "50% 50%",
    });

    const firstStep = 0.5 * this.deltaX - this.TOOTH_STROKE_SIZE;
    const secondStep = 0.5 * this.deltaX + this.TOOTH_STROKE_SIZE;

    // Conveyor
    gsap.set(this.teeth!, {
      strokeDasharray: firstStep + " " + secondStep,
      strokeDashoffset: 0,
      strokeWidth: 0.5 * this.deltaX,
      stroke: this.TOOTH_STROKE_COLOR,
      strokeLinecap: "round",
      attr: {
        x1: -this.toothOffset,
        y1:
          this.PLATFORM_Y +
          this.CONVEYOR_HEIGHT -
          this.toothStrokeThickness / 2,
        x2: 1280,
        y2:
          this.PLATFORM_Y +
          this.CONVEYOR_HEIGHT -
          this.toothStrokeThickness / 2,
      },
    });

    this.labels!.forEach((l, i) => {
      let intOffset = Math.round(this.STAMP_CENTER / this.deltaX) as number;

      this.startXOffset = this.STAMP_CENTER - this.deltaX * intOffset;

      let v = Number(this.startValue) - Number(intOffset) + i;
      l.initialValue = v;

      gsap.set(l, {
        textContent: v,
        fontSize: 30,
        fill: this.NUMBER_LINE_TEXT_COLOR,
      });

      l._width = l.getBBox().width;

      let _x = this.startXOffset + this.deltaX * i - l._width / 2;
      gsap.set(l, { x: _x, y: this.PLATFORM_Y + this.TICK_STROKE_SIZE * 2.5 });
    });

    // Stamp
    this.stamp!._refreshData();
    gsap.set(this.stamp!, {
      transformOrigin: "50% 0%",
      y: 10,
      x: this.STAMP_CENTER - this.stamp!._width / 2,
    });

    this.layoutGummies(this.bearSpacing);

    gsap.set(this.ticks!, {
      strokeDasharray: 0.15 * this.deltaX + " " + 0.85 * this.deltaX,
      strokeDashoffset: -this.startXOffset,
      strokeWidth: this.TICK_STROKE_SIZE,
      stroke: this.TOOTH_STROKE_COLOR,
      attr: {
        x1: (-0.15 * this.deltaX) / 2,
        y1: this.PLATFORM_Y + this.TICK_STROKE_SIZE / 2,
        x2: 1280,
        y2: this.PLATFORM_Y + this.TICK_STROKE_SIZE / 2,
      },
    });

    // Inputs

    //gsap.set(this.iconTurns!,{x: this.INPUTS_START_X+27.5,y: 105})
    gsap.set(this.iconFreq!, { y: 100, x: this.INPUTS_START_X + 20.5 });
    gsap.set(this.iconEnd!, {
      x: this.INPUTS_START_X + 1.5 * this.INPUTS_DELTA - 5,
      y: 95,
    });
  }

  /// STEP 1: This is where you setup all of your stuff when the app loads. This is only called one time.
  private init(playIntroAnimation: () => void) {
    this.world = new World(this.arena as SVGSVGElement);

    this.touchReceiver.addEventListener('pointerdown',this.animateHelp.bind(this))
    

    /// Initialization (things we only have to do once)

    // Create Sprites and set Immutable Properties
    this.gear = this.world.newSpriteFrom("gear");
    this.stamp = this.world.newSpriteFrom("stamp");
    this.header = this.world.newSpriteFrom("header");
    this.bin = this.world.newSpriteFrom("bin");
    this.iconFreq = this.world.newSpriteFrom("iconfreq");
    this.iconEnd = this.world.newSpriteFrom("iconend");
    this.teeth = document.createElementNS(svgns, "line");
    this.ticks = document.createElementNS(svgns, "line");
    this.conveyor = document.createElementNS(svgns, "rect");

    let bears = 10 - this.props.target;

    for (let i = 0; i < bears; i++) {
      this.initialCrateBears.push(this.world.newSpriteFrom("gummybear"));
    }

    this.crateBears = [...this.initialCrateBears];

    for (let i = 0; i < 3; i++) {
      let w = this.world.newSpriteFrom("wall");
      this.walls!.push(w);
    }

    this.walls!.forEach((w, i) => {
      this.add(w);
      gsap.set(w, {
        x: this.WALL_ORIGIN.x,
        y: this.WALL_ORIGIN.y + i * this.WALL_SPACING.dy,
      });
    });

    // Create Jelly Blocks - an array of 20. (This is called "asset pooling")
    for (let i = 0; i < 20; i++) {
      let jb = this.world.newSpriteFrom("jellyblock") as JellyBlock;
      gsap.set(jb, { transformOrigin: "50% 100%" });
      this.jellyblocks!.push(jb);
    }

    // Event Handlers

    /*

    Disabling draggable for now.

    Draggable.create(this.gear, {
      type: "rotation",
      inertia: true,
      onDrag: this.onGearDrag,
      onDragParams: [this],
    });

    */

    // Set view layoutx

    gsap.set(this.conveyor, {
      y: this.PLATFORM_Y,
      strokeWidth: this.deltaX / 10,
      stroke: "#252E73",
      fill: "#2061B0",
      width: 1300,
      height: this.CONVEYOR_HEIGHT,
    });

    for (let i = 0; i < 30; i++) {
      let l = (document.createElementNS(svgns, "text") as any) as Label;
      let v = this.numberLineMin + i;
      l.initialValue = v;
      this.labels!.push(l);
      gsap.set(l, {
        textContent: v,
        fontSize: 30,
        fill: this.NUMBER_LINE_TEXT_COLOR,
      });

      l._width = l.getBBox().width;

      let _x = this.deltaX * i - l._width / 2;
      gsap.set(l, { x: _x, y: this.PLATFORM_Y + this.PLATFORM_STROKE / 4 });
    }

    // Frequency Input
    this.inputFreq = new OverlayNumberInput(
      {
        height: 70,
        width: 100,
        ignoreDecimalsInLength: true,
        defaultValue: this.frequency,
        maxLength: 1,
        minNumber: 0,
        style: this.styleInactiveNumberInput,
        x: this.INPUTS_START_X,
        y: this.INPUTS_START_Y,
      },
      this.puzzleUiFixedOverlay!
    );

    this.inputFreq.onChange = this.freqInputOnChange.bind(this);

    // End Input
    this.inputEnd = new SvgDropdownInput(this.arena as HTMLElement, {
      height: 70,
      width: 100,
      options: this.endInputChoices,
      style:
        "font-size: 35px; border-radius: 5px;text-align-last:center; border-width: 4px; background-color: #262E73; color: #40e6ff; border-color: #ABFFF5;",
      x: this.INPUTS_START_X + 1.25 * this.INPUTS_DELTA,
      y: this.INPUTS_START_Y,
    });

    this.inputEnd.onChange = this.endInputOnChange.bind(this);
    gsap.set(this.inputEnd!.foreignObject,{transformOrigin:"50% 50%"})

    setTimeout(() => {
      this.layoutView();
    }, 50);
  

    this.help.to(this.inputEnd!.foreignObject,{scale: 1.4,duration: 0.5})
    this.help.to(this.inputEnd!.foreignObject,{scale: 1,duration: 1, ease: "elastic"})



    // our intro animation is empty, this will play on startup and also if you hit reset.
    if (playIntroAnimation) {
      playIntroAnimation();
    }

    // lazers
    for (let i = 0; i < 6; i++) {
      let l = document.createElementNS(svgns, "line");
      gsap.set(l, {
        strokeWidth: this.deltaX * 0.2,
        strokeOpacity: 1,
        stroke: this.TOOTH_STROKE_COLOR,
        attr: { x1: 0, y1: 0, x2: 0, y2: this.WALL_SPACING.dy * 2 },
      });
      gsap.set(l, {
        x:
          this.deltaX * 0.1 +
          this.WALL_ORIGIN.x +
          (i * (425 - this.deltaX * 0.2)) / 5,
        y: this.WALL_ORIGIN.y,
      });
      this.crateLines.push(l);
    }

    this.layerCrate();
    this.setCrateLocations();

    this.add(this.touchReceiver)
  
  }
}
