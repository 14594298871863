/* eslint-disable indent */

import { setElementAttrs } from "../puzzle/PuzzleUtils";
import { SvgRenderer } from "./SvgRenderer";


export type SvgDropdownInputConfig = {
    width: number,
    height: number,
    options: string[],
    x: number,
    y: number,
    defaultValue?: string,
    style: string;
    backgroundImage?: string
}

const defaultProps: SvgDropdownInputConfig = {
  width: 100,
  height: 50,
  x: 0,
  y: 0,
  options: [],
  style: ''
}

/**
 * In a push to move puzzle logic and SVG manipulations out of Angular this is specifically not an angular component.
 * While templating would be quite nice isolation is our current goal
 */
export class SvgDropdownInput {
    public element: HTMLSelectElement;
    public foreignObject: SVGForeignObjectElement;
    public value: string;
    public onChange?: (value: string) => void;
    private properties: SvgDropdownInputConfig;

    public constructor(parent: HTMLElement | SVGElement, properties: SvgDropdownInputConfig) {
        this.properties = {...defaultProps, ...properties}; //overwrite any defaults with provided values
        this.foreignObject = document.createElementNS(SvgRenderer.svgns, 'foreignObject');
        this.element = document.createElement('select');
        this.element.setAttribute('style', properties.style + `width: ${properties.width}px;  height: ${properties.height}px; --`);
        this.value = '';

        // explicitly check if default value was undefined or null. The reason is the user might want '' or 0 to be their defaults so
        // a blanket "is Defined" check in JS will result in those being undefined
        if (properties.defaultValue !== undefined && properties.defaultValue !== null) {
          this.value = properties.defaultValue;
        }

        setElementAttrs(this.element, {
            value: properties.defaultValue ? properties.defaultValue.toString() : '',
            type: 'number'
        });

        this.setOptions();
        this.setupEventListeners();
        this.setForeignObjectAttributes();
        this.setStyle();

        this.foreignObject.appendChild(this.element);
        parent.appendChild(this.foreignObject);
    }

    /**
     * <select> element have children <options> so iterate over the options array and build them
     */
    private setOptions(): void {
      for (let i = 0; i < this.properties.options.length; i++) {
      const curOptionValue = this.properties.options[i];
        const optEl = document.createElement('option');
        optEl.value = curOptionValue;
        optEl.text = curOptionValue;
        this.element.appendChild(optEl);
      }
    }

    private setupEventListeners(): void {
        this.element.addEventListener('change', evt => {
          const newValue = (evt.target as HTMLSelectElement).value;
          this.value = newValue;

          if (this.onChange) {
              this.onChange(this.value);
          }
      });
    }

    private setForeignObjectAttributes(): void {
      setElementAttrs(this.foreignObject, {
        height: this.properties.height.toString(),
        width: this.properties.width.toString(),
        x: this.properties.x.toString(),
        y: this.properties.y.toString()});
    }

    private setStyle(): void {
      this.foreignObject.innerHTML = `<style>
      input {
          font-size: 40px;
          text-align: center;
          ${this.getBgImageString()}
      }

      input:focus{
          outline: none;
      }
    </style>`;
    }


    private getBgImageString(): string {
        if (this.properties.backgroundImage) {
            return `background-image: url("${this.properties.backgroundImage}");
            background-position: center;
            background-repeat: no-repeat;`;
        }

        return '';
    }
}
