import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TEMPLATE_PREFIX } from '../../../environments/locale-config';

@Component({
  selector: 'kh-manip-controls-at-bottom',
  templateUrl: TEMPLATE_PREFIX + 'manip-controls-at-bottom.component.html',
  styleUrls: ['./manip-controls-at-bottom.component.less']
})
export class ManipControlsAtBottomComponent implements OnInit, OnDestroy {
  @Output() public closed = new EventEmitter<void>();
  private timer!: number;

  constructor(public readonly bsModalRef: BsModalRef) {
  }

  public ngOnInit(): void {
    this.timer = window.setTimeout(() => {
      this.closed.emit();
      this.bsModalRef.hide();
    }, 3500);
  }

  public ngOnDestroy(): void {
    clearTimeout(this.timer);
  }
}
