/* eslint-disable max-len */
import { gsap } from 'gsap/gsap-core';
import { MinecartSlectionMenu } from './MinecartSelectionMenu';
import { MinecartTerm } from './MinecartTerm';
import { MinecartTermTopMenu } from './MinecartTermTopMenu';
import { KHPoint } from '../pixi-puzzle-framework/PuzzleEngine/KHPoint';

export type MinecartMenuOverlayConfig = {
  numChoices: number;
  choiceString: string;
  overlay: HTMLElement;
  equationElement: HTMLElement;
  equationInputElement: HTMLDivElement;
  showImages: boolean;
  useText: boolean;
}

const defaultMinecartConfig: Partial<MinecartMenuOverlayConfig> = {
  numChoices: 3,
  showImages: true,
  useText: true
}
export class MinecartMenuOverlay {
  private isEditPaused = false;
  private topTermMenu: MinecartTermTopMenu;
  private topInputMenu: MinecartSlectionMenu;
  private isEdit = false;
  private config: MinecartMenuOverlayConfig;

  public constructor(config: MinecartMenuOverlayConfig) {
    this.config = {...defaultMinecartConfig, ...config};

    this.topTermMenu = new MinecartTermTopMenu(this.config.numChoices, this.config.equationElement, !this.config.useText, this.config.showImages, this.topTermSelected.bind(this));
    this.topInputMenu = new MinecartSlectionMenu(this.config.equationInputElement,
      this.config.overlay,
      this.parseChoices(this.config.equationInputElement, this.config.choiceString),
      this.onAddTermSelect.bind(this));
  }

  private parseChoices(parent: HTMLDivElement, choiceString: string): MinecartTerm[] {
    const terms: MinecartTerm[] = [];
    const choiceSplit = choiceString.split(',');

    choiceSplit.forEach(c => {
      const choiceVal = this.getValueFromChoice(c);

      if (c.startsWith('+S')) {
        terms.push(new MinecartTerm(choiceVal, parent, 'sandbag', !this.config.useText, this.config.showImages, (t: MinecartTerm) => this.onAddTermSelect(t)));
      } else if (c.startsWith('-S')) {
        terms.push(new MinecartTerm(choiceVal * -1, parent, 'sandbag', !this.config.useText, this.config.showImages, (t: MinecartTerm) => this.onAddTermSelect(t)));
      } else if (c.startsWith('+B')) {
        terms.push(new MinecartTerm(choiceVal, parent, 'balloon', !this.config.useText, this.config.showImages, (t: MinecartTerm) => this.onAddTermSelect(t)));
      } else if (c.startsWith('-B')) {
        terms.push(new MinecartTerm(choiceVal * -1, parent, 'balloon', !this.config.useText, this.config.showImages, (t: MinecartTerm) => this.onAddTermSelect(t)));
      }
    });

    return terms;
  }

  private getValueFromChoice(choice: string): number {
    return Number(choice.substring(2, choice.length));
  }

  private async tweenTerm(term: MinecartTerm, targetPosition: KHPoint): Promise<void> {
    const startPoint = this.getTermPoint(term);
    term.setSelected(false); // this true false dance is a lazy way to clone the node so it's got a white background.
    const clonedTerm = term.element.cloneNode(true) as HTMLElement;
    term.setSelected(true);
    clonedTerm.style.position = 'absolute';
    clonedTerm.style.transform = `translate(${startPoint.x}px, ${startPoint.y}px)`;

    this.config.overlay.appendChild(clonedTerm);

    return new Promise(resolve => {
      gsap.to(clonedTerm, {
        duration: 0.3,
        onComplete: () => {
          clonedTerm.remove();
          resolve();
        },
        x: targetPosition.x,
        y: targetPosition.y});
    });
  }

  private getTermPoint(term: MinecartTerm): KHPoint {
    const inverseScale = 1 /Number(this.config.overlay.style.scale);
    const svgRect = this.config.overlay.getBoundingClientRect();
    const rect = term.element.getBoundingClientRect();

    return {x: (rect.left - svgRect.left) * inverseScale, y: (rect.top - svgRect.top) * inverseScale};
  }

  private async animateTopElementToOriginalPosition(): Promise<void> {
    if(this.topTermMenu.selectedTerm && this.topTermMenu.selectedTerm.value !== 0) {
      const originalTerm = this.topInputMenu.getTermWithValue(this.topTermMenu.selectedTerm.value, this.topTermMenu.selectedTerm.type);

      if (originalTerm) {
        const originalPoint = this.getTermPoint(originalTerm);

        return this.tweenTerm(this.topTermMenu.selectedTerm, originalPoint);
      }
    }

    return undefined;
  }

  private async onAddTermSelect(t: MinecartTerm): Promise<void> {
    const animationPromises: Promise<void>[] = [];

    if (this.topTermMenu.selectedTerm) {
      animationPromises.push(this.tweenTerm(t, this.getTermPoint(this.topTermMenu.selectedTerm)));
      animationPromises.push(this.animateTopElementToOriginalPosition());
    }

    const targetTermCache = this.topTermMenu.selectedTerm;

    await Promise.all(animationPromises);

    if (targetTermCache) {
      this.topInputMenu.showElementWithValue(targetTermCache.value, targetTermCache.type);
    }

    targetTermCache?.setType(t.type);
    targetTermCache?.setValue(t.value);
  }

  private async topTermSelected(t: MinecartTerm): Promise<void> {
    if (this.isEditPaused) {
      return;
    }

    this.topInputMenu.setVisible(true);
    this.topInputMenu.setPositionTarget(t);

    if (this.topTermMenu.selectedTerm === t && this.topInputMenu.isVisible()) { // the same term was selected twice, so remove it
      const cachedValue = this.topTermMenu.selectedTerm.value;
      const cachedType = this.topTermMenu.selectedTerm.type;

      this.animateTopElementToOriginalPosition().then(() => {
        this.topInputMenu.unselectAllTerms();
        this.topInputMenu.showElementWithValue(cachedValue, cachedType);
      });

      this.topTermMenu.selectedTerm.setType('none');
      this.topTermMenu.selectedTerm.setValue(0);
    }
    else {
      this.topInputMenu.setPositionTarget(t);
      this.topInputMenu.setVisible(true);
    }
  }

  public pauseEdit(pause: boolean) {
    this.isEditPaused = pause;
    this.topTermMenu.clickEnabled = !pause;
  }

  public beginEdit(): void {
    if (!this.isEdit) {
      this.isEdit = true;
    }
  }

  public endEdit(): void {
    if (this.isEdit) {
      this.isEdit = false;
      this.topTermMenu.unselectAll();
    }

    this.topInputMenu.setVisible(false);
  }

  public getNumberOfTerms(): number {
    return this.topTermMenu.getTermCount();
  }

  public getTerms(): MinecartTerm[] {
    return this.topTermMenu.getTerms();
  }

  public reset(): void {
    this.topTermMenu.reset();
    this.topInputMenu.reset();
  }

  public setTermAsError(term: MinecartTerm): void {
    term.setAsError(true);
  }

  public setTermAsHighlighted(term: MinecartTerm): void {
    term.setSelected(true);
  }
}
