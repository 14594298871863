
import { AfterViewInit, Component, ElementRef, Input, ViewChild, Inject } from '@angular/core';

import { FarmPuzzleBase } from './FarmPuzzleBase';
import { FarmMarkupParser } from './parsers/FarmMarkupParser';
import { OnDynamicData, OnDynamicMount } from 'ngx-dynamic-hooks';
import { PuzzleEventService } from '../../services/puzzle-event.service';
import { PuzzleBaseComponent } from '../../puzzle/puzzle.component';
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from '../../../services/IQuestionEvents';
import { IAnalyticsService, ANALYTICS_SERVICE_TOKEN } from '../../../services/IAnalyticsService';
import { AUTH_DATA_SERVICE_TOKEN, IAuthenticationDataService } from '../../../services/IAuthenticationDataService';

@Component({
  selector: 'kh-puzzle-farm',
  template: '<div #pixiDiv></div>'
})
export class PuzzleFarm extends PuzzleBaseComponent implements AfterViewInit, OnDynamicMount {
  @ViewChild('pixiDiv') public pixiDiv: ElementRef<HTMLParagraphElement>;
  @Input('count') public count = 1000;

  public constructor(
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService,
    protected puzzleEventService: PuzzleEventService,
    @Inject(ANALYTICS_SERVICE_TOKEN) protected readonly analyticsService: IAnalyticsService,
    @Inject(AUTH_DATA_SERVICE_TOKEN) protected readonly authenticationDataService: IAuthenticationDataService,) {
    super(questionEventsService, puzzleEventService, analyticsService, authenticationDataService);

    puzzleEventService.isPixi.next(true);
    this.onPixiStart = this.makeFarmPuzzle.bind(this);
    this.onPixiStop = this.stopFarmPuzzle.bind(this);
  }

  private makeFarmPuzzle(): void {
    if (this.pixiDiv?.nativeElement) {
      const parser = new FarmMarkupParser();
      const result = parser.parseMarkup(this.dynamicChildren);

      const puzzle = new FarmPuzzleBase({
        parent: this.pixiDiv.nativeElement,
        farmData: result,
        overlayDiv: this.getPuzzleContainer()
      });

      this.setPuzzle(puzzle);
    }
  }

  private stopFarmPuzzle(): void {    
    if (this.puzzle) {
      this.puzzle.destroy();
      this.puzzle = null;
    }
  }

  public ngAfterViewInit(): void {
    this.awaitUserBeginForPixi();
    
  }
  
  public onDynamicMount(data: OnDynamicData): void {
    console.log('dynamoid');
    if (this.puzzle && (this.puzzle as FarmPuzzleBase).isDoneLoading()) {
      this.puzzle?.onPuzzleTryAgain();
    }
  }

  private getPuzzleContainer(): HTMLDivElement | undefined {
    if (this.pixiDiv.nativeElement) {
      let el: HTMLElement | null = this.pixiDiv.nativeElement;

      while (el) {
        if (el.classList.contains('puzzleSvgContainer')) {
          return el as HTMLDivElement;
        }

        el = el.parentElement;
      }
    }

    return undefined; // Not found
  }
}
