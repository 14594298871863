import { svgns } from "../spotlight/spotlight-api"
import { gsap } from "gsap";

export interface Sprite extends SVGUseElement {
  _anchorX: number 
  _anchorY: number
  _width: number, 
  _height: number,
  _aspectRatio: number,
  _baseFrame: {width: number,height: number},
  _setWidth: (width: number)=>void

  _setTexture: (t: string)=>void
  _refreshData: ()=>void
}


export class World {
  
  arena: SVGSVGElement
  constructor(arena: SVGSVGElement){
  this.arena = arena

  }

  add(element: Sprite){
    this.arena.appendChild(element)
  }

  newSpriteFrom(texture: string){
    let s = document.createElementNS(svgns,"use") as Sprite

    s._setTexture = (t: string)=> {
      s.setAttribute('href', "#" + texture);
    }


    s._setWidth = (width: number) => {
      let _scale = width/s._baseFrame.width
      gsap.set(s,{scale: _scale})
    }


    s._refreshData = ()=> {
        const {width,height} = s.getBBox()
        s._width = width
        s._height = height 
        s._aspectRatio = width/height
        s._baseFrame = {width: s.getBBox().width,height: s.getBBox().height}
      } 
    
    s._setTexture(texture)
    s._refreshData()

    return s
  }

}