import { fabric } from 'fabric';
import { Subject } from 'rxjs';

export interface IInputSpriteOpts{
  frame: number[];
  backgroungImage: string;
}

export class FabricSprite extends fabric.Image {
  public isLoading$ = new Subject<void>();

  public constructor(
    options: IInputSpriteOpts
  ) {
    super();
    const [left, top, width, height] = options.frame;

    this.setSrc(options.backgroungImage ?? '', () => {
      (this as FabricSprite).set({
        top: top + (height - (this.height ?? 0)) / 2,
        left: left + (width - (this.width ?? 0)) / 2,
        hasBorders: false,
        hasControls: false,
        height: this.height,
        hoverCursor: 'default',
        lockMovementX: true,
        lockMovementY: true,
        width: this.width,
        originX: 'left',
        originY: 'top',
        selectable: false
      })
      if (this.width && this.height) {
        this.scaleY = this.scaleX = Math.min(width / this.width, height / this.height);
      }
      this.isLoading$.next();
    });
  }
}
