import { Component, Input } from "@angular/core";
import { ScorableData } from "../kh-manip-scorable/IScorable";
import { ControlType } from "../../../enums/control-type.enum";

@Component({
  selector: "forward-control",
  templateUrl: "kh-manip-forward.component.html",
  styleUrls: ["kh-manip-forward.component.less"],
})
export class KhManipulativeForwardComponent {
  @Input("key") public key: string = "";
  @Input("image") public image: string = "";
  @Input("frame") public frame = "0 0 100 100";

  public onClick: () => void;
  public box = { left: 0, top: 0, width: 0, height: 0, };

  public ngOnInit() {
    const [left, top, width, height] = this.frame.split(" ").map(Number);
    this.box = { left, top, width, height };
  }

  public onClickEvent(): void {
    if (this.onClick) {
      this.onClick();
    }
  }
}
