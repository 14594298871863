import { Component, ElementRef, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FabricManipText } from '../../../../utils/fabric-utils/FabricManipText';
import { ManipulativeChildObject } from '../../../models/manipulative-child-object.model';


@Component({
  selector: 'manip-text',
  styleUrls: ['./manip-text.component.less'],
  templateUrl: './manip-text.component.html'
})

export class ManipTextComponent extends ManipulativeChildObject {

  public order = 1;

  @Input() public font = '';
  @Input() public backgroundColor = '';
  @Input() public strokeColor = '';
  @Input() public strokeWidth = '';
  @Input() public frame = '';
  @Input() public fontSize = '';

  public constructor(private _elementRef: ElementRef) {
    super();
  }

  private parseValue(stringValue: string, dimensionValue: number): number {
    const parsedValue = parseFloat(stringValue);

    return stringValue.endsWith('%') ? parsedValue * dimensionValue / 100 : parsedValue;
  }

  public get isLoaded(): Observable<boolean> {
    return of(true);
  }

  public get fabricObject(): FabricManipText[] {

    const [top, left, width, height] = this.frame.replace(/'/g, '').split(',')
      .map(Number);

    const strokeWidth = this.parseValue(this.strokeWidth.replace(/'/g, ''), width);
    const fontSize = this.parseValue(this.fontSize.replace(/'/g, ''), height);

    const textElem = this._elementRef.nativeElement as HTMLElement;
    const inText = (textElem).innerText.replace(/\n/gi, '').replace(/\\n/gi, '\n')

    const manipText = new FabricManipText(inText, {
      backgroundColor: this.backgroundColor,
      fontFamily: this.font.replace(/'/g, ''),
      fontSize,
      frame: this.frame.replace(/'/g, '').split(',')
        .map(Number),
      strokeColor: this.strokeColor,
      strokeWidth: strokeWidth
    });
    textElem.style.display = 'none';
    return [manipText];
  }
}
