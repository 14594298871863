import { MinecartTerm } from './MinecartTerm';

export class MinecartTermTopMenu {
  private numberOfEelements = 0;
  private container: HTMLElement;
  private onTermSelected: (term: MinecartTerm) => void;
  public selectedTerm: MinecartTerm | null = null;
  private terms: MinecartTerm[] = [];
  private useSymbols = false;
  private showImages = true;
  public clickEnabled = true;

  public constructor(numberOfElements: number, termContainer: HTMLElement, useSymbols: boolean, showImages: boolean, onTermSelected: (term: MinecartTerm) => void) {
    this.numberOfEelements = numberOfElements;
    this.container = termContainer;
    this.onTermSelected = onTermSelected;
    this.useSymbols = useSymbols;
    this.showImages = showImages;
    this.createElements();
  }

  private createElements(): void {
    for (let i = 0; i < this.numberOfEelements; i++) {
      this.terms.push(new MinecartTerm(0, this.container, 'none', this.useSymbols, this.showImages, this.termSelected.bind(this)));
    }
  }

  private termSelected(t: MinecartTerm): void {
    if (this.clickEnabled) {
      this.terms.forEach(term => term.setSelected(false));
      this.onTermSelected(t);
      this.selectedTerm = t;
      t.setSelected(true);
    }

  }

  public getTermCount(): number {
    return this.getTerms().length;
  }

  public unselectAll(): void {
    this.terms.forEach(t => {
      t.setSelected(false);
    });

    this.selectedTerm = null;
  }

  /**
   * Get the number of populated terms (empty terms don't count)
   */
  public getTerms(): MinecartTerm[] {
    return this.terms.filter(t => t.value !== 0 && t.type !== 'none');
  }

  public reset(): void {
    this.terms.forEach(t => {
      t.setSelected(false);
      t.setAsError(false);
      t.setType('none');
      t.setValue(0);
    });
  }
}
