export const RegionDefaultOptions = {
  fill: 'transparent',
  hasBorders: false,
  hasControls: false,
  rx: 5,
  ry: 5,
  selectable: false,
  stroke: '#cccccc',
  strokeLineJoin: 'round',
  strokeWidth: 0,
  ignoreInScore: false
};