import { FarmBottomMenu } from './ui/FarmBottomMenu';
import { FarmLayoutElement } from './FarmLayoutElement';
import { FarmUnits, getFarmFrameURL, getFarmSpriteChildDimensions, getFarmSpriteDimensions, getFarmSpriteURL, getNextType, getRegroupCoords } from './FarmTypes';
import { DisplayObject, Graphics, Sprite } from 'pixi.js';
import gsap from 'gsap';
import { FarmTopMenu } from './ui/FarmTopMenu';
import { GlowFilter } from '@pixi/filter-glow';
import { Game } from '../../pixi-puzzle-framework/PuzzleEngine/Game';

export class AnimateRegroup {
  private background: Graphics;
  private nextType: FarmUnits | undefined;
  private bottomMenu: FarmBottomMenu;
  private glowFilter: GlowFilter;
  private farmLayoutElement: FarmLayoutElement;
  private unit: FarmUnits;
  private desiredSpriteWidth = 200;
  private gameInstance: Game;

  public constructor(gameInstance: Game, unit: FarmUnits, el: FarmLayoutElement, bottomMenu: FarmBottomMenu) {
    this.gameInstance = gameInstance;
    this.background = new Graphics();
    this.background.alpha = 0;
    this.unit = unit;
    this.nextType = getNextType(unit);
    this.bottomMenu = bottomMenu;
    this.farmLayoutElement = el;
    // gsap.globalTimeline.timeScale(10);

    if (this.nextType) {
      const nextSpriteDimensions = getFarmSpriteDimensions(this.nextType);
      const childSize = getFarmSpriteChildDimensions(this.nextType);
      const ratio = this.farmLayoutElement.getEntityWidth() / childSize.x ;

      this.desiredSpriteWidth = nextSpriteDimensions.x * ratio;
    }

    this.glowFilter = new GlowFilter({
      distance: 50,
      outerStrength: 2,
      innerStrength: 0,
      color: 0xffffff,
      quality: 0.1 // low quality seems to make no visual difference for this animation but substantially improves performance
    });
  }

  public async animateRegroup(): Promise<void> {
    const frameURL = getFarmFrameURL(this.unit);
    const frameSprite = Sprite.from(frameURL);
    const nextType = getNextType(this.unit);
    const dimensions = getFarmSpriteDimensions(nextType!);
    const ratio = this.desiredSpriteWidth / dimensions.x;
    const calculatedHeight = dimensions.y * ratio;

    this.background.beginFill(0x000000, 0.5);
    this.background.drawRect(0, 0, 1280, 720);
    this.background.endFill();

    frameSprite.anchor.set(0);
    frameSprite.position.set(640 - (this.desiredSpriteWidth / 2), 360 - (calculatedHeight / 2));
    frameSprite.width = this.desiredSpriteWidth;
    frameSprite.height = calculatedHeight;

    this.bottomMenu.makeNumbers();

    frameSprite.filters = [this.glowFilter];
    this.farmLayoutElement.filters = [this.glowFilter];

    this.gameInstance.app.stage.addChild(this.background as DisplayObject);
    this.gameInstance.app.stage.addChild(frameSprite as DisplayObject);
    this.bottomMenu.parent.addChild(this.bottomMenu as DisplayObject); // bring to front

    this.gameInstance.app.stage.addChild(this.farmLayoutElement as DisplayObject); // bring to front

    const fadeTime = 1;
    await this.fadeBackground(fadeTime);

    // go to the bottom menu
    if (nextType) {
      await this.farmLayoutElement.animateRegroup(nextType, frameSprite, getRegroupCoords(this.unit));
      await this.fadeGlow(10, 5, 0.25);
      this.farmLayoutElement.deleteSrites();
      await this.animateToBottomMenu(nextType, frameSprite, this.bottomMenu);
    }

    await this.fadeBackground(0);
    this.background.destroy();
  }

  private async fadeBackground(value: number): Promise<void> {
    return new Promise<void>(resolve => {
      gsap.to(this.background, {duration: 1, alpha: value, onComplete: () => {
        resolve();
      }});
    });
  }

  private async fadeGlow(innerStrength: number, outerStrength: number, duration: number): Promise<void> {
    return new Promise<void>(resolve => {
      gsap.to(this.glowFilter, {duration, innerStrength, outerStrength, onComplete: () => {
        resolve();
      }});
    });
  }

  private async animateToBottomMenu(nextType: FarmUnits, frameSprite: Sprite, bottomMenu: FarmBottomMenu): Promise<void> {
    const nextSprite = Sprite.from(getFarmSpriteURL(nextType));
    const dimensions = getFarmSpriteDimensions(nextType);
    const ratio = this.desiredSpriteWidth / dimensions.x;
    const calculatedHeight = dimensions.y * ratio;

    nextSprite.filters = [this.glowFilter];
    nextSprite.anchor.set(0.5);
    nextSprite.width = this.desiredSpriteWidth;
    nextSprite.height = calculatedHeight;
    const bounds = nextSprite.getBounds();

    nextSprite.position.set(frameSprite.position.x + (bounds.width / 2), frameSprite.position.y + (bounds.height / 2));

    this.gameInstance.app.stage.addChild(nextSprite as DisplayObject);
    frameSprite.destroy();

    await this.fadeGlow(0, 2, 0.75);

    return new Promise<void>(resolve => {
      if (nextType) {
        const newCoords = bottomMenu.getCoordinates(nextType);
        const targetScale = bottomMenu.getScale(nextType);

        if (newCoords && targetScale) {
          nextSprite.setParent(this.gameInstance.app.stage);
          const curPos = {
            x: nextSprite.position.x,
            y: nextSprite.position.y,
            scaleX: nextSprite.scale.x,
            scaleY: nextSprite.scale.y};

          gsap.to(curPos, {duration: 1, onComplete: () => {
            nextSprite.destroy();
            bottomMenu.incrementUnit(nextType);
            resolve();
          }, onUpdate: () => {
            nextSprite.position.set(curPos.x, curPos.y);
            nextSprite.scale.set(curPos.scaleX, curPos.scaleY);
          },
          x: newCoords.x,
          y: newCoords.y,
          scaleX: targetScale.x,
          scaleY: targetScale.y
          });
        }
      }
    });
  }
}

export class FarmSuccessAnimation {
  public async animate(topMenu: FarmTopMenu, bottomMenu: FarmBottomMenu): Promise<void> {
    await this.animateTopMenu(topMenu);
    await bottomMenu.animateCorrect();
    topMenu.numberInput!.getElement().style.color = 'green';
  }

  private async animateTopMenu(topMenu: FarmTopMenu): Promise<void> {
    return new Promise(resolve => {
      const tl = gsap.timeline();
      tl.add(gsap.to(topMenu.textLeft, {opacity: 0, duration: 1}));
      tl.add(gsap.to(topMenu.textRight, {opacity: 0, duration: 1}), '<');
      tl.add(gsap.to(topMenu.menuDiv, {backgroundColor: '#00000000', duration: 1}), '<');
      tl.add(gsap.to(topMenu.numberInput!.getElement(), {y: 200, duration: 1, onComplete: () => {
        resolve();
      }}), '0.5');
    });
  }
}