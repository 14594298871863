import { MinecartTerm, MinecartTermType } from './MinecartTerm';

export class MinecartSlectionMenu {
  private onTermSelect: (t: MinecartTerm) => void;
  private terms: MinecartTerm[];
  private element: HTMLDivElement;
  private parent: HTMLElement;
  public clickEnabled = true;

  public constructor(element: HTMLDivElement, parent: HTMLElement, terms: MinecartTerm[], onTermSelect: (t: MinecartTerm) => void) {
    this.onTermSelect = onTermSelect;
    this.element = element;
    this.terms = terms;
    this.parent = parent;

    this.terms.forEach(t => t.onSelected = this.termSelected.bind(this));
    this.setVisible(false);
  }

  public setVisible(visible: boolean): void {
    this.element.style.display = visible ? '' : 'none';
  }

  public isVisible(): boolean {
    return this.element.style.display !== 'none';
  }

  public unselectAllTerms(): void {
    this.terms.forEach(term => {
      term.setSelected(false);
    });
  }

  public showElementWithValue(value: number, type: MinecartTermType): void {
    for(let i = 0; i < this.terms.length; i++) {
      const curTerm = this.terms[i];

      if (curTerm.inUse && curTerm.value === value && curTerm.type === type) {
        curTerm.inUse = false;
        curTerm.element.style.visibility = '';
        break;
      }
    }
  }

  public getTermWithValue(val: number, type: MinecartTermType): MinecartTerm | null {
    for(let i = 0; i < this.terms.length; i++) {
      if (this.terms[i].inUse && this.terms[i].value === val && this.terms[i].type === type) {
        return this.terms[i];
      }
    }

    return null;
  }

  public setPositionTarget(t: MinecartTerm): void {
    const rect = t.element.getBoundingClientRect();
    const parentRect = this.parent.getBoundingClientRect();
    this.element.style.top = '100px';
    this.parent.appendChild(this.element);
    const elRect = this.element.getBoundingClientRect();

    this.element.style.left = `${(rect.x - parentRect.x - elRect.width / 2) * 1/Number(this.parent.style.scale)}px`;
  }

  public reset(): void {
    this.terms.forEach(t => {
      t.setSelected(false);
      t.setAsError(false);
      t.inUse = false;
      t.element.style.visibility = '';
    });

    this.setVisible(false);
  }

  private termSelected(t: MinecartTerm) {
    this.unselectAllTerms();
    t.setSelected(true);

    if (this.onTermSelect) {
      this.onTermSelect(t);
    }

    t.inUse = true;
    t.element.style.visibility = 'hidden';
  }
}
