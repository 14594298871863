import { KHPoint } from '../../../pixi-puzzle-framework/PuzzleEngine/KHPoint';
import { FarmUnits } from '../FarmTypes';

export const enum FarmLayoutType {
  FREE,
  FRAME
}

export type ParsedFarmElement = {
  layoutType: FarmLayoutType;
  coordinates: KHPoint[];
  count: number;
  regroup: boolean;
  order: number;
  entityWidth: number;
  unit?: FarmUnits
}

const defaultFarmElement: ParsedFarmElement = {
  coordinates: [],
  count: 0,
  entityWidth: 0,
  layoutType: FarmLayoutType.FREE,
  order: 0,
  regroup: false
}

const farmTags = ['ones', 'tens', 'hundreds', 'thousands', 'ten-thousands', 'hundred-thousands', 'millions'];

export class FarmMarkupParser {
  public parseMarkup(element: HTMLElement): ParsedFarmElement[] {
    const parsedEls = [];
    const children = element.children;

    for (let i = 0; i < children.length; i++) {
      const child = children[i];

      if (farmTags.includes(child.tagName.toLowerCase())) {
        const parsedEl = this.parseElement(child as Element);
        parsedEl.unit = child.tagName.toLowerCase() as FarmUnits;
        parsedEls.push(parsedEl);
      }
    }

    return parsedEls;
  }

  private parseElement(element: Element): ParsedFarmElement {
    const newEl = {...defaultFarmElement};

    const layoutVal = element.getAttribute('layout');
    const countVal = element.getAttribute('count');
    const regroupVal = element.getAttribute('regroup');
    const orderVal = element.getAttribute('order');
    const entityWidthVal = element.getAttribute('entity-width');
    const coordsVal = element.getAttribute('coordinates');

    newEl.layoutType = layoutVal === 'frame' ? FarmLayoutType.FRAME : FarmLayoutType.FREE;
    newEl.count = countVal ? parseInt(countVal, 10) : 0;
    newEl.regroup = regroupVal === 'true';
    newEl.order = orderVal ? parseInt(orderVal, 10) : 0;
    newEl.entityWidth = entityWidthVal ? parseInt(entityWidthVal, 10) : 0;
    newEl.coordinates = this.parseCoordinates(coordsVal || '');

    return newEl;
  }

  private parseCoordinates(coords: string): KHPoint[] {
    const returnCoords: KHPoint[] = [];

    const trimmedString = coords.trim().replace(']', '').replace('[', '').trim();
    const splitCoords = trimmedString.split('),');

    for(let i = 0; i < splitCoords.length; i++) {
      const curCoords = splitCoords[i].replace('(', '').replace(')', '');
      const splitCurCoords = curCoords.split(',');

      if (splitCurCoords.length >= 2) {
        returnCoords.push({x: parseFloat(splitCurCoords[0]), y: parseFloat(splitCurCoords[1])});
      }
    }

    return returnCoords;
  }

}
