import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TEMPLATE_PREFIX } from '../../../environments/locale-config';

@Component({
  selector: 'kh-algebra-tile-add',
  templateUrl: TEMPLATE_PREFIX + 'algebra-tile-add.component.html',
  styleUrls: ['./algebra-tile-add.component.less']
})
export class AlgebraTileAddComponent {
  public allTiles: string[] = [];

  @Output() public add = new EventEmitter<string>();

  constructor(private readonly bsModalRef: BsModalRef) {
  }

  public addTile(tile: string): void {
    this.add.emit(tile);
    this.bsModalRef.hide();
  };

  public cancel(): void {
    this.bsModalRef.hide();
  };

}
