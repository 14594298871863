import { Injectable, InjectionToken } from '@angular/core';
import { fabric } from 'fabric';
import { FabricExtendedCanvas } from '../question/fabric/angular-fabric';

export const khFabricCanvas = new InjectionToken('khFabricCanvas');

@Injectable()
export class FabricCanvas {
  mainTouchId?: string;
  canvasId: string = '';
  element?: HTMLCanvasElement;
  canvas?: fabric.Canvas;

  constructor() {
  }

  public createCanvas(canvas: HTMLCanvasElement): FabricExtendedCanvas {
    this.element = canvas;
    this.canvasId = 'fabric-canvas-' + this.createId();
    this.element.setAttribute('id', this.canvasId);
    this.canvas = new fabric.Canvas(this.canvasId, {
      allowTouchScrolling: true,
      // enablePointerEvents: false
    });

    return this.canvas as FabricExtendedCanvas;
  }

  public getCanvas(): fabric.Canvas | undefined {
    return this.canvas;
  }

  public getCanvasId(): string {
    return this.canvasId;
  }

  private createId(): number {
    return Math.floor(Math.random() * 10000);
  }
}
