import { Component, Input, OnInit } from '@angular/core';
import { DynamicContentChild, OnDynamicData } from 'ngx-dynamic-hooks';
import { BackwardComponent } from '../backward/backward.component';

import { ForwardComponent } from '../forward/forward.component';
import {fabric} from 'fabric';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { TEMPLATE_PREFIX } from '../../../../environments/locale-config';
import { WindowsService } from '../../../../services/windows.service';
import { ManipulativeChildObject } from '../../../models/manipulative-child-object.model';

@UntilDestroy()
@Component({
  selector: 'window-controls',
  styleUrls: ['./window-controls.component.less'],
  templateUrl: TEMPLATE_PREFIX + 'window-controls.component.html'
})
export class WindowControlsComponent extends ManipulativeChildObject implements OnInit {

  public order = 0;
  public allChildrenLoaded$ = new BehaviorSubject(false)

  private manipulativeChildren: ManipulativeChildObject[] = [];

  @Input() public id = '';

  private options: {order: number, id: string}[] = [];

  public constructor(private readonly windowsService: WindowsService) {
    super();
  }

  public onDynamicMount(data: OnDynamicData): void {
    super.onDynamicMount(data);
    const contentChildren: DynamicContentChild[] = data.contentChildren ?? [];
    this.manipulativeChildren = contentChildren
      .filter(c => c.componentRef.instance instanceof ManipulativeChildObject)
      .map(c => c.componentRef.instance) as ManipulativeChildObject[];

    combineLatest(this.manipulativeChildren.map(c => c.isLoaded)).pipe(
      map(loading => loading.every(l => l)),
      distinctUntilChanged(),
      tap (loading => {
        if (loading) {
          this.allChildrenLoaded$.next(true)
        }
      }),
      untilDestroyed(this))
      .subscribe()
  }

  public ngOnInit(): void {
    this.windowsService.getControllerSettingsById(this.id).pipe(untilDestroyed(this))
      .subscribe(value => {this.options = value;})

    this.windowsService.getControllerSettingsById(this.id).pipe(untilDestroyed(this))
      .subscribe(value => {
        this.options = value;
      })
  }

  public get isLoaded(): Observable<boolean> {
    return this.allChildrenLoaded$;
  }


  private changeOption(step: number): void {
    const currentCount = this.windowsService.getControllerById(this.id).value;
    const currentOptions = this.windowsService.getControllerSettingsById(this.id).value
      .map(val => Number(val.order)).sort((a, b) => a - b);
    const currentIdx = currentOptions.indexOf(currentCount);
    const next = Math.max(0, Math.min(currentOptions.length - 1, currentIdx + step));
    this.windowsService.getControllerById(this.id).next(currentOptions[next]);
  }

  public get fabricObject(): fabric.Object[] {
    const objects = this.manipulativeChildren.map(c => c.fabricObject).reduce((acc, i) => [...acc, ...i], []);
    this.manipulativeChildren.forEach(child => {
      if (child instanceof BackwardComponent || child instanceof ForwardComponent) {
        child.onChange.pipe(
          tap(val => this.changeOption(val)),
          untilDestroyed(this)
        ).subscribe();
      }
    })

    return objects;
  }
}
