import {Component,
  Input,
  OnInit
} from '@angular/core';
import { OnDynamicData } from 'ngx-dynamic-hooks';

import { BehaviorSubject, combineLatest } from 'rxjs';
import { PaneComponent } from '../pane/pane.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { WindowsService } from '../../../../services/windows.service';
import { FabricWindowPane } from '../../../../utils/fabric-utils/fabric-window-pane';
import { ManipulativeChildObject } from '../../../models/manipulative-child-object.model';

@UntilDestroy()
@Component({
  selector: 'window',
  styleUrls: ['./window.component.less'],
  templateUrl: './window.component.html'
})

export class WindowComponent extends ManipulativeChildObject implements OnInit {

  @Input() public frame = '';
  @Input() public id = '';
  @Input() public controllers = '';
  @Input() public startId = 0;
  @Input() public ignoreInScore = false;

  private paneComponents: PaneComponent[] = []
  private fabricPane = new BehaviorSubject<FabricWindowPane[]>([]);
  public windowFrame = [0, 0, 0, 0];
  public paneOrders: {order: number, id: string}[] = [];
  public order = 0;
  public isLoaded = new BehaviorSubject(false);
  public reportMode = false;


  public constructor(private readonly windowsService: WindowsService) {
    super();
  }

  public ngOnInit(): void {
    this.id = this.id.replace(/[']/g, '');

    this.windowFrame = this.frame.replace(/[']/g, '').split(',')
      .map(Number);
    const controller = this.windowsService.getControllerById(this.controllers);
    combineLatest([this.fabricPane, controller]).pipe(untilDestroyed(this))
      .subscribe(([panes, value]) => {
        panes.forEach(element => {
          element.set('visible', (String(element.order) === String(value)));
          if (element.visible) {
            this.windowsService.setWindowState(this.id, element.id);
            if (!this.ignoreInScore) {
              this.windowsService.setWindowAnswer(this.id, element.id);
            }
          }
          element.canvas?.renderAll();
        });
      })

    controller.next(this.startId);

    this.windowsService.windows.pipe(untilDestroyed(this)).subscribe(answer => {
      const paneId = answer[this.id.replace(/[']/g, '')];
      if (paneId) {
        this.fabricPane.pipe(untilDestroyed(this)).subscribe(panes => {
          panes.forEach(element => {
            if (element.id === paneId) {
              controller.next(element.order);
            }
          });
        })
      }
    });
  }

  public override onDynamicMount(data: OnDynamicData): void {
    const { contentChildren = [] } = data;
    this.paneComponents = contentChildren
      .filter(c => c.componentRef.instance instanceof PaneComponent)
      .map(c => c.componentRef.instance)

    this.paneComponents.forEach(p => p.setFrame(this.windowFrame))

    combineLatest(this.paneComponents.map(p => p.isLoaded)).pipe(
      map(loading => loading.every(l => l)),
      distinctUntilChanged(),
      tap (loaded => {
        if (loaded) {
          this.paneOrders = this.paneComponents.map(p => p.getOrders()).sort();
          this.windowsService.getControllerSettingsById(this.controllers).next(this.paneOrders);
          this.isLoaded.next(loaded);
        }
      }),
      untilDestroyed(this))
      .subscribe()
  }

  public get fabricObject(): FabricWindowPane[] {
    const panes = this.paneComponents.reduce((acc, {fabricObject}) => [...acc, ...fabricObject], [] as FabricWindowPane[])
    this.fabricPane.next(panes)

    return panes;
  }
}
