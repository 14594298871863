import { Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fabric } from 'fabric';
import { OnDynamicData } from 'ngx-dynamic-hooks';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FabricWindowControls } from '../../../../utils/fabric-utils/fabric-window-controls';
import { ManipulativeChildObject } from '../../../models/manipulative-child-object.model';


@UntilDestroy()
@Component({
  selector: 'backward',
  template: '',
  styleUrls: []
})
export class BackwardComponent extends ManipulativeChildObject {
  public isLoaded = new BehaviorSubject(false);
  private control!: FabricWindowControls;

  public order = 0;

  @Input() public frame = '';
  @Input() public img = '';

  public onChange = new Subject<number>();

  public click(): void {
    this.onChange.next(-1);
  }

  public override onDynamicMount(data: OnDynamicData): void {
    super.onDynamicMount(data);
    this.control = new FabricWindowControls({
      frame: this.frame.replace(/'/g, '').split(',')
        .map(Number),
      img: this.img.replace(/'/g, '')
    });

    this.control.on('mouseup', () => this.click());

    this.control.loaded$.pipe(
      tap(loaded => this.isLoaded.next(loaded)),
      untilDestroyed(this)
    ).subscribe()
  }

  public get fabricObject(): fabric.Object[] {
    return [this.control];
  }

}
