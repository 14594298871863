
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TEMPLATE_PREFIX } from '../../../environments/locale-config';
import { ChangeDetectionStrategy, Output, Component, EventEmitter } from '@angular/core';
import { FractionStrip } from '../../components/manipulatives/manip-fraction-strips/fraction-strip';
import { FractionsList } from '../../constants/fractions-list';

@Component({
  selector: 'kh-fraction-strip-add',
  templateUrl: TEMPLATE_PREFIX + 'fraction-strip-add.component.html',
  styleUrls: ['./fraction-strip-add.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FractionStripAddComponent {
  public fractions: FractionStrip[] = FractionsList;

  @Output() public selected = new EventEmitter<FractionStrip>();

  constructor(private readonly bsModalRef: BsModalRef) {
  }

  public selectFractionStrip(fraction: number, colour: string): void {
    this.selected.emit({fraction, colour});
    this.bsModalRef.hide();
  };

  public cancel(): void {
    this.bsModalRef.hide();
  };

  public getFractionsSet(fraction: number): string[] {
    return fraction === 1 ?
      ['1 (Whole)'] :
      [...Array(fraction).keys()].map(() => `1/${fraction}`);
  }
}
