import { AnimateRegroup, FarmSuccessAnimation } from './FarmAnimations';
import { Assets, DisplayObject, Text } from 'pixi.js';
import { FarmBottomMenu } from './ui/FarmBottomMenu';
import { FarmLayoutElement } from './FarmLayoutElement';
import { FarmTopMenu } from './ui/FarmTopMenu';
import { FarmUnitData, FarmUnits, getFarmFrameURL, getFarmSpriteDimensions, getFarmSpriteURL, getNextType, getUnitValue } from './FarmTypes';
import { ParsedFarmElement } from './parsers/FarmMarkupParser';
import { Game } from '../../pixi-puzzle-framework/PuzzleEngine/Game';
import { GameEntity } from '../../pixi-puzzle-framework/PuzzleEngine/GameEntity';
import { loadSvg } from '../../pixi-puzzle-framework/PuzzleEngine/loaders/SVGLoader';


export type FarmPuzzleConfig = {
  parent: HTMLDivElement;
  farmData: ParsedFarmElement[];
  overlayDiv?: HTMLDivElement;
  onInputChange?: (value: number) => void;
  onAnswerComplete?: (correct: boolean) => void;
}

const urlPath = 'puzzles/farm/';
export class FarmScene extends GameEntity {
  private doneLoading = false;
  private config: FarmPuzzleConfig;
  private readonly screenWidth = 1280;
  private readonly screenHeight = 720;
  private layoutElements: FarmLayoutElement[] = [];
  private bottomMenu: FarmBottomMenu | null = null;
  private topMenu: FarmTopMenu;

  public constructor(config: FarmPuzzleConfig) {
    super();
    this.config = config;

    this.gameInstance = new Game({
      antialias: true,
      backgroundColor: 0x21C060,
      height: this.screenHeight,
      width: this.screenWidth
    }, config.parent);

    this.gameInstance.addEntity(this, true);
  }

  public update(deltaTimeSeconds: number): void {
    // no update
  }

  public start(): void {
    // no start
    const text = new Text('Loading...', { fill: 0xFFFFFF, fontSize: 24 });
    text.anchor.set(0.5);
    text.position.set(this.screenWidth / 2, this.screenHeight / 2);

    this.gameInstance.app.stage.addChild(text as DisplayObject);
    this.loadAssets().then(() => {
      this.doneLoad();
      text.destroy();
    });
  }

  public restart(): void {
    for (let i = 0; i < this.layoutElements.length; i++) {
      this.layoutElements[i].destroy();
    }

    this.layoutElements = [];
    this.bottomMenu?.destroy();
    this.topMenu.menuDiv.remove();
    this.doneLoad();
  }

  public async animate(): Promise<void> {
    if (this.topMenu) {
      this.topMenu.disableInput();
      this.bottomMenu?.setPlayerAnswer(this.topMenu.getPlayerAnswer());
    }

    for (let i = 0; i < this.layoutElements.length; i++) {
      const el = this.layoutElements[i];

      if (this.bottomMenu) {
        if (el.getData().regroup) {
          const regroup = new AnimateRegroup(this.gameInstance, el.getUnit(), el, this.bottomMenu);
          await regroup.animateRegroup();
        } else {
          const coords = this.bottomMenu.getCoordinates(el.getUnit());
          if (coords) {
            this.bottomMenu.makeNumbers();
            await el.animateToBottomMenu(coords, this.bottomMenu);
          }
        }

      }
    }

    let isCorrect = false;

    if (this.bottomMenu) {
      isCorrect = this.bottomMenu.validateAll();
      
      if (isCorrect === false) {
        await this.bottomMenu.animateIncorrect();
      } else {
        new FarmSuccessAnimation().animate(this.topMenu, this.bottomMenu);
      }
    }

    if (this.config.onAnswerComplete) {
      this.config.onAnswerComplete(isCorrect);
    }
  }

  public destroyInstance(): void {
    this.destroy();
    this.gameInstance.destroy();
  }

  private doneLoad(): void {
    this.doneLoading = true;
    this.drawScene();
    this.drawUI();
  }

  private async loadAssets(): Promise<void> {
    const promises: Promise<any>[] = [];
    const urlKeys = Object.keys(FarmUnitData);

    for (let i = 0; i < urlKeys.length; i++) {      
      const dimensions = getFarmSpriteDimensions(urlKeys[i] as FarmUnits);
      const nextType = getNextType(urlKeys[i] as FarmUnits);
      const frameUrl = getFarmFrameURL(urlKeys[i] as FarmUnits);
      const spriteUrl = getFarmSpriteURL(urlKeys[i] as FarmUnits);
      
      if (nextType) {
        const nextDimensions = getFarmSpriteDimensions(nextType);
        if(frameUrl.endsWith('.svg')) {
          promises.push(loadSvg(frameUrl, nextDimensions.x, nextDimensions.y, 5, true));
        } else {
          promises.push(Assets.load([frameUrl]));
        }
        
      }

      if (spriteUrl.endsWith('.svg')) {
        const p = loadSvg(spriteUrl, dimensions.x, dimensions.y, 5, true);
        promises.push(p);
      } else {
        promises.push(Assets.load([spriteUrl]));
      }

    }

    await Promise.all(promises);
  }

  private drawUI(): void {
    this.bottomMenu = new FarmBottomMenu(this.getBottomMenuItems(), this.calculateCorrectAnswer());
    this.gameInstance.addEntity(this.bottomMenu, true);

    if (this.config.overlayDiv) {
      this.topMenu = new FarmTopMenu(this.config.overlayDiv, this.config.onInputChange);
    }
  }

  private drawScene(): void {
    this.config.farmData.forEach(element => { this.drawElement(element); });
  }

  private calculateCorrectAnswer(): number {
    let result = 0;

    this.config.farmData.forEach(element => { 
      if (element.unit) {
        result += element.count * getUnitValue(element.unit);
      }
    });

    return result;
  }

  private drawElement(element: ParsedFarmElement): void {
    const el = new FarmLayoutElement({ data: element });
    this.layoutElements.push(el);
    this.gameInstance.addEntity(el, false);
  }

  private getBottomMenuItems(): FarmUnits[] {
    const items: Set<FarmUnits> = new Set();

    this.layoutElements.forEach(el => {
      items.add(el.getUnit());

      const nextType = getNextType(el.getUnit());

      if (nextType) {
        items.add(nextType);
      }
    });

    return new Array(...items);
  }
  public isDoneLoading(): boolean {
    return this.doneLoading;
  }
}
