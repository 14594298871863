import { Pipe, PipeTransform } from '@angular/core';
import { isNumber, isString } from 'lodash-es';

@Pipe({
  name: 'letter'
})
export class LetterPipe implements PipeTransform {

  transform(value: number | string): string {
  	if (!isString(value) && !isNumber(value)) {
			return value;
	  }

	  const val = isNumber(value) ? value.toString() : value;
    return String.fromCharCode(65 + parseInt(val, 10));
  }

}
