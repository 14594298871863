/* eslint-disable max-len */
import { FarmUnits, getFarmSpriteDimensions, getFarmSpriteURL, getUnitValue } from '../FarmTypes';
import { Container, DisplayObject, Graphics, MIPMAP_MODES, Sprite, Text } from 'pixi.js';
import gsap from 'gsap';
import { GameEntity } from '../../../pixi-puzzle-framework/PuzzleEngine/GameEntity';
import { KHPoint } from '../../../pixi-puzzle-framework/PuzzleEngine/KHPoint';

type TextContainer = {
  text: Text;
  container: Container;
  background: Graphics;
}
export class FarmBottomMenu extends GameEntity {
  private menuWidth = 600;
  private menuHeight = 100;
  private padding = 10;
  private spacing = 10;
  private spriteMap: Map<FarmUnits, Sprite> = new Map();
  private items: FarmUnits[];
  private unitTexts: TextContainer[] = [];
  private hasNumbers = false;
  private correctAnswer: number;
  private playerAnswer: number = 0;
  private desiredSpriteWidth = 80;
  private textContainerWidth = 40;
  private textContainerHeight = 40;

  public constructor(items: FarmUnits[], correctAnswer: number) {
    super();

    this.items = items;
    this.correctAnswer = correctAnswer;
  }

  public update(deltaTimeSeconds: number): void {
    // nothing
  }

  public start(): void {
    this.determineSize();

    this.createMenu();
    this.populateItems();
    this.position.set((1280 - this.menuWidth) / 2, 600);
  }
  
  public setPlayerAnswer(answer: number): void {
    this.playerAnswer = answer;
  }

  public isCorrect(): boolean {
    return this.playerAnswer === this.correctAnswer;
  }

  private determineSize(): void {
    const numSprites = this.items.length;
    const totalPadding = this.padding * 2;

    this.menuWidth = (this.desiredSpriteWidth * numSprites) + totalPadding + (this.spacing * (numSprites - 1));
  }

  public createMenu(): void {
    this.makeRoundedBackgorund();
  }

  private makeRoundedBackgorund(): void {
    const g = new Graphics();
    g.beginFill(0x110000, 0.3);
    g.drawRoundedRect(0, 0, this.menuWidth, this.menuHeight, 30);
    g.endFill();

    this.addChild(g as DisplayObject);
  }

  private isUnitAnswerCorrect(unit: FarmUnits): boolean {
    const unitValue = getUnitValue(unit);
    
    const playerAnswerValue = Math.floor(this.playerAnswer / unitValue) % 10;
    const correctAnswerValue = Math.floor(this.correctAnswer / unitValue) % 10;

    // if the player entered 10 but the real answer is 2010, we want the leading "20" to be false and not true on the 0 at the end
    if (this.playerAnswer.toString().length < unitValue.toString().length) {
      return false;
    }

    return playerAnswerValue === correctAnswerValue;    
  }

  private populateItems(): void {
    const items = this.items;

    this.sortItems();

    const totalPadding = this.padding * 2;
    const totalSpacing = this.spacing * (items.length - 1);
    const spriteWidth = (this.menuWidth - totalPadding - totalSpacing) / items.length;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const url = getFarmSpriteURL(item);
      const dimensions = getFarmSpriteDimensions(item);
      const s = Sprite.from(url);
      const calculatedHeight = dimensions.y * (spriteWidth / dimensions.x);
      s.texture.baseTexture.mipmap = MIPMAP_MODES.ON;
      s.width = spriteWidth;
      s.height = calculatedHeight;

      
      // if the height would overrun then fix it by scaling again to fit the height
      if (s.height > this.menuHeight - this.padding * 2) {
        s.height = this.menuHeight - this.padding * 2;
        s.width = dimensions.x * (s.height / dimensions.y);
      }

      const heightOffset = (this.menuHeight - s.height) / 2;
      s.position.set(this.padding + (i * spriteWidth) + (this.spacing * i) + ((spriteWidth - s.width) / 2), heightOffset);

      this.addChild(s as DisplayObject);
      this.spriteMap.set(item, s);
    }
  }

  public getCoordinates(unit: FarmUnits): KHPoint | null {
    const sprite = this.spriteMap.get(unit);

    if (sprite) {
      const posx = this.position.x + sprite.position.x + (sprite.width / 2);
      const posy = this.position.y + sprite.position.y + (sprite.height / 2);
      return {x: posx, y: posy};
    }

    return null;
  }

  public getScale(unit: FarmUnits): KHPoint | null {
    const sprite = this.spriteMap.get(unit);

    if (sprite) {
      return {x: sprite.scale.x, y: sprite.scale.y};
    }

    return null;
  }

  /**
   * During the final animation numbers pop up above the sprites
   */
  public makeNumbers(): void {
    if (this.hasNumbers) {
      return;
    }

    const items = this.items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const sprite = this.spriteMap.get(item);

      if (sprite) {
        const text = new Text('0', {fill: 0xFFFFFF, fontSize: 30});
        const container = new Container();

        const containerBackground = new Graphics();
        containerBackground.beginFill(0x000000, 0.3);
        containerBackground.drawRoundedRect(0, 0, this.textContainerWidth, this.textContainerHeight, 10);
        containerBackground.endFill();

        container.addChild(containerBackground as DisplayObject);
        container.addChild(text as DisplayObject);

        container.position.set((sprite.position.x) + (sprite.width / 2) - (this.textContainerWidth / 2), -this.textContainerHeight - 10);

        text.anchor.set(0.5);
        text.position.set((this.textContainerWidth / 2), (this.textContainerHeight / 2));

        this.addChild(container as DisplayObject);
        this.unitTexts.push({
          text,
          container,
          background: containerBackground
        });
      }
    }

    this.hasNumbers = true;
  }

  public validateAll(): boolean {
    let allCorrect = true;

    for (let i = 0; i < this.items.length; i++) {
      const validResult = this.validateAnswerForUnit(this.items[i]);
      allCorrect = allCorrect && validResult;
    }

    return allCorrect;
  }

  public async animateIncorrect(): Promise<void> {
    const promises = [];

    for (let i = 0; i < this.items.length; i++) {
      const isCorrect = this.isUnitAnswerCorrect(this.items[i]);
      if (!isCorrect) {
        const animationPromise = new Promise<void>((resolve) => {
            const unit = this.items[i];
            const text = this.getTextForUnit(unit);
            
            if (text) {
              gsap.to(text.container, {y: text.container.y - this.textContainerHeight, duration: 0.5, onComplete: () => {
                resolve();
              }});
            } 
        });

        promises.push(animationPromise);
      }
    }

    await Promise.all(promises);
  }

  public async animateCorrect(): Promise<void> {
    const promises = [];

    for (let i = 0; i < this.items.length; i++) {
      const animationPromise = new Promise<void>((resolve) => {
      const unit = this.items[i];
      const text = this.getTextForUnit(unit);
      
      if (text) {
        gsap.to(text.container, {x: 75, y: -380, duration: 0.5, onComplete: () => {
          resolve();
          text.container.destroy();
        }});
      }});

      promises.push(animationPromise);
    }

    await Promise.all(promises);
  }

  public validateAnswerForUnit(unit: FarmUnits): boolean {
    const isCorrect = this.isUnitAnswerCorrect(unit);

    if (!isCorrect) {
      const text = this.getTextForUnit(unit);

      if (text) {
        text.background.beginFill(0xFF0000, 1);
        text.background.drawRoundedRect(0, 0, this.textContainerWidth, this.textContainerHeight, 10);
        text.background.endFill();
      }
    }

    return isCorrect;
  }

  private getTextForUnit(unit: FarmUnits): TextContainer | null {
    for(let i = 0; i < this.items.length; i++) {
      if (this.items[i] === unit) {
        return this.unitTexts[i];
      }
    }
    return null;
  }

  public incrementUnit(unit: FarmUnits): void {
    for(let i = 0; i < this.items.length; i++) {
      if (this.items[i] === unit) {
        const text = this.unitTexts[i].text;
        const curVal = parseInt(text.text, 10);
        text.text = (curVal + 1).toString();

        return;
      }
    }
  }

  private sortItems(): void {
    const items = this.items;
    const sortedItems = items.sort((a, b) => getUnitValue(b) - getUnitValue(a));
    this.items = sortedItems;
  }
}
