<form [ngStyle]="{'font-family' : font}" novalidate name="input-keyboard">
    <table class="input-int-keypad-table">
        <tr class="input-int-keypad-table__keyboard">
            <td *ngFor="let number of numbers.slice(0, 5)">
                <button [ngClass]="{'input-int-keypad-table__keyboard-field': number.broken}" (click)="setValue(number.value)">
                    {{number.broken ? '' : number.value}}
                </button>
            </td>
            <td>
                <button (click)="done()" class="input-int-keypad-table__done-btn">
                    <fa-icon [icon]="['fas','check']"></fa-icon>
                </button>
            </td>
        </tr>
        <tr class="input-int-keypad-table__keyboard">
            <td *ngFor="let number of numbers.slice(5, 10)">
                <button [ngClass]="{'input-int-keypad-table__keyboard-field': number.broken}"
                        (click)="setValue(number.value)">
                    {{number.broken ? '' : number.value}}
                </button>
            </td>
            <td>
                <button (click)="cancel()" class="input-int-keypad-table__cancel-btn">
                    <fa-icon class="backspace-btn-icon" [icon]="['fas','long-arrow-alt-left']"></fa-icon>
                </button>
            </td>
        </tr>
    </table>
</form>