import { gsap } from 'gsap';

const svgns = 'http://www.w3.org/2000/svg';

export class ConfettiPiece {
    private square: SVGRectElement;

    /**
     * Create a confetti piece for SVG puzzles
     * @param element The svg element to add the confetti to
     * @param color The color. If no colour specified it will just be a random colour
     */
    public constructor(element: SVGElement, color?: string) {
        this.square = document.createElementNS(svgns, 'rect') as SVGRectElement;
        this.setAttributes(this.square, {
            width: 20,
            height: 20,
            fill: color ? color : this.randomHexColor()
        });

        element.appendChild(this.square);

        const timeline = gsap.timeline();

        timeline.to(this.square, {scaleX: -1, duration: this.randomBetween(0.3, 1), ease: "none", repeat: -1, yoyo: true}, 0);
        timeline.to(this.square, {rotation: 360, transformOrigin: 'left', duration: this.randomBetween(0.3, 1), ease: "none", repeat: -1}, 0);

        timeline.fromTo(this.square, {x: this.randomBetween(0, 1280), y: this.randomBetween(-10, -50)}, {x: this.randomBetween(0, 1280), y: 750, duration: this.randomBetween(2, 5), ease: "none", onComplete: () => {
            timeline.kill();
            this.square.remove();
        }}, 0);
    }

    /**
     * Drop 100 confetti peices with the given colour array. If the array is empty it will use random colours instead.
     * The confetti cleans itself up from the DOM when the animation is complete or interrupted.
     * @param svgElement The SVG element to add the confetti to.
     * @param colorArray An array of colours to randomly pick from. If empty, will use random colours instead.
     */
    public static dropConfetti(svgElement: SVGElement, colorArray: string[] = []) {
        if (colorArray.length > 0) {
            for (let i = 0; i < 100; i++) {
                const randomColor = colorArray[Math.floor(Math.random() * colorArray.length)];
                new ConfettiPiece(svgElement, randomColor);
            }
        } else {
            for (let i = 0; i < 100; i++) {
                new ConfettiPiece(svgElement);
            }
        }
    }

    private setAttributes(element: SVGRectElement, attributes: any) {
        Object.keys(attributes).forEach(attr => {
            element.setAttribute(attr, attributes[attr]);
        });
    }

    private randomHexColor(): string {
        return `#${Math.floor(Math.random()*16777215).toString(16)}`;
    }

    private randomBetween(min: number, max: number): number { // min and max included 
        return Math.random() * (max - min + 1) + min;
    }
}
