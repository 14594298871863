import { fabric } from 'fabric';
import { BehaviorSubject } from 'rxjs';

export interface IInputControlOpts {
  img?: string;
  frame: number[];
}


export class FabricWindowControls extends fabric.Image {

  public loaded$ = new BehaviorSubject(false);

  public constructor(
    options: IInputControlOpts
  ) {
    super();
    const [left, top, width, height] = options.frame;
    this.setSrc(options.img ?? '', () => {
      (this as FabricWindowControls).set({
        hasBorders: false,
        hasControls: false,
        height: this.height,
        hoverCursor: 'default',
        left: left + (width - (this.width ?? 0)) / 2,
        lockMovementX: true,
        lockMovementY: true,
        top: top + (height - (this.height ?? 0)) / 2,
        width: this.width
      })
      if(this.width && this.height){
        this.scaleY = this.scaleX = Math.min(width / this.width, height / this.height);
      }
      this.loaded$.next(true);
    })
  }
}
