import { Injectable } from '@angular/core';
import { fabric } from 'fabric';
import { IBlendColorFilter } from 'fabric/fabric-impl';
import { BehaviorSubject } from 'rxjs';
import { FabricCanvas } from '../../services/fabric-canvas.service';
import { FabricDirtyStatusService } from '../../services/fabric-dirty-status.service';
import { FabricExtendedCanvas, ExtendedObject, ExtendedImage, FabricExtended } from './angular-fabric';

@Injectable()
export class AngularFabricService {
  public canvas!: FabricExtendedCanvas;
  public options = {
    canvasBackgroundColor: '#ffffff',
    backgroundImage: undefined as (string | undefined),
    canvasWidth: 300,
    canvasHeight: 300,
    canvasOriginalHeight: 300,
    canvasOriginalWidth: 300,
    maxContinuousRenderLoops: 25,
    continuousRenderTimeDelay: 500,
    editable: true,
    JSONExportProperties: [] as any[],
    loading: false,
    dirty: false,
    initialized: false,
    userHasClickedCanvas: false,
    downloadMultipler: 2,
    imageDefaults: {},
    textDefaults: {},
    shapeDefaults: {},
    windowDefaults: {
      transparentCorners: false,
      rotatingPointOffset: 25,
      padding: 0
    },
    canvasDefaults: {
      selection: false
    },
    imageOptions: {} as { [key in keyof fabric.Image]: any }
  };

  public renderCount = 0;
  public canvasScale = 1;
  public canvasId = '';
  public canvasOriginalWidth = 0;
  public canvasOriginalHeight = 0;
  public canvasWidth = 0;
  public canvasHeight = 0;
  public userHasClickedCanvas = false;
  public loading = false;
  public initialized = false;
  public selectedObject = new BehaviorSubject<ExtendedObject | undefined>(undefined);
  public editable = false;
  public downloadMultipler?: number;
  // Prevent infinite rendering loop
  public continuousRenderCounter = 0;
  public continuousRenderHandle?: number;

  constructor(
    private readonly dirtyService: FabricDirtyStatusService,
    private readonly fabricCanvas: FabricCanvas,
  ) {
  }

  public initialize(options: Record<string, any>, canvas: HTMLCanvasElement, json?: string): void {
    this.options = {...this.options, ...options};
    this.canvas = this.fabricCanvas.createCanvas(canvas);
    this.canvas.clear();

    // For easily accessing the json
    let JSONObject: any;
    if (json) {
      JSONObject = JSON.parse(json);
      this.loadJSON(json);
    }

    JSONObject = JSONObject || {};

    JSONObject.background = JSONObject.background || '#ffffff';
    this.setCanvasBackgroundColor(JSONObject.background);
    this.setCanvasBackgroundImage(JSONObject.bgDefaultImage);

    // Set the size of the canvas
    JSONObject.width = JSONObject.width || 300;
    this.canvasOriginalWidth = JSONObject.width;

    JSONObject.height = JSONObject.height || 300;
    this.canvasOriginalHeight = JSONObject.height;

    this.setCanvasSize(this.canvasOriginalWidth, this.canvasOriginalHeight);

    this.render();
    this.setDirty(false);
    this.setInitalized(true);

    this.setCanvasDefaults();
    this.setWindowDefaults();
    this.startCanvasListeners();
    this.startContinuousRendering();
    //TODO: connect Fabric Dirty
    //FabricDirtyStatus.startListening();
  }

  public getCanvasId(): string {
    return this.fabricCanvas.getCanvasId();
  }


  public render(): void {
    const objects = this.canvas?.getObjects() || [];
    for (const obj of objects) {
      obj.setCoords();
    }

    this.canvas?.calcOffset();
    this.canvas?.renderAll();
    this.renderCount++;
  }

  public setCanvas(newCanvas: fabric.Canvas): void {
    this.canvas = newCanvas as FabricExtendedCanvas;
    this.canvas.selection = this.options.canvasDefaults.selection;
  }

  public setTextDefaults(textDefaults: any): void {
    this.options.textDefaults = textDefaults;
  }

  public setJSONExportProperties(JSONExportProperties: any[]): void {
    this.options.JSONExportProperties = JSONExportProperties;
  }

  public setCanvasBackgroundColor(color: string): void {
    this.options.canvasBackgroundColor = color;
    this.canvas?.setBackgroundColor(color, () => {
    });
    this.render();
  }

  // Canvas

  public setCanvasBackgroundImage(image: string): void {
    this.options.backgroundImage = image;
    this.canvas?.setBackgroundImage(image, () => {
    });
    this.render();
  }

  public setCanvasWidth(width: number): void {
    this.options.canvasWidth = width;
    this.canvas?.setWidth(width);
    this.render();
  }

  public setCanvasHeight(height: number): void {
    this.options.canvasHeight = height;
    this.canvas?.setHeight(height);
    this.render();
  }

  public setCanvasSize(width: number, height: number): void {
    this.stopContinuousRendering();
    const initialCanvasScale = this.canvasScale;
    this.resetZoom();

    this.canvasWidth = width;
    this.canvasOriginalWidth = width;
    this.canvas.originalWidth = width;
    this.canvas.setWidth(width);

    this.canvasHeight = height;
    this.canvasOriginalHeight = height;
    this.canvas.originalHeight = height;
    this.canvas.setHeight(height);

    this.canvasScale = initialCanvasScale;
    this.render();
    this.setZoom();
  }

  public deactivateAll(): void {
    this.deselectActiveObject();
    this.render();
  }

  public clearCanvas(): void {
    this.canvas.clear();
    this.canvas.setBackgroundColor('#ffffff', () => {
    });
    this.render();
  }


  public addObjectToCanvas(object: ExtendedObject): void {
    object.originalScaleX = object.scaleX;
    object.originalScaleY = object.scaleY;
    object.originalLeft = object.left;
    object.originalTop = object.top;
    object.originX = 'center';
    object.originY = 'center';

    this.canvas.add(object);
    this.setObjectZoom(object);
    this.canvas.setActiveObject(object);
    object.bringToFront();
    this.center();
    this.render();
  }


  public addImage(imageURL: string, objectAdded: (obj: fabric.Image) => void): fabric.Image {
    return fabric.Image.fromURL(imageURL, (obj: fabric.Image) => {
      const object = obj as ExtendedImage;
      object.id = this.createId();

      for (let p in this.options.imageOptions) {
        const key = p as keyof fabric.Image;
        object[key] = this.options.imageOptions[key];
      }

      // Add a filter that can be used to turn the image
      // into a solid colored shape.
      const filter = new fabric.Image.filters.BlendColor({
        color: '#ffffff',
        alpha: 0
      });
      object.filters = [...(object.filters || []), filter];
      this.canvas.renderAll();

      this.addObjectToCanvas(object);

      objectAdded(object);
    }, this.options.imageDefaults);
  }

  public loadImageURL(imageURL: string): Promise<fabric.Image> {
    return new Promise<fabric.Image>((resolve) => {
      fabric.Image.fromURL(imageURL, function (img) {
        resolve(img);
      });
    });
  }

  public setImage(object: fabric.Image, imageURL: string): fabric.Image {
    return fabric.Image.fromURL(imageURL, (img: fabric.Image) => {
      object.setElement(img.getElement());
    }, this.options.imageDefaults);
  }

  public addShape(svgURL: string): void {
    fabric.loadSVGFromURL(svgURL, (objects, options) => {
      const object = fabric.util.groupSVGElements(objects, options) as ExtendedObject;
      object.id = this.createId();

      this.addObjectToCanvas(object);
    });
  }

  //
  // Creating Objects

  public loadSvg(svgURL: string): void {
    fabric.loadSVGFromURL(svgURL, (objects, options) => {
      objects.forEach((object) => {
        object.lockScalingX = true;
        object.lockScalingY = true;
        this.canvas.add(object);
      });
    });
  }

  //
  // Image


  public addText(str: string): fabric.Text {
    str = str || 'New Text';
    const object = new fabric.Text(str, this.options.textDefaults) as FabricExtended<fabric.Text>;
    object.id = this.createId();

    this.addObjectToCanvas(object);

    return object;
  }

  public addIText(str: string): fabric.IText {
    str = str || 'New Text';
    const object = new fabric.IText(str, this.options.textDefaults) as FabricExtended<fabric.IText>;
    object.id = this.createId();

    this.addObjectToCanvas(object);

    return object;
  }

  public getText(): string {
    return this.getActiveProp<fabric.Text>('text');
  }

  public setText(value: string): void {
    this.setActiveProp<fabric.Text>('text', value);
  }


  public getFontSize(): string {
    return this.getActiveStyle('fontSize');
  }

  //
  // Text

  public setFontSize(value: string): void {
    this.setActiveStyle<fabric.Text>('fontSize', parseInt(value, 10));
    this.render();
  }


  public getTextAlign(): string {
    return this.capitalize(this.getActiveProp<fabric.Text>('textAlign'));
  }

  public setTextAlign(value: string): void {
    this.setActiveProp<fabric.Text>('textAlign', value.toLowerCase());
  }


  public getFontFamily(): string {
    const fontFamily = this.getActiveProp<fabric.Text>('fontFamily');
    return fontFamily?.toLowerCase() ?? '';
  }

  //
  // Font Size

  public setFontFamily(value: string): void {
    this.setActiveProp<fabric.Text>('fontFamily', value.toLowerCase());
  }


  public getLineHeight(): string {
    return this.getActiveStyle('lineHeight');
  }

  //
  // Text Align

  public setLineHeight(value: string): void {
    this.setActiveStyle('lineHeight', parseFloat(value));
    this.render();
  }


  public isBold(): boolean {
    return this.getActiveStyle('fontWeight') === 'bold';
  }

  //
  // Font Family

  public toggleBold(): void {
    this.setActiveStyle('fontWeight',
      this.isBold() ? '' : 'bold');
    this.render();
  }


  public isItalic(): boolean {
    return this.getActiveStyle('fontStyle') === 'italic';
  }

  //
  // Lineheight

  public toggleItalic(): void {
    this.setActiveStyle('fontStyle',
      this.isItalic() ? '' : 'italic');
    this.render();
  }


  public isUnderline(): boolean {
    return this.getActiveStyle('textDecoration').indexOf('underline') > -1;
  }

  //
  // Bold

  public toggleUnderline(): void {
    const value = this.isUnderline() ? this.getActiveStyle('textDecoration').replace('underline', '') : (this.getActiveStyle('textDecoration') + ' underline');

    this.setActiveStyle('textDecoration', value);
    this.render();
  }


  public isLinethrough(): boolean {
    return this.getActiveStyle('textDecoration').indexOf('line-through') > -1;
  }

  //
  // Italic

  public toggleLinethrough(): void {
    const value = this.isLinethrough() ? this.getActiveStyle('textDecoration').replace('line-through', '') : (this.getActiveStyle('textDecoration') + ' line-through');

    this.setActiveStyle('textDecoration', value);
    this.render();
  }


  public getOpacity(): string {
    return this.getActiveStyle('opacity');
  }

  //
  // Underline

  public setOpacity(value: number): void {
    this.setActiveStyle('opacity', value);
  }


  public getFlipX(): any {
    return this.getActiveProp('flipX');
  }

  //
  // Linethrough

  public setFlipX(value: boolean): void {
    this.setActiveProp('flipX', value);
  }

  public toggleFlipX(): void {
    const value = this.getFlipX() ? false : true;
    this.setFlipX(value);
    this.render();
  }


  //
  // Opacity


  public center(): void {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      activeObject.center();
      this.updateActiveObjectOriginals();
      this.render();
    }
  }

  public centerH(): void {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      activeObject.centerH();
      this.updateActiveObjectOriginals();
      this.render();
    }
  }

  //
  // FlipX

  public centerV(): void {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      activeObject.centerV();
      this.updateActiveObjectOriginals();
      this.render();
    }
  }


  public sendBackwards(): void {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      this.canvas.sendBackwards(activeObject);
      this.render();
    }
  }

  public sendToBack(): void {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      this.canvas.sendToBack(activeObject);
      this.render();
    }
  }

  //
  // Align Active Object

  public bringForward(): void {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      this.canvas.bringForward(activeObject);
      this.render();
    }
  }

  public bringToFront(): void {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      this.canvas.bringToFront(activeObject);
      this.render();
    }
  }


  public isTinted(): any {
    return this.getActiveProp<ExtendedImage>('isTinted');
  }

  //
  // Active Object Layer Position

  public toggleTint(): void {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject instanceof fabric.Image) {
      const obj = activeObject as ExtendedImage;
      obj.isTinted = !obj.isTinted;
      if (obj.filters && obj.filters[0] instanceof fabric.Image.filters.BlendColor) {
        (obj.filters[0] as IBlendColorFilter).alpha = obj.isTinted ? 1 : 0;
      }
      activeObject.applyFilters(activeObject.filters);
    }
  }

  public getTint(): string | undefined {
    const object = this.canvas.getActiveObject();

    if (typeof object !== 'object' || object === null) {
      return '';
    }

    if (object instanceof fabric.Image && object.filters !== undefined) {
      if (object.filters[0] instanceof fabric.Image.filters.BlendColor) {
        return (object.filters[0] as IBlendColorFilter).color;
      }
    }
    return undefined;
  }

  public setTint(tint: string): void {
    if (!this.isHex(tint)) {
      return;
    }

    const activeObject = this.canvas.getActiveObject();
    if (activeObject instanceof fabric.Image && activeObject.filters !== undefined) {
      if (activeObject.filters[0] instanceof fabric.Image.filters.BlendColor) {
        (activeObject.filters[0] as IBlendColorFilter).color = tint;
        this.canvas.renderAll();
      }
    }
  }


  public getFill(): string {
    return this.getActiveStyle('fill');
  }

  //
  // Active Object Tint Color

  public setFill(value: string): void {
    const object = this.canvas.getActiveObject();
    if (object) {
      if (object.type === 'text') {
        this.setActiveStyle('fill', value);
      }
      else {
        this.setFillPath(object, value);
      }
    }
  }

  public setFillPath(object: fabric.Object, value: string): void {
    if (object instanceof fabric.Group) {
      for (const obj of object.getObjects()) {
        obj.fill = value;
      }
    }
    else {
      object.fill = value;
    }
  }


  public resetZoom(): void {
    this.canvasScale = 1;
    this.setZoom();
  }

  public setZoom(): void {
    const objects = this.canvas.getObjects() as ExtendedObject[];
    for (const o of objects) {
      o.originalScaleX = o.originalScaleX ? o.originalScaleX : o.scaleX;
      o.originalScaleY = o.originalScaleY ? o.originalScaleY : o.scaleY;
      o.originalLeft = o.originalLeft ? o.originalLeft : o.left;
      o.originalTop = o.originalTop ? o.originalTop : o.top;
      this.setObjectZoom(o);
    }

    this.setCanvasZoom();
    this.render();
  }

  //
  // Active Object Fill Color

  public setObjectZoom(object: ExtendedObject): void {
    const scaleX = object.originalScaleX || 1;
    const scaleY = object.originalScaleY || 1;
    const left = object.originalLeft || 0;
    const top = object.originalTop || 0;

    const tempScaleX = scaleX * this.canvasScale;
    const tempScaleY = scaleY * this.canvasScale;
    const tempLeft = left * this.canvasScale;
    const tempTop = top * this.canvasScale;

    object.scaleX = tempScaleX;
    object.scaleY = tempScaleY;
    object.left = tempLeft;
    object.top = tempTop;

    object.setCoords();
  }

  public setCanvasZoom(): void {
    const width = this.canvasOriginalWidth;
    const height = this.canvasOriginalHeight;

    const tempWidth = width * this.canvasScale;
    const tempHeight = height * this.canvasScale;

    this.canvas.setWidth(tempWidth);
    this.canvas.setHeight(tempHeight);
  }

  public updateActiveObjectOriginals(): void {
    const object = this.canvas.getActiveObject() as ExtendedObject;
    if (object) {
      object.originalScaleX = (object.scaleX || 1) / this.canvasScale;
      object.originalScaleY = (object.scaleY || 1) / this.canvasScale;
      object.originalLeft = (object.left || 0) / this.canvasScale;
      object.originalTop = (object.top || 0) / this.canvasScale;
    }
  }


  //
  // Canvas Zoom


  public toggleLockActiveObject(): void {
    const activeObject = this.canvas.getActiveObject() as ExtendedObject;
    if (activeObject) {
      activeObject.lockMovementX = !activeObject.lockMovementX;
      activeObject.lockMovementY = !activeObject.lockMovementY;
      activeObject.lockScalingX = !activeObject.lockScalingX;
      activeObject.lockScalingY = !activeObject.lockScalingY;
      activeObject.lockUniScaling = !activeObject.lockUniScaling;
      activeObject.lockRotation = !activeObject.lockRotation;
      activeObject.lockObject = !activeObject.lockObject;
      this.render();
    }
  }


  public selectActiveObject(): void {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }

    this.selectedObject.next(activeObject as ExtendedObject);
    const pojo = activeObject as { [key: string]: any };
    pojo.text = this.getText();
    pojo.fontSize = this.getFontSize();
    pojo.lineHeight = this.getLineHeight();
    pojo.textAlign = this.getTextAlign();

    pojo.opacity = this.getOpacity();
    pojo.fontFamily = this.getFontFamily();
    pojo.fill = this.getFill();
    pojo.tint = this.getTint();
  }

  public deselectActiveObject(): void {
    this.selectedObject.next(undefined);
  }

  public deleteActiveObject(): void {
    const activeObject = this.canvas.getActiveObject();
    this.canvas.remove(activeObject);
    this.render();
  }


  public isLoading(): boolean {
    return this.loading;
  }

  //
  // Active Object Lock

  public setLoading(value: boolean): void {
    this.loading = value;
  }

  //
  // Active Object

  public setDirty(value: boolean): void {
    this.dirtyService.setDirty(value);
  }

  public isDirty(): boolean {
    return this.dirtyService.isDirty();
  }

  public setInitalized(value: boolean): void {
    this.initialized = value;
  }

  //
  // State Managers

  public isInitalized(): boolean {
    return this.initialized;
  }


  public getJSON(): string {
    const initialCanvasScale = this.canvasScale;
    this.canvasScale = 1;
    this.resetZoom();

    const json = JSON.stringify(this.canvas.toJSON(this.options.JSONExportProperties));

    this.canvasScale = initialCanvasScale;
    this.setZoom();

    return json;
  }

  public loadJSON(json: string): void {
    this.setLoading(true);
    this.canvas.loadFromJSON(json, () => {
      setTimeout(() => {
        this.setLoading(false);

        if (!this.editable) {
          this.disableEditing();
        }

        this.render();
      });
    });
  }


  public getCanvasData(): string {
    return this.canvas.toDataURL({
      width: this.canvas.getWidth(),
      height: this.canvas.getHeight(),
      multiplier: this.downloadMultipler
    });
  }

  public getCanvasBlob(): string {
    const base64Data = this.getCanvasData();
    const data = base64Data.replace('data:image/png;base64,', '');
    const blob = this.b64toBlob(data, 'image/png');
    return URL.createObjectURL(blob);
  }

  public getCanvas(): FabricExtendedCanvas {
    return this.canvas;
  }

  //
  // JSON

  public download(name: string): void {
    // Stops active object outline from showing in image
    this.deactivateAll();

    const initialCanvasScale = this.canvasScale;
    this.resetZoom();

    // Click an artifical anchor to 'force' download.
    const link = document.createElement('a');
    const filename = name + '.png';
    link.download = filename;
    link.href = this.getCanvasBlob();
    link.click();

    this.canvasScale = initialCanvasScale;
    this.setZoom();
  }

  public stopContinuousRendering(): void {
    clearTimeout(this.continuousRenderHandle);
    this.continuousRenderCounter = this.options.maxContinuousRenderLoops;
  }

  //
  // Download Canvas

  public startContinuousRendering(): void {
    this.continuousRenderCounter = 0;
    this.continuousRender();
  }

  // Prevents the "not fully rendered up upon init for a few seconds" bug.
  public continuousRender(): void {
    if (this.userHasClickedCanvas || this.continuousRenderCounter > this.options.maxContinuousRenderLoops) {
      return;
    }

    this.continuousRenderHandle = window.setTimeout(() => {
      this.setZoom();
      this.render();
      this.continuousRenderCounter++;
      this.continuousRender();
    }, this.options.continuousRenderTimeDelay);
  }


  public setUserHasClickedCanvas(value: boolean): void {
    this.userHasClickedCanvas = value;
  }

  public createId(): string {
    return Math.floor(Math.random() * 10000).toString();
  }

  //
  // Continuous Rendering
  // ==============================================================
  // Upon initialization re render the canvas
  // to account for fonts loaded from CDN's
  // or other lazy loaded items.


  public disableEditing(): void {
    this.canvas.selection = false;
    this.canvas.forEachObject((object) => {
      object.selectable = false;
    });
  }

  public enableEditing(): void {
    this.canvas.selection = true;
    this.canvas.forEachObject((object) => {
      object.selectable = true;
    });
  }


  public setCanvasDefaults(): void {
    this.canvas.selection = this.options.canvasDefaults.selection;
  }

  public setWindowDefaults(): void {
    fabric.Object.prototype.transparentCorners = this.options.windowDefaults.transparentCorners;
    fabric.Object.prototype.rotatingPointOffset = this.options.windowDefaults.rotatingPointOffset;
    fabric.Object.prototype.padding = this.options.windowDefaults.padding;
  }

  // ============================================================
  public startCanvasListeners(): void {
    this.canvas.on('object:selected', () => {
      this.stopContinuousRendering();
      setTimeout(() => {
        this.selectActiveObject();
        this.setDirty(true);
      });
    });
    this.canvas.on('object:moving', (options) => {
    });

    this.canvas.on('object:scaling', (options) => {
    });

    this.canvas.on('object:rotating', (options) => {
    });

    this.canvas.on('selection:created', () => {
      this.stopContinuousRendering();
    });

    this.canvas.on('selection:cleared', () => {
      setTimeout(() => {
        this.deselectActiveObject();
      });
    });

    this.canvas.on('after:render', () => {
      this.canvas.calcOffset();
    });

    this.canvas.on('object:modified', () => {
      this.stopContinuousRendering();
      setTimeout(() => {
        this.updateActiveObjectOriginals();
        this.setDirty(true);
      });
    });
  }

  public forceRefresh(): void {
    const canvas = this.getCanvas();
    const objects = canvas.getObjects() as ExtendedObject[];
    for (let i in objects) {
      objects[i].originalTop = undefined;
      objects[i].originalLeft = undefined;
    }
    this.setCanvasSize(canvas.width ?? 0, canvas.height ?? 0);
  }

  //
  // Utility

  private capitalize(str: string): string {
    if (typeof str !== 'string') {
      return '';
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private getActiveStyle(styleName: string, object?: fabric.Object): string {
    object = object || this.canvas?.getActiveObject();

    if (typeof object !== 'object' || object === null) {
      return '';
    }
    if (object instanceof fabric.Textbox && object.isEditing) {
      return (object.getSelectionStyles()[0][styleName] || '');
    }
    else {
      return object[styleName as keyof fabric.Object] || '';
    }

  }

  //
  // Toggle Object Selectability

  private setActiveStyle<T extends fabric.Object>(styleName: string, value: any, object?: T): void {
    const activeObject = object || this.canvas?.getActiveObject();

    if (activeObject && activeObject instanceof fabric.Textbox && activeObject.isEditing) {
      const style = {} as { [key: string]: any };
      style[styleName] = value;
      activeObject.setSelectionStyles(style);
    }
    else {
      activeObject[styleName as keyof fabric.Object] = value;
    }

    this.render();
  }

  private getActiveProp<T extends fabric.Object>(name: keyof T): any {
    const object = this.canvas?.getActiveObject() as T;
    return object ? object[name] : '';
  }


  //
  // Set Global Defaults

  private setActiveProp<T extends fabric.Object>(name: keyof T, value: any): void {
    const object = this.canvas?.getActiveObject() as T;
    if (object) {
      object.set(name, value);
    }
    this.render();
  }

  private b64toBlob(b64Data: string, contentType: string, sliceSize = 512): Blob {
    contentType = contentType || '';

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  //
  // Canvas Listeners

  private isHex(str: string): boolean {
    return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/gi.test(str);
  }
}


