import { Component, ViewChild, Inject } from '@angular/core';
import { PuzzleBaseComponent } from '../puzzle/puzzle.component';
import { PuzzleTemplateLogic } from './puzzleTemplateLogic';
import { PuzzleEventService } from '../services/puzzle-event.service';
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from '../../services/IQuestionEvents';
import { ANALYTICS_SERVICE_TOKEN, IAnalyticsService } from '../../services/IAnalyticsService';
import { AUTH_DATA_SERVICE_TOKEN, IAuthenticationDataService } from '../../services/IAuthenticationDataService';


@Component({
  selector: 'kh-puzzle-template',
  styleUrls: ['./puzzle-template.component.less'],
  templateUrl: './puzzle-template.component.html'
})
export class PuzzleTemplateComponent extends PuzzleBaseComponent {
    @ViewChild('PuzzleSvg') svgElement: SVGElement | undefined;

    public constructor(@Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService,
        protected puzzleEventService: PuzzleEventService,
        @Inject(ANALYTICS_SERVICE_TOKEN) protected readonly analyitcsService: IAnalyticsService,
        @Inject(AUTH_DATA_SERVICE_TOKEN) protected readonly authenticationDataService: IAuthenticationDataService) {
      super(questionEventsService, puzzleEventService, analyitcsService, authenticationDataService);
    }

    public ngAfterViewInit(): void {
      if (this.svgElement)
      {
        this.setPuzzle(new PuzzleTemplateLogic());
      }
      else {
        console.error('Could not find SVG element for this puzzle');
      }
    }
}
