<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">

<div #minecartContainer class="minecartContainer">
   <div #minecartOverlay class="minecartInputOverlay">
      <!--
      <svg #inputBtns width="150" height="500" viewBox="0 -15 11.906249 105.83333" version="1.1" id="svg2000" class="pointer-events-enable"
         inkscape:version="1.2 (dc2aedaf03, 2022-05-15)" sodipodi:docname="integer-platform-controls.svg"
         xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
         xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns="http://www.w3.org/2000/svg"
         xmlns:svg="http://www.w3.org/2000/svg">
         <defs id="defs1997" />
         <g id="g367" transform="rotate(-90,34.197543,41.22328)">
            <rect
               style="fill:#c0f4ff;fill-opacity:1;stroke:#c0f4ff;stroke-width:1.40279;stroke-linecap:round;stroke-linejoin:round;stroke-opacity:1;paint-order:stroke fill markers;stop-color:#000000"
               id="rect1003" width="22.409714" height="46.222214" x="-24.183718" y="-0.60695148" transform="rotate(-90)"
               ry="11.204857" />
            <g id="minusBtn" style="stroke:#23a3ff;stroke-width:0.264583;stroke-dasharray:none;stroke-opacity:1"
               transform="matrix(1.1223951,0,0,1.1283244,-4.9585527,-1.5933577)">
               <rect
                  style="fill:#47a8ff;fill-opacity:1;stroke:#47a8ff;stroke-width:0.640618;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:stroke fill markers;stop-color:#000000"
                  id="rect1015" width="10.00559" height="12.109485" x="7.9121246" y="-21.602482" transform="rotate(90)"
                  ry="5.0292239" />
               <text #minusTxt xml:space="preserve"
                  style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:12.0477px;line-height:1.25;font-family:ChalkboardSE-Light, 'Chalkboard SE', cursive;-inkscape-font-specification:'ChalkboardSE-Light, Chalkboard SE, cursive, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-east-asian:normal;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.0920414;stroke-dasharray:none;stroke-opacity:1"
                  x="10.675068" y="-11.964284" id="minusTxt" transform="matrix(0,0.97610456,-1.0244804,0,0,0)">
                  <tspan sodipodi:role="line" id="tspan959"
                     style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:12.0477px;font-family:ChalkboardSE-Light, 'Chalkboard SE', cursive;-inkscape-font-specification:'ChalkboardSE-Light, Chalkboard SE, cursive, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-east-asian:normal;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.0920414;stroke-dasharray:none"
                     x="10.675068" y="-11.964284">-</tspan>
               </text>
               <rect #minus
                  style="stroke:#47a8ff;stroke-width:0.640618;stroke-dasharray:none;stroke-opacity:1;fill:#47a8ff;fill-opacity:0;stroke-linecap:round;stroke-linejoin:round;stroke-dashoffset:0;paint-order:stroke fill markers;stop-color:#000000"
                  id="minusCover" width="10.00559" height="12.109485" x="7.9121246" y="-21.602482"
                  transform="rotate(90,-5.6475367e-7,-5.6529942e-7)" ry="5.0292239" />
            </g>
            <g id="plusButton" style="stroke:#23a3ff;stroke-width:0.264583;stroke-dasharray:none;stroke-opacity:1"
               transform="matrix(1.370473,0,0,1.1752043,-11.687256,-2.7901808)">
               <rect
                  style="fill:#47a8ff;fill-opacity:1;stroke:#47a8ff;stroke-width:0.568064;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:stroke fill markers;stop-color:#000000"
                  id="rect350" width="9.6064596" height="9.9174709" x="8.6148987" y="-37.246048" transform="rotate(90)"
                  ry="4.1188526" />
               <text #plusTxt xml:space="preserve"
                  style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:10.6832px;line-height:1.25;font-family:ChalkboardSE-Light, 'Chalkboard SE', cursive;-inkscape-font-specification:'ChalkboardSE-Light, Chalkboard SE, cursive, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-east-asian:normal;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.0816171;stroke-dasharray:none"
                  x="10.189692" y="-30.790009" id="plusTxt" transform="matrix(0,1.0568638,-0.94619571,0,0,0)">
                  <tspan sodipodi:role="line" id="tspan905"
                     style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:10.6832px;font-family:ChalkboardSE-Light, 'Chalkboard SE', cursive;-inkscape-font-specification:'ChalkboardSE-Light, Chalkboard SE, cursive, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-east-asian:normal;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.0816171;stroke-dasharray:none"
                     x="10.189692" y="-30.790009">+</tspan>
               </text>
               <rect #plus
                  style="stroke:#47a8ff;stroke-width:0.568064;stroke-dasharray:none;stroke-opacity:1;fill:#47a8ff;fill-opacity:0;stroke-linecap:round;stroke-linejoin:round;stroke-dashoffset:0;paint-order:stroke fill markers;stop-color:#000000"
                  id="plusCover" width="9.6064596" height="9.9174709" x="8.6148987" y="-37.246048"
                  transform="rotate(90)" ry="4.1188526" />
            </g>
         </g>
      </svg>
      -->

      <!--
      <div #inputNums class="sidebar pointer-events-enable">
      </div>
      -->

      <div class="equation-zone">
         <!--
         <div #equationRemoveButton id="equationRemoveButton" class="minecart-equation-remove-button pointer-events-enable"></div>
         -->

         <div #equation class="equation pointer-events-enable"></div>
         <div #equationInput class="equation equation-input pointer-events-enable"></div>      

         <!--
         <div #equationAddButton id="equationAddButton" class="minecart-equation-add-button pointer-events-enable">

         </div>
                     -->
      </div>
   </div>
   
   <svg #minecartSvg class="main" viewBox="0 0 1280 720" version="1.1" id="svg3253"
      xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
      <defs id="defs3250">
         <image id="groundLeft" width="450"
            href="https://staticcontent.knowledgehook.com/learning-worlds/minecart/ground-left.svg">
         </image>
         <image id="groundRight" width="450"
            href="https://staticcontent.knowledgehook.com/learning-worlds/minecart/ground-right.svg">
         </image>
         <image id="surface" width="380"
            href="https://staticcontent.knowledgehook.com/learning-worlds/minecart/platform.svg">
         </image>
         <image id="spring" width="180" height="380"
            href="https://staticcontent.knowledgehook.com/learning-worlds/minecart/spring.svg">
         </image>
         <image id="playBtn" width="70"
            href="https://staticcontent.knowledgehook.com/learning-worlds/minecart/play-btn.svg"></image>
         <image id="balloon" width="40"
            href="images/minecart-balloon.svg">
         </image>
         <image id="sandbag" width="40"
            href="https://staticcontent.knowledgehook.com/learning-worlds/minecart/sandbag.svg">
         </image>
         <image id="retryBtn" width="70"
            href="https://staticcontent.knowledgehook.com/learning-worlds/minecart/retry-btn.svg">
         </image>
         <image id="tunnel" width="450"
            href="https://staticcontent.knowledgehook.com/learning-worlds/minecart/tunnel1.svg">
         </image>
         <image id="grasstunnel" width="450"
            href="https://staticcontent.knowledgehook.com/learning-worlds/minecart/tunnel2.svg">
         </image>
         <image id="bridge" width="450" height="100"
            href="https://staticcontent.knowledgehook.com/learning-worlds/minecart/bridge.svg">
         </image>
         <image id="bridge-extension" width="450" height="50"
            href="https://staticcontent.knowledgehook.com/learning-worlds/minecart/bridge-extension.svg">
         </image>
         <image id="bridge-wall" width="450" height="50"
            href="https://staticcontent.knowledgehook.com/learning-worlds/minecart/bridge-wall.svg">
         </image>
         <image id="dirt" width="450"
            href="https://staticcontent.knowledgehook.com/learning-worlds/minecart/dirt-rect.svg">
         </image>
         <image id="nextBtn" width="70"
            href="https://staticcontent.knowledgehook.com/learning-worlds/minecart/next-btn.svg"></image>
         <image id="gem" height="50" width="50"
            href="https://staticcontent.knowledgehook.com/learning-worlds/minecart/gem.svg">
         </image>
         <image id="sparkle" height="50" width="50"
            href="https://staticcontent.knowledgehook.com/learning-worlds/minecart/sparkle.svg"
         ></image>
         <clipPath id="SVGID_00000053527752501290461140000004835903138525139383_">
            <use xlink:href="#SVGID_00000051353305199918397890000000278294444491254200_" style="overflow:visible"
               id="use466" />
         </clipPath>
         <clipPath id="SVGID_00000150790064428620358830000003742189225339271331_">
            <use xlink:href="#SVGID_00000077307387601370877060000013353907161601052330_" style="overflow:visible"
               id="use474" />
         </clipPath>
         <clipPath id="SVGID_00000011024886449054222920000014816527063421636776_">
            <use xlink:href="#SVGID_00000113319263687228008200000005241097388660227981_" style="overflow:visible"
               id="use565" />
         </clipPath>
         <clipPath id="SVGID_00000145054998660956614570000005710050520886364823_">
            <use xlink:href="#SVGID_00000120519077490967480640000017437641670044613249_" style="overflow:visible"
               id="use656" />
         </clipPath>
         <clipPath id="SVGID_00000151540657356736464080000006853202056136723878_">
            <use xlink:href="#SVGID_00000132071914674845529790000006250598002237323656_" style="overflow:visible"
               id="use747" />
         </clipPath>
         <polygon id="SVGID_00000051353305199918397890000000278294444491254200_"
            points="2286.3,1233.03 3077.41,1233.03 2990.99,1643.41 2372.72,1643.41 " />
         <rect id="SVGID_00000077307387601370877060000013353907161601052330_" x="2328.98" y="1271.38" width="705.75"
            height="83.019997" />
         <rect id="SVGID_00000113319263687228008200000005241097388660227981_" x="2328.98" y="1353.26" width="705.75"
            height="83.019997" />
         <rect id="SVGID_00000120519077490967480640000017437641670044613249_" x="2328.98" y="1436.12" width="705.75"
            height="83.019997" />
         <rect id="SVGID_00000132071914674845529790000006250598002237323656_" x="2328.98" y="1518" width="705.75"
            height="83.019997" />
      </defs>
      <g #arena inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1">
         <rect
            style="fill:#00dbffff;fill-opacity:1;stroke:none;stroke-width:1.87601;stroke-linecap:round;stroke-linejoin:round;paint-order:stroke fill markers;stop-color:#000000"
            id="rect6979" width="1280" height="720" x="0" y="0" ry="0" />
         <g id="clouds" transform="matrix(0.24426989,0,0,0.24426989,-1.0991606e-4,1.678122e-4)">
            <path id="cloud_00000051360918814410769560000014858662145623270034_"
               d="m 1183.96,541.25 c 20.15,0 32.14,-22.49 20.92,-39.23 -30.45,-45.41 -86.09,-75.82 -149.7,-75.82 -21.72,0 -42.5,3.57 -61.67,10.05 -28.72,-65.9 -92.49,-111.77 -166.6,-111.77 -68.36,0 -127.93,39.04 -159.3,96.81 -13.24,-3.87 -27.23,-5.99 -41.72,-5.99 -54.16,0 -101.53,29.04 -127.42,72.39 -15.03,-10.5 -33.29,-16.68 -53.01,-16.68 -25.69,0 -48.94,10.46 -65.71,27.36 -15.75,15.86 -4.43,42.88 17.93,42.88 z"
               style="fill:#70ebff" />
            <path id="cloud_00000175281654296294138980000003738031505314360964_"
               d="m 3130.07,369.5 c -22.1,0 -33.45,-26.4 -18.3,-42.49 23.57,-25.04 58.89,-40.97 98.42,-40.97 16.02,0 31.34,2.64 45.48,7.41 21.18,-48.6 68.21,-82.43 122.86,-82.43 43.55,0 82.22,21.51 106.87,54.82 16.51,-22.12 42.81,-36.51 72.54,-36.51 50.02,0 90.56,40.55 90.56,90.56 0,0.05 -0.01,0.1 -0.01,0.16 3.69,-0.62 7.48,-0.97 11.36,-0.97 1.31,0 2.62,0.04 3.91,0.11 32.36,1.83 30.64,50.3 -1.78,50.3 h -531.91 z"
               style="fill:#70ebff" />
         </g>
      </g>
      <g #cart >
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M3031.49,1037.75L2286.21,1037.75L2174.34,502.407L3143.37,502.407L3031.49,1037.75Z" style="fill:rgb(227,134,34);fill-rule:nonzero;"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <g>
                 <clipPath id="_clip1">
                     <path d="M3031.49,1037.75L2286.21,1037.75L2174.34,502.407L3143.37,502.407L3031.49,1037.75Z"/>
                 </clipPath>
                 <g clip-path="url(#_clip1)">
                     <g>
                         <rect x="2185.25" y="558.877" width="947.197" height="125.929" style="fill:rgb(227,134,34);"/>
                         <g>
                             <path d="M2554.4,599.225C2552.43,597.623 2549.24,596.046 2545.9,596.046C2545.1,596.046 2544.28,596.137 2543.48,596.341C2539.46,597.36 2537.16,600.727 2535.16,604.089L2512.78,641.641C2511.28,644.15 2510.09,646.401 2510.71,648.557C2511.7,652.063 2517.21,653.772 2521.74,653.353C2525.72,652.981 2529.44,651.423 2533.04,649.915C2533.88,649.564 2534.72,649.212 2535.56,648.876C2541.17,646.632 2554.51,646.123 2568.63,645.584C2582.68,645.048 2601.91,644.314 2602.67,641.163C2603.58,637.416 2593.99,632.667 2586.99,629.199C2583.52,627.479 2580.52,625.993 2578.74,624.689C2573.17,620.616 2569.21,615.715 2565.38,610.975C2561.95,606.735 2558.72,602.73 2554.4,599.225Z" style="fill:none;fill-rule:nonzero;"/>
                             <path d="M2310.78,651.654C2313.8,653.822 2317.23,656.279 2319.76,659.965C2320.31,660.767 2320.86,661.584 2321.4,662.407C2325.25,668.184 2329.24,674.157 2336.46,676.58C2342.46,678.589 2350.19,677.545 2355.71,673.981C2360.7,670.754 2364.03,665.596 2364.83,659.827C2366.56,647.287 2355.38,634.553 2339.89,631.441C2337.57,630.975 2334.97,630.743 2332.26,630.743C2323.12,630.743 2312.79,633.385 2308.08,638.597C2305.67,641.273 2304.59,643.431 2304.8,645.197C2305.07,647.557 2307.59,649.364 2310.78,651.654Z" style="fill:none;fill-rule:nonzero;"/>
                             <path d="M2189.2,655.418C2213.98,650.495 2239.61,645.406 2263.51,637.337C2296.32,626.265 2322.95,610.281 2340.54,591.115C2349.65,581.191 2356.35,570.175 2360.43,558.877L2358.22,558.877C2354.21,569.723 2347.71,580.299 2338.95,589.851C2321.59,608.761 2295.26,624.548 2262.8,635.506C2239.03,643.527 2213.48,648.603 2188.76,653.512C2187.6,653.743 2186.42,653.979 2185.25,654.211L2185.25,656.204C2186.57,655.943 2187.89,655.679 2189.2,655.418Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2187.99,620.734C2217.26,612.917 2247.53,604.832 2274.11,591.652C2293.44,582.064 2307.76,571.066 2316.86,558.877L2314.29,558.877C2305.35,570.367 2291.56,580.785 2273.12,589.931C2246.73,603.015 2216.58,611.071 2187.41,618.861C2186.7,619.052 2185.97,619.247 2185.25,619.439L2185.25,621.467C2186.16,621.224 2187.08,620.976 2187.99,620.734Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2211.43,558.877L2209.16,558.877C2203.96,570.345 2195.7,580.956 2185.25,589.971L2185.25,592.599C2196.83,582.952 2205.9,571.405 2211.43,558.877Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2403.81,558.877L2401.62,558.877C2401.07,560.646 2400.58,562.429 2400.17,564.228C2397.73,574.994 2399.27,586.726 2404.4,596.417C2410.09,607.165 2419.82,614.629 2431.8,617.437C2435.41,618.286 2439.04,618.677 2442.63,618.677C2462.16,618.677 2480.49,607.053 2489.37,594.018C2496.26,583.9 2499.73,572.249 2499.78,558.878L2497.68,558.878C2497.62,571.877 2494.26,583.184 2487.59,592.976C2477.48,607.828 2454.66,620.783 2432.31,615.548C2412.88,610.995 2396.31,590.704 2402.22,564.63C2402.66,562.696 2403.2,560.78 2403.81,558.877Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2474.33,634.913C2451.13,647.249 2415.83,651.722 2396.09,635.709C2378.08,621.095 2381.14,597.157 2385.81,577.934C2387.36,571.591 2389.03,565.165 2390.68,558.877L2388.52,558.877C2386.91,565.026 2385.28,571.302 2383.77,577.504C2378.96,597.254 2375.86,621.886 2394.71,637.177C2404.08,644.773 2416.76,647.951 2430.06,647.951C2445.82,647.951 2462.44,643.479 2475.37,636.604C2507.53,619.499 2527.56,589.482 2528.48,558.877L2526.38,558.877C2525.46,588.795 2505.83,618.156 2474.33,634.913Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2433.9,571.939C2434.06,569.11 2434.35,564.255 2435.04,558.877L2432.91,558.877C2432.26,564.068 2431.98,568.789 2431.81,571.834L2432.85,571.921L2431.8,571.868C2431.52,576.774 2431.6,582.472 2434.4,587.538C2437.44,593.06 2443.52,596.794 2449.88,597.05C2450.11,597.059 2450.34,597.064 2450.57,597.064C2456.58,597.064 2462.82,593.933 2466.62,588.957C2470.16,584.329 2471.47,578.802 2472.62,573.926C2473.76,569.159 2474.96,564.053 2475.34,558.877L2473.23,558.877C2472.85,563.885 2471.68,568.861 2470.58,573.506C2469.41,578.431 2468.2,583.523 2464.91,587.829C2461.37,592.472 2455.49,595.32 2449.97,595.104C2444.35,594.878 2438.97,591.56 2436.27,586.65C2433.7,581.996 2433.63,576.62 2433.9,571.974L2433.9,571.939Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2496.98,680.552C2493.87,681.178 2490.76,681.816 2487.65,682.455C2483.81,683.243 2479.95,684.032 2476.07,684.806L2485.91,684.806C2486.64,684.657 2487.37,684.507 2488.1,684.358C2491.21,683.72 2494.32,683.083 2497.42,682.457C2523.96,677.108 2554.74,671.798 2584.17,676.577C2594.67,678.282 2603.84,681.162 2611.67,684.806L2615.85,684.806C2606.45,679.986 2595.64,676.46 2584.53,674.657C2554.73,669.82 2523.71,675.163 2496.98,680.552Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2263.92,681.867C2269.74,681.654 2280.62,681.763 2288.83,684.806L2293.57,684.806C2288.16,681.701 2279.08,679.36 2263.83,679.92C2242.55,680.706 2221.3,682.353 2200.2,684.806L2219.04,684.806C2233.96,683.42 2248.93,682.421 2263.92,681.867Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2309.5,653.197C2312.39,655.267 2315.66,657.614 2317.99,661.014C2318.54,661.808 2319.08,662.619 2319.62,663.435C2323.66,669.495 2327.84,675.762 2335.75,678.414C2338.05,679.186 2340.55,679.562 2343.07,679.562C2347.91,679.562 2352.87,678.188 2356.9,675.582C2362.38,672.042 2366.03,666.39 2366.9,660.077C2368.78,646.558 2356.86,632.857 2340.33,629.537C2326.76,626.812 2311.87,631.373 2306.48,637.345C2303.63,640.491 2302.44,643.053 2302.71,645.407C2303.08,648.592 2306.06,650.726 2309.5,653.197ZM2308.08,638.597C2312.79,633.385 2323.12,630.743 2332.26,630.743C2334.97,630.743 2337.57,630.975 2339.89,631.441C2355.38,634.553 2366.56,647.287 2364.83,659.827C2364.03,665.595 2360.7,670.754 2355.71,673.981C2350.19,677.544 2342.46,678.589 2336.46,676.58C2329.24,674.158 2325.25,668.184 2321.4,662.407C2320.86,661.584 2320.31,660.766 2319.76,659.965C2317.23,656.279 2313.8,653.822 2310.78,651.654C2307.59,649.365 2305.07,647.557 2304.8,645.197C2304.59,643.431 2305.67,641.273 2308.08,638.597Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2724.62,651.468C2707.68,634.37 2684.72,621.621 2659.96,615.567C2654.13,614.14 2647.95,612.957 2641.97,611.812C2623.52,608.278 2604.45,604.624 2592.62,593.312C2583.66,584.73 2580.36,573.202 2582.71,558.877L2580.59,558.877C2578.22,573.693 2581.72,585.675 2591.12,594.67C2603.39,606.412 2622.79,610.127 2641.55,613.72C2647.5,614.86 2653.65,616.038 2659.43,617.451C2683.8,623.411 2706.41,635.961 2723.08,652.788C2732.72,662.514 2740.12,673.431 2744.96,684.806L2747.2,684.806C2742.28,672.95 2734.64,661.573 2724.62,651.468Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2890.55,558.877L2883.05,558.877C2865.87,563.406 2848.29,566.93 2830.56,564.827C2822.72,563.898 2815.21,561.839 2808.37,558.877L2803.71,558.877C2811.71,562.884 2820.77,565.633 2830.29,566.761C2850.83,569.195 2870.98,564.286 2890.55,558.877Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M3078.51,648.761C3078.69,649.107 3079.06,649.309 3079.45,649.309C3079.6,649.309 3079.76,649.278 3079.91,649.211C3080.43,648.975 3080.64,648.392 3080.39,647.908C3068.18,624.597 3055.55,600.493 3034.9,580.515C3025.89,571.796 3015.8,564.523 3005.1,558.878L3000.74,558.878C3012.5,564.637 3023.59,572.383 3033.39,581.866C3053.81,601.622 3066.36,625.586 3078.51,648.761Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M3057.22,564.025C3054.8,562.247 3052.31,560.544 3049.78,558.878L3045.99,558.878C3077.18,578.766 3100.4,606.646 3108.85,637.532C3108.97,637.973 3109.4,638.267 3109.86,638.267C3109.95,638.267 3110.04,638.257 3110.12,638.236C3110.68,638.103 3111.02,637.573 3110.88,637.052C3103.45,609.906 3084.39,583.972 3057.22,564.025Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2884.74,642.959C2868.3,623.994 2849.85,612.307 2829.9,608.222C2818.4,605.866 2805.39,606.632 2794.23,610.322C2782.27,614.273 2773.53,621.145 2769.62,629.673C2762.86,644.379 2770.97,660.738 2777.66,671.763C2778.8,673.638 2779.97,675.513 2781.15,677.395C2782.68,679.845 2784.22,682.315 2785.7,684.807L2788.1,684.807C2786.44,681.979 2784.7,679.185 2782.96,676.415C2781.79,674.541 2780.62,672.673 2779.49,670.806C2773.02,660.14 2765.15,644.352 2771.55,630.438C2779.62,612.849 2808.01,605.735 2829.45,610.125C2848.92,614.113 2866.98,625.571 2883.11,644.182C2893.92,656.651 2902.53,670.397 2908.71,684.806L2910.96,684.806C2904.68,669.954 2895.86,655.787 2884.74,642.959Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2842.37,637.958C2832.18,630.489 2816.09,624.108 2804.31,630.706C2797.92,634.282 2794.45,640.845 2793.71,650.77C2792.84,662.253 2794.57,673.803 2798.64,684.807L2800.86,684.807C2796.71,673.859 2794.94,662.346 2795.8,650.906C2796.49,641.661 2799.63,635.6 2805.39,632.376C2816.26,626.293 2831.41,632.405 2841.07,639.49C2856.72,650.956 2869.15,666.19 2878.1,684.808L2880.4,684.808C2871.29,665.533 2858.51,649.785 2842.37,637.958Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2825.96,655.485C2819.82,653.969 2811.7,655.807 2809.38,660.962C2807.01,666.247 2811.66,671.474 2815.38,675.674L2815.61,675.933C2818.13,678.765 2820.36,681.734 2822.32,684.806L2824.74,684.806C2822.58,681.295 2820.09,677.908 2817.23,674.693L2817,674.433C2813.45,670.439 2809.43,665.912 2811.32,661.712C2813.18,657.56 2820.25,656.09 2825.43,657.368C2831.11,658.772 2835.42,662.565 2838.89,666.09C2844.58,671.882 2849.41,678.176 2853.32,684.805L2855.71,684.805C2851.62,677.696 2846.51,670.954 2840.43,664.775C2836.76,661.042 2832.17,657.018 2825.96,655.485Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2629.12,558.877L2626.73,558.877C2626.48,559.34 2626.24,559.809 2626.05,560.289C2624.54,564.027 2623.6,571.409 2634.3,578.844C2642.75,584.718 2654.48,586.802 2663.9,588.476C2689.43,593.013 2721.21,598.659 2738.65,617.122C2740.58,619.16 2743.67,622.437 2747.67,622.436C2748.1,622.436 2748.54,622.398 2748.99,622.317C2752.38,621.702 2754.5,618.932 2755.29,614.084L2759.88,585.766C2761.4,576.365 2762.26,567.331 2756.16,560.146C2755.78,559.698 2755.37,559.287 2754.96,558.877L2751.91,558.877C2752.89,559.648 2753.76,560.47 2754.51,561.353C2760.1,567.927 2759.26,576.515 2757.8,585.475L2753.21,613.793C2752.57,617.794 2751.05,619.957 2748.59,620.403C2745.4,620.986 2742.71,618.463 2740.23,615.835C2722.31,596.869 2690.14,591.153 2664.3,586.561C2655.08,584.924 2643.62,582.886 2635.55,577.282C2628.44,572.337 2625.76,566.545 2628.01,560.973C2628.3,560.252 2628.69,559.556 2629.12,558.877Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M3041.35,570.745C3077.93,596.403 3100.41,634.834 3101.5,673.547C3101.51,674.077 3101.98,674.496 3102.55,674.496L3102.57,674.496C3103.15,674.482 3103.61,674.034 3103.59,673.496C3102.49,634.195 3079.69,595.202 3042.61,569.189C3037.33,565.483 3031.74,562.048 3025.92,558.877L3021.7,558.877C3028.6,562.463 3035.18,566.419 3041.35,570.745Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M3010.03,653.834C3003.06,648.561 2996.84,645.763 2990.47,645.029C2974.23,643.161 2960.43,655.69 2958.21,668.111C2957.21,673.717 2957.91,679.358 2959.46,684.805L2961.61,684.805C2960.02,679.442 2959.3,673.942 2960.28,668.43C2962.85,654.042 2978.12,645.57 2990.22,646.962C2996.18,647.65 3002.05,650.313 3008.71,655.345C3019.51,663.503 3027.72,673.699 3032.73,684.805L3035,684.805C3029.9,673.114 3021.34,662.379 3010.03,653.834Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M3050.26,667.979C3048.36,662.804 3046.4,657.453 3044.24,652.243C3039.36,640.494 3031.05,623.461 3015.22,611.254C2999.59,599.189 2977.99,593.992 2960.22,598.013C2935.45,603.613 2922.17,624.917 2920.3,642.341C2918.75,656.813 2922.83,671.283 2927.94,684.805L2930.17,684.805C2925.01,671.299 2920.85,656.852 2922.39,642.534C2924.18,625.772 2936.94,605.282 2960.71,599.906C2977.86,596.026 2998.74,601.069 3013.89,612.753C3029.33,624.668 3037.49,641.397 3042.28,652.943C3043.73,656.433 3045.09,659.993 3046.4,663.525C3034.2,647.285 3015.9,633.945 2994.09,626.125C2983.24,622.239 2974.55,621.43 2967.51,623.658C2958.99,626.35 2953.03,633.19 2949.27,644.568C2944.93,657.736 2943.51,671.351 2944.98,684.805L2947.09,684.805C2945.61,671.544 2946.99,658.121 2951.28,645.14C2954.82,634.403 2960.35,627.98 2968.18,625.504C2974.74,623.43 2982.97,624.23 2993.33,627.944C3017.74,636.693 3037.65,652.458 3049.32,671.439C3050.96,675.874 3052.65,680.355 3054.51,684.806L3058.17,684.806C3056.36,679.939 3054.03,675.205 3051.25,670.65C3050.92,669.758 3050.59,668.866 3050.26,667.979Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2555.78,597.757C2552.05,594.722 2547,593.427 2542.93,594.46C2538.12,595.679 2535.45,599.581 2533.33,603.144L2510.94,640.696C2509.62,642.92 2507.8,645.965 2508.68,649.053C2509.85,653.177 2515.25,655.365 2520.35,655.365C2520.89,655.365 2521.42,655.34 2521.95,655.292C2526.27,654.888 2530.15,653.264 2533.9,651.693C2534.73,651.346 2535.56,650.999 2536.39,650.666C2541.64,648.565 2555.41,648.04 2568.72,647.532C2590.35,646.707 2603.66,645.978 2604.72,641.589C2605.97,636.395 2596.41,631.658 2587.98,627.479C2584.73,625.872 2581.67,624.354 2580.03,623.157C2574.68,619.241 2570.81,614.444 2567.06,609.804C2563.72,605.671 2560.26,601.396 2555.78,597.757ZM2578.74,624.689C2580.52,625.993 2583.52,627.479 2586.99,629.199C2593.99,632.667 2603.58,637.416 2602.67,641.163C2601.91,644.315 2582.68,645.048 2568.64,645.584C2554.51,646.123 2541.17,646.632 2535.57,648.876C2534.72,649.213 2533.88,649.564 2533.04,649.915C2529.44,651.423 2525.72,652.981 2521.74,653.353C2517.21,653.772 2511.7,652.063 2510.71,648.557C2510.1,646.401 2511.28,644.15 2512.78,641.641L2535.16,604.089C2537.16,600.727 2539.46,597.36 2543.48,596.341C2544.29,596.138 2545.1,596.046 2545.9,596.046C2549.24,596.046 2552.43,597.623 2554.41,599.225C2558.72,602.73 2561.95,606.735 2565.38,610.975C2569.21,615.714 2573.17,620.616 2578.74,624.689Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M3088.97,560.521C3089.22,560.521 3089.47,560.505 3089.72,560.472C3090.93,560.316 3092.04,559.783 3093.08,558.877L3084.24,558.877C3085.82,559.888 3087.39,560.521 3088.97,560.521Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                         </g>
                         <rect x="2185.25" y="673" width="947.197" height="11.806" style="fill:rgb(191,116,15);fill-opacity:0.73;"/>
                         <rect x="2185.25" y="558.877" width="947.197" height="11.806" style="fill:rgb(255,178,35);fill-opacity:0.71;"/>
                     </g>
                     <g>
                         <rect x="2185.25" y="684.806" width="947.197" height="125.929" style="fill:rgb(227,134,34);"/>
                         <g>
                             <path d="M2261.43,750.696C2258.56,750.696 2255.23,752.119 2251.34,754.998C2246.5,758.584 2235.68,767.739 2239.88,775.272C2241.28,777.787 2242.8,779.167 2244.38,779.373C2247.46,779.784 2251.33,775.949 2255.07,772.255C2256.78,770.568 2258.55,768.823 2260.37,767.329C2263.24,764.964 2266.49,762.282 2267.68,759.012C2267.8,758.673 2267.89,758.32 2267.96,757.96C2268.39,755.434 2267.32,752.206 2263.92,751.086C2263.13,750.827 2262.3,750.696 2261.43,750.696Z" style="fill:none;fill-rule:nonzero;"/>
                             <path d="M3070.2,742.66C3072.54,741.086 3074.96,739.46 3076.97,737.619C3081.04,733.89 3082.44,729.571 3080.62,726.348C3078.76,723.07 3074.01,721.675 3068.2,722.704C3061.36,723.918 3045.13,738.047 3039.52,743.735C3033.82,749.505 3029.09,758.684 3032.9,767.144C3036.1,774.259 3047.27,779.972 3056.82,776.975C3052.04,767.092 3054.87,755.017 3063.92,747.252C3065.87,745.578 3068.07,744.095 3070.2,742.66Z" style="fill:none;fill-rule:nonzero;"/>
                             <path d="M3132.45,708.728L3132.45,706.442C3116.3,697.204 3098.56,689.055 3079.95,684.806L3069.18,684.806C3091.7,688.027 3113.23,697.625 3132.45,708.728Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2994.87,810.683C2975.01,789.037 2966.77,762.14 2972.83,738.733C2979.55,712.77 3004.21,691.372 3034.18,685.486C3035.48,685.23 3036.79,685.007 3038.09,684.806L3028.4,684.806C3000.1,692.213 2977.3,713.16 2970.79,738.277C2964.68,761.852 2972.68,788.827 2992.19,810.735L2994.92,810.735C2994.9,810.718 2994.89,810.701 2994.87,810.683Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M3114.72,713.308C3099.64,706.141 3081.33,702.169 3064.49,702.443C3051.29,702.65 3032.73,705.492 3019.33,717.871C3004.72,731.368 3001.72,753.038 3011.86,771.796C3019.76,786.41 3034.71,799.496 3056.29,810.735L3060.64,810.735C3037.63,799.314 3021.85,785.918 3013.74,770.92C3004,752.919 3006.85,732.155 3020.81,719.253C3037.58,703.764 3075.86,697.025 3113.76,715.043C3120.35,718.174 3126.54,721.781 3132.45,725.677L3132.45,723.285C3126.83,719.647 3120.96,716.274 3114.72,713.308Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M3065.34,748.683C3067.2,747.089 3069.35,745.64 3071.43,744.238C3073.84,742.622 3076.32,740.951 3078.44,739.006C3083.19,734.651 3084.74,729.453 3082.47,725.441C3080.17,721.373 3074.55,719.591 3067.8,720.789C3059.31,722.297 3041.19,739.159 3037.97,742.416C3030.14,750.345 3027.46,760.108 3030.97,767.896C3034.4,775.526 3043.55,779.623 3051.62,779.623C3054.14,779.623 3056.55,779.225 3058.66,778.412C3058.93,778.308 3059.14,778.104 3059.24,777.85C3059.34,777.596 3059.32,777.314 3059.19,777.072C3054.15,767.793 3056.68,756.119 3065.34,748.683ZM3056.82,776.974C3047.27,779.971 3036.1,774.258 3032.9,767.143C3029.09,758.683 3033.82,749.504 3039.51,743.734C3045.13,738.046 3061.36,723.917 3068.2,722.703C3074,721.674 3078.76,723.069 3080.61,726.347C3082.43,729.57 3081.04,733.889 3076.97,737.618C3074.96,739.459 3072.54,741.085 3070.2,742.659C3068.07,744.094 3065.87,745.577 3063.92,747.25C3054.87,755.016 3052.04,767.091 3056.82,776.974Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M3123.89,777.965C3122.06,773.65 3120.37,768.581 3121.71,763.7C3122.82,759.631 3126.82,755.364 3132.45,754.628L3132.45,752.671C3125.76,753.417 3120.99,758.433 3119.68,763.219C3118.18,768.669 3120.1,774.344 3121.93,778.678C3124.59,784.95 3128.14,790.982 3132.45,796.665L3132.45,793.215C3129.01,788.35 3126.12,783.244 3123.89,777.965Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2895.22,684.806C2894.94,699.075 2897.61,713.341 2903.45,726.809C2906.77,734.482 2911.08,742.007 2915.25,749.285C2921.88,760.866 2928.73,772.841 2931.59,785.461C2933.7,794.778 2932.93,803.349 2930.2,810.735L2932.21,810.735C2935.01,802.549 2935.56,793.508 2933.64,785.059C2930.72,772.169 2923.8,760.07 2917.1,748.369C2912.96,741.136 2908.68,733.656 2905.39,726.08C2899.66,712.848 2897.04,698.829 2897.31,684.805L2895.22,684.805L2895.22,684.806Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2851.88,716.026C2849.29,721.916 2846.61,728.007 2844.2,734.022C2831.46,765.87 2830.69,791.03 2841.89,810.735L2844.28,810.735C2832.83,791.411 2833.45,766.49 2846.17,734.701C2848.57,728.716 2851.24,722.639 2853.82,716.763C2858.42,706.304 2863.09,695.654 2866.54,684.806L2864.35,684.806C2860.94,695.384 2856.38,705.797 2851.88,716.026Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2808.33,766.02C2810.79,751.265 2817.74,737.027 2824.45,723.258C2830.5,710.873 2836.73,698.077 2839.79,684.807L2837.64,684.807C2834.6,697.73 2828.48,710.291 2822.55,722.453C2815.77,736.342 2808.76,750.704 2806.26,765.723C2803.74,780.916 2806.23,796.427 2812.86,810.736L2815.14,810.736C2808.39,796.545 2805.82,781.118 2808.33,766.02Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2928.41,684.806C2928.14,688.653 2928.04,692.226 2928.05,695.416C2928.2,722.909 2935.41,750.309 2942.37,776.806C2944.26,784.004 2946.22,791.446 2948,798.766C2948.93,802.605 2949.8,806.632 2950.45,810.735L2952.57,810.735C2951.91,806.482 2951.01,802.31 2950.05,798.336C2948.26,790.999 2946.3,783.548 2944.41,776.343C2937.47,749.956 2930.3,722.669 2930.15,695.406C2930.13,692.223 2930.23,688.652 2930.51,684.806L2928.41,684.806Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2868.9,808.661C2865.62,804.356 2864.41,799.263 2863.24,794.338C2861.17,785.618 2858.82,775.733 2862.01,766.656C2863.97,761.061 2869.69,754.275 2876.83,752.132C2878.3,751.689 2879.74,751.468 2881.15,751.468C2883.88,751.468 2886.47,752.294 2888.9,753.941C2898.02,760.125 2899.45,784.697 2899.92,792.772L2899.92,792.806C2900.19,797.452 2900.12,802.828 2897.55,807.482C2896.9,808.667 2896.09,809.757 2895.15,810.735L2897.84,810.735C2898.42,809.989 2898.96,809.203 2899.42,808.37C2902.21,803.303 2902.3,797.605 2902.01,792.7L2900.97,792.753L2902.01,792.666C2901.48,783.485 2900.06,759.096 2890.13,752.365C2885.93,749.519 2881.11,748.797 2876.18,750.276C2869.19,752.378 2862.54,758.865 2860.02,766.051C2856.65,775.634 2859.07,785.793 2861.19,794.758C2862.35,799.634 2863.66,805.161 2867.2,809.789C2867.44,810.113 2867.7,810.428 2867.97,810.735L2870.78,810.735C2870.1,810.091 2869.47,809.402 2868.9,808.661Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2723.23,684.806C2715.88,704.996 2727.82,727.025 2738.47,746.643L2739.35,748.264C2754.14,775.548 2755.79,796.53 2744.32,810.735L2746.91,810.735C2757.91,795.947 2756.02,774.68 2741.22,747.387L2740.34,745.766C2729.78,726.303 2717.91,704.419 2725.45,684.806L2723.23,684.806Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2300.43,802.697C2323.15,780.708 2352.8,768.029 2381.76,767.908L2382.19,767.908C2401.05,767.908 2419.59,773.095 2437.53,778.113C2458.89,784.088 2480.97,790.266 2503.52,787.593C2523.64,785.208 2541.76,775.664 2551.98,762.061C2562.41,748.174 2563.98,730.497 2556.08,715.927C2549.22,703.297 2536.13,693.011 2516.39,684.806L2510.93,684.806C2532.66,693.137 2547.17,703.854 2554.2,716.806C2561.76,730.734 2560.25,747.648 2550.26,760.946C2540.37,774.105 2522.8,783.344 2503.26,785.66C2481.16,788.281 2459.28,782.162 2438.14,776.247C2420.05,771.189 2401.36,765.959 2382.19,765.959C2382.04,765.959 2381.9,765.959 2381.75,765.96C2352.22,766.082 2322.03,778.98 2298.91,801.347C2295.78,804.384 2292.84,807.525 2290.05,810.736L2292.74,810.736C2295.18,807.992 2297.73,805.306 2300.43,802.697Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2372.58,750.074C2375.69,749.736 2378.89,749.458 2381.97,749.189C2390.93,748.409 2400.2,747.602 2408.88,745.236C2422.55,741.51 2432.33,734.048 2435.03,725.275C2438.95,712.515 2427.78,700.481 2417.01,694.053C2410.82,690.357 2403.89,687.492 2397.03,684.806L2391.6,684.806C2392.08,684.991 2392.56,685.179 2393.03,685.362C2400.82,688.387 2408.88,691.515 2415.88,695.694C2426.04,701.76 2436.62,713.007 2433.01,724.74C2430.51,732.867 2421.27,739.829 2408.29,743.366C2399.8,745.68 2390.64,746.478 2381.78,747.25C2378.68,747.52 2375.47,747.799 2372.34,748.14C2337.74,751.892 2303.74,764.932 2276.59,784.858C2265.96,792.662 2256.58,801.386 2248.64,810.737L2251.38,810.737C2279.33,778.674 2323.96,755.348 2372.58,750.074Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2601.91,719.527C2603.13,726.874 2608.88,751.12 2631.68,754.66C2633.35,754.92 2635.01,755.042 2636.65,755.042C2649.32,755.042 2660.76,747.732 2666.58,740.125C2675.87,728 2677.52,711.937 2671.48,692.378C2670.7,689.845 2669.82,687.318 2668.89,684.805L2666.67,684.805C2667.68,687.499 2668.63,690.207 2669.47,692.915C2675.32,711.874 2673.78,727.378 2664.88,738.994C2658.66,747.104 2645.74,754.863 2632.03,752.737C2610.62,749.413 2605.15,726.249 2603.98,719.228C2603.67,717.379 2603.39,715.5 2603.11,713.608C2601.61,703.523 2600.07,693.18 2594.16,684.805L2591.58,684.805C2597.92,693.028 2599.51,703.587 2601.04,713.876C2601.32,715.779 2601.6,717.668 2601.91,719.527Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2619.37,687.296C2620.6,687.592 2621.82,687.728 2623.03,687.728C2626.57,687.728 2629.97,686.552 2632.69,684.807L2628.21,684.807C2625.63,685.746 2622.73,686.097 2619.9,685.411C2619.26,685.255 2618.66,685.047 2618.08,684.807L2614.11,684.807C2615.59,685.922 2617.34,686.801 2619.37,687.296Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2576.01,806.307C2574.56,797.347 2573.71,788.758 2579.3,782.185C2584.3,776.3 2594.48,772.806 2606.6,772.806C2606.74,772.806 2606.87,772.806 2607.01,772.807C2613.11,772.848 2619.26,773.602 2625.22,774.333C2633.19,775.311 2641.43,776.323 2649.75,775.657C2654.83,775.251 2659.67,773.788 2664.35,772.374C2669.09,770.939 2673.57,769.584 2678.25,769.252C2679.11,769.191 2679.96,769.161 2680.81,769.161C2692.15,769.161 2702.83,774.447 2705.8,781.806C2708.05,787.377 2705.38,793.17 2698.26,798.115C2690.2,803.72 2678.73,805.757 2669.52,807.394C2663.81,808.409 2657.78,809.479 2651.68,810.735L2661.98,810.735C2664.67,810.24 2667.33,809.768 2669.91,809.308C2679.34,807.633 2691.07,805.549 2699.52,799.676C2710.22,792.241 2709.28,784.859 2707.77,781.121C2704.26,772.442 2691.23,766.375 2678.09,767.308C2673.16,767.659 2668.35,769.113 2663.7,770.52C2659.13,771.9 2654.41,773.327 2649.57,773.714C2641.48,774.361 2633.35,773.364 2625.49,772.4C2619.48,771.662 2613.26,770.899 2607.03,770.858C2600.8,770.806 2585.52,771.723 2577.65,780.977C2571.55,788.163 2572.41,797.196 2573.94,806.597L2574.61,810.734L2576.73,810.734L2576.01,806.307Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2292.47,791.577C2329.1,765.883 2380.01,752.884 2428.66,756.804C2432.16,757.086 2435.74,757.457 2439.19,757.816C2449.97,758.936 2461.12,760.092 2472.04,758.779C2479.57,757.874 2486.87,755.473 2492.59,752.018C2499.03,748.127 2503.11,743.149 2504.4,737.62C2506.28,729.541 2502.27,720.521 2492.49,710.811C2482.33,700.726 2469.9,692.388 2456.78,684.806L2452.77,684.806C2466.83,692.761 2480.22,701.479 2490.96,712.143C2500.24,721.358 2504.08,729.791 2502.35,737.208C2500.51,745.117 2490.16,754.637 2471.77,756.847C2461.1,758.13 2450.08,756.986 2439.43,755.879C2435.96,755.519 2432.37,755.147 2428.85,754.862C2379.7,750.902 2328.24,764.044 2291.21,790.021C2282.34,796.239 2274.3,803.206 2267.17,810.735L2269.97,810.735C2276.7,803.797 2284.22,797.36 2292.47,791.577Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2194.25,731.739C2207.68,720.108 2227.58,713.462 2247.45,713.966C2253.35,714.115 2259.32,714.875 2265.08,715.61C2270.15,716.256 2275.39,716.923 2280.61,717.184C2294.2,717.86 2305.49,715.146 2312.39,709.533C2320.29,703.104 2321.71,693.823 2319.15,684.807L2316.99,684.807C2319.55,693.32 2318.37,702.078 2311.01,708.067C2302.26,715.185 2288.28,715.615 2280.72,715.238C2275.58,714.982 2270.39,714.32 2265.37,713.68C2259.55,712.938 2253.52,712.17 2247.51,712.019C2227.1,711.505 2206.64,718.343 2192.82,730.315C2189.99,732.765 2187.48,735.424 2185.25,738.23L2185.25,741.622C2187.77,738.077 2190.78,734.749 2194.25,731.739Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2195.04,699.479C2206.26,692.048 2220.71,688.893 2233.69,691.044C2235.88,691.406 2238.1,691.87 2240.46,692.361C2250.12,694.375 2260.1,696.458 2267.23,691.257C2269.74,689.43 2271.14,687.187 2271.8,684.807L2269.61,684.807C2268.99,686.638 2267.84,688.336 2265.94,689.724C2259.59,694.355 2250.1,692.375 2240.91,690.459C2238.64,689.985 2236.29,689.495 2234.06,689.125C2220.54,686.887 2205.5,690.163 2193.82,697.892C2190.53,700.068 2187.67,702.498 2185.25,705.11L2185.25,708.199C2187.85,705.009 2191.13,702.062 2195.04,699.479Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2185.25,693.871C2185.39,693.957 2185.55,694.024 2185.72,694.04C2185.76,694.043 2185.79,694.045 2185.83,694.045C2186.36,694.045 2186.82,693.67 2186.87,693.167C2187.18,690.242 2188.22,687.423 2189.81,684.806L2187.46,684.806C2186.47,686.575 2185.72,688.422 2185.25,690.326L2185.25,693.871Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2250.03,753.474C2244.78,757.36 2233.1,767.352 2238.02,776.169C2239.77,779.322 2241.76,781.002 2244.09,781.304C2244.34,781.337 2244.6,781.353 2244.85,781.353C2248.73,781.353 2252.56,777.577 2256.6,773.593C2258.28,771.937 2260.01,770.224 2261.76,768.788C2264.82,766.261 2268.3,763.397 2269.66,759.636C2269.82,759.195 2269.94,758.736 2270.02,758.27C2270.62,754.867 2268.95,750.676 2264.62,749.249C2260.49,747.887 2255.72,749.27 2250.03,753.474ZM2267.96,757.961C2267.89,758.32 2267.8,758.674 2267.68,759.013C2266.49,762.284 2263.24,764.965 2260.37,767.33C2258.55,768.823 2256.78,770.569 2255.07,772.256C2251.33,775.95 2247.46,779.785 2244.38,779.374C2242.8,779.168 2241.28,777.788 2239.88,775.273C2235.68,767.74 2246.5,758.585 2251.34,754.999C2255.23,752.12 2258.56,750.697 2261.43,750.697C2262.3,750.697 2263.13,750.828 2263.92,751.088C2267.32,752.207 2268.39,755.435 2267.96,757.961Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                         </g>
                         <rect x="2185.25" y="798.93" width="947.197" height="11.806" style="fill:rgb(191,116,15);fill-opacity:0.73;"/>
                         <rect x="2185.25" y="684.806" width="947.197" height="11.806" style="fill:rgb(255,178,35);fill-opacity:0.71;"/>
                     </g>
                     <g>
                         <rect x="2185.25" y="810.735" width="947.197" height="125.929" style="fill:rgb(227,134,34);"/>
                         <g>
                             <path d="M2294.42,891.735C2297.44,893.903 2300.86,896.36 2303.39,900.046C2303.95,900.848 2304.49,901.665 2305.04,902.488C2308.89,908.265 2312.87,914.238 2320.1,916.661C2326.09,918.67 2333.83,917.626 2339.34,914.062C2344.34,910.835 2347.66,905.677 2348.46,899.908C2350.2,887.368 2339.01,874.635 2323.52,871.522C2321.2,871.056 2318.6,870.824 2315.89,870.824C2306.76,870.824 2296.43,873.466 2291.72,878.678C2289.3,881.354 2288.23,883.512 2288.43,885.278C2288.7,887.638 2291.23,889.446 2294.42,891.735Z" style="fill:none;fill-rule:nonzero;"/>
                             <path d="M2538.04,839.307C2536.07,837.705 2532.87,836.128 2529.53,836.128C2528.73,836.128 2527.92,836.219 2527.12,836.422C2523.09,837.441 2520.8,840.808 2518.79,844.17L2496.41,881.722C2494.92,884.231 2493.73,886.482 2494.34,888.638C2495.33,892.144 2500.84,893.853 2505.37,893.434C2509.35,893.062 2513.08,891.504 2516.68,889.997C2517.52,889.645 2518.36,889.294 2519.2,888.957C2524.8,886.713 2538.14,886.204 2552.27,885.665C2566.31,885.129 2585.55,884.395 2586.31,881.244C2587.21,877.498 2577.62,872.748 2570.62,869.28C2567.15,867.56 2564.15,866.074 2562.37,864.77C2556.8,860.697 2552.84,855.796 2549.01,851.056C2545.59,846.816 2542.35,842.811 2538.04,839.307Z" style="fill:none;fill-rule:nonzero;"/>
                             <path d="M2247.15,877.419C2279.95,866.347 2306.59,850.363 2324.18,831.197C2330.08,824.762 2334.97,817.867 2338.8,810.736L2336.45,810.736C2332.78,817.418 2328.13,823.881 2322.58,829.933C2305.22,848.843 2278.89,864.63 2246.43,875.588C2226.77,882.225 2205.88,886.844 2185.25,891.022L2185.25,893.017C2206.09,888.8 2227.22,884.146 2247.15,877.419Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2257.74,831.733C2270.63,825.34 2281.29,818.317 2289.67,810.735L2286.62,810.735C2278.64,817.655 2268.68,824.101 2256.75,830.013C2234.64,840.981 2209.87,848.413 2185.25,855.119L2185.25,857.15C2210.16,850.374 2235.27,842.876 2257.74,831.733Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2188.65,810.735L2186.19,810.735C2185.88,811.192 2185.57,811.646 2185.25,812.099L2185.25,815.581C2186.45,813.993 2187.58,812.376 2188.65,810.735Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2382.83,810.735C2382.11,819.6 2383.91,828.708 2388.03,836.498C2393.72,847.245 2403.45,854.71 2415.43,857.517C2419.05,858.366 2422.68,858.757 2426.27,858.757C2445.8,858.757 2464.12,847.133 2473,834.098C2477.78,827.071 2480.92,819.303 2482.41,810.735L2480.28,810.735C2478.81,818.924 2475.8,826.346 2471.23,833.056C2461.11,847.908 2438.3,860.863 2415.94,855.627C2398.04,851.431 2382.58,833.867 2384.89,810.734L2382.83,810.734L2382.83,810.735Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2457.96,874.995C2434.77,887.331 2399.46,891.804 2379.72,875.791C2361.71,861.177 2364.77,837.239 2369.45,818.016C2370.04,815.598 2370.65,813.167 2371.26,810.736L2369.11,810.736C2368.53,813.023 2367.96,815.31 2367.4,817.586C2362.6,837.336 2359.5,861.968 2378.35,877.259C2387.71,884.855 2400.4,888.033 2413.69,888.033C2429.46,888.033 2446.08,883.561 2459,876.686C2487.01,861.786 2505.83,837.086 2510.82,810.736L2508.7,810.736C2503.72,836.399 2485.31,860.447 2457.96,874.995Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2417.53,812.021C2417.56,811.633 2417.58,811.196 2417.61,810.736L2415.51,810.736C2415.48,811.15 2415.46,811.549 2415.44,811.916L2416.49,812.003L2415.44,811.95C2415.15,816.856 2415.24,822.554 2418.03,827.62C2421.08,833.142 2427.15,836.876 2433.51,837.132C2433.74,837.141 2433.97,837.146 2434.2,837.146C2440.22,837.146 2446.45,834.015 2450.25,829.039C2453.79,824.411 2455.1,818.884 2456.26,814.008C2456.51,812.934 2456.77,811.841 2457.02,810.736L2454.88,810.736C2454.66,811.698 2454.43,812.651 2454.21,813.588C2453.04,818.513 2451.83,823.605 2448.54,827.911C2445,832.554 2439.12,835.402 2433.6,835.186C2427.99,834.96 2422.61,831.642 2419.9,826.732C2417.33,822.078 2417.26,816.702 2417.53,812.056L2417.53,812.021Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2479.11,936.664L2504.01,936.664C2495.67,936.251 2487.35,936.248 2479.11,936.664Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2608.33,930.101C2597.23,922.597 2582.97,917.141 2568.17,914.739C2538.37,909.902 2507.35,915.246 2480.61,920.635C2477.5,921.261 2474.39,921.899 2471.28,922.538C2446.19,927.685 2420.25,933.007 2394.18,932.269L2393.82,932.258C2386.79,932.058 2378.83,931.829 2373.93,936.075C2373.71,936.264 2373.53,936.466 2373.33,936.665L2376.5,936.665C2380.88,933.846 2387.69,934.032 2393.76,934.207L2394.12,934.217C2420.44,934.96 2446.52,929.614 2471.73,924.441C2474.84,923.803 2477.95,923.166 2481.05,922.54C2507.6,917.191 2538.38,911.881 2567.8,916.66C2588.03,919.943 2603.31,927.575 2613.62,936.666L2616.66,936.666C2614.17,934.364 2611.39,932.17 2608.33,930.101Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2247.55,921.949C2256.75,921.613 2278.55,922.069 2282.53,933.629C2282.87,934.639 2283.04,935.652 2283.05,936.665L2285.15,936.665C2285.14,935.458 2284.94,934.247 2284.52,933.036C2282.7,927.724 2275.46,918.975 2247.47,920.002C2226.66,920.77 2205.89,922.367 2185.25,924.73L2185.25,926.696C2205.92,924.322 2226.72,922.718 2247.55,921.949Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2293.14,893.278C2296.02,895.349 2299.29,897.695 2301.63,901.095C2302.17,901.889 2302.71,902.7 2303.26,903.516C2307.3,909.576 2311.47,915.843 2319.39,918.495C2321.69,919.267 2324.18,919.643 2326.71,919.643C2331.54,919.643 2336.51,918.269 2340.54,915.663C2346.02,912.123 2349.66,906.472 2350.54,900.158C2352.41,886.639 2340.49,872.938 2323.97,869.618C2310.4,866.893 2295.51,871.454 2290.11,877.426C2287.27,880.572 2286.07,883.134 2286.35,885.488C2286.72,888.673 2289.69,890.807 2293.14,893.278ZM2291.72,878.679C2296.43,873.467 2306.76,870.825 2315.89,870.825C2318.6,870.825 2321.2,871.057 2323.52,871.523C2339.01,874.635 2350.2,887.369 2348.46,899.909C2347.66,905.678 2344.34,910.836 2339.34,914.063C2333.83,917.627 2326.09,918.671 2320.1,916.662C2312.87,914.24 2308.89,908.266 2305.04,902.489C2304.49,901.666 2303.94,900.848 2303.39,900.047C2300.86,896.361 2297.44,893.904 2294.42,891.736C2291.22,889.447 2288.7,887.639 2288.43,885.279C2288.23,883.513 2289.3,881.354 2291.72,878.679Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2708.26,891.549C2691.31,874.451 2668.35,861.702 2643.6,855.648C2637.76,854.221 2631.58,853.038 2625.61,851.893C2607.16,848.359 2588.08,844.705 2576.26,833.393C2569.9,827.305 2566.39,819.733 2565.73,810.735L2563.61,810.735C2564.27,820.251 2567.99,828.274 2574.76,834.751C2587.03,846.492 2606.42,850.208 2625.18,853.801C2631.13,854.941 2637.28,856.119 2643.06,857.532C2667.44,863.493 2690.04,876.042 2706.72,892.869C2719.64,905.913 2728.55,921.098 2732.63,936.664L2734.78,936.664C2730.68,920.617 2721.55,904.965 2708.26,891.549Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M3062.14,888.843C3062.32,889.189 3062.7,889.391 3063.09,889.391C3063.24,889.391 3063.4,889.36 3063.54,889.293C3064.06,889.057 3064.28,888.474 3064.03,887.99C3051.81,864.679 3039.18,840.575 3018.53,820.597C3014.89,817.07 3011.07,813.779 3007.1,810.736L3003.77,810.736C3008.39,814.142 3012.83,817.884 3017.02,821.947C3037.44,841.704 3050,865.668 3062.14,888.843Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M3092.48,877.613C3092.6,878.054 3093.03,878.348 3093.5,878.348C3093.58,878.348 3093.67,878.338 3093.76,878.317C3094.32,878.185 3094.66,877.654 3094.51,877.133C3087.88,852.902 3071.98,829.639 3049.32,810.736L3046.01,810.736C3068.86,829.224 3085.59,852.417 3092.48,877.613Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2868.38,883.04C2851.94,864.075 2833.49,852.388 2813.54,848.303C2802.03,845.947 2789.03,846.713 2777.86,850.403C2765.91,854.354 2757.17,861.226 2753.25,869.754C2746.5,884.46 2754.61,900.819 2761.3,911.844C2762.43,913.719 2763.61,915.594 2764.78,917.476C2768.7,923.725 2772.68,930.098 2775.42,936.665L2777.66,936.665C2774.86,929.72 2770.69,923.041 2766.6,916.496C2765.43,914.622 2764.26,912.754 2763.12,910.887C2756.65,900.221 2748.79,884.433 2755.18,870.519C2763.26,852.93 2791.65,845.816 2813.09,850.206C2832.56,854.194 2850.61,865.652 2866.74,884.263C2880.46,900.091 2890.65,917.975 2896.81,936.664L2898.99,936.664C2892.78,917.533 2882.4,899.222 2868.38,883.04Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2866.13,929.507C2856.92,908.116 2843.42,890.799 2826.01,878.039C2815.81,870.57 2799.73,864.189 2787.94,870.787C2781.56,874.363 2778.09,880.926 2777.34,890.851C2776.16,906.518 2779.79,922.312 2787.65,936.665L2790,936.665C2781.96,922.393 2778.26,906.619 2779.43,890.987C2780.13,881.742 2783.27,875.681 2789.03,872.457C2799.89,866.374 2815.04,872.486 2824.71,879.571C2841.83,892.117 2855.11,909.162 2864.19,930.232C2865.1,932.363 2865.95,934.511 2866.77,936.666L2868.99,936.666C2868.1,934.267 2867.15,931.878 2866.13,929.507Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2809.6,895.567C2803.46,894.051 2795.33,895.889 2793.02,901.044C2790.65,906.329 2795.29,911.556 2799.02,915.756L2799.25,916.015C2804.86,922.337 2809.09,929.335 2811.92,936.664L2814.15,936.664C2811.24,928.888 2806.8,921.464 2800.87,914.775L2800.64,914.515C2797.09,910.521 2793.07,905.994 2794.95,901.794C2796.82,897.642 2803.89,896.172 2809.06,897.45C2814.74,898.854 2819.05,902.647 2822.52,906.172C2831.5,915.305 2838.33,925.683 2842.8,936.664L2845.05,936.664C2840.49,925.198 2833.41,914.365 2824.07,904.857C2820.4,901.124 2815.81,897.1 2809.6,895.567Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2617.93,818.925C2626.38,824.799 2638.11,826.883 2647.54,828.557C2673.07,833.094 2704.84,838.74 2722.29,857.203C2724.21,859.241 2727.31,862.518 2731.3,862.517C2731.73,862.517 2732.17,862.479 2732.62,862.398C2736.01,861.783 2738.14,859.013 2738.92,854.165L2743.51,825.847C2744.36,820.61 2745,815.488 2744.35,810.735L2742.22,810.735C2742.9,815.342 2742.28,820.386 2741.44,825.556L2736.85,853.874C2736.2,857.875 2734.68,860.038 2732.22,860.484C2729.03,861.067 2726.35,858.544 2723.86,855.917C2705.95,836.951 2673.78,831.235 2647.93,826.643C2638.72,825.006 2627.25,822.968 2619.19,817.364C2616.19,815.279 2613.99,813.042 2612.6,810.735L2610.2,810.735C2611.52,813.33 2613.9,816.124 2617.93,818.925Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M3086.18,914.578L3086.21,914.578C3086.79,914.564 3087.24,914.116 3087.23,913.578C3086.15,875.003 3064.15,836.734 3028.27,810.736L3024.85,810.736C3024.89,810.767 3024.93,810.796 3024.98,810.827C3061.56,836.485 3084.05,874.916 3085.13,913.629C3085.15,914.158 3085.61,914.578 3086.18,914.578Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2993.67,893.916C2986.69,888.643 2980.47,885.845 2974.11,885.111C2957.86,883.243 2944.07,895.772 2941.85,908.193C2940.1,917.985 2943.54,927.878 2947.63,936.664L2949.91,936.664C2945.68,927.764 2942.19,918.157 2943.92,908.512C2946.49,894.124 2961.75,885.653 2973.85,887.044C2979.81,887.732 2985.69,890.395 2992.34,895.427C3006.86,906.4 3016.72,921.061 3020.35,936.664L3022.52,936.664C3018.86,920.476 3008.69,905.265 2993.67,893.916Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M3045.96,936.664C3045.45,935.716 3044.96,934.761 3044.48,933.801C3044.48,933.756 3044.49,933.713 3044.48,933.667C3042.59,925.695 3039.32,917.985 3034.88,910.732C3034.55,909.84 3034.22,908.948 3033.9,908.06C3032,902.885 3030.03,897.534 3027.87,892.324C3022.99,880.575 3014.68,863.542 2998.86,851.335C2983.22,839.27 2961.62,834.073 2943.85,838.094C2919.09,843.694 2905.8,864.998 2903.93,882.423C2901.92,901.194 2909.38,919.962 2916.29,936.663L2918.54,936.663C2911.63,919.989 2904.03,901.194 2906.02,882.616C2907.82,865.854 2920.58,845.364 2944.35,839.988C2961.5,836.109 2982.37,841.151 2997.52,852.835C3012.97,864.75 3021.12,881.479 3025.91,893.025C3027.36,896.515 3028.72,900.075 3030.03,903.607C3017.83,887.367 2999.54,874.026 2977.72,866.206C2966.88,862.32 2958.18,861.511 2951.14,863.739C2942.63,866.431 2936.66,873.271 2932.91,884.649C2927.29,901.679 2926.55,919.454 2930.66,936.662L2932.82,936.662C2928.67,919.65 2929.35,902.063 2934.91,885.22C2938.45,874.483 2943.99,868.06 2951.82,865.584C2958.38,863.51 2966.6,864.31 2976.97,868.024C3001.38,876.773 3021.28,892.538 3032.96,911.519C3036.05,919.912 3039.35,928.479 3043.64,936.663L3045.96,936.663L3045.96,936.664Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2539.42,837.839C2535.68,834.804 2530.64,833.509 2526.56,834.542C2521.75,835.761 2519.08,839.663 2516.96,843.226L2494.58,880.778C2493.25,883.002 2491.44,886.047 2492.31,889.135C2493.48,893.259 2498.89,895.447 2503.98,895.447C2504.52,895.447 2505.05,895.422 2505.58,895.374C2509.9,894.97 2513.78,893.346 2517.54,891.775C2518.37,891.428 2519.2,891.081 2520.03,890.748C2525.28,888.647 2539.04,888.122 2552.36,887.614C2573.99,886.788 2587.3,886.06 2588.35,881.671C2589.61,876.477 2580.05,871.74 2571.61,867.561C2568.37,865.954 2565.3,864.436 2563.67,863.239C2558.32,859.323 2554.44,854.526 2550.69,849.886C2547.35,845.752 2543.9,841.478 2539.42,837.839ZM2562.37,864.77C2564.15,866.074 2567.15,867.56 2570.62,869.28C2577.62,872.748 2587.21,877.498 2586.31,881.244C2585.55,884.396 2566.31,885.129 2552.27,885.665C2538.14,886.204 2524.8,886.713 2519.2,888.957C2518.36,889.294 2517.52,889.645 2516.68,889.997C2513.08,891.504 2509.35,893.063 2505.37,893.434C2500.84,893.853 2495.33,892.144 2494.34,888.638C2493.73,886.482 2494.92,884.231 2496.41,881.722L2518.8,844.17C2520.8,840.808 2523.09,837.441 2527.12,836.422C2527.92,836.219 2528.73,836.128 2529.53,836.128C2532.87,836.128 2536.07,837.705 2538.04,839.307C2542.35,842.811 2545.59,846.817 2549.02,851.057C2552.85,855.796 2556.8,860.698 2562.37,864.77Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                         </g>
                         <rect x="2185.25" y="924.859" width="947.197" height="11.806" style="fill:rgb(191,116,15);fill-opacity:0.73;"/>
                         <rect x="2185.25" y="810.735" width="947.197" height="11.806" style="fill:rgb(255,178,35);fill-opacity:0.71;"/>
                     </g>
                     <g>
                         <rect x="2185.25" y="936.664" width="947.197" height="125.929" style="fill:rgb(227,134,34);"/>
                         <rect x="2185.25" y="1050.79" width="947.197" height="11.806" style="fill:rgb(191,116,15);fill-opacity:0.73;"/>
                         <g>
                             <path d="M3033.74,1015.44C3005.95,1020.89 2982.61,1039.38 2973.28,1062.59L2975.53,1062.59C2984.79,1040.32 3007.35,1022.61 3034.18,1017.34C3046.75,1014.88 3059.22,1015.02 3071.39,1016.99L3071.39,1015.01C3059.1,1013.05 3046.48,1012.94 3033.74,1015.44Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2905.39,1057.94C2887.83,1017.39 2899.47,969.476 2934.2,936.664L2931.26,936.664C2897.07,969.98 2885.82,1017.97 2903.45,1058.67C2904.02,1059.98 2904.62,1061.29 2905.24,1062.59L2907.54,1062.59C2906.79,1061.05 2906.07,1059.5 2905.39,1057.94Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2845.55,1062.59L2847.79,1062.59C2849.74,1057.91 2851.8,1053.2 2853.82,1048.62C2862.55,1028.77 2871.57,1008.25 2871.9,986.952C2872.11,972.871 2867.07,958.158 2858.05,946.586C2855.58,943.418 2852.4,939.958 2848.44,936.665L2844.96,936.665C2859.41,947.403 2870.14,964.758 2869.8,986.925C2869.48,1007.85 2860.53,1028.2 2851.88,1047.88C2849.76,1052.7 2847.59,1057.66 2845.55,1062.59Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2822.54,1054.31C2821.21,1057.05 2819.86,1059.81 2818.54,1062.59L2820.84,1062.59C2822.03,1060.09 2823.24,1057.59 2824.45,1055.12C2830.68,1042.35 2837.12,1029.15 2840.07,1015.45C2845.98,987.942 2836,959.174 2815.45,936.664L2812.69,936.664C2833.63,958.907 2843.91,987.633 2838.01,1015.07C2835.11,1028.55 2828.72,1041.65 2822.54,1054.31Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2930.15,1027.27C2930.03,1005.12 2935.23,964.725 2970.21,936.665L2967.03,936.665C2933.04,965.151 2927.93,1005.17 2928.06,1027.28C2928.12,1039.09 2929.5,1050.88 2931.6,1062.59L2933.72,1062.59C2931.62,1050.86 2930.22,1039.06 2930.15,1027.27Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2988.57,968.759C2988.01,969.316 2987.41,969.882 2986.81,970.455C2982,975.056 2976.54,980.271 2978.95,986.146C2980.75,990.562 2986.4,992.779 2991.2,993.018C2991.57,993.036 2991.94,993.045 2992.31,993.045C2996.35,993.045 3000.71,991.975 3005.61,989.789C3026.35,980.53 3038.18,959.591 3033.11,941.082C3032.97,940.561 3032.4,940.244 3031.84,940.378C3031.28,940.511 3030.94,941.041 3031.08,941.562C3035.9,959.19 3024.56,979.167 3004.7,988.032C2999.67,990.275 2995.3,991.27 2991.31,991.071C2986.44,990.829 2982.16,988.519 2980.91,985.454C2978.98,980.739 2983.73,976.202 2988.32,971.814C2988.92,971.234 2989.52,970.662 2990.1,970.098C2999.45,960.9 3003.45,948.374 3001.24,936.665L2999.11,936.665C3001.36,947.871 2997.55,959.922 2988.57,968.759Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2988.95,936.664L2986.41,936.664C2978.63,947.075 2971.62,957.913 2965.53,968.974C2961.21,976.81 2957.39,985.886 2961.81,993.779C2965.22,999.89 2973.66,1004.35 2983.29,1005.14C2984.48,1005.24 2985.69,1005.29 2986.91,1005.29C2993.57,1005.29 3000.79,1003.84 3008.84,1000.89C3036.82,990.65 3057.34,969.276 3062.39,945.11C3062.5,944.582 3062.13,944.07 3061.56,943.968C3061,943.864 3060.44,944.21 3060.33,944.739C3055.42,968.257 3035.39,989.079 3008.08,999.078C2998.85,1002.46 2990.8,1003.81 2983.48,1003.2C2974.54,1002.47 2966.76,998.415 2963.67,992.88C2959.69,985.774 2963.32,977.256 2967.39,969.862C2973.66,958.486 2980.9,947.342 2988.95,936.664Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2727.39,1012.43C2731.56,1004.77 2738.8,998.269 2745.81,991.977C2753.6,984.983 2761.65,977.751 2765.67,968.809C2769.55,960.158 2768.76,950.343 2763.44,941.172C2762.53,939.603 2761.5,938.103 2760.38,936.664L2757.77,936.664C2759.2,938.371 2760.49,940.185 2761.6,942.097C2766.61,950.742 2767.37,959.962 2763.73,968.059C2759.88,976.63 2751.99,983.72 2744.35,990.576C2737.22,996.98 2729.84,1003.6 2725.52,1011.56C2716.66,1027.87 2722.03,1045.67 2730.17,1062.59L2732.47,1062.59C2724.32,1045.85 2718.8,1028.26 2727.39,1012.43Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2556.08,1047.79C2546.25,1029.68 2523.64,1016.39 2486.95,1007.15C2480.68,1005.57 2474.2,1004.1 2467.94,1002.68C2446.33,997.776 2423.97,992.704 2405.77,982.153C2387.16,971.368 2374.22,954.637 2369.24,936.665L2367.08,936.665C2372.1,955.297 2385.42,972.659 2404.66,983.806C2423.15,994.523 2445.67,999.633 2467.44,1004.58C2473.69,1005.99 2480.15,1007.46 2486.4,1009.03C2521.98,1018 2544.79,1031.33 2554.2,1048.66C2556.6,1053.08 2558.05,1057.79 2558.64,1062.6L2560.74,1062.6C2560.15,1057.49 2558.62,1052.48 2556.08,1047.79Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2429.79,1062.59L2432.31,1062.59C2433.52,1060.85 2434.45,1059.02 2435.03,1057.13C2438.95,1044.37 2427.78,1032.34 2417.01,1025.91C2409.86,1021.64 2401.72,1018.48 2393.84,1015.42C2388.61,1013.39 2383.2,1011.29 2378.15,1008.89C2347.02,994.095 2327.48,965.34 2328.03,936.665L2325.93,936.665C2325.39,966.059 2345.37,995.499 2377.2,1010.63C2382.32,1013.06 2387.76,1015.18 2393.03,1017.22C2400.82,1020.25 2408.88,1023.37 2415.88,1027.55C2426.04,1033.62 2436.62,1044.87 2433.01,1056.6C2432.37,1058.69 2431.28,1060.7 2429.79,1062.59Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2671.93,1062.59C2676.21,1051.96 2676.08,1039.11 2671.48,1024.24C2669.5,1017.8 2666.89,1011.4 2664.37,1005.2C2660.76,996.322 2657.02,987.139 2655.11,977.823C2651.73,961.311 2655.4,946.595 2665.15,936.665L2662.36,936.665C2653.08,947.008 2649.69,961.763 2653.05,978.188C2654.99,987.67 2658.76,996.934 2662.41,1005.89C2664.91,1012.05 2667.51,1018.42 2669.47,1024.78C2674.03,1039.56 2674.07,1052.22 2669.66,1062.6L2671.93,1062.6L2671.93,1062.59Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2603.98,1051.09C2603.67,1049.24 2603.39,1047.36 2603.11,1045.47C2601.13,1032.16 2599.08,1018.39 2587,1009.3C2580.55,1004.45 2572.18,1001.84 2564.09,999.311C2558.64,997.609 2553,995.849 2548.02,993.425C2535.07,987.118 2525.35,975.658 2520.65,961.157C2518.05,953.16 2517.08,944.909 2516.73,936.664L2514.63,936.664C2514.98,945.067 2515.97,953.497 2518.64,961.719C2523.5,976.726 2533.59,988.599 2547.05,995.152C2552.17,997.647 2557.89,999.432 2563.42,1001.16C2571.7,1003.74 2579.53,1006.19 2585.68,1010.82C2597.12,1019.42 2599.11,1032.8 2601.03,1045.74C2601.32,1047.64 2601.6,1049.53 2601.91,1051.39C2602.3,1053.77 2603.18,1057.94 2604.97,1062.59L2607.19,1062.59C2605.3,1057.83 2604.38,1053.5 2603.98,1051.09Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2565.55,936.664C2564.89,942.35 2567.03,948.645 2571.79,954.61C2575.84,959.677 2581.19,964.007 2586.37,968.194C2588.03,969.536 2589.69,970.876 2591.31,972.241C2597.05,977.089 2603.92,983.656 2606,991.474C2606.68,994.029 2606.84,996.61 2607.01,999.343C2607.16,1001.74 2607.32,1004.22 2607.86,1006.65C2608.96,1011.61 2612.57,1017.5 2619.37,1019.15C2620.6,1019.45 2621.82,1019.59 2623.03,1019.59C2628.63,1019.59 2633.87,1016.65 2636.65,1013.16C2639.72,1009.3 2640.94,1004.33 2640.37,997.967C2639.6,989.197 2636,980.768 2632.52,972.615C2630.79,968.554 2629,964.354 2627.59,960.182C2625.63,954.393 2623.56,945.319 2625.71,936.664L2623.57,936.664C2621.49,945.609 2623.58,954.838 2625.59,960.767C2627.02,965.007 2628.83,969.24 2630.57,973.334C2633.99,981.353 2637.53,989.646 2638.28,998.127C2638.8,1004 2637.72,1008.54 2634.96,1012.01C2632.08,1015.63 2625.82,1018.7 2619.9,1017.27C2614.02,1015.84 2610.89,1010.64 2609.91,1006.26C2609.4,1003.96 2609.25,1001.56 2609.1,999.229C2608.93,996.528 2608.76,993.734 2608.03,991.005C2605.82,982.676 2598.68,975.83 2592.72,970.797C2591.09,969.423 2589.42,968.073 2587.75,966.723C2582.65,962.604 2577.39,958.345 2573.47,953.448C2568.98,947.813 2566.99,941.915 2567.67,936.663L2565.55,936.663L2565.55,936.664Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2492.49,1042.67C2476.02,1026.32 2453.59,1014.55 2431.91,1003.18C2421.24,997.578 2410.21,991.79 2400.02,985.529C2380.29,973.406 2361.2,955.914 2353.62,936.665L2351.27,936.665C2353.12,941.562 2355.76,946.506 2359.2,951.486C2370.4,967.704 2386.95,979.835 2398.86,987.155C2409.11,993.455 2420.18,999.26 2430.88,1004.87C2452.43,1016.18 2474.71,1027.87 2490.96,1044C2497.64,1050.64 2501.49,1056.86 2502.48,1062.6L2504.6,1062.6C2503.62,1056.38 2499.58,1049.71 2492.49,1042.67Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                             <path d="M2280.61,1049.04C2294.2,1049.72 2305.49,1047 2312.39,1041.39C2325.58,1030.66 2320.72,1011.99 2309.45,999.864C2304.55,994.599 2298.65,989.88 2292.94,985.317C2285.87,979.661 2278.56,973.813 2273.27,966.975C2267.57,959.586 2265.28,950.152 2267.3,942.354C2267.84,940.281 2268.69,938.383 2269.79,936.664L2267.37,936.664C2266.46,938.278 2265.75,940.024 2265.27,941.897C2263.1,950.23 2265.52,960.275 2271.57,968.111C2277,975.138 2284.41,981.063 2291.58,986.794C2297.22,991.311 2303.07,995.982 2307.86,1001.14C2318.47,1012.55 2323.18,1030.02 2311.01,1039.92C2302.26,1047.04 2288.28,1047.47 2280.72,1047.1C2275.59,1046.84 2270.39,1046.18 2265.37,1045.54C2260.81,1044.96 2256.14,1044.37 2251.44,1044.06L2251.44,1046.01C2256.03,1046.32 2260.61,1046.9 2265.08,1047.47C2270.15,1048.11 2275.39,1048.78 2280.61,1049.04Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
                         </g>
                         <rect x="2185.25" y="936.664" width="947.197" height="11.806" style="fill:rgb(255,178,35);fill-opacity:0.71;"/>
                     </g>
                 </g>
             </g>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M2410.05,1037.75L2298.54,484.435L2175.09,509.314L2281.59,1037.75L2410.05,1037.75Z" style="fill:rgb(227,134,34);fill-rule:nonzero;"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M2293.63,1037.75L2186.66,506.982L2175.09,509.314L2281.59,1037.75L2293.63,1037.75Z" style="fill:rgb(191,116,15);fill-opacity:0.73;fill-rule:nonzero;"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M2331.37,1030.86C2336.1,1032.32 2340.66,1034.82 2345.1,1037.75L2348.71,1037.75C2343.33,1034 2337.76,1030.67 2331.86,1028.85C2317.28,1024.36 2303.61,1029.49 2290.89,1037.75L2294.83,1037.75C2306.35,1030.83 2318.56,1026.91 2331.37,1030.86Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
             <path d="M2262.87,869.901C2278.67,856.694 2287.23,831.902 2289.05,794.108C2289.36,787.649 2289.52,781.012 2289.67,774.594C2290.21,752.436 2290.77,729.523 2297.51,709.591C2304.41,689.217 2318.26,673.232 2334.89,664.794L2334.46,662.677C2317.19,671.281 2302.8,687.771 2295.67,708.83C2288.82,729.074 2288.26,752.156 2287.72,774.48C2287.56,780.885 2287.4,787.509 2287.09,793.941C2285.34,830.594 2276.77,855.591 2261.64,868.239C2257.78,871.459 2253.45,873.816 2248.86,875.339L2249.28,877.402C2254.16,875.811 2258.77,873.322 2262.87,869.901Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
             <path d="M2256.84,729.257C2259.61,721.403 2261.1,712.794 2262.54,704.468C2263.5,698.94 2264.49,693.223 2265.84,687.801C2274.2,654.357 2298.53,629.525 2326.75,624.397L2326.33,622.344C2297.41,627.62 2272.5,653.016 2263.95,687.209C2262.58,692.707 2261.58,698.464 2260.62,704.032C2259.19,712.27 2257.72,720.787 2255.01,728.472C2251.07,739.628 2242.13,752.224 2229.92,751.005C2227.74,750.788 2225.56,750.112 2223.4,749.034L2223.9,751.506C2225.85,752.347 2227.82,752.892 2229.79,753.089C2243.08,754.412 2252.66,741.079 2256.84,729.257Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
             <path d="M2393.35,954.872L2392.8,952.132C2380.83,945.084 2365.69,944.671 2350.25,951.213C2341.34,954.99 2333.01,960.516 2324.94,965.861C2319.4,969.534 2313.67,973.331 2307.83,976.512C2294.24,983.905 2281.83,986.447 2270.79,984.168L2271.24,986.392C2282.51,988.489 2295.08,985.822 2308.75,978.381C2314.67,975.162 2320.43,971.342 2326.01,967.647C2334,962.349 2342.26,956.87 2351.02,953.16C2366.54,946.583 2381.69,947.272 2393.35,954.872Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
             <path d="M2269.1,917.513C2270.85,916.847 2272.63,916.201 2274.43,915.552C2287.09,910.981 2300.18,906.254 2306.7,892.615C2310.18,885.337 2311.09,876.615 2311.97,868.182C2312.56,862.501 2313.17,856.626 2314.57,851.268C2318.19,837.323 2327.5,825.528 2340.79,818.055C2348.12,813.934 2356.01,811.35 2364.03,809.378L2363.61,807.319C2355.45,809.324 2347.38,811.961 2339.84,816.198C2326.09,823.933 2316.45,836.169 2312.68,850.656C2311.25,856.173 2310.63,862.132 2310.03,867.893C2309.13,876.523 2308.28,884.675 2304.96,891.622C2298.78,904.533 2286.06,909.127 2273.76,913.569C2271.95,914.222 2270.16,914.871 2268.39,915.541C2266.14,916.4 2262.23,918.088 2258.01,920.754L2258.45,922.937C2262.75,920.138 2266.81,918.383 2269.1,917.513Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
             <path d="M2361.91,920.853C2358.03,923.095 2354.24,925.702 2350.57,928.223C2343.39,933.161 2335.95,938.266 2327.79,940.68C2322.14,942.35 2317.47,942.181 2313.53,940.164C2309.41,938.057 2305.16,932.531 2305.39,926.443C2305.63,920.398 2310.11,916.295 2314.22,914.47C2316.36,913.517 2318.69,912.895 2320.94,912.293C2323.56,911.594 2326.26,910.871 2328.8,909.619C2336.52,905.799 2341.82,897.454 2345.58,890.612C2346.6,888.744 2347.6,886.841 2348.59,884.936C2351.62,879.128 2354.76,873.122 2358.78,868.321C2363.42,862.797 2368.81,859.68 2374.09,859.316L2373.67,857.239C2367.97,857.711 2362.22,861.052 2357.31,866.899C2353.14,871.866 2349.96,877.972 2346.88,883.877C2345.89,885.768 2344.9,887.659 2343.88,889.514C2340.27,896.105 2335.18,904.13 2327.93,907.715C2325.56,908.887 2323.06,909.555 2320.42,910.263C2318.1,910.883 2315.7,911.524 2313.42,912.535C2308.78,914.597 2303.72,919.295 2303.44,926.295C2303.39,927.55 2303.5,928.779 2303.74,929.962C2304.85,935.458 2308.76,940.015 2312.72,942.045C2317.12,944.293 2322.23,944.506 2328.36,942.696C2336.8,940.201 2344.36,935.011 2351.66,929.991C2355.3,927.49 2359.06,924.904 2362.87,922.697C2368.16,919.635 2376.65,915.815 2385.56,916.213L2385.13,914.107C2375.95,913.838 2367.32,917.716 2361.91,920.853Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
             <path d="M2255.32,806.558C2268.1,787.183 2275.2,762.873 2282.07,739.364C2285.45,727.801 2288.94,715.843 2293.07,704.618C2301.06,682.888 2314.43,660.715 2331.8,649.483L2331.34,647.181C2326.9,649.962 2322.58,653.52 2318.38,657.876C2304.69,672.066 2296.07,690.686 2291.25,703.806C2287.1,715.098 2283.59,727.091 2280.2,738.688C2273.38,762.048 2266.32,786.202 2253.72,805.319C2248.53,813.18 2243.19,818.176 2237.76,820.283L2238.18,822.363C2244.08,820.175 2249.83,814.897 2255.32,806.558Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
             <path d="M2207.22,600.111C2209.24,613.57 2214.13,624.102 2220.99,629.756C2234.12,640.564 2251.46,632.108 2261.12,618.665C2265.32,612.827 2268.78,606.11 2272.12,599.614C2276.27,591.562 2280.56,583.238 2286.22,576.708C2292.33,569.653 2301.13,565.547 2309.17,565.993C2311.31,566.111 2313.34,566.554 2315.24,567.294L2314.76,564.936C2313,564.363 2311.15,564.012 2309.22,563.905C2300.62,563.428 2291.25,567.781 2284.76,575.263C2278.95,581.973 2274.61,590.409 2270.4,598.565C2267.09,604.995 2263.67,611.644 2259.56,617.361C2250.47,630.016 2234.27,638.085 2222.16,628.112C2213.45,620.942 2210.27,607.326 2209.15,599.836C2208.38,594.752 2208.01,589.529 2207.64,584.479C2207.31,579.901 2206.97,575.2 2206.34,570.534L2204.42,570.92C2205.03,575.475 2205.37,580.087 2205.69,584.581C2206.06,589.675 2206.44,594.942 2207.22,600.111Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M2410.05,1037.75L2298.54,484.435L2286.97,486.767L2398.01,1037.75L2410.05,1037.75Z" style="fill:rgb(255,178,35);fill-opacity:0.71;fill-rule:nonzero;"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M2908.11,1037.75L3019.63,484.435L3143.07,509.314L3036.58,1037.75L2908.11,1037.75Z" style="fill:rgb(227,134,34);fill-rule:nonzero;"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M3024.53,1037.75L3131.5,506.982L3143.07,509.314L3036.58,1037.75L3024.53,1037.75Z" style="fill:rgb(255,178,35);fill-opacity:0.71;fill-rule:nonzero;"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M3023.34,1037.75L3027.28,1037.75C3014.55,1029.49 3000.89,1024.36 2986.3,1028.85C2980.41,1030.67 2974.84,1034 2969.46,1037.75L2973.06,1037.75C2977.5,1034.82 2982.07,1032.32 2986.8,1030.86C2999.6,1026.91 3011.82,1030.83 3023.34,1037.75Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
             <path d="M3056.53,868.238C3041.39,855.591 3032.83,830.593 3031.07,793.94C3030.76,787.508 3030.6,780.884 3030.45,774.479C3029.91,752.155 3029.34,729.073 3022.49,708.829C3015.37,687.77 3000.98,671.28 2983.7,662.676L2983.28,664.793C2999.91,673.231 3013.76,689.216 3020.65,709.59C3027.4,729.522 3027.95,752.434 3028.49,774.593C3028.65,781.01 3028.81,787.648 3029.12,794.107C3030.93,831.902 3039.49,856.693 3055.3,869.9C3059.39,873.322 3064,875.81 3068.89,877.401L3069.31,875.338C3064.72,873.815 3060.38,871.458 3056.53,868.238Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
             <path d="M3063.16,728.471C3060.45,720.786 3058.97,712.269 3057.55,704.031C3056.58,698.463 3055.59,692.706 3054.21,687.208C3045.67,653.016 3020.75,627.62 2991.83,622.343L2991.42,624.396C3019.64,629.524 3043.97,654.356 3052.32,687.8C3053.68,693.221 3054.66,698.938 3055.62,704.467C3057.06,712.793 3058.55,721.402 3061.33,729.256C3065.5,741.078 3075.09,754.412 3088.37,753.086C3090.34,752.889 3092.32,752.345 3094.26,751.503L3094.76,749.031C3092.61,750.109 3090.42,750.785 3088.25,751.002C3076.03,752.223 3067.1,739.626 3063.16,728.471Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
             <path d="M3010.34,976.512C3004.49,973.331 2998.76,969.534 2993.22,965.861C2985.16,960.516 2976.82,954.989 2967.91,951.213C2952.48,944.671 2937.34,945.084 2925.37,952.132L2924.82,954.872C2936.48,947.271 2951.63,946.582 2967.15,953.159C2975.9,956.87 2984.17,962.349 2992.16,967.646C2997.73,971.341 3003.5,975.161 3009.41,978.38C3023.09,985.821 3035.65,988.489 3046.93,986.391L3047.37,984.167C3036.33,986.447 3023.93,983.905 3010.34,976.512Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
             <path d="M3049.77,915.54C3048.01,914.87 3046.21,914.221 3044.4,913.568C3032.1,909.126 3019.38,904.532 3013.21,891.621C3009.88,884.673 3009.03,876.522 3008.14,867.892C3007.54,862.13 3006.92,856.172 3005.48,850.655C3001.72,836.169 2992.07,823.932 2978.32,816.197C2970.79,811.96 2962.72,809.323 2954.55,807.318L2954.14,809.377C2962.15,811.349 2970.05,813.933 2977.38,818.055C2990.66,825.527 2999.97,837.323 3003.6,851.268C3004.99,856.626 3005.6,862.501 3006.19,868.182C3007.07,876.616 3007.98,885.337 3011.46,892.615C3017.98,906.254 3031.07,910.981 3043.73,915.552C3045.53,916.201 3047.32,916.847 3049.07,917.513C3051.36,918.383 3055.41,920.138 3059.71,922.936L3060.15,920.753C3055.94,918.087 3052.03,916.399 3049.77,915.54Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
             <path d="M2955.29,922.698C2959.1,924.905 2962.87,927.491 2966.51,929.992C2973.81,935.012 2981.36,940.202 2989.81,942.697C2995.93,944.507 3001.05,944.294 3005.44,942.046C3009.41,940.016 3013.32,935.458 3014.43,929.963C3014.66,928.78 3014.77,927.551 3014.72,926.296C3014.45,919.296 3009.39,914.598 3004.74,912.536C3002.47,911.525 3000.07,910.884 2997.75,910.264C2995.1,909.556 2992.6,908.889 2990.23,907.716C2982.98,904.131 2977.9,896.106 2974.28,889.515C2973.26,887.66 2972.28,885.769 2971.29,883.878C2968.21,877.973 2965.02,871.868 2960.85,866.9C2955.95,861.053 2950.2,857.712 2944.49,857.24L2944.08,859.317C2949.36,859.681 2954.75,862.798 2959.38,868.322C2963.41,873.123 2966.55,879.129 2969.58,884.937C2970.57,886.841 2971.56,888.744 2972.59,890.613C2976.34,897.455 2981.64,905.8 2989.37,909.62C2991.9,910.871 2994.61,911.595 2997.22,912.294C2999.47,912.896 3001.8,913.518 3003.95,914.471C3008.06,916.295 3012.53,920.398 3012.77,926.444C3013.01,932.532 3008.76,938.058 3004.64,940.165C3000.7,942.182 2996.03,942.351 2990.38,940.681C2982.21,938.267 2974.78,933.162 2967.6,928.224C2963.93,925.703 2960.14,923.096 2956.26,920.854C2950.85,917.718 2942.21,913.84 2933.03,914.11L2932.61,916.216C2941.52,915.816 2950,919.636 2955.29,922.698Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
             <path d="M3064.45,805.32C3051.85,786.203 3044.79,762.049 3037.97,738.689C3034.58,727.091 3031.07,715.098 3026.92,703.807C3022.1,690.686 3013.47,672.066 2999.79,657.877C2995.59,653.521 2991.26,649.963 2986.83,647.182L2986.36,649.484C3003.74,660.716 3017.11,682.889 3025.1,704.619C3029.22,715.844 3032.72,727.801 3036.1,739.365C3042.97,762.874 3050.07,787.183 3062.84,806.559C3068.34,814.898 3074.08,820.176 3079.98,822.365L3080.4,820.285C3074.98,818.176 3069.63,813.181 3064.45,805.32Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
             <path d="M3031.95,576.708C3037.61,583.238 3041.9,591.563 3046.04,599.614C3049.39,606.11 3052.85,612.826 3057.04,618.665C3066.7,632.108 3084.05,640.564 3097.17,629.756C3104.03,624.102 3108.93,613.571 3110.95,600.111C3111.72,594.942 3112.11,589.675 3112.47,584.581C3112.8,580.087 3113.13,575.475 3113.74,570.92L3111.83,570.534C3111.2,575.201 3110.85,579.901 3110.52,584.479C3110.16,589.529 3109.78,594.752 3109.02,599.836C3107.89,607.326 3104.71,620.942 3096.01,628.112C3083.9,638.085 3067.7,630.016 3058.61,617.361C3054.5,611.644 3051.07,604.996 3047.76,598.565C3043.56,590.408 3039.22,581.973 3033.4,575.263C3026.91,567.781 3017.55,563.428 3008.95,563.905C3007.01,564.012 3005.16,564.363 3003.4,564.936L3002.93,567.294C3004.83,566.554 3006.86,566.112 3008.99,565.993C3017.04,565.547 3025.84,569.653 3031.95,576.708Z" style="fill:rgb(199,120,9);fill-rule:nonzero;"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M2908.11,1037.75L3019.63,484.435L3031.2,486.767L2920.16,1037.75L2908.11,1037.75Z" style="fill:rgb(255,178,35);fill-opacity:0.71;fill-rule:nonzero;"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M3124.96,590.5L3143.37,502.407L2174.34,502.407L2192.75,590.5L3124.96,590.5Z" style="fill:rgb(114,62,0);fill-opacity:0.71;fill-rule:nonzero;"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M3168.53,558.877L2149.18,558.877C2133.76,558.877 2121.25,546.376 2121.25,530.955L2121.25,417.388C2121.25,401.967 2133.76,389.466 2149.18,389.466L3168.53,389.466C3183.95,389.466 3196.45,401.967 3196.45,417.388L3196.45,530.955C3196.45,546.376 3183.95,558.877 3168.53,558.877Z" style="fill:rgb(214,126,26);fill-rule:nonzero;"/>
             <g>
                 <path d="M2913.58,512.943C2913.04,507.62 2911.42,491.421 2901.07,487.344C2898.32,486.258 2895.37,485.714 2892.28,485.714C2890.69,485.714 2889.05,485.86 2887.38,486.151C2879.28,487.564 2872.79,492.038 2870.56,495.726C2866.95,501.71 2869.61,508.226 2871.96,513.975C2873.28,517.222 2874.66,520.579 2878.39,523.417C2882.41,526.478 2889.08,528.355 2895.34,528.213C2901.72,528.064 2907.82,525.876 2910.89,522.64C2913.8,519.572 2913.88,516.027 2913.58,512.965L2913.58,512.943Z" style="fill:none;fill-rule:nonzero;"/>
                 <path d="M2664.95,497.378C2663.98,497.378 2663.01,497.398 2662.04,497.438C2656.73,497.657 2651.65,498.551 2646.26,499.496C2640.95,500.428 2635.46,501.392 2629.7,501.66C2620.26,502.099 2610.91,501.432 2601.86,500.787C2595.11,500.305 2588.12,499.808 2581.2,499.781C2581.05,499.781 2580.89,499.78 2580.74,499.78C2566.99,499.78 2555.43,502.083 2549.76,505.963C2543.42,510.297 2544.38,515.958 2546.03,521.866L2551.24,540.535C2551.97,543.173 2553.69,544.598 2556.49,544.893C2560.1,545.278 2563.15,543.614 2565.97,541.882C2586.3,529.379 2622.8,525.611 2652.13,522.583C2662.59,521.503 2675.6,520.16 2684.75,516.465C2692.82,513.205 2695.86,509.387 2693.31,505.714C2689.93,500.862 2677.82,497.378 2664.95,497.378Z" style="fill:none;fill-rule:nonzero;"/>
                 <path d="M3106.81,479.907C3109.46,478.87 3112.21,477.797 3114.49,476.584C3119.11,474.126 3120.69,471.278 3118.62,469.153C3116.52,466.992 3111.12,466.072 3104.53,466.751C3096.78,467.551 3078.36,476.866 3071.99,480.616C3065.52,484.42 3060.16,490.471 3064.48,496.048C3068.12,500.738 3080.78,504.505 3091.62,502.529C3086.2,496.014 3089.41,488.053 3099.68,482.935C3101.89,481.831 3104.39,480.853 3106.81,479.907Z" style="fill:none;fill-rule:nonzero;"/>
                 <path d="M2189.06,485.205C2185.8,485.205 2182.02,486.143 2177.61,488.041C2172.11,490.405 2159.84,496.44 2164.6,501.406C2166.19,503.064 2167.91,503.974 2169.71,504.11C2173.21,504.381 2177.59,501.853 2181.84,499.418C2183.78,498.306 2185.79,497.155 2187.85,496.171C2191.11,494.612 2194.8,492.844 2196.14,490.688C2196.28,490.465 2196.39,490.231 2196.46,489.995C2196.96,488.33 2195.74,486.202 2191.88,485.464C2190.99,485.291 2190.05,485.205 2189.06,485.205Z" style="fill:none;fill-rule:nonzero;"/>
                 <path d="M3065.44,440.958C3030.55,444.939 3001.84,459.43 2994,477.018C2986.94,492.847 2996.48,511.003 3019.51,525.585C3039.47,538.22 3069.7,548.758 3106.92,556.057C3112.09,557.071 3117.35,557.994 3122.64,558.878L3130.91,558.878C3123.07,557.658 3115.32,556.336 3107.74,554.85C3070.9,547.626 3041.02,537.218 3021.33,524.752C2998.79,510.482 2989.43,492.75 2996.32,477.319C3003.95,460.203 3031.92,446.097 3065.93,442.216C3113.62,436.775 3160.05,450.553 3196.45,464.349L3196.45,462.784C3159.85,449.01 3113.41,435.485 3065.44,440.958Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M3193.28,543.876C3160.18,538.741 3125.96,533.43 3096.02,524.804C3069.87,517.271 3051.94,508.433 3042.73,498.537C3031.69,486.67 3034.91,472.981 3050.76,464.475C3069.79,454.264 3113.22,449.822 3156.24,461.7C3171.08,465.798 3184.15,471.113 3196.45,476.757L3196.45,475.071C3184.49,469.639 3171.75,464.54 3157.32,460.556C3140.21,455.831 3119.44,453.212 3100.33,453.393C3085.34,453.529 3064.29,455.403 3049.09,463.564C3032.5,472.462 3029.1,486.748 3040.61,499.114C3050.04,509.251 3068.31,518.276 3094.9,525.937C3125.06,534.624 3159.39,539.953 3192.6,545.106C3192.84,544.702 3193.06,544.292 3193.28,543.876Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M3101.29,483.878C3103.4,482.827 3105.84,481.872 3108.2,480.948C3110.93,479.883 3113.75,478.781 3116.16,477.499C3121.55,474.628 3123.3,471.201 3120.73,468.556C3118.12,465.874 3111.74,464.7 3104.09,465.489C3094.45,466.483 3073.88,477.599 3070.23,479.747C3061.35,484.974 3058.3,491.411 3062.28,496.545C3066.18,501.575 3076.56,504.276 3085.72,504.276C3088.58,504.276 3091.32,504.014 3093.71,503.477C3094.01,503.408 3094.25,503.274 3094.37,503.107C3094.48,502.94 3094.46,502.753 3094.32,502.594C3088.59,496.476 3091.46,488.78 3101.29,483.878ZM3091.62,502.528C3080.78,504.504 3068.11,500.737 3064.48,496.047C3060.16,490.47 3065.52,484.419 3071.99,480.615C3078.36,476.865 3096.78,467.551 3104.53,466.75C3111.12,466.071 3116.52,466.991 3118.62,469.152C3120.69,471.277 3119.1,474.124 3114.49,476.583C3112.21,477.796 3109.46,478.869 3106.81,479.906C3104.39,480.852 3101.89,481.83 3099.68,482.933C3089.41,488.053 3086.2,496.013 3091.62,502.528Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M3178.57,487.734C3183.18,487.59 3187.47,488.619 3192.19,489.878C3193.62,490.258 3195.03,490.655 3196.45,491.038L3196.45,489.599C3195.38,489.311 3194.32,489.012 3193.24,488.726C3188.39,487.434 3183.62,486.288 3178.43,486.453C3170.31,486.708 3164.5,490.172 3162.95,493.463C3161.25,497.056 3163.43,500.797 3165.51,503.654C3171.83,512.327 3182.61,520.308 3196.45,526.91L3196.45,525.197C3183.65,518.878 3173.67,511.34 3167.73,503.184C3165.65,500.339 3163.74,496.998 3165.25,493.78C3166.59,490.941 3171.59,487.954 3178.57,487.734Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2879.4,422.161C2879.04,435.957 2868.89,449.374 2859.07,462.349C2856.13,466.232 2853.09,470.247 2850.36,474.213C2834.87,496.698 2834.97,514.128 2850.64,527.498C2860.71,536.091 2881.51,543.755 2903.67,543.755C2907.74,543.755 2911.86,543.497 2915.96,542.937C2929.56,541.086 2940.6,536.165 2947.05,529.08C2952.87,522.692 2954.62,514.957 2951.85,507.86C2948.54,499.362 2940.68,491.386 2933.07,483.672C2928.38,478.904 2923.52,473.973 2919.79,468.978C2900,442.43 2912.89,411.084 2951.68,389.466L2948.37,389.466C2910.17,411.41 2897.71,442.805 2917.58,469.458C2921.35,474.516 2926.24,479.477 2930.97,484.275C2938.5,491.91 2946.28,499.804 2949.52,508.124C2956.22,525.313 2937.43,538.689 2915.38,541.691C2890.01,545.142 2864.12,536.602 2852.64,526.811C2837.4,513.803 2837.38,496.744 2852.59,474.661C2855.3,470.715 2858.34,466.709 2861.27,462.836C2871.17,449.75 2881.41,436.219 2881.78,422.18C2882.03,412.897 2876.3,403.198 2866.07,395.569C2863.45,393.621 2860.14,391.505 2856.06,389.467L2852.22,389.467C2868.11,396.574 2879.78,407.847 2879.4,422.161Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2843.33,440.714C2840.04,449.605 2832.79,458.237 2825.78,466.586C2818.09,475.742 2810.14,485.21 2807.3,495.111C2800.67,518.281 2824.47,542.579 2866.52,555.573C2870.5,556.802 2874.79,557.909 2879.28,558.877L2886.15,558.877C2879.58,557.694 2873.33,556.196 2867.7,554.458C2826.5,541.726 2803.17,517.955 2809.66,495.307C2812.44,485.58 2820.32,476.193 2827.95,467.116C2835.01,458.702 2842.32,450.001 2845.67,440.965C2852.31,423.009 2841.27,404.239 2818.41,389.465L2815.31,389.465C2838.59,404.067 2849.96,422.808 2843.33,440.714Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2945.51,448.762C2945.68,466.887 2953.85,484.95 2961.75,502.418C2963.9,507.163 2966.12,512.07 2968.14,516.895C2973.45,529.567 2976.92,545.349 2956.48,554.983C2953.08,556.583 2949.28,557.869 2945.18,558.878L2950.37,558.878C2953.09,558.04 2955.67,557.073 2958.05,555.952C2979.43,545.871 2975.92,529.633 2970.46,516.613C2968.44,511.776 2966.22,506.864 2964.07,502.114C2956.2,484.718 2948.06,466.73 2947.89,448.757C2947.75,434.27 2953.56,407.946 2992.42,389.467L2988.83,389.467C2951.06,408.215 2945.37,434.301 2945.51,448.762Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2902.47,486.306C2897.71,484.43 2892.24,483.953 2886.64,484.929C2878.7,486.315 2871.16,490.592 2868.3,495.328C2864.48,501.645 2867.22,508.343 2869.63,514.253C2870.95,517.467 2872.43,521.111 2876.45,524.162C2880.76,527.443 2887.84,529.506 2894.66,529.507C2894.92,529.507 2895.18,529.504 2895.44,529.498C2902.66,529.329 2909.55,526.868 2913.01,523.227C2916.18,519.887 2916.28,516.13 2915.95,512.896L2914.76,512.931L2915.95,512.874C2915.35,506.821 2913.74,490.743 2902.47,486.306ZM2910.89,522.641C2907.82,525.877 2901.71,528.065 2895.34,528.214C2889.08,528.357 2882.41,526.479 2878.39,523.418C2874.65,520.58 2873.28,517.222 2871.96,513.976C2869.61,508.227 2866.95,501.711 2870.56,495.727C2872.79,492.039 2879.28,487.565 2887.38,486.152C2889.05,485.86 2890.69,485.715 2892.28,485.715C2895.37,485.715 2898.31,486.259 2901.07,487.345C2911.42,491.422 2913.04,507.621 2913.57,512.944L2913.58,512.967C2913.88,516.028 2913.8,519.573 2910.89,522.641Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M3174.72,422.703C3165.8,418.419 3158.26,413.447 3150.96,408.638C3145.71,405.179 3140.28,401.601 3134.36,398.283C3129.15,395.367 3122.56,392.193 3114.83,389.466L3110.33,389.466C3119.29,392.346 3126.84,395.925 3132.64,399.175C3138.5,402.455 3143.9,406.012 3149.12,409.452C3156.47,414.299 3164.08,419.311 3173.13,423.663C3180.01,426.966 3187.86,429.861 3196.45,432.305L3196.45,430.819C3188.53,428.528 3181.2,425.822 3174.72,422.703Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M3157.15,389.466L3150.8,389.466C3168.16,392.772 3180.57,399.594 3193.61,406.79C3193.93,406.966 3194.25,407.142 3194.57,407.317C3194.25,406.487 3193.89,405.677 3193.5,404.888C3182.72,398.961 3171.52,393.019 3157.15,389.466Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M3058.17,556.061C3052.05,552.124 3035.15,549.117 3019.75,550.914C3010.02,552.05 3001.7,555.03 2996.24,558.878L2999.28,558.878C3004.39,555.646 3011.74,553.164 3020.25,552.17C3022.89,551.863 3025.84,551.71 3028.91,551.71C3039.28,551.71 3051,553.452 3056.34,556.888C3057.46,557.607 3058.31,558.267 3058.93,558.878L3061.6,558.878C3060.89,558.012 3059.76,557.084 3058.17,556.061Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M3014.18,410.186C3013.53,410.553 3012.86,410.926 3012.18,411.304C3006.72,414.337 3000.53,417.775 3003.25,421.648C3005.3,424.559 3011.71,426.021 3017.16,426.178C3017.58,426.19 3018,426.196 3018.42,426.196C3023,426.196 3027.95,425.491 3033.51,424.05C3057.05,417.946 3070.47,404.142 3064.72,391.94C3064.56,391.596 3063.91,391.388 3063.28,391.476C3062.64,391.563 3062.25,391.913 3062.42,392.257C3067.89,403.878 3055.01,417.048 3032.48,422.892C3026.78,424.371 3021.82,425.027 3017.29,424.896C3011.76,424.736 3006.9,423.213 3005.48,421.193C3003.29,418.085 3008.68,415.093 3013.89,412.201C3014.58,411.819 3015.26,411.442 3015.91,411.07C3026.32,405.121 3030.91,397.06 3028.71,389.468L3026.3,389.468C3028.53,396.728 3024.17,404.475 3014.18,410.186Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2988.03,410.328C2983.13,415.494 2978.8,421.477 2983.81,426.681C2987.69,430.71 2997.26,433.651 3008.19,434.174C3009.54,434.238 3010.91,434.271 3012.3,434.271C3019.85,434.271 3028.04,433.315 3037.18,431.371C3068.93,424.619 3092.21,410.529 3097.95,394.598C3098.07,394.25 3097.65,393.912 3097.01,393.845C3096.36,393.777 3095.74,394.004 3095.61,394.353C3090.03,409.857 3067.31,423.583 3036.31,430.176C3025.84,432.404 3016.7,433.295 3008.4,432.895C2998.25,432.41 2989.43,429.739 2985.92,426.09C2981.41,421.405 2985.52,415.79 2990.15,410.915C2997.11,403.57 3005.14,396.374 3014.05,389.468L3011.16,389.468C3002.57,396.195 2994.8,403.19 2988.03,410.328Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2657.66,542.946C2664.21,542.014 2671.19,541.238 2677.95,540.486C2699.23,538.117 2721.24,535.668 2735.17,527.927C2752.75,518.156 2752.18,503.048 2733.5,483.023L2732.5,481.954C2719.64,468.193 2705.07,452.595 2717.81,438.978C2722.53,433.929 2730.75,429.64 2738.7,425.492C2747.54,420.881 2756.68,416.114 2761.24,410.219C2765.64,404.516 2764.75,398.046 2758.72,391.999C2757.84,391.124 2756.87,390.28 2755.83,389.465L2752.88,389.465C2754.26,390.463 2755.52,391.508 2756.62,392.608C2762.31,398.307 2763.17,404.385 2759.04,409.723C2754.68,415.374 2745.72,420.047 2737.05,424.567C2728.96,428.789 2720.59,433.155 2715.68,438.398C2702.41,452.594 2717.27,468.499 2730.38,482.532L2731.38,483.601C2749.55,503.084 2750.26,517.696 2733.47,527.032C2720.05,534.489 2698.4,536.898 2677.47,539.228C2670.68,539.983 2663.67,540.763 2657.05,541.704C2634.67,544.884 2613.59,550.874 2596.22,558.877L2599.92,558.877C2616.6,551.488 2636.54,545.947 2657.66,542.946Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2233.31,519.486C2259.09,504.99 2292.73,496.631 2325.6,496.552L2326.09,496.552C2347.49,496.552 2368.53,499.971 2388.89,503.28C2413.12,507.219 2438.18,511.292 2463.77,509.53C2486.6,507.958 2507.16,501.666 2518.76,492.698C2530.59,483.543 2532.38,471.89 2523.4,462.284C2512.25,450.351 2486.6,441.589 2444.96,435.495C2437.84,434.454 2430.5,433.485 2423.39,432.549C2398.87,429.315 2373.5,425.971 2352.84,419.015C2331.99,411.993 2317.41,401.147 2311.6,389.466L2309.15,389.466C2315,401.582 2330.02,412.844 2351.58,420.106C2372.57,427.171 2398.12,430.54 2422.83,433.797C2429.92,434.732 2437.25,435.698 2444.34,436.736C2484.72,442.645 2510.6,451.435 2521.28,462.863C2529.86,472.045 2528.14,483.196 2516.8,491.962C2505.58,500.637 2485.64,506.728 2463.47,508.255C2438.39,509.983 2413.57,505.949 2389.57,502.05C2369.05,498.716 2347.84,495.268 2326.09,495.268C2325.92,495.268 2325.76,495.268 2325.59,495.269C2292.08,495.35 2257.82,503.852 2231.59,518.598C2210.26,530.587 2196.48,544.829 2183.72,558.879L2186.37,558.879C2198.91,545.1 2212.51,531.176 2233.31,519.486Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2315.18,484.795C2318.71,484.572 2322.34,484.389 2325.85,484.212C2336.01,483.698 2346.52,483.166 2356.38,481.606C2371.89,479.15 2382.99,474.23 2386.05,468.447C2390.5,460.035 2377.82,452.102 2365.6,447.864C2357.49,445.048 2348.25,442.964 2339.31,440.949C2333.37,439.61 2327.23,438.226 2321.51,436.645C2286.45,426.964 2264.36,408.224 2264.63,389.467L2262.26,389.467C2261.99,408.699 2284.58,427.89 2320.42,437.789C2326.23,439.393 2332.41,440.787 2338.39,442.135C2347.23,444.129 2356.38,446.191 2364.32,448.946C2375.84,452.945 2387.85,460.36 2383.76,468.094C2380.92,473.451 2370.44,478.042 2355.71,480.373C2346.07,481.899 2335.68,482.425 2325.62,482.933C2322.1,483.111 2318.47,483.295 2314.9,483.519C2275.64,485.993 2237.06,494.589 2206.26,507.725C2175.43,520.875 2153.8,537.972 2145.37,555.868C2145.21,556.212 2145.6,556.561 2146.23,556.649C2146.33,556.663 2146.43,556.669 2146.53,556.669C2147.06,556.669 2147.54,556.475 2147.68,556.184C2164.79,519.856 2235.24,489.832 2315.18,484.795Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2480.92,405.545C2486.44,415.438 2497.89,423.266 2513.16,427.586C2518.98,429.231 2525.47,430.408 2531.74,431.546C2541.14,433.251 2550.02,434.861 2557,437.913C2569.98,443.586 2572.24,452.404 2574.42,460.933C2574.74,462.187 2575.06,463.433 2575.41,464.658C2576.8,469.502 2583.33,485.485 2609.2,487.819C2611.09,487.99 2612.97,488.07 2614.83,488.07C2629.21,488.07 2642.19,483.251 2648.8,478.237C2659.34,470.244 2661.22,459.654 2654.36,446.76C2652.11,442.517 2649.15,438.295 2646.29,434.212C2642.19,428.357 2637.95,422.303 2635.78,416.161C2632.03,405.521 2635.9,396.003 2646.42,389.466L2643.32,389.466C2633.3,396.259 2629.71,405.807 2633.44,416.402C2635.65,422.653 2639.93,428.76 2644.06,434.667C2646.91,438.726 2649.85,442.922 2652.08,447.115C2658.72,459.614 2656.96,469.834 2646.86,477.492C2639.81,482.839 2625.15,487.954 2609.59,486.552C2585.29,484.36 2579.09,469.09 2577.76,464.462C2577.41,463.243 2577.1,462.004 2576.78,460.757C2574.53,451.981 2572.21,442.907 2558.5,436.914C2551.18,433.716 2541.68,431.994 2532.5,430.328C2526.31,429.206 2519.91,428.046 2514.27,426.448C2499.57,422.29 2488.53,414.735 2483.2,405.176C2480.34,400.046 2479.22,394.757 2478.79,389.467L2476.41,389.467C2476.84,394.86 2477.98,400.267 2480.92,405.545Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2534.09,389.466C2533.61,393.098 2536.04,397.079 2541.24,400.859C2545.83,404.2 2551.91,407.054 2557.78,409.814C2559.67,410.698 2561.55,411.582 2563.38,412.482C2569.9,415.678 2577.69,420.007 2580.05,425.161C2580.83,426.845 2581.01,428.547 2581.2,430.349C2581.37,431.929 2581.55,433.563 2582.16,435.167C2583.41,438.437 2587.51,442.32 2595.23,443.409C2596.62,443.604 2598.01,443.694 2599.38,443.694C2605.74,443.694 2611.68,441.761 2614.83,439.461C2618.32,436.915 2619.7,433.638 2619.06,429.442C2618.18,423.66 2614.1,418.103 2610.15,412.729C2608.19,410.052 2606.15,407.283 2604.55,404.533C2602.39,400.814 2600.11,395.039 2602.26,389.467L2599.82,389.467C2597.74,395.225 2600.07,401.107 2602.28,404.918C2603.91,407.713 2605.96,410.504 2607.94,413.203C2611.82,418.49 2615.84,423.956 2616.69,429.547C2617.28,433.419 2616.05,436.412 2612.92,438.697C2609.65,441.084 2602.55,443.113 2595.83,442.165C2589.16,441.225 2585.6,437.799 2584.49,434.906C2583.91,433.394 2583.74,431.808 2583.58,430.274C2583.39,428.493 2583.19,426.652 2582.37,424.852C2579.85,419.361 2571.75,414.848 2564.98,411.53C2563.14,410.624 2561.24,409.734 2559.35,408.844C2553.57,406.129 2547.59,403.321 2543.15,400.093C2538.26,396.535 2535.97,392.816 2536.48,389.466L2534.09,389.466Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2535.5,558.877C2535.49,556.233 2534.66,553.58 2532.62,551.003C2528.18,545.381 2518.26,540.85 2504.69,538.246C2492.02,535.813 2477.27,535.308 2464.21,536.861C2442.12,539.489 2421.66,546.903 2403.35,558.876L2406.44,558.876C2424.08,547.608 2443.67,540.62 2464.72,538.116C2489.05,535.222 2521.27,539.912 2530.43,551.507C2532.33,553.906 2533.11,556.39 2533.11,558.876L2535.5,558.876L2535.5,558.877Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2493.25,551.684C2479.88,547.335 2461.63,551.541 2450.06,556.465C2448.24,557.24 2446.47,558.05 2444.72,558.877L2448.36,558.877C2449.41,558.401 2450.46,557.931 2451.53,557.474C2462.51,552.803 2479.7,548.774 2492.03,552.785C2496.04,554.091 2498.93,556.106 2500.74,558.877L2503.26,558.877C2501.29,555.599 2497.98,553.22 2493.25,551.684Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2661.86,496.156C2656.27,496.387 2650.81,497.346 2645.53,498.274C2640.34,499.184 2634.99,500.125 2629.5,500.38C2620.32,500.807 2611.09,500.149 2602.17,499.514C2595.35,499.027 2588.29,498.524 2581.22,498.497C2574.16,498.462 2556.81,499.067 2547.89,505.168C2540.96,509.905 2541.94,515.861 2543.67,522.058L2548.88,540.726C2549.77,543.922 2552.18,545.748 2556.03,546.153C2556.54,546.207 2557.04,546.232 2557.53,546.232C2562.06,546.232 2565.57,544.072 2567.76,542.729C2587.55,530.558 2623.61,526.835 2652.58,523.844C2663.28,522.74 2676.59,521.366 2686.17,517.494C2698.31,512.592 2697.25,507.726 2695.54,505.262C2691.56,499.541 2676.77,495.541 2661.86,496.156ZM2684.75,516.465C2675.6,520.16 2662.59,521.503 2652.13,522.583C2622.8,525.611 2586.3,529.379 2565.97,541.882C2563.15,543.614 2560.1,545.278 2556.49,544.893C2553.69,544.599 2551.97,543.174 2551.24,540.535L2546.03,521.866C2544.38,515.959 2543.42,510.297 2549.76,505.963C2555.43,502.083 2566.99,499.78 2580.74,499.78C2580.89,499.78 2581.05,499.78 2581.2,499.781C2588.12,499.807 2595.11,500.305 2601.86,500.787C2610.91,501.432 2620.26,502.099 2629.7,501.66C2635.46,501.392 2640.95,500.428 2646.26,499.496C2651.65,498.55 2656.73,497.657 2662.04,497.438C2663.01,497.398 2663.98,497.378 2664.95,497.378C2677.82,497.378 2689.93,500.863 2693.31,505.714C2695.86,509.387 2692.82,513.205 2684.75,516.465Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2162.5,558.877C2172.67,540.979 2194.3,524.371 2224.28,512.155C2265.84,495.216 2323.61,486.647 2378.82,489.231C2382.8,489.417 2386.85,489.661 2390.77,489.898C2403,490.636 2415.65,491.398 2428.05,490.533C2436.59,489.936 2444.87,488.353 2451.36,486.075C2458.67,483.51 2463.3,480.228 2464.76,476.583C2466.9,471.257 2462.35,465.311 2451.25,458.91C2432.56,448.131 2407.11,440.375 2382.5,432.873C2370.39,429.183 2357.88,425.367 2346.32,421.239C2324.19,413.339 2302.75,401.981 2293.95,389.464L2291.28,389.464C2293.36,392.549 2296.26,395.662 2299.99,398.796C2312.71,409.488 2331.49,417.485 2345,422.31C2356.63,426.463 2369.19,430.29 2381.33,433.991C2405.78,441.445 2431.07,449.152 2449.51,459.787C2460.05,465.862 2464.39,471.422 2462.43,476.311C2460.35,481.525 2448.6,487.801 2427.74,489.258C2415.62,490.104 2403.12,489.35 2391.03,488.62C2387.1,488.382 2383.03,488.137 2379.03,487.95C2323.26,485.339 2264.87,494.003 2222.84,511.128C2192.26,523.592 2170.25,540.577 2160.02,558.875L2162.5,558.875L2162.5,558.877Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2173.19,460.991C2179.89,461.089 2186.66,461.59 2193.2,462.074C2198.95,462.5 2204.89,462.94 2210.82,463.112C2226.24,463.558 2239.05,461.769 2246.88,458.068C2261.85,450.995 2256.33,438.684 2243.54,430.692C2237.99,427.221 2231.29,424.11 2224.82,421.102C2216.79,417.373 2208.49,413.518 2202.49,409.01C2196.02,404.139 2193.42,397.919 2195.72,392.779C2196.26,391.589 2197.04,390.481 2198.06,389.466L2195.38,389.466C2194.55,390.405 2193.89,391.411 2193.41,392.478C2190.95,397.972 2193.69,404.593 2200.56,409.759C2206.72,414.391 2215.13,418.298 2223.26,422.076C2229.67,425.054 2236.3,428.133 2241.74,431.532C2253.78,439.056 2259.12,450.575 2245.32,457.101C2235.39,461.793 2219.53,462.077 2210.94,461.829C2205.12,461.66 2199.22,461.224 2193.52,460.802C2186.92,460.313 2180.08,459.807 2173.26,459.707C2154.64,459.435 2136,462.3 2121.25,467.531L2121.25,469.083C2135.79,463.689 2154.49,460.715 2173.19,460.991Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2157.58,445.879C2160.06,446.118 2162.59,446.424 2165.26,446.747C2176.22,448.075 2187.55,449.448 2195.64,446.019C2205.57,441.81 2200.23,434.247 2197.47,431.191C2184.71,417.062 2159.92,405.07 2129.26,397.827C2128.89,398.2 2128.54,398.584 2128.19,398.977C2158.4,406.094 2182.8,417.877 2195.33,431.753C2197.83,434.529 2202.74,441.376 2194.17,445.007C2186.97,448.06 2176.19,446.755 2165.78,445.492C2163.2,445.18 2160.53,444.857 2158,444.612C2145.92,443.45 2132.77,444.551 2121.25,447.557L2121.25,448.979C2132.56,445.883 2145.62,444.728 2157.58,445.879Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2145.11,431.505C2146.37,431.553 2147.67,431.621 2149,431.69C2156.16,432.064 2164.27,432.488 2168.73,429.758C2170.39,428.739 2171.12,427.539 2170.88,426.19C2170.48,423.883 2167.13,421.203 2161.93,419.022C2151.53,414.659 2138.88,411.424 2125.34,409.666C2124.71,409.584 2124.06,409.795 2123.91,410.141C2123.75,410.486 2124.15,410.833 2124.79,410.916C2137.99,412.631 2150.33,415.785 2160.47,420.038C2164.1,421.557 2168.11,424.003 2168.51,426.312C2168.68,427.307 2168.17,428.156 2166.94,428.909C2163.28,431.147 2156.14,430.773 2149.22,430.412C2147.88,430.341 2146.55,430.272 2145.27,430.224C2136.82,429.905 2128.39,431.048 2121.25,433.165L2121.25,434.611C2128.22,432.402 2136.64,431.184 2145.11,431.505Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2308.06,547.039C2300.07,545.57 2290.21,546.103 2277.9,548.665C2265.56,551.235 2254.22,554.712 2244.22,558.877L2248.18,558.877C2257.37,555.238 2267.65,552.177 2278.76,549.864C2290.52,547.415 2299.86,546.888 2307.3,548.256C2315.17,549.701 2320.98,553.188 2325,558.878L2327.54,558.878C2323.25,552.541 2316.86,548.654 2308.06,547.039Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2316.34,530.132C2296.17,527.481 2271.67,530.907 2253.92,538.861C2240.88,544.707 2232.34,552.228 2226.52,558.877L2229.16,558.877C2234.85,552.495 2243.09,545.386 2255.44,539.85C2272.63,532.147 2296.32,528.823 2315.78,531.381C2342.26,534.861 2356.69,547.944 2359.14,558.878L2361.53,558.878C2359.09,547.481 2344.05,533.773 2316.34,530.132Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2722.06,558.578C2721.03,556.108 2731.91,552.977 2739.86,550.691C2743.8,549.557 2747.2,548.577 2749.22,547.718C2755.54,545.033 2760.03,541.802 2764.38,538.677C2768.26,535.882 2771.94,533.241 2776.83,530.931C2779.07,529.875 2782.7,528.835 2786.49,528.835C2787.4,528.835 2788.32,528.895 2789.23,529.029C2793.79,529.701 2796.4,531.921 2798.67,534.137L2824.05,558.877L2826.77,558.877C2826.56,558.666 2826.35,558.463 2826.15,558.27L2800.75,533.514C2798.34,531.165 2795.31,528.592 2789.85,527.789C2785.23,527.109 2779.51,527.962 2775.27,529.963C2770.18,532.362 2766.26,535.18 2762.47,537.905C2758.22,540.964 2753.82,544.127 2747.75,546.708C2745.9,547.497 2742.42,548.498 2738.74,549.557C2729.17,552.312 2718.32,555.434 2719.74,558.859C2719.74,558.865 2719.75,558.871 2719.75,558.877L2722.36,558.877C2722.21,558.781 2722.1,558.682 2722.06,558.578Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
                 <path d="M2176.12,487.036C2170.17,489.598 2156.91,496.185 2162.49,501.997C2164.48,504.075 2166.74,505.183 2169.38,505.382C2169.67,505.404 2169.95,505.414 2170.24,505.414C2174.64,505.414 2178.99,502.924 2183.57,500.298C2185.48,499.206 2187.45,498.077 2189.43,497.131C2192.91,495.465 2196.85,493.577 2198.4,491.098C2198.58,490.807 2198.72,490.505 2198.81,490.198C2199.48,487.955 2197.59,485.191 2192.68,484.251C2187.99,483.353 2182.57,484.264 2176.12,487.036ZM2196.46,489.994C2196.39,490.231 2196.28,490.464 2196.14,490.687C2194.8,492.843 2191.1,494.611 2187.85,496.17C2185.79,497.155 2183.78,498.305 2181.84,499.417C2177.59,501.853 2173.21,504.38 2169.71,504.109C2167.91,503.973 2166.19,503.064 2164.6,501.405C2159.84,496.439 2172.11,490.404 2177.61,488.04C2182.02,486.142 2185.8,485.204 2189.06,485.204C2190.05,485.204 2190.99,485.29 2191.88,485.462C2195.74,486.201 2196.96,488.329 2196.46,489.994Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <rect x="2163.92" y="927.919" width="993.744" height="50.338" style="fill:rgb(147,83,9);"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M3115.46,927.162L3115.46,979.014C3115.46,988.967 3107.4,997.029 3097.45,997.029L2224.13,997.029C2214.17,997.029 2206.11,988.967 2206.11,979.014L2206.11,927.162C2206.11,917.23 2214.17,909.147 2224.13,909.147L3097.45,909.147C3107.4,909.147 3115.46,917.23 3115.46,927.162Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M2341.2,997.029L2224.13,997.029C2214.17,997.029 2206.11,988.967 2206.11,979.014L2206.11,927.162C2206.11,917.23 2214.18,909.147 2224.13,909.147L2340.15,909.147C2363.99,909.147 2384.28,927.678 2385.12,951.497C2386,976.499 2366.01,997.029 2341.2,997.029Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M2117.85,1028.35L2110.99,1028.35L2110.99,877.822L2117.85,877.822C2147.58,877.822 2171.69,901.93 2171.69,931.668L2171.69,974.508C2171.69,1004.25 2147.58,1028.35 2117.85,1028.35Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M2107.35,1038.07C2099.31,1038.07 2092.78,1031.54 2092.78,1023.5L2092.78,882.678C2092.78,874.633 2099.31,868.11 2107.35,868.11C2115.4,868.11 2121.92,874.632 2121.92,882.678L2121.92,1023.5C2121.92,1031.54 2115.4,1038.07 2107.35,1038.07Z" style="fill:rgb(147,83,9);fill-rule:nonzero;"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M3199.86,1028.35L3206.71,1028.35L3206.71,877.822L3199.86,877.822C3170.12,877.822 3146.01,901.93 3146.01,931.668L3146.01,974.508C3146.01,1004.25 3170.12,1028.35 3199.86,1028.35Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M3210.35,1038.07C3218.4,1038.07 3224.92,1031.54 3224.92,1023.5L3224.92,882.678C3224.92,874.633 3218.4,868.11 3210.35,868.11C3202.31,868.11 3195.79,874.632 3195.79,882.678L3195.79,1023.5C3195.79,1031.54 3202.31,1038.07 3210.35,1038.07Z" style="fill:rgb(147,83,9);fill-rule:nonzero;"/>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M2642.95,996.767C2626.84,904.554 2546.39,834.461 2449.56,834.461C2352.73,834.461 2272.28,904.554 2256.16,996.767L2642.95,996.767Z" style="fill:rgb(147,83,9);fill-opacity:0.2;fill-rule:nonzero;"/>
         </g>
         <g #backwheel id="backwheel" transform="matrix(0.114827,0,0,0.114827,-8.52651e-14,-2.13163e-14)">
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <circle cx="2449.56" cy="1030.81" r="175.63" style="fill:rgb(147,83,9);"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2441.69,940.376L2547.24,958.987C2545.37,954.117 2542.83,949.32 2539.65,944.732C2531.16,932.481 2518.52,922.232 2504.06,915.875C2500.64,914.367 2496.95,912.965 2493.39,911.608C2482.15,907.331 2470.54,902.909 2464.22,894.29C2462.13,891.449 2460.79,888.383 2460.18,885.103C2445.66,884.027 2431.39,885.15 2417.73,888.205C2410.35,896.793 2400.08,903.669 2387.89,907.63C2381.5,909.707 2374.03,910.772 2366.67,910.53C2360.7,914.646 2355.03,919.198 2349.72,924.159L2430.39,938.383C2434.18,938.866 2437.95,939.518 2441.69,940.376ZM2414.72,924.303C2414.39,924.276 2414.05,924.233 2413.72,924.174C2410.56,923.617 2407.45,921.67 2407.18,918.987C2406.97,916.977 2408.43,915.288 2409.49,914.055L2427.47,893.227C2429.18,891.251 2431.26,889.124 2434.37,888.895C2437.01,888.7 2439.99,890.054 2441.98,892.344C2444.36,895.088 2446.03,898.115 2447.65,901.042C2449.47,904.327 2451.34,907.725 2454.23,910.736C2455.11,911.656 2456.85,912.932 2458.68,914.282C2463.45,917.793 2468.86,921.774 2467.52,924.856C2466.38,927.461 2458.05,926.458 2444.56,924.605C2436.25,923.465 2427.66,922.287 2424.18,923.015C2423.63,923.13 2423.08,923.254 2422.52,923.379C2420.02,923.943 2417.44,924.525 2414.72,924.303Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2415.56,888.711C2398.63,892.771 2382.67,899.81 2368.4,909.35C2375.03,909.375 2381.68,908.34 2387.43,906.468C2398.77,902.783 2408.42,896.528 2415.56,888.711Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2466.3,924.369C2467.26,922.146 2461.84,918.154 2457.88,915.24C2455.92,913.794 2454.22,912.545 2453.26,911.542C2450.26,908.41 2448.34,904.939 2446.48,901.583C2444.82,898.58 2443.25,895.744 2440.96,893.101C2439.91,891.892 2438.11,890.566 2436.04,890.201C2435.54,890.113 2435.03,890.082 2434.51,890.12C2431.9,890.312 2430.11,892.149 2428.5,894.013L2410.53,914.841C2409.33,916.232 2408.34,917.498 2408.49,918.901C2408.72,921.183 2411.95,922.843 2414.8,923.079C2417.31,923.284 2419.78,922.725 2422.18,922.184C2422.74,922.058 2423.3,921.932 2423.86,921.815C2427.58,921.037 2435.9,922.179 2444.71,923.389C2453.48,924.592 2465.48,926.239 2466.3,924.369Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2493.86,910.472C2497.44,911.835 2501.14,913.244 2504.6,914.766C2519.28,921.223 2532.12,931.634 2540.75,944.083C2544.13,948.956 2546.79,954.064 2548.72,959.248L2571.75,963.308C2571.63,963.025 2571.52,962.739 2571.41,962.457C2569.51,957.575 2567.5,951.253 2567.86,945.13C2554.4,926.536 2536.57,911.085 2515.56,900.454C2513.67,899.748 2511.81,899.066 2510,898.406C2506.02,896.95 2501.36,895.225 2497.34,892.748C2490.14,890.267 2482.66,888.31 2474.92,886.946C2470.45,886.157 2465.99,885.593 2461.55,885.222C2462.15,888.218 2463.39,891.019 2465.3,893.613C2471.39,901.916 2482.81,906.266 2493.86,910.472Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2573.24,963.573L2579.85,964.737C2576.7,958.544 2573.12,952.585 2569.14,946.909C2569.2,952.291 2570.97,957.754 2572.64,962.064C2572.84,962.565 2573.05,963.07 2573.24,963.573Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2417.73,888.206C2417,888.369 2416.28,888.539 2415.56,888.712C2408.42,896.529 2398.77,902.783 2387.43,906.47C2381.68,908.341 2375.03,909.377 2368.4,909.352C2367.82,909.74 2367.25,910.137 2366.67,910.533C2374.04,910.774 2381.5,909.71 2387.89,907.633C2400.08,903.67 2410.35,896.794 2417.73,888.206Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2430.39,938.384L2441.69,940.376C2437.95,939.518 2434.18,938.866 2430.39,938.384Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2493.39,911.608C2496.95,912.965 2500.64,914.367 2504.06,915.875C2518.52,922.232 2531.16,932.481 2539.65,944.732C2542.83,949.321 2545.37,954.118 2547.24,958.987L2548.72,959.247C2546.79,954.063 2544.13,948.955 2540.75,944.082C2532.12,931.633 2519.28,921.222 2504.6,914.765C2501.14,913.243 2497.44,911.834 2493.86,910.471C2482.81,906.265 2471.39,901.915 2465.3,893.612C2463.39,891.019 2462.15,888.217 2461.55,885.221C2461.09,885.183 2460.63,885.137 2460.18,885.103C2460.79,888.383 2462.13,891.449 2464.22,894.29C2470.54,902.909 2482.15,907.331 2493.39,911.608Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2571.75,963.308L2573.24,963.572C2573.04,963.069 2572.84,962.564 2572.64,962.063C2570.97,957.753 2569.2,952.29 2569.14,946.908C2568.72,946.31 2568.29,945.72 2567.86,945.129C2567.5,951.252 2569.51,957.575 2571.41,962.456C2571.52,962.74 2571.63,963.025 2571.75,963.308Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2515.56,900.454C2509.72,897.502 2503.64,894.921 2497.34,892.749C2501.36,895.226 2506.02,896.95 2510,898.407C2511.81,899.066 2513.67,899.748 2515.56,900.454Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2444.56,924.605C2458.05,926.457 2466.38,927.46 2467.52,924.856C2468.86,921.774 2463.45,917.793 2458.68,914.282C2456.85,912.932 2455.11,911.656 2454.23,910.736C2451.34,907.724 2449.47,904.327 2447.65,901.042C2446.03,898.115 2444.36,895.088 2441.98,892.344C2439.99,890.054 2437.01,888.701 2434.37,888.895C2431.26,889.125 2429.18,891.252 2427.47,893.227L2409.49,914.055C2408.43,915.288 2406.97,916.978 2407.18,918.987C2407.45,921.671 2410.56,923.617 2413.72,924.174C2414.05,924.233 2414.39,924.276 2414.72,924.303C2417.44,924.525 2420.02,923.942 2422.52,923.379C2423.08,923.254 2423.63,923.13 2424.18,923.015C2427.66,922.287 2436.25,923.465 2444.56,924.605ZM2422.18,922.184C2419.79,922.725 2417.31,923.284 2414.8,923.079C2411.95,922.843 2408.72,921.183 2408.49,918.901C2408.34,917.498 2409.33,916.232 2410.53,914.841L2428.51,894.013C2430.12,892.149 2431.9,890.313 2434.51,890.12C2435.03,890.082 2435.54,890.114 2436.04,890.201C2438.11,890.566 2439.92,891.893 2440.96,893.101C2443.26,895.745 2444.82,898.58 2446.48,901.583C2448.34,904.939 2450.26,908.409 2453.26,911.542C2454.22,912.545 2455.92,913.794 2457.88,915.24C2461.84,918.154 2467.27,922.146 2466.3,924.369C2465.48,926.239 2453.48,924.592 2444.72,923.389C2435.91,922.179 2427.58,921.037 2423.86,921.815C2423.3,921.931 2422.74,922.058 2422.18,922.184Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2574.18,965.92C2574.95,967.826 2575.73,969.749 2576.45,971.682L2582.99,972.835C2582.85,972.067 2582.73,971.297 2582.63,970.527C2581.74,968.576 2580.82,966.645 2579.85,964.737L2573.24,963.573C2573.56,964.355 2573.87,965.135 2574.18,965.92Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2574.18,965.92C2574.95,967.826 2575.73,969.749 2576.45,971.682L2582.99,972.835C2582.85,972.067 2582.73,971.297 2582.63,970.527C2581.74,968.576 2580.82,966.645 2579.85,964.737L2573.24,963.573C2573.56,964.355 2573.87,965.135 2574.18,965.92Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2469.69,952.857L2549.71,966.967C2549.09,964.299 2548.26,961.633 2547.24,958.987L2441.69,940.376C2444.97,941.129 2448.21,942.035 2451.39,943.145C2458.08,945.477 2464.39,948.843 2469.69,952.857Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2469.69,952.857L2549.71,966.967C2549.09,964.299 2548.26,961.633 2547.24,958.987L2441.69,940.376C2444.97,941.129 2448.21,942.035 2451.39,943.145C2458.08,945.477 2464.39,948.843 2469.69,952.857Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2396.55,938.459C2394.56,938.508 2392.56,938.564 2390.57,938.619C2390.1,938.632 2389.63,938.644 2389.16,938.658L2404.66,941.392L2408.05,941.988L2467.1,952.401C2462.64,949.286 2457.28,946.499 2450.96,944.295C2433.24,938.118 2413.58,938.045 2396.55,938.459Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2396.55,938.459C2394.56,938.508 2392.56,938.564 2390.57,938.619C2390.1,938.632 2389.63,938.644 2389.16,938.658L2404.66,941.392L2408.05,941.988L2467.1,952.401C2462.64,949.286 2457.28,946.499 2450.96,944.295C2433.24,938.118 2413.58,938.045 2396.55,938.459Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2371.11,935.474L2383.06,937.582C2385.55,937.526 2388.03,937.459 2390.49,937.39C2392.49,937.334 2394.49,937.279 2396.48,937.23C2407.12,936.971 2418.79,936.906 2430.39,938.383L2349.72,924.159C2347.51,926.225 2345.37,928.365 2343.29,930.569L2368.62,935.036L2371.11,935.474Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2371.11,935.474L2383.06,937.582C2385.55,937.526 2388.03,937.459 2390.49,937.39C2392.49,937.334 2394.49,937.279 2396.48,937.23C2407.12,936.971 2418.79,936.906 2430.39,938.383L2349.72,924.159C2347.51,926.225 2345.37,928.365 2343.29,930.569L2368.62,935.036L2371.11,935.474Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2574.96,971.42C2574.32,969.714 2573.64,968.014 2572.95,966.328C2572.54,965.318 2572.14,964.313 2571.75,963.308L2548.72,959.248C2549.7,961.89 2550.51,964.55 2551.1,967.213L2574.96,971.42Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2574.96,971.42C2574.32,969.714 2573.64,968.014 2572.95,966.328C2572.54,965.318 2572.14,964.313 2571.75,963.308L2548.72,959.248C2549.7,961.89 2550.51,964.55 2551.1,967.213L2574.96,971.42Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2390.56,938.619C2392.56,938.564 2394.56,938.508 2396.55,938.459C2413.58,938.045 2433.24,938.117 2450.96,944.295C2457.28,946.499 2462.64,949.286 2467.1,952.401L2469.69,952.857C2464.39,948.843 2458.08,945.477 2451.39,943.145C2448.21,942.036 2444.97,941.129 2441.69,940.376L2430.39,938.384C2418.79,936.906 2407.12,936.971 2396.48,937.231C2394.49,937.28 2392.49,937.336 2390.49,937.391C2388.02,937.459 2385.54,937.527 2383.06,937.583L2389.16,938.659C2389.63,938.645 2390.1,938.632 2390.56,938.619Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2390.56,938.619C2392.56,938.564 2394.56,938.508 2396.55,938.459C2413.58,938.045 2433.24,938.117 2450.96,944.295C2457.28,946.499 2462.64,949.286 2467.1,952.401L2469.69,952.857C2464.39,948.843 2458.08,945.477 2451.39,943.145C2448.21,942.036 2444.97,941.129 2441.69,940.376L2430.39,938.384C2418.79,936.906 2407.12,936.971 2396.48,937.231C2394.49,937.28 2392.49,937.336 2390.49,937.391C2388.02,937.459 2385.54,937.527 2383.06,937.583L2389.16,938.659C2389.63,938.645 2390.1,938.632 2390.56,938.619Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2551.1,967.213C2550.51,964.551 2549.7,961.89 2548.72,959.248L2547.24,958.988C2548.26,961.633 2549.09,964.3 2549.71,966.968L2551.1,967.213Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2551.1,967.213C2550.51,964.551 2549.7,961.89 2548.72,959.248L2547.24,958.988C2548.26,961.633 2549.09,964.3 2549.71,966.968L2551.1,967.213Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2574.96,971.42L2576.45,971.682C2575.73,969.749 2574.95,967.826 2574.18,965.92C2573.87,965.135 2573.56,964.355 2573.25,963.573L2571.75,963.309C2572.14,964.314 2572.54,965.319 2572.95,966.329C2573.64,968.015 2574.32,969.714 2574.96,971.42Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2574.96,971.42L2576.45,971.682C2575.73,969.749 2574.95,967.826 2574.18,965.92C2573.87,965.135 2573.56,964.355 2573.25,963.573L2571.75,963.309C2572.14,964.314 2572.54,965.319 2572.95,966.329C2573.64,968.015 2574.32,969.714 2574.96,971.42Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2583.7,972.961C2583.35,972.146 2582.99,971.334 2582.63,970.527C2582.73,971.297 2582.85,972.066 2582.99,972.835L2583.7,972.961Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2583.7,972.961C2583.35,972.146 2582.99,971.334 2582.63,970.527C2582.73,971.297 2582.85,972.066 2582.99,972.835L2583.7,972.961Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2435.51,1008.29C2441.39,1003.41 2450.97,1004.51 2454.82,1005.23C2458.68,1005.93 2462.45,1007.09 2466.1,1008.2C2470.86,1009.66 2475.79,1011.16 2480.88,1011.65C2483.92,1011.94 2487,1011.57 2489.98,1011.21C2493.02,1010.85 2496.16,1010.47 2499.26,1010.79C2507.5,1011.65 2514.91,1016.84 2516.14,1022.6C2516.65,1025 2516.43,1029.44 2509.7,1032.84L2540.63,1038.3C2544.39,1030.53 2543.95,1019.62 2539.3,1005.69L2538.93,1004.58C2535.33,993.819 2531.39,981.988 2533.57,971.665L2503.62,966.385C2504.52,977.822 2501.66,986.665 2495.09,992.689C2490.65,996.766 2482.76,1000.05 2474.91,998.662C2473.89,998.483 2472.88,998.226 2471.87,997.883C2458.13,993.197 2457.21,977.542 2457.26,972.854C2457.27,971.668 2457.3,970.466 2457.34,969.256C2457.44,965.55 2457.53,961.789 2457.05,958.172L2420.31,951.694C2424.65,956.11 2427.62,960.736 2429.25,965.613C2432.56,975.507 2429.65,986.292 2421.67,993.758C2413.85,1001.07 2401.58,1005.01 2388.84,1004.29C2374.58,1003.48 2361.56,997.237 2348.98,991.2C2338.41,986.13 2327.48,980.888 2315.79,978.827C2315.71,978.811 2315.62,978.796 2315.53,978.781C2314.75,978.647 2313.96,978.535 2313.18,978.431C2310.86,984.471 2308.93,990.725 2307.41,997.173L2430.64,1018.9C2431.05,1014.77 2432.2,1011.03 2435.51,1008.29Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2458.57,972.896C2458.53,977.375 2459.39,992.329 2472.29,996.729C2480.56,999.545 2489.42,996.149 2494.16,991.801C2500.49,985.984 2503.22,977.361 2502.29,966.15L2458.41,958.413C2458.84,961.991 2458.75,965.681 2458.65,969.318C2458.62,970.521 2458.59,971.716 2458.57,972.896Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2316.01,977.62C2327.88,979.714 2338.9,984.999 2349.56,990.11C2362.02,996.087 2374.9,1002.27 2388.89,1003.06C2401.25,1003.76 2413.15,999.955 2420.72,992.88C2428.37,985.73 2431.15,975.413 2427.99,965.954C2426.29,960.868 2422.94,955.972 2418,951.288L2381.34,944.823C2384.68,948.202 2387.74,951.739 2390.39,955.494C2395.4,962.581 2396.89,968.609 2394.85,973.411C2393.44,976.698 2390.37,979.336 2385.95,981.044C2382.03,982.561 2377.24,983.251 2372.48,982.989C2365.56,982.609 2358.78,980.674 2352.22,978.802C2350.12,978.202 2347.95,977.582 2345.81,977.024C2335.94,974.452 2325.71,973.011 2315.52,972.668C2314.86,974.184 2314.23,975.711 2313.62,977.256C2314.33,977.353 2315.03,977.451 2315.74,977.572C2315.83,977.588 2315.91,977.603 2316.01,977.62Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2508.39,1032.09C2513.34,1029.8 2515.63,1026.51 2514.84,1022.81C2513.8,1017.92 2507.76,1013.48 2500.74,1012.24C2500.21,1012.15 2499.68,1012.07 2499.14,1012.02C2496.2,1011.71 2493.28,1012.06 2490.18,1012.43C2487.12,1012.8 2483.97,1013.17 2480.78,1012.87C2475.55,1012.37 2470.55,1010.85 2465.71,1009.37C2462.1,1008.27 2458.37,1007.12 2454.6,1006.43C2454.51,1006.42 2454.43,1006.4 2454.34,1006.39C2446.83,1005.06 2440.14,1006.12 2436.4,1009.22C2433.38,1011.71 2432.33,1015.23 2431.95,1019.13L2507.47,1032.45C2507.78,1032.33 2508.09,1032.23 2508.39,1032.09Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2595.38,1039.48C2595.33,1040.1 2595.3,1040.7 2595.27,1041.3C2595.31,1040.7 2595.34,1040.09 2595.38,1039.48Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2540.19,1004.25L2540.56,1005.35C2545.25,1019.42 2545.71,1030.52 2541.97,1038.53L2576.58,1044.64C2575.8,1037.57 2576.51,1030.5 2578.86,1023.82C2581.83,1015.42 2587.15,1007.8 2592.48,1000.44C2591.07,993.836 2589.22,987.365 2586.94,981.076L2534.88,971.897C2532.74,981.952 2536.64,993.628 2540.19,1004.25Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2577.92,1044.87L2594.17,1047.74C2593.51,1042.35 2594.01,1036.63 2595.66,1030.57C2595.65,1020.91 2594.69,1011.41 2592.84,1002.17C2587.81,1009.14 2582.89,1016.36 2580.12,1024.23C2577.78,1030.85 2577.1,1037.86 2577.92,1044.87Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2352.01,957.627C2355.36,951.079 2350.69,943.405 2345.37,938.481L2337.47,937.089C2330.12,945.868 2323.76,955.571 2318.59,966.048C2323.92,966.514 2329.4,966.952 2334.65,966.469C2343.08,965.696 2349.57,962.39 2352.01,957.627Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2346.13,975.841C2348.29,976.403 2350.47,977.025 2352.58,977.627C2359.06,979.478 2365.77,981.39 2372.52,981.762C2384.16,982.401 2391.62,977.633 2393.62,972.931C2395.5,968.523 2394.05,962.878 2389.3,956.152C2386.38,952.012 2382.92,948.137 2379.16,944.439L2347.57,938.869C2352.63,944.093 2356.57,951.599 2353.2,958.18C2350.57,963.323 2343.7,966.879 2334.81,967.694C2329.27,968.203 2323.55,967.717 2318,967.229C2317.33,968.626 2316.68,970.04 2316.05,971.464C2326.18,971.84 2336.33,973.286 2346.13,975.841Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2594.17,1047.74L2594.64,1047.82C2594.89,1045.64 2595.11,1043.47 2595.27,1041.31C2595.3,1040.7 2595.33,1040.1 2595.38,1039.48C2595.56,1036.5 2595.66,1033.53 2595.66,1030.57C2594.01,1036.63 2593.51,1042.35 2594.17,1047.74Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2576.58,1044.63L2577.92,1044.87C2577.1,1037.86 2577.78,1030.85 2580.12,1024.23C2582.89,1016.36 2587.81,1009.14 2592.84,1002.17C2592.72,1001.59 2592.6,1001.02 2592.47,1000.44C2587.15,1007.8 2581.83,1015.42 2578.86,1023.82C2576.51,1030.49 2575.8,1037.57 2576.58,1044.63Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2540.56,1005.35L2540.19,1004.25C2536.64,993.628 2532.74,981.953 2534.88,971.896L2533.57,971.665C2531.39,981.988 2535.33,993.819 2538.93,1004.58L2539.3,1005.69C2543.95,1019.62 2544.39,1030.53 2540.63,1038.3L2541.97,1038.53C2545.71,1030.52 2545.26,1019.42 2540.56,1005.35Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2315.79,978.827C2327.48,980.888 2338.41,986.13 2348.98,991.2C2361.56,997.237 2374.58,1003.48 2388.84,1004.29C2401.57,1005.01 2413.84,1001.07 2421.66,993.758C2429.65,986.292 2432.55,975.507 2429.24,965.613C2427.61,960.736 2424.65,956.11 2420.31,951.694L2418,951.287C2422.94,955.971 2426.29,960.866 2427.99,965.953C2431.15,975.412 2428.37,985.729 2420.72,992.879C2413.15,999.954 2401.25,1003.76 2388.89,1003.06C2374.9,1002.27 2362.02,996.087 2349.56,990.109C2338.9,984.998 2327.88,979.713 2316.01,977.619C2315.91,977.603 2315.83,977.587 2315.74,977.572C2315.03,977.451 2314.33,977.353 2313.62,977.256C2313.47,977.645 2313.33,978.039 2313.18,978.43C2313.96,978.534 2314.75,978.646 2315.53,978.78C2315.62,978.796 2315.7,978.812 2315.79,978.827Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2353.2,958.179C2356.57,951.599 2352.63,944.093 2347.57,938.868L2345.37,938.481C2350.69,943.405 2355.36,951.079 2352.01,957.627C2349.57,962.39 2343.08,965.696 2334.65,966.469C2329.4,966.952 2323.91,966.514 2318.59,966.048C2318.39,966.442 2318.19,966.833 2318,967.229C2323.55,967.716 2329.27,968.202 2334.81,967.694C2343.7,966.878 2350.57,963.322 2353.2,958.179Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2494.16,991.801C2489.42,996.148 2480.56,999.545 2472.29,996.729C2459.39,992.329 2458.53,977.375 2458.57,972.896C2458.59,971.717 2458.62,970.521 2458.65,969.318C2458.75,965.681 2458.84,961.99 2458.41,958.413L2457.04,958.172C2457.53,961.789 2457.43,965.55 2457.33,969.256C2457.3,970.466 2457.27,971.667 2457.26,972.854C2457.21,977.541 2458.12,993.197 2471.87,997.883C2472.88,998.226 2473.89,998.483 2474.91,998.662C2482.76,1000.05 2490.64,996.766 2495.09,992.689C2501.65,986.665 2504.51,977.822 2503.62,966.385L2502.28,966.15C2503.22,977.361 2500.49,985.984 2494.16,991.801Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2436.4,1009.22C2440.14,1006.12 2446.83,1005.06 2454.34,1006.39C2454.43,1006.4 2454.51,1006.42 2454.6,1006.43C2458.37,1007.13 2462.1,1008.27 2465.71,1009.37C2470.55,1010.85 2475.54,1012.37 2480.78,1012.87C2483.97,1013.17 2487.12,1012.8 2490.18,1012.43C2493.28,1012.06 2496.2,1011.71 2499.14,1012.02C2499.68,1012.07 2500.21,1012.15 2500.74,1012.24C2507.76,1013.48 2513.81,1017.92 2514.84,1022.81C2515.63,1026.51 2513.34,1029.8 2508.39,1032.09C2508.09,1032.23 2507.78,1032.33 2507.47,1032.45L2509.7,1032.84C2516.43,1029.44 2516.64,1025 2516.14,1022.6C2514.91,1016.83 2507.5,1011.65 2499.25,1010.79C2496.16,1010.47 2493.02,1010.85 2489.98,1011.21C2487,1011.57 2483.91,1011.94 2480.87,1011.65C2475.79,1011.16 2470.86,1009.66 2466.09,1008.2C2462.45,1007.09 2458.67,1005.93 2454.82,1005.23C2450.97,1004.51 2441.39,1003.41 2435.51,1008.29C2432.2,1011.03 2431.05,1014.77 2430.64,1018.9L2431.94,1019.13C2432.33,1015.23 2433.38,1011.71 2436.4,1009.22Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2345.81,977.025C2347.95,977.582 2350.12,978.202 2352.22,978.803C2358.78,980.675 2365.57,982.61 2372.48,982.99C2377.24,983.252 2382.03,982.561 2385.95,981.045C2390.37,979.337 2393.44,976.698 2394.85,973.412C2396.89,968.611 2395.4,962.583 2390.4,955.495C2387.75,951.74 2384.68,948.203 2381.34,944.824L2379.16,944.44C2382.93,948.139 2386.38,952.013 2389.3,956.153C2394.05,962.879 2395.5,968.525 2393.62,972.932C2391.62,977.633 2384.16,982.402 2372.53,981.763C2365.77,981.391 2359.07,979.478 2352.58,977.628C2350.47,977.026 2348.29,976.403 2346.14,975.842C2336.34,973.287 2326.18,971.842 2316.05,971.465C2315.88,971.866 2315.7,972.267 2315.52,972.671C2325.71,973.012 2335.94,974.453 2345.81,977.025Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2390.84,1019.43L2430.37,1026.4L2430.41,1023.76C2430.43,1022.11 2430.48,1020.48 2430.64,1018.9L2307.41,997.174C2306.84,999.573 2306.34,1002 2305.89,1004.45L2389.52,1019.2L2390.84,1019.43Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2390.84,1019.43L2430.37,1026.4L2430.41,1023.76C2430.43,1022.11 2430.48,1020.48 2430.64,1018.9L2307.41,997.174C2306.84,999.573 2306.34,1002 2305.89,1004.45L2389.52,1019.2L2390.84,1019.43Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2577.92,1044.87C2578.22,1047.49 2578.73,1050.1 2579.44,1052.68L2593.58,1055.18C2593.9,1053.26 2594.19,1051.35 2594.44,1049.44C2594.34,1048.87 2594.24,1048.31 2594.17,1047.74L2577.92,1044.87Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2577.92,1044.87C2578.22,1047.49 2578.73,1050.1 2579.44,1052.68L2593.58,1055.18C2593.9,1053.26 2594.19,1051.35 2594.44,1049.44C2594.34,1048.87 2594.24,1048.31 2594.17,1047.74L2577.92,1044.87Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2507.47,1032.45L2431.95,1019.13C2431.8,1020.64 2431.74,1022.21 2431.72,1023.81L2431.68,1026.63L2478.14,1034.82C2482.06,1034.71 2485.91,1034.71 2489.56,1034.7C2495.14,1034.69 2501.99,1034.66 2507.47,1032.45Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2507.47,1032.45L2431.95,1019.13C2431.8,1020.64 2431.74,1022.21 2431.72,1023.81L2431.68,1026.63L2478.14,1034.82C2482.06,1034.71 2485.91,1034.71 2489.56,1034.7C2495.14,1034.69 2501.99,1034.66 2507.47,1032.45Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2489.59,1035.93C2487.94,1035.93 2486.24,1035.93 2484.52,1035.95L2535.55,1044.95C2537.7,1043.08 2539.39,1040.86 2540.63,1038.3L2509.7,1032.84C2509.46,1032.96 2509.24,1033.08 2508.99,1033.2C2503.12,1035.91 2495.62,1035.92 2489.59,1035.93Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2489.59,1035.93C2487.94,1035.93 2486.24,1035.93 2484.52,1035.95L2535.55,1044.95C2537.7,1043.08 2539.39,1040.86 2540.63,1038.3L2509.7,1032.84C2509.46,1032.96 2509.24,1033.08 2508.99,1033.2C2503.12,1035.91 2495.62,1035.92 2489.59,1035.93Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2578.03,1052.44C2577.35,1049.85 2576.86,1047.24 2576.58,1044.63L2541.97,1038.53C2540.78,1041.07 2539.19,1043.31 2537.16,1045.23L2578.03,1052.44Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2578.03,1052.44C2577.35,1049.85 2576.86,1047.24 2576.58,1044.63L2541.97,1038.53C2540.78,1041.07 2539.19,1043.31 2537.16,1045.23L2578.03,1052.44Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2594.44,1049.44C2594.51,1048.9 2594.57,1048.36 2594.64,1047.82L2594.17,1047.74C2594.24,1048.31 2594.34,1048.87 2594.44,1049.44Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2594.44,1049.44C2594.51,1048.9 2594.57,1048.36 2594.64,1047.82L2594.17,1047.74C2594.24,1048.31 2594.34,1048.87 2594.44,1049.44Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2579.44,1052.68C2578.73,1050.1 2578.22,1047.49 2577.92,1044.87L2576.58,1044.63C2576.86,1047.24 2577.35,1049.85 2578.03,1052.44L2579.44,1052.68Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2579.44,1052.68C2578.73,1050.1 2578.22,1047.49 2577.92,1044.87L2576.58,1044.63C2576.86,1047.24 2577.35,1049.85 2578.03,1052.44L2579.44,1052.68Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2537.16,1045.23C2539.19,1043.31 2540.78,1041.07 2541.97,1038.53L2540.63,1038.3C2539.39,1040.86 2537.7,1043.07 2535.55,1044.94L2537.16,1045.23Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2537.16,1045.23C2539.19,1043.31 2540.78,1041.07 2541.97,1038.53L2540.63,1038.3C2539.39,1040.86 2537.7,1043.07 2535.55,1044.94L2537.16,1045.23Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2431.72,1023.81C2431.74,1022.21 2431.8,1020.64 2431.95,1019.13L2430.64,1018.9C2430.48,1020.48 2430.43,1022.11 2430.41,1023.76L2430.37,1026.4L2431.68,1026.63L2431.72,1023.81Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2431.72,1023.81C2431.74,1022.21 2431.8,1020.64 2431.95,1019.13L2430.64,1018.9C2430.48,1020.48 2430.43,1022.11 2430.41,1023.76L2430.37,1026.4L2431.68,1026.63L2431.72,1023.81Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2489.56,1034.7C2485.91,1034.71 2482.06,1034.71 2478.14,1034.82L2484.52,1035.95C2486.24,1035.94 2487.94,1035.93 2489.59,1035.93C2495.62,1035.92 2503.12,1035.91 2508.99,1033.2C2509.24,1033.08 2509.46,1032.96 2509.7,1032.84L2507.47,1032.45C2501.99,1034.66 2495.14,1034.69 2489.56,1034.7Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2489.56,1034.7C2485.91,1034.71 2482.06,1034.71 2478.14,1034.82L2484.52,1035.95C2486.24,1035.94 2487.94,1035.93 2489.59,1035.93C2495.62,1035.92 2503.12,1035.91 2508.99,1033.2C2509.24,1033.08 2509.46,1032.96 2509.7,1032.84L2507.47,1032.45C2501.99,1034.66 2495.14,1034.69 2489.56,1034.7Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2389.16,938.658L2383.06,937.582L2371.11,935.474C2374.68,938.478 2378.13,941.578 2381.34,944.823L2418,951.288C2414.4,947.873 2409.96,944.57 2404.66,941.392L2389.16,938.658Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2389.16,938.658L2383.06,937.582L2371.11,935.474C2374.68,938.478 2378.13,941.578 2381.34,944.823L2418,951.288C2414.4,947.873 2409.96,944.57 2404.66,941.392L2389.16,938.658Z" style="fill:rgb(247,165,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2347.57,938.868L2379.16,944.438C2375.84,941.183 2372.29,938.062 2368.62,935.036L2343.29,930.569C2342.44,931.464 2341.61,932.37 2340.78,933.286C2342.41,934.418 2343.98,935.593 2345.45,936.859C2346.16,937.48 2346.88,938.154 2347.57,938.868Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2347.57,938.868L2379.16,944.438C2375.84,941.183 2372.29,938.062 2368.62,935.036L2343.29,930.569C2342.44,931.464 2341.61,932.37 2340.78,933.286C2342.41,934.418 2343.98,935.593 2345.45,936.859C2346.16,937.48 2346.88,938.154 2347.57,938.868Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2344.56,937.752C2343.12,936.503 2341.57,935.329 2339.96,934.206C2339.12,935.156 2338.29,936.116 2337.47,937.089L2345.37,938.481C2345.1,938.233 2344.83,937.986 2344.56,937.752Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2344.56,937.752C2343.12,936.503 2341.57,935.329 2339.96,934.206C2339.12,935.156 2338.29,936.116 2337.47,937.089L2345.37,938.481C2345.1,938.233 2344.83,937.986 2344.56,937.752Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2576.45,971.682L2574.96,971.42L2551.1,967.213L2549.71,966.968L2469.69,952.858L2467.1,952.402L2408.05,941.989C2412.94,945.114 2417.02,948.345 2420.31,951.696L2457.04,958.174C2456.67,955.404 2455.96,952.718 2454.65,950.208L2456.25,950.489C2457.44,953.016 2458.08,955.683 2458.41,958.415L2502.29,966.152C2502.22,965.362 2502.14,964.562 2502.04,963.746C2501.82,961.976 2501.52,960.194 2501.19,958.414L2502.57,958.657C2502.87,960.316 2503.14,961.978 2503.34,963.634C2503.46,964.57 2503.55,965.484 2503.62,966.388L2533.57,971.668C2534.09,969.214 2534.95,966.845 2536.24,964.596L2537.62,964.838C2536.28,967.068 2535.4,969.435 2534.88,971.898L2586.93,981.077C2585.94,978.336 2584.86,975.632 2583.7,972.963L2582.98,972.836L2576.45,971.682ZM2473.82,955.453C2473.07,955.321 2472.33,955.103 2471.61,954.786C2470.4,954.257 2469.41,953.52 2468.61,952.667L2469.69,952.857L2471.08,953.102C2471.41,953.314 2471.76,953.508 2472.14,953.675C2473.82,954.409 2475.65,954.509 2477.35,954.208L2480.13,954.698C2478.25,955.483 2476.02,955.84 2473.82,955.453Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2576.45,971.682L2574.96,971.42L2551.1,967.213L2549.71,966.968L2469.69,952.858L2467.1,952.402L2408.05,941.989C2412.94,945.114 2417.02,948.345 2420.31,951.696L2457.04,958.174C2456.67,955.404 2455.96,952.718 2454.65,950.208L2456.25,950.489C2457.44,953.016 2458.08,955.683 2458.41,958.415L2502.29,966.152C2502.22,965.362 2502.14,964.562 2502.04,963.746C2501.82,961.976 2501.52,960.194 2501.19,958.414L2502.57,958.657C2502.87,960.316 2503.14,961.978 2503.34,963.634C2503.46,964.57 2503.55,965.484 2503.62,966.388L2533.57,971.668C2534.09,969.214 2534.95,966.845 2536.24,964.596L2537.62,964.838C2536.28,967.068 2535.4,969.435 2534.88,971.898L2586.93,981.077C2585.94,978.336 2584.86,975.632 2583.7,972.963L2582.98,972.836L2576.45,971.682ZM2473.82,955.453C2473.07,955.321 2472.33,955.103 2471.61,954.786C2470.4,954.257 2469.41,953.52 2468.61,952.667L2469.69,952.857L2471.08,953.102C2471.41,953.314 2471.76,953.508 2472.14,953.675C2473.82,954.409 2475.65,954.509 2477.35,954.208L2480.13,954.698C2478.25,955.483 2476.02,955.84 2473.82,955.453Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2537.62,964.835L2536.25,964.593C2534.95,966.842 2534.09,969.211 2533.57,971.665L2534.88,971.896C2535.4,969.433 2536.28,967.065 2537.62,964.835Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2537.62,964.835L2536.25,964.593C2534.95,966.842 2534.09,969.211 2533.57,971.665L2534.88,971.896C2535.4,969.433 2536.28,967.065 2537.62,964.835Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2404.66,941.392C2409.96,944.571 2414.4,947.873 2418,951.288L2420.31,951.695C2417.02,948.344 2412.94,945.114 2408.05,941.988L2404.66,941.392Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2404.66,941.392C2409.96,944.571 2414.4,947.873 2418,951.288L2420.31,951.695C2417.02,948.344 2412.94,945.114 2408.05,941.988L2404.66,941.392Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2345.37,938.481L2347.57,938.868C2346.88,938.154 2346.16,937.48 2345.45,936.859C2343.98,935.593 2342.41,934.418 2340.78,933.286C2340.51,933.592 2340.23,933.896 2339.96,934.205C2341.57,935.328 2343.12,936.502 2344.57,937.751C2344.83,937.986 2345.1,938.233 2345.37,938.481Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2345.37,938.481L2347.57,938.868C2346.88,938.154 2346.16,937.48 2345.45,936.859C2343.98,935.593 2342.41,934.418 2340.78,933.286C2340.51,933.592 2340.23,933.896 2339.96,934.205C2341.57,935.328 2343.12,936.502 2344.57,937.751C2344.83,937.986 2345.1,938.233 2345.37,938.481Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2456.25,950.487L2454.65,950.206C2455.96,952.717 2456.67,955.402 2457.04,958.172L2458.41,958.413C2458.08,955.681 2457.44,953.014 2456.25,950.487Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2456.25,950.487L2454.65,950.206C2455.96,952.717 2456.67,955.402 2457.04,958.172L2458.41,958.413C2458.08,955.681 2457.44,953.014 2456.25,950.487Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2502.57,958.654L2501.19,958.411C2501.52,960.191 2501.82,961.974 2502.04,963.743C2502.14,964.558 2502.22,965.359 2502.29,966.149L2503.62,966.384C2503.55,965.481 2503.46,964.566 2503.35,963.63C2503.14,961.975 2502.87,960.313 2502.57,958.654Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2502.57,958.654L2501.19,958.411C2501.52,960.191 2501.82,961.974 2502.04,963.743C2502.14,964.558 2502.22,965.359 2502.29,966.149L2503.62,966.384C2503.55,965.481 2503.46,964.566 2503.35,963.63C2503.14,961.975 2502.87,960.313 2502.57,958.654Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2472.14,953.675C2471.76,953.508 2471.41,953.313 2471.08,953.102L2469.69,952.857L2468.61,952.667C2469.41,953.52 2470.39,954.256 2471.6,954.786C2472.33,955.103 2473.07,955.321 2473.82,955.453C2476.02,955.841 2478.25,955.483 2480.13,954.699L2477.35,954.209C2475.65,954.509 2473.82,954.409 2472.14,953.675Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2472.14,953.675C2471.76,953.508 2471.41,953.313 2471.08,953.102L2469.69,952.857L2468.61,952.667C2469.41,953.52 2470.39,954.256 2471.6,954.786C2472.33,955.103 2473.07,955.321 2473.82,955.453C2476.02,955.841 2478.25,955.483 2480.13,954.699L2477.35,954.209C2475.65,954.509 2473.82,954.409 2472.14,953.675Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2368.62,935.036C2372.29,938.063 2375.85,941.183 2379.16,944.438L2381.34,944.822C2378.13,941.578 2374.68,938.477 2371.11,935.473L2368.62,935.036Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2368.62,935.036C2372.29,938.063 2375.85,941.183 2379.16,944.438L2381.34,944.822C2378.13,941.578 2374.68,938.477 2371.11,935.473L2368.62,935.036Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2517.94,1070.71C2519.19,1072.61 2520.58,1074.47 2522.62,1074.46C2524.19,1074.45 2525.37,1073.28 2526.21,1070.87L2532.15,1053.82C2532.36,1053.21 2532.56,1052.6 2532.76,1052L2463.45,1039.78C2466.93,1041.65 2470.74,1043.06 2474.08,1044.27C2489.59,1049.94 2508.91,1057 2517.94,1070.71Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2533.4,1054.22L2527.46,1071.27C2526.45,1074.19 2524.83,1075.68 2522.66,1075.69C2522.37,1075.69 2522.09,1075.66 2521.83,1075.62C2519.35,1075.18 2517.79,1072.81 2516.82,1071.34C2508.03,1057.99 2488.95,1051.02 2473.62,1045.41C2469.32,1043.84 2464.23,1041.98 2460.02,1039.17L2425.36,1033.06C2426.01,1035.74 2427.19,1038.27 2428.92,1040.62C2435.01,1048.92 2446.43,1053.28 2457.48,1057.48C2461.06,1058.84 2464.76,1060.25 2468.22,1061.78C2482.9,1068.23 2495.74,1078.64 2504.37,1091.09C2509.47,1098.44 2512.97,1106.32 2514.72,1114.2L2538.58,1118.41C2537.94,1116.71 2537.25,1115.02 2536.57,1113.34C2536.05,1112.05 2535.53,1110.75 2535.03,1109.47C2532.09,1101.9 2528.85,1090.88 2534.64,1082.5C2538,1077.64 2544.17,1074.34 2552.01,1073.2C2559.33,1072.13 2567.48,1073.08 2574.35,1075.8C2578.53,1077.45 2582.49,1079.67 2586.23,1082.43C2588.64,1076.01 2590.64,1069.35 2592.16,1062.47L2534.07,1052.23C2533.86,1052.89 2533.63,1053.56 2533.4,1054.22Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2556.98,1106.92C2555.36,1109.31 2557.35,1112.56 2559.12,1115.42L2559.23,1115.61C2560.64,1117.9 2561.82,1120.27 2562.77,1122.67L2563.11,1122.73C2566.27,1118.83 2569.25,1114.75 2572.01,1110.51C2570.5,1108.63 2568.65,1106.85 2566.2,1105.76C2563.13,1104.41 2558.59,1104.55 2556.98,1106.92Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2378.42,1070.09C2380.93,1070.29 2383.41,1069.73 2385.8,1069.19C2386.36,1069.07 2386.92,1068.94 2387.48,1068.82C2391.2,1068.05 2399.52,1069.19 2408.34,1070.4C2417.1,1071.6 2429.1,1073.25 2429.91,1071.38C2430.88,1069.15 2425.46,1065.16 2421.5,1062.25C2419.54,1060.8 2417.84,1059.56 2416.88,1058.55C2413.88,1055.42 2411.96,1051.95 2410.1,1048.59C2408.44,1045.59 2406.87,1042.75 2404.58,1040.11C2403.53,1038.9 2401.73,1037.58 2399.66,1037.21C2399.16,1037.12 2398.65,1037.09 2398.13,1037.13C2395.52,1037.32 2393.73,1039.16 2392.12,1041.02L2374.15,1061.85C2372.95,1063.24 2371.96,1064.51 2372.11,1065.91C2372.34,1068.19 2375.57,1069.85 2378.42,1070.09Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2327.12,1036.88C2340.41,1042.57 2355.97,1037.04 2363.86,1028.94C2365.62,1027.12 2367.17,1025.15 2368.51,1023.04L2354.2,1020.51C2353.41,1021.81 2352.48,1023.06 2351.3,1024.15C2348.4,1026.82 2344.19,1028.08 2340.46,1027.42C2340.32,1027.4 2340.18,1027.37 2340.04,1027.34C2336.12,1026.49 2332.77,1023.51 2331.48,1019.75C2331.1,1018.64 2330.92,1017.51 2330.85,1016.4L2311.73,1013.03C2311.66,1023.98 2318.39,1033.14 2327.12,1036.88Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2351.05,1053.48C2366.71,1048.39 2379.14,1038.39 2386.18,1026.15L2369.89,1023.28C2368.46,1025.62 2366.78,1027.79 2364.84,1029.78C2357.92,1036.88 2345.29,1042.09 2333.18,1039.95C2330.96,1039.56 2328.75,1038.92 2326.6,1038C2319.48,1034.95 2314.27,1029.26 2311.92,1021.98C2310.97,1019.04 2310.53,1015.93 2310.56,1012.82L2304.73,1011.79C2303.14,1023.85 2303.08,1035.78 2304.4,1047.39C2315.51,1057.6 2336.23,1058.3 2351.05,1053.48Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2340.31,1026.14C2343.7,1026.88 2347.66,1025.76 2350.36,1023.27C2351.34,1022.36 2352.14,1021.35 2352.82,1020.27L2332.18,1016.63C2332.26,1017.56 2332.42,1018.49 2332.74,1019.41C2333.88,1022.74 2336.85,1025.39 2340.31,1026.14Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2360.17,1085.47C2358.18,1085.52 2356.18,1085.57 2354.18,1085.63C2353.75,1085.64 2353.31,1085.65 2352.87,1085.66L2430.67,1099.38C2426.23,1096.28 2420.88,1093.5 2414.58,1091.31C2396.86,1085.13 2377.2,1085.05 2360.17,1085.47Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2354.11,1084.4C2356.11,1084.34 2358.11,1084.29 2360.1,1084.24C2377.26,1083.82 2397.07,1083.9 2415.01,1090.15C2421.69,1092.48 2427.99,1095.84 2433.28,1099.84L2513.32,1113.96C2511.57,1106.38 2508.18,1098.82 2503.27,1091.74C2494.78,1079.49 2482.14,1069.24 2467.68,1062.88C2464.26,1061.38 2460.57,1059.97 2457.01,1058.62C2445.77,1054.34 2434.16,1049.92 2427.84,1041.3C2425.9,1038.66 2424.62,1035.83 2423.96,1032.81L2387.54,1026.39C2380.39,1039.05 2367.63,1049.4 2351.51,1054.64C2342.75,1057.49 2331.96,1058.44 2322.19,1056.72C2315.54,1055.55 2309.36,1053.13 2304.61,1049.23C2305.88,1059.24 2308.18,1068.99 2311.42,1078.36L2346.77,1084.59C2349.23,1084.53 2351.68,1084.47 2354.11,1084.4ZM2373.11,1061.06L2391.09,1040.24C2392.8,1038.26 2394.88,1036.13 2397.99,1035.9C2400.63,1035.71 2403.61,1037.06 2405.6,1039.35C2407.98,1042.1 2409.65,1045.12 2411.27,1048.05C2413.08,1051.34 2414.96,1054.73 2417.85,1057.75C2418.73,1058.66 2420.47,1059.94 2422.3,1061.29C2427.07,1064.8 2432.48,1068.78 2431.14,1071.87C2430,1074.47 2421.67,1073.47 2408.18,1071.61C2399.87,1070.47 2391.28,1069.3 2387.8,1070.02C2387.24,1070.14 2386.69,1070.26 2386.14,1070.39C2383.64,1070.95 2381.06,1071.53 2378.34,1071.31C2378.01,1071.29 2377.67,1071.24 2377.34,1071.18C2374.18,1070.63 2371.07,1068.68 2370.8,1066C2370.59,1063.99 2372.05,1062.3 2373.11,1061.06Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2535.75,1083.19C2530.27,1091.11 2533.42,1101.76 2536.26,1109.07C2536.76,1110.35 2537.28,1111.64 2537.8,1112.93C2538.57,1114.83 2539.34,1116.74 2540.05,1118.67L2546.6,1119.83C2545.28,1112.57 2545.47,1105.23 2547.26,1098.21C2548.81,1092.14 2551.68,1088.45 2556.03,1086.93C2564.05,1084.13 2573.32,1089.84 2578.83,1095.59C2579.2,1095.98 2579.55,1096.39 2579.92,1096.79C2582.07,1092.53 2584.02,1088.15 2585.76,1083.64C2582.03,1080.83 2578.05,1078.58 2573.86,1076.92C2561.05,1071.86 2542.68,1073.17 2535.75,1083.19Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2556.52,1088.09C2552.6,1089.45 2549.99,1092.87 2548.55,1098.52C2546.76,1105.51 2546.6,1112.83 2547.97,1120.07L2561.26,1122.41C2560.38,1120.3 2559.34,1118.22 2558.09,1116.2L2557.98,1116.01C2556.12,1113 2553.82,1109.25 2555.87,1106.24C2557.86,1103.3 2563.1,1103.05 2566.74,1104.65C2569.26,1105.77 2571.17,1107.53 2572.73,1109.39C2575.08,1105.71 2577.28,1101.91 2579.31,1097.99C2578.83,1097.46 2578.35,1096.91 2577.85,1096.39C2572.64,1090.95 2563.91,1085.5 2556.52,1088.09Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2363.86,1028.94C2355.97,1037.04 2340.41,1042.57 2327.12,1036.88C2318.39,1033.14 2311.66,1023.98 2311.73,1013.02L2310.55,1012.82C2310.53,1015.93 2310.97,1019.04 2311.92,1021.98C2314.27,1029.26 2319.48,1034.95 2326.6,1038C2328.75,1038.92 2330.96,1039.56 2333.18,1039.95C2345.28,1042.09 2357.91,1036.88 2364.84,1029.77C2366.78,1027.78 2368.46,1025.62 2369.89,1023.28L2368.51,1023.03C2367.17,1025.15 2365.62,1027.12 2363.86,1028.94Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2351.51,1054.64C2367.63,1049.4 2380.39,1039.05 2387.54,1026.39L2386.17,1026.15C2379.14,1038.39 2366.71,1048.39 2351.05,1053.48C2336.23,1058.3 2315.51,1057.6 2304.4,1047.39C2304.47,1048 2304.53,1048.62 2304.61,1049.23C2309.36,1053.13 2315.54,1055.55 2322.19,1056.72C2331.96,1058.44 2342.75,1057.49 2351.51,1054.64Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2340.04,1027.34C2340.18,1027.37 2340.32,1027.4 2340.46,1027.42C2344.19,1028.08 2348.4,1026.82 2351.3,1024.15C2352.48,1023.06 2353.41,1021.81 2354.2,1020.51L2352.82,1020.27C2352.14,1021.34 2351.34,1022.36 2350.36,1023.27C2347.65,1025.76 2343.7,1026.88 2340.3,1026.14C2336.85,1025.39 2333.88,1022.74 2332.74,1019.4C2332.42,1018.49 2332.26,1017.56 2332.18,1016.63L2330.85,1016.39C2330.91,1017.51 2331.1,1018.63 2331.48,1019.75C2332.77,1023.51 2336.12,1026.49 2340.04,1027.34Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2360.1,1084.24C2358.11,1084.29 2356.11,1084.35 2354.11,1084.4C2351.68,1084.47 2349.23,1084.53 2346.77,1084.59L2352.87,1085.66C2353.31,1085.65 2353.75,1085.64 2354.18,1085.63C2356.18,1085.57 2358.18,1085.52 2360.17,1085.47C2377.2,1085.06 2396.86,1085.13 2414.58,1091.31C2420.88,1093.5 2426.22,1096.28 2430.67,1099.38L2433.27,1099.84C2427.99,1095.84 2421.68,1092.48 2415.01,1090.15C2397.07,1083.9 2377.26,1083.82 2360.1,1084.24Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2468.22,1061.78C2464.76,1060.25 2461.06,1058.84 2457.48,1057.48C2446.43,1053.28 2435.01,1048.93 2428.91,1040.62C2427.19,1038.27 2426.01,1035.74 2425.36,1033.06L2423.96,1032.81C2424.62,1035.83 2425.9,1038.66 2427.83,1041.3C2434.16,1049.92 2445.77,1054.34 2457.01,1058.62C2460.57,1059.97 2464.25,1061.38 2467.68,1062.88C2482.14,1069.24 2494.77,1079.49 2503.27,1091.74C2508.18,1098.82 2511.57,1106.38 2513.32,1113.96L2514.72,1114.2C2512.96,1106.32 2509.47,1098.44 2504.37,1091.09C2495.74,1078.64 2482.9,1068.23 2468.22,1061.78Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2574.35,1075.79C2567.47,1073.08 2559.33,1072.13 2552.01,1073.2C2544.17,1074.34 2538,1077.64 2534.64,1082.5C2528.85,1090.88 2532.09,1101.9 2535.03,1109.47C2535.53,1110.75 2536.05,1112.04 2536.57,1113.34C2537.25,1115.02 2537.93,1116.71 2538.57,1118.41L2540.05,1118.67C2539.34,1116.74 2538.57,1114.83 2537.8,1112.93C2537.28,1111.64 2536.76,1110.35 2536.26,1109.07C2533.42,1101.76 2530.27,1091.11 2535.75,1083.19C2542.68,1073.17 2561.05,1071.86 2573.86,1076.92C2578.05,1078.58 2582.03,1080.83 2585.76,1083.64C2585.92,1083.24 2586.07,1082.84 2586.22,1082.43C2582.49,1079.67 2578.53,1077.45 2574.35,1075.79Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2556.03,1086.93C2551.68,1088.45 2548.81,1092.14 2547.26,1098.21C2545.48,1105.23 2545.28,1112.57 2546.6,1119.82L2547.97,1120.07C2546.6,1112.83 2546.76,1105.51 2548.55,1098.52C2549.99,1092.87 2552.6,1089.45 2556.52,1088.09C2563.92,1085.5 2572.64,1090.94 2577.86,1096.39C2578.36,1096.91 2578.83,1097.46 2579.31,1097.99C2579.52,1097.59 2579.72,1097.19 2579.92,1096.79C2579.55,1096.39 2579.2,1095.98 2578.83,1095.59C2573.32,1089.84 2564.05,1084.13 2556.03,1086.93Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2555.87,1106.24C2553.82,1109.26 2556.12,1113 2557.98,1116.01L2558.09,1116.2C2559.34,1118.22 2560.38,1120.3 2561.26,1122.41L2562.77,1122.68C2561.82,1120.27 2560.64,1117.91 2559.23,1115.61L2559.12,1115.42C2557.35,1112.56 2555.36,1109.32 2556.98,1106.92C2558.6,1104.55 2563.14,1104.41 2566.2,1105.77C2568.65,1106.85 2570.5,1108.64 2572.01,1110.51C2572.26,1110.14 2572.49,1109.77 2572.73,1109.39C2571.17,1107.53 2569.26,1105.77 2566.74,1104.66C2563.1,1103.05 2557.86,1103.3 2555.87,1106.24Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2516.82,1071.34C2517.79,1072.81 2519.35,1075.18 2521.83,1075.62C2522.09,1075.66 2522.37,1075.69 2522.66,1075.69C2524.83,1075.68 2526.45,1074.19 2527.46,1071.27L2533.4,1054.22C2533.64,1053.56 2533.86,1052.89 2534.07,1052.23L2532.76,1052C2532.56,1052.6 2532.36,1053.21 2532.15,1053.82L2526.21,1070.87C2525.37,1073.28 2524.19,1074.45 2522.62,1074.46C2520.58,1074.47 2519.19,1072.61 2517.94,1070.71C2508.91,1057 2489.59,1049.94 2474.08,1044.27C2470.74,1043.05 2466.93,1041.65 2463.45,1039.78L2460.02,1039.17C2464.23,1041.97 2469.32,1043.84 2473.62,1045.41C2488.95,1051.02 2508.03,1057.99 2516.82,1071.34Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2377.34,1071.18C2377.67,1071.24 2378.01,1071.29 2378.34,1071.31C2381.06,1071.53 2383.64,1070.95 2386.14,1070.39C2386.7,1070.26 2387.25,1070.14 2387.8,1070.02C2391.28,1069.3 2399.87,1070.47 2408.18,1071.61C2421.67,1073.47 2430,1074.47 2431.14,1071.87C2432.48,1068.78 2427.07,1064.8 2422.3,1061.29C2420.47,1059.94 2418.74,1058.66 2417.85,1057.75C2414.97,1054.73 2413.09,1051.34 2411.27,1048.05C2409.65,1045.12 2407.98,1042.1 2405.6,1039.35C2403.62,1037.06 2400.63,1035.71 2397.99,1035.9C2394.88,1036.13 2392.8,1038.26 2391.09,1040.24L2373.12,1061.06C2372.05,1062.3 2370.59,1063.99 2370.8,1066C2371.07,1068.68 2374.18,1070.63 2377.34,1071.18ZM2374.15,1061.85L2392.12,1041.02C2393.73,1039.16 2395.52,1037.32 2398.13,1037.13C2398.65,1037.09 2399.16,1037.12 2399.66,1037.21C2401.73,1037.58 2403.53,1038.9 2404.58,1040.11C2406.87,1042.76 2408.44,1045.59 2410.1,1048.59C2411.96,1051.95 2413.88,1055.42 2416.88,1058.55C2417.84,1059.56 2419.54,1060.8 2421.5,1062.25C2425.46,1065.16 2430.89,1069.15 2429.91,1071.38C2429.1,1073.25 2417.1,1071.6 2408.34,1070.4C2399.52,1069.19 2391.2,1068.05 2387.48,1068.82C2386.92,1068.94 2386.36,1069.07 2385.8,1069.19C2383.41,1069.73 2380.93,1070.29 2378.42,1070.09C2375.57,1069.85 2372.34,1068.19 2372.11,1065.91C2371.96,1064.51 2372.95,1063.24 2374.15,1061.85Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2562.9,1122.99C2562.97,1122.9 2563.04,1122.82 2563.11,1122.73L2562.77,1122.67C2562.81,1122.78 2562.86,1122.88 2562.9,1122.99Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2562.9,1122.99C2562.97,1122.9 2563.04,1122.82 2563.11,1122.73L2562.77,1122.67C2562.81,1122.78 2562.86,1122.88 2562.9,1122.99Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2311.42,1078.36C2311.99,1080.03 2312.6,1081.69 2313.23,1083.34C2324.35,1084.82 2335.65,1084.84 2346.77,1084.59L2311.42,1078.36Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2311.42,1078.36C2311.99,1080.03 2312.6,1081.69 2313.23,1083.34C2324.35,1084.82 2335.65,1084.84 2346.77,1084.59L2311.42,1078.36Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2547.97,1120.07C2548.48,1122.73 2549.18,1125.37 2550.1,1127.99L2557.47,1129.29C2559,1127.61 2560.49,1125.91 2561.94,1124.16C2561.73,1123.57 2561.5,1122.99 2561.26,1122.41L2547.97,1120.07Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2547.97,1120.07C2548.48,1122.73 2549.18,1125.37 2550.1,1127.99L2557.47,1129.29C2559,1127.61 2560.49,1125.91 2561.94,1124.16C2561.73,1123.57 2561.5,1122.99 2561.26,1122.41L2547.97,1120.07Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2548.65,1127.73C2547.76,1125.12 2547.08,1122.48 2546.6,1119.82L2540.05,1118.67C2541.03,1121.31 2541.89,1123.97 2542.46,1126.64L2548.65,1127.73Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2548.65,1127.73C2547.76,1125.12 2547.08,1122.48 2546.6,1119.82L2540.05,1118.67C2541.03,1121.31 2541.89,1123.97 2542.46,1126.64L2548.65,1127.73Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2379.43,1097.89L2380.74,1098.12L2410.99,1103.46L2412.31,1103.69L2446.95,1109.8L2448.28,1110.03L2470.99,1114.04L2472.72,1114.34L2530.13,1124.47L2531.75,1124.75L2541.07,1126.39C2540.47,1123.73 2539.57,1121.06 2538.58,1118.41L2514.72,1114.2C2515.3,1116.79 2515.68,1119.38 2515.88,1121.95L2514.54,1121.72C2514.33,1119.14 2513.92,1116.55 2513.32,1113.96L2433.28,1099.84C2435.05,1101.18 2436.7,1102.6 2438.22,1104.07C2439.89,1105.68 2441.38,1107.35 2442.67,1109.04L2440.79,1108.71C2438.18,1105.45 2434.82,1102.28 2430.67,1099.38L2352.87,1085.66C2339.98,1086.02 2326.76,1086.32 2313.75,1084.65C2313.99,1085.25 2314.23,1085.84 2314.47,1086.44L2379.43,1097.89ZM2357.42,1094.01C2362.57,1094.65 2367.73,1095.56 2372.85,1096.73L2357.42,1094.01Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2379.43,1097.89L2380.74,1098.12L2410.99,1103.46L2412.31,1103.69L2446.95,1109.8L2448.28,1110.03L2470.99,1114.04L2472.72,1114.34L2530.13,1124.47L2531.75,1124.75L2541.07,1126.39C2540.47,1123.73 2539.57,1121.06 2538.58,1118.41L2514.72,1114.2C2515.3,1116.79 2515.68,1119.38 2515.88,1121.95L2514.54,1121.72C2514.33,1119.14 2513.92,1116.55 2513.32,1113.96L2433.28,1099.84C2435.05,1101.18 2436.7,1102.6 2438.22,1104.07C2439.89,1105.68 2441.38,1107.35 2442.67,1109.04L2440.79,1108.71C2438.18,1105.45 2434.82,1102.28 2430.67,1099.38L2352.87,1085.66C2339.98,1086.02 2326.76,1086.32 2313.75,1084.65C2313.99,1085.25 2314.23,1085.84 2314.47,1086.44L2379.43,1097.89ZM2357.42,1094.01C2362.57,1094.65 2367.73,1095.56 2372.85,1096.73L2357.42,1094.01Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2357.42,1094.01L2372.85,1096.73C2367.73,1095.56 2362.57,1094.65 2357.42,1094.01Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2357.42,1094.01L2372.85,1096.73C2367.73,1095.56 2362.57,1094.65 2357.42,1094.01Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2440.79,1108.71L2442.67,1109.04C2441.38,1107.34 2439.89,1105.68 2438.22,1104.06C2436.7,1102.59 2435.05,1101.18 2433.28,1099.84L2430.68,1099.38C2434.82,1102.28 2438.18,1105.45 2440.79,1108.71Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2440.79,1108.71L2442.67,1109.04C2441.38,1107.34 2439.89,1105.68 2438.22,1104.06C2436.7,1102.59 2435.05,1101.18 2433.28,1099.84L2430.68,1099.38C2434.82,1102.28 2438.18,1105.45 2440.79,1108.71Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2346.77,1084.59C2335.65,1084.84 2324.35,1084.82 2313.23,1083.34C2313.4,1083.78 2313.58,1084.21 2313.75,1084.65C2326.76,1086.32 2339.99,1086.02 2352.87,1085.66L2346.77,1084.59Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2346.77,1084.59C2335.65,1084.84 2324.35,1084.82 2313.23,1083.34C2313.4,1083.78 2313.58,1084.21 2313.75,1084.65C2326.76,1086.32 2339.99,1086.02 2352.87,1085.66L2346.77,1084.59Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2514.54,1121.72L2515.88,1121.95C2515.68,1119.38 2515.3,1116.79 2514.72,1114.2L2513.32,1113.96C2513.92,1116.55 2514.33,1119.14 2514.54,1121.72Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2514.54,1121.72L2515.88,1121.95C2515.68,1119.38 2515.3,1116.79 2514.72,1114.2L2513.32,1113.96C2513.92,1116.55 2514.33,1119.14 2514.54,1121.72Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2542.46,1126.64C2541.89,1123.97 2541.03,1121.31 2540.05,1118.67L2538.58,1118.41C2539.57,1121.06 2540.47,1123.73 2541.07,1126.39L2542.46,1126.64Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2542.46,1126.64C2541.89,1123.97 2541.03,1121.31 2540.05,1118.67L2538.58,1118.41C2539.57,1121.06 2540.47,1123.73 2541.07,1126.39L2542.46,1126.64Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2550.1,1127.99C2549.18,1125.37 2548.47,1122.73 2547.97,1120.07L2546.6,1119.83C2547.08,1122.48 2547.76,1125.12 2548.65,1127.73L2550.1,1127.99Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2550.1,1127.99C2549.18,1125.37 2548.47,1122.73 2547.97,1120.07L2546.6,1119.83C2547.08,1122.48 2547.76,1125.12 2548.65,1127.73L2550.1,1127.99Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2561.26,1122.41C2561.5,1122.99 2561.73,1123.58 2561.94,1124.16C2562.26,1123.77 2562.58,1123.38 2562.9,1122.99C2562.86,1122.88 2562.81,1122.78 2562.77,1122.67L2561.26,1122.41Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2561.26,1122.41C2561.5,1122.99 2561.73,1123.58 2561.94,1124.16C2562.26,1123.77 2562.58,1123.38 2562.9,1122.99C2562.86,1122.88 2562.81,1122.78 2562.77,1122.67L2561.26,1122.41Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2311.51,1005.44L2312.78,1005.67C2312.08,1008.18 2311.75,1010.64 2311.73,1013.02L2330.85,1016.39C2330.7,1014.07 2331.1,1011.79 2331.59,1009.76L2332.23,1009.9L2331.59,1009.74C2331.64,1009.51 2331.7,1009.26 2331.76,1009.01L2333.07,1009.24C2333,1009.52 2332.93,1009.79 2332.88,1010.03L2332.87,1010.05C2332.39,1012.08 2331.99,1014.35 2332.18,1016.63L2352.82,1020.27C2353.88,1018.6 2354.67,1016.79 2355.44,1015.01C2355.68,1014.45 2355.92,1013.89 2356.16,1013.32L2357.49,1013.55C2357.22,1014.21 2356.93,1014.86 2356.66,1015.49C2355.96,1017.12 2355.2,1018.86 2354.2,1020.51L2368.51,1023.03C2369.86,1020.88 2370.99,1018.57 2371.9,1016.09L2373.22,1016.32C2372.33,1018.8 2371.21,1021.11 2369.89,1023.28L2386.17,1026.15C2387.47,1023.9 2388.6,1021.58 2389.52,1019.2L2305.89,1004.45C2305.82,1004.78 2305.76,1005.11 2305.7,1005.44C2305.32,1007.56 2305.01,1009.68 2304.73,1011.79L2310.55,1012.82C2310.58,1010.33 2310.88,1007.85 2311.51,1005.44Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2311.51,1005.44L2312.78,1005.67C2312.08,1008.18 2311.75,1010.64 2311.73,1013.02L2330.85,1016.39C2330.7,1014.07 2331.1,1011.79 2331.59,1009.76L2332.23,1009.9L2331.59,1009.74C2331.64,1009.51 2331.7,1009.26 2331.76,1009.01L2333.07,1009.24C2333,1009.52 2332.93,1009.79 2332.88,1010.03L2332.87,1010.05C2332.39,1012.08 2331.99,1014.35 2332.18,1016.63L2352.82,1020.27C2353.88,1018.6 2354.67,1016.79 2355.44,1015.01C2355.68,1014.45 2355.92,1013.89 2356.16,1013.32L2357.49,1013.55C2357.22,1014.21 2356.93,1014.86 2356.66,1015.49C2355.96,1017.12 2355.2,1018.86 2354.2,1020.51L2368.51,1023.03C2369.86,1020.88 2370.99,1018.57 2371.9,1016.09L2373.22,1016.32C2372.33,1018.8 2371.21,1021.11 2369.89,1023.28L2386.17,1026.15C2387.47,1023.9 2388.6,1021.58 2389.52,1019.2L2305.89,1004.45C2305.82,1004.78 2305.76,1005.11 2305.7,1005.44C2305.32,1007.56 2305.01,1009.68 2304.73,1011.79L2310.55,1012.82C2310.58,1010.33 2310.88,1007.85 2311.51,1005.44Z" style="fill:rgb(247,165,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2578.03,1052.44L2537.16,1045.23L2535.55,1044.95L2484.52,1035.95L2478.14,1034.82L2431.68,1026.63L2430.37,1026.4L2390.83,1019.43C2389.93,1021.82 2388.81,1024.13 2387.54,1026.39L2423.96,1032.81C2423.42,1030.39 2423.27,1027.86 2423.55,1025.2L2424.86,1025.43C2424.59,1028.1 2424.77,1030.65 2425.36,1033.06L2460.02,1039.17C2458.68,1038.28 2457.43,1037.31 2456.33,1036.21C2454.13,1034.03 2452.97,1032.04 2452.43,1030.29L2453.92,1030.55C2454.52,1032.13 2455.64,1033.76 2457.28,1035.38C2459.02,1037.11 2461.16,1038.54 2463.45,1039.78L2532.76,1052C2533.56,1049.51 2534.19,1047.07 2534.25,1044.71L2535.55,1044.94L2535.57,1044.95C2535.48,1047.33 2534.86,1049.76 2534.07,1052.23L2592.16,1062.47C2592.62,1060.39 2593.04,1058.29 2593.42,1056.17C2593.47,1055.84 2593.52,1055.51 2593.58,1055.18L2579.44,1052.68L2578.03,1052.44Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2578.03,1052.44L2537.16,1045.23L2535.55,1044.95L2484.52,1035.95L2478.14,1034.82L2431.68,1026.63L2430.37,1026.4L2390.83,1019.43C2389.93,1021.82 2388.81,1024.13 2387.54,1026.39L2423.96,1032.81C2423.42,1030.39 2423.27,1027.86 2423.55,1025.2L2424.86,1025.43C2424.59,1028.1 2424.77,1030.65 2425.36,1033.06L2460.02,1039.17C2458.68,1038.28 2457.43,1037.31 2456.33,1036.21C2454.13,1034.03 2452.97,1032.04 2452.43,1030.29L2453.92,1030.55C2454.52,1032.13 2455.64,1033.76 2457.28,1035.38C2459.02,1037.11 2461.16,1038.54 2463.45,1039.78L2532.76,1052C2533.56,1049.51 2534.19,1047.07 2534.25,1044.71L2535.55,1044.94L2535.57,1044.95C2535.48,1047.33 2534.86,1049.76 2534.07,1052.23L2592.16,1062.47C2592.62,1060.39 2593.04,1058.29 2593.42,1056.17C2593.47,1055.84 2593.52,1055.51 2593.58,1055.18L2579.44,1052.68L2578.03,1052.44Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2312.78,1005.67L2311.51,1005.44C2310.88,1007.85 2310.57,1010.33 2310.56,1012.82L2311.73,1013.02C2311.75,1010.64 2312.08,1008.18 2312.78,1005.67Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2312.78,1005.67L2311.51,1005.44C2310.88,1007.85 2310.57,1010.33 2310.56,1012.82L2311.73,1013.02C2311.75,1010.64 2312.08,1008.18 2312.78,1005.67Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2371.9,1016.09C2370.99,1018.57 2369.86,1020.88 2368.51,1023.03L2369.89,1023.28C2371.22,1021.11 2372.33,1018.8 2373.22,1016.32L2371.9,1016.09Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2371.9,1016.09C2370.99,1018.57 2369.86,1020.88 2368.51,1023.03L2369.89,1023.28C2371.22,1021.11 2372.33,1018.8 2373.22,1016.32L2371.9,1016.09Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2389.52,1019.2C2388.6,1021.58 2387.47,1023.9 2386.18,1026.15L2387.54,1026.39C2388.81,1024.14 2389.93,1021.82 2390.84,1019.43L2389.52,1019.2Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2389.52,1019.2C2388.6,1021.58 2387.47,1023.9 2386.18,1026.15L2387.54,1026.39C2388.81,1024.14 2389.93,1021.82 2390.84,1019.43L2389.52,1019.2Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2332.87,1010.05L2332.88,1010.03C2332.93,1009.79 2333,1009.52 2333.07,1009.24L2331.76,1009.01C2331.7,1009.27 2331.64,1009.51 2331.59,1009.74L2332.23,1009.9L2331.59,1009.76C2331.11,1011.79 2330.7,1014.07 2330.85,1016.39L2332.18,1016.63C2331.99,1014.35 2332.39,1012.08 2332.87,1010.05Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2332.87,1010.05L2332.88,1010.03C2332.93,1009.79 2333,1009.52 2333.07,1009.24L2331.76,1009.01C2331.7,1009.27 2331.64,1009.51 2331.59,1009.74L2332.23,1009.9L2331.59,1009.76C2331.11,1011.79 2330.7,1014.07 2330.85,1016.39L2332.18,1016.63C2331.99,1014.35 2332.39,1012.08 2332.87,1010.05Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2356.66,1015.49C2356.94,1014.86 2357.22,1014.21 2357.49,1013.55L2356.16,1013.32C2355.92,1013.89 2355.68,1014.45 2355.44,1015.01C2354.67,1016.79 2353.88,1018.6 2352.82,1020.27L2354.2,1020.51C2355.2,1018.86 2355.96,1017.12 2356.66,1015.49Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2356.66,1015.49C2356.94,1014.86 2357.22,1014.21 2357.49,1013.55L2356.16,1013.32C2355.92,1013.89 2355.68,1014.45 2355.44,1015.01C2354.67,1016.79 2353.88,1018.6 2352.82,1020.27L2354.2,1020.51C2355.2,1018.86 2355.96,1017.12 2356.66,1015.49Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2424.87,1025.43L2423.55,1025.2C2423.27,1027.86 2423.42,1030.39 2423.96,1032.81L2425.36,1033.06C2424.77,1030.65 2424.59,1028.1 2424.87,1025.43Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2424.87,1025.43L2423.55,1025.2C2423.27,1027.86 2423.42,1030.39 2423.96,1032.81L2425.36,1033.06C2424.77,1030.65 2424.59,1028.1 2424.87,1025.43Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2457.28,1035.38C2455.64,1033.76 2454.52,1032.13 2453.92,1030.55L2452.43,1030.29C2452.97,1032.04 2454.13,1034.03 2456.33,1036.21C2457.43,1037.31 2458.68,1038.28 2460.02,1039.17L2463.45,1039.78C2461.16,1038.54 2459.02,1037.11 2457.28,1035.38Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2457.28,1035.38C2455.64,1033.76 2454.52,1032.13 2453.92,1030.55L2452.43,1030.29C2452.97,1032.04 2454.13,1034.03 2456.33,1036.21C2457.43,1037.31 2458.68,1038.28 2460.02,1039.17L2463.45,1039.78C2461.16,1038.54 2459.02,1037.11 2457.28,1035.38Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2535.57,1044.95L2535.55,1044.94L2534.25,1044.72C2534.19,1047.07 2533.57,1049.51 2532.76,1052L2534.07,1052.23C2534.87,1049.76 2535.48,1047.33 2535.57,1044.95Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2535.57,1044.95L2535.55,1044.94L2534.25,1044.72C2534.19,1047.07 2533.57,1049.51 2532.76,1052L2534.07,1052.23C2534.87,1049.76 2535.48,1047.33 2535.57,1044.95Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2350.37,1138.06C2361.12,1148.02 2373.44,1156.42 2387.01,1162.85C2380.78,1154.47 2369.74,1146.7 2354.03,1139.66C2352.83,1139.12 2351.59,1138.59 2350.37,1138.06Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2414.07,1114.73C2415.96,1118.19 2418.76,1121.4 2421.47,1124.51C2422.36,1125.53 2423.24,1126.55 2424.1,1127.58C2427.25,1131.35 2430.92,1136.38 2431.39,1141.78C2431.54,1143.55 2431.34,1145.3 2431.15,1147C2430.99,1148.45 2430.82,1149.96 2430.88,1151.44C2431.01,1154.27 2432.39,1157.83 2435.87,1159.36C2439.39,1160.89 2443.6,1159.67 2445.78,1157.74C2447.87,1155.89 2449.04,1153.2 2449.36,1149.5C2449.82,1144.16 2448.53,1138.64 2447.29,1133.29C2446.65,1130.56 2445.99,1127.74 2445.57,1124.96C2445.24,1122.8 2445.01,1120.01 2445.25,1117.04L2412.59,1111.29C2412.95,1112.41 2413.44,1113.56 2414.07,1114.73Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2463.46,1156.95C2462.18,1151 2460.85,1144.84 2460.68,1138.76C2460.49,1131.75 2462.08,1125.53 2465.18,1120.56L2446.55,1117.27C2446.33,1120.09 2446.56,1122.76 2446.87,1124.81C2447.29,1127.55 2447.94,1130.35 2448.57,1133.06C2449.84,1138.49 2451.14,1144.11 2450.67,1149.63C2450.32,1153.64 2449.02,1156.58 2446.7,1158.64C2444.6,1160.5 2441.03,1161.75 2437.55,1161.13C2436.8,1161 2436.06,1160.78 2435.34,1160.46C2431.3,1158.7 2429.71,1154.65 2429.56,1151.46C2429.49,1149.89 2429.67,1148.34 2429.84,1146.84C2430.03,1145.12 2430.21,1143.51 2430.07,1141.85C2429.64,1136.78 2426.1,1131.95 2423.07,1128.32C2422.21,1127.3 2421.33,1126.29 2420.45,1125.28C2417.7,1122.11 2414.85,1118.85 2412.9,1115.26C2412.12,1113.83 2411.55,1112.42 2411.15,1111.03L2380.09,1105.55C2380.01,1108.27 2380.1,1111 2380.48,1113.73C2381.81,1123.23 2386.59,1131.4 2393.93,1136.72C2396.75,1138.77 2400.05,1140.47 2403.24,1142.12C2407.98,1144.58 2412.88,1147.11 2416.35,1150.82C2422.67,1157.59 2422.62,1166.07 2422.4,1174.32C2422.99,1174.44 2423.59,1174.56 2424.19,1174.66C2438.42,1177.17 2452.53,1177.52 2466.19,1175.96C2466.19,1173.88 2466.05,1171.7 2465.77,1169.42C2465.25,1165.27 2464.34,1161.04 2463.46,1156.95Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2515.92,1156.41C2521.4,1153 2527.07,1149.46 2530.39,1144.57C2532.77,1141.07 2533.65,1136.9 2533,1132.52L2466.58,1120.8C2463.43,1125.65 2461.81,1131.79 2462,1138.75C2462.17,1144.74 2463.48,1150.84 2464.75,1156.74C2465.63,1160.85 2466.55,1165.1 2467.08,1169.31C2467.36,1171.57 2467.5,1173.74 2467.51,1175.81C2479.5,1174.33 2491.14,1171.37 2502.18,1167.09C2505.73,1162.77 2510.9,1159.54 2515.92,1156.41Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2531.51,1145.25C2528.04,1150.35 2522.26,1153.96 2516.67,1157.44C2512.51,1160.03 2508.24,1162.71 2504.95,1165.99C2522.15,1158.92 2537.86,1148.62 2551.2,1135.72L2534.36,1132.75C2534.95,1137.29 2533.99,1141.61 2531.51,1145.25Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2415.37,1151.62C2412.06,1148.07 2407.48,1145.7 2402.63,1143.2C2399.39,1141.52 2396.04,1139.79 2393.14,1137.69C2385.51,1132.15 2380.55,1123.69 2379.18,1113.86C2378.78,1111 2378.68,1108.15 2378.78,1105.32L2318.16,1094.63C2325.59,1109.88 2335.61,1123.77 2347.78,1135.61C2350.06,1136.57 2352.34,1137.55 2354.57,1138.56C2371.85,1146.3 2383.32,1154.66 2389.36,1163.94C2399.32,1168.43 2409.93,1171.87 2421.09,1174.08C2421.3,1166.12 2421.3,1157.97 2415.37,1151.62Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2516.67,1157.44C2522.26,1153.96 2528.04,1150.35 2531.51,1145.25C2533.99,1141.61 2534.95,1137.29 2534.36,1132.75L2533,1132.52C2533.65,1136.9 2532.77,1141.07 2530.39,1144.57C2527.07,1149.46 2521.4,1153 2515.92,1156.41C2510.9,1159.54 2505.73,1162.77 2502.18,1167.09C2503.11,1166.74 2504.03,1166.36 2504.95,1165.99C2508.24,1162.71 2512.51,1160.03 2516.67,1157.44Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2354.57,1138.55C2352.34,1137.55 2350.06,1136.57 2347.78,1135.61C2348.63,1136.43 2349.5,1137.26 2350.37,1138.06C2351.59,1138.59 2352.83,1139.12 2354.03,1139.66C2369.74,1146.7 2380.78,1154.47 2387.01,1162.85C2387.79,1163.21 2388.57,1163.58 2389.36,1163.94C2383.32,1154.66 2371.85,1146.3 2354.57,1138.55Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2464.75,1156.74C2463.48,1150.84 2462.17,1144.74 2462,1138.75C2461.81,1131.79 2463.43,1125.65 2466.58,1120.8L2465.18,1120.56C2462.08,1125.53 2460.49,1131.75 2460.69,1138.76C2460.85,1144.84 2462.18,1151 2463.46,1156.95C2464.34,1161.04 2465.25,1165.27 2465.77,1169.42C2466.06,1171.7 2466.2,1173.88 2466.2,1175.96C2466.64,1175.91 2467.08,1175.86 2467.51,1175.81C2467.5,1173.74 2467.36,1171.57 2467.08,1169.31C2466.55,1165.1 2465.64,1160.85 2464.75,1156.74Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2416.35,1150.82C2412.89,1147.11 2407.98,1144.58 2403.24,1142.13C2400.05,1140.48 2396.75,1138.77 2393.93,1136.72C2386.59,1131.4 2381.82,1123.23 2380.49,1113.73C2380.1,1111 2380.01,1108.27 2380.09,1105.56L2378.78,1105.32C2378.68,1108.15 2378.78,1111 2379.18,1113.86C2380.55,1123.69 2385.51,1132.15 2393.14,1137.69C2396.04,1139.79 2399.39,1141.53 2402.63,1143.2C2407.48,1145.71 2412.06,1148.08 2415.37,1151.62C2421.3,1157.97 2421.29,1166.12 2421.09,1174.08C2421.52,1174.16 2421.96,1174.24 2422.4,1174.33C2422.62,1166.07 2422.67,1157.59 2416.35,1150.82Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2445.57,1124.96C2445.99,1127.74 2446.65,1130.56 2447.29,1133.29C2448.53,1138.63 2449.82,1144.16 2449.36,1149.5C2449.04,1153.2 2447.87,1155.89 2445.78,1157.74C2443.6,1159.67 2439.39,1160.89 2435.87,1159.35C2432.39,1157.83 2431.01,1154.26 2430.88,1151.44C2430.82,1149.96 2430.99,1148.45 2431.15,1146.99C2431.34,1145.3 2431.54,1143.55 2431.39,1141.78C2430.92,1136.37 2427.25,1131.35 2424.1,1127.58C2423.24,1126.55 2422.36,1125.53 2421.47,1124.51C2418.76,1121.4 2415.96,1118.19 2414.07,1114.73C2413.44,1113.56 2412.95,1112.41 2412.59,1111.28L2411.15,1111.03C2411.55,1112.41 2412.12,1113.83 2412.9,1115.26C2414.86,1118.84 2417.7,1122.11 2420.45,1125.27C2421.34,1126.29 2422.22,1127.3 2423.07,1128.32C2426.1,1131.95 2429.64,1136.77 2430.07,1141.85C2430.22,1143.51 2430.03,1145.12 2429.84,1146.84C2429.67,1148.34 2429.5,1149.89 2429.57,1151.46C2429.71,1154.65 2431.3,1158.7 2435.34,1160.46C2436.06,1160.78 2436.81,1161 2437.56,1161.13C2441.03,1161.75 2444.6,1160.5 2446.7,1158.64C2449.03,1156.58 2450.32,1153.63 2450.67,1149.63C2451.15,1144.11 2449.84,1138.49 2448.58,1133.06C2447.95,1130.35 2447.29,1127.55 2446.88,1124.81C2446.56,1122.75 2446.34,1120.09 2446.55,1117.27L2445.25,1117.04C2445.01,1120.01 2445.24,1122.8 2445.57,1124.96Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2530.13,1124.46L2472.72,1114.34C2470.28,1116.09 2468.22,1118.27 2466.58,1120.8L2533,1132.52C2532.79,1131.11 2532.43,1129.68 2531.91,1128.25C2531.43,1126.94 2530.83,1125.68 2530.13,1124.46Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2530.13,1124.46L2472.72,1114.34C2470.28,1116.09 2468.22,1118.27 2466.58,1120.8L2533,1132.52C2532.79,1131.11 2532.43,1129.68 2531.91,1128.25C2531.43,1126.94 2530.83,1125.68 2530.13,1124.46Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2548.65,1127.73L2542.46,1126.64L2541.07,1126.39L2531.75,1124.75C2532.28,1125.76 2532.76,1126.81 2533.15,1127.88C2533.75,1129.52 2534.15,1131.15 2534.36,1132.76L2551.2,1135.73C2553.35,1133.64 2555.44,1131.5 2557.47,1129.29L2550.1,1127.99L2548.65,1127.73Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2548.65,1127.73L2542.46,1126.64L2541.07,1126.39L2531.75,1124.75C2532.28,1125.76 2532.76,1126.81 2533.15,1127.88C2533.75,1129.52 2534.15,1131.15 2534.36,1132.76L2551.2,1135.73C2553.35,1133.64 2555.44,1131.5 2557.47,1129.29L2550.1,1127.99L2548.65,1127.73Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2412.59,1111.28L2445.25,1117.04C2445.44,1114.66 2445.94,1112.17 2446.95,1109.8L2412.31,1103.69C2411.6,1105.94 2411.71,1108.54 2412.59,1111.28Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2412.59,1111.28L2445.25,1117.04C2445.44,1114.66 2445.94,1112.17 2446.95,1109.8L2412.31,1103.69C2411.6,1105.94 2411.71,1108.54 2412.59,1111.28Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2411,1103.46L2380.74,1098.12C2380.41,1100.59 2380.17,1103.06 2380.09,1105.55L2411.15,1111.03C2410.38,1108.32 2410.31,1105.74 2411,1103.46Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2411,1103.46L2380.74,1098.12C2380.41,1100.59 2380.17,1103.06 2380.09,1105.55L2411.15,1111.03C2410.38,1108.32 2410.31,1105.74 2411,1103.46Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2379.43,1097.89L2314.47,1086.44C2315.62,1089.21 2316.85,1091.94 2318.16,1094.63L2378.78,1105.32C2378.87,1102.83 2379.11,1100.35 2379.43,1097.89Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2379.43,1097.89L2314.47,1086.44C2315.62,1089.21 2316.85,1091.94 2318.16,1094.63L2378.78,1105.32C2378.87,1102.83 2379.11,1100.35 2379.43,1097.89Z" style="fill:rgb(247,165,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2448.28,1110.03C2447.24,1112.38 2446.74,1114.88 2446.55,1117.27L2465.18,1120.56C2466.74,1118.05 2468.68,1115.85 2470.99,1114.04L2448.28,1110.03Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2448.28,1110.03C2447.24,1112.38 2446.74,1114.88 2446.55,1117.27L2465.18,1120.56C2466.74,1118.05 2468.68,1115.85 2470.99,1114.04L2448.28,1110.03Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2531.91,1128.25C2532.43,1129.68 2532.79,1131.11 2533,1132.52L2534.36,1132.76C2534.15,1131.15 2533.75,1129.52 2533.15,1127.88C2532.76,1126.81 2532.28,1125.76 2531.75,1124.75L2530.13,1124.46C2530.83,1125.68 2531.43,1126.94 2531.91,1128.25Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2531.91,1128.25C2532.43,1129.68 2532.79,1131.11 2533,1132.52L2534.36,1132.76C2534.15,1131.15 2533.75,1129.52 2533.15,1127.88C2532.76,1126.81 2532.28,1125.76 2531.75,1124.75L2530.13,1124.46C2530.83,1125.68 2531.43,1126.94 2531.91,1128.25Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2470.99,1114.04C2468.68,1115.85 2466.74,1118.05 2465.18,1120.56L2466.58,1120.8C2468.22,1118.27 2470.28,1116.09 2472.72,1114.34L2470.99,1114.04Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2470.99,1114.04C2468.68,1115.85 2466.74,1118.05 2465.18,1120.56L2466.58,1120.8C2468.22,1118.27 2470.28,1116.09 2472.72,1114.34L2470.99,1114.04Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2379.43,1097.89C2379.11,1100.35 2378.87,1102.83 2378.78,1105.32L2380.09,1105.55C2380.17,1103.07 2380.41,1100.59 2380.74,1098.12L2379.43,1097.89Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2379.43,1097.89C2379.11,1100.35 2378.87,1102.83 2378.78,1105.32L2380.09,1105.55C2380.17,1103.07 2380.41,1100.59 2380.74,1098.12L2379.43,1097.89Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2412.31,1103.69L2411,1103.46C2410.31,1105.74 2410.38,1108.32 2411.15,1111.03L2412.59,1111.28C2411.71,1108.54 2411.6,1105.94 2412.31,1103.69Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2412.31,1103.69L2411,1103.46C2410.31,1105.74 2410.38,1108.32 2411.15,1111.03L2412.59,1111.28C2411.71,1108.54 2411.6,1105.94 2412.31,1103.69Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2448.28,1110.03L2446.95,1109.8C2445.94,1112.17 2445.44,1114.66 2445.25,1117.04L2446.55,1117.27C2446.74,1114.88 2447.24,1112.38 2448.28,1110.03Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2448.28,1110.03L2446.95,1109.8C2445.94,1112.17 2445.44,1114.66 2445.25,1117.04L2446.55,1117.27C2446.74,1114.88 2447.24,1112.38 2448.28,1110.03Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <circle cx="2449.56" cy="1030.81" r="29.065" style="fill:rgb(147,83,9);"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2515.48,1077.69C2508.34,1076.43 2503.57,1069.62 2504.83,1062.48L2512.33,1019.94C2513.59,1012.8 2520.4,1008.03 2527.54,1009.29C2534.68,1010.55 2539.45,1017.36 2538.19,1024.5L2530.68,1067.04C2529.43,1074.18 2522.62,1078.95 2515.48,1077.69Z" style="fill:rgb(147,83,9);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2371.58,1052.32C2364.44,1051.06 2359.67,1044.25 2360.93,1037.11L2368.43,994.567C2369.69,987.426 2376.5,982.658 2383.64,983.917C2390.78,985.176 2395.55,991.986 2394.29,999.127L2386.79,1041.67C2385.53,1048.81 2378.72,1053.58 2371.58,1052.32Z" style="fill:rgb(147,83,9);fill-rule:nonzero;"/>
             </g>
         </g>
         <g transform="matrix(0.114827,0,0,0.114827,-240.307,-44.7209)">
             <path d="M3066.48,996.767C3050.37,904.554 2969.91,834.461 2873.09,834.461C2776.26,834.461 2695.81,904.554 2679.69,996.767L3066.48,996.767Z" style="fill:rgb(147,83,9);fill-opacity:0.2;fill-rule:nonzero;"/>
         </g>
         <g #frontwheel id="frontwheel" transform="matrix(0.114827,0,0,0.114827,-8.52651e-14,-2.13163e-14)">
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <circle cx="2873.09" cy="1030.81" r="175.63" style="fill:rgb(147,83,9);"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2821.06,956.425L2921.77,919.768C2917.72,916.488 2913.12,913.601 2908.07,911.218C2894.59,904.856 2878.52,902.3 2862.82,904.021C2859.1,904.429 2855.21,905.056 2851.45,905.662C2839.58,907.575 2827.31,909.554 2817.52,905.251C2814.3,903.832 2811.6,901.848 2809.43,899.314C2796.32,905.642 2784.53,913.749 2774.22,923.224C2772.13,934.351 2766.67,945.443 2758.1,954.966C2753.6,959.96 2747.67,964.615 2741.17,968.089C2738.05,974.639 2735.42,981.415 2733.3,988.365L2810.28,960.35C2813.8,958.874 2817.39,957.55 2821.06,956.425ZM2789.66,955.992C2789.36,956.134 2789.05,956.264 2788.73,956.38C2785.72,957.477 2782.05,957.346 2780.47,955.159C2779.29,953.522 2779.71,951.329 2780.01,949.729L2785.17,922.703C2785.66,920.139 2786.4,917.257 2788.98,915.5C2791.17,914.012 2794.43,913.692 2797.29,914.683C2800.72,915.871 2803.69,917.655 2806.55,919.381C2809.77,921.318 2813.09,923.321 2817.1,924.485C2818.32,924.841 2820.46,925.079 2822.72,925.331C2828.61,925.986 2835.29,926.73 2835.66,930.071C2835.98,932.894 2828.27,936.19 2815.65,941.334C2807.89,944.499 2799.86,947.773 2797.21,950.146C2796.79,950.521 2796.38,950.905 2795.96,951.289C2794.08,953.027 2792.13,954.823 2789.66,955.992Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2772.6,924.748C2759.96,936.73 2749.66,950.804 2742.08,966.201C2747.83,962.909 2753.07,958.688 2757.12,954.19C2765.09,945.328 2770.32,935.085 2772.6,924.748Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2834.36,930.26C2834.09,927.85 2827.4,927.104 2822.51,926.561C2820.09,926.291 2818,926.058 2816.66,925.67C2812.49,924.46 2809.1,922.413 2805.81,920.435C2802.87,918.665 2800.1,916.993 2796.79,915.849C2795.28,915.326 2793.05,915.081 2791.07,915.8C2790.6,915.973 2790.14,916.201 2789.71,916.494C2787.55,917.963 2786.92,920.448 2786.46,922.867L2781.3,949.893C2780.96,951.698 2780.74,953.285 2781.57,954.429C2782.91,956.289 2786.53,956.114 2789.12,954.891C2791.4,953.815 2793.26,952.092 2795.07,950.425C2795.49,950.036 2795.91,949.648 2796.34,949.268C2799.17,946.734 2806.95,943.562 2815.18,940.203C2823.38,936.862 2834.59,932.287 2834.36,930.26Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2851.29,904.443C2855.07,903.834 2858.98,903.204 2862.73,902.792C2878.68,901.044 2895,903.641 2908.7,910.106C2914.07,912.637 2918.92,915.732 2923.18,919.256L2945.16,911.258C2944.92,911.069 2944.67,910.881 2944.44,910.691C2940.35,907.412 2935.45,902.941 2932.7,897.457C2911.75,888.086 2888.58,883.618 2865.07,884.919C2863.08,885.251 2861.13,885.591 2859.23,885.923C2855.05,886.655 2850.16,887.49 2845.44,887.355C2837.96,888.805 2830.51,890.851 2823.13,893.537C2818.86,895.091 2814.71,896.833 2810.68,898.731C2812.7,901.024 2815.18,902.83 2818.12,904.124C2827.55,908.27 2839.62,906.324 2851.29,904.443Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2946.59,910.737L2952.89,908.444C2947.06,904.654 2940.99,901.282 2934.7,898.358C2937.44,902.989 2941.7,906.837 2945.31,909.731C2945.73,910.068 2946.16,910.402 2946.59,910.737Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2774.22,923.224C2773.68,923.728 2773.14,924.236 2772.6,924.747C2770.33,935.084 2765.09,945.328 2757.12,954.189C2753.07,958.688 2747.83,962.908 2742.08,966.2C2741.77,966.826 2741.47,967.457 2741.17,968.088C2747.67,964.614 2753.6,959.959 2758.1,954.965C2766.67,945.444 2772.13,934.352 2774.22,923.224Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2810.28,960.35L2821.06,956.426C2817.39,957.55 2813.8,958.874 2810.28,960.35Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2851.45,905.662C2855.21,905.056 2859.1,904.428 2862.82,904.021C2878.52,902.3 2894.59,904.856 2908.07,911.218C2913.12,913.601 2917.72,916.488 2921.77,919.768L2923.18,919.255C2918.92,915.731 2914.06,912.636 2908.7,910.105C2895,903.64 2878.68,901.043 2862.73,902.791C2858.98,903.203 2855.07,903.833 2851.28,904.442C2839.61,906.324 2827.55,908.269 2818.12,904.124C2815.17,902.829 2812.7,901.024 2810.68,898.731C2810.26,898.926 2809.84,899.115 2809.43,899.314C2811.6,901.849 2814.3,903.833 2817.52,905.251C2827.31,909.554 2839.58,907.576 2851.45,905.662Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2945.16,911.258L2946.59,910.737C2946.16,910.402 2945.73,910.068 2945.31,909.731C2941.7,906.836 2937.44,902.989 2934.7,898.358C2934.04,898.05 2933.37,897.754 2932.7,897.456C2935.45,902.94 2940.35,907.411 2944.44,910.69C2944.67,910.88 2944.92,911.068 2945.16,911.258Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2865.07,884.919C2858.54,885.28 2851.98,886.085 2845.44,887.355C2850.16,887.49 2855.05,886.654 2859.23,885.923C2861.13,885.591 2863.08,885.251 2865.07,884.919Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2815.66,941.335C2828.27,936.191 2835.99,932.895 2835.66,930.072C2835.29,926.731 2828.61,925.987 2822.73,925.332C2820.46,925.08 2818.32,924.841 2817.1,924.486C2813.09,923.322 2809.77,921.319 2806.55,919.382C2803.69,917.656 2800.72,915.871 2797.29,914.684C2794.43,913.693 2791.17,914.014 2788.98,915.501C2786.4,917.257 2785.66,920.14 2785.17,922.704L2780.02,949.73C2779.71,951.33 2779.29,953.523 2780.48,955.16C2782.05,957.347 2785.72,957.478 2788.74,956.381C2789.05,956.265 2789.36,956.135 2789.66,955.993C2792.13,954.825 2794.08,953.028 2795.96,951.291C2796.38,950.907 2796.79,950.523 2797.21,950.148C2799.87,947.774 2807.89,944.5 2815.66,941.335ZM2795.07,950.424C2793.26,952.091 2791.4,953.814 2789.12,954.89C2786.53,956.113 2782.91,956.288 2781.57,954.428C2780.74,953.285 2780.96,951.697 2781.3,949.892L2786.46,922.866C2786.92,920.447 2787.55,917.962 2789.71,916.493C2790.14,916.2 2790.6,915.971 2791.07,915.799C2793.05,915.08 2795.28,915.325 2796.79,915.848C2800.1,916.992 2802.87,918.664 2805.81,920.434C2809.1,922.412 2812.49,924.459 2816.66,925.669C2817.99,926.057 2820.09,926.29 2822.51,926.56C2827.4,927.104 2834.09,927.849 2834.36,930.259C2834.59,932.286 2823.38,936.86 2815.18,940.2C2806.95,943.559 2799.17,946.731 2796.34,949.265C2795.91,949.646 2795.49,950.035 2795.07,950.424Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2948.57,912.301C2950.19,913.566 2951.83,914.842 2953.41,916.158L2959.65,913.888C2959.15,913.292 2958.66,912.683 2958.19,912.069C2956.45,910.822 2954.68,909.611 2952.89,908.444L2946.59,910.737C2947.25,911.258 2947.91,911.78 2948.57,912.301Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2948.57,912.301C2950.19,913.566 2951.83,914.842 2953.41,916.158L2959.65,913.888C2959.15,913.292 2958.66,912.683 2958.19,912.069C2956.45,910.822 2954.68,909.611 2952.89,908.444L2946.59,910.737C2947.25,911.258 2947.91,911.78 2948.57,912.301Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2851.55,953.235L2927.9,925.444C2926.03,923.443 2923.98,921.55 2921.77,919.768L2821.06,956.425C2824.27,955.439 2827.53,954.603 2830.84,953.973C2837.8,952.648 2844.95,952.406 2851.55,953.235Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2851.55,953.235L2927.9,925.444C2926.03,923.443 2923.98,921.55 2921.77,919.768L2821.06,956.425C2824.27,955.439 2827.53,954.603 2830.84,953.973C2837.8,952.648 2844.95,952.406 2851.55,953.235Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2781.01,977.336C2779.3,978.374 2777.61,979.42 2775.9,980.467C2775.51,980.712 2775.11,980.957 2774.71,981.202L2789.5,975.818L2792.73,974.643L2849.08,954.133C2843.66,953.664 2837.62,953.933 2831.04,955.185C2812.61,958.694 2795.55,968.46 2781.01,977.336Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2781.01,977.336C2779.3,978.374 2777.61,979.42 2775.9,980.467C2775.51,980.712 2775.11,980.957 2774.71,981.202L2789.5,975.818L2792.73,974.643L2849.08,954.133C2843.66,953.664 2837.62,953.933 2831.04,955.185C2812.61,958.694 2795.55,968.46 2781.01,977.336Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2757.48,987.473L2768.89,983.322C2771.01,982.03 2773.12,980.732 2775.23,979.439C2776.93,978.391 2778.63,977.345 2780.33,976.306C2789.42,970.76 2799.49,964.872 2810.28,960.35L2733.3,988.365C2732.42,991.259 2731.64,994.185 2730.94,997.134L2755.11,988.337L2757.48,987.473Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2757.48,987.473L2768.89,983.322C2771.01,982.03 2773.12,980.732 2775.23,979.439C2776.93,978.391 2778.63,977.345 2780.33,976.306C2789.42,970.76 2799.49,964.872 2810.28,960.35L2733.3,988.365C2732.42,991.259 2731.64,994.185 2730.94,997.134L2755.11,988.337L2757.48,987.473Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2952,916.674C2950.59,915.518 2949.14,914.389 2947.71,913.27C2946.85,912.6 2946,911.93 2945.16,911.258L2923.18,919.256C2925.36,921.052 2927.39,922.952 2929.23,924.961L2952,916.674Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2952,916.674C2950.59,915.518 2949.14,914.389 2947.71,913.27C2946.85,912.6 2946,911.93 2945.16,911.258L2923.18,919.256C2925.36,921.052 2927.39,922.952 2929.23,924.961L2952,916.674Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2775.9,980.467C2777.61,979.42 2779.31,978.375 2781.01,977.336C2795.55,968.46 2812.61,958.694 2831.05,955.186C2837.62,953.934 2843.66,953.665 2849.08,954.134L2851.55,953.235C2844.95,952.406 2837.8,952.648 2830.85,953.973C2827.53,954.603 2824.27,955.439 2821.06,956.425L2810.28,960.349C2799.49,964.871 2789.42,970.759 2780.34,976.305C2778.63,977.344 2776.93,978.391 2775.23,979.438C2773.13,980.73 2771.01,982.029 2768.89,983.321L2774.71,981.202C2775.11,980.957 2775.51,980.712 2775.9,980.467Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2775.9,980.467C2777.61,979.42 2779.31,978.375 2781.01,977.336C2795.55,968.46 2812.61,958.694 2831.05,955.186C2837.62,953.934 2843.66,953.665 2849.08,954.134L2851.55,953.235C2844.95,952.406 2837.8,952.648 2830.85,953.973C2827.53,954.603 2824.27,955.439 2821.06,956.425L2810.28,960.349C2799.49,964.871 2789.42,970.759 2780.34,976.305C2778.63,977.344 2776.93,978.391 2775.23,979.438C2773.13,980.73 2771.01,982.029 2768.89,983.321L2774.71,981.202C2775.11,980.957 2775.51,980.712 2775.9,980.467Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2929.23,924.961C2927.39,922.952 2925.36,921.052 2923.18,919.256L2921.77,919.769C2923.98,921.551 2926.03,923.444 2927.9,925.445L2929.23,924.961Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2929.23,924.961C2927.39,922.952 2925.36,921.052 2923.18,919.256L2921.77,919.769C2923.98,921.551 2926.03,923.444 2927.9,925.445L2929.23,924.961Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2952,916.674L2953.41,916.158C2951.83,914.841 2950.19,913.565 2948.57,912.301C2947.91,911.78 2947.25,911.258 2946.59,910.737L2945.16,911.258C2946,911.93 2946.85,912.6 2947.71,913.27C2949.14,914.389 2950.59,915.518 2952,916.674Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2952,916.674L2953.41,916.158C2951.83,914.841 2950.19,913.565 2948.57,912.301C2947.91,911.78 2947.25,911.258 2946.59,910.737L2945.16,911.258C2946,911.93 2946.85,912.6 2947.71,913.27C2949.14,914.389 2950.59,915.518 2952,916.674Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2960.34,913.639C2959.62,913.11 2958.91,912.585 2958.19,912.069C2958.66,912.683 2959.15,913.293 2959.65,913.888L2960.34,913.639Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2960.34,913.639C2959.62,913.11 2958.91,912.585 2958.19,912.069C2958.66,912.683 2959.15,913.293 2959.65,913.888L2960.34,913.639Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2849.66,1018.33C2852.32,1011.16 2861.16,1007.33 2864.85,1006.02C2868.55,1004.71 2872.39,1003.82 2876.11,1002.96C2880.96,1001.84 2885.98,1000.68 2890.63,998.555C2893.41,997.285 2895.89,995.424 2898.3,993.626C2900.74,991.792 2903.28,989.896 2906.12,988.627C2913.69,985.247 2922.7,986.031 2926.64,990.408C2928.28,992.23 2930.32,996.188 2926.19,1002.5L2955.7,991.758C2955.07,983.154 2949.24,973.921 2938.24,964.179L2937.38,963.41C2928.88,955.887 2919.54,947.615 2916.27,937.584L2887.7,947.985C2894.19,957.443 2896.13,966.53 2893.46,975.03C2891.65,980.782 2886.46,987.566 2878.97,990.295C2878,990.648 2876.99,990.933 2875.95,991.139C2861.7,993.953 2853.08,980.853 2850.78,976.77C2850.19,975.737 2849.62,974.68 2849.04,973.616C2847.28,970.357 2845.48,967.051 2843.25,964.161L2808.2,976.919C2814.16,978.575 2819.05,981.098 2822.9,984.506C2830.71,991.42 2833.59,1002.21 2830.41,1012.67C2827.29,1022.92 2818.63,1032.46 2807.24,1038.2C2794.49,1044.63 2780.09,1045.73 2766.18,1046.8C2754.49,1047.69 2742.4,1048.62 2731.25,1052.67C2731.17,1052.7 2731.08,1052.74 2731,1052.77C2730.26,1053.04 2729.52,1053.34 2728.79,1053.64C2729.8,1060.03 2731.26,1066.41 2733.16,1072.75L2850.75,1029.96C2849.05,1026.17 2848.17,1022.36 2849.66,1018.33Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2851.94,976.147C2854.14,980.048 2862.36,992.569 2875.74,989.927C2884.3,988.233 2890.28,980.863 2892.21,974.729C2894.79,966.521 2892.84,957.689 2886.42,948.448L2844.56,963.687C2846.72,966.568 2848.48,969.812 2850.22,973.011C2850.79,974.068 2851.36,975.119 2851.94,976.147Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2730.83,1051.52C2742.17,1047.4 2754.35,1046.46 2766.14,1045.56C2779.91,1044.51 2794.17,1043.42 2806.67,1037.11C2817.73,1031.54 2826.13,1022.29 2829.15,1012.38C2832.2,1002.36 2829.45,992.036 2821.98,985.427C2817.96,981.872 2812.62,979.305 2806,977.719L2771.01,990.453C2775.59,991.711 2780.02,993.239 2784.19,995.166C2792.07,998.803 2796.38,1003.27 2797.01,1008.46C2797.43,1012 2796.09,1015.83 2793.12,1019.51C2790.48,1022.79 2786.68,1025.78 2782.43,1027.93C2776.25,1031.06 2769.41,1032.78 2762.79,1034.44C2760.67,1034.97 2758.48,1035.52 2756.35,1036.1C2746.51,1038.81 2736.94,1042.68 2727.94,1047.48C2728.13,1049.12 2728.34,1050.76 2728.59,1052.4C2729.25,1052.13 2729.91,1051.86 2730.58,1051.62C2730.66,1051.58 2730.75,1051.55 2730.83,1051.52Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2924.68,1002.5C2927.82,998.049 2928.16,994.046 2925.63,991.237C2922.28,987.525 2914.83,986.698 2908.12,989.139C2907.62,989.322 2907.12,989.523 2906.63,989.743C2903.93,990.947 2901.58,992.714 2899.08,994.583C2896.62,996.427 2894.07,998.333 2891.15,999.666C2886.38,1001.85 2881.28,1003.03 2876.36,1004.16C2872.68,1005.01 2868.88,1005.89 2865.26,1007.18C2865.18,1007.21 2865.1,1007.24 2865.02,1007.27C2857.86,1009.88 2852.59,1014.13 2850.9,1018.69C2849.53,1022.36 2850.38,1025.93 2852,1029.5L2924.07,1003.27C2924.26,1003.02 2924.49,1002.77 2924.68,1002.5Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3003.71,965.41C3003.97,965.967 3004.25,966.507 3004.53,967.043C3004.26,966.494 3003.98,965.954 3003.71,965.41Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2938.29,962.488L2939.16,963.257C2950.27,973.095 2956.21,982.478 2956.98,991.291L2990,979.273C2985.79,973.541 2982.87,967.061 2981.57,960.101C2979.94,951.344 2980.74,942.083 2981.68,933.052C2977.15,928.033 2972.32,923.354 2967.19,919.049L2917.52,937.129C2920.7,946.906 2929.91,955.069 2938.29,962.488Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2991.28,978.807L3006.79,973.163C3003.53,968.823 3001.1,963.629 2999.49,957.548C2994.66,949.188 2989.07,941.443 2982.85,934.365C2981.98,942.915 2981.34,951.633 2982.86,959.831C2984.15,966.729 2987.07,973.146 2991.28,978.807Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2752.02,1016.21C2751.64,1008.86 2743.76,1004.55 2736.7,1002.95L2729.16,1005.69C2727.18,1016.97 2726.53,1028.55 2727.28,1040.21C2732.13,1037.95 2737.1,1035.59 2741.41,1032.54C2748.32,1027.66 2752.29,1021.55 2752.02,1016.21Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2756.04,1034.92C2758.18,1034.33 2760.38,1033.77 2762.51,1033.24C2769.05,1031.6 2775.81,1029.91 2781.85,1026.85C2792.25,1021.59 2796.32,1013.73 2795.71,1008.65C2795.13,1003.9 2791.05,999.734 2783.58,996.283C2778.97,994.158 2774.05,992.53 2768.93,991.21L2738.79,1002.18C2745.78,1004.18 2752.95,1008.71 2753.33,1016.09C2753.62,1021.86 2749.44,1028.37 2742.16,1033.52C2737.61,1036.73 2732.41,1039.17 2727.37,1041.52C2727.48,1043.07 2727.63,1044.62 2727.79,1046.17C2736.76,1041.43 2746.27,1037.61 2756.04,1034.92Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3006.79,973.163L3007.24,973.001C3006.37,970.988 3005.47,968.998 3004.53,967.043C3004.25,966.507 3003.97,965.967 3003.71,965.41C3002.38,962.735 3000.97,960.11 2999.49,957.548C3001.1,963.628 3003.52,968.823 3006.79,973.163Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2990,979.273L2991.28,978.806C2987.07,973.145 2984.15,966.728 2982.86,959.83C2981.34,951.632 2981.98,942.914 2982.85,934.364C2982.46,933.923 2982.07,933.487 2981.68,933.051C2980.74,942.082 2979.94,951.343 2981.57,960.1C2982.87,967.061 2985.8,973.542 2990,979.273Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2939.16,963.257L2938.3,962.488C2929.91,955.068 2920.7,946.906 2917.52,937.129L2916.27,937.584C2919.54,947.615 2928.88,955.888 2937.38,963.41L2938.24,964.179C2949.24,973.921 2955.07,983.154 2955.7,991.758L2956.98,991.292C2956.21,982.479 2950.27,973.096 2939.16,963.257Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2731.25,1052.67C2742.41,1048.62 2754.49,1047.69 2766.18,1046.8C2780.1,1045.73 2794.49,1044.63 2807.25,1038.2C2818.63,1032.46 2827.29,1022.92 2830.41,1012.67C2833.59,1002.21 2830.71,991.42 2822.9,984.506C2819.05,981.098 2814.16,978.575 2808.2,976.919L2806,977.72C2812.62,979.307 2817.96,981.874 2821.98,985.428C2829.45,992.038 2832.2,1002.37 2829.15,1012.38C2826.14,1022.29 2817.73,1031.54 2806.67,1037.11C2794.17,1043.42 2779.92,1044.51 2766.14,1045.56C2754.35,1046.46 2742.17,1047.4 2730.84,1051.52C2730.75,1051.55 2730.66,1051.58 2730.58,1051.62C2729.91,1051.86 2729.25,1052.13 2728.59,1052.4C2728.65,1052.81 2728.72,1053.23 2728.79,1053.64C2729.52,1053.34 2730.26,1053.04 2731,1052.77C2731.09,1052.74 2731.17,1052.7 2731.25,1052.67Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2753.33,1016.09C2752.95,1008.71 2745.78,1004.18 2738.79,1002.18L2736.7,1002.94C2743.76,1004.55 2751.64,1008.86 2752.02,1016.21C2752.29,1021.55 2748.32,1027.65 2741.41,1032.54C2737.1,1035.59 2732.13,1037.95 2727.28,1040.21C2727.31,1040.65 2727.33,1041.09 2727.37,1041.52C2732.41,1039.17 2737.61,1036.73 2742.16,1033.52C2749.44,1028.37 2753.62,1021.86 2753.33,1016.09Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2892.21,974.728C2890.28,980.862 2884.3,988.232 2875.74,989.926C2862.36,992.569 2854.14,980.047 2851.94,976.146C2851.36,975.119 2850.79,974.068 2850.22,973.009C2848.48,969.81 2846.72,966.566 2844.55,963.685L2843.25,964.159C2845.48,967.05 2847.28,970.355 2849.04,973.614C2849.62,974.678 2850.19,975.734 2850.78,976.768C2853.08,980.85 2861.7,993.95 2875.95,991.137C2876.99,990.931 2878,990.646 2878.97,990.293C2886.46,987.565 2891.65,980.781 2893.46,975.028C2896.13,966.529 2894.19,957.441 2887.7,947.983L2886.43,948.446C2892.84,957.689 2894.79,966.521 2892.21,974.728Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2850.89,1018.69C2852.59,1014.13 2857.86,1009.88 2865.02,1007.27C2865.1,1007.24 2865.18,1007.21 2865.26,1007.18C2868.88,1005.89 2872.68,1005.01 2876.36,1004.16C2881.28,1003.03 2886.38,1001.85 2891.15,999.666C2894.07,998.333 2896.61,996.427 2899.08,994.583C2901.57,992.714 2903.93,990.947 2906.63,989.743C2907.12,989.523 2907.62,989.322 2908.12,989.139C2914.83,986.698 2922.28,987.525 2925.63,991.237C2928.16,994.047 2927.82,998.049 2924.68,1002.5C2924.49,1002.77 2924.26,1003.02 2924.06,1003.27L2926.19,1002.5C2930.32,996.188 2928.28,992.23 2926.64,990.408C2922.7,986.031 2913.69,985.247 2906.12,988.627C2903.28,989.896 2900.74,991.792 2898.29,993.626C2895.89,995.425 2893.41,997.285 2890.63,998.555C2885.98,1000.68 2880.96,1001.84 2876.1,1002.96C2872.39,1003.82 2868.55,1004.71 2864.85,1006.02C2861.16,1007.33 2852.32,1011.16 2849.66,1018.33C2848.17,1022.36 2849.05,1026.17 2850.75,1029.96L2852,1029.5C2850.38,1025.93 2849.53,1022.36 2850.89,1018.69Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2756.35,1036.1C2758.48,1035.52 2760.67,1034.97 2762.79,1034.44C2769.41,1032.78 2776.25,1031.06 2782.43,1027.93C2786.68,1025.78 2790.48,1022.79 2793.12,1019.51C2796.09,1015.83 2797.43,1012 2797.01,1008.46C2796.38,1003.27 2792.07,998.803 2784.19,995.166C2780.02,993.239 2775.6,991.711 2771.01,990.453L2768.94,991.21C2774.05,992.531 2778.98,994.159 2783.58,996.283C2791.05,999.735 2795.13,1003.9 2795.71,1008.65C2796.32,1013.73 2792.25,1021.59 2781.85,1026.85C2775.81,1029.91 2769.05,1031.6 2762.51,1033.24C2760.39,1033.77 2758.18,1034.33 2756.04,1034.92C2746.27,1037.61 2736.76,1041.43 2727.8,1046.17C2727.84,1046.6 2727.89,1047.04 2727.94,1047.48C2736.94,1042.68 2746.51,1038.81 2756.35,1036.1Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2816.54,1050.31L2854.26,1036.58L2852.98,1034.28C2852.17,1032.84 2851.4,1031.4 2850.75,1029.96L2733.17,1072.75C2733.88,1075.11 2734.65,1077.47 2735.49,1079.82L2815.29,1050.77L2816.54,1050.31Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2816.54,1050.31L2854.26,1036.58L2852.98,1034.28C2852.17,1032.84 2851.4,1031.4 2850.75,1029.96L2733.17,1072.75C2733.88,1075.11 2734.65,1077.47 2735.49,1079.82L2815.29,1050.77L2816.54,1050.31Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2991.28,978.807C2992.85,980.918 2994.6,982.926 2996.51,984.811L3010,979.902C3009.32,978.08 3008.61,976.279 3007.87,974.5C3007.51,974.059 3007.14,973.623 3006.79,973.163L2991.28,978.807Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2991.28,978.807C2992.85,980.918 2994.6,982.926 2996.51,984.811L3010,979.902C3009.32,978.08 3008.61,976.279 3007.87,974.5C3007.51,974.059 3007.14,973.623 3006.79,973.163L2991.28,978.807Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2924.06,1003.27L2852,1029.5C2852.62,1030.89 2853.36,1032.27 2854.14,1033.66L2855.52,1036.13L2899.84,1019.99C2903.18,1017.94 2906.51,1016.01 2909.68,1014.18C2914.5,1011.38 2920.42,1007.92 2924.06,1003.27Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2924.06,1003.27L2852,1029.5C2852.62,1030.89 2853.36,1032.27 2854.14,1033.66L2855.52,1036.13L2899.84,1019.99C2903.18,1017.94 2906.51,1016.01 2909.68,1014.18C2914.5,1011.38 2920.42,1007.92 2924.06,1003.27Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2910.32,1015.23C2908.89,1016.06 2907.42,1016.91 2905.93,1017.78L2954.63,1000.06C2955.55,997.361 2955.91,994.594 2955.7,991.759L2926.19,1002.5C2926.05,1002.72 2925.91,1002.93 2925.76,1003.16C2922.02,1008.45 2915.53,1012.21 2910.32,1015.23Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2910.32,1015.23C2908.89,1016.06 2907.42,1016.91 2905.93,1017.78L2954.63,1000.06C2955.55,997.361 2955.91,994.594 2955.7,991.759L2926.19,1002.5C2926.05,1002.72 2925.91,1002.93 2925.76,1003.16C2922.02,1008.45 2915.53,1012.21 2910.32,1015.23Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2995.16,985.301C2993.28,983.404 2991.55,981.388 2990,979.273L2956.98,991.291C2957.23,994.084 2956.96,996.821 2956.16,999.496L2995.16,985.301Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2995.16,985.301C2993.28,983.404 2991.55,981.388 2990,979.273L2956.98,991.291C2957.23,994.084 2956.96,996.821 2956.16,999.496L2995.16,985.301Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3007.87,974.5C3007.66,973.998 3007.45,973.499 3007.24,973.001L3006.79,973.163C3007.14,973.623 3007.51,974.059 3007.87,974.5Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3007.87,974.5C3007.66,973.998 3007.45,973.499 3007.24,973.001L3006.79,973.163C3007.14,973.623 3007.51,974.059 3007.87,974.5Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2996.51,984.811C2994.6,982.926 2992.86,980.918 2991.28,978.807L2990,979.274C2991.55,981.389 2993.28,983.405 2995.16,985.302L2996.51,984.811Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2996.51,984.811C2994.6,982.926 2992.86,980.918 2991.28,978.807L2990,979.274C2991.55,981.389 2993.28,983.405 2995.16,985.302L2996.51,984.811Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2956.16,999.497C2956.96,996.822 2957.22,994.085 2956.98,991.292L2955.7,991.758C2955.91,994.593 2955.55,997.36 2954.63,1000.05L2956.16,999.497Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2956.16,999.497C2956.96,996.822 2957.22,994.085 2956.98,991.292L2955.7,991.758C2955.91,994.593 2955.55,997.36 2954.63,1000.05L2956.16,999.497Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2854.14,1033.66C2853.36,1032.27 2852.62,1030.89 2852,1029.5L2850.75,1029.96C2851.4,1031.4 2852.17,1032.84 2852.98,1034.28L2854.26,1036.59L2855.52,1036.13L2854.14,1033.66Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2854.14,1033.66C2853.36,1032.27 2852.62,1030.89 2852,1029.5L2850.75,1029.96C2851.4,1031.4 2852.17,1032.84 2852.98,1034.28L2854.26,1036.59L2855.52,1036.13L2854.14,1033.66Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2909.68,1014.18C2906.51,1016.01 2903.18,1017.94 2899.84,1020L2905.93,1017.78C2907.42,1016.9 2908.89,1016.06 2910.32,1015.23C2915.53,1012.21 2922.02,1008.45 2925.75,1003.16C2925.91,1002.93 2926.04,1002.72 2926.19,1002.5L2924.06,1003.27C2920.42,1007.92 2914.5,1011.38 2909.68,1014.18Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2909.68,1014.18C2906.51,1016.01 2903.18,1017.94 2899.84,1020L2905.93,1017.78C2907.42,1016.9 2908.89,1016.06 2910.32,1015.23C2915.53,1012.21 2922.02,1008.45 2925.75,1003.16C2925.91,1002.93 2926.04,1002.72 2926.19,1002.5L2924.06,1003.27C2920.42,1007.92 2914.5,1011.38 2909.68,1014.18Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2774.71,981.202L2768.88,983.321L2757.48,987.472C2762.08,988.285 2766.62,989.244 2771.01,990.452L2806,977.719C2801.17,976.562 2795.68,975.923 2789.5,975.818L2774.71,981.202Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2774.71,981.202L2768.88,983.321L2757.48,987.472C2762.08,988.285 2766.62,989.244 2771.01,990.452L2806,977.719C2801.17,976.562 2795.68,975.923 2789.5,975.818L2774.71,981.202Z" style="fill:rgb(247,165,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2738.79,1002.18L2768.93,991.21C2764.44,990.048 2759.8,989.122 2755.11,988.336L2730.94,997.133C2730.65,998.33 2730.38,999.532 2730.13,1000.74C2732.1,1000.91 2734.05,1001.14 2735.95,1001.5C2736.88,1001.68 2737.84,1001.91 2738.79,1002.18Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2738.79,1002.18L2768.93,991.21C2764.44,990.048 2759.8,989.122 2755.11,988.336L2730.94,997.133C2730.65,998.33 2730.38,999.532 2730.13,1000.74C2732.1,1000.91 2734.05,1001.14 2735.95,1001.5C2736.88,1001.68 2737.84,1001.91 2738.79,1002.18Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2735.63,1002.72C2733.76,1002.36 2731.83,1002.12 2729.87,1001.95C2729.62,1003.19 2729.38,1004.44 2729.16,1005.69L2736.69,1002.94C2736.34,1002.86 2735.98,1002.78 2735.63,1002.72Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2735.63,1002.72C2733.76,1002.36 2731.83,1002.12 2729.87,1001.95C2729.62,1003.19 2729.38,1004.44 2729.16,1005.69L2736.69,1002.94C2736.34,1002.86 2735.98,1002.78 2735.63,1002.72Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2953.41,916.158L2952,916.674L2929.23,924.961L2927.9,925.444L2851.55,953.235L2849.08,954.134L2792.73,974.644C2798.53,974.904 2803.67,975.662 2808.2,976.919L2843.25,964.161C2841.55,961.947 2839.59,959.977 2837.2,958.459L2838.72,957.905C2841.01,959.499 2842.9,961.487 2844.55,963.688L2886.42,948.449C2885.97,947.798 2885.5,947.146 2885,946.491C2883.93,945.07 2882.78,943.673 2881.6,942.297L2882.92,941.819C2884.01,943.104 2885.07,944.409 2886.08,945.739C2886.65,946.491 2887.18,947.239 2887.7,947.986L2916.27,937.585C2915.49,935.201 2915.05,932.718 2915.05,930.121L2916.37,929.644C2916.32,932.246 2916.74,934.736 2917.52,937.13L2967.19,919.05C2964.96,917.174 2962.67,915.374 2960.34,913.639L2959.65,913.888L2953.41,916.158ZM2856.42,953.416C2855.71,953.676 2854.95,953.859 2854.17,953.947C2852.86,954.093 2851.64,953.948 2850.52,953.608L2851.55,953.234L2852.87,952.752C2853.26,952.77 2853.66,952.763 2854.08,952.717C2855.9,952.514 2857.54,951.683 2858.86,950.573L2861.51,949.607C2860.28,951.227 2858.52,952.653 2856.42,953.416Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2953.41,916.158L2952,916.674L2929.23,924.961L2927.9,925.444L2851.55,953.235L2849.08,954.134L2792.73,974.644C2798.53,974.904 2803.67,975.662 2808.2,976.919L2843.25,964.161C2841.55,961.947 2839.59,959.977 2837.2,958.459L2838.72,957.905C2841.01,959.499 2842.9,961.487 2844.55,963.688L2886.42,948.449C2885.97,947.798 2885.5,947.146 2885,946.491C2883.93,945.07 2882.78,943.673 2881.6,942.297L2882.92,941.819C2884.01,943.104 2885.07,944.409 2886.08,945.739C2886.65,946.491 2887.18,947.239 2887.7,947.986L2916.27,937.585C2915.49,935.201 2915.05,932.718 2915.05,930.121L2916.37,929.644C2916.32,932.246 2916.74,934.736 2917.52,937.13L2967.19,919.05C2964.96,917.174 2962.67,915.374 2960.34,913.639L2959.65,913.888L2953.41,916.158ZM2856.42,953.416C2855.71,953.676 2854.95,953.859 2854.17,953.947C2852.86,954.093 2851.64,953.948 2850.52,953.608L2851.55,953.234L2852.87,952.752C2853.26,952.77 2853.66,952.763 2854.08,952.717C2855.9,952.514 2857.54,951.683 2858.86,950.573L2861.51,949.607C2860.28,951.227 2858.52,952.653 2856.42,953.416Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2916.37,929.643L2915.05,930.12C2915.05,932.717 2915.49,935.2 2916.27,937.584L2917.52,937.129C2916.74,934.735 2916.32,932.245 2916.37,929.643Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2916.37,929.643L2915.05,930.12C2915.05,932.717 2915.49,935.2 2916.27,937.584L2917.52,937.129C2916.74,934.735 2916.32,932.245 2916.37,929.643Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2789.5,975.818C2795.68,975.923 2801.18,976.562 2806,977.719L2808.2,976.918C2803.67,975.662 2798.53,974.903 2792.73,974.643L2789.5,975.818Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2789.5,975.818C2795.68,975.923 2801.18,976.562 2806,977.719L2808.2,976.918C2803.67,975.662 2798.53,974.903 2792.73,974.643L2789.5,975.818Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2736.7,1002.94L2738.79,1002.18C2737.84,1001.91 2736.88,1001.68 2735.95,1001.5C2734.05,1001.14 2732.1,1000.91 2730.13,1000.74C2730.04,1001.14 2729.95,1001.54 2729.87,1001.95C2731.83,1002.12 2733.76,1002.36 2735.63,1002.72C2735.98,1002.78 2736.34,1002.86 2736.7,1002.94Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2736.7,1002.94L2738.79,1002.18C2737.84,1001.91 2736.88,1001.68 2735.95,1001.5C2734.05,1001.14 2732.1,1000.91 2730.13,1000.74C2730.04,1001.14 2729.95,1001.54 2729.87,1001.95C2731.83,1002.12 2733.76,1002.36 2735.63,1002.72C2735.98,1002.78 2736.34,1002.86 2736.7,1002.94Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2838.72,957.903L2837.2,958.457C2839.59,959.975 2841.55,961.946 2843.25,964.159L2844.55,963.685C2842.9,961.486 2841.01,959.497 2838.72,957.903Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2838.72,957.903L2837.2,958.457C2839.59,959.975 2841.55,961.946 2843.25,964.159L2844.55,963.685C2842.9,961.486 2841.01,959.497 2838.72,957.903Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2882.92,941.817L2881.6,942.295C2882.78,943.671 2883.93,945.068 2885,946.489C2885.5,947.144 2885.97,947.797 2886.42,948.447L2887.69,947.984C2887.18,947.237 2886.65,946.489 2886.08,945.737C2885.07,944.408 2884.01,943.102 2882.92,941.817Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2882.92,941.817L2881.6,942.295C2882.78,943.671 2883.93,945.068 2885,946.489C2885.5,947.144 2885.97,947.797 2886.42,948.447L2887.69,947.984C2887.18,947.237 2886.65,946.489 2886.08,945.737C2885.07,944.408 2884.01,943.102 2882.92,941.817Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2854.08,952.718C2853.66,952.764 2853.26,952.771 2852.87,952.753L2851.55,953.235L2850.52,953.609C2851.64,953.949 2852.86,954.094 2854.17,953.948C2854.95,953.86 2855.71,953.676 2856.42,953.417C2858.52,952.654 2860.28,951.227 2861.51,949.609L2858.86,950.575C2857.54,951.684 2855.9,952.515 2854.08,952.718Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2854.08,952.718C2853.66,952.764 2853.26,952.771 2852.87,952.753L2851.55,953.235L2850.52,953.609C2851.64,953.949 2852.86,954.094 2854.17,953.948C2854.95,953.86 2855.71,953.676 2856.42,953.417C2858.52,952.654 2860.28,951.227 2861.51,949.609L2858.86,950.575C2857.54,951.684 2855.9,952.515 2854.08,952.718Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2755.11,988.336C2759.8,989.121 2764.44,990.047 2768.93,991.21L2771.01,990.453C2766.62,989.245 2762.08,988.286 2757.48,987.473L2755.11,988.336Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2755.11,988.336C2759.8,989.121 2764.44,990.047 2768.93,991.21L2771.01,990.453C2766.62,989.245 2762.08,988.286 2757.48,987.473L2755.11,988.336Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2952.26,1031.18C2954.29,1032.2 2956.43,1033.11 2958.18,1032.08C2959.55,1031.29 2959.98,1029.68 2959.5,1027.17L2956.12,1009.44C2956,1008.81 2955.87,1008.18 2955.74,1007.56L2889.61,1031.63C2893.55,1031.51 2897.56,1030.82 2901.05,1030.21C2917.33,1027.36 2937.58,1023.82 2952.26,1031.18Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2957.41,1009.17L2960.79,1026.9C2961.37,1029.94 2960.71,1032.03 2958.83,1033.13C2958.58,1033.27 2958.33,1033.39 2958.08,1033.48C2955.72,1034.34 2953.18,1033.07 2951.6,1032.28C2937.31,1025.11 2917.31,1028.62 2901.23,1031.43C2896.72,1032.21 2891.38,1033.14 2886.33,1032.82L2853.26,1044.86C2855.17,1046.86 2857.45,1048.45 2860.12,1049.63C2869.55,1053.78 2881.61,1051.83 2893.28,1049.95C2897.07,1049.34 2900.98,1048.71 2904.73,1048.3C2920.68,1046.55 2937,1049.14 2950.7,1055.61C2958.79,1059.43 2965.76,1064.5 2971.22,1070.45L2993.98,1062.16C2992.58,1061.01 2991.14,1059.89 2989.71,1058.77C2988.61,1057.91 2987.51,1057.06 2986.44,1056.19C2980.11,1051.12 2971.79,1043.19 2972.62,1033.04C2973.1,1027.15 2976.79,1021.2 2983.01,1016.29C2988.82,1011.71 2996.34,1008.46 3003.66,1007.37C3008.1,1006.71 3012.64,1006.66 3017.26,1007.18C3016.14,1000.41 3014.54,993.648 3012.41,986.931L2956.99,1007.1C2957.14,1007.78 2957.27,1008.47 2957.41,1009.17Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3004.18,1043.01C3003.96,1045.89 3007.32,1047.71 3010.27,1049.31L3010.47,1049.41C3012.84,1050.69 3015.04,1052.15 3017.06,1053.76L3017.39,1053.64C3018.18,1048.68 3018.72,1043.66 3018.99,1038.61C3016.74,1037.74 3014.24,1037.12 3011.58,1037.4C3008.25,1037.76 3004.39,1040.15 3004.18,1043.01Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2831.12,1100.39C2833.39,1099.32 2835.26,1097.59 2837.07,1095.93C2837.49,1095.54 2837.91,1095.15 2838.34,1094.77C2841.17,1092.24 2848.95,1089.06 2857.18,1085.7C2865.37,1082.37 2876.59,1077.79 2876.36,1075.76C2876.09,1073.35 2869.4,1072.61 2864.51,1072.06C2862.09,1071.79 2859.99,1071.56 2858.66,1071.17C2854.49,1069.96 2851.09,1067.92 2847.81,1065.94C2844.87,1064.17 2842.09,1062.5 2838.79,1061.35C2837.28,1060.83 2835.05,1060.59 2833.07,1061.3C2832.6,1061.47 2832.14,1061.7 2831.71,1062C2829.55,1063.47 2828.92,1065.95 2828.45,1068.37L2823.3,1095.4C2822.96,1097.2 2822.74,1098.79 2823.57,1099.93C2824.91,1101.79 2828.53,1101.62 2831.12,1100.39Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2770.1,1097.29C2784.45,1095.57 2795.15,1083 2797.93,1072.04C2798.56,1069.58 2798.91,1067.11 2799.01,1064.6L2785.36,1069.57C2785.33,1071.09 2785.15,1072.64 2784.66,1074.17C2783.49,1077.94 2780.47,1081.13 2776.92,1082.42C2776.78,1082.47 2776.64,1082.52 2776.51,1082.57C2772.69,1083.78 2768.3,1082.88 2765.3,1080.27C2764.42,1079.5 2763.69,1078.61 2763.08,1077.68L2744.84,1084.32C2750.26,1093.84 2760.66,1098.41 2770.1,1097.29Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2799.11,1099.69C2810.13,1087.45 2815.89,1072.58 2815.87,1058.47L2800.33,1064.12C2800.26,1066.86 2799.89,1069.58 2799.2,1072.27C2796.76,1081.89 2788.42,1092.71 2776.87,1096.91C2774.75,1097.69 2772.52,1098.24 2770.2,1098.51C2762.51,1099.43 2755.15,1097.11 2749.47,1091.98C2747.18,1089.91 2745.25,1087.44 2743.71,1084.73L2738.15,1086.75C2742.8,1097.99 2748.72,1108.36 2755.66,1117.75C2770.39,1121.04 2788.69,1111.28 2799.11,1099.69Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2776.14,1081.39C2779.45,1080.34 2782.31,1077.38 2783.41,1073.88C2783.81,1072.6 2783.99,1071.32 2784.04,1070.05L2764.35,1077.22C2764.88,1077.99 2765.49,1078.71 2766.22,1079.35C2768.87,1081.66 2772.77,1082.47 2776.14,1081.39Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2823.01,1122.84C2821.3,1123.88 2819.6,1124.92 2817.9,1125.97C2817.53,1126.2 2817.16,1126.43 2816.78,1126.66L2891.02,1099.64C2885.62,1099.17 2879.6,1099.44 2873.04,1100.69C2854.61,1104.2 2837.55,1113.96 2823.01,1122.84Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2817.22,1124.94C2818.93,1123.89 2820.63,1122.85 2822.33,1121.81C2836.98,1112.87 2854.18,1103.03 2872.84,1099.48C2879.79,1098.15 2886.92,1097.91 2893.5,1098.74L2969.88,1070.93C2964.58,1065.25 2957.86,1060.4 2950.07,1056.72C2936.59,1050.36 2920.52,1047.81 2904.82,1049.53C2901.1,1049.93 2897.21,1050.56 2893.45,1051.17C2881.58,1053.08 2869.31,1055.06 2859.52,1050.76C2856.53,1049.44 2854.01,1047.62 2851.92,1045.35L2817.17,1057.99C2817.31,1072.53 2811.43,1087.87 2800.1,1100.47C2793.93,1107.32 2785.07,1113.54 2775.74,1116.93C2769.39,1119.25 2762.84,1120.24 2756.77,1119.24C2762.87,1127.27 2769.74,1134.56 2777.23,1141.06L2810.97,1128.78C2813.06,1127.5 2815.15,1126.22 2817.22,1124.94ZM2822.01,1095.23L2827.17,1068.21C2827.66,1065.64 2828.39,1062.76 2830.98,1061C2833.16,1059.52 2836.43,1059.2 2839.29,1060.19C2842.72,1061.38 2845.68,1063.16 2848.55,1064.88C2851.76,1066.82 2855.09,1068.83 2859.1,1069.99C2860.32,1070.35 2862.46,1070.58 2864.72,1070.84C2870.61,1071.49 2877.28,1072.23 2877.66,1075.58C2877.98,1078.4 2870.27,1081.69 2857.65,1086.84C2849.89,1090 2841.86,1093.28 2839.21,1095.65C2838.79,1096.03 2838.37,1096.41 2837.96,1096.79C2836.08,1098.53 2834.13,1100.33 2831.66,1101.5C2831.36,1101.64 2831.05,1101.77 2830.73,1101.88C2827.72,1102.98 2824.05,1102.85 2822.47,1100.66C2821.29,1099.03 2821.71,1096.83 2822.01,1095.23Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2973.92,1033.07C2973.14,1042.68 2981.19,1050.32 2987.31,1055.24C2988.38,1056.1 2989.47,1056.95 2990.57,1057.81C2992.18,1059.07 2993.81,1060.34 2995.39,1061.65L3001.64,1059.38C2996.87,1053.75 2993.36,1047.3 2991.4,1040.33C2989.71,1034.3 2990.35,1029.67 2993.36,1026.18C2998.91,1019.74 3009.79,1020.05 3017.43,1022.27C3017.95,1022.43 3018.46,1022.6 3018.97,1022.77C3018.71,1018.01 3018.21,1013.24 3017.46,1008.46C3012.82,1007.9 3008.26,1007.94 3003.8,1008.6C2990.17,1010.61 2974.91,1020.93 2973.92,1033.07Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2994.35,1026.93C2991.64,1030.08 2991.09,1034.34 2992.67,1039.95C2994.62,1046.9 2998.14,1053.32 3002.94,1058.9L3015.62,1054.29C3013.81,1052.89 3011.86,1051.62 3009.78,1050.49L3009.58,1050.39C3006.47,1048.71 3002.6,1046.61 3002.87,1042.98C3003.13,1039.43 3007.54,1036.6 3011.49,1036.17C3014.23,1035.88 3016.76,1036.44 3019.05,1037.28C3019.25,1032.91 3019.25,1028.52 3019.05,1024.12C3018.36,1023.89 3017.68,1023.66 3016.99,1023.46C3009.74,1021.35 2999.47,1021 2994.35,1026.93Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2797.93,1072.04C2795.15,1083 2784.45,1095.57 2770.1,1097.29C2760.66,1098.41 2750.26,1093.85 2744.84,1084.32L2743.71,1084.73C2745.25,1087.44 2747.18,1089.91 2749.47,1091.98C2755.15,1097.11 2762.51,1099.43 2770.2,1098.51C2772.52,1098.24 2774.75,1097.69 2776.88,1096.91C2788.42,1092.71 2796.76,1081.89 2799.2,1072.27C2799.89,1069.58 2800.26,1066.87 2800.33,1064.12L2799.01,1064.6C2798.91,1067.1 2798.56,1069.58 2797.93,1072.04Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2800.09,1100.47C2811.43,1087.87 2817.31,1072.53 2817.17,1057.99L2815.87,1058.47C2815.89,1072.58 2810.13,1087.45 2799.11,1099.69C2788.69,1111.28 2770.39,1121.04 2755.66,1117.75C2756.03,1118.24 2756.4,1118.75 2756.77,1119.24C2762.84,1120.24 2769.39,1119.24 2775.74,1116.93C2785.06,1113.54 2793.93,1107.32 2800.09,1100.47Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2776.51,1082.57C2776.64,1082.52 2776.78,1082.47 2776.92,1082.42C2780.47,1081.13 2783.49,1077.94 2784.66,1074.17C2785.14,1072.64 2785.33,1071.09 2785.36,1069.57L2784.04,1070.05C2783.99,1071.32 2783.81,1072.6 2783.41,1073.88C2782.31,1077.38 2779.45,1080.34 2776.14,1081.39C2772.77,1082.47 2768.87,1081.66 2766.22,1079.35C2765.49,1078.71 2764.88,1077.99 2764.35,1077.22L2763.07,1077.68C2763.69,1078.61 2764.41,1079.5 2765.3,1080.27C2768.29,1082.88 2772.69,1083.78 2776.51,1082.57Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2822.33,1121.81C2820.63,1122.85 2818.93,1123.89 2817.22,1124.94C2815.15,1126.22 2813.06,1127.5 2810.96,1128.78L2816.78,1126.66C2817.16,1126.43 2817.53,1126.2 2817.9,1125.97C2819.6,1124.92 2821.3,1123.88 2823.01,1122.84C2837.55,1113.96 2854.61,1104.2 2873.04,1100.69C2879.6,1099.44 2885.62,1099.17 2891.02,1099.64L2893.51,1098.73C2886.92,1097.91 2879.79,1098.15 2872.84,1099.48C2854.18,1103.03 2836.98,1112.87 2822.33,1121.81Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2904.73,1048.3C2900.97,1048.71 2897.07,1049.34 2893.28,1049.95C2881.61,1051.83 2869.54,1053.77 2860.12,1049.63C2857.45,1048.45 2855.17,1046.85 2853.26,1044.86L2851.92,1045.35C2854,1047.62 2856.53,1049.44 2859.52,1050.76C2869.3,1055.06 2881.58,1053.08 2893.44,1051.17C2897.21,1050.56 2901.1,1049.93 2904.82,1049.53C2920.52,1047.8 2936.59,1050.36 2950.07,1056.72C2957.86,1060.4 2964.58,1065.25 2969.88,1070.93L2971.21,1070.45C2965.76,1064.5 2958.79,1059.43 2950.7,1055.61C2937,1049.14 2920.67,1046.55 2904.73,1048.3Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3003.65,1007.37C2996.34,1008.46 2988.82,1011.71 2983.01,1016.29C2976.79,1021.2 2973.1,1027.15 2972.62,1033.03C2971.79,1043.18 2980.11,1051.11 2986.43,1056.19C2987.51,1057.06 2988.61,1057.91 2989.71,1058.77C2991.14,1059.89 2992.58,1061.01 2993.98,1062.16L2995.39,1061.65C2993.81,1060.34 2992.19,1059.07 2990.57,1057.81C2989.47,1056.95 2988.38,1056.1 2987.31,1055.24C2981.19,1050.32 2973.14,1042.68 2973.92,1033.07C2974.91,1020.93 2990.17,1010.61 3003.8,1008.6C3008.26,1007.93 3012.82,1007.89 3017.46,1008.46C3017.4,1008.03 3017.33,1007.61 3017.26,1007.18C3012.64,1006.66 3008.1,1006.71 3003.65,1007.37Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2993.36,1026.18C2990.35,1029.67 2989.71,1034.3 2991.4,1040.33C2993.36,1047.3 2996.87,1053.75 3001.64,1059.38L3002.95,1058.9C2998.14,1053.32 2994.62,1046.9 2992.67,1039.96C2991.09,1034.34 2991.64,1030.08 2994.36,1026.93C2999.47,1021 3009.75,1021.35 3016.99,1023.46C3017.68,1023.66 3018.36,1023.9 3019.05,1024.12C3019.03,1023.67 3019,1023.22 3018.97,1022.77C3018.46,1022.6 3017.95,1022.43 3017.43,1022.27C3009.79,1020.05 2998.91,1019.74 2993.36,1026.18Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3002.87,1042.98C3002.6,1046.61 3006.47,1048.71 3009.59,1050.39L3009.78,1050.49C3011.87,1051.62 3013.81,1052.9 3015.62,1054.29L3017.06,1053.76C3015.04,1052.15 3012.84,1050.69 3010.47,1049.41L3010.27,1049.31C3007.32,1047.71 3003.96,1045.9 3004.18,1043.01C3004.39,1040.15 3008.25,1037.76 3011.58,1037.4C3014.24,1037.12 3016.74,1037.74 3018.99,1038.61C3019.01,1038.16 3019.03,1037.72 3019.05,1037.28C3016.77,1036.44 3014.23,1035.88 3011.49,1036.17C3007.54,1036.6 3003.13,1039.43 3002.87,1042.98Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2951.6,1032.28C2953.18,1033.07 2955.72,1034.34 2958.08,1033.48C2958.33,1033.39 2958.58,1033.27 2958.83,1033.12C2960.71,1032.03 2961.37,1029.94 2960.79,1026.9L2957.41,1009.16C2957.28,1008.47 2957.14,1007.78 2956.99,1007.1L2955.74,1007.56C2955.87,1008.18 2956,1008.81 2956.12,1009.44L2959.5,1027.17C2959.98,1029.68 2959.55,1031.28 2958.18,1032.08C2956.43,1033.11 2954.29,1032.19 2952.26,1031.17C2937.58,1023.82 2917.33,1027.36 2901.05,1030.21C2897.56,1030.82 2893.55,1031.51 2889.61,1031.62L2886.33,1032.82C2891.38,1033.14 2896.72,1032.21 2901.23,1031.42C2917.31,1028.61 2937.31,1025.11 2951.6,1032.28Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2830.73,1101.88C2831.05,1101.77 2831.36,1101.64 2831.66,1101.5C2834.13,1100.33 2836.08,1098.53 2837.96,1096.79C2838.37,1096.41 2838.79,1096.03 2839.21,1095.65C2841.86,1093.28 2849.89,1090.01 2857.65,1086.84C2870.27,1081.69 2877.98,1078.4 2877.66,1075.58C2877.28,1072.24 2870.61,1071.49 2864.72,1070.84C2862.46,1070.58 2860.32,1070.35 2859.1,1069.99C2855.09,1068.83 2851.76,1066.82 2848.55,1064.89C2845.68,1063.16 2842.72,1061.38 2839.29,1060.19C2836.43,1059.2 2833.16,1059.52 2830.98,1061.01C2828.39,1062.76 2827.66,1065.64 2827.17,1068.21L2822.01,1095.23C2821.71,1096.83 2821.29,1099.03 2822.47,1100.66C2824.05,1102.85 2827.72,1102.98 2830.73,1101.88ZM2823.3,1095.4L2828.45,1068.37C2828.92,1065.95 2829.55,1063.47 2831.71,1062C2832.14,1061.7 2832.6,1061.48 2833.07,1061.3C2835.05,1060.58 2837.28,1060.83 2838.79,1061.35C2842.09,1062.5 2844.87,1064.17 2847.81,1065.94C2851.09,1067.92 2854.49,1069.96 2858.66,1071.17C2859.99,1071.56 2862.09,1071.79 2864.51,1072.06C2869.4,1072.61 2876.09,1073.35 2876.36,1075.76C2876.59,1077.79 2865.37,1082.37 2857.18,1085.7C2848.95,1089.06 2841.17,1092.24 2838.34,1094.77C2837.91,1095.15 2837.49,1095.54 2837.07,1095.93C2835.26,1097.59 2833.39,1099.32 2831.12,1100.39C2828.53,1101.62 2824.91,1101.79 2823.57,1099.93C2822.74,1098.79 2822.96,1097.2 2823.3,1095.4Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3017.33,1053.97C3017.35,1053.86 3017.37,1053.75 3017.39,1053.64L3017.06,1053.76C3017.15,1053.83 3017.24,1053.9 3017.33,1053.97Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3017.33,1053.97C3017.35,1053.86 3017.37,1053.75 3017.39,1053.64L3017.06,1053.76C3017.15,1053.83 3017.24,1053.9 3017.33,1053.97Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2777.23,1141.06C2778.57,1142.21 2779.92,1143.35 2781.29,1144.46C2791.66,1140.19 2801.45,1134.56 2810.96,1128.78L2777.23,1141.06Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2777.23,1141.06C2778.57,1142.21 2779.92,1143.35 2781.29,1144.46C2791.66,1140.19 2801.45,1134.56 2810.96,1128.78L2777.23,1141.06Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3002.95,1058.9C3004.71,1060.95 3006.65,1062.89 3008.75,1064.69L3015.78,1062.14C3016.27,1059.92 3016.71,1057.7 3017.09,1055.46C3016.61,1055.06 3016.12,1054.67 3015.62,1054.29L3002.95,1058.9Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3002.95,1058.9C3004.71,1060.95 3006.65,1062.89 3008.75,1064.69L3015.78,1062.14C3016.27,1059.92 3016.71,1057.7 3017.09,1055.46C3016.61,1055.06 3016.12,1054.67 3015.62,1054.29L3002.95,1058.9Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3007.36,1065.2C3005.29,1063.38 3003.38,1061.43 3001.64,1059.38L2995.39,1061.65C2997.56,1063.45 2999.63,1065.32 3001.46,1067.35L3007.36,1065.2Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3007.36,1065.2C3005.29,1063.38 3003.38,1061.43 3001.64,1059.38L2995.39,1061.65C2997.56,1063.45 2999.63,1065.32 3001.46,1067.35L3007.36,1065.2Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2845.9,1123.97L2847.14,1123.52L2876.02,1113.01L2877.27,1112.55L2910.33,1100.52L2911.6,1100.06L2933.26,1092.17L2934.92,1091.57L2989.69,1071.63L2991.24,1071.07L3000.13,1067.83C2998.28,1065.83 2996.17,1063.96 2993.98,1062.16L2971.22,1070.45C2973.01,1072.4 2974.64,1074.45 2976.1,1076.58L2974.82,1077.04C2973.35,1074.92 2971.7,1072.88 2969.88,1070.93L2893.5,1098.73C2895.71,1099.01 2897.85,1099.4 2899.9,1099.92C2902.15,1100.48 2904.27,1101.18 2906.24,1102L2904.44,1102.66C2900.56,1101.13 2896.06,1100.07 2891.02,1099.64L2816.78,1126.66C2805.8,1133.41 2794.5,1140.27 2782.39,1145.34C2782.9,1145.73 2783.41,1146.13 2783.92,1146.53L2845.9,1123.97ZM2824.89,1131.61C2829.68,1129.59 2834.6,1127.8 2839.62,1126.25L2824.89,1131.61Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2845.9,1123.97L2847.14,1123.52L2876.02,1113.01L2877.27,1112.55L2910.33,1100.52L2911.6,1100.06L2933.26,1092.17L2934.92,1091.57L2989.69,1071.63L2991.24,1071.07L3000.13,1067.83C2998.28,1065.83 2996.17,1063.96 2993.98,1062.16L2971.22,1070.45C2973.01,1072.4 2974.64,1074.45 2976.1,1076.58L2974.82,1077.04C2973.35,1074.92 2971.7,1072.88 2969.88,1070.93L2893.5,1098.73C2895.71,1099.01 2897.85,1099.4 2899.9,1099.92C2902.15,1100.48 2904.27,1101.18 2906.24,1102L2904.44,1102.66C2900.56,1101.13 2896.06,1100.07 2891.02,1099.64L2816.78,1126.66C2805.8,1133.41 2794.5,1140.27 2782.39,1145.34C2782.9,1145.73 2783.41,1146.13 2783.92,1146.53L2845.9,1123.97ZM2824.89,1131.61C2829.68,1129.59 2834.6,1127.8 2839.62,1126.25L2824.89,1131.61Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2824.89,1131.61L2839.62,1126.25C2834.6,1127.8 2829.68,1129.59 2824.89,1131.61Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2824.89,1131.61L2839.62,1126.25C2834.6,1127.8 2829.68,1129.59 2824.89,1131.61Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2904.44,1102.66L2906.24,1102.01C2904.27,1101.18 2902.15,1100.48 2899.9,1099.92C2897.85,1099.4 2895.71,1099.01 2893.5,1098.74L2891.02,1099.64C2896.06,1100.07 2900.56,1101.14 2904.44,1102.66Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2904.44,1102.66L2906.24,1102.01C2904.27,1101.18 2902.15,1100.48 2899.9,1099.92C2897.85,1099.4 2895.71,1099.01 2893.5,1098.74L2891.02,1099.64C2896.06,1100.07 2900.56,1101.14 2904.44,1102.66Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2810.96,1128.78C2801.45,1134.56 2791.66,1140.19 2781.29,1144.46C2781.66,1144.76 2782.03,1145.05 2782.4,1145.34C2794.5,1140.28 2805.8,1133.41 2816.78,1126.66L2810.96,1128.78Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2810.96,1128.78C2801.45,1134.56 2791.66,1140.19 2781.29,1144.46C2781.66,1144.76 2782.03,1145.05 2782.4,1145.34C2794.5,1140.28 2805.8,1133.41 2816.78,1126.66L2810.96,1128.78Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2974.82,1077.04L2976.1,1076.58C2974.64,1074.45 2973.01,1072.4 2971.22,1070.45L2969.88,1070.93C2971.7,1072.88 2973.35,1074.92 2974.82,1077.04Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2974.82,1077.04L2976.1,1076.58C2974.64,1074.45 2973.01,1072.4 2971.22,1070.45L2969.88,1070.93C2971.7,1072.88 2973.35,1074.92 2974.82,1077.04Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3001.46,1067.35C2999.63,1065.32 2997.56,1063.45 2995.39,1061.65L2993.98,1062.16C2996.17,1063.96 2998.28,1065.83 3000.13,1067.83L3001.46,1067.35Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3001.46,1067.35C2999.63,1065.32 2997.56,1063.45 2995.39,1061.65L2993.98,1062.16C2996.17,1063.96 2998.28,1065.83 3000.13,1067.83L3001.46,1067.35Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3008.75,1064.69C3006.65,1062.89 3004.71,1060.95 3002.95,1058.9L3001.64,1059.38C3003.38,1061.43 3005.29,1063.38 3007.36,1065.2L3008.75,1064.69Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3008.75,1064.69C3006.65,1062.89 3004.71,1060.95 3002.95,1058.9L3001.64,1059.38C3003.38,1061.43 3005.29,1063.38 3007.36,1065.2L3008.75,1064.69Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3015.62,1054.29C3016.12,1054.67 3016.61,1055.06 3017.09,1055.46C3017.18,1054.96 3017.25,1054.47 3017.33,1053.97C3017.25,1053.9 3017.15,1053.83 3017.06,1053.76L3015.62,1054.29Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3015.62,1054.29C3016.12,1054.67 3016.61,1055.06 3017.09,1055.46C3017.18,1054.96 3017.25,1054.47 3017.33,1053.97C3017.25,1053.9 3017.15,1053.83 3017.06,1053.76L3015.62,1054.29Z" style="fill:rgb(186,110,2);fill-opacity:0.73;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2740.85,1077.86L2742.07,1077.42C2742.71,1079.95 2743.66,1082.25 2744.84,1084.32L2763.08,1077.68C2761.79,1075.74 2761,1073.57 2760.4,1071.56L2761.03,1071.37L2760.39,1071.54C2760.33,1071.32 2760.25,1071.08 2760.18,1070.83L2761.42,1070.38C2761.5,1070.66 2761.58,1070.92 2761.65,1071.15L2761.66,1071.18C2762.26,1073.17 2763.04,1075.34 2764.35,1077.22L2784.05,1070.05C2784.13,1068.08 2783.9,1066.11 2783.68,1064.19C2783.61,1063.59 2783.54,1062.97 2783.46,1062.36L2784.73,1061.89C2784.82,1062.6 2784.9,1063.3 2784.98,1063.99C2785.19,1065.75 2785.4,1067.64 2785.36,1069.57L2799.01,1064.6C2799.11,1062.06 2798.93,1059.49 2798.48,1056.89L2799.74,1056.43C2800.2,1059.02 2800.39,1061.58 2800.33,1064.12L2815.87,1058.47C2815.87,1055.87 2815.69,1053.3 2815.29,1050.77L2735.49,1079.82C2735.6,1080.13 2735.7,1080.45 2735.82,1080.77C2736.55,1082.79 2737.34,1084.78 2738.16,1086.75L2743.71,1084.73C2742.49,1082.57 2741.51,1080.26 2740.85,1077.86Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2740.85,1077.86L2742.07,1077.42C2742.71,1079.95 2743.66,1082.25 2744.84,1084.32L2763.08,1077.68C2761.79,1075.74 2761,1073.57 2760.4,1071.56L2761.03,1071.37L2760.39,1071.54C2760.33,1071.32 2760.25,1071.08 2760.18,1070.83L2761.42,1070.38C2761.5,1070.66 2761.58,1070.92 2761.65,1071.15L2761.66,1071.18C2762.26,1073.17 2763.04,1075.34 2764.35,1077.22L2784.05,1070.05C2784.13,1068.08 2783.9,1066.11 2783.68,1064.19C2783.61,1063.59 2783.54,1062.97 2783.46,1062.36L2784.73,1061.89C2784.82,1062.6 2784.9,1063.3 2784.98,1063.99C2785.19,1065.75 2785.4,1067.64 2785.36,1069.57L2799.01,1064.6C2799.11,1062.06 2798.93,1059.49 2798.48,1056.89L2799.74,1056.43C2800.2,1059.02 2800.39,1061.58 2800.33,1064.12L2815.87,1058.47C2815.87,1055.87 2815.69,1053.3 2815.29,1050.77L2735.49,1079.82C2735.6,1080.13 2735.7,1080.45 2735.82,1080.77C2736.55,1082.79 2737.34,1084.78 2738.16,1086.75L2743.71,1084.73C2742.49,1082.57 2741.51,1080.26 2740.85,1077.86Z" style="fill:rgb(247,165,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2995.16,985.301L2956.16,999.496L2954.63,1000.05L2905.93,1017.78L2899.84,1019.99L2855.52,1036.13L2854.27,1036.58L2816.54,1050.31C2816.95,1052.83 2817.15,1055.4 2817.17,1057.99L2851.92,1045.34C2850.25,1043.52 2848.85,1041.39 2847.76,1038.95L2849.01,1038.5C2850.12,1040.95 2851.54,1043.06 2853.26,1044.86L2886.33,1032.82C2884.73,1032.72 2883.16,1032.5 2881.65,1032.1C2878.66,1031.31 2876.66,1030.17 2875.32,1028.92L2876.74,1028.4C2878.05,1029.47 2879.83,1030.32 2882.06,1030.91C2884.43,1031.54 2887.01,1031.7 2889.61,1031.63L2955.74,1007.56C2955.19,1005 2954.51,1002.58 2953.39,1000.5L2954.63,1000.05L2954.65,1000.05C2955.76,1002.15 2956.44,1004.57 2956.99,1007.1L3012.41,986.928C3011.77,984.895 3011.09,982.865 3010.36,980.842C3010.24,980.525 3010.12,980.215 3010,979.9L2996.51,984.809L2995.16,985.301Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2995.16,985.301L2956.16,999.496L2954.63,1000.05L2905.93,1017.78L2899.84,1019.99L2855.52,1036.13L2854.27,1036.58L2816.54,1050.31C2816.95,1052.83 2817.15,1055.4 2817.17,1057.99L2851.92,1045.34C2850.25,1043.52 2848.85,1041.39 2847.76,1038.95L2849.01,1038.5C2850.12,1040.95 2851.54,1043.06 2853.26,1044.86L2886.33,1032.82C2884.73,1032.72 2883.16,1032.5 2881.65,1032.1C2878.66,1031.31 2876.66,1030.17 2875.32,1028.92L2876.74,1028.4C2878.05,1029.47 2879.83,1030.32 2882.06,1030.91C2884.43,1031.54 2887.01,1031.7 2889.61,1031.63L2955.74,1007.56C2955.19,1005 2954.51,1002.58 2953.39,1000.5L2954.63,1000.05L2954.65,1000.05C2955.76,1002.15 2956.44,1004.57 2956.99,1007.1L3012.41,986.928C3011.77,984.895 3011.09,982.865 3010.36,980.842C3010.24,980.525 3010.12,980.215 3010,979.9L2996.51,984.809L2995.16,985.301Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2742.07,1077.42L2740.85,1077.86C2741.51,1080.26 2742.49,1082.57 2743.71,1084.73L2744.84,1084.32C2743.66,1082.25 2742.71,1079.95 2742.07,1077.42Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2742.07,1077.42L2740.85,1077.86C2741.51,1080.26 2742.49,1082.57 2743.71,1084.73L2744.84,1084.32C2743.66,1082.25 2742.71,1079.95 2742.07,1077.42Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2798.48,1056.89C2798.93,1059.49 2799.11,1062.06 2799.01,1064.6L2800.33,1064.12C2800.39,1061.58 2800.2,1059.02 2799.74,1056.43L2798.48,1056.89Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2798.48,1056.89C2798.93,1059.49 2799.11,1062.06 2799.01,1064.6L2800.33,1064.12C2800.39,1061.58 2800.2,1059.02 2799.74,1056.43L2798.48,1056.89Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2815.29,1050.77C2815.69,1053.3 2815.86,1055.87 2815.87,1058.47L2817.17,1057.99C2817.14,1055.4 2816.95,1052.83 2816.54,1050.31L2815.29,1050.77Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2815.29,1050.77C2815.69,1053.3 2815.86,1055.87 2815.87,1058.47L2817.17,1057.99C2817.14,1055.4 2816.95,1052.83 2816.54,1050.31L2815.29,1050.77Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2761.66,1071.17L2761.65,1071.15C2761.58,1070.92 2761.5,1070.66 2761.42,1070.38L2760.18,1070.83C2760.25,1071.08 2760.32,1071.32 2760.39,1071.54L2761.03,1071.37L2760.4,1071.56C2761,1073.57 2761.79,1075.74 2763.07,1077.68L2764.35,1077.22C2763.04,1075.34 2762.25,1073.17 2761.66,1071.17Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2761.66,1071.17L2761.65,1071.15C2761.58,1070.92 2761.5,1070.66 2761.42,1070.38L2760.18,1070.83C2760.25,1071.08 2760.32,1071.32 2760.39,1071.54L2761.03,1071.37L2760.4,1071.56C2761,1073.57 2761.79,1075.74 2763.07,1077.68L2764.35,1077.22C2763.04,1075.34 2762.25,1073.17 2761.66,1071.17Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2784.98,1063.99C2784.9,1063.3 2784.82,1062.6 2784.73,1061.89L2783.46,1062.36C2783.54,1062.97 2783.61,1063.59 2783.68,1064.19C2783.9,1066.11 2784.13,1068.08 2784.04,1070.05L2785.36,1069.57C2785.39,1067.64 2785.18,1065.75 2784.98,1063.99Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2784.98,1063.99C2784.9,1063.3 2784.82,1062.6 2784.73,1061.89L2783.46,1062.36C2783.54,1062.97 2783.61,1063.59 2783.68,1064.19C2783.9,1066.11 2784.13,1068.08 2784.04,1070.05L2785.36,1069.57C2785.39,1067.64 2785.18,1065.75 2784.98,1063.99Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2849.01,1038.5L2847.76,1038.95C2848.85,1041.4 2850.24,1043.52 2851.92,1045.35L2853.26,1044.86C2851.54,1043.06 2850.12,1040.95 2849.01,1038.5Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2849.01,1038.5L2847.76,1038.95C2848.85,1041.4 2850.24,1043.52 2851.92,1045.35L2853.26,1044.86C2851.54,1043.06 2850.12,1040.95 2849.01,1038.5Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2882.06,1030.91C2879.83,1030.32 2878.05,1029.47 2876.73,1028.41L2875.32,1028.92C2876.66,1030.17 2878.66,1031.31 2881.65,1032.1C2883.16,1032.5 2884.73,1032.72 2886.33,1032.82L2889.61,1031.63C2887.01,1031.7 2884.43,1031.54 2882.06,1030.91Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2882.06,1030.91C2879.83,1030.32 2878.05,1029.47 2876.73,1028.41L2875.32,1028.92C2876.66,1030.17 2878.66,1031.31 2881.65,1032.1C2883.16,1032.5 2884.73,1032.72 2886.33,1032.82L2889.61,1031.63C2887.01,1031.7 2884.43,1031.54 2882.06,1030.91Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2954.65,1000.05L2954.63,1000.05L2953.39,1000.5C2954.51,1002.58 2955.19,1005 2955.74,1007.56L2956.99,1007.1C2956.44,1004.57 2955.76,1002.15 2954.65,1000.05Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2954.65,1000.05L2954.63,1000.05L2953.39,1000.5C2954.51,1002.58 2955.19,1005 2955.74,1007.56L2956.99,1007.1C2956.44,1004.57 2955.76,1002.15 2954.65,1000.05Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2840.82,1173.29C2855.11,1176.54 2869.97,1177.65 2884.94,1176.43C2875.35,1172.29 2861.91,1171.08 2844.78,1172.84C2843.47,1172.98 2842.14,1173.13 2840.82,1173.29Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2884.32,1121.23C2887.68,1123.28 2891.71,1124.67 2895.61,1126.01C2896.89,1126.44 2898.17,1126.88 2899.43,1127.35C2904.04,1129.04 2909.73,1131.55 2912.84,1136C2913.85,1137.46 2914.56,1139.07 2915.24,1140.63C2915.83,1141.98 2916.43,1143.37 2917.23,1144.61C2918.75,1147 2921.72,1149.4 2925.51,1148.98C2929.32,1148.55 2932.36,1145.38 2933.28,1142.62C2934.16,1139.98 2933.83,1137.06 2932.26,1133.7C2929.99,1128.85 2926.11,1124.7 2922.36,1120.7C2920.45,1118.65 2918.47,1116.54 2916.71,1114.34C2915.34,1112.63 2913.74,1110.33 2912.47,1107.64L2881.31,1118.99C2882.19,1119.78 2883.18,1120.54 2884.32,1121.23Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2948.2,1133.1C2944.11,1128.59 2939.89,1123.92 2936.7,1118.73C2933.03,1112.76 2931.29,1106.58 2931.49,1100.72L2913.72,1107.19C2914.94,1109.74 2916.47,1111.93 2917.77,1113.56C2919.5,1115.73 2921.46,1117.82 2923.36,1119.85C2927.17,1123.92 2931.11,1128.14 2933.46,1133.15C2935.16,1136.8 2935.51,1140 2934.53,1142.94C2933.64,1145.61 2931.17,1148.47 2927.85,1149.67C2927.14,1149.93 2926.39,1150.12 2925.6,1150.2C2921.22,1150.69 2917.82,1147.99 2916.1,1145.29C2915.25,1143.97 2914.63,1142.54 2914.03,1141.15C2913.34,1139.57 2912.68,1138.08 2911.73,1136.71C2908.82,1132.54 2903.35,1130.13 2898.9,1128.5C2897.65,1128.04 2896.38,1127.61 2895.12,1127.17C2891.15,1125.81 2887.05,1124.4 2883.57,1122.28C2882.18,1121.43 2880.97,1120.49 2879.94,1119.49L2850.3,1130.27C2851.58,1132.67 2853.03,1134.98 2854.73,1137.16C2860.63,1144.72 2868.85,1149.4 2877.87,1150.35C2881.33,1150.71 2885.05,1150.54 2888.64,1150.37C2893.97,1150.12 2899.48,1149.87 2904.34,1151.35C2913.2,1154.05 2917.39,1161.42 2921.33,1168.68C2921.9,1168.48 2922.48,1168.28 2923.05,1168.08C2936.63,1163.13 2949.02,1156.38 2960.07,1148.2C2959.03,1146.4 2957.83,1144.58 2956.44,1142.75C2953.91,1139.41 2951,1136.2 2948.2,1133.1Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2993.36,1106.4C2996.4,1100.7 2999.54,1094.81 2999.97,1088.91C3000.28,1084.7 2998.96,1080.64 2996.21,1077.17L2932.83,1100.24C2932.52,1106.01 2934.19,1112.13 2937.84,1118.07C2940.97,1123.17 2945.16,1127.8 2949.21,1132.27C2952.03,1135.39 2954.95,1138.62 2957.51,1142C2958.89,1143.81 2960.09,1145.62 2961.14,1147.41C2970.78,1140.13 2979.38,1131.75 2986.81,1122.52C2987.72,1117.01 2990.58,1111.62 2993.36,1106.4Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3001.28,1088.94C3000.83,1095.1 2997.62,1101.11 2994.52,1106.92C2992.22,1111.25 2989.86,1115.7 2988.65,1120.18C3000.01,1105.46 3008.47,1088.69 3013.57,1070.85L2997.5,1076.7C3000.28,1080.33 3001.6,1084.55 3001.28,1088.94Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2903.88,1152.53C2899.25,1151.11 2894.09,1151.35 2888.64,1151.61C2885,1151.78 2881.23,1151.95 2877.66,1151.58C2868.29,1150.6 2859.77,1145.75 2853.66,1137.92C2851.89,1135.64 2850.38,1133.23 2849.05,1130.73L2791.21,1151.78C2805.26,1161.28 2820.89,1168.29 2837.35,1172.45C2839.8,1172.15 2842.27,1171.86 2844.7,1171.61C2863.54,1169.68 2877.65,1171.19 2887.52,1176.2C2898.39,1175.11 2909.3,1172.78 2920.07,1169.12C2916.27,1162.12 2912.2,1155.06 2903.88,1152.53Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2994.52,1106.92C2997.62,1101.11 3000.83,1095.1 3001.28,1088.94C3001.6,1084.55 3000.28,1080.33 2997.5,1076.7L2996.21,1077.17C2998.96,1080.64 3000.28,1084.7 2999.97,1088.91C2999.54,1094.81 2996.4,1100.7 2993.36,1106.4C2990.58,1111.62 2987.72,1117.01 2986.81,1122.52C2987.43,1121.75 2988.04,1120.96 2988.65,1120.18C2989.86,1115.7 2992.22,1111.25 2994.52,1106.92Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2844.7,1171.61C2842.26,1171.86 2839.8,1172.15 2837.35,1172.45C2838.5,1172.75 2839.66,1173.02 2840.82,1173.29C2842.14,1173.13 2843.47,1172.98 2844.78,1172.84C2861.91,1171.08 2875.35,1172.29 2884.94,1176.43C2885.8,1176.36 2886.66,1176.29 2887.52,1176.2C2877.65,1171.19 2863.54,1169.68 2844.7,1171.61Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2949.21,1132.27C2945.16,1127.79 2940.97,1123.17 2937.84,1118.07C2934.19,1112.13 2932.52,1106.01 2932.83,1100.24L2931.49,1100.72C2931.29,1106.58 2933.03,1112.76 2936.7,1118.73C2939.89,1123.92 2944.11,1128.59 2948.2,1133.1C2951,1136.2 2953.91,1139.41 2956.44,1142.75C2957.83,1144.58 2959.03,1146.39 2960.07,1148.19C2960.43,1147.93 2960.78,1147.67 2961.14,1147.4C2960.09,1145.61 2958.89,1143.81 2957.51,1141.99C2954.95,1138.62 2952.03,1135.39 2949.21,1132.27Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2904.34,1151.35C2899.48,1149.87 2893.97,1150.12 2888.64,1150.37C2885.05,1150.54 2881.33,1150.71 2877.87,1150.35C2868.85,1149.4 2860.63,1144.72 2854.73,1137.16C2853.03,1134.98 2851.58,1132.67 2850.3,1130.27L2849.05,1130.73C2850.38,1133.22 2851.88,1135.64 2853.66,1137.92C2859.77,1145.75 2868.29,1150.6 2877.66,1151.58C2881.23,1151.95 2885,1151.77 2888.64,1151.61C2894.09,1151.35 2899.24,1151.11 2903.88,1152.53C2912.2,1155.06 2916.26,1162.12 2920.07,1169.12C2920.49,1168.97 2920.91,1168.82 2921.33,1168.67C2917.39,1161.42 2913.2,1154.05 2904.34,1151.35Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2916.71,1114.34C2918.47,1116.54 2920.45,1118.65 2922.36,1120.7C2926.11,1124.7 2929.99,1128.85 2932.26,1133.7C2933.83,1137.06 2934.16,1139.98 2933.28,1142.62C2932.36,1145.39 2929.32,1148.55 2925.51,1148.98C2921.72,1149.4 2918.75,1147 2917.23,1144.62C2916.43,1143.37 2915.83,1141.98 2915.24,1140.63C2914.56,1139.07 2913.85,1137.46 2912.84,1136C2909.73,1131.55 2904.04,1129.04 2899.43,1127.35C2898.17,1126.88 2896.89,1126.44 2895.61,1126.01C2891.71,1124.67 2887.68,1123.28 2884.32,1121.23C2883.18,1120.54 2882.19,1119.78 2881.31,1118.99L2879.94,1119.49C2880.97,1120.49 2882.18,1121.43 2883.57,1122.28C2887.05,1124.4 2891.15,1125.81 2895.12,1127.17C2896.39,1127.61 2897.66,1128.05 2898.91,1128.5C2903.35,1130.13 2908.82,1132.54 2911.73,1136.72C2912.69,1138.08 2913.34,1139.57 2914.03,1141.15C2914.63,1142.54 2915.26,1143.97 2916.1,1145.29C2917.82,1147.99 2921.22,1150.69 2925.6,1150.21C2926.39,1150.12 2927.14,1149.93 2927.86,1149.68C2931.17,1148.47 2933.64,1145.61 2934.53,1142.95C2935.51,1140 2935.16,1136.8 2933.46,1133.16C2931.11,1128.14 2927.17,1123.93 2923.36,1119.85C2921.46,1117.83 2919.5,1115.73 2917.77,1113.56C2916.47,1111.94 2914.94,1109.75 2913.72,1107.19L2912.47,1107.65C2913.74,1110.33 2915.34,1112.63 2916.71,1114.34Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2989.69,1071.63L2934.92,1091.57C2933.67,1094.3 2932.98,1097.22 2932.82,1100.24L2996.2,1077.17C2995.32,1076.05 2994.3,1075 2993.12,1074.02C2992.06,1073.13 2990.91,1072.33 2989.69,1071.63Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2989.69,1071.63L2934.92,1091.57C2933.67,1094.3 2932.98,1097.22 2932.82,1100.24L2996.2,1077.17C2995.32,1076.05 2994.3,1075 2993.12,1074.02C2992.06,1073.13 2990.91,1072.33 2989.69,1071.63Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3007.36,1065.2L3001.46,1067.35L3000.13,1067.83L2991.24,1071.07C2992.21,1071.68 2993.14,1072.34 2994.02,1073.08C2995.36,1074.19 2996.52,1075.41 2997.5,1076.69L3013.57,1070.85C3014.4,1067.97 3015.14,1065.07 3015.78,1062.14L3008.75,1064.69L3007.36,1065.2Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M3007.36,1065.2L3001.46,1067.35L3000.13,1067.83L2991.24,1071.07C2992.21,1071.68 2993.14,1072.34 2994.02,1073.08C2995.36,1074.19 2996.52,1075.41 2997.5,1076.69L3013.57,1070.85C3014.4,1067.97 3015.14,1065.07 3015.78,1062.14L3008.75,1064.69L3007.36,1065.2Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2881.31,1118.99L2912.47,1107.64C2911.45,1105.48 2910.64,1103.08 2910.33,1100.52L2877.27,1112.55C2877.78,1114.85 2879.18,1117.06 2881.31,1118.99Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2881.31,1118.99L2912.47,1107.64C2911.45,1105.48 2910.64,1103.08 2910.33,1100.52L2877.27,1112.55C2877.78,1114.85 2879.18,1117.06 2881.31,1118.99Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2876.02,1113.01L2847.14,1123.52C2848.09,1125.81 2849.13,1128.08 2850.3,1130.27L2879.94,1119.49C2877.91,1117.53 2876.57,1115.32 2876.02,1113.01Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2876.02,1113.01L2847.14,1123.52C2848.09,1125.81 2849.13,1128.08 2850.3,1130.27L2879.94,1119.49C2877.91,1117.53 2876.57,1115.32 2876.02,1113.01Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2845.9,1123.97L2783.92,1146.53C2786.3,1148.36 2788.73,1150.11 2791.21,1151.78L2849.05,1130.73C2847.88,1128.53 2846.85,1126.26 2845.9,1123.97Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2845.9,1123.97L2783.92,1146.53C2786.3,1148.36 2788.73,1150.11 2791.21,1151.78L2849.05,1130.73C2847.88,1128.53 2846.85,1126.26 2845.9,1123.97Z" style="fill:rgb(247,165,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2911.6,1100.06C2911.87,1102.62 2912.68,1105.03 2913.72,1107.19L2931.49,1100.72C2931.59,1097.77 2932.17,1094.89 2933.26,1092.17L2911.6,1100.06Z" style="fill:rgb(228,133,34);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2911.6,1100.06C2911.87,1102.62 2912.68,1105.03 2913.72,1107.19L2931.49,1100.72C2931.59,1097.77 2932.17,1094.89 2933.26,1092.17L2911.6,1100.06Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2993.12,1074.02C2994.3,1075 2995.32,1076.05 2996.21,1077.17L2997.5,1076.7C2996.52,1075.41 2995.36,1074.2 2994.02,1073.08C2993.14,1072.35 2992.21,1071.68 2991.24,1071.07L2989.69,1071.63C2990.91,1072.33 2992.06,1073.13 2993.12,1074.02Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2993.12,1074.02C2994.3,1075 2995.32,1076.05 2996.21,1077.17L2997.5,1076.7C2996.52,1075.41 2995.36,1074.2 2994.02,1073.08C2993.14,1072.35 2992.21,1071.68 2991.24,1071.07L2989.69,1071.63C2990.91,1072.33 2992.06,1073.13 2993.12,1074.02Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2933.26,1092.17C2932.17,1094.89 2931.59,1097.77 2931.49,1100.72L2932.83,1100.24C2932.98,1097.22 2933.67,1094.31 2934.92,1091.57L2933.26,1092.17Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2933.26,1092.17C2932.17,1094.89 2931.59,1097.77 2931.49,1100.72L2932.83,1100.24C2932.98,1097.22 2933.67,1094.31 2934.92,1091.57L2933.26,1092.17Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2845.9,1123.97C2846.85,1126.26 2847.88,1128.52 2849.05,1130.73L2850.3,1130.27C2849.13,1128.08 2848.09,1125.81 2847.14,1123.52L2845.9,1123.97Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2845.9,1123.97C2846.85,1126.26 2847.88,1128.52 2849.05,1130.73L2850.3,1130.27C2849.13,1128.08 2848.09,1125.81 2847.14,1123.52L2845.9,1123.97Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2877.27,1112.55L2876.02,1113.01C2876.57,1115.32 2877.91,1117.53 2879.94,1119.49L2881.31,1118.99C2879.18,1117.06 2877.78,1114.85 2877.27,1112.55Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2877.27,1112.55L2876.02,1113.01C2876.57,1115.32 2877.91,1117.53 2879.94,1119.49L2881.31,1118.99C2879.18,1117.06 2877.78,1114.85 2877.27,1112.55Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2911.6,1100.06L2910.33,1100.52C2910.64,1103.08 2911.45,1105.48 2912.47,1107.64L2913.72,1107.19C2912.68,1105.03 2911.87,1102.62 2911.6,1100.06Z" style="fill:rgb(186,110,2);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2911.6,1100.06L2910.33,1100.52C2910.64,1103.08 2911.45,1105.48 2912.47,1107.64L2913.72,1107.19C2912.68,1105.03 2911.87,1102.62 2911.6,1100.06Z" style="fill:rgb(247,165,34);fill-opacity:0.71;fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <circle cx="2873.09" cy="1030.81" r="29.065" style="fill:rgb(147,83,9);"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2953.62,1038.45C2946.8,1040.93 2939.27,1037.42 2936.79,1030.61L2922.01,990.011C2919.53,983.197 2923.05,975.663 2929.86,973.183C2936.68,970.703 2944.21,974.216 2946.69,981.03L2961.47,1021.62C2963.95,1028.44 2960.43,1035.97 2953.62,1038.45Z" style="fill:rgb(147,83,9);fill-rule:nonzero;"/>
             </g>
             <g transform="matrix(1,0,0,1,-2092.78,-389.464)">
                 <path d="M2816.31,1088.43C2809.5,1090.91 2801.96,1087.39 2799.48,1080.58L2784.71,1039.99C2782.23,1033.17 2785.74,1025.64 2792.56,1023.16C2799.37,1020.68 2806.9,1024.19 2809.38,1031.01L2824.16,1071.6C2826.64,1078.41 2823.13,1085.95 2816.31,1088.43Z" style="fill:rgb(147,83,9);fill-rule:nonzero;"/>
             </g>
         </g>
         </g>
     
     

      <g #platform id="platform">
      </g>
      <g id="lines" style="stroke:#f0f0f0;stroke-opacity:0.22108845">
         <path
            style="fill:none;stroke:#f0f0f0;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:0.3;stroke-dasharray:10,10;stroke-dashoffset:0"
            d="M 0,700 H 1280" id="path1890" sodipodi:nodetypes="cc" />
         <path
            style="fill:none;stroke:#f0f0f0;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:0.3;stroke-dasharray:10,10;stroke-dashoffset:0"
            d="M 0,650 H 1280" id="path1890" sodipodi:nodetypes="cc" />
         <path
            style="fill:none;stroke:#f0f0f0;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:0.3;stroke-dasharray:10,10;stroke-dashoffset:0"
            d="M 0,600 H 1280" id="path1890" sodipodi:nodetypes="cc" />
         <path
            style="fill:none;stroke:#f0f0f0;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:0.3;stroke-dasharray:10,10;stroke-dashoffset:0"
            d="M 0,550 H 1280" id="path1890" sodipodi:nodetypes="cc" />
         <path
            style="fill:none;stroke:#f0f0f0;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:0.3;stroke-dasharray:10,10;stroke-dashoffset:0"
            d="M 0,500 H 1280" id="path1890" sodipodi:nodetypes="cc" />
         <path
            style="fill:none;stroke:#f0f0f0;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:0.3;stroke-dasharray:10,10;stroke-dashoffset:0"
            d="M 0,450 H 1280" id="path1890" sodipodi:nodetypes="cc" />
         <path
            style="fill:none;stroke:#f0f0f0;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:0.3;stroke-dasharray:10,10;stroke-dashoffset:0"
            d="M 0,400 H 1280" id="path1890" sodipodi:nodetypes="cc" />
         <path
            style="fill:none;stroke:#f0f0f0;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:0.3;stroke-dasharray:10,10;stroke-dashoffset:0"
            d="M 0,350 H 1280" id="path1890" sodipodi:nodetypes="cc" />
         <path
            style="fill:none;stroke:#f0f0f0;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:0.3;stroke-dasharray:10,10;stroke-dashoffset:0"
            d="M 0,300 H 1280" id="path1890" sodipodi:nodetypes="cc" />
         <path
            style="fill:none;stroke:#f0f0f0;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:0.3;stroke-dasharray:10,10;stroke-dashoffset:0"
            d="M 0,250 H 1280" id="path1890" sodipodi:nodetypes="cc" />
         <path
            style="fill:none;stroke:#f0f0f0;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:0.3;stroke-dasharray:10,10;stroke-dashoffset:0"
            d="M 0,200 H 1280" id="path1890" sodipodi:nodetypes="cc" />
         <path
            style="fill:none;stroke:#f0f0f0;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:0.3;stroke-dasharray:10,10;stroke-dashoffset:0"
            d="M 0,150 H 1280" id="path1890" sodipodi:nodetypes="cc" />
         <path
            style="fill:none;stroke:#f0f0f0;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:0.3;stroke-dasharray:10,10;stroke-dashoffset:0"
            d="M 0,100 H 1280" id="path1890" sodipodi:nodetypes="cc" />
         <path
            style="fill:none;stroke:#f0f0f0;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:0.3;stroke-dasharray:10,10;stroke-dashoffset:0"
            d="M 0,50 H 1280" id="path1890" sodipodi:nodetypes="cc" />
      </g>
      <rect #cover
         style="visibility:hidden;fill:#909093;fill-opacity:0;stroke:none;stroke-width:17.3578;stroke-linecap:round;stroke-linejoin:round;stroke-opacity:0.385502;paint-order:stroke fill markers;stop-color:#000000"
         id="cover" width="1280" height="720" x="0" y="-0.52916652" />
      <g #controls id="controls" class="highz"></g>
   </svg>
</div>