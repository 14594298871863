import { Injectable } from '@angular/core';

/**
 * Why does this even exist? We can just export constants it doesn't need to be a service....
 */

@Injectable({
  providedIn: 'root'
})

export class FabricConstantsService {

	constructor() { }

	objectDefaults: object = {
		rotatingPointOffset: 20,
		padding: 0,
		borderColor: 'EEF6FC',
		cornerColor: 'rgba(64, 159, 221, 1)',
		cornerSize: 10,
		transparentCorners: false,
		hasRotatingPoint: true,
		centerTransform: true,
	};
	
	presetSizes = [
		{
			name: 'Portrait (8.5 x 11)',
			height: 1947,
			width: 1510
		},
		{
			name: 'Landscape (11 x 8.5)',
			width: 1947,
			height: 1510
		},
		{
			name: 'Business Card (3.5 x 2)',
			height: 368,
			width: 630
		},
		{
			name: 'Postcard (6 x 4)',
			height: 718,
			width: 1068
		},
		{
			name: 'Content/Builder Product Thumbnail',
			height: 400,
			width: 760
		},
		{
			name: 'Badge',
			height: 400,
			width: 400
		},
		{
			name: 'Facebook Profile Picture',
			height: 300,
			width: 300
		},
		{
			name: 'Facebook Cover Picture',
			height: 315,
			width: 851
		},
		{
			name: 'Facebook Photo Post (Landscape)',
			height: 504,
			width: 403
		},
		{
			name: 'Facebook Photo Post (Horizontal)',
			height: 1008,
			width: 806
		},
		{
			name: 'Facebook Full-Width Photo Post',
			height: 504,
			width: 843
		}
	];

	fonts = [
		{ name: 'Arial' },
		{ name: 'Lora' },
		{ name: 'Croissant One' },
		{ name: 'Architects Daughter' },
		{ name: 'Emblema One' },
		{ name: 'Graduate' },
		{ name: 'Hammersmith One' },
		{ name: 'Oswald' },
		{ name: 'Oxygen' },
		{ name: 'Krona One' },
		{ name: 'Indie Flower' },
		{ name: 'Courgette' },
		{ name: 'Gruppo' },
		{ name: 'Ranchers' }
	];

	shapeCategories = [
		{
			name: 'Popular Shapes',
			shapes: [
				'arrow6',
				'bubble4',
				'circle1',
				'rectangle1',
				'star1',
				'triangle1'
			]
		},
		{
			name: 'Simple Shapes',
			shapes: [
				'circle1',
				'heart1',
				'rectangle1',
				'triangle1',
				'star1',
				'star2',
				'star3',
				'square1'
			]
		},
		{
			name: 'Arrows & Pointers',
			shapes: [
				'arrow1',
				'arrow9',
				'arrow3',
				'arrow6',
			]
		},
		{
			name: 'Bubbles & Balloons',
			shapes: [
				'bubble5',
				'bubble4'
			]
		},
		{
			name: 'Check Marks',
			shapes: [

			]
		},
		{
			name: 'Badges',
			shapes: [
				'badge1',
				'badge2',
				'badge4',
				'badge5',
				'badge6'
			]
		}
	];

	JSONExportProperties = [
		'height',
		'width',
		'background',
		'objects',

		'originalHeight',
		'originalWidth',
		'originalScaleX',
		'originalScaleY',
		'originalLeft',
		'originalTop',

		'lineHeight',
		'lockMovementX',
		'lockMovementY',
		'lockScalingX',
		'lockScalingY',
		'lockUniScaling',
		'lockRotation',
		'lockObject',
		'id',
		'isTinted',
		'filters'
	];

	shapeDefaults = {
		fill: '#0088cc',
		...this.objectDefaults
	};

	textDefaults = {
	originX: 'left',
	scaleX: 1,
	scaleY: 1,
	fontFamily: 'Arial',
	fontSize: 40,
	fill: '#454545',
	textAlign: 'left',
	...this.objectDefaults
};



}
