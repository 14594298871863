import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild, Inject } from '@angular/core';
import gsap from 'gsap';
import SvgText from './Text/SvgText';
import SymbolGroup from './Text/SymbolGroup';
import Drag from '../../../KhDecorators/Decorators/Drag/Drag';
import DragAttached from '../../../KhDecorators/Decorators/Drag/DragAttached';
import SpringyDrag from '../../../KhDecorators/Decorators/Drag/SpringyDrag';
import WeakLine from '../../../KhDecorators/Decorators/Lines/WeakLine';
import MoveAttached from '../../../KhDecorators/Decorators/Movement/MoveAttached';
import Strechable from '../../../KhDecorators/Decorators/Strechable';
import AssetManager from '../../../KhDecorators/Pattern/AssetManager';
import Sprite from '../../../KhDecorators/Pattern/Sprite';
import UseSprite from '../../../KhDecorators/Pattern/UseSprite';
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from '../../../../services/IQuestionEvents';

@Component({
  selector: 'kh-puzzle-balloons',
  styleUrls: ['./balloons.component.css'],
  templateUrl: './balloons.component.html'
})

export class BalloonsComponent implements OnInit, AfterViewInit {
  @ViewChild('svgref') private svgRef!: ElementRef;
  @ViewChild('whiteline') private whiteLine!: ElementRef<SVGLineElement>;
  @ViewChild('btnContainer') private buttonContainer!: ElementRef<HTMLDivElement>;

  private basket!: UseSprite;
  private spring!: UseSprite;
  private ruler!: UseSprite;
  private incrementDistance = 65;
  public isRecording = false;
  private canAdd = true;
  private canZoom = true;
  private zoomValue = 0;
  private recordText!: SvgText;
  private tempText!: SvgText;
  private currentValue = 0;
  private outputOrder = ['start'];
  private outputValues = {balloonsAdded: 0, balloonsRemoved: 0, sandBagsAdded: 0, sandBagsRemoved: 0, start: 0};
  public toggleText = 'Symbol Equation';
  public containerHeight = 0;
  public padding = 20;

  public constructor(
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService,
    private readonly renderer: Renderer2
  ) { }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    this.resizeWorks();
  }

  public resizeWorks(): void {
    const newHeight = this.svgRef.nativeElement.getBoundingClientRect().height;
    if (newHeight <= this.containerHeight) {
      const scaleFactor = (newHeight - this.padding) / this.containerHeight;
      this.renderer.setStyle(this.buttonContainer.nativeElement, 'transform', `scale(${scaleFactor})`)
    }
  }

  public ngOnInit(): void {
    this.questionEventsService.hideInputOnlyForManip.next(true);
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.initBasket();
      const basketBottom = Sprite.getBottomCenter(this.basket)[1];
      const basketRight = Sprite.getRight(this.basket);
      this.workWithAssetManager(basketBottom, basketRight)

      this.initRuler(basketRight);
      this.initSpring(basketBottom);
      this.setToBasket();

      this.zoomIn();
      this.initText();
      this.setToText(basketBottom);
      this.moveEquationLine();
      this.containerHeight = this.buttonContainer.nativeElement.getBoundingClientRect().height;
      this.resizeWorks();
    }, 650);
  }

  public initBasket(): void {
    this.basket = AssetManager.createSpriteIn(this.svgRef.nativeElement, 'basket', 600, 270);
    this.basket = new SpringyDrag(this.basket);
    this.basket = new DragAttached(this.basket);
    this.basket = new MoveAttached(this.basket);
    this.basket = new Drag(this.basket, 'y', null, null);
  }

  public workWithAssetManager(basketBottom: number, basketRight: number): void {
    AssetManager.createSpriteIn(this.svgRef.nativeElement, 'hillSmall', this.basket.getX() - 2500, basketBottom - 160);
    AssetManager.createSpriteIn(this.svgRef.nativeElement, 'hillLarge', basketRight + 700, basketBottom - 360);
    AssetManager.createSpriteIn(this.svgRef.nativeElement, 'hillLarge', this.basket.getX() - 2000, basketBottom - 360);
    AssetManager.createSpriteIn(this.svgRef.nativeElement, 'hillSmall', basketRight + 2700, basketBottom - 160);
    AssetManager.createSpritesInRange(this.svgRef.nativeElement, 'cloud', 15, -1520, 4000, 140, -1200, 1.5);
    AssetManager.createSpritesInRange(this.svgRef.nativeElement, 'tree', 2, this.basket.getX() - 1600, 1200, basketBottom - 255, 0, null);
    AssetManager.createSpritesInRange(this.svgRef.nativeElement, 'tree', 3, basketRight + 200, 1700, basketBottom - 255, 0, null);
    AssetManager.createSpritesInRange(this.svgRef.nativeElement, 'bush', 4, this.basket.getX() - 2000, 2000, basketBottom - 35, 0, null);
    AssetManager.createSpritesInRange(this.svgRef.nativeElement, 'bush', 3, basketRight + 200, 700, basketBottom - 35, 0, null);
    AssetManager.createSpritesInRange(
      this.svgRef.nativeElement,
      'dirtCircle',
      8,
      this.basket.getX() + 10,
      this.basket.element.getBBox().width - 20,
      basketBottom,
      350,
      1.2
    );
  }

  public initRuler(basketRight: number): void {
    const newSprite = AssetManager.createSpriteIn(this.svgRef.nativeElement, 'ruler', basketRight, 0);
    const springyDrag = new SpringyDrag(newSprite);

    this.ruler = new Drag(springyDrag, 'y', null, null);
  }

  public initSpring(basketBottom: number): void {
    const spring = AssetManager.createSpriteIn(this.svgRef.nativeElement, 'spring', this.basket.getX() + 10, basketBottom);
    this.spring = new Strechable(spring, null, 720);
  }

  public setToBasket(): void {
    this.basket.addSprite(this.spring);
    this.basket.setParent(this.svgRef.nativeElement);
  }

  public initText(): void {
    this.recordText = new SymbolGroup(this.svgRef.nativeElement, 'redBalloon', 'sandBag', 10, 20, '25px');
    this.tempText = new SvgText(this.svgRef.nativeElement, 10, 10, '25px');
  }

  public setToText(basketBottom: number): void {
    this.tempText.setVisible(false);
    this.recordText.setVisible(false);
    this.recordText.move(Sprite.getRight(this.ruler), basketBottom);
    this.tempText.move(Sprite.getRight(this.ruler), basketBottom);
  }

  public addBalloon(): void{
    if (this.canAdd) {
      this.canAdd = false;
      this.currentValue += 1;
      this.outputValues.balloonsAdded += 1;
      this.addToOrder(this.outputOrder, 'balloonsAdded');
      this.recordText?.applyOrderedValues(this.outputValues, this.outputOrder);

      this.basket?.getDraggable()[0].disable();
      let balloon = AssetManager.createSpriteFromId('redBalloon');

      balloon.setParent(this.svgRef.nativeElement);
      balloon.setX(this.basket.getX() + (Math.random() * (this.basket.element.getBBox().width - 70)), 0);

      balloon.callAfterMove('y', this.basket.getY() - 100 - (Math.random() * 50), 1, () => {
        balloon = new WeakLine(balloon, this.basket, 'bottom', 'top', 'white');
        balloon = new Drag(balloon, 'x,y', null, () => this.checkBalloon(balloon));
        this.basket.addSprite(balloon);
        this.basket?.elasticMove('y', -this.incrementDistance, 1, () => {
          this.moveEquationLine();
        })

        setTimeout(() => {
          this.canAdd = true;
          this.basket?.getDraggable()[0].enable();
        }, 750);
      })
    }
  }

  public addSandBag(): void {
    if (this.canAdd) {
      this.canAdd = false;

      this.currentValue -= 1;
      this.outputValues.sandBagsAdded += 1;
      this.addToOrder(this.outputOrder, 'sandBagsAdded');
      this.recordText?.applyOrderedValues(this.outputValues, this.outputOrder);

      this.basket?.getDraggable()[0].disable();
      let sandBag = AssetManager.createSpriteFromId('sandBag');
      sandBag.setParent(this.svgRef.nativeElement);
      sandBag.setX(this.basket.getX() + (Math.random() * (this.basket.element.getBBox().width - 70)), 0);

      sandBag.callAfterMove('y', this.basket.getY() + (Math.random() * this.basket.element.getBBox().height + 20), 1, () => {
        sandBag = new WeakLine(sandBag, this.basket, 'top', 'top', 'white');
        sandBag = new Drag(sandBag, 'x,y', null, () => this.checkSandBag(sandBag));
        this.basket.addSprite(sandBag);
        this.basket?.elasticMove('y', this.incrementDistance, 1, () => {
          this.moveEquationLine();
        })

        setTimeout(() => {
          this.canAdd = true;
          this.basket?.getDraggable()[0].enable();
        }, 1550);
      })
    }
  }

  public cargoCheck(
    elemCenter: number[],
    basketCenter: number[],
    currentValue: number,
    elemRemoved: string,
    elem: UseSprite,
    incrementDistance: number,
    y: number
  ): void {
    const distanceX = Math.abs(basketCenter[0] - elemCenter[0]);
    const distanceY = (basketCenter[1] - elemCenter[1]);

    if (distanceX > this.basket.element.getBBox().width / 2 || distanceY > 0) {
      this.currentValue += currentValue;
      this.outputValues.sandBagsRemoved += 1;
      this.addToOrder(this.outputOrder, elemRemoved);
      this.basket.removeSprite(elem);

      elem.callAfterMove('y', y, 3, () => {
        elem.destroy();
      })

      this.basket?.elasticMove('y', incrementDistance, 1, () => {
        this.moveEquationLine();
      })
    }
    this.recordText?.applyOrderedValues(this.outputValues, this.outputOrder);
  }

  public checkBalloon(balloon: UseSprite): void {
    const balloonCenter = Sprite.getCenter(balloon);
    const basketCenter = Sprite.getTopCenter(this.basket);
    const viewBox = this.svgRef.nativeElement.viewBox.baseVal;
    const y = viewBox.y - viewBox.height;
    this.cargoCheck(balloonCenter, basketCenter, -1, 'balloonsRemoved', balloon, this.incrementDistance, y);
  }

  public checkSandBag(sandBag: UseSprite): void {
    const bagCenter = Sprite.getCenter(sandBag);
    const basketCenter = Sprite.getTopCenter(this.basket);
    const viewBox = this.svgRef.nativeElement.viewBox.baseVal;
    const y = viewBox.y - viewBox.height;
    this.cargoCheck(bagCenter, basketCenter, 1, 'balloonsRemoved', sandBag, -this.incrementDistance, -y);
  }

  public setOutputValues(): void {
    this.outputValues.balloonsAdded = 0;
    this.outputValues.balloonsRemoved = 0;
    this.outputValues.sandBagsAdded = 0;
    this.outputValues.sandBagsRemoved = 0;
    this.outputOrder = ['start'];
    this.outputValues.start = this.currentValue;
  }

  public toggleRecording(): void {
    this.isRecording = !this.isRecording;
    this.recordText?.toggle();
    if (this.isRecording) {
      this.whiteLine.nativeElement.classList.remove('hide');
    } else {
      this.whiteLine.nativeElement.classList.add('hide');
    }

    this.setOutputValues();

    this.recordText?.applyOrderedValues(this.outputValues, this.outputOrder);
  }

  public toggleSelect(): void {
    const temp = this.recordText;
    this.recordText = this.tempText;
    this.tempText = temp;
    this.recordText?.setVisible(temp.visible);
    this.tempText?.setVisible(false);
  }

  public toggleEquation(): void {
    if (this.toggleText === 'Math Equation') {
      this.toggleText = 'Symbol Equation';
      this.toggleSelect();
    } else {
      this.toggleText = 'Math Equation';
      this.toggleSelect();
    }

    this.recordText?.applyOrderedValues(this.outputValues, this.outputOrder);
    this.moveEquationLine();
  }

  public addToOrder(array: string[], s: string): void {
    if (array.lastIndexOf(s) === -1) {
      array.push(s);
    }
  }

  public moveEquationLine(): void {
    const bottomCenter = Sprite.getBottomCenter(this.basket);
    gsap.set(this.recordText.group, {y: bottomCenter[1]});
    gsap.set(this.whiteLine.nativeElement, {attr: {y1: bottomCenter[1] - 5, y2: bottomCenter[1] - 5}});
  }

  public zoomIn(): void {
    if (this.zoomValue < 4 && this.canZoom) {
      this.canZoom = false;
      AssetManager.zoomIn(this.svgRef.nativeElement);
      this.zoomValue += 1;

      setTimeout(() => {
        this.canZoom = true;
      }, 750);
    }
  }

  public zoomOut(): void {
    if (this.zoomValue > 0 && this.canZoom) {
      this.canZoom = false;
      AssetManager.zoomOut(this.svgRef.nativeElement);
      this.zoomValue -= 1;

      setTimeout(() => {
        this.canZoom = true;
      }, 750);
    }
  }
}
