<div class="position-relative">
  <svg #svgref viewBox="-1920 -1080 5120 2880" xmlns="http://www.w3.org/2000/svg">
    <defs #spritedef>
      <image id="basket" href="https://staticcontent.knowledgehook.com/learning-worlds/balloons/basket.svg"
        height="100" />
      <image id="redBalloon" href="https://staticcontent.knowledgehook.com/learning-worlds/balloons/red-balloon.svg"
        width="50" height="50" />
      <image id="sandBag" href="https://staticcontent.knowledgehook.com/learning-worlds/balloons/sandbag.svg"
        width="50" height="50" />
      <image id="ruler" href="https://staticcontent.knowledgehook.com/learning-worlds/balloons/ruler.svg"
        height="720" />
      <image id="cloud" href="https://staticcontent.knowledgehook.com/learning-worlds/balloons/cloud.svg" />
      <image id="bush" href="https://staticcontent.knowledgehook.com/learning-worlds/balloons/grass.svg" />
      <image id="spring" href="https://staticcontent.knowledgehook.com/learning-worlds/balloons/spring.svg"
        height="90" />
      <image id="tree" href="https://staticcontent.knowledgehook.com/learning-worlds/balloons/tree.png" height="250" />
      <image id="hillSmall" href="https://staticcontent.knowledgehook.com/learning-worlds/balloons/hill-small.svg"
        height="150" />
      <image id="hillLarge" href="https://staticcontent.knowledgehook.com/learning-worlds/balloons/hill.svg"
        height="350" />

      <circle id="dirtCircle" r="5" />

    </defs>
    <rect id="background" x="-1920" y="-1080" width="5120" height="2880" />

    <rect id="dirtLayer1" x="-1920" y="360" width="5120" height="180" />
    <rect id="dirtLayer2" x="-1920" y="540" width="5120" height="1280" />

    <rect id="holeLayer1" x="600" y="360" width="200" height="180" />
    <rect id="holeLayer2" x="600" y="540" width="200" height="179" />
    <rect class="grass" x="-1920" y="360" id="grassLeft" width="2520" height="10" />
    <rect class="grass" x="800" y="360" id="grassRight" width="2520" height="10" />

    <line class="hide" #whiteline x1="-1920" x2="5120" y1="0" y2="0" stroke="white" stroke-width="5"
      stroke-dasharray="10" />
  </svg>

  <div #btnContainer class="buttonContainer">
    <button class="Scale pictureButton" (click)="addBalloon()"><img
        src="https://staticcontent.knowledgehook.com/learning-worlds/balloons/red-balloon.svg" /></button>
    <button class="Scale pictureButton" (click)="addSandBag()"><img
        src="https://staticcontent.knowledgehook.com/learning-worlds/balloons/sangbag.svg" /></button>

    <button class="Scale pictureButton" (click)="toggleRecording()">
      <img *ngIf="!isRecording" src="https://staticcontent.knowledgehook.com/learning-worlds/balloons/record.svg" />
      <img *ngIf="isRecording" src="https://staticcontent.knowledgehook.com/learning-worlds/balloons/pause.svg" />
    </button>

    <button class="toggleButton" (click)="toggleEquation()">{{toggleText}}</button>

    <button class="Scale pictureButton" (click)="zoomIn()"><img
        src="https://staticcontent.knowledgehook.com/learning-worlds/balloons/zoomin.svg" /></button>
    <button class="Scale pictureButton" (click)="zoomOut()"><img
        src="https://staticcontent.knowledgehook.com/learning-worlds/balloons/zoomout.svg" /></button>
  </div>
</div>