import { PuzzleAttempt } from "../puzzle/puzzle.model";

export interface SpotlightPuzzleAttempt extends PuzzleAttempt {
  target: number;
}

export interface StrokeData {
  major: number;
  minor: number;
  mini: number;
}

// SVG Groups don't contain a width property but we want to store the width of the bounding box for each label.
export interface Label extends SVGGElement {
  width: any;
}

export interface JumpData {
  viewBox: string;
  scale: number;
  width: number;
  count: number;
  stepSize: number;
  stroke: StrokeData;
}

export interface SVGJump extends SVGPathElement {
  data: JumpData;
}

// Tick stores its original location which is used to calculate it's position during animations
export interface Tick extends SVGPathElement {
  xAnchor: number;
}

export interface View {
  jumpsMajor: SVGJump;
  jumpsMinor: SVGJump;
  jumpsMini: SVGJump;
  labels: Array<SVGElement>;
  ticks: Array<Tick>;
  numberLine: SVGElement;
  descriptor: SVGGraphicsElement;
}

export interface Jumps {
  major: JumpData;
  minor: JumpData;
  mini: JumpData;
}


export interface Marking {
  visible: boolean;
  location: number;
  label: number;
  reduce: boolean;
}

export interface Answers {
  answer: string
  state: string
}

export type SpotlightInputType = 'keyboard' | 'spotlight';
export interface SpotlightSetup {
  height: number;
  width: number;
  decimals: number;
  padding: number;
  answer: string;
  state: string;
  reduceTicks: boolean;
  reduceLabels: boolean;
  reduceHints: boolean;
  reduceTarget: boolean;
  max: number;
  min: number;
  spotlightWidth: number;
  denominator: number;
  tickStepSize: number;
  stepSize: number;
  labelTicks: boolean;
  timeStep: number;
  hints: Array<number>;
  majorJumps: number;
  minorJumps: number;
  miniJumps: number;
  zoom: boolean;
  target: number;
  numberlineY: number;
  reduceEndpoints: boolean;
  inputType: SpotlightInputType;
  maxInputDigits: number;
  customDescriptor: string | undefined;
  labelJumps: boolean;
  jumpStart: number;
}
