import { gsap } from "gsap/all"
const svgns = "http://www.w3.org/2000/svg";

function isThisPointOnALineBetween(point: Point, between: Point[]): boolean {
  return distanceBetween(between[0], point) + distanceBetween(point, between[1]) == distanceBetween(between[0], between[1]);
}

function distanceBetween(a: Point, b: Point): number {
  const xDist = Math.pow((b.x - a.x), 2);
  const yDist = Math.pow((b.y - a.y), 2);
  return (Math.sqrt(xDist + yDist));
}
export interface DotConnectSetup {
  dotSize: number;
  width: number;
  height: number;
  dotColor: string;
  lineColor: string;
  background: string;
  nodes: any;
  reportMode: boolean | null;
  state?: string | null;
  answer?: string | null;
}
interface Point {
  x: number;
  y: number;
}
export interface Node extends SVGCircleElement {
  [x: string]: any;
  active: boolean;
  location: Point;
  identifier: string;
}

export interface Nodes {
  [key: string]: Node;
}

interface Line extends SVGLineElement {
  nodesOnThisLine: Node[];
  nodeOne: Node;
  nodeTwo: Node;
  identifier: string;
}

interface Lines {
  [key: string]: Line;
}
export class DotConnectClass {
  self = this;

  nodes: Nodes;
  lines: Lines;
  setup: DotConnectSetup;
  nodeInFocus: Node | null;
  cutting: boolean;
  element: SVGSVGElement;
  idInFocus: string | null;
  onAnswerUpdate?: (ans: string) => void;

  constructor(element: SVGSVGElement, setup: DotConnectSetup) {

    this.nodes = {} as Nodes;
    this.lines = {} as Lines;
    this.setup = setup;
    this.nodeInFocus = null;
    this.idInFocus = null;
    this.cutting = false;
    this.element = element;

    this.init();
  }

  svgPointerDown(e: PointerEvent): void {
    e.stopPropagation();
    if (this.cutting) {
      this.toggleCutting(false);
    } else {
      this.toggleCutting(true);
    }
  }

  bringToFront(obj: Node | Line): void {
    let parent = obj.parentElement;
    parent?.removeChild(obj);
    parent?.appendChild(obj);
  }

  createLineBetween(n1: Node, n2: Node): Line {

    let x1 = gsap.getProperty(n1, "x");
    let y1 = gsap.getProperty(n1, "y");
    let x2 = gsap.getProperty(n2, "x");
    let y2 = gsap.getProperty(n2, "y");

    const id1 = n1.identifier;
    const id2 = n2.identifier;

    let line = document.createElementNS(svgns, "line") as Line;
    line.identifier = id1 + id2;
    gsap.set(line, {
      stroke: this.setup.lineColor,
      strokeWidth: this.setup.dotSize,
      strokeLinecap: 'round',
      attr: { x1: x1, y1: y1, x2: x2, y2: y2 },
    });

    line.nodesOnThisLine = [];

    this.element.appendChild(line);

    line.addEventListener('pointerdown', e => this.linePointerDown(e));

    line.nodeOne = n1;
    line.nodeTwo = n2;

    let between: Point[] = [{ x: Number(x1), y: Number(y1) }, { x: Number(x2), y: Number(y2) }];

    Object.values(this.nodes).forEach(node => {
      let x = gsap.getProperty(node, "x");
      let y = gsap.getProperty(node, "y");
      let point: Point = { x: Number(x), y: Number(y) };
      if (isThisPointOnALineBetween(point, between)) {
        line.nodesOnThisLine.push(node);
      }
    })

    return line;
  }

  updateAnswer(): void {
    const lineValues = Object.values(this.lines);
    const answerString = lineValues.map(l => {
      let pair = [];
      pair.push(l.nodeOne.identifier, l.nodeTwo.identifier);
      return pair.sort().join(':');
    }).join(',');
    this.onAnswerUpdate && this.onAnswerUpdate(answerString);
  }

  linePointerDown(e: PointerEvent): void {
    const line = e.target as Line;
    e.stopPropagation();
    if (this.cutting) {
      this.deleteLine(line);
    }
    if (Object.keys(this.lines).length == 0) {
      this.toggleCutting(false);
      this.nodeInFocus = null;
    }
    this.updateAnswer();
  }

  toggleCutting(state: boolean): void {

    this.cutting = state;

    Object.keys(this.lines).forEach(l => {
      let line = this.lines[l];
      this.bringToFront(line);
      !this.cutting && gsap.to(line, { strokeWidth: this.setup.dotSize });
      this.cutting && gsap.to(line, { strokeWidth: 4 * this.setup.dotSize });
    })


    if (!this.cutting) {
      Object.keys(this.nodes).forEach(n => {
        const node = this.nodes[n];
        this.bringToFront(node);
      })
    }

    this.nodeInFocus && gsap.to(this.nodeInFocus, { duration: 0.25, scale: 1 });
    this.nodeInFocus = null;
  }


  deleteLine(line: Line): void {
    const nodeA = line.nodeOne;
    const nodeB = line.nodeTwo;

    gsap.to(nodeA, { duration: 0.5, scale: 1 });
    gsap.to(nodeB, { duration: 0.5, scale: 1 });

    this.element.removeChild(line);

    delete this.lines[line.identifier];
  }

  nodePointerDown(e: PointerEvent): void {

    const target = e.target as Node;

    e.stopPropagation();

    if (this.nodeInFocus == target) {

      gsap.to(target, { duration: 0.5, scale: 1, ease: "elastic" });

      this.nodeInFocus = null;

    } else if (this.nodeInFocus) {

      gsap.to(this.nodeInFocus, { duration: 0.5, scale: 1, ease: "elastic" });
      gsap.fromTo(target, { duration: 0.5, scale: 2 }, { duration: 0.5, scale: 1 });

      const potentialNewID1 = this.nodeInFocus.identifier + target.identifier;
      const potentialNewID2 = target.identifier + this.nodeInFocus.identifier;

      if (!this.lines[potentialNewID1] && !this.lines[potentialNewID2]) {

        const l = this.createLineBetween(this.nodeInFocus, target) as Line;

        this.lines[potentialNewID1] = l;
        this.updateAnswer();
      }

      this.bringToFront(target);
      this.bringToFront(this.nodeInFocus);

      this.nodeInFocus = null;
    } else {
      gsap.to(target, { duration: 0.5, scale: 2, ease: "elastic" });
      this.nodeInFocus = target;
    }
  }

  loadLines(answerPairs: Array<Array<string>>): void {

    answerPairs.forEach(p => {
      let firstNode = this.nodes[p[0]];
      let secondNode = this.nodes[p[1]];

      let line = this.createLineBetween(firstNode, secondNode);

      const ID = firstNode.identifier + secondNode.identifier;

      this.lines[ID] = line;
    })

  }

  init(): void {

    const vBox = "0 0 " + this.setup.width + " " + this.setup.height;

    gsap.set(this.element, { attr: { viewBox: vBox } });

    this.element.style.backgroundImage = `url(${this.setup.background})`;

    Object.keys(this.setup.nodes).forEach(k => {

      const node = this.setup.nodes[k];

      let circle = document.createElementNS(svgns, "circle") as Node;

      this.nodes[k] = circle;
      circle.identifier = k;

      const to = gsap.set(circle, {
        attr: {
          r: this.setup.dotSize,
        },
        x: node.x,
        y: node.y,
        fill: this.setup.dotColor,
        strokeOpacity: 0,
        strokeWidth: 3 * this.setup.dotSize,
        stroke: "#ffffff",
      });

      this.element.appendChild(circle);

      circle.addEventListener("pointerdown", e => this.nodePointerDown(e));
    })

    if (this.setup.answer) {

      let splitValue = this.setup.answer.split(",");

      const split = splitValue.map(s => s.split(":"));

      this.loadLines(split);
    }

    this.element.addEventListener("pointerdown", e => this.svgPointerDown(e));
  }
}