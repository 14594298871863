<div #container>
<svg #arena id="testPuzzle" class="factoryPuzzle" viewBox="0 0 1280 720" xmlns="http://www.w3.org/2000/svg">
  <defs #assets> 
    <image
    id="gear"
    height="271.6"
    width="271.6"
    href="https://staticcontent.knowledgehook.com/learning-worlds/factory/gear.png"
  ></image>
  <image
  id="header"
  width="512"
  href="https://staticcontent.knowledgehook.com/learning-worlds/factory/empty-header.png"
></image>
  <image
  id="jellyblock"
  width="60"
  href="https://staticcontent.knowledgehook.com/learning-worlds/factory/jelly-block.png"
></image>
<image
id="gummybear"
width="60"
href="https://staticcontent.knowledgehook.com/learning-worlds/factory/bummy-bear.png"
></image>
<image
id="stamp"
width="100"
href="https://staticcontent.knowledgehook.com/learning-worlds/factory/stamp.png"
></image>
<image
id="wall"
width="425"
href="images/f-wall.png"
></image>
<image
id="iconfreq"
width="65"
href="https://staticcontent.knowledgehook.com/learning-worlds/factory/frequency-icon.png"
></image>
<image
id="iconspacing"
width="110"
href="https://staticcontent.knowledgehook.com/learning-worlds/factory/space-icon.png"
></image>
<image
id="iconend"
width="40"
href="images/f-end-icon.png"
></image>
<image
id="iconturns"
width="40"
href="https://staticcontent.knowledgehook.com/learning-worlds/factory/turn-icon.png"
></image>
  </defs>
</svg>
</div>