import { SvgRenderer } from "../svg-components/SvgRenderer";

export class PuzzleFocusElement {
  public focusedElement: HTMLElement | SVGElement;
  public blocker: HTMLElement | undefined;
  public blockerSvg: SVGElement | undefined;
  public containerTarget: HTMLElement;
  private originalParent: HTMLElement | SVGElement | null;
  private originalZIndex: string;
  private listenerRemoveReference: any;

  public constructor(targetElement: HTMLElement | SVGElement, containerElement: HTMLElement, width = 1280, height = 720) {
    this.focusedElement = targetElement;
    this.containerTarget = containerElement;
    this.originalParent = targetElement.parentElement;
    this.originalZIndex = targetElement.style.zIndex;

    this.buildBlocker(width, height);
  }

  private buildBlocker(width = 1280, height = 720): void {
    this.blocker = document.createElement('div');
    this.blockerSvg = document.createElementNS(SvgRenderer.svgns, 'svg');
    this.blockerSvg.setAttribute('width', width.toString());
    this.blockerSvg.setAttribute('height', height.toString());
    this.blockerSvg.style.position = 'relative';
    this.blockerSvg.style.zIndex = '1001';
    this.blockerSvg.style.pointerEvents = 'auto';

    this.blocker.style.backgroundColor = 'black';
    this.blocker.style.opacity = '0.4';
    this.blocker.style.zIndex = '1000'
    this.blocker.style.position = 'absolute';
    this.blocker.style.left = '0px';
    this.blocker.style.top = '0px';
    this.blocker.style.width = '100%';
    this.blocker.style.height = '100%';
    this.blocker.style.pointerEvents = 'auto';

    this.containerTarget.appendChild(this.blocker);

    this.focusedElement.style.zIndex = '1001';

    if (this.focusedElement instanceof SVGElement) {
      this.blockerSvg.appendChild(this.focusedElement);
      this.containerTarget.appendChild(this.blockerSvg);
    } else {
      this.containerTarget.appendChild(this.focusedElement);
    }

    this.listenerRemoveReference = this.removeBlocker.bind(this);
    this.blocker.addEventListener('click', this.listenerRemoveReference);
    this.blockerSvg.addEventListener('click', this.listenerRemoveReference);
    this.focusedElement.addEventListener('click', this.listenerRemoveReference);
  }

  public removeBlocker(): void {
    this.blocker?.remove();
    this.blockerSvg?.remove();

    this.blocker?.removeEventListener('click', this.listenerRemoveReference);
    this.blockerSvg?.removeEventListener('click', this.listenerRemoveReference);
    this.focusedElement?.removeEventListener('click', this.listenerRemoveReference);

    if (this.originalParent) {
      this.focusedElement.style.zIndex = this.originalZIndex;
      this.originalParent.appendChild(this.focusedElement);

      if (this.focusedElement.tagName.toLowerCase() === 'input') {

        this.focusedElement.focus();
      } else {
        const children = this.focusedElement.children;
        for (let i = 0; i < children.length; i++) {

          if(children[i].tagName.toLowerCase() === 'input') {
            (children[i] as HTMLInputElement).focus();
            break;
          }
        }
      }
    }

  }
}
