import { applyDecimalOffset, bringToFront, getBoundingBoxWithTransform } from './spotlight-api'
import { gsap } from 'gsap/all';
import { Jumps, Label, Marking, SpotlightInputType, SpotlightPuzzleAttempt, SpotlightSetup, View } from './spotlight-models';
import { NumberLine, NumberLineMarkerData } from './NumberLine';
import { OverlayNumberInput } from '../puzzle-ui/OverlayNumberInput';
import { PuzzleState } from '../puzzle/puzzle.model';
import { PuzzleBase } from '../puzzle/puzzleBase';
import { KHPoint } from '../pixi-puzzle-framework/PuzzleEngine/KHPoint';
const svgns = 'http://www.w3.org/2000/svg';

export function PuzzleSpotlight(_elements: Array<SVGSVGElement | undefined>, _setup: SpotlightSetup) {
  let self = {} as PuzzleSpotlightClass;

  // why is there a class in this function?? Is this like a really weird factory or something?
  class PuzzleSpotlightClass extends PuzzleBase<SpotlightPuzzleAttempt, PuzzleState<SpotlightPuzzleAttempt>> {
    public onSuccess?: () => void;
    public maxInputDigits?: number;
    public initialSpotlightTarget?: number;
    public inputType: SpotlightInputType = 'spotlight';

    private numberInput: OverlayNumberInput | undefined;
    private elements: (SVGSVGElement | undefined)[];
    private view: View = {} as View;
    private spotlightScene: SVGSVGElement | undefined; // element that renders spotlight and jumps
    private numberLineScene: SVGSVGElement | undefined; // element that renders the number line
    private setup: SpotlightSetup;
    private feedbackTimeline: any; // GSAP timeline that manages the visual feedback.
    private shine: Element | undefined; // The light purple "shine" that extends to number line
    private light: Element | undefined; // the can that rotates along with the shine
    private flair: Element | undefined; // extra strokes/stars for decoration of the light
    private header: Element | undefined; // Top part of the spotlight scene where the provblem is desplayed
    private point: SVGPoint | undefined; // Helper variable to store svg pointer events
    private targetInPixels = 0;
    private tickHeight = 0; // Height of the number line ticks
    private lineWidthInUnits = 0;
    private fontSize = 30;
    private aspectRatioHW?: number;
    private range?: number;
    private lineWidthInPixels = 0;
    private yFromNumberLineToLight = 0; // Distance between the light itself and the number line.
    private feedbackRunning?: false;
    private feedbackBegun?: false;
    private currentShineTarget: number;
    private shineCenter: number; // center of the spotlight
    private shineRight = 0; // right boundary of spotlight shine
    private shineLeft = 0; // left boundary of spotlight shine
    private rotation = 0; // Angle of spotlight rotation
    private markings: Array<Marking> = []; // Array of tick/label combinations that are shown on the number line.
    private xStart = 0; // location in pixels of the first tick.
    private xEnd?: number; // location in pixels of the last tick.
    private anchorStroke?: number;
    private anchorWidth = 0;
    private strokeWidth = 0;
    private awaitingInput = true;
    private jumps!: Jumps;
    private sessionState: PuzzleState<SpotlightPuzzleAttempt>;
    private feedbackComplete?: boolean;
    private timeStep: number; // Used to speed up or slow down the animation feedback rate
    private width = 1280;
    private correct!: SVGSVGElement;
    private resetBtnURL = 'https://res.cloudinary.com/duim8wwno/image/upload/v1647289526/SpotlightRetryBtn_nwcp3o.svg';
    private goBtnURL = 'https://res.cloudinary.com/duim8wwno/image/upload/v1644246521/SpotlightGoBtn_eqeyvr.svg';
    private numberLine: NumberLine;
    private majorJumps: number | undefined;
    private labelJumps: boolean;
    private helpTweenAnimation: gsap.core.Tween;

    public constructor(elements: (SVGSVGElement | undefined)[], setup: SpotlightSetup) {
      super({ attempts: [] }, elements[0]);

      self = this;
      this.elements = elements;
      this.spotlightScene = elements[0];
      this.numberLineScene = elements[1];
      this.shine = this.spotlightScene?.getElementById('Shine');
      this.light = this.spotlightScene?.getElementById('Light');
      this.flair = this.spotlightScene?.getElementById('Flair');
      this.header = this.spotlightScene?.getElementById('Header');
      this.setup = setup;

      this.sessionState = {} as PuzzleState<SpotlightPuzzleAttempt>;
      this.sessionState.attempts = [];
      this.setup.numberlineY = 500;
      this.setup.width = 1280
      this.setup.height = 720
      this.currentShineTarget = 300;
      this.awaitingInput = true;
      this.shineCenter = this.setup.width / 2;
      this.point = this.spotlightScene?.createSVGPoint();
      this.timeStep = 1;
      this.labelJumps = this.setup.labelJumps;
      this.feedbackTimeline = gsap.timeline({
        onComplete: this.onFeedbackComplete,
        paused: true
      });
      this.view.ticks = [];
      this.view.labels = [];
      this.inputType = this.setup.inputType;
      this.initialSpotlightTarget = Number(this.setup.target);
      this.maxInputDigits = this.setup.maxInputDigits;
      this.majorJumps = this.setup.majorJumps;

      this.init();
    }

    private cursorPoint(evt: MouseEvent): DOMPoint {
      this.point!.x = evt.clientX;
      this.point!.y = evt.clientY;

      return this.point!.matrixTransform(this.spotlightScene?.getScreenCTM()?.inverse());
    }

    public backgroundPointerDown(e: MouseEvent): void {
      if (self.awaitingInput) {
        const pt = self.cursorPoint(e);

        if (self.inputType === 'spotlight') {
          self.rotateShineToPoint({ x: pt.x, y: pt.y });
        } else {
          const boundingRect = self.numberInput?.getElement().getBoundingClientRect();

          // check if point is in the number input zone. If it is don't do anything
          if (!(boundingRect &&
            e.clientX >= boundingRect.left &&
            e.clientX <= boundingRect.right &&
            e.clientY >= boundingRect.top &&
            e.clientY <= boundingRect.bottom)) {

            this.onPuzzleHelp();
          }
        }
      }
    }

    private rotateShineToNumber(num: number) {
      self.rotateShineToPoint(this.numberLine.getPointForNumber(num));
    }

    private rotateShineToPoint(point: KHPoint) {
      self.currentShineTarget = point.x;
      const dx = self.shineCenter - self.currentShineTarget;
      const theta = Math.atan(dx / self.yFromNumberLineToLight);
      self.rotation = theta;

      gsap.set(self.light!, {
        rotation: (theta * 180) / Math.PI,
        transformOrigin: '50% 0%'
      });

      self.drawShine();
    }


    private drawShine(): void {
      const dx = (this.setup.spotlightWidth * this.setup.width) / 2;

      const theta = this.rotation;
      const left = this.currentShineTarget - dx;
      const right = this.currentShineTarget + dx;

      this.shineLeft = left;
      this.shineRight = right;

      const H1 = 75;
      const H2 = 80;

      const delta = theta - Math.PI / 5.5;
      const alpha = theta + Math.PI / 5.2;
      const canRightX = this.shineCenter - H1 * Math.sin(delta);
      const canRightY = 129 + H1 * Math.cos(delta);
      const canLeftX = this.shineCenter - H2 * Math.sin(alpha);
      const canLeftY = 129 + H2 * Math.cos(alpha);

      const s = `M ${left} ${this.setup.numberlineY} 
                 L ${right} ${this.setup.numberlineY} 
                 L ${canRightX} ${canRightY} 
                 L ${canLeftX} ${canLeftY}`;

      gsap.set(this.shine!, { attr: { d: s } });
    }

    private reduceFraction(n: number, d: number): { n: number, d: number } {
      const m = Math.max(n, d);
      let gcf = 1;
      for (let i = 0; i < m; i++) {
        if (d % i === 0 && n % i === 0) {
          gcf = i;
        }
      }

      return { d: d / gcf, n: n / gcf }
    }

    private getLabel(f: number, num: number, den: number, reduce: boolean, override?: string): Label {
      let numerator = num;
      let denominator = den;

      const shouldIReduce = reduce !== null ? reduce : true;

      if (shouldIReduce) {
        numerator = this.reduceFraction(num, den).n;
        denominator = this.reduceFraction(num, den).d;
      }

      const label = document.createElementNS(svgns, 'g') as Label;

      if (numerator !== null) {
        const nStringLength = numerator.toString().length;

        let dWidth = 0;

        const nWidth = 0.6 * nStringLength * f;
        let maxTextWidth = nWidth;

        if (denominator) {
          const dl = denominator.toString().length;
          dWidth = 0.6 * dl * f;
          if (numerator % denominator === 0) {
            numerator = numerator / denominator;
            denominator = 0;
          }
        }

        maxTextWidth = Math.max(nWidth, dWidth);

        const svgN = document.createElementNS(svgns, 'text');
        const svgD = document.createElementNS(svgns, 'text');
        const line = document.createElementNS(svgns, 'line');

        let startLabelText: string = applyDecimalOffset(numerator, this.setup.decimals);

        if (override) {
          startLabelText = override;
        }

        gsap.set(svgN, {
          fill: 'white',
          fontFamily: 'Quicksand',
          fontSize: f,
          textContent: startLabelText,
          x: maxTextWidth / 2 - nWidth / 2,
          y: 0
        });

        if (denominator) {
          gsap.set(svgD, {
            fill: 'white',
            fontFamily: 'Quicksand',
            fontSize: f,
            textContent: denominator,
            x: maxTextWidth / 2 - dWidth / 2,
            y: f
          });

          gsap.set(line, {
            attr: { x1: 0, x2: maxTextWidth, y1: f / 10, y2: f / 10 },
            fontFamily: 'Quicksand',
            stroke: 'white',
            strokeLinecap: 'round',
            strokeWidth: f / 10
          });

          label.appendChild(svgD);
        }

        label.appendChild(svgN);
        label.appendChild(line);

      }

      return label;
    }

    public checkAnswer(): boolean {
      let targetCheckInPixles = this.targetInPixels;

      if (this.inputType === 'keyboard') {
        const curPoint = this.numberLine.getPointForNumber(this.numberLine.getTarget());
        targetCheckInPixles = curPoint.x;
      }

      if (targetCheckInPixles > this.shineLeft && targetCheckInPixles < this.shineRight) {
        this.onSuccess?.();

        return true;
      }

      return false;
    }

    public onFeedbackComplete(): void {
      const attemptNumber = self.sessionState.attempts.length + 1;
      const correct = self.checkAnswer();

      const thisAttempt = { attemptNumber: attemptNumber, correct: correct, target: self.currentShineTarget }

      self.feedbackComplete = true;
      // self.sessionState.attempts.push(thisAttempt);
      this.puzzleState.attempts.push(thisAttempt);
      const ans = String(self.getSessionString());
      // this.recordAnswerAttempt(correct);
      self.onAnswerUpdate?.(ans, self.puzzleState);

      if (correct) {
        this.successAnimation();
      }

      if (self.onPuzzleAnimationEnd) {
        self.onPuzzleAnimationEnd();
      }
    }

    private successAnimation(): void {
      const cam = this.svgRenderer.camera;
      this.numberLine.doCorrect();
      const tweenObjFrom = { scale: cam.getScale(), x: cam.getCenter().x, y: cam.getCenter().y };
      const tweenObjTo = {
        duration: 1, onComplete: (() => {
          this.dropConfetti();
        }), onUpdate: (() => {
          cam.setScale(tweenObjFrom.scale);
          cam.centerOn({ x: tweenObjFrom.x, y: tweenObjFrom.y });
        }), scale: 1,
        x: 1280 / 2,
        y: 720 / 2
      };

      gsap.to(tweenObjFrom, tweenObjTo);
    }

    public reset(): void {
      this.numberLine.reset();

      if (this.inputType !== 'keyboard') {
        this.resetShine();
      }

      this.svgRenderer.camera.setScale(1);
      this.svgRenderer.camera.setPosition({ x: 0, y: 0 });
    }

    public resetShine(): gsap.core.Timeline {
      const timeline = gsap.timeline();
      const onUpdate = () => {
        this.drawShine();
      };
      const onComplete = () => {
        this.awaitingInput = true;
        this.feedbackComplete = false;
      }
      timeline.to(this, {
        currentShineTarget: this.shineCenter,
        duration: 1,
        ease: 'elastic',
        onComplete: onComplete,
        onUpdate: onUpdate,
        rotation: 0
      });
      timeline.to(this.light!, { duration: 1, ease: 'elastic', rotation: 0 }, '<');

      return timeline;
    }

    private initNumberLineParams(): void {

      const start = { label: this.setup.min, location: this.setup.min, reduce: this.setup.reduceEndpoints, visible: true } as Marking;
      const end = { label: this.setup.max, location: this.setup.max, reduce: this.setup.reduceEndpoints, visible: true } as Marking;

      this.markings = [start];

      if (this.setup.tickStepSize) {

        let sum: number = Number(this.setup.tickStepSize) + Number(this.setup.min);
        do {
          const label = sum;
          this.markings.push({ label: label, location: sum, reduce: this.setup.reduceTicks, visible: this.setup.labelTicks });
          sum += Number(this.setup.tickStepSize);
        } while (sum < this.setup.max);
      }

      const hints = this.setup.hints ? this.setup.hints : [];

      const hintMarkings = hints.map(h => ({ label: h, location: h, reduce: this.setup.reduceHints, visible: true }))

      const _ = hintMarkings && this.markings.push(...hintMarkings);
      this.markings.push(end);
    }

    private initView(): void {
      if (this.inputType === 'spotlight') {
        if (this.setup.customDescriptor) {
          this.view.descriptor = this.getLabel(60, this.setup.target, this.setup.denominator, this.setup.reduceTarget, this.setup.customDescriptor);
        } else {
          this.view.descriptor = this.getLabel(60, this.setup.target, this.setup.denominator, this.setup.reduceTarget);
        }

        this.spotlightScene!.appendChild(this.view.descriptor);
        const w = this.view.descriptor.getBBox().width;
        const h = this.view.descriptor.getBBox().height;
        gsap.set(this.view.descriptor, { x: this.shineCenter - w / 2, y: 140 / 2 });
      } else {
        if (this.header) {

          if (this.puzzleUiCameraOverlay) {

            // this is not ideal. But, because this input is raised out of its css context by the
            // focus event system we need it to have its style inlined or else it just renders in the browser
            // default style :(
            const inputStyle = `-webkit-appearance: none;
            background-color: transparent;
            border-style: none;
            color: white;
            font-size: 40px;
            text-align: center;
            background-image: url(images/spotlight-input-image.svg);
            background-position: center;
            background-repeat: no-repeat;
            outline: none;
            -moz-appearance: textfield;
            `;


            this.numberInput = new OverlayNumberInput({
              height: 100,
              ignoreDecimalsInLength: true,
              maxLength: this.maxInputDigits ? this.maxInputDigits : 10,
              minNumber: this.setup.min,
              style: inputStyle,
              type: 'number',
              width: 200,
              x: this.shineCenter - 100,
              y: 15
            }, this.puzzleUiCameraOverlay);

            this.numberInput.onChange = (n: number) => {
              // if we're operating in decimals mode, we need to multiply by 10^x where x is the number of decimals (1, 10, 100, etc)
              const multFactor = Math.pow(10, Number(this.setup.decimals) || 0);
              this.numberLine.setTarget(n * multFactor);
              this.updateCanPlayValue();
            }
          }
        }
      }

      gsap.set(this.view.numberLine, {
        attr: {
          x1: 0,
          x2: this.setup.width,
          y1: this.setup.numberlineY,
          y2: this.setup.numberlineY
        },
        fill: 'none',
        stroke: 'white',
        strokeWidth: 5
      });

      const numberLineY = this.setup.numberlineY;
      const lightY = getBoundingBoxWithTransform(this.light).y;
      this.yFromNumberLineToLight = numberLineY - lightY;

      bringToFront(this.light);
      bringToFront(this.header);

      const scale = Math.min(1, Math.sqrt(this.setup.spotlightWidth / 0.06));
      gsap.set(this.flair!, { scaleX: scale, transformOrigin: '50% 0%' });
    }

    private initLayoutParams(): void {
      this.currentShineTarget = this.setup.width / 2;
      this.targetInPixels = this.getPointFromUnits(this.setup.target - this.setup.min);
      this.initNumberLineParams();
    }

    private getPointFromUnits(units: number): number {
      const lineInPixels = (1 - this.setup.padding) * this.setup.width;
      const ratio = units / (this.setup.max - this.setup.min);
      const point = (this.setup.padding / 2) * this.setup.width + lineInPixels * ratio;

      return point;
    }

    private attachEvents(): void {
      this.spotlightScene!.addEventListener('pointerdown', this.backgroundPointerDown.bind(this));
    }

    public generateReport(): void {
      this.initLayoutParams();
      this.initNumberLineParams();
      this.initView();
      this.drawShine();
    }

    private init(): void {
      this.generateReport();
      this.attachEvents();


      this.numberLine = new NumberLine(this.svgRenderer, {
        constantJumpSize: this.majorJumps ? this.majorJumps : undefined,
        divisor: Math.pow(10, this.setup.decimals),
        endPosition: { x: 1280, y: this.setup.numberlineY },
        labelJumps: this.labelJumps,
        markers: this.getMarkerData(),
        startJumpNumber: this.setup.jumpStart ? Number(this.setup.jumpStart) : undefined,
        startPosition: { x: 0, y: this.setup.numberlineY },
        stroke: { color: 'white', width: 5 },
        target: this.setup.target
      }).render();

      if (this.inputType === 'keyboard' && this.initialSpotlightTarget) {
        this.rotateShineToNumber(Number(this.initialSpotlightTarget));
      }
    }

    private getMarkerData(): NumberLineMarkerData[] {
      const data: NumberLineMarkerData[] = [];

      for (let i = 0; i < this.markings.length; i++) {
        const curMarker = this.markings[i];
        const markerLabel = parseFloat(applyDecimalOffset(curMarker.label, this.setup.decimals)).toString();

        const newData: NumberLineMarkerData = {
          label: curMarker.visible ? markerLabel : '',
          value: curMarker.label
        };

        if (curMarker.reduce) {
          const { n, d } = this.reduceFraction(curMarker.label, this.setup.denominator);

          // ensure there is actually a denominator when trying to do this
          if (d) {
            newData.label = `${n}/${d}`;
          }
        }

        data.push(newData);
      }

      return data;
    }

    public override onPuzzlePlay(): void {
      this.playResetToggle();
    }

    public onPuzzleTryAgain(): void {
      this.playResetToggle();
    }

    public onPuzzleHelp(): void {
      this.focusShineAnimation();
    }

    private focusShineAnimation() {
      if (this.inputType === 'spotlight') {
        if (this.helpTweenAnimation && this.helpTweenAnimation.isActive()) {
          return;
        }

        this.awaitingInput = false;
        const restNumber = this.currentShineTarget;
        const tweenObj = {num: restNumber + 50};

        const t = gsap.to(tweenObj, {duration: 1, ease: 'elastic', num: restNumber, onComplete: () => {
          this.currentShineTarget = restNumber;
          this.awaitingInput = true;
        }, onUpdate: () => {
          this.currentShineTarget = tweenObj.num;
          this.drawShine();
        }
        });

        this.helpTweenAnimation = t;
      } else {
        if (this.puzzleUiCameraOverlay) {
          const rect = this.numberInput?.getElement().getBoundingClientRect();

          if (rect && this.numberInput) {
            const center: KHPoint = {x: rect.x + rect.width / 2, y: rect.y + rect.height / 2};
            const coords = this.svgRenderer.camera.getWorldCoordinates(center);
            this.pointerHand(this.numberInput.getElement(), this.puzzleUiCameraOverlay, coords);
          }
        }
      }

    }

    private playResetToggle(): void {
      if (self.feedbackComplete) {
        self.reset();
        self.feedbackComplete = false;
        self.awaitingInput = true;
      } else if (self.awaitingInput) {
        self.awaitingInput = false;
        this.numberLine.animate().then(() => {
          self.onFeedbackComplete();
          self.feedbackComplete = true;
        });
      }
    }

    public onPuzzleOverlayConnect(): void {
      super.onPuzzleOverlayConnect();
      this.updateCanPlayValue();
    }

    private updateCanPlayValue(): void {
      if (this.onPlayEnable) {
        let enabled = true;

        if (this.setup.inputType === 'keyboard') {
          enabled = !!this.numberInput?.getValue();
        }

        this.onPlayEnable(enabled);
      }
    }
  }

  return new PuzzleSpotlightClass(_elements, _setup);
}
