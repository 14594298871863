import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kh-input-int-keypad',
  templateUrl: './input-int-keypad.component.html',
  styleUrls: ['./input-int-keypad.component.less']
})
export class InputIntKeypadComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public maxDigits: number = 0;
  @Input() public font: string = '';
  @Input() public brokenKeys: number[] = [];
  @Input() public result: string = '';

  @Output() public resultChange = new EventEmitter<string>();
  @Output() public onClose = new EventEmitter<void>();
  @Output() public onAfterInit = new EventEmitter<void>();

  public numbers: { value: number, broken: boolean }[] = [];

  public ngOnDestroy(): void {
    window.removeEventListener('keydown', this.onKeydown);
  }

  public ngOnInit(): void {
    for (let i = 0; i < 10; i++) {
      this.numbers.push({
        value: i,
        broken: this.brokenKeys.includes(i)
      });
    }

    window.addEventListener('keydown', this.onKeydown);
  }

  public ngAfterViewInit(): void {
    this.onAfterInit.emit();
  }

  public onKeydown = (e: KeyboardEvent) => {
    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
      const KeyValue = parseInt(e.key);
      this.setValue(KeyValue);
      e.preventDefault();
    }
    else if (e.keyCode === 46 || e.keyCode === 8) {
      this.cancel();
      e.preventDefault();
    }
  }

  public setValue(value: number): void {    
    if (!this.brokenKeys.includes(value)) {
      let result = this.result.toString();

      if (result.length < this.maxDigits) {
        result = result.concat(value.toString());
      }
      else if (this.maxDigits === 1) {
        result = value.toString();
      }

      this.result = result;

      this.resultChange.emit(this.result);
    }
  }

  public cancel(): void {
    let result = this.result.toString();
    result = result.slice(0, -1);
    this.result = result ?? '';

    this.resultChange.emit(this.result);
  }

  public done(): void {
    this.onClose.emit();
  }

}
