<div class="puzzleContainer">
    <div *ngIf="awaitingPixiInitialize()">
        <div 
            #pixiInput 
            class="puzzlePlayButton"
            (click)="pixiInputClicked()"
            ></div>
    </div>
    <div *ngIf="!awaitingPixiInitialize()" #overlayDiv [id]="'puzzleOverlay' + myId" class="puzzleOverlay">
            <div 
                #nextButton
                *ngIf="isPuzzleAnswerCorrect() && !shouldShowDisabledSubmit()" 
                class="puzzleNextButton scale-on-hover"
                (click)="onSubmitClicked()"
            ></div>

            <div 
                #nextButtonDisabled
                *ngIf="shouldShowDisabledSubmit()" 
                class="puzzleNextButtonDisabled"
                (click)="onDisabledSubmitClicked()"
            ></div>

            <div
                #playButton
                *ngIf="shouldShowPlayButton()"
                class="puzzlePlayButton scale-on-hover"
                (click)="onPlayClicked()"
            ></div>

            <div
                #resetButtonTopLeft
                *ngIf="shouldShowResetCorrect()"
                class="puzzleResetButton scale-on-hover"
                (click)="onTryAgainClicked()"
            ></div>

            <div
                #resetButtonCenter
                *ngIf="shouldShowResetIncorrect()"
                class="puzzleReplayButton scale-on-hover rotate"
                (click)="onTryAgainClicked()"
            ></div>

            <div
                #tryAgainButton
                *ngIf="shouldDisplayTryAgain()"
                class="puzzleReplayButton scale-on-hover rotate"
                (click)="onTryAgainClicked()"
            ></div>
    </div>
    <div [id]="'puzzleSvgContainer' + myId" class="puzzleSvgContainer">
        <ng-content></ng-content>
    </div>
</div>
