
import { fabric } from 'fabric';
import { FabricRectRegion } from './fabric-rect-region';
import { IRegionOptions, RegionDefaultOptions } from '../..';

export class FabricOvalRegion extends FabricRectRegion {
  public constructor(opts?: Partial<IRegionOptions>) {
    const options = {...RegionDefaultOptions, ...(opts || {})};
    super({...options, rx: (options.width || 0) / 2, ry: (options.height || 0) / 2}, fabric.Ellipse);
  }
}
