import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numWithSign'
})
export class NumWithSignPipe implements PipeTransform {
  transform(value: number): string {
    return value > 0 ? '+' + value : String(value);
  }
}
