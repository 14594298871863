import { fabric } from 'fabric';

export interface IDraggableOptions extends fabric.IRectOptions {
  hasOutline?: boolean;
  initialPosition?: string;
  src: string;
  img?: fabric.Image | null;
  _id: string;
  index: number;
  cloned?: boolean;
  BGRect: fabric.Rect;
  OutlineRect: fabric.Rect;
  value?: string;
}
