import { Graphics } from 'pixi.js';
import { KHPoint } from '../KHPoint';

export function CreatePixiTriangle(p1: KHPoint, p2: KHPoint, p3: KHPoint, color: number): Graphics {
  const graphics = new Graphics();

  graphics.lineStyle(1, color, 1);
  graphics.beginFill(color, 1);

  // Draw the triangle by moving to the first point and drawing lines to the other points
  graphics.moveTo(p1.x, p1.x);
  graphics.lineTo(p2.x, p2.y);
  graphics.lineTo(p3.x, p3.y);
  graphics.lineTo(p1.x, p1.y); // close the triangle

  graphics.endFill();

  return graphics;
}

export function CreatePixiRectangle(x: number, y: number, width: number, height: number, color: number): Graphics {
  const graphics = new Graphics();

  graphics.beginFill(color, 1);
  graphics.drawRect(x, y, width, height);
  graphics.endFill();

  return graphics;
}

export function CreatePixiLine(p1: KHPoint, P2: KHPoint, width: number, color: number): Graphics {
  const graphics = new Graphics();

  graphics.lineStyle(width, color);
  graphics.moveTo(p1.x, p1.y);
  graphics.lineTo(P2.x, P2.y);
  graphics.endFill();

  return graphics;
}