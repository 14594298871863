import { KHPoint } from "../pixi-puzzle-framework/PuzzleEngine/KHPoint";



/**
 * Animate a pointer icon and rings at the desired position on the puzzle.
 */
export class PuzzleHandPointer {
  private parent: HTMLDivElement;
  private position: KHPoint;
  private hand: HTMLImageElement;
  private rings: HTMLDivElement;
  private onClickHandler: () => void;
  private onDestroy: () => void;
  private blocker: HTMLElement;
  private focusedElement: HTMLElement;

  public constructor(parent: HTMLDivElement, focusedElement: HTMLElement, position: KHPoint, onDestory: () => void) {
    this.parent = parent;
    this.focusedElement = focusedElement;
    this.position = position;
    this.blocker = document.createElement('div');
    this.onDestroy = onDestory;

    this.onClickHandler = this.onClick.bind(this);

    this.rings = document.createElement('div');
    this.hand = new Image();

    this.makeBlocker();
    this.makeHand();

    parent.addEventListener('click', this.onClickHandler);
    this.focusedElement.style.zIndex = '1001';
  }

  public makeHand(): void {
    const src = 'images/puzzle-hand-pointer.svg';


    this.hand.style.position = 'absolute';
    this.hand.style.left = `${this.position.x}px`;
    this.hand.style.top = `${this.position.y}px`;
    this.hand.style.width = '100px';
    this.hand.style.height = '100px';

    this.hand.onload = () => {
      this.animate();
    };

    this.hand.src = src;
    this.parent.appendChild(this.hand);
  }

  private animate(): void {
    this.animateRings();
    this.animateHand();
  }

  private makeCircle(): void {
    this.rings.style.left = `${this.position.x - 50}px`;
    this.rings.style.top = `${this.position.y - 50}px`;
    this.rings.classList.add('animatedCircle');
    this.rings.style.zIndex = '1001';

    this.parent.appendChild(this.rings);
  }

  private animateRings(): void {
    this.makeCircle();
  }

  private animateHand(): void {
    this.hand.style.zIndex = '1001';
    this.hand.style.animation = 'bounceAnimation 2s ease-in-out infinite';
  }

  private onClick(): void {
    this.destroy();
  }

  public destroy(): void {
    this.rings.remove();
    this.hand.remove();
    this.blocker.remove();

    this.parent.removeEventListener('click', this.onClickHandler);
    if (this.onDestroy) {
      this.onDestroy();
    }
  }

  private makeBlocker(): void {
    this.blocker.style.backgroundColor = 'black';
    this.blocker.style.opacity = '0.4';
    this.blocker.style.zIndex = '1000'
    this.blocker.style.position = 'absolute';
    this.blocker.style.left = '0px';
    this.blocker.style.top = '0px';
    this.blocker.style.width = '100%';
    this.blocker.style.height = '100%';
    this.blocker.style.pointerEvents = 'auto';

    this.parent.appendChild(this.blocker);
  }
}
