import { InjectionToken } from '@angular/core';
import { IndividualConfig, ActiveToast } from 'ngx-toastr';

export type ToastType = 'success' | 'info' | 'warning' | 'error';

export interface ILocalizedToasterService {
    pop(type: ToastType, title: string, body?: string, config?: Partial<IndividualConfig>): ActiveToast<any>;
    success(title: string, body?: string, config?: Partial<IndividualConfig>): ActiveToast<any>;
    info(title: string, body?: string, config?: Partial<IndividualConfig>): ActiveToast<any>;
    error(title: string, body?: string, config?: Partial<IndividualConfig>): ActiveToast<any>;
    warn(title: string, body?: string, config?: Partial<IndividualConfig>): ActiveToast<any>;
    persistedError(title: string, body?: string): ActiveToast<any>;
    khError(data: any): ActiveToast<any>;
}

export const LOCALIZED_TOASTER_SERVICE_TOKEN = new InjectionToken<ILocalizedToasterService>('ILocalizedToasterService');