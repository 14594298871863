import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { RegroupClass } from './regroupAPI'
import { QUESTION_EVENTS_SERVICE_TOKEN, IQuestionEventsService } from '../../../../services/IQuestionEvents';
import { ANALYTICS_SERVICE_TOKEN, IAnalyticsService } from '../../../../services/IAnalyticsService';
import { AUTH_DATA_SERVICE_TOKEN, IAuthenticationDataService } from '../../../../services/IAuthenticationDataService';
import { PuzzleBaseComponent } from '../../../../LearningWorlds/puzzle/puzzle.component';
import { PuzzleEventService } from '../../../../LearningWorlds/services/puzzle-event.service';

@Component({
  selector: 'kh-puzzle-representing-numbers',
  templateUrl: './representing-numbers.component.html',
  styleUrls: ['./representing-numbers.component.css']
  })
export class RepresentingNumbersComponent extends PuzzleBaseComponent implements AfterViewInit, OnInit {
  @ViewChild('lowerRenderEl') public lowerRenderEl!: ElementRef<SVGSVGElement>;

  @Input() public type = 'grapes';
  @Input() public tens = 16;
  @Input() public ones = 42;
  @Input() public feedBack = 'full';

  @Output() public valueChange = new EventEmitter<{ answer: string, state: string }>();

  public constructor(
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService,
    public puzzleEventService: PuzzleEventService,
    @Inject(ANALYTICS_SERVICE_TOKEN) protected analyticsService: IAnalyticsService,
    @Inject(AUTH_DATA_SERVICE_TOKEN) protected authenticationDataService: IAuthenticationDataService) {
    super(questionEventsService, puzzleEventService, analyticsService, authenticationDataService);
  }

  private resizeObserver!: ResizeObserver;

  public ngAfterViewInit(): void {
    this.resizeObserver = new ResizeObserver(entries => {
      this.handleResize(entries);
    });

    this.resizeObserver.observe(this.lowerRenderEl.nativeElement);
  }

  private handleResize(entries: ResizeObserverEntry[]) {
    const width = entries[0].contentRect.width;
    const height = entries[0].contentRect.height;
    if (width > 0 && height > 0) {
      // what the hell? This makes a new puzzle instance on resize??
      const interactive = new RegroupClass(
        this.lowerRenderEl.nativeElement,
        this.type,
        this.tens,
        this.ones,
        this.feedBack
      );

      interactive.onAnswerUpdate = this.onAnswerUpdate.bind(this);

      this.setPuzzle(interactive)

      this.resizeObserver.disconnect();
    }
  }
}
