import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MathRenderingService {
  public renderContent(renderIndividually?: boolean): void {
    if (renderIndividually) {
      return this.renderMathIndividually();
    }

    return this.renderMath();
  }

  private renderMath(): void {
    this.render('.RenderMathParent');
  }

  public renderMathIndividually(): void {
    this.render('.RenderMath');
  }

  private async render(renderingClass: string): Promise<void> {
    const allElements = document.querySelectorAll(renderingClass);

    // Check if the element has children with the same class and omit it so we don't try to double render the same content twice.
    // Double rendering can cause issues with MathJax.
    const internalElements = Array.from(allElements).filter(element => !element.querySelector(renderingClass));

    await (MathJax as any).typesetPromise(internalElements);
  }
}
