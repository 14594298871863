import { KHPoint } from '../../../../../LearningWorlds/pixi-puzzle-framework/PuzzleEngine/KHPoint';
import { ManipFrame } from '../../../../../utils/utils';
import { ScorableDragRegion } from './ScorableDragRegion';

/**
 * While this is a "group" it isn't an actual DIV element or anything - it just positions the draggables with some logic.
 */
export class ScorableDragGroup {
  private draggables: ScorableDragRegion[];
  private framePosition: ManipFrame;
  private draggablePadding = 20;
  private maxWidth;

  public constructor(parent: HTMLElement, topCenterPosition: KHPoint, maxWidth: number, draggables: ScorableDragRegion[]) {
    this.draggables = draggables;
    this.maxWidth = maxWidth;
    console.log(topCenterPosition);
    this.framePosition = this.calculateGroupSize(topCenterPosition);

    this.positionDraggables();
  }

  private calculateGroupSize(topCenter: KHPoint): ManipFrame {
    let width = 0;
    let height = 0;

    for (const draggable of this.draggables) {
      if (draggable.getIsPositioned()) {
        continue;
      }

      const rect = draggable.dragElement.getBoundingClientRect();
      width += rect.width;
      height += rect.height;
    }

    width += this.draggablePadding * (this.draggables.length - 1);
    width = Math.min(width, this.maxWidth);

    return { height, width, x: topCenter.x - (width / 2), y: topCenter.y + this.draggablePadding };
  }

  /**
   * Position the draggables in a row such as they're centered in each row.
   * On overflow start a new row that is also centered and so on.
   */
  private positionDraggables() {
    const startX = this.framePosition.x;
    const startY = this.framePosition.y;
    let currentX = startX;
    let currentY = startY;
    let rowHeight = 0;
    let rowElements: ScorableDragRegion[] = [];

    for (let draggable of this.draggables) {
      // Get the bounding rectangle of the element
      const rect = draggable.dragElement.getBoundingClientRect();

      // Check if adding this element would exceed maxWidth
      if (currentX + rect.width > startX + this.maxWidth) {
        // Center the previous row of elements
        const totalRowWidth = rowElements.reduce((sum, e) => sum + e.dragElement.getBoundingClientRect().width + this.draggablePadding, -this.draggablePadding);
        const offsetX = (this.maxWidth - totalRowWidth) / 2;

        // Position the elements in the row
        let rowCurrentX = startX + offsetX;
        for (let e of rowElements) {
          const eRect = e.dragElement.getBoundingClientRect();
          e.updateAnchorPosition(rowCurrentX, currentY);
          rowCurrentX += eRect.width + this.draggablePadding;
        }

        // Move to the next row
        currentY += rowHeight + this.draggablePadding;
        rowHeight = 0;
        rowElements = [];
        currentX = startX;
      }

      // Add the element to the current row
      rowElements.push(draggable);
      rowHeight = Math.max(rowHeight, rect.height);
      currentX += rect.width + this.draggablePadding;
    }

    // Center and position any remaining elements in the last row
    if (rowElements.length > 0) {
      const totalRowWidth = rowElements.reduce((sum, e) => sum + e.dragElement.getBoundingClientRect().width + this.draggablePadding, -this.draggablePadding);
      const offsetX = (this.maxWidth - totalRowWidth) / 2;

      let rowCurrentX = startX + offsetX;
      for (let e of rowElements) {
        const eRect = e.dragElement.getBoundingClientRect();
        e.updateAnchorPosition(rowCurrentX, currentY);
        rowCurrentX += eRect.width + this.draggablePadding;
      }
    }
  }
}
