import { fabric } from 'fabric';

export interface IObjectAddition {
  originalWidth: number;
  originalHeight: number;
  originalScaleX?: number;
  originalScaleY?: number;
  originalLeft?: number;
  originalTop?: number;
  id: string;
  lockObject?: boolean;
}

export type FabricExtended<T> = T & IObjectAddition;

export type FabricExtendedCanvas = FabricExtended<fabric.Canvas>;
export type ExtendedObject = FabricExtended<fabric.Object>;
export type ExtendedImage = FabricExtended<fabric.Image> & { isTinted?: boolean };