
import gsap from 'gsap';
import Sprite from '../../../../KhDecorators/Pattern/Sprite';

const svgNS = 'http://www.w3.org/2000/svg';

class SvgText{
  public parent: SVGSVGElement|SVGGElement;
  public group: SVGGElement;
  public background: SVGRectElement;
  public text: SVGTextElement;
  public paddingX = 0;
  public paddingY = 0;
  public visible = true;

  public constructor(svgParent: SVGSVGElement|SVGGElement, paddingX: number, paddingY: number, fontSize: string){
    this.parent = svgParent;
    this.group = document.createElementNS(svgNS, 'g');
    this.text = document.createElementNS(svgNS, 'text');
    this.background = document.createElementNS(svgNS, 'rect');
    this.paddingX = paddingX;
    this.paddingY = paddingY;

    gsap.set(this.background, {fill: 'white', rx: 5});
    this.text.style.fontSize = fontSize;
    this.text.style.fontWeight = 'bold';

    this.group.append(this.background);
    this.group.append(this.text);
    this.parent.append(this.group);
  }

  public setText(text: string): void {
    this.text.textContent = text;
    this.resize();
  }

  public resize(): void {
    const bbox = this.text.getBBox();
    gsap.set(this.background,
      {
        x: bbox.x - this.paddingX / 2,
        y: bbox.y - this.paddingY / 2,
        width: bbox.width + this.paddingX,
        height: bbox.height + this.paddingY
      })
  }

  public move(x: number, y: number): void{
    gsap.to(this.group, {x: x, y: y, duration: 0});
  }

  public setVisible(state: boolean): void {
    this.visible = state
    if (this.visible) {
      this.group.style.display = '';
    } else {
      this.group.style.display = 'none';
    }
  }

  public toggle(): void {
    this.setVisible(!this.visible)
  }

  public applyOrderedValues(values: object, order: string[]): void {
    let output = '';

    for (let i = 0; i < Object.entries(values).length; i++) {
      const next = order[i];
      switch(next){
        case 'start':
          output += ' = ' + this.signString(Sprite.getProperty(values, next));
          break;
        case 'balloonsAdded':
          output += ' + ' + `(${this.signString(Sprite.getProperty(values, next))})`;
          break;
        case 'balloonsRemoved':
          output += ' - ' + `(${this.signString(Sprite.getProperty(values, next))})`;
          break;
        case 'sandBagsAdded':
          output += ' + ' +`(${this.signString(-Sprite.getProperty(values, next))})`;
          break;
        case 'sandBagsRemoved':
          output += ' - ' + `(${this.signString(-Sprite.getProperty(values, next))})`;
          break;
      }
    }

    this.setText(output);
  }

  public signString(n: number): string {
    if (n <= 0) {
      return `${n}`;
    }

    return `+${n}`;
  }

  public getRight(): number{
    const bbox = this.group.getBBox();
    const x = gsap.getProperty(this.group, 'x') as number;

    return x + bbox.width
  }

  public getMiddle(){
    const bbox = this.group.getBBox();

    return bbox.y - bbox.height / 3;
  }

} export default SvgText
