import { BehaviorSubject } from 'rxjs';
import { EventEmitter } from '@angular/core';

export class WindowsService {

  private controllers: {[key: string]: BehaviorSubject<number>} = {};
  private controllerSettings: {[key: string]: BehaviorSubject<{order: number, id: string}[]>} = {};

  private events: {[key: string]: {forward: EventEmitter<any>, backward: EventEmitter<any>}} = {};

  public valueChange = new EventEmitter<string>();
  public windows = new BehaviorSubject<{[key: string]: string}>({});
  public windowAnswers = new BehaviorSubject<{[key: string]: string}>({});
  public windowState = new BehaviorSubject<{[key: string]: string}>({});

  public setWindowAnswer(windowId: string, answer: string) {
    this.windowAnswers.next({...this.windowAnswers.value, [windowId]: answer});
  }

  public setWindows(windowId: string, paneId: string) {
    this.windows.next({...this.windows.value, [windowId]: paneId});
  }

  public setWindowState(windowId: string, answer: string){
    this.windowState.next({...this.windowState.value, [windowId]: answer});
  }

  public setKeyValueAnswer(name: string, value: string) {
    // If the value contains a comma, wrap it in quotes. French language uses commas as decimal separators.
    if (value.includes(',')) {
      value = `"${value}"`;
    }

    this.setWindowAnswer(name, value);
  }

  public getControllerById(id: string): BehaviorSubject<number> {
    const unquotedId = id.replace(/[']/g, '');
    const controller = this.controllers[unquotedId];
    if (!controller) {
      this.controllers[unquotedId] = new BehaviorSubject(0);
    }

    return this.controllers[unquotedId];
  }

  public getControllerSettingsById(id: string): BehaviorSubject<{order: number, id: string}[]> {
    const controllerSettings = this.controllerSettings[id];
    if (!controllerSettings) {
      this.controllerSettings[id] = new BehaviorSubject([] as {order: number, id: string}[]);
    }

    return this.controllerSettings[id];
  }

  public getEvents(id: string): {forward: EventEmitter<any>, backward: EventEmitter<any>} {
    const controllerSettings = this.events[id];
    if (!controllerSettings) {
      this.events[id] = {backward: new EventEmitter<any>(), forward: new EventEmitter<any>()}
    }

    return this.events[id];
  }

}
