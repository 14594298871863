import { MinecartTermType } from "./MinecartTerm";

export class MinecartTermSelectionMenuItem {
  public value: number;
  public type: MinecartTermType;
  private textElement: Text;
  private image: HTMLElement;
  public element: HTMLDivElement;
  private onClick?: (o: MinecartTermSelectionMenuItem) => void;

  public constructor(value: number, type: MinecartTermType, parent: HTMLElement, onClick: (o: MinecartTermSelectionMenuItem) => void) {
    this.value = value;
    this.type = type;
    this.onClick = onClick;

    // create elements
    this.element = document.createElement('div');
    this.textElement = document.createTextNode('');
    this.image = document.createElement('div');
    this.textElement.textContent = `${value}`;
    this.element.classList.add('minecart-default-node');
    this.element.addEventListener('click', () => {
      if (this.onClick) {
        this.onClick(this);
      }
    });

    // set image style
    let imageClassName = type === 'balloon' ? 'minecart-balloon' : 'minecart-sandbag';
    this.image.setAttribute('class', imageClassName);

    // append elements to DOM
    this.element.appendChild(this.textElement);
    this.element.appendChild(this.image);
    parent.appendChild(this.element);
  }

  public setSelected(selected: boolean): void {
    this.element.setAttribute('class', selected ? 'minecart-selected-node' : 'minecart-default-node');
  }
}