import { Component, Input } from '@angular/core';
import { Node } from '../manip-dot-connect/dot-connect-node';
import { TEMPLATE_PREFIX } from '../../../../environments/locale-config';

@Component({
  selector: 'kh-node',
  templateUrl: TEMPLATE_PREFIX + 'node.component.html',
  styleUrls: ['./node.component.less']
})
export class NodeComponent {

  @Input() public id = '';
  @Input() public x = 0;
  @Input() public y = 0;
  
  public getNode(): Node {
    return {
      id: this.id,
      x: this.x,
      y: this.y
    }
  }
}
