
import { ModalComponent } from 'shared-components';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { UnsafeHtmlPipe } from './pipes/unsafe-html.pipe';
import { UnsafeUrlPipe } from './pipes/unsafe-url.pipe';
import { TextEditorFileUploadModalComponent } from './modals/text-editor-file-upload-modal/text-editor-file-upload-modal.component';
import { UploadFileModalsHolderComponent } from './components/upload-file-modals-holder/upload-file-modals-holder.component';
import { UploadFilesListingModalComponent } from './modals/upload-files-listing-modal/upload-files-listing-modal.component';
import { ImgComponent } from './components/img/img.component';
import { InputComponent } from './components/input/input.component';
import { InputTextEditorComponent } from './components/input-text-editor/input-text-editor.component';
import { LoadingStatusComponent } from './components/loading-status/loading-status.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { VideoComponent } from './components/video/video.component';
import { VideoWithEventsComponent } from './components/video/video-with-events/video-with-events.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { NgModule } from '@angular/core';


const directives = [
  ClickOutsideDirective
];

const pipes = [
  UnsafeHtmlPipe,
  UnsafeUrlPipe
];

const modals = [
  ModalComponent,
  TextEditorFileUploadModalComponent,
  UploadFileModalsHolderComponent,
  UploadFilesListingModalComponent
];

const components = [
  ImgComponent,
  InputComponent,
  InputTextEditorComponent,
  LoadingStatusComponent,
  UploadImageComponent,
  VideoComponent,
  VideoWithEventsComponent,
];

@NgModule({
  imports: [
    FontAwesomeModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    QuillModule
  ],
  declarations: [
     ...directives,
    ...pipes,
    ...components,
    ...modals
  ],
  exports: [
    FontAwesomeModule,
    ...directives,
    ...pipes,
    ...components
  ]
})
export class SharedModule {}
