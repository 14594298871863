import FontFaceObserver from 'fontfaceobserver';

export const fontLoader = (font = 'arial'): Promise<void> => {
  if (['arial', 'helvetica', 'times new roman'].includes(font.toLowerCase())) {
    return Promise.resolve();
  }
  const myFont = new FontFaceObserver(font);

  return myFont.load()
  .catch((e: any) => {
    console.warn(e);
  });
};
