/* eslint-disable indent */
import { SvgRenderElement, SvgRenderElementType } from './SvgRenderElement';
import { SvgRenderer } from './SvgRenderer';

export type SvgLineConfig = SvgRenderElementType & {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    stroke?: string;
    strokeWidth?: number;
    nonScalingStroke?: boolean,
}

const svgLineConfigDefault: Partial<SvgLineConfig> = {
    maintainScale: false,
    nonScalingStroke: false,
    stroke: 'black',
    strokeWidth: 2,
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0
}

export class SvgLine extends SvgRenderElement<SvgLineConfig> {
    public constructor(renderer: SvgRenderer, parent: SVGElement, config: SvgLineConfig) {
        super(renderer, parent, config, svgLineConfigDefault);
    }

    public createElement(): void {
        this.element = document.createElementNS(SvgRenderer.svgns, 'line');
    }

    protected onMaintainScale(newScale: number): void {
        const s = this.getConfigValue('strokeWidth') * newScale;
        this.element.setAttribute('stroke-width', s.toString());
    }

    public applyConfigProperties(): void {
        this.element.setAttribute('x1', this.config.x1.toString());
        this.element.setAttribute('x2', this.config.x2.toString());
        this.element.setAttribute('y1', this.config.y1.toString());
        this.element.setAttribute('y2', this.config.y2.toString());
        this.element.setAttribute('stroke', this.getConfigValue('stroke'));
        this.element.setAttribute('stroke-width', this.getConfigValue('strokeWidth').toString());

        if (this.getConfigValue('nonScalingStroke')) {
            this.element.setAttribute('vector-effect', 'non-scaling-stroke');
        }
    }
}
