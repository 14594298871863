import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IQuestionAnswerSuccess } from 'app/question/components/question/models/question-answer-success';
import { QuestionAnswerFail } from 'app/question/components/question/models/question-answer-fail';

@Injectable({
  providedIn: 'root'
})
export class QuestionEventsService {
  public submitAnswerCompleted = new Subject<IQuestionAnswerSuccess>();
  public submitAnswerFailed = new Subject<QuestionAnswerFail>();
  public questionClosed = new Subject<any>();

  public hideInputOnlyForManip = new BehaviorSubject(false);
  public hideAllInputForManip = new BehaviorSubject(false);
  public hideStudentManipControlBar = new BehaviorSubject(false);

  public disableButtonForPuzzle = new BehaviorSubject(false);
  private puzzleSubmitSubject = new Subject<void>();
  public puzzleSubmit$ = this.puzzleSubmitSubject.asObservable();

  public puzzleSubmitButtonClicked(): void {
    this.puzzleSubmitSubject.next();
  }
}
