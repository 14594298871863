
import { FabricIntInputRectRegion } from './fabric-int-input-rect-region';
import { FabricOvalRegion } from './fabric-oval-region';
import { IInputRegionCallbacks } from './models/input-region-callbacks';
import { IInputRegionOptions } from './models/input-region-options';

export class FabricIntInputOvalRegion extends FabricIntInputRectRegion {
  public constructor(
    options: IInputRegionOptions,
    callbacks: IInputRegionCallbacks) {
    super(options, callbacks, FabricOvalRegion);
  }
}
