import { KHPoint } from '../../../../../LearningWorlds/pixi-puzzle-framework/PuzzleEngine/KHPoint';
import { ManipFrame, parseFrameString, applyFrameToElement } from '../../../../../utils/utils';
import { SetDraggable } from '../../../../mixins/DraggableMixin';
import { ScorableDropRegion } from './ScorabelDropRegion';
import gsap from 'gsap/all';

export type DragRegionConfig = {
  parent: HTMLElement,
  frame?: string,
  visualElement: HTMLElement,
  key: string,
  dropRegions: ScorableDropRegion[],
  makeclones: boolean,
}

export class ScorableDragRegion {
  public dragElement: HTMLDivElement;
  public config: DragRegionConfig;
  private initialFrame: ManipFrame = { height: 0, width: 0, x: 100, y: 100};
  private currentRegion: ScorableDropRegion | null = null;
  private cloneParent?: ScorableDragRegion;
  private readyClone: ScorableDragRegion | null = null;

  public constructor(config: DragRegionConfig) {
    this.config = config;
    this.dragElement = document.createElement('div');
    config.parent.appendChild(this.dragElement);

    this.setupImage();

    if (this.config.frame) {
      this.initialFrame = parseFrameString(this.config.frame);
    } else {
      this.initialFrame.width = this.config.visualElement.clientWidth;
      this.initialFrame.height = this.config.visualElement.clientHeight;
    }

    if (this.dragElement && this.dragElement) {
      applyFrameToElement(this.dragElement, this.initialFrame)
    }

    this.setupDrag();

    if (this.config.makeclones) {
      this.makeClone();
    }
  }

  public updateAnchorPosition(x: number, y: number): void {
    this.initialFrame.x = x;
    this.initialFrame.y = y;

    this.dragElement.style.left = `${x}px`;
    this.dragElement.style.top = `${y}px`;

    if (this.readyClone) {
      this.readyClone.updateAnchorPosition(x, y);
    }
  }

  public getPosition(): KHPoint {
    return { x: parseFloat(this.dragElement.style.left), y: parseFloat(this.dragElement.style.top) };
  }

  private setupDrag(): void {
    if (this.dragElement) {
      // if you're the thing that makes clones you don't actually drag at all
      if (!this.config.makeclones) {
        SetDraggable(this.dragElement, this.startDrag.bind(this), undefined, this.finishDrag.bind(this));
      }
    }
  }

  private setupImage(): void {
    if (this.dragElement) {
      this.dragElement.appendChild(this.config.visualElement);
    }
  }

  private startDrag(point: KHPoint): void {
    this.resetDragRegion();

    this.dragElement.style.zIndex = '1000';
  }

  private finishDrag(point: KHPoint): void {
    const overlapRegion = this.findOverlapRegion();

    if (overlapRegion?.isValidDrop()) {
      this.currentRegion = overlapRegion;
      const pos = overlapRegion.getManipulativePosition();
      this.currentRegion.addOccupiedRegion(this);

      // in single mode snap the draggable to middle region
      if (overlapRegion.isSingle()) {
        this.dragElement.style.left = `${pos.x}px`;
        this.dragElement.style.top = `${pos.y}px`;
      } else {
        // we should find the closest point where the entire draggable will fit in the region
        const dragRect = this.dragElement.getBoundingClientRect();
        const regionRect = overlapRegion.getRect();

        const offset = this.findRectangleOffset(dragRect, regionRect);

        this.dragElement.style.left = `${parseFloat(this.dragElement.style.left) + offset.x}px`;
        this.dragElement.style.top = `${parseFloat(this.dragElement.style.top) + offset.y}px`;
      }
    } else {
      this.resetDragRegion();
      this.resetPosition();
    }

    if (this.cloneParent && this.cloneParent.readyClone === this) {
      this.cloneParent.readyClone = null;
      this.cloneParent.makeClone();
    }
  }

  private findOverlapRegion(): ScorableDropRegion | null {
    const dragRect = this.dragElement.getBoundingClientRect();
    let foundRegion: ScorableDropRegion | null = null;
    let biggestArea = 0;

    for (const region of this.config.dropRegions) {
      const rect = region.getRect();
      if (this.findArea(dragRect, rect) > biggestArea) {
        biggestArea = this.findArea(dragRect, rect);
        foundRegion = region;
      }
    }

    return foundRegion;
  }

  /**
   * Calculates the area of overlap between two DOMRects.
   * @param rect1 The first DOMRect.
   * @param rect2 The second DOMRect.
   * @returns The area of overlap between the two rectangles. Returns 0 if they don't overlap.
   */
  private findArea(rect1: DOMRect, rect2: DOMRect): number {
    // Calculate the (x, y) coordinates of the intersection rectangle's top-left and bottom-right corners
    const xOverlap = Math.max(0, Math.min(rect1.right, rect2.right) - Math.max(rect1.left, rect2.left));
    const yOverlap = Math.max(0, Math.min(rect1.bottom, rect2.bottom) - Math.max(rect1.top, rect2.top));

    // If there's no overlap in either dimension, the overlap area is 0
    if (xOverlap === 0 || yOverlap === 0) {
      return 0;
    }

    // Otherwise, the overlap area is the product of the overlaps in the x and y dimensions
    return xOverlap * yOverlap;
  }

  /**
   * Offset required to try and fit rect1 inside rect2
   * @param rect1 Rectangle to fit in rect2
   * @param rect2 target rectangle
   * @returns offset required to apply to rect1
   */
  private findRectangleOffset(rect1: DOMRect, rect2: DOMRect): { x: number, y: number } {
    let deltaX = 0;
    let deltaY = 0;

    // Calculate horizontal difference
    if (rect1.width > rect2.width) {
      // Center rect1 horizontally within rect2 if rect1 is wider
      deltaX = (rect2.x + (rect2.width / 2)) - (rect1.x + (rect1.width / 2));
    } else {
      // Move rect1 within the horizontal bounds of rect2
      if (rect1.x < rect2.x) {
        deltaX = rect2.x - rect1.x; // Align left edge
      }
      else if (rect1.right > rect2.right) {
        deltaX = rect2.right - rect1.right; // Align right edge
      }
    }

    // Calculate vertical difference
    if (rect1.height > rect2.height) {
      // Center rect1 vertically within rect2 if rect1 is taller
      deltaY = (rect2.y + (rect2.height / 2)) - (rect1.y + (rect1.height / 2));
    } else {
      // Move rect1 within the vertical bounds of rect2
      if (rect1.y < rect2.y) {
        deltaY = rect2.y - rect1.y; // Align top edge
      }
      else if (rect1.bottom > rect2.bottom) {
        deltaY = rect2.bottom - rect1.bottom; // Align bottom edge
      }
    }

    // Return the calculated differences
    return {x: deltaX, y: deltaY };
  }

  private resetDragRegion(): void {
    if (this.currentRegion) {
      this.currentRegion.removeOccupiedRegion(this);
      this.currentRegion = null;
    }
  }

  private resetPosition(): void {
    if (this.cloneParent) {
      gsap.to(this.dragElement, { left: this.initialFrame.x, top: this.initialFrame.y, duration: 0.25, ease: 'power2.inOut',
        onComplete: () => {
          this.dragElement.remove();
        }});
    } else {
      gsap.to(this.dragElement, { left: this.initialFrame.x, top: this.initialFrame.y, duration: 0.25, ease: 'power2.inOut' });
    }
  }

  private makeClone(): void {
    if (!this.readyClone) {
      const cloneConfig = { ...this.config };
      cloneConfig.makeclones = false;
      cloneConfig.frame = `${this.initialFrame.x} ${this.initialFrame.y} ${this.initialFrame.width} ${this.initialFrame.height}`;
      cloneConfig.visualElement = this.config.visualElement.cloneNode(true) as HTMLElement;
      const clone = new ScorableDragRegion(cloneConfig);
      clone.cloneParent = this;
      this.readyClone = clone;
    }
  }
}
