import { Component, Input } from '@angular/core';
import { fabric } from 'fabric';
import { FabricDraggable } from '../../../../utils/fabric-utils/fabric-draggable';
import { TEMPLATE_PREFIX } from '../../../../environments/locale-config';

@Component({
  selector: 'kh-draggable',
  templateUrl: TEMPLATE_PREFIX + 'draggable.component.html',
  styleUrls: ['./draggable.component.less']
})
export class DraggableComponent {
  @Input() public src!: string;
  @Input() public name!: string;
  @Input() public value!: string;
  @Input() public hasOutline = false;
  @Input() public initialPosition?: string;
  public left = 0;
  public top = 0;
  private BGRect!: fabric.Rect;
  private OutlineRect!: fabric.Rect;

  constructor() {
  }

  public getDraggable(): FabricDraggable {
    this.setGroupObjects();
    return new FabricDraggable({
      value: this.value ? this.value : this.name,
      src: this.src,
      hasOutline: this.hasOutline,
      initialPosition: this.initialPosition,
      BGRect: this.BGRect,
      OutlineRect: this.OutlineRect
    });
  }

  public setOutline(hasOutline: boolean): void {
    if (hasOutline) {
      this.BGRect.fill = '#ffffff';
      //@ts-ignore
      this.OutlineRect.set('hasOutline', hasOutline);
      this.OutlineRect.set('strokeWidth', 3);
      if (!this.hasOutline) {
        this.OutlineRect.set('left', this.left - 1.5);
        this.OutlineRect.set('top', this.top - 1.5);
        this.hasOutline = hasOutline;
      }
    }
    else {
      this.BGRect.fill = 'rgba(0,0,0,0)';
      //@ts-ignore
      this.OutlineRect.set('hasOutline', hasOutline);
      this.OutlineRect.set('strokeWidth', 0);
      if (this.hasOutline) {
        this.OutlineRect.set('left', this.left + 1.5);
        this.OutlineRect.set('top', this.top + 1.5);
        this.hasOutline = hasOutline;
      }
    }
  }

  private setGroupObjects(): void {
    this.BGRect = new fabric.Rect({
      fill: 'rgba(0,0,0,0)',
      strokeWidth: 0,
      hasControls: false,
      hasBorders: false,
      left: 0,
      top: 0,
    });
    this.OutlineRect = new fabric.Rect({
      fill: 'rgba(0,0,0,0)',
      strokeLineJoin: 'round',
      stroke: '#0fade1',
      strokeWidth: 0,
      rx: 5,
      ry: 5,
      hasControls: false,
      hasBorders: false,
      left: 0,
      top: 0,
    });

    const objects = [this.BGRect, this.OutlineRect];
    this.setOutline(this.hasOutline);
  }

}
