/* eslint-disable complexity */
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild, Inject } from '@angular/core';
import { GraphAxisData, GraphingConfig, GraphLineData, GraphLineType, GraphPointData, SvgGraphing } from './SvgGraphing';
import { IScorable, ScorableData } from '../manipulatives/kh-manip-scorable/IScorable';
import { ILogger, LOGGER_TOKEN } from '../../../services/ILogger';
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from '../../../services/IQuestionEvents';
import { SvgRenderer } from '../../../LearningWorlds/svg-components';
import { ManipFrame, parseFrameString, applyFrameToElement } from '../../../utils/utils';

@Component({
  selector: 'kh-manip-graph',
  template: `
  <svg #graphSvg></svg>
  <div #contentDiv>
    <ng-content></ng-content>
  </div>
 `
  })
export class KhManipGraphComponent implements IScorable, AfterViewInit, OnDestroy {
  public onChangeCallback: ((data: ScorableData) => void) | undefined;
  @ViewChild('graphSvg') public svgEl!: ElementRef<SVGElement>;
  @ViewChild('contentDiv') public content!: ElementRef<HTMLDivElement>;
  @Input() public zoomSteps=[];
  @Input('frame') public frame = '0 0 500 500';
  @Input('gridFrame') public gridFrame = '0 0 500 500';
  @Input('camera') public camera: 'locked' | 'free' = 'locked';
  @Input('origin') public origin = '0,0';
  @Input('backgroundImage') public backgroundImage = '';
  @Input('hideGrid') public hideGrid = false;
  @Input('color') public color = 'grey';

  private graphRenderer!: SvgGraphing;
  private gridPosResult!: ManipFrame;
  private parentFrame!: ManipFrame;

  public constructor(
    @Inject(LOGGER_TOKEN) protected readonly logger: ILogger,
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService) {
    questionEventsService.hideInputOnlyForManip.next(true);
  }

  public ngAfterViewInit(): void {
    this.parentFrame = parseFrameString(this.frame);
    this.gridPosResult = parseFrameString(this.gridFrame);

    const renderer = new SvgRenderer(this.svgEl.nativeElement, this.parentFrame.width, this.parentFrame.height);

    // Splitting on either a space or a comma
    const originResult = this.origin.split(/[ ,]+/);
    this.origin = `${originResult[0]},${originResult[1]}`;

    const config = this.parseChildComponents();

    if (this.svgEl.nativeElement) {
      applyFrameToElement(this.svgEl.nativeElement, this.frame);
    }

    this.graphRenderer = new SvgGraphing(renderer, renderer.svg.node, config);
    this.graphRenderer.onChangeCallback = this.graphChangeCallback.bind(this);
  }

  private graphChangeCallback(data: ScorableData): void {
    if (this.onChangeCallback) {
      this.onChangeCallback(data);
    }
  }

  public getScorableData(): ScorableData {
    return this.graphRenderer.getScorableData();
  }

  public setScorableData(data: ScorableData): void {
    this.graphRenderer?.setScorableData(data);
  }

  private parseChildComponents(): GraphingConfig {
    const childContents = this.content?.nativeElement.children;

    const parsedOrigin = this.origin.split(',');
    const originX = Number(parsedOrigin[0]);
    const originY = Number(parsedOrigin[1]);

    const config: GraphingConfig = {
      axisX: {
        labelInterval: 1,
        strokeWidth: 2,
        tickInterval: 1,
        type: 'x',
        unit: '60px'
      },
      axisY: {
        labelInterval: 1,
        strokeWidth: 2,
        tickInterval: 1,
        type: 'x',
        unit: '60px'
      },
      backgroundImage: this.backgroundImage,
      camera: this.camera,
      color: this.color,
      graphFrame: this.gridPosResult,
      hideGrid: this.hideGrid,
      lines: [],
      originX: originX,
      originY: originY,
      parentFrame: this.parentFrame,
      points: []
    };

    for (let i = 0; i < childContents.length; i++) {
      const curEl = childContents[i];
      const tagName = curEl.tagName.toLowerCase();

      if (tagName === 'axis') {
        const axis = this.parseAxis(curEl);

        if(axis.type === 'x') {
          config.axisX = axis;
        } else if (axis.type === 'y') {
          config.axisY = axis;
        }
      } else if (tagName=== 'point') {
        config.points.push(this.parsePoint(curEl));
      } else if (tagName === 'line') {
        config.lines.push(this.parseLine(curEl));
      }
    }

    return config;
  }

  private parseAxis(element: Element): GraphAxisData {
    return {
      labelInterval: Number(element.getAttribute('labels-every')) || 1,
      strokeWidth: Number(element.getAttribute('grid-stroke')) || 2,
      tickInterval: Number(element.getAttribute('labels-every')) || 1,
      type: element.getAttribute('type') as GraphLineType || 'x',
      unit: element.getAttribute('unit') || '60'
    };
  }

  private parsePoint(element: Element): GraphPointData {
    const points = element.getAttribute('pos') || '0,0';
    const pointSplit = points.split(',');

    if (pointSplit.length < 2) {
      this.logger.warn('Points must be comma delimited');
    }

    const px = pointSplit[0];
    const py = pointSplit[1];

    const data: GraphPointData = {
      alias: element.getAttribute('alias') || '',
      color: element.getAttribute('color') || 'black',
      highlightColor: element.getAttribute('highlightColor') || '',
      key: element.getAttribute('key') || '',
      label: element.getAttribute('label') || '',
      radius: Number(element.getAttribute('radius')) || 1,
      scoreBy: element.getAttribute('scoreBy') || '',
      strokeWidth: Number(element.getAttribute('stroke')) || 1,
      x: Number(px),
      y: Number(py) * -1 // y are flipped as SVGs use top right 0,0 cartesian coordinates (ie, up is down)
    }

    return data;
  }

  private parseLine(element: Element): GraphLineData {
    const points = element.getAttribute('points') || '0,0';
    const pointSplit = points.split(',');

    if (pointSplit.length < 2) {
      this.logger.warn('Points must be comma delimited');
    }

    const key1 = pointSplit[0];
    const key2 = pointSplit[1];

    const data: GraphLineData = {
      color: element.getAttribute('color') || 'grey',
      key: element.getAttribute('key') || '',
      point1: key1 || '',
      point2: key2 || '',
      score: element.getAttribute('score') || '',
      scoreBy: element.getAttribute('scoreBy') || '',
      segment: element.getAttribute('segment') === 'true' ? true : false,
      strokeWidth: Number(element.getAttribute('strokewidth')) || 1
    }

    return data;
  }

  ngOnDestroy(): void {
    // clean up cause i'm paranoid about sticky states in this app
    this.questionEventsService.hideInputOnlyForManip.next(false);
  }
}
