import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { FabricIntInputOvalRegion } from '../../../../utils/fabric-utils/fabric-int-input-oval-region';
import { FabricIntInputRectRegion } from '../../../../utils/fabric-utils/fabric-int-input-rect-region';
import { IFabricRect } from '../../../../utils/fabric-utils/models/fabric-rect';
import { ManipulativeChildObject } from '../../../models/manipulative-child-object.model';
import { RegionObject } from '../region-object';


gsap.registerPlugin(Draggable);

export enum RegionOriginMode {
  TOP_LEFT,
  BOT_LEFT
}

@Component({
  selector: 'kh-int-input-region',
  styleUrls: ['./int-input-region.component.less'],
  templateUrl: './int-input-region.component.html'
})
export class IntInputRegionComponent extends ManipulativeChildObject implements OnInit, RegionObject {
  @ViewChild('keyboard') public keyboard!: ElementRef<HTMLElement>;

  @Input() public width = 0;
  @Input() public height = 0;
  @Input() public position = '';
  @Input() public hasOutline = false;
  @Input() public itemOrder!: string;
  @Input() public background?: string;
  @Input() public shape?: string = '';
  @Input() public maxDigits = Infinity;
  @Input() public brokenKeys: number[] = [];
  @Input() public fontFamily = 'Arial';
  @Input() public digitSize = '30';
  @Input() public ignoreInScore = false;
  @Input() public stickTo = '';
  public order = 1;

  public showInputKeypad = false;
  public value = '';
  public region!: FabricIntInputOvalRegion | FabricIntInputRectRegion;
  public keyboardPositionSet = false;
  public keyboardLeft = 0;
  public keyboardTop = 0;

  public canvasRect!: IFabricRect;
  public regionRect!: IFabricRect;
  public draggable: Draggable[] | null = null;

  private x = 0;
  private y = 0;
  private origin = RegionOriginMode.BOT_LEFT;
  private parentOffset: number = 0;

  public constructor(private readonly hostElement: ElementRef){
    super();
  }

  public setOriginMode(origin: RegionOriginMode): void {
    this.origin = origin;
  }

  public setOffset(offset: number): void {
    this.parentOffset = offset;
  }

  public wrapperStyles(): Record<string, any> {
    return {
      'left': `${this.keyboardLeft}px`,
      'top': `${this.keyboardTop}px`,
      'visibility': this.keyboardPositionSet ? 'visible' : 'hidden'
    };
  }

  public ngOnInit(): void {
    const arrayPosition = this.position.replace(/[']/g, '').split(',');
    this.x = parseInt(arrayPosition[0], 10);
    this.y = parseInt(arrayPosition[1], 10);
  }

  /**
   * This is horrifying - getters should not be setting required state values
   */
  public getRegion(height: number): FabricIntInputRectRegion {
    const fontFamily = this.fontFamily ?? 'Arial';
    const fontSize = this.digitSize && parseFloat(this.digitSize) / 100 || .8;

    this.region = new (this.shape === 'oval'? FabricIntInputOvalRegion : FabricIntInputRectRegion )({
      _fontFamily: fontFamily,
      _fontSize: fontSize,
      background: this.background,
      height: +this.height,
      ignoreInScore: this.ignoreInScore,
      itemOrder: this.itemOrder,
      left: this.x,
      outline: this.hasOutline,
      top: height - this.y - Number(this.height) / 2,
      width: +this.width
    }, {
      onClose: this.onClose,
      onInput: this.showInput
    });

    return this.region;
  }

  public get isLoaded(): Observable<boolean> {
    return of(true);
  }

  public get fabricObject(): FabricIntInputRectRegion[] {
    const fontFamily = this.fontFamily ?? 'Arial';
    const fontSize = this.digitSize && parseFloat(this.digitSize) / 100 || .8;

    this.region = new (this.shape === 'oval'? FabricIntInputOvalRegion : FabricIntInputRectRegion )({
      width: +this.width,
      height: +this.height,
      top: this.y,
      left: this.x,
      outline: this.hasOutline,
      itemOrder: this.itemOrder,
      background: this.background,
      _fontFamily: fontFamily,
      _fontSize: fontSize,
      ignoreInScore: this.ignoreInScore,
    }, {
      onInput: this.showInput,
      onClose: this.onClose
    });

    return [this.region];
  }

  public valueChanged(val: string): void {
    let text;
    if (!val) {
      text = ''
    }
    else if (val == '00' || val=='0') {
      text = '0';
    }
    else {
      text = val.replace(/^0+/, '').toString()
    }

    this.value = text;
    this.region.setText(text);
  }

  public onClose = () => {
    this.showInputKeypad = false;
    this.region.endInput();
    this.draggable && this.draggable[0].kill();
    this.keyboardPositionSet = false;
  };

  public showInput = (regionRect: IFabricRect, canvasRect: IFabricRect) => {
    this.canvasRect = canvasRect;
    this.regionRect = regionRect;
    this.showInputKeypad = true;
  };

  public setupPosition() {
    const container = this.hostElement.nativeElement.closest('.gameplayQuestionText');

    if (!this.keyboard) {
      return;
    }

    let maxX = window.innerWidth - 10 - this.keyboard.nativeElement.offsetWidth;
    let minX = 10;

    if (container) {
      maxX = container.offsetLeft + container.offsetWidth - 10 - this.keyboard.nativeElement.offsetWidth;
      minX = container.offsetLeft + 10;
    }

    minX -= this.canvasRect.left;
    maxX -= this.canvasRect.left;

    this.keyboardLeft = Math.min(Math.max(minX, this.regionRect.left - this.keyboard.nativeElement.offsetWidth / 2), maxX);

    if (this.regionRect.top + this.regionRect.height + this.canvasRect.top + this.keyboard.nativeElement.offsetHeight > window.innerHeight) {
      this.keyboardTop = this.regionRect.top - this.keyboard.nativeElement.offsetHeight;
    }
    else {
      this.keyboardTop = this.regionRect.top + this.regionRect.height;
    }

    this.keyboardPositionSet = true;

    if (this.stickTo) {
      const left = Number(this.stickTo.replace(/[']/g, '').split(',')[0]);
      const top = Number(this.stickTo.replace(/[']/g, '').split(',')[1]);

      const targetHeight = this.region.canvas?.height! * (1 / this.getCanvasScale());
      let calculatedTop = (top + this.parentOffset) * this.getCanvasScale();

      if (this.origin === RegionOriginMode.BOT_LEFT) {
        calculatedTop = (targetHeight - top - this.parentOffset) * this.getCanvasScale();
      }
    
      this.keyboardLeft = left * this.getCanvasScale();
      this.keyboardTop = calculatedTop;
      
      this.createDraggableStickTo(left, top, this.region.canvas?.getElement()?.parentElement as any);
    } else {
      const left = -this.canvasRect.left + 10;
      const top = -this.canvasRect.height - this.canvasRect.top + this.regionRect.top;
      this.createDraggable(left, top);
    }
  }

  private getCanvasScale(): number {
    if (this.region.canvas) {
      return this.region.canvas.getZoom();
    }

    return 1;
  }

  public createDraggable(left: number, top: number): void {
    this.draggable = Draggable.create(this.keyboard.nativeElement, {
      allowEventDefault: true,
      bounds: {
        height: Math.max(document.body.clientHeight, window.innerHeight),
        left: left,
        top: top,
        width: window.innerWidth - 20
      },
      dragClickables: true,
      edgeResistance: 0
    });
  }

  public createDraggableStickTo(left: number, top: number, parent?: HTMLElement): void {
    const bounds = {
      height: Math.max(document.body.clientHeight, window.innerHeight),
      left: 0,
      top: 0,
      width: window.innerWidth - 20
    };

    if (parent) {
      parent.appendChild(this.keyboard.nativeElement);
    }

    this.draggable = Draggable.create(this.keyboard.nativeElement, {
      allowEventDefault: true,
      bounds,
      dragClickables: true,
      edgeResistance: 0
    });
  }
}
