import {
  Component,
  Input,
} from "@angular/core";
import { ScorableData } from "../kh-manip-scorable/IScorable";
import { DynamicContentChild, OnDynamicData, OnDynamicMount } from "ngx-dynamic-hooks";
import { KhManipulativeForwardComponent } from "../kh-manip-forward/kh-manip-forward.component";
import { KhManipulativeBackwardComponent } from "../kh-manip-backward/kh-manip-backward.component";
@Component({
  selector: "kh-manipulative-window-controls",
  templateUrl: "kh-manip-scorable-window-controls.component.html",
  styleUrls: ["kh-manip-scorable-window-controls.component.less"],
})
export class KhManipulativeWindowControlsComponent implements OnDynamicMount {
  @Input("key") public key: string = "";
  @Input("background") public background: string = "";

  public onForwardClick: () => void;
  public onBackwardClick: () => void;
  public clickCallback: undefined | ((data: ScorableData) => void);

  public onDynamicMount(data: OnDynamicData): void {
    const contentChildren: DynamicContentChild[] = data.contentChildren ?? [];

    contentChildren.forEach((c, index) => {
      const curComponent = c.componentRef.instance;
      
      if (curComponent instanceof KhManipulativeForwardComponent) {
        curComponent.onClick = this.forwardClick.bind(this);
      } else if (curComponent instanceof KhManipulativeBackwardComponent) {
        curComponent.onClick = this.backwardClick.bind(this);
      }
    });
  }

  private forwardClick(): void {
    if (this.onForwardClick) {
      this.onForwardClick();
    }
  }

  private backwardClick(): void {
    if (this.onBackwardClick) {
      this.onBackwardClick();
    }
  }
}
