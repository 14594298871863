/* eslint-disable max-len */
import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import { OnDynamicData, OnDynamicMount } from 'ngx-dynamic-hooks';
import { PuzzleAttempt, PuzzleState } from './puzzle.model';
import { PuzzleBase } from './puzzleBase';
import { Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PuzzleEventService } from '../services/puzzle-event.service';
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from '../../services/IQuestionEvents';
import { ANALYTICS_SERVICE_TOKEN, IAnalyticsService } from '../../services/IAnalyticsService';
import { IAuthenticationDataService, AUTH_DATA_SERVICE_TOKEN } from '../../services/IAuthenticationDataService';
import { PuzzleAnalytics } from './PuzzleAnalytics';

/**
 * The base class for all angular puzzle components.
 * At the moment it doesn't do a lot but track puzzle answer changes. It's designed to be extended.
 */
@UntilDestroy()
@Component({
  selector: 'puzzle-base-component',
  template: ''
})
export class PuzzleBaseComponent implements OnDynamicMount {
  @Input() public disablePuzzleSubmit = true;
  @Input() public submitFreeformAnswer: any;
  @Input() public dynamicChildren: HTMLElement;
  @Output() public valueChange = new EventEmitter<{ answer: string, state: string }>();
  @Output() public onPlayClick = new EventEmitter<void>();

  public enableSubmitButton = false;
  private curAnswer: any;
  protected puzzle?: PuzzleBase | null;
  protected onPixiStart?: () => void = () => {};
  protected onPixiStop?: () => void = () => {};
  protected puzzleAnalytics: PuzzleAnalytics;
  private playSubscription?: Subscription;
  private submitSubscription?: Subscription;
  private tryAgainSubscription?: Subscription;
  private puzzleHelpSubscription?: Subscription;
  private pixiStartSubscription?: Subscription;
  private puzzleStartTime: number;
  private questionId = '';
  private helpClickCount = 0;

  // private answerAttempts = 0;
  private enableSubmitAfterTries = 2;

  public constructor(
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService,
    protected puzzleEventService: PuzzleEventService,
    @Inject(ANALYTICS_SERVICE_TOKEN) protected readonly analyitcsService: IAnalyticsService,
    @Inject(AUTH_DATA_SERVICE_TOKEN) protected readonly authenticationDataService: IAuthenticationDataService,
    puzzle?: PuzzleBase) {
    this.setPuzzle(puzzle);
    this.puzzleStartTime = Date.now();
  }

  public onDynamicMount(data: OnDynamicData): void {
    this.questionId = data.context?.Question?.QuestionId || '';
    const userId = this.authenticationDataService.getAuthData()?.UserId || '';
    this.puzzleAnalytics = new PuzzleAnalytics(this.analyitcsService, userId, this.questionId);
  }

  public awaitUserBeginForPixi(): void {
    this.puzzleEventService.awaitingPixiInitialize.next(true);
  }

  public setPuzzle(puzzle?: PuzzleBase): void {
    this.puzzle = puzzle;

    if (this.puzzle) {
      this.puzzleAnalytics?.refreshPuzzleStartTime();
      this.puzzle.onPuzzleAnimationEnd = this.doneAnimating.bind(this);
      this.puzzle.onAnswerUpdate = this.onAnswerUpdate.bind(this);
      this.puzzle.onPlayEnable = this.playEnabled.bind(this);

      this.puzzle.onPuzzleOverlayConnect();
    }
  }

  public ngOnInit(): void {
    this.puzzleEventService.disablePlay.next(false);
    this.puzzleEventService.isPuzzle.next(true);
    this.questionEventsService.hideInputOnlyForManip.next(true);
    this.questionEventsService.disableButtonForPuzzle.next(true);
    this.puzzleEventService.isDisplayingResults.next(false)
    this.submitSubscription = this.questionEventsService.puzzleSubmit$.pipe(untilDestroyed(this)).subscribe(() => {this.submitClicked();});
    this.playSubscription = this.puzzleEventService.puzzlePlay$.pipe(untilDestroyed(this)).subscribe(() => {this.playClicked();});
    this.tryAgainSubscription = this.puzzleEventService.puzzleTryAgain$.pipe(untilDestroyed(this)).subscribe(() => {this.tryAgainClicked(); });
    this.puzzleHelpSubscription = this.puzzleEventService.puzzleHelp$.pipe(untilDestroyed(this)).subscribe(() => {this.puzzleHelpClicked();});
    
    this.pixiStartSubscription = this.puzzleEventService.awaitingPixiInitialize$.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value === false && this.onPixiStart && !this.puzzle) {
        this.onPixiStart();
      } else if (value === true && this.onPixiStop && this.puzzle) {
        this.onPixiStop();
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.puzzle) {
      this.puzzle.destroy();
      this.puzzle = null;
    }

    this.submitSubscription?.unsubscribe();
    this.playSubscription?.unsubscribe();
    this.tryAgainSubscription?.unsubscribe();
    this.puzzleHelpSubscription?.unsubscribe();
    this.questionEventsService.disableButtonForPuzzle.next(false);
  }

  protected doneAnimating(): void {
    this.puzzleEventService.isPlaying.next(false);
    this.puzzleEventService.isDisplayingResults.next(true);
  }

  protected playEnabled(enabled: boolean): void {
    this.puzzleEventService.disablePlay.next(!enabled);
  }

  protected submitClicked(): void {
    this.submitFreeformAnswer(this.curAnswer);

    if (this.puzzle) {
      const incorrectIdx = this.puzzle.getIncorrectIndex();

      if (incorrectIdx >= 1) { // remember we are 0 indexed so 1 is the 2nd attept
        this.puzzleAnalytics.sendAnalyticsTwoAttempts(incorrectIdx);
      }
    }
  }

  protected playClicked(): void {
    if (this.puzzle) {
      this.puzzleEventService.isPlaying.next(true);
      this.puzzle.onPuzzlePlay();
      this.puzzleAnalytics.sendAnalyticsTimeToPlay();
    }
  }

  protected puzzleHelpClicked(): void {
    if (this.puzzle) {
      this.puzzle.onPuzzleHelp();
      ++this.helpClickCount;

      if (this.helpClickCount === 3) {
        this.puzzleAnalytics.sendAnalyticsInactiveClicks();
      }
    }
  }

  protected tryAgainClicked(): void {
    this.puzzleEventService?.isDisplayingResults.next(false);
    this.puzzle?.onPuzzleTryAgain();
  }

  public onAnswerUpdate(ans: string, state: PuzzleState<PuzzleAttempt>): void {
    const answer = { answer: ans, state: JSON.stringify(state) };
    this.curAnswer = answer;
    this.puzzleEventService.showNextButton.next(this.shouldEnableSubmitButton(state));
    this.puzzleEventService.isCorrect.next(this.isLatestCorrect(state));
    this.valueChange.emit(answer);
  }

  /**
   * Returns true if the submit button for a puzzle should be enabled. That is, if the user has attempted the puzzle
   * the defined number of attempts in enableSubmitAfterTries or it is correct
   * @param state
   * @returns
   */
  private shouldEnableSubmitButton(state: PuzzleState<PuzzleAttempt>) {
    return state.attempts.length >= this.enableSubmitAfterTries || this.isAnyCorrect(state);
  }

  private isLatestCorrect(state: PuzzleState<PuzzleAttempt>): boolean {
    const lastIdx = state.attempts.length -1;

    return state.attempts[lastIdx].correct;
  }

  /**
   * Returns true if any attempt in the puzzle state was flagged as correct
   * @param state
   * @returns
   */
  protected isAnyCorrect(state: PuzzleState<PuzzleAttempt>): boolean {
    for (let i = 0; i < state.attempts.length; i++) {
      if (state.attempts[i].correct) {
        return true;
      }
    }

    return false;
  }
}
