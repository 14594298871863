import { gsap } from 'gsap';
import { PuzzleBase } from '../puzzle/puzzleBase'

/**
 * A minimal implementation of a puzzle for you to work off of when you make a new puzzle
 */
export class PuzzleTemplateLogicMinimal extends PuzzleBase {
  /**
   * Create a new puzzle
   */
  constructor(svgElement: SVGElement) {
    super({attempts: []}, svgElement);

    // hook up any elements in here. Eg if you need to point to a button or something in your component and want to store a reference to them you can do it here

    // A good way to query items inside the SVG is to do it like this
    // this.svgRenderer.svg.node.querySelector('#myElement');
    // This is the same as document.getElementById() but has support for SVGElements and makes typescript a lot happier
  }

  /**
   * This is called when the "play" button in the overlay is clicked
   */
  public onPuzzlePlay(): void {
    this.playAnimation();

    // do any additional logic that happens when play is clicked here
  }

  /**
   * This is called when the user tries again
   */
  public onPuzzleTryAgain(): void {
    // Do any code that needs to reset animations or states of things here
    this.playIntroAnimation();
  }
  
  /**
   * This is called if the user attempts to press "submit" while disabled
   */
  public onPuzzleHelp(): void { 
    // Do an animation that highlights how the user interacts with this puzzle here
  }

  /**
   * Called once the overlay is successfully "hooked up" to this puzzle
   * Think of it as an "on load" or "on ready" call. Once this is done you are ready to manipulate the DOM.
   * You probably will not need to add logic here.
   */
  public onPuzzleOverlayConnect(): void {
    super.onPuzzleOverlayConnect();
    this.playIntroAnimation();
  }

  private playIntroAnimation(): void {
    // Disable the play button while the intro animation plays:
    this.enablePlayButton(false);

    // Do the intro animation here

    // after the intro animation is done, call this:
    this.enablePlayButton(true);
  }

  private enablePlayButton(enabled: boolean): void {
    if (this.onPlayEnable) {
      this.onPlayEnable(enabled);
    }
  }

  private playAnimation(): void {
      // Put the puzzle feedback animation here

      // AFTER  the animation is done, do this:
      if (this.onPuzzleAnimationEnd) {
        // signal to the overlay the feedback animation is complete
        this.onPuzzleAnimationEnd();

        // Do whatever logic you need to figure out if the answer is right or not
        const isAnswerCorrect = false;

        // This tracks the attempt history and if the answer was correct or not.
        this.recordAnswerAttempt(isAnswerCorrect);
      }
    }
}
