import { IScorable, ScorableData } from './IScorable';

export class ScorableTracker {
  public scorableObjects: IScorable[] = [];

  public getAnswerData(): ScorableData {
    const data: ScorableData = {answer: {}, state: {}};

    this.scorableObjects.forEach(element => {
      const elData = element.getScorableData();
      data.answer = {...data.answer, ...elData.answer};
      data.state = {...data.state, ...elData.state};
    });

    return data;
  }

  public setData(data: ScorableData): void {
    this.scorableObjects.forEach(element => {
      element.setScorableData(data);
    });
  }

  public addScorable(o: IScorable): void {
    this.scorableObjects.push(o);
  }
}
