import { fabric } from 'fabric';
import { Observable, of } from 'rxjs';
import { RegionDefaultOptions } from './constants/region-default-options';
import { FabricDraggable } from './fabric-draggable';
import { IRegionOptions } from './models/region-options';

export class FabricRectRegion extends fabric.Group {
  public _id?: string;
  public activeDraggables: FabricDraggable[];
  public type: string;
  public currentImage?: fabric.Object;
  public selected: boolean;
  public clickable: boolean;
  public itemOrder: string;
  public ignoreInScore: boolean;
  public active: boolean;
  public lastEventDate?: number;
  public lastEventType?: string;
  public defaultValue: string | null;

  private enabled: boolean;
  private dotImage?: fabric.Image;
  private defaultImg?: fabric.Image;
  private rect?: fabric.Rect;
  private dotImageSrc?: string;

  public constructor(opts?: Partial<IRegionOptions>, Obj: typeof fabric.Rect | typeof fabric.Ellipse = fabric.Rect) {
    const options = { ...RegionDefaultOptions, ...(opts || {}) };
    super([new Obj(options)], options);
    this.on('mousedown', (ev: fabric.IEvent) => this.mousedown(ev));
    this.on('added', () => this.setSelected(this.selected));
    this.active = false;
    this.enabled = true;
    this.clickable = false;
    this.itemOrder = options.itemOrder ?? '';
    this.ignoreInScore = options.ignoreInScore ?? false;
    this.type = 'region';
    this.activeDraggables = [];
    this.active = false;
    this.enabled = true;
    this.selected = false;
    this.activeDraggables = [];
    this.rect = this.getObjects()[0];

    // this verbosity is needed because we want the default value to be able to be 0
    this.defaultValue = (options.defaultValue !== null && options.defaultValue !== undefined) ? options.defaultValue : null;

    if (options.outline) {
      options.top = (options.top || 0) - 1.5;
      options.left = (options.left || 0) - 1.5;
      this.setOutline(true);
    }

    if (options.background) {
      this.rect.set('fill', options.background);
    }
  }

  public changeChoice(regions: FabricRectRegion[]): void {
    regions.forEach(region => {
      if (region.selected && region !== this) {
        region.setSelected(false);
      }
    });
  }

  public preload(): Observable<fabric.Image | undefined> {
    if (this.dotImageSrc) {
      const src: string = this.dotImageSrc;

      return new Observable(subscriber => {
        fabric.Image.fromURL(src, image => {
          this.setDot(image);
          subscriber.next(image);
          subscriber.complete();
        });
      });
    }

    return of(undefined);
  }

  public dragOut(draggable: FabricDraggable): void {
    const itemIndex = this.activeDraggables.indexOf(draggable);
    if (itemIndex >= 0) {
      this.activeDraggables.splice(itemIndex, 1);
    }
    this.canvas?.fire('region:onChange', { target: this });
    this.setStroke(false);
    draggable.stickToRegion = false;
    draggable.region = null;
  }

  public dragIn(draggable: FabricDraggable): void {
    draggable.region = this;
    this.setStroke(true);
    draggable.stickToRegion = true;
  }

  public reset(): void {
    this.activeDraggables = [];
    this.setStroke(false);

    if (this.selected) {
      this.mousedown();
    }
  }

  public drop(draggable: FabricDraggable, singleMode: boolean): void {
    const alreadyThere = this.activeDraggables.indexOf(draggable) >= 0;
    const draggableWidth = draggable.width ?? 0;
    const draggableHeight = draggable.height ?? 0;

    if (!singleMode) {
      if (!alreadyThere) {
        this.activeDraggables.push(draggable);
      }

      const { left: tLeft, top: tTop, width, height } = this.getBoundingRect(true);
      const xOffset = width - draggableWidth < 0 ? (width - draggableWidth) / 2 : 0;
      const xMin = tLeft + xOffset;
      const xMax = tLeft + width - draggableWidth - xOffset;
      const left = Math.min(xMax, Math.max(xMin, draggable.left));

      const yOffset = height - draggableHeight < 0 ? (height - draggableHeight) / 2 : 0;
      const yMin = tTop + yOffset;
      const yMax = tTop + height - draggableHeight - yOffset;
      const top = Math.min(yMax, Math.max(yMin, draggable.top));

      draggable.animateTo(left, top);
      draggable.region = this;
    }
    else {
      if (this.activeDraggables.length === 0 || alreadyThere) {
        if (!alreadyThere) {
          this.activeDraggables.push(draggable);
        }
        draggable.region = this;
        const { left, width, top, height } = this.getBoundingRect(true);
        draggable.animateTo(left + (width - draggableWidth) / 2, top + (height - draggableHeight) / 2);
      }
    }
    this.canvas?.fire('region:onChange', { target: this });
    this.active = false;
  }

  public setClickable(clickable: boolean): FabricRectRegion {
    this.clickable = clickable;
    this.set('hoverCursor', clickable ? 'pointer' : '');

    return this;
  }

  public setDot(dotImage: fabric.Image): this {
    if (!this.dotImage) {
      this.dotImage = dotImage;
      dotImage.set('hoverCursor', 'pointer');
    }

    return this;
  }

  public setDefaultImg(defaultImg: fabric.Image): this {
    if (!this.defaultImg) {
      this.defaultImg = defaultImg;
      defaultImg.set('hoverCursor', 'pointer');
      if (!this.selected) {
        this.setRegionImage(this.defaultImg)
      }
    }

    return this;
  }

  public setRegionImage(img: fabric.Image): void {
    const { left, top, width, height } = this.getBoundingRect(true);
    const center = {
      x: left + width / 2,
      y: top + height / 2
    };
    const dot = fabric.util.object.clone(img);
    dot.on('mousedown', this.mousedown.bind(this));
    dot.left = center.x - dot.width / 2;
    dot.top = center.y - dot.height / 2;
    this.canvas?.add(dot);
    this.set('currentImage', dot);
  }

  public setOutline(hasOutline: boolean): FabricRectRegion {
    this.rect?.set('strokeWidth', hasOutline ? 3 : 0);
    this.addWithUpdate();

    return this;
  }

  public setStroke(active: boolean): void {
    this.active = active;
    const highlight = active || this.activeDraggables.length > 0;
    this.rect?.set('stroke', highlight ? '#0fade1' : '#cccccc');
  }

  public setSelected(selected: boolean): void {
    this.set('selected', selected);
    const img = this.selected ? this.dotImage : this.defaultImg;
    if (this.currentImage) {
      this.canvas?.remove(this.currentImage);
      this.set('currentImage', undefined);
    }
    if (img) {
      this.setRegionImage(img)
    }

    this.canvas?.fire('region:choosen', {
      target: this
    });
  }

  public mousedown(ev?: fabric.IEvent): void {
    if (this.lastEventType !== ev?.e.type && Date.now() - (this.lastEventDate || 0) < 500) {
      return;
    }
    this.set('lastEventType', ev?.e.type);
    this.set('lastEventDate', Date.now());
    if (!this.enabled) {
      return;
    }
    if (this.clickable) {
      this.setSelected(!this.selected)
    }
  }

  private setEnabled(enabled: boolean): void {
    this.enabled = enabled;
  }
}
