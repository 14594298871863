import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse'
})
export class ReversePipe implements PipeTransform {

  transform(items: any[]): any[] {
	  if (items) {
		  return items.slice().reverse();
	  }
	  return items;
  }

}
