import { Assets, MIPMAP_MODES, Texture } from "pixi.js";
export * from '../PixiExtensions/SVGLoaderKH';

export async function loadSvg(url: string, width: number, height: number, renderScale: number, mipmap = true): Promise<Texture> {
    return Assets.load({
        src: url,
        format: 'svg',
        loadParser: 'loadSVG_KH',
        data: {
            resourceOptions: {
                width: width * renderScale,
                height: height * renderScale,
            }
        }
    }).then((resource: Texture) => {
        if (mipmap) {
            resource.baseTexture.mipmap = MIPMAP_MODES.ON;
        }

        return resource;
    });
}
