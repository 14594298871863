import { AppComponent } from 'app/app.component';
import { AppRoutingModule } from './app-routing.module';
import { AUTH_SERVICE, AuthModule } from 'ngx-auth';
import { AuthenticationDataService, AuthenticationService } from 'app/core/authentication';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CoreModule } from 'app/core/core.module';
import { CustomMissingTranslationHandler } from 'app/core/internationalization/custom-missing-translation-handler';
import { CustomRouteReuseStrategy } from 'app/core/navigation/custom-route-reuse-strategy';
import { DynamicHooksModule } from 'ngx-dynamic-hooks';
import { ErrorHandler, NgModule } from '@angular/core';
import { ExceptionService } from 'app/core/exceptions/exception.service';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LottieModule } from 'ngx-lottie';
import { LowerCaseUrlSerializer } from './core/navigation/lower-case-url-serializer';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { QuillModule } from 'ngx-quill';
import { RequestInterceptor } from './core/http/request.interceptor';
import { RouteReuseStrategy, UrlSerializer } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QUESTION_EVENTS_SERVICE_TOKEN, ANALYTICS_SERVICE_TOKEN, AUTH_DATA_SERVICE_TOKEN, LOCALIZED_TOASTER_SERVICE_TOKEN, LOGGER_TOKEN, MATH_RENDERING_SERVICE_TOKEN, MODAL_SERVICE_TOKEN, MUSIC_SERVICE_TOKEN, STUDENT_MUSIC_SERVICE_TOKEN } from 'shared-components';
import { Logger } from './core/logger/services/logger.service';
import { ModalService } from './core/modals/services/modal.service';
import { LocalizedToasterService } from './core/toaster/localized-toaster.service';
import { QuestionEventsService } from './question/components/question/question-events.service';
import { MathRenderingService } from './shared/services/math-rendering.service';
import { MusicService } from './shared/services/music.service';
import { StudentMusicService } from './student/services/student-music.service';
import { MockAnalyticsService } from './editor/services/mock-analytics-service/mock-analytics-service';

export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent
  ],
  imports: [
    AuthModule,
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    TypeaheadModule.forRoot(),
    CollapseModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 4000
    }),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    LoadingBarHttpClientModule,
    LoadingBarModule,
    DynamicHooksModule.forRoot({}),
    LottieModule.forRoot({player: playerFactory}),
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      }
    }),
    PaginationModule.forRoot(),
    QuillModule.forRoot(),
    FormsModule
  ],
  providers: [
    { provide: AUTH_SERVICE, useClass: AuthenticationService },
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor },
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    BsDatepickerConfig,
    { provide: ErrorHandler, useClass: ExceptionService },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    {provide: QUESTION_EVENTS_SERVICE_TOKEN, useExisting: QuestionEventsService},
    {provide: ANALYTICS_SERVICE_TOKEN, useExisting: MockAnalyticsService},
    {provide: AUTH_DATA_SERVICE_TOKEN, useExisting: AuthenticationDataService},
    {provide: LOCALIZED_TOASTER_SERVICE_TOKEN, useExisting: LocalizedToasterService},
    {provide: LOGGER_TOKEN, useExisting: Logger},
    {provide: MATH_RENDERING_SERVICE_TOKEN, useExisting: MathRenderingService},
    {provide: MODAL_SERVICE_TOKEN, useExisting: ModalService},
    {provide: MUSIC_SERVICE_TOKEN, useExisting: MusicService},
    {provide: STUDENT_MUSIC_SERVICE_TOKEN, useExisting: StudentMusicService}
  ]
})
export class AppModule {
  constructor() {
    console.log('AppModule loaded');
  }
}
