/*
    this is copied from pixi.js, so disable linting and ts-checks.
    This will make future revisions easier as it should more closley match the original source when PIXI updates.
*/

/* eslint-disable */
// @ts-nocheck

import { LoaderParserPriority, checkDataUrl, checkExtension, ResolvedAsset, IBaseTextureOptions, createTexture, loadTextures, LoaderParser, ExtensionType, Loader, Texture, BaseTexture, utils, settings, extensions, loadSVG } from 'pixi.js';
import { SVGResourceKH } from './SVGResourceKH';

const validSVGExtension = '.svg';
const validSVGMIME = 'image/svg+xml';

/**
 * Loads SVG's into Textures.
 */
export const loadSVGKH = {
    extension: {
        type: ExtensionType.LoadParser,
        priority: LoaderParserPriority.High,
    },

    name: 'loadSVG_KH',

    test(url: string): boolean
    {
        return checkDataUrl(url, validSVGMIME) || checkExtension(url, validSVGExtension);
    },

    async testParse(data: string): Promise<boolean>
    {
        return SVGResourceKH.test(data);
    },

    async parse(asset: string, data: ResolvedAsset<IBaseTextureOptions>, loader: Loader): Promise<Texture>
    {
        const src = new SVGResourceKH(asset, data?.data?.resourceOptions);

        await src.load();

        const base = new BaseTexture(src, {
            resolution: utils.getResolutionOfUrl(asset),
            ...data?.data,
        });

        base.resource.src = data.src;

        const texture = createTexture(base, loader, data.src);

        return texture;
    },

    async load(url: string, _options: ResolvedAsset): Promise<string>
    {
        const response = await settings.ADAPTER.fetch(url);

        return response.text();
    },

    unload: loadTextures.unload,

} as LoaderParser<Texture | string, IBaseTextureOptions>;

extensions.remove({
    type: ExtensionType.LoadParser,
    ref: loadSVG
}).add(loadSVGKH);