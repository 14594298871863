import { Injectable } from "@angular/core";
import { IAnalyticsService } from "shared-components";


@Injectable({
    providedIn: 'root'
    })
export class MockAnalyticsService implements IAnalyticsService {
    trackEventSimple(eventType: string, classId?: string, jsonData?: object): void {
        console.log(`mocking analytics track: ${eventType}\n${classId}\n${JSON.stringify(jsonData)}`);
    }
    trackEvent(event: any): void {
        console.log(`mocking analytics track: ${event}`);
    }

}