/* eslint-disable sort-keys-fix/sort-keys-fix */
import { FarmLayoutType, ParsedFarmElement } from './parsers/FarmMarkupParser';
import { FarmUnits, getFarmSpriteChildDimensions, getFarmSpriteDimensions, getFarmSpriteURL } from './FarmTypes';
import { Assets, DisplayObject, Sprite, Texture } from 'pixi.js';
import gsap from 'gsap';
import { FarmBottomMenu } from './ui/FarmBottomMenu';
import { GameEntity } from '../../pixi-puzzle-framework/PuzzleEngine/GameEntity';
import { KHPoint } from '../../pixi-puzzle-framework/PuzzleEngine/KHPoint';

export type FarmLayoutElementConfig = {
  data: ParsedFarmElement;
}

export class FarmLayoutElement extends GameEntity {
  private config: FarmLayoutElementConfig;
  private sprites: Sprite[] = [];

  public constructor(config: FarmLayoutElementConfig) {
    super();

    this.config = config;
  }

  public update(deltaTimeSeconds: number): void {
    // no update
  }

  public start(): void {
    if (this.config.data.layoutType === FarmLayoutType.FREE) {
      this.layoutFree();
    }
    else if (this.config.data.layoutType === FarmLayoutType.FRAME) {
      this.layoutFrame();
    }
  }

  public getData(): ParsedFarmElement {
    return this.config.data;
  }

  /**
   * Do the regroup animation where the sprites go to the parent "frame" sprite
   * @param regroupSprite - The parent frame sprite
   * @param positions - The positions to animate to, relative to the parent position
   */
  public async animateRegroup(unit: FarmUnits, regroupSprite: Sprite, positions: KHPoint[]): Promise<void> {
    let numToComplete = this.sprites.length;
    const dimensions = getFarmSpriteDimensions(unit);
    const myDimensions = getFarmSpriteDimensions(this.config.data.unit!);
    const childDimensions = getFarmSpriteChildDimensions(unit);

    const scaleX = regroupSprite.width / dimensions.x;
    const scaleY = regroupSprite.height / dimensions.y;
    childDimensions.x *= scaleX;
    childDimensions.y *= scaleY;

    return new Promise<void>((resolve, reject) => {
      const onComplete = () => {
        --numToComplete;

        if (numToComplete <= 0) {
          resolve();
        }
      };

      if (this.sprites.length <= positions.length) {
        for (let i = 0; i < this.sprites.length; i++) {
          const sprite = this.sprites[i];
          const curPos = {
            x: sprite.position.x, 
            y: sprite.position.y,
            width: sprite.width,
            height: sprite.height
          };

          const targetPos = {
            x: positions[i].x,
            y: positions[i].y,
            width: childDimensions.x,
            height: childDimensions.y
          };

          targetPos.x *= scaleX;
          targetPos.y *= scaleY;
          targetPos.x += regroupSprite.x;
          targetPos.y += regroupSprite.y;

          gsap.to(curPos, {x: targetPos.x, y: targetPos.y, width: targetPos.width, height: targetPos.height, duration: 1, delay: 0.2 * i,
            onUpdate: () => {
              sprite.position.set(curPos.x, curPos.y);
              sprite.width = curPos.width;
              sprite.height = curPos.height;
            },
            onComplete
          });
        }
      } else {
        console.warn('Not enough positions to animate to');
        reject();
      }
    });
  }

  public deleteSrites(): void {
    for (let i = 0; i < this.sprites.length; i++) {
      this.sprites[i].destroy();
    }
  }

  public async animateToBottomMenu(position: KHPoint, bottomMenu: FarmBottomMenu): Promise<void> {
    let numToComplete = this.sprites.length;

    return new Promise<void>((resolve, reject) => {
      for (let i = 0; i < this.sprites.length; i++) {
        const sprite = this.sprites[i];
        const curPos: KHPoint = {x: sprite.position.x, y: sprite.position.y};

        gsap.to(curPos, {x: position.x, y: position.y, duration: 1, delay: 0.2 * i,
          onUpdate: () => {
            sprite.position.set(curPos.x, curPos.y);
          },
          onComplete: () => {
            sprite.destroy();
            bottomMenu.incrementUnit(this.config.data.unit as FarmUnits);
            --numToComplete;

            if (numToComplete <= 0) {
              resolve();
            }
          }
        });
      }
    });
  }

  public getUnit(): FarmUnits {
    return this.config.data.unit as FarmUnits;
  }

  private layoutFree(): void {
    for (let i = 0; i < this.config.data.coordinates.length; i++) {
      const curCoord = this.config.data.coordinates[i];
      const sprite = this.makeSprite();
      sprite.position.set(curCoord.x, curCoord.y);
      this.addChild(sprite as DisplayObject);
    }
  }

  private layoutFrame(): void {
    const startCoord = this.config.data.coordinates[0];
    const dimensions = getFarmSpriteDimensions(this.config.data.unit as FarmUnits);

    const ratio = this.config.data.entityWidth / dimensions.x;

    for (let i = 0; i < this.config.data.count; i++) {
      const sprite = this.makeSprite();
      this.addChild(sprite as DisplayObject);

      const x = startCoord.x + ((i % 2) * this.config.data.entityWidth);
      const y = startCoord.y + (Math.floor(i / 2) * dimensions.y * ratio);

      sprite.position.set(x, y);
    }
  }

  private makeSprite(): Sprite {
    // this texture is cached so it should be basically an O(1) lookup
    const dimensions = getFarmSpriteDimensions(this.config.data.unit as FarmUnits);
    const returnSprite = new Sprite();

    const t = Assets.get<Texture>(getFarmSpriteURL(this.config.data.unit as FarmUnits));

    returnSprite.texture = t;

    const targetSpriteWidth = this.config.data.entityWidth;
    const ratio = targetSpriteWidth / dimensions.x;
    returnSprite.width = targetSpriteWidth;
    returnSprite.height = dimensions.y * ratio;

    this.sprites.push(returnSprite);

    return returnSprite;
  }

  public getEntityWidth(): number {
    return this.config.data.entityWidth;
  }
}
