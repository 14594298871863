import { Game } from "./Game";
import { GameEntity } from "./GameEntity";

export class GameComponent {
    public entity: GameEntity;
    public gameInstance: Game;

    public constructor(entity: GameEntity, gameInstance: Game) {
        this.entity = entity;
        this.gameInstance = gameInstance;
    }

    public update(deltaTime: number): void {}
    public start(): void {}
}
