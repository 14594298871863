import { Draggable, gsap } from 'gsap/all'
import { PuzzleState, PuzzleAttempt } from '../../../../LearningWorlds/puzzle/puzzle.model';
import { PuzzleBase } from '../../../../LearningWorlds/puzzle/puzzleBase';

const svgns = 'http://www.w3.org/2000/svg'

export class RegroupClass extends PuzzleBase {
  private gsvg: SVGSVGElement;
  private alternateTimeline: gsap.core.Timeline;
  private mainTimeline!: gsap.core.Timeline;

  private bundleLocation = [[29, 10.5, 1], [53.5, 11.5, -1], [10.5, 33, 1], [31, 43, -1], [58.5, 40.5, -1], [81.5, 35.5, 1], [4, 63, 1], [23.5, 75.5, -1], [44, 68, 1], [66, 68, -1]];
  private grapeLocation = [[1, 2], [7, 0], [12.5, 3], [6.5, 6], [0, 7], [4.5, 11.5], [11, 9], [5.5, 16], [11, 13.5], [9.5, 20]];
  private grapeLocationFlipped = [[11.5, 2], [5.5, 0], [0, 3], [6, 6], [12.5, 7], [8, 11.5], [1.5, 9], [7, 16], [1.5, 13.5], [3, 20]];

  private truckLocation = [[80, 55], [93, 63], [106, 71], [119, 79], [132, 87], [145, 95], [158, 103], [171, 111], [184, 119], [197, 127]];
  private barrelLocation = [[31.5, 1], [27.5, 3], [23, 5], [18.5, 8], [14, 10.5], [36.5, 4], [32.5, 6], [28, 8], [23.5, 10], [19, 12.5]];

  private startingNumber: number;
  private startingOnes: number;
  private startingTens: number;

  private regroupSpacingX = 0;

  // #region slider variables
  private sliderNumber: SVGSVGElement;
  private sliderNumberValue = 0;
  private sliderControls: SVGSVGElement;
  private controllerDraggable!: Draggable[];
  private numberDisplay: SVGSVGElement;
  private buttons: SVGSVGElement;
  private sliderBar: SVGSVGElement;
  private slider: SVGSVGElement;
  private maxText: SVGSVGElement;
  // private goButton: SVGSVGElement;
  private sliderOpen = false;
  private sliderIncrements = 0;
  private max = 500;
  private min = 0;
  // #endregion

  private animationFinished = false;
  private firstTimeOpen = true;
  private tutorialFinished = false;
  // private tryAgain: SVGSVGElement;
  private correct: SVGSVGElement;

  private onesGroup: SVGSVGElement;
  private tensGroup: SVGSVGElement;
  private hundredsGroup: SVGSVGElement;

  private boatScale = 0.5;
  private elementType: string;

  private tensElementBBox = {height: 0, width: 0, x: 0, y: 0};
  private onesElementBBox = {height: 0, width: 0, x: 0, y: 0};
  private onesElementScale = 0;
  private onesElementHref = '';
  private feedback: string;
  private sessionState: PuzzleState<PuzzleAttempt>;

  public constructor(gsvg: SVGSVGElement, type: string, tens: number, ones: number, feedback: string) {
    super({attempts: []});

    this.gsvg = gsvg;
    this.alternateTimeline = gsap.timeline();

    this.sliderNumber = this.findElement('num');
    this.sliderControls = this.findElement('sliderControls');
    this.numberDisplay = this.findElement('numberDisplay');
    this.buttons = this.findElement('buttons');
    this.sliderBar = this.findElement('sliderBar');
    this.slider = this.findElement('slider');
    this.maxText = this.findElement('maxText');
    // this.goButton = this.findElement('goButton');
    // this.tryAgain = this.findElement('tryAgain');
    this.correct = this.findElement('correct');

    this.onesGroup = this.findElement('ones');
    this.tensGroup = this.findElement('tens');
    this.hundredsGroup = this.findElement('hundreds');

    this.startingTens = tens;
    this.startingOnes = ones;
    this.elementType = type;
    this.startingNumber = Number(this.startingTens) * 10 + Number(this.startingOnes);

    this.feedback = feedback;
    this.sessionState = {} as PuzzleState<PuzzleAttempt>;
    this.sessionState.attempts = [];
    this.init();
  }

  private findElement(id: string): SVGSVGElement {
    return this.gsvg.querySelector(`#${id}`) as SVGSVGElement;
  }

  private bringToFront(obj: Element): void {
    const parent = obj.parentElement;
    parent?.removeChild(obj);
    parent?.appendChild(obj);
  }

  private redrawElements(arr: Element[]): void {
    arr.slice().reverse()
      .forEach(element => {
        this.bringToFront(element);
      });
  }

  private createGrapeBundle(x: number, y: number, flip: boolean): SVGSVGElement {
    const bundle = document.createElementNS(svgns, 'g');

    const grapeBBox = this.findElement('grape').getBBox();

    for (let i = 0; i < this.grapeLocation.length; i++) {

      const location = flip ? this.grapeLocationFlipped : this.grapeLocation;
      const newX = -grapeBBox.x + location[i][0] + x;
      const newY = -grapeBBox.y + location[i][1] + y;
      const use = this.createAtPosition('#grape', newX, newY, 1);
      bundle.appendChild(use);
    }

    this.redrawElements(Array.from(bundle.children));

    return bundle as SVGSVGElement;
  }

  private createLeaves(xSpacing: number, ySpacing: number, opacity: number): SVGUseElement {
    const leafBBox = this.findElement('leaves').getBBox();
    const leafX = xSpacing - leafBBox.x - leafBBox.width / 4;
    const leafY = ySpacing - leafBBox.y - leafBBox.height / 3;
    const leaves = this.createAtPosition('#leaves', leafX, leafY, opacity);

    return leaves
  }

  private createAtPosition(referenceId: string, x: number, y: number, opacity?: number): SVGUseElement {
    const newElement = document.createElementNS(svgns, 'use');
    const properties = { attr: { href: referenceId }, opacity: opacity, x: x, y: y };
    gsap.set(newElement, properties);

    return newElement;
  }

  private createVine(x: number, y: number): SVGSVGElement {
    const vine = document.createElementNS(svgns, 'g');

    const vinebbox = this.findElement('emptyVine').getBBox();
    const use = this.createAtPosition('#emptyVine', -vinebbox.x + x, -vinebbox.y + y, 0);

    vine.appendChild(use);

    const bundles = document.createElementNS(svgns, 'g');

    for (let i = 0; i < this.bundleLocation.length; i++) {

      const grapeBundle = this.createGrapeBundle(x, y, this.bundleLocation[i][2] === -1);
      gsap.set(grapeBundle, { visibility: 'hidden', x: this.bundleLocation[i][0], y: this.bundleLocation[i][1] });
      bundles.appendChild(grapeBundle);
    }
    vine.appendChild(bundles);

    return vine as SVGSVGElement;
  }

  private createBoat(x: number, y: number): SVGSVGElement {
    const boat = document.createElementNS(svgns, 'g');
    const truckGroup = document.createElementNS(svgns, 'g');

    const temp = this.createAtPosition('#emptyBoat', x, y, 0);
    boat.appendChild(temp);

    const truckBBox = this.findElement('truck').getBBox();

    for (let i = 0; i < this.truckLocation.length; i++) {
      const truck = this.findElement('truck').cloneNode(true);
      gsap.set(truck, { x: -truckBBox.x + this.truckLocation[i][0] + x, y: -truckBBox.y + this.truckLocation[i][1] + y });
      truckGroup.appendChild(truck);
    }
    boat.appendChild(truckGroup);
    gsap.set(boat, { scale: this.boatScale });

    return boat as SVGSVGElement;
  }

  private sumDecomposeTexts(textArr: HTMLCollection): void {
    if (textArr.length > 0) {
      const total = textArr[0];
      const totalTextX = total.getAttribute('xPos') as string;
      const totalTextY = total.getAttribute('yPos') as string;
      for (let i = 1; i < textArr.length; i++) {
        const currentText = textArr[i] as SVGSVGElement;

        this.alternateTimeline.to(currentText, {
          onComplete: () => {
            total.textContent = String(Number(total.textContent) + Number(currentText.textContent));
            currentText.remove();
          }, x: totalTextX, y: totalTextY
        });
      }
    }
  }

  private groupPlaceValues(total: SVGSVGElement, value: SVGSVGElement): void {
    if (value) {
      this.alternateTimeline.to(value, {
        delay: 0.1, onComplete: () => {
          if (total !== value) {
            total.textContent = String(Number(total.textContent) + Number(value.textContent));
            value.remove();
          }
        }, x: total.getAttribute('xPos') as string, y: total.getAttribute('yPos') as string
      });
    }
  }

  private decomposeElementToText(array: ChildNode[], textContent: string): void {
    this.alternateTimeline.to({}, {delay: 1});
    for (let i = 0; i < array.length; i++) {
      const currentEl = array[i] as SVGSVGElement;
      gsap.set(currentEl, { transformOrigin: 'center' });

      let elementBBox = currentEl.getBoundingClientRect();
      let pt = this.gsvg.createSVGPoint();
      pt.x = elementBBox.x;
      pt.y = elementBBox.y;

      pt = pt.matrixTransform(this.gsvg.getScreenCTM()?.inverse());

      const elementText = document.createElementNS(svgns, 'text');
      gsap.set(elementText, {
        alignmentBaseline: 'text-before-edge',
        fontFamily: 'Arial',
        fontSize: 16,
        fontWeight: 'bold',
        textContent: textContent
      });

      if (textContent === '100') {
        this.findElement('hundredsText').appendChild(elementText);
      }
      else if (textContent === '10') {
        this.findElement('tensText').appendChild(elementText);
      }
      else {
        this.findElement('onesText').appendChild(elementText);
      }

      elementBBox = currentEl.getBBox();

      this.alternateTimeline.to(currentEl, { duration: 0.5, scale: 0 }, '<-=0.5');

      let textX, textY;
      textX = pt.x + elementBBox.width / 2 - elementText.getBBox().width / 2;
      textY = pt.y + elementBBox.height / 2 - elementText.getBBox().height / 2;
      if (textContent === '100' && this.elementType === 'barrels') {
        textX = pt.x + elementBBox.width / 2 * this.boatScale - elementText.getBBox().width / 2;
        textY = pt.y + elementBBox.height / 2 * this.boatScale - elementText.getBBox().height / 2;
      }
      if (textContent === '1' && this.elementType === 'barrels') {
        textX = pt.x + elementBBox.width - elementText.getBBox().width / 2;
        textY = pt.y + elementBBox.height - elementText.getBBox().height / 2;
      }


      gsap.set(elementText, {
        attr: { xPos: textX, yPos: textY },
        scale: 0, transformOrigin: 'center', x: textX, y: textY
      });
      this.alternateTimeline.to(elementText, { duration: 0.5,
        onComplete: function () {
          currentEl.remove();
        }, scale: 1
      }, '<+=0.5');
    }
  }

  private wiggleGroupElement(groupElement: SVGSVGElement, timeline = gsap.timeline()): void {
    timeline.to(groupElement, { duration: 0.1, repeat: 1, x: '-=5', yoyo: true });
    timeline.to(groupElement, { duration: 0.1, repeat: 1, x: '+=5', yoyo: true });
    timeline.to(groupElement, { duration: 0.1, repeat: 1, x: '-=5', yoyo: true });
  }

  private checkAnswer(): void {
    // why was this done?
    const self = this;

    this.alternateTimeline.to(this.onesGroup, {
      onComplete: () => {
        // const attemptNumber = self.sessionState.attempts.length + 1;
        let correct: boolean;

        if (self.sliderNumberValue === self.startingNumber) {
          gsap.set(self.correct, { scale: 0, transformOrigin: 'center', visibility: 'visible' });
          self.alternateTimeline.to(self.correct, { duration: 0.5, scale: 1 });
          correct = true;
        } else {
          if (self.feedback === 'half') {

            const sliderHundredsPlace = Math.floor(self.sliderNumberValue / 100 % 10);
            const sliderTensPlace = Math.floor(self.sliderNumberValue / 10 % 10);
            const sliderOnesPlace = Math.floor(self.sliderNumberValue % 10);

            if (sliderHundredsPlace !== Math.floor(self.startingNumber / 100 % 10)) {
              self.wiggleGroupElement(self.hundredsGroup);
            }
            if (sliderTensPlace !== Math.floor(self.startingNumber / 10 % 10)) {
              self.wiggleGroupElement(self.tensGroup);
            }
            if (sliderOnesPlace !== Math.floor(self.startingNumber % 10)) {
              self.wiggleGroupElement(self.onesGroup);
            }
          }

          self.wiggleGroupElement(self.sliderNumber, self.alternateTimeline)
          // self.alternateTimeline.to(self.tryAgain, { y: 0 });
          correct = false;
        }
        // const thisAttempt = { attemptNumber: attemptNumber, correct: correct }
        // self.sessionState.attempts.push(thisAttempt);
        // const ans = String(self.getSessionString());
        this.recordAnswerAttempt(correct);

        if (self.onPuzzleAnimationEnd) {
          self.onPuzzleAnimationEnd();
        }

        // self.onAnswerUpdate?.(ans, self.sessionState);
      }
    });
  }

  private decompose(): void {
    if ('full' === this.feedback || this.startingNumber === this.sliderNumberValue) {
      const remainingHundreds = Array.from(this.hundredsGroup.childNodes) as ChildNode[];
      const remainingTens = Array.from(this.tensGroup.childNodes) as ChildNode[];
      const remainingOnes = Array.from(this.onesGroup.childNodes) as ChildNode[];

      this.decomposeElementToText(remainingHundreds, '100');
      this.decomposeElementToText(remainingTens, '10');
      this.decomposeElementToText(remainingOnes, '1');

      const hundredsTextArr = this.findElement('hundredsText').children;
      const tensTextArr = this.findElement('tensText').children;
      const onesTextArr = this.findElement('onesText').children;

      this.sumDecomposeTexts(hundredsTextArr);
      this.sumDecomposeTexts(tensTextArr);
      this.sumDecomposeTexts(onesTextArr);

      const displayBBox = this.findElement('displayBox').getBBox();

      let total;

      if (hundredsTextArr.length > 0) {
        total = hundredsTextArr[0] as SVGSVGElement;
      }
      else if (tensTextArr.length > 0) {
        total = tensTextArr[0] as SVGSVGElement;
      }
      else {
        total = onesTextArr[0] as SVGSVGElement;
      }
      const ten = tensTextArr[0] as SVGSVGElement;
      const one = onesTextArr[0] as SVGSVGElement;
      if (ten) {
        this.alternateTimeline.to(ten, { delay: 0.1, y: Number(total.getAttribute('yPos')) });
      }

      if (one) {
        this.alternateTimeline.to(one, { y: Number(total.getAttribute('yPos')) });
      }
      this.groupPlaceValues(total, ten);
      this.groupPlaceValues(total, one);

      const totalBBox = total.getBBox();
      this.alternateTimeline.to(total, {
        delay: 0.1,
        x: displayBBox.x + displayBBox.width / 2 - totalBBox.width / 2,
        y: displayBBox.y + displayBBox.height + 40
      });

    }
    this.checkAnswer();
  }

  private animateRemainingEls(elementArr: ChildNode[], timeline: gsap.core.Timeline, scale: number): void {
    if (elementArr.length > 0) {
      let columnCounter = 0;
      let ySpacing = 80;
      for (let i = Math.floor(elementArr.length / 10) * 10; i < elementArr.length; i++) {
        const currentEl = elementArr[i] as SVGSVGElement;
        const currentElBBox = currentEl.getBBox();

        const newX = -currentElBBox.x * scale + this.regroupSpacingX;
        const newY = -currentElBBox.y * scale + ySpacing;

        timeline.to(currentEl, { duration: '1', x: newX, y: newY }, '<+=0.09');

        ySpacing += currentEl.getBBox().height * scale + 5;

        columnCounter++;
        if (columnCounter % 5 === 0 || i === elementArr.length - 1) {
          this.regroupSpacingX += currentElBBox.width * scale + 5;
          if (columnCounter % 10 === 0) {
            this.regroupSpacingX += 15;
          }
          ySpacing = 80;
        }
      }
    }
  }

  private animateOnesToTens(timeline: gsap.core.Timeline): void {

    const tensGroupBBox = this.tensGroup.getBBox();
    this.regroupSpacingX = tensGroupBBox.x + tensGroupBBox.width + 25;
    if (Number(this.startingTens) === 0) {
      this.regroupSpacingX += 100;
    }
    let ySpacing = 80;
    let columnCounter = 0;

    for (let i = 0; i < Math.floor(this.startingOnes / 10); i++) {
      let tenElement, tenElementChildren: Element[];
      if (this.elementType === 'grapes') {
        tenElement = document.createElementNS(svgns, 'g');

        const leaves = this.createLeaves(this.regroupSpacingX, ySpacing, 0);
        tenElement.appendChild(leaves);

        const bundle = this.createGrapeBundle(0, 0, false);
        gsap.set(bundle, { visibility: 'hidden', x: this.regroupSpacingX, y: ySpacing });
        tenElement.appendChild(bundle);

        this.tensGroup.appendChild(tenElement);
        timeline.to(leaves, { duration: 0.5, opacity: 1 });

        tenElementChildren = Array.from(bundle.children).reverse();
      }
      else {
        tenElement = this.findElement('truck').cloneNode(true) as SVGSVGElement;
        tenElementChildren = Array.from((gsap.utils.toArray('.barrels', tenElement)[0] as SVGSVGElement).children);
        for (let j = 0; j < 10; j++) {
          gsap.set(tenElementChildren[j], { visibility: 'hidden' });
        }
        const tenElementPositionX = -this.tensElementBBox.x + this.regroupSpacingX;
        const tenElementPositionY = -this.tensElementBBox.y + ySpacing;
        gsap.set(tenElement, { opacity: 0, visibility: 'visible', x: tenElementPositionX, y: tenElementPositionY });
        this.tensGroup.appendChild(tenElement);

        timeline.to(tenElement, { duration: 0.5, opacity: 1 });
      }

      for (let j = 0; j < 10; j++) {

        const currentOneElement = this.onesGroup.children[i * 10 + j] as SVGSVGElement;
        const currentElementBBox = currentOneElement.getBBox();

        let newX, newY;
        if (this.elementType === 'grapes') {
          newX = -currentElementBBox.x + this.grapeLocation[j][0] + this.regroupSpacingX;
          newY = -currentElementBBox.y + this.grapeLocation[j][1] + ySpacing;
        }
        else {
          newX = -currentElementBBox.x + this.barrelLocation[j][0] + this.regroupSpacingX;
          newY = -currentElementBBox.y + this.barrelLocation[j][1] + ySpacing;
        }

        timeline.to(currentOneElement, {
          duration: '1', onComplete: function () {
            gsap.set(tenElementChildren[j], { onComplete: function () { currentOneElement.remove(); }, visibility: 'visible' });
          }, scale: 1, x: newX, y: newY
        }, '<+=0.09');
      }
      ySpacing += tenElement.getBBox().height;

      columnCounter++
      if (columnCounter % 5 === 0 || i === Math.floor(this.startingOnes / 10) - 1) {
        this.regroupSpacingX += tenElement.getBBox().width;
        ySpacing = 80;
      }
      if (columnCounter % 10 === 0) {
        this.regroupSpacingX += 15;
      }
      timeline.set(this.onesGroup, { delay: 0 }); // pause slight pause after each row animation
    }
  }

  private animateTensToHundreds(timeline: gsap.core.Timeline): void {
    let columnCounter = 0;
    let ySpacing = 80;
    this.regroupSpacingX = 0;
    for (let i = 0; i < Math.floor(this.tensGroup.children.length / 10); i++) {

      let hundredElement, hundredElementChildren: Element[], scale;

      if (this.elementType === 'grapes') {
        hundredElement = this.createVine(this.regroupSpacingX, ySpacing);
        hundredElementChildren = gsap.utils.toArray('g', hundredElement.children[1]);
        scale = 1;
      }
      else {
        hundredElement = this.createBoat(this.regroupSpacingX, ySpacing);
        hundredElementChildren = Array.from(hundredElement.children[1].children);
        scale = 0.5;
      }
      this.hundredsGroup.appendChild(hundredElement);

      timeline.to(hundredElement.children[0], { delay: 0.25, duration: 1, opacity: 1 });

      for (let j = 0; j < 10; j++) {
        const currentTen = this.tensGroup.children[i * 10 + j] as SVGSVGElement;
        const currentTenBBox = currentTen.getBBox();

        let newX, newY;

        if (this.elementType === 'grapes') {
          newX = -currentTenBBox.x - currentTenBBox.width + this.regroupSpacingX + (currentTen.children[1] as SVGSVGElement).getBBox().width + this.bundleLocation[j][0];
          newY = -currentTenBBox.y - currentTenBBox.height + ySpacing + (currentTen.children[1] as SVGSVGElement).getBBox().height + this.bundleLocation[j][1];
        }
        else {
          newX = -currentTenBBox.x + this.regroupSpacingX + this.truckLocation[j][0] * scale;
          newY = -currentTenBBox.y + ySpacing + this.truckLocation[j][1] * scale;
        }

        timeline.to(currentTen, {
          duration: '1', onComplete: function () {
            gsap.set(hundredElementChildren[j], { onComplete: function () { currentTen.remove(); }, visibility: 'visible' });
          }, scale: scale, x: newX, y: newY
        }, '<+=0.09');
      }
      ySpacing += hundredElement.getBBox().height * scale + 15;

      columnCounter++
      if (columnCounter % 3 === 0 || i === Math.floor(this.tensGroup.children.length / 10) - 1) {
        this.regroupSpacingX += hundredElement.getBBox().width * scale + 15;
        ySpacing = 80;
      }
      timeline.set(this.onesGroup, { delay: 0 });
    }
  }

  private playRegroup(): void {
    if (!this.animationFinished) {
      this.sliderControls.style.display = 'none';
      this.sliderOpen = false;

      const self = this;

      const timeline = gsap.timeline({ callbackScope: self, onComplete: this.decompose });

      this.mainTimeline = timeline;

      this.animateOnesToTens(timeline);

      if ('hint' !== this.feedback || this.startingNumber === this.sliderNumberValue) {

        this.animateTensToHundreds(timeline);
        const remainingTens = Array.from(this.tensGroup.children) as ChildNode[];
        this.animateRemainingEls(remainingTens, timeline, 1);
      }

      this.regroupSpacingX += 20;
      const remainingOnes = Array.from(this.onesGroup.childNodes) as ChildNode[];

      this.animateRemainingEls(remainingOnes, timeline, 'grapes' === this.elementType ? 1 : 2)
      this.animationFinished = true;
    }
  }

  private drawTensElements(): void {
    if (this.startingTens > 0) {

      this.regroupSpacingX = 130;
      let ySpacing = 80;

      let secondRow = false;
      let indexOfTen = 0;
      for (let i = 0; i < this.startingTens; i++) {
        if (indexOfTen === 5) {
          this.regroupSpacingX += this.tensElementBBox.width;
          if (secondRow) {
            ySpacing -= this.tensElementBBox.height * 5
          }
          else {
            ySpacing = 80;
          }
        }
        else if (indexOfTen === 10) {
          ySpacing += 20;
          if (secondRow) {
            ySpacing = 80;
            this.regroupSpacingX += this.tensElementBBox.width + 20;
            secondRow = false;
          }
          else {
            this.regroupSpacingX -= this.tensElementBBox.width;
            secondRow = true;
          }
          indexOfTen = 0;
        }

        if (this.elementType === 'grapes') {
          const bundleGroup = document.createElementNS(svgns, 'g');

          bundleGroup.appendChild(this.createLeaves(this.regroupSpacingX, ySpacing, 1));

          const bundle = this.createGrapeBundle(this.regroupSpacingX, ySpacing, false);
          gsap.set(bundle, { visibility: 'visible' });

          bundleGroup.appendChild(bundle);
          this.tensGroup.appendChild(bundleGroup);
        }

        else {
          const truck = this.findElement('truck').cloneNode(true);
          gsap.set(truck, {
            visibility: 'visible',
            x: -this.tensElementBBox.x + this.regroupSpacingX,
            y: -this.tensElementBBox.y + ySpacing
          });

          this.tensGroup.appendChild(truck);
        }
        ySpacing += this.tensElementBBox.height;
        indexOfTen++;
      }
    }
  }

  private drawOnesElements(): void {
    if (Number(this.startingOnes) > 0) {
      this.regroupSpacingX += 120;
      if (Number(this.startingTens) === 0) {
        this.regroupSpacingX += 100;
      }
      let ySpacing = 80;
      let secondRow = false;
      let indexOfTen = 0;
      for (let i = 0; i < this.startingOnes; i++) {
        if (indexOfTen === 5) {
          this.regroupSpacingX += this.onesElementBBox.width * this.onesElementScale;

          if (secondRow) {
            ySpacing -= this.onesElementBBox.height * 5 * this.onesElementScale;
          }
          else {
            ySpacing = 80;
          }
        }
        else if (indexOfTen === 10) {
          ySpacing += 15;
          if (secondRow) {
            ySpacing = 80;
            this.regroupSpacingX += this.onesElementBBox.width * this.onesElementScale + 15;
            secondRow = false;
          }
          else {
            this.regroupSpacingX -= this.onesElementBBox.width * this.onesElementScale;
            secondRow = true;
          }
          indexOfTen = 0;
        }
        const temp = document.createElementNS(svgns, 'use');
        gsap.set(temp, {
          attr: { href: this.onesElementHref },
          scale: this.onesElementScale,
          x: (-this.onesElementBBox.x * this.onesElementScale + this.regroupSpacingX),
          y: (-this.onesElementBBox.y * this.onesElementScale + ySpacing)
        });
        this.findElement('ones').appendChild(temp);
        ySpacing += this.onesElementBBox.height * this.onesElementScale;
        indexOfTen++;
      }
    }
  }

  // #region SLIDER FUNCTIONS
  private inputFieldPressed(): void {
    if (!this.sliderOpen && !this.animationFinished) {
      if (this.firstTimeOpen) {
        this.controllerDraggable[0].disable();
        const self = this;
        const start = self.findElement('slider').getBoundingClientRect().x;
        gsap.to(this.findElement('pointerHand'), {
          delay: 0.5, duration: 2, onComplete: () => {
            gsap.set(this.findElement('pointerHand'), { visibility: 'hidden' });
            this.controllerDraggable[0].enable();
            this.tutorialFinished = true;
          }, repeat: 1, x: '+=50', yoyo: true
        });
        gsap.to(this.findElement('slider'), {
          delay: 0.5, duration: 2, onUpdate: function () {
            self.sliderNumber.textContent = String(Math.round((self.findElement('slider').getBoundingClientRect().x - start) / self.sliderIncrements));
            gsap.set(self.sliderNumber, { x: -(self.sliderNumber.getBBox().width / 2) });
          }, repeat: 1, x: '+=50', yoyo: true
        });
        this.firstTimeOpen = false;
      }
      this.sliderControls.style.display = 'block';
      this.sliderOpen = true;
    }
    else {
      if (this.tutorialFinished) {
        this.sliderControls.style.display = 'none';
        this.sliderOpen = false;
      }
    }
  }

  private sliderButtonPressed(button: Element): void {
    let value = 0;
    if (button.id === 'largeInc') {
      value = 5;
    }
    else if (button.id === 'smallInc') {
      value = 1;
    }
    else if (button.id === 'smallDec') {
      value = -1;
    }
    else if (button.id === 'largeDec') {
      value = -5;
    }
    let newNumber = Number(this.sliderNumber.textContent) + value;
    if (newNumber > this.max) {
      newNumber = this.max;
    }
    else if (newNumber < this.min) {
      newNumber = this.min;
    }
    if (newNumber <= this.max && newNumber >= this.min) {
      this.sliderValueHasBeenUpdated(newNumber);
    }
  }

  private sliderValueHasBeenUpdated(value: number): void {
    this.sliderNumber.textContent = String(value);
    this.sliderNumberValue = value;

    gsap.set(this.sliderNumber, { x: -(this.sliderNumber.getBBox().width / 2) });
    gsap.set(this.slider, { x: this.sliderIncrements * (value - this.min) });
  }

  private addSliderEventListenersAndInteractivity(): void {

    this.numberDisplay.addEventListener('pointerdown', () => this.inputFieldPressed());

    for (let i = 0; i < this.buttons.children.length; i++) {
      this.buttons.children[i].addEventListener('pointerdown', () => this.sliderButtonPressed(this.buttons.children[i]));
    }

    // Initializing draggables, controller and slider
    this.controllerDraggable = Draggable.create(this.sliderControls, {
      type: 'x,y'
    });

    const self = this;

    Draggable.create(this.slider, {
      bounds: this.sliderBar,
      cursor: 'pointer',
      onDrag: function () {
        self.sliderValueHasBeenUpdated(Math.round(this.x / self.sliderIncrements) + Number(self.min));
      },
      onDragEnd: function () {
        self.controllerDraggable[0].enable();
      },
      onPress: function () {
        // disable controller drag if we are dragging slider
        self.controllerDraggable[0].disable();
      },
      type: 'x'
    });
  }

  private restart(): void {
    this.mainTimeline.clear();
    this.alternateTimeline.clear();
    Array.from(this.onesGroup.children).forEach(e => e.remove());
    Array.from(this.tensGroup.children).forEach(e => e.remove());
    Array.from(this.hundredsGroup.children).forEach(e => e.remove());
    Array.from(this.findElement('onesText').childNodes).forEach(e => e.remove());
    Array.from(this.findElement('tensText').childNodes).forEach(e => e.remove());
    Array.from(this.findElement('hundredsText').childNodes).forEach(e => e.remove());
    this.animationFinished = false;
    this.sliderOpen = false;
    this.regroupSpacingX = 0;
    this.sliderValueHasBeenUpdated(this.min);

    // gsap.set(this.tryAgain, { y: -this.tryAgain.getBBox().height });
    gsap.set(this.correct, { visibility: 'hidden' });

    this.drawTensElements();
    this.drawOnesElements();
  }

  private init(): void {

    gsap.registerPlugin(Draggable);

    // calculating increments for slider
    const barWidth = Math.round(this.sliderBar.getBBox().width - this.slider.getBBox().width);
    this.sliderIncrements = barWidth / (this.max - this.min);

    gsap.set(this.sliderControls, { display: 'none' });
    this.sliderOpen = false;
    gsap.set(this.sliderNumber, { x: -(this.sliderNumber.getBBox().width / 2) });

    // setting slider max and mins and repositioning the max
    this.gsvg.getElementById('maxText').textContent = String(this.max);
    gsap.set(this.maxText, { x: `-=${this.maxText.getBBox().width}` });
    this.gsvg.getElementById('minText').textContent = String(this.min);

    const self = this;
    this.gsvg.addEventListener('pointerdown', event => {
      if (self.sliderOpen && event.target === this.gsvg && this.tutorialFinished) {
        self.sliderControls.style.display = 'none';
        self.sliderOpen = false;
      }
    });

    this.addSliderEventListenersAndInteractivity();

    // setting popups
    // gsap.set(this.tryAgain, { y: -this.tryAgain.getBBox().height });
    gsap.set(this.correct, { visibility: 'hidden' });

    if (this.elementType === 'grapes') {
      const tempBundle = this.createGrapeBundle(0, 0, false);
      this.gsvg.appendChild(tempBundle);
      gsap.set(tempBundle, { visibility: 'hidden' });
      this.tensElementBBox = tempBundle.getBBox();
      this.tensElementBBox.width += 5;
      this.tensElementBBox.height += 5;

      this.onesElementBBox = this.findElement('grape').getBBox();
      this.onesElementScale = 1;
      this.onesElementHref = '#grape';
    }
    else {
      this.tensElementBBox = this.findElement('truck').getBBox();

      this.onesElementBBox = this.findElement('barrel').getBBox();
      this.onesElementScale = 2;
      this.onesElementHref = '#barrel';
    }

    this.onesElementBBox.height += 3;
    this.onesElementBBox.width += 3;

    this.drawTensElements();
    this.drawOnesElements();

    // this.goButton.addEventListener('pointerdown', () => this.playRegroup());
    // this.findElement('restart').addEventListener('pointerdown', () => this.restart());

    gsap.set(this.findElement('displayBox'), { transformOrigin: 'center' });
    gsap.to(this.findElement('displayBox'), { delay: 0.5, repeat: 5, scale: '+=0.2', yoyo: true });

  }

  public onPuzzlePlay(): void {
    this.playRegroup();
  }

  public onPuzzleTryAgain(): void {
    this.restart();
  }

  public onPuzzleHelp(): void {

  }
}
