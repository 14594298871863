import { gsap } from 'gsap';
import { PuzzleBase } from '../puzzle/puzzleBase'
import { OverlayNumberInput } from '../puzzle-ui/OverlayNumberInput';


/**
 * A minimal implementation of a puzzle for you to work off of when you make a new puzzle. It is fully functional but not particularly deep.
 */
export class PuzzleTemplateLogic extends PuzzleBase {
  public tryAgain(): void {
    throw new Error('Method not implemented.');
  }

  private correctButton: SVGTextElement;
  private incorrectButton: SVGTextElement;
  private currentSelected: 'correct' | 'incorrect' | 'none' = 'none';

  /**
   * Create a new puzzle that gets the button IDs from the DOM by svg node lookup
   */
  public constructor() {
    super({attempts: []}, document.getElementById('testPuzzle') as unknown as SVGElement);

    this.correctButton = this.svgRenderer.svg.node.querySelector('#correct') as SVGTextElement;
    this.incorrectButton = this.svgRenderer.svg.node.querySelector('#incorrect') as SVGTextElement;

    this.correctButton.addEventListener('click', () => this.buttonClicked(true));
    this.incorrectButton.addEventListener('click', () => this.buttonClicked(false));

    if (this.puzzleUiFixedOverlay && this.puzzleUiCameraOverlay) {
      new OverlayNumberInput({x: 100, y: 100, width: 100, height: 50}, this.puzzleUiFixedOverlay);
      new OverlayNumberInput({x: 100, y: 200, width: 100, height: 50}, this.puzzleUiCameraOverlay);
    }

    //this.svgRenderer.camera.enableFreeCamera();
  }

  /**
   * This is called when the "play" button in the overlay is clicked
   */
  public onPuzzlePlay(): void {
    this.playAnimation(this.currentSelected === 'correct');
  }

  /**
   * This is called when the user tries again
   */
  public onPuzzleTryAgain(): void {
    this.playIntroAnimation();
    this.currentSelected = 'none';
  }
  
  /**
   * This is called if the user attempts to press "submit" while disabled
   */
  public onPuzzleHelp(): void {
      this.animateUserAttention(this.correctButton);
      this.animateUserAttention(this.incorrectButton);
  }

  /**
   * Called once the overlay is successfully "hooked up" to this puzzle
   * If we move to having the overlay as part of the angular component this won't be neccessary anymore.
   * 
   * Think of it as an "on load" or "on ready" call. Once this is done you are ready to manipulate the DOM.
   */
  public onPuzzleOverlayConnect(): void {
    super.onPuzzleOverlayConnect();
    this.playIntroAnimation();
  }

  /**
   * Called when one of the buttons of this puzzle is clicked
   * @param correct If correct we highlight the correct button. If not we highlight the incorrect button.
   */
  private buttonClicked(correct: boolean): void {
    this.currentSelected = correct ? 'correct' : 'incorrect';

    if(correct) {
      gsap.set(this.correctButton, {'opacity': 1});
      gsap.set(this.incorrectButton, {'opacity': 0.5});
    } else {
      gsap.set(this.correctButton, {'opacity': 0.5});
      gsap.set(this.incorrectButton, {'opacity': 1});
    }
  }

  /**
   * An animation that slides in the buttons.
   */
  private playIntroAnimation(): void {
    if (this.correctButton && this.incorrectButton) {
      if (this.onPlayEnable) {
        this.onPlayEnable(false);
      }
  
      // set position variables
      gsap.set(this.correctButton, {x: 1380, 'opacity': 1});
      gsap.set(this.incorrectButton, {x: -100, 'opacity': 1});

      // tween buttons in
      gsap.to(this.correctButton, {duration: 2, x: 800});
      gsap.to(this.incorrectButton, {duration: 2, x: 360, onComplete: () => {
        if (this.onPlayEnable) {
          this.onPlayEnable(true);
        }
      }});
    }
  }

  /**
   * Play the feedback animation. If correct we fade out the incorrect button
   * if incorrect (or "none") we fade out the correct button
   * @param isCorrect 
   */
  private playAnimation(isCorrect: boolean): void {
    const animationObject = {
      opacity: 0,
      duration: 1,
      onComplete: () => {
        // score this attempt as correct or incorrect
        this.recordAnswerAttempt(isCorrect);
        
        if (this.onPuzzleAnimationEnd) {
          // signal to the overlay the feedback animation is complete
          this.onPuzzleAnimationEnd();
        }
      }
    };

    if(isCorrect) {
      gsap.to(this.incorrectButton, animationObject);
    } else {
      gsap.to(this.correctButton, animationObject);
    }
  }
}
