import { Component, EventEmitter, Input, Output } from '@angular/core';
import { range } from 'lodash-es';
import { TEMPLATE_PREFIX } from '../../../../environments/locale-config';
@Component({
  selector: 'kh-manip-multiply',
  templateUrl: TEMPLATE_PREFIX + 'manip-multiply.component.html',
  styleUrls: ['./manip-multiply.component.less']
})
export class ManipMultiplyComponent {
  public images: number[] = [];

  @Input() public itemIndex: number = 0;
  @Input() public product?: number;
  @Input() public displayExpression = true;
  @Input() public imageSource!: string;
  @Input() public minHeight: number = 0;
  @Input() public multiplierColour?: string;
  @Input() public multiplier: any;
  @Input() public minValue = 0;
  @Input() public maxValue = 10;
  @Input() public defaultValue: number = -1;

  @Output() public valueChange = new EventEmitter<string>();
  @Output() public submitAnswer = new EventEmitter<string>();

  public val: string = '';

  @Input()
  public set value(value: string) {
    this.val = value ?? '';
    const rangeAmount = value ? parseInt(value, 10) : 1;
    this.images = range(rangeAmount);
  }

  public get defaultVal(): string {
    return this.defaultValue !== -1 ? this.defaultValue.toString() : this.minValue.toString();
  }


  public trackByIndex(index: number): number {
    return index;
  }

  public valueChanged(value: string): void {
    this.valueChange.emit(value);
  }

  public submit(answer: string): void {
    this.submitAnswer.emit(answer);
  }
}
