/* eslint-disable complexity */
import { Draggable, Elastic, gsap, Power1 } from 'gsap/all';
import { MinecartMenuOverlay } from './MinecartMenuOverlay';
import { PuzzleBase } from '../puzzle/puzzleBase';

const svgns = 'http://www.w3.org/2000/svg';

export interface InputSetup {
  arena: HTMLElement;
  platform: SVGSVGElement;
  controls: HTMLElement;
  inputNums: HTMLElement;
  inputBtns: HTMLElement;
  equation: HTMLElement;
  equationInput: HTMLDivElement;
  terms: HTMLElement;
  numbers: SVGSVGElement;
  plusBtn: SVGSVGElement;
  minusBtn: SVGSVGElement;
  cover: SVGSVGElement;
  cart: SVGSVGElement;
  backWheel: SVGSVGElement;
  frontWheel: SVGSVGElement;
  plusTxt: SVGSVGElement;
  minusTxt: SVGSVGElement;
  minecartSvg: SVGElement;
  minecartContainer: HTMLDivElement;
  minecartOverlay: HTMLDivElement;
  addButton: HTMLDivElement;
  removeButton: HTMLDivElement;

  addRemove: boolean;
  useImgs: boolean;
  scrollbarRangeMax: number;
  scrollbarRangeMin: number;
  termLimit: number;
  inputs: number;
  choices: string;
}

interface Node extends HTMLElement {
  on: boolean;
  val: number;
}

interface Term extends HTMLElement {
  node: Node;
  positive: boolean;
  val: number;
  txt: any[]; // CHANGE
  img: HTMLElement;
}

export interface GameInput {
  startBalloons: number;
  startSandbags: number;
  goal: number;
}

export interface MinecartGame extends GameInput {
  startBalloons: number;
  startSandbags: number;
  goal: number;
  start: number;
  attempts: number;
  completed: boolean;
}

export class IntegerPlatfromClass extends PuzzleBase {
  private setup: InputSetup;
  private games: MinecartGame[];
  private game: MinecartGame;
  private gameIndex: number;
  private arena: SVGSVGElement;
  private platform: SVGSVGElement;
  private controls: SVGSVGElement;
  private inputNums: HTMLElement;
  private inputBtns: HTMLElement;
  private equation: HTMLElement;
  private overlay: HTMLDivElement;
  private minecartContainer: HTMLDivElement;

  private numbers: HTMLElement;
  private plusBtn: HTMLElement;
  private minusBtn: HTMLElement;
  private terms: HTMLElement;
  // private addBtn: SVGUseElement;
  // private nextBtn: SVGUseElement;
  // private retryBtn: SVGUseElement;
  // private playBtn: SVGUseElement;
  private gem: SVGUseElement;
  private readonly gemPosX = 1040;
  private readonly gemPosY = 354;
  private readonly LEVEL_SPACING = 50;

  private dragEl: any;
  private spring: SVGUseElement;
  private left: SVGUseElement;
  private right: SVGUseElement;
  private surface: SVGUseElement;
  private levelEls: SVGUseElement[];
  private sandbagBounce: any;
  private cover: SVGSVGElement;
  private cart: SVGSVGElement;
  private backWheel: SVGSVGElement;
  private frontWheel: SVGSVGElement;
  private plusTxt: SVGSVGElement;
  private minusTxt: SVGSVGElement;
  private wheelCircumference: number;
  private cartXPos: number;


  private selectedTerm: Term | any;
  private allTerms: Term[];
  private termIndex: number;
  private selectedNode: Node | null;
  private items: Node[];

  private positive: boolean;
  private sum: number;
  private pos: number;
  private DIFF = 300;

  private tl: gsap.core.Timeline;
  private canEdit: boolean;
  private finished: boolean;
  private canPlay: boolean;
  private canReset: boolean;
  private cartOnPlatform: boolean;
  private editing: boolean;
  private groundLevel: number;

  private addRemove: boolean;
  private useImgs: boolean;
  private scrollbarRangeMax: number;
  private scrollbarRangeMin: number;
  private removeButton: HTMLDivElement;
  private addButton: HTMLDivElement;

  private balloons: SVGUseElement[];
  private balloonVal = 1;
  private sandbagVal = -1;
  private sandbags: SVGUseElement[];
  private balloonX: number;
  private sandbagX: number;
  private ITEM_START_X = 455;
  private ITEM_START_Y = 202;
  private balloonYDiff: number;
  private sandbagYDiff: number;
  private ADDITIONAL_SANDBAG_Y = 148;
  private BALLOON_Y = 550;
  private BALLOON_DURATION = 1;
  private SANDBAG_DURATION = 0.7;

  private CART_X_DIFF = 0;
  private CART_Y_DIFF = 5;
  private sparkle : SVGUseElement;
  private minecartMenu: MinecartMenuOverlay;

  public constructor(setup: any, gameInputs: any) {
    super({attempts: []}, setup.minecartSvg);
    this.games = [];
    this.setup = setup;

    for (let i = 0; i < gameInputs.length; i++) {
      this.games.push(this.gameInToGame(gameInputs[i]));
    }

    this.gameIndex = 0;
    this.game = this.games[0];
    this.arena = setup.arena;
    this.terms = setup.terms;
    this.platform = setup.platform;
    this.controls = setup.controls;
    this.inputNums = setup.inputNums;
    this.inputBtns = setup.inputBtns;
    this.equation = setup.equation;
    this.plusBtn = setup.plusBtn;
    this.minusBtn = setup.minusBtn;
    this.cart = setup.cart;
    this.backWheel = setup.backWheel;
    this.frontWheel = setup.frontWheel;
    this.numbers = setup.numbers;
    this.cover = setup.cover;
    this.plusTxt = setup.plusTxt;
    this.minusTxt = setup.minusTxt;
    this.overlay = setup.minecartOverlay;
    this.minecartContainer = setup.minecartContainer;
    this.removeButton = setup.removeButton;
    this.addButton = setup.addButton;

    this.sum = 0;
    this.pos = this.sum * 50;
    this.positive = true;

    this.selectedNode = null;
    this.selectedTerm = null;
    this.allTerms = [];
    this.items = [];

    this.tl = gsap.timeline();
    this.canEdit = false;
    this.finished = false;
    this.canPlay = false;

    if (this.onPlayEnable) {
      this.onPlayEnable(this.canPlay);
    }

    this.canReset = false;
    this.cartOnPlatform = false;
    this.editing = false;
    this.termIndex = -1;

    this.balloons = [];
    this.sandbags = [];
    this.levelEls = [];

    this.addRemove = setup.addRemove;
    this.useImgs = setup.useImgs;
    this.scrollbarRangeMax = setup.scrollbarRangeMax;
    this.scrollbarRangeMin = setup.scrollbarRangeMin;
    this.groundLevel = 0;

    this.balloonYDiff = 0;
    this.sandbagYDiff = 0;

    this.minecartMenu = new MinecartMenuOverlay({
      numChoices: this.setup.inputs,
      choiceString: this.setup.choices,
      overlay: this.overlay,
      equationElement: this.equation,
      equationInputElement: this.setup.equationInput,
      showImages: this.setup.useImgs,
      useText: this.addRemove
    });
    this.minecartMenu.pauseEdit(true);
  }

  public gameInToGame(gIn: GameInput) {
    const g = {
      attempts: 0,
      completed: false,
      goal: gIn.goal,
      start: 0,
      startBalloons: gIn.startBalloons <= 8 && gIn.startBalloons > 0 ? gIn.startBalloons : 0,
      startSandbags: gIn.startSandbags <= 8 && gIn.startSandbags > 0 ? gIn.startSandbags : 0
    } as MinecartGame;

    return g;
  }

  private positionGem(): void {
    gsap.set(this.gem, { x: this.gemPosX, y: this.gemPosY - (this.game.goal * this.LEVEL_SPACING) });
  }

  public setLevels() {
    const self = this;

    // ground
    gsap.set(self.left, { y: 400 + -self.game.start * 50 - 28 });
    gsap.set(self.right, { x: 830, y: 400 + -self.game.start * 50 - 22 });

    // bridge or tunnel
    if (self.game.goal < 0) {
      const rect = document.createElementNS(svgns, 'use');
      this.arena.appendChild(rect);
      rect.setAttribute('href', '#dirt');
      gsap.set(rect, { scaleY: -self.game.goal - 3.5, x: 830, y: 450 });
      this.levelEls.push(rect);

      const tunnel = document.createElementNS(svgns, 'use');
      this.arena.appendChild(tunnel);
      // tunnel.setAttribute("href","#tunnel")
      // gsap.set(tunnel, {x : 830, y : 400 + -self.game.goal*50 - 125})
      this.levelEls.push(tunnel);

      // move up the ground
      if (self.game.goal === -1) {
        tunnel.setAttribute('href', '#grasstunnel');
        gsap.set(tunnel, { x: 830, y: 400 - 100 - 22 });
        self.groundLevel = 2;
      } else if (self.game.goal === -2) {
        tunnel.setAttribute('href', '#grasstunnel');
        gsap.set(tunnel, { x: 830, y: 400 - 50 - 22 });
        self.groundLevel = 1;
      } else {
        tunnel.setAttribute('href', '#tunnel');
        gsap.set(tunnel, { x: 830, y: 400 + -self.game.goal * 50 - 125 });
        self.groundLevel = 0;
      }
    } else if (self.game.goal === 1) {
      var bridge = document.createElementNS(svgns, 'use');
      this.arena.appendChild(bridge);
      bridge.setAttribute('href', '#bridge-wall');
      gsap.set(bridge, { x: 830, y: 400 + -self.game.goal * 50 });
      this.levelEls.push(bridge);
    } else if (self.game.goal > 0) {
      var bridge = document.createElementNS(svgns, 'use');
      this.arena.appendChild(bridge);
      bridge.setAttribute('href', '#bridge');
      gsap.set(bridge, { x: 830, y: 400 + -self.game.goal * 50 });
      this.levelEls.push(bridge);

      for (let i = 2; i < self.game.goal; i++) {
        const extension = document.createElementNS(svgns, 'use');
        this.arena.appendChild(extension);
        extension.setAttribute('href', '#bridge-extension');
        gsap.set(extension, { x: 830, y: 400 + -self.game.goal * 50 + i * 50 });
        this.levelEls.push(extension);
      }
    }

    gsap.set(self.surface, { x: 450, y: 400 - self.game.start * 50 });
    gsap.set(self.spring, { height: 300 - self.game.start * this.LEVEL_SPACING, x: 550, y: 400 - self.game.start * this.LEVEL_SPACING });

    // cart
    gsap.set(self.cart, { x: -200 + self.CART_X_DIFF, y: 300 + self.game.start * 50 + self.CART_Y_DIFF });
    this.cartXPos = 640 - 74; // to do: adjust here

    try {
      this.arena.removeChild(self.gem);
    } catch {}

    // gem
    const gem = document.createElementNS(svgns, 'use');
    this.arena.appendChild(gem);
    gem.setAttribute('href', '#gem');
    // gsap.set(gem, { x: self.gemPos + 26, y: 400 + -self.game.goal * 50 - 50 });
    this.gem = gem;
    this.positionGem();
    // take width away

  }

  public setupEls() {
    const self = this;

    // ground
    const left = document.createElementNS(svgns, 'use');
    this.arena.appendChild(left);
    this.left = left;

    const right = document.createElementNS(svgns, 'use');
    this.arena.appendChild(right);
    this.right = right;

    left.setAttribute('href', '#groundLeft');
    right.setAttribute('href', '#groundRight');

    // platform surface
    const surface = document.createElementNS(svgns, 'use');
    this.platform.appendChild(surface);
    surface.setAttribute('href', '#surface');
    this.surface = surface;

    // spring
    const spring = document.createElementNS(svgns, 'use');
    this.arena.appendChild(spring);
    spring.setAttribute('href', '#spring');
    this.spring = spring;

    // inputs
    //gsap.set(self.inputBtns, { visibility: 'hidden' });
    //gsap.set(self.inputNums, { visibility: 'hidden' });

    if (this.onPlayEnable) {
      this.onPlayEnable(this.canPlay);
    }

   this.updateEquationButtons();

    this.equation.onpointerdown = () => {
      this.openInput();
    }

    // arena click
    this.arena.onpointerdown = function (e) {
      self.highlightEdit();
    };
    this.cart.onpointerdown = function (e) {
      self.highlightEdit();
    };

    // other event listeners
    addEventListener('resize', e => {
      self.onResize();
    });
    this.cover.onpointerdown = function (e) {
      self.closeInput();
    };

    // set wheels
    gsap.set(self.backWheel, { transformOrigin: '50% 50%' });
    gsap.set(self.frontWheel, { transformOrigin: '50% 50%' });
    self.wheelCircumference = 2 * Math.PI * 20

    // set up other stuff
    this.setLevels();
    this.setupDraggablePlatform();

    // this.tl.timeScale(10);

    this.tl.to(self.cart, { duration: 1 });
    this.onResize();
    this.setupAnimation();
  }

  private highlightEdit() {
    if (!this.editing && this.canEdit && this.minecartMenu.getNumberOfTerms() <= 0) {
      // highlight edit button
      this.tl.to(this.equation, { duration: 0.25, scale: 1.5 });
      this.tl.to(this.equation, { duration: 0.5, ease: 'bounce', scale: 1 });
    }
    

    // this.focusElement(this.overlay);
  }

  private playButtonPress() {
      if (this.canPlay) {
        this.canEdit = false;
        this.canReset = false;
        this.canPlay = false;
        this.minecartMenu.pauseEdit(true);

        if (this.onPlayEnable) {
          this.onPlayEnable(this.canPlay);
        }
        // this.tl.to(this.playBtn, { duration: 0.2, scale: 0 });
        this.closeInput();
        this.playAnimation();
      }
  }


  private resetGame() {
    const self = this;
    this.finished = false;
    this.cartOnPlatform = false;
    this.canEdit = false;
    this.canPlay = false;
    this.canReset = false;
    this.minecartMenu.pauseEdit(true);

    if (this.onPlayEnable) {
      this.onPlayEnable(this.canPlay);
    }

    this.minecartMenu.reset();

    this.tl.clear();

    // buttons
    // gsap.set(self.retryBtn, { scale: 0 });
    // gsap.set(self.playBtn, { scale: 1 });

    // platform
    this.sum = 0;
    this.updatePlatformPos();

    // reset balloons and sandbags
    self.balloons.forEach(el => {
      self.platform.removeChild(el);
    });
    self.balloons = [];

    self.sandbags.forEach(el => {
      self.platform.removeChild(el);
    });
    self.sandbags = [];

    // reset cart  (to outside of screen)
    gsap.set(self.cart, { x: -200 + self.CART_X_DIFF, y: 300 + self.game.start * 50 + self.CART_Y_DIFF });
    gsap.set([self.backWheel, self.frontWheel], { rotation: 0 });

    // reset terms
    self.allTerms.forEach(term => {
      self.terms.removeChild(term);
    });
    self.allTerms = [];

    this.sandbagYDiff = 0;
    this.balloonYDiff = 0;

    try { this.arena.removeChild(this.sparkle); } catch {}

    // gsap.set(self.gem, { x: self.gemPos + 26, y: 400 + -self.game.goal * 50 - 50 });
    this.positionGem();
  }

  private animateBalloonAdd(): SVGUseElement {
    const temp = document.createElementNS(svgns, 'use');
    this.platform.appendChild(temp);
    temp.setAttribute('href', '#balloon');
    this.balloons.push(temp);

    gsap.set(temp, { visibility: 'hidden', x: this.balloonX, y: this.ITEM_START_Y + 600 - this.balloonYDiff });

    this.balloonX += 50;
    if (this.balloonX >= this.ITEM_START_X + 50 * 7) {
      this.balloonYDiff += 20; // to do: change val?
      this.balloonX = this.ITEM_START_X;
    }

    return temp;
  }

  private animateSandbagAdd(): SVGUseElement {
    const temp = document.createElementNS(svgns, 'use');
    this.platform.appendChild(temp);
    temp.setAttribute('href', '#sandbag');
    this.sandbags.push(temp);

    gsap.set(temp, { visibility: 'hidden', x: this.sandbagX, y: this.ITEM_START_Y - 400 - this.sandbagYDiff });

    this.sandbagX += 50;
    if (this.sandbagX >= this.ITEM_START_X + 50 * 7) {
      this.sandbagYDiff += 20;
      this.sandbagX = this.ITEM_START_X;
    }

    return temp;
  }

  private playAnimation() {
    this.game.attempts += 1;
    this.dragEl[0].disable();
    this.finished = true;

    const terms = this.minecartMenu.getTerms();

    try {
      terms.forEach(term => {
        // this.tl.to(term, { background: '#23a3ff', color: 'white', duration: 0.3 });
        const elements = [];

        this.tl.to(term, {onComplete: () => term.setSelected(true), duration: 0});

        // add balloons or sandbags
        // TO DO: format balloons and sandbags so more than 8 are allowed
        if (term.value > 0) { // adds
          if (term.type === 'balloon') {
            for (let i = 0; i < Math.abs(term.value); i++) {
              elements.push(this.animateBalloonAdd());
            }

            this.tl.to(elements, { duration: this.BALLOON_DURATION, visibility: 'visible', y: '-=' + this.BALLOON_Y });
          } else {
            for (let i = 0; i < Math.abs(term.value); i++) {
              elements.push(this.animateSandbagAdd());
            }

            this.tl.to(elements, { duration: this.SANDBAG_DURATION, ease: 'linear', visibility: 'visible', y: '+= ' + (400 + this.ADDITIONAL_SANDBAG_Y) });
          }

          this.sum = Number(this.sum) + term.getEffectiveValue();
          this.updatePlatformPos();
        } else { // removes
          if (term.type === 'balloon') { // remove balloons
            if (Math.abs(term.value) <= this.balloons.length) {
              for (let i = 0; i < Math.abs(term.value); i++) {
                const temp = this.balloons.pop();
                elements.push(temp);
                this.balloonX -= 50;
              }

              this.tl.to(elements, { duration: this.BALLOON_DURATION, y: this.ITEM_START_Y - 600 });

              this.sum = Number(this.sum) + term.getEffectiveValue();
              this.tl.to(elements, { duration: 0, visibility: 'hidden' });
              this.updatePlatformPos();
            } else {
              this.tl.to({}, {duration: 0, onComplete: () => {this.minecartMenu.setTermAsError(term)}});
              throw new Error('Break the loop.');
            }
          } else {
            if (Math.abs(term.value) <= this.sandbags.length) {

              for (let i = 0; i < Math.abs(term.value); i++) {
                const temp = this.sandbags.pop();
                elements.push(temp);
                this.sandbagX -= 50;
              }
              this.tl.to(elements, { duration: this.SANDBAG_DURATION, ease: 'linear', y: this.ITEM_START_Y + 600 });
              this.tl.to(elements, { duration: 0, visibility: 'hidden' });
              // this.sum -= term.val;
              this.sum = Number(this.sum) + term.getEffectiveValue();
              this.updatePlatformPos();
            } else {
              this.tl.to({}, {duration: 0, onComplete: () => {this.minecartMenu.setTermAsError(term)}});
              throw new Error('Break the loop.');
            }
          }
        }
      });

      // feedback animation
      if (this.sum === this.game.goal) {
        // cart rolls off
        this.game.completed = true;
        this.tl.to(this.cart, { duration: 0.8, ease: 'linear', x: 830 + this.CART_X_DIFF });
        this.tl.to(
          [this.backWheel, this.frontWheel],
          {
            duration: 0.8,
            ease: 'linear',
            onComplete: () => {
              this.cartOnPlatform = false;
              // this.sum += 1;
              this.sum = Number(this.sum) + 1;
              this.updatePlatformPos();

              let time = ((this.gemPosX - 830) / 264) * 0.8;
              this.tl.to(this.cart, { duration: time, ease: 'linear', x: this.gemPosX + this.CART_X_DIFF }, '<');
              this.tl.to(
                [this.backWheel, this.frontWheel],
                { duration: time, ease: 'linear', rotation: '+=' + ((this.gemPosX - 830) / this.wheelCircumference) * 360 },
                '<'
              );

              time = ((1400 - this.gemPosX) / 264) * 0.8;
              this.tl.to(
                this.cart,
                {
                  duration: time,
                  ease: 'linear',
                  onStart: function () {
                    gsap.set(this.gem, { y: '-=' + 75 });
                  },
                  onUpdate: () => {this.moveGemWithCart();},
                  onUpdateParams: [self],
                  x: 1400 + this.CART_X_DIFF
                },
                '>'
              );
              this.tl.to(
                [this.backWheel, this.frontWheel],
                { duration: time, ease: 'linear', rotation: '+=' + ((1400 - this.gemPosX) / this.wheelCircumference) * 360 },
                '<'
              );
            },
            rotation: '+=' + ((830 - this.cartXPos + this.CART_X_DIFF) / this.wheelCircumference) * 360
          },
          '<'
        );
      }

      // too low -> hit the ground
      // else if (this.sum < 0 || this.sum < this.game.goal) {
      else if (Number(this.sum) < Number(this.groundLevel) || Number(this.sum) < Number(this.game.goal)) {
        // TO DO: adjust cart width = 148
        this.tl.to(this.cart, { duration: 0.75, ease: Power1.easeIn, x: 830 - 148 + this.CART_X_DIFF });
        this.tl.to(
          [this.backWheel, this.frontWheel],
          { duration: 0.75, ease: Power1.easeIn, rotation: '+=' + ((830 - 148 - this.cartXPos + this.CART_X_DIFF) / this.wheelCircumference) * 360 },
          '<'
        );

        this.tl.to(this.cart, { duration: 1, ease: Power1.easeOut, x: this.cartXPos + this.CART_X_DIFF });
        this.tl.to(
          [this.backWheel, this.frontWheel],
          { duration: 1, ease: Power1.easeOut, rotation: '-=' + ((830 - 148 - this.cartXPos + this.CART_X_DIFF) / this.wheelCircumference) * 360 },
          '<'
        );
      }

      // too high -> wiggle platform
      else {
        this.tl.to([this.platform, this.cart], { duration: 0, transformOrigin: 'center' });

        this.tl.to([this.platform, this.cart], 0.3, { rotation: 5 });
        this.tl.to(this.spring, 0.3, { skewY: 3 }, '<');
        this.tl.to([this.platform, this.cart], 3, { ease: Elastic.easeOut.config(0.9, 0.1), rotation: 0 });
        this.tl.to(this.spring, 3, { ease: Elastic.easeOut.config(0.9, 0.1), skewY: 0 }, '<');

        this.tl.to([this.platform, this.cart], { duration: 0, transformOrigin: 'top left' });
      }
    } catch {}

    // set values after play animation
    this.tl.to(this.platform, {
      duration: 0,
      onComplete: () => {
        this.canEdit = false;
        this.canReset = true;
        this.finished = true;

        // next and retry buttons
        this.recordAnswerAttempt(this.game.completed);
        if (this.onPuzzleAnimationEnd) {
          this.onPuzzleAnimationEnd();
        }


        this.dragEl[0].enable();
      }
    });
  }

  private moveGemWithCart() {
    const xVal = Math.round(Number(gsap.getProperty(this.cart, 'x')));
    const yVal = Math.round(Number(gsap.getProperty(this.cart, 'y')));
    gsap.set(this.gem, { x: xVal + 20 - this.CART_X_DIFF, y: yVal - 40 });
  }

  private updateEquationButtons(): void {
    const val = this.editing ? "" : "none";
  }

  private openInput() {

    if (this.finished || !this.canEdit) {
      return;
    }

    this.editing = true;
    this.canReset = false;
    gsap.set([this.cover, this.inputBtns, this.inputNums], { visibility: 'visible' });
    this.canEdit = false;

    this.updateEquationButtons();
    this.minecartMenu.beginEdit();
  }

  private closeInput() {
    this.minecartMenu.endEdit();

    this.editing = false;
    this.canPlay = true;
    this.canReset = true;

    this.updateEquationButtons();

    if (this.onPlayEnable) {
      this.onPlayEnable(this.canPlay);
    }

    gsap.set([this.cover, this.inputBtns, this.inputNums], { visibility: 'hidden' });

    this.selectedNode = null;
    this.canEdit = true;
    this.minecartMenu.pauseEdit(false);
  }

  private onResize() {

    if (this.overlay) {
      const currentPuzzleWidth = this.minecartContainer.offsetWidth;

      const curScale = currentPuzzleWidth / 1280;
      this.overlay.style.scale = curScale.toString();

      this.overlay.style.width = `${(currentPuzzleWidth * (1 / curScale))}px`;
      this.overlay.style.height = `${(this.minecartContainer.offsetHeight * (1 / curScale))}px`;
    }
  }

  private onUpdateDrag(self: IntegerPlatfromClass) {
    const yVal = Math.round(Number(gsap.getProperty(self.platform, 'y')));
    gsap.set(self.spring, { scaleY: self.getScaleVal(yVal) });
    if (self.cartOnPlatform) {gsap.set(self.cart, { y: 300 + yVal + self.CART_Y_DIFF });}
  }

  private setupDraggablePlatform() {
    const self = this;

    // MOVE SPRING AND PLATFORM
    gsap.registerPlugin(Draggable);

    this.dragEl = Draggable.create(self.platform, {
      onDrag: self.onUpdateDrag,
      onDragEnd: function () {
        gsap.to(self.platform, { duration: 1, ease: 'elastic', onUpdate: self.onUpdateDrag, onUpdateParams: [self], y: self.pos });
      },
      onDragParams: [self],
      type: 'y'
    });
  }

  private getScaleVal(y: number) {
    return -y / 330 + 1;
  }

  private getPos() {
    return -Number(this.sum) * 50;
  }

  private updatePlatformPos() {
    const self = this;
    this.pos = this.getPos();
    this.tl.to(self.platform, { duration: 1, ease: 'elastic', onUpdate: self.onUpdateDrag, onUpdateParams: [self], y: self.pos });
  }

  private setupAnimation() {
    const self = this;
    this.dragEl[0].disable();
    self.onResize();
    this.tl.to(self.spring, { transformOrigin: 'bottom'});

    this.sparkle = document.createElementNS(svgns, 'use');
    this.arena.appendChild(this.sparkle);
    this.sparkle.setAttribute('href', '#sparkle');
    gsap.set(this.sparkle, {scale: 0});
    self.tl.to(this.sparkle, {duration: 0, transformOrigin: 'center'});
    const sparkleSize = this.sparkle.getBBox();

    gsap.set(this.sparkle, {
      x: this.gemPosX + (sparkleSize.width/2),
      y: this.gemPosY - (self.game.goal*this.LEVEL_SPACING) + (sparkleSize.height/2)
    });

    // move cart and wheels
    gsap.set([self.backWheel, self.frontWheel], { rotation: 0 });
    this.tl.to(this.sparkle, {duration: 3, ease: 'linear', opacity: 0, rotate: 420, scale: 6});

    this.tl.to(self.cart, {
      duration: 2,
      ease: 'linear',
      onComplete: function () {
        self.cartOnPlatform = true;
      },
      x: self.cartXPos + self.CART_X_DIFF
    }, '<1');
    this.tl.to(
      [self.backWheel, self.frontWheel],
      { duration: 2, ease: 'linear', rotation: ((self.cartXPos + 200 + self.CART_X_DIFF) / self.wheelCircumference) * 360 },
      '<'
    );
    this.tl.to(self.cart, { duration: 0.05 });

    // update platform position
    self.sum = -1;
    this.updatePlatformPos();

    // create start balloons and sandbags
    // TO DO: fomat balloons and sandbags so more than 8 are allowed
    this.balloonX = self.ITEM_START_X;
    for (let i = 0; i < self.game.startBalloons; i++) {
      const temp = document.createElementNS(svgns, 'use');
      this.platform.appendChild(temp);
      temp.setAttribute('href', '#balloon');

      // gsap.set(temp, {x : self.ITEM_START_X + i * 50, y : self.ITEM_START_Y + 600, visibility : "hidden"})
      gsap.set(temp, { visibility: 'hidden', x: this.balloonX, y: self.ITEM_START_Y + 600 - this.balloonYDiff });
      this.balloonX += 50;

      if (this.balloonX > self.ITEM_START_X + 7 * 50) {
        this.balloonX = self.ITEM_START_X;
        this.balloonYDiff += 20;
      }
      this.balloons.push(temp);
    }
    // this.balloonX = self.ITEM_START_X + (self.game.startBalloons - 1)*50

    this.sandbagX = self.ITEM_START_X;
    for (let i = 0; i < self.game.startSandbags; i++) {
      const temp = document.createElementNS(svgns, 'use');
      this.platform.appendChild(temp);
      temp.setAttribute('href', '#sandbag');

      // gsap.set(temp, {x : self.ITEM_START_X + i * 50, y : self.ITEM_START_Y - 400, visibility : "hidden"})
      gsap.set(temp, { visibility: 'hidden', x: this.sandbagX, y: self.ITEM_START_Y - 400 - this.sandbagYDiff });
      this.sandbagX += 50;

      if (this.sandbagX > self.ITEM_START_X + 7 * 50) {
        this.sandbagX = self.ITEM_START_X;
        this.sandbagYDiff += 20;
      }
      this.sandbags.push(temp);
    }
    // this.sandbagX = self.ITEM_START_X + (self.game.startSandbags - 1)*50

    // add balloons and sandbags
    this.tl.to(self.balloons, { duration: 0, visibility: 'visible' });
    this.tl.to(self.sandbags, { duration: 0, visibility: 'visible' });

    if (this.game.startBalloons > 0) {
      this.doSetupBalloons();
      this.updatePlatformPos();
    }

    if (this.game.startSandbags > 0) {
      this.doSetupSandbags();
      this.updatePlatformPos();
    }

    this.tl.to({}, {duration: 0, onComplete: () => {
      this.canEdit = true;
      this.canPlay = true;
      this.canReset = true;
      this.finished = false;
      this.minecartMenu.pauseEdit(false);

      if (this.onPlayEnable) {
        this.onPlayEnable(this.canPlay);
      }

      this.dragEl[0].enable();
    }}, '>');
  }

  private doSetupBalloons(): void {
    this.sum = Number(this.sum) + Number(this.game.startBalloons);

    this.tl.to(this.balloons, {
      duration: this.BALLOON_DURATION,
      y: '-=' + this.BALLOON_Y
    }, '>');
  }

  private doSetupSandbags(): void {
    this.sum = Number(this.sum) - Number(this.game.startSandbags);

    this.tl.to(
      this.sandbags,
      {
        duration: this.SANDBAG_DURATION,
        ease: 'linear',
        y: '+=' + (400 + this.ADDITIONAL_SANDBAG_Y)
      }, '>');
  }

  public onPuzzlePlay(): void {
    this.playButtonPress();
  }

  public onPuzzleHelp(): void {
      this.highlightEdit();
  }

  public onPuzzleTryAgain(): void {
    if (this.canReset) {
      this.resetGame();
      this.setupAnimation();
    }
  }
}
