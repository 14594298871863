export type ScorableData = {
  answer: {[key: string]: string};
  state: {[key: string]: string};
};

export interface IScorable {
  onChangeCallback: undefined | ((data: ScorableData) => void); // callback
  getScorableData(): ScorableData;
  setScorableData(data: ScorableData): void;
}
