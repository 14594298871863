import { OverlayNumberInput } from "../../../puzzle-ui/OverlayNumberInput";

/**
 * The top menu of the farm puzzle. This is an HTML overlay.
 */
export class FarmTopMenu {
  public menuDiv: HTMLDivElement;
  private width = 400;
  private height = 50;
  private onChange?: (value: number) => void;
  public numberInput?: OverlayNumberInput;
  public textLeft: HTMLDivElement;
  public textRight: HTMLDivElement;

  public constructor(parent: HTMLElement, onChangeCallback?: (value: number) => void) {
    this.onChange = onChangeCallback;

    this.menuDiv = document.createElement('div');

    // all of this (except the width) should be moved to a css file
    this.menuDiv.style.display = 'flex';
    this.menuDiv.style.flexDirection = 'row';
    this.menuDiv.style.justifyContent = 'center';
    this.menuDiv.style.width = `${this.width}px`;
    this.menuDiv.style.height = `${this.height}px`;
    this.menuDiv.style.backgroundColor = '#00000080';
    this.menuDiv.style.position = 'absolute';
    this.menuDiv.style.top = '20px';
    this.menuDiv.style.left = `${(1280 - this.width) / 2}px`;
    this.menuDiv.style.borderRadius = '20px';
    this.menuDiv.style.color = 'white';
    this.menuDiv.style.fontSize = '24px';
    this.menuDiv.style.gap = '10px';
    this.menuDiv.style.alignItems = 'center';

    parent.appendChild(this.menuDiv);

    this.createText();
  }

  public getPlayerAnswer(): number {
    if (this.numberInput) {
      return this.numberInput.getValue();
    }

    return 0;
  }

  private createText(): void {
    this.textLeft = document.createElement('div');
    this.textRight = document.createElement('div');

    this.textLeft.innerText = 'There are';
    this.textRight.innerText = 'grapes';

    this.menuDiv.appendChild(this.textLeft);

    this.numberInput = new OverlayNumberInput({
      height: 40,
      positionStyle: 'static',
      type: 'number',
      width: 150,
      x: 0,
      y: 0
    }, this.menuDiv);

    if (this.onChange) {
      this.numberInput.onChange = this.onChange;
    }

    this.menuDiv.appendChild(this.textRight);
  }

  public disableInput(): void {
    if (this.numberInput) {
      this.numberInput.getElement().readOnly = true;
      this.numberInput.getElement().style.pointerEvents = 'none';
      this.numberInput.getElement().style.touchAction = 'none';
    }
  }
}
