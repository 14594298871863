import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TEMPLATE_PREFIX } from '../../../environments/locale-config';


@Component({
  selector: 'kh-modal',
  templateUrl: TEMPLATE_PREFIX + 'modal.component.html',
  styleUrls: ['./modal.component.less']
})
export class ModalComponent {
  public title = '';
  public body = '';
  public button1Text = '';
  public button2Text = '';
  public value = null;

  @Output() public closed = new EventEmitter<any>();

  public constructor(private readonly bsModalRef: BsModalRef) {}

  public button1Click(): void {
    if (this.value !== null) {
      this.closed.emit(this.value);
    }

    this.bsModalRef.hide();
  }

  public button2Click(): void {
    this.bsModalRef.hide();
  }

}
