/* eslint-disable indent */

import { SvgRenderer } from './SvgRenderer';

export type SvgRenderElementType = {
    maintainScale?: boolean;
}

export abstract class SvgRenderElement<ConfigType extends SvgRenderElementType> {
    protected renderer: SvgRenderer;
    protected config: ConfigType;
    public parent: SVGElement;
    public element: SVGElement;
    public isDragging = false;

    public constructor(renderer: SvgRenderer, parent: SVGElement, config: ConfigType, defaultConfig: Partial<ConfigType>) {
        this.renderer = renderer;
        this.parent = parent;
        this.config = {...defaultConfig, ...config};

        this.render();
    }

    protected abstract createElement(): void;
    protected abstract applyConfigProperties(): void;

    protected render(): void {
        this.createElement();
        this.applyConfigProperties();

        if (this.config.maintainScale) {
            this.renderer.camera.addOnScaleChangeListener(this.onMaintainScale.bind(this));
        }

        this.parent.appendChild(this.element);
    }

    protected onMaintainScale(newScale: number): void {
        // not implemented
    }

    protected setElementAttribute(key: string, configKey: keyof ConfigType) {
        if (this.config[configKey]) {
            this.element?.setAttribute(key, (this.config[configKey] as any).toString());
        }
    }

    public setConfigValue<K extends keyof ConfigType>(configKey: K, value: ConfigType[K]) {
        this.config[configKey] = value;
        this.applyConfigProperties();
    }

    public getConfig(): ConfigType {
        return this.config;
    }

    public getConfigValue<K extends keyof ConfigType>(configKey: K): NonNullable<ConfigType[K]> {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.config[configKey]!;
    }
}
