<div class="ManipulativeContainer">
  <div class="ManipFractionStripsTable">
    <div class="cell_bar">
      <div class="ManipFractionStripsContainer">
        <div class="target-line" *ngIf="fractionParams.targetLine > 0"
             [ngStyle]="{ width: fractionParams.targetLine + '%' }"
        ></div>
        <div class="row-fluid">
          <div class="thumbnail-wrapper"
               cdkDropList
               cdkDropListOrientation="horizontal"
               (cdkDropListDropped)="drop($event)">
            <div class="thumbnail hthumbnail ManipFractionStripStrip"
                 [ngStyle]="{ 'width' : fraction.width + '%', 'background' : fraction.colour }"
                 *ngFor="let fraction of strips; index as ind"
                 cdkDrag>
              <div class="label_numerator">1</div>
              <br/>
              <div class="label_denominator">{{fraction.fraction}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="fractionParams.showFraction" class="cell_fraction text-nowrap">
      =&nbsp;{{sum.Numerator}}<span *ngIf="sum.Denominator !== 1">/{{sum.Denominator}}</span>
    </div>
  </div>

  <button (click)="addFractionStrip()" class="btn btn-md btn-primary">
    + Strip
  </button>&nbsp;
  <a class="text-sm cursor reset-btn" (click)="resetStrips()">Reset</a>
</div>