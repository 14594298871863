export const enum KeyboardEventKey {
    // Alphabet keys
    A = 'a',
    B = 'b',
    C = 'c',
    D = 'd',
    E = 'e',
    F = 'f',
    G = 'g',
    H = 'h',
    I = 'i',
    J = 'j',
    K = 'k',
    L = 'l',
    M = 'm',
    N = 'n',
    O = 'o',
    P = 'p',
    Q = 'q',
    R = 'r',
    S = 's',
    T = 't',
    U = 'u',
    V = 'v',
    W = 'w',
    X = 'x',
    Y = 'y',
    Z = 'z',

    // Number keys (top row)
    Digit0 = '0',
    Digit1 = '1',
    Digit2 = '2',
    Digit3 = '3',
    Digit4 = '4',
    Digit5 = '5',
    Digit6 = '6',
    Digit7 = '7',
    Digit8 = '8',
    Digit9 = '9',

    // Function keys
    F1 = 'F1',
    F2 = 'F2',
    F3 = 'F3',
    F4 = 'F4',
    F5 = 'F5',
    F6 = 'F6',
    F7 = 'F7',
    F8 = 'F8',
    F9 = 'F9',
    F10 = 'F10',
    F11 = 'F11',
    F12 = 'F12',

    // Arrow keys
    ArrowUp = 'ArrowUp',
    ArrowDown = 'ArrowDown',
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',

    // Special keys
    Escape = 'Escape',
    Enter = 'Enter',
    Tab = 'Tab',
    Backspace = 'Backspace',
    Delete = 'Delete',
    Home = 'Home',
    End = 'End',
    PageUp = 'PageUp',
    PageDown = 'PageDown',
    CapsLock = 'CapsLock',
    Shift = 'Shift',
    Control = 'Control',
    Alt = 'Alt',
    Meta = 'Meta',
    ContextMenu = 'ContextMenu',
    ScrollLock = 'ScrollLock',
    Pause = 'Pause',

    // Symbol keys (top row)
    Backquote = '`',
    Minus = '-',
    Equal = '=',
    BracketLeft = '[',
    BracketRight = ']',
    Backslash = '\\',
    Semicolon = ';',
    Quote = '\'',
    Comma = ',',
    Period = '.',
    Slash = '/',

    // Number pad keys
    Numpad0 = 'Numpad0',
    Numpad1 = 'Numpad1',
    Numpad2 = 'Numpad2',
    Numpad3 = 'Numpad3',
    Numpad4 = 'Numpad4',
    Numpad5 = 'Numpad5',
    Numpad6 = 'Numpad6',
    Numpad7 = 'Numpad7',
    Numpad8 = 'Numpad8',
    Numpad9 = 'Numpad9',
    NumpadAdd = 'NumpadAdd',
    NumpadSubtract = 'NumpadSubtract',
    NumpadMultiply = 'NumpadMultiply',
    NumpadDivide = 'NumpadDivide',
    NumpadDecimal = 'NumpadDecimal',
    NumpadEnter = 'NumpadEnter',

    // Lock keys
    NumLock = 'NumLock',
    Insert = 'Insert',

    // Media keys
    MediaNextTrack = 'MediaNextTrack',
    MediaPreviousTrack = 'MediaPreviousTrack',
    MediaStop = 'MediaStop',
    MediaPlayPause = 'MediaPlayPause',

    // Browser keys
    BrightnessDown = 'BrightnessDown',
    BrightnessUp = 'BrightnessUp',
    Eject = 'Eject',
    Hibernate = 'Hibernate',
    Power = 'Power',
    Sleep = 'Sleep',
    WakeUp = 'WakeUp'
}