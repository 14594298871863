import { fabric } from 'fabric';
import { IEvent } from 'fabric/fabric-impl';
import { Observable, from } from 'rxjs';
import { FabricOvalRegion } from './fabric-oval-region';
import { FabricRectRegion } from './fabric-rect-region';
import { fontLoader } from './fabric-utils';
import { FabricDraggable } from './fabric-draggable';
import { IFabricRect } from './models/fabric-rect';
import { IInputRegionCallbacks } from './models/input-region-callbacks';
import { IInputRegionOptions } from './models/input-region-options';

export class FabricIntInputRectRegion extends fabric.Group {
  public type: 'intInputRegion';
  public activeDraggables: { name: string }[];
  public itemOrder!: string;
  public ignoreInScore: boolean;
  private region: FabricRectRegion | FabricOvalRegion;
  private text: fabric.Text;
  private onInput: (boundingRect: IFabricRect, canvasRect: IFabricRect) => void;
  private onClose: () => void;
  private _fontSize: number;
  private _fontFamily?: string;

  public constructor(
    options: IInputRegionOptions,
    callbacks: IInputRegionCallbacks,
    baseClass: typeof FabricRectRegion | typeof FabricOvalRegion = FabricRectRegion
  ) {
    const opts = {
      ...(options || {}),
      hasBorders: false,
      hasControls: false,
      selectable: false
    };
    const region = new baseClass(opts);
    let text: fabric.Text;
    if (region.height && options._fontSize) {
      text = new fabric.Text('', { ...opts, fill: '#000', fontSize: region.height * options._fontSize });
    } else {
      text = new fabric.Text('', { ...opts, fill: '#000' });
    }
    super([region, text], opts);
    this.type = 'intInputRegion';
    this.ignoreInScore = options.ignoreInScore ?? false;
    this.activeDraggables = [];

    this._fontSize = options._fontSize ?? 0;

    this.region = region;
    this.text = text;

    this.text.set("fontFamily", options._fontFamily);
    this.text.canvas?.renderAll();
    this.addWithUpdate();

    this.text.left = -((this.text.width ?? 0) / 2);
    this.onInput = callbacks?.onInput;
    this.onClose = callbacks?.onClose;
    this.on('mousedown', this.mousedown.bind(this));
  }

  public close(): void {
    this.canvas?.fire('keyboard:onClose', { target: this });
    this.onClose?.();
  }

  public endInput(): void {
    this.region.setStroke(!!this.text.text);
    this.canvas?.renderAll();
  }

  public setText(text: string): void {
    this.text.text = text;
    this.text.fontSize = (this.height ?? 0) * this._fontSize;
    this.addWithUpdate();
    this.text.set('left', 0);
    this.text.top = this.text.fontSize * 0.03;
    this.text.set({
      originX: 'center',
      originY: 'center'
    });
    this.activeDraggables = [{ name: text }];
    this.canvas?.fire('region:onChange', { target: this });
    this.canvas?.renderAll();
  }

  public getText(): string | undefined {
    return this.text.text;
  }

  public reset(): void {
  }

  public preload(): Observable<any> {
    return from(
      fontLoader(this._fontFamily).then(() => {
        this.text.set('fontFamily', this._fontFamily);
        this.addWithUpdate();
      })
    );
  }

  public mousedown(ev: IEvent): void {
    this.region.setStroke(true);
    const boundingRect = this.getBoundingRect();
    const canvasRect = this.canvas?.getElement().getBoundingClientRect();

    if (canvasRect) {
      this.onInput?.(boundingRect, canvasRect);
      this.canvas?.fire('keyboard:onOpen', { target: this });
    }

    ev.e.preventDefault();
    ev.e.stopPropagation();
  }

  public drop(draggable: FabricDraggable, singleMode: boolean): void {
  }

  public setStroke(active: boolean): void {
  }

}
