import { KHPoint } from "../..";

/**
 * A functional mixin to set any manipulative HTML element as draggable
 * @param element - Any HTML Element
 * @param onDragStart - Additional logic for drag start
 * @param onDrag - Additional logic that happens while dragging
 * @param onDragEnd - Additional logic for drag end
 */
export function SetDraggable(element: HTMLElement,
  onDragStart?: (pos: KHPoint) => void,
  onDrag?: (pos: KHPoint) => void,
  onDragEnd?: (pos: KHPoint) => void) {
  let isDragging = false;
  let startX = parseFloat(element.style.left);
  let startY = parseFloat(element.style.top);
  let startMouseX = 0;
  let startMouseY = 0;

  // drag start
  const dragStartFn = (e: MouseEvent | TouchEvent) => {
    e.preventDefault();
    isDragging = true;

    let startPos: KHPoint = { x: 0, y: 0 };
    if (e instanceof MouseEvent) {
      startPos = { x: e.clientX, y: e.clientY };
    } else if (e instanceof TouchEvent) {
      startPos = { x: e.touches[0].clientX, y: e.touches[0].clientY };
    }

    startX = parseFloat(element.style.left);
    startY = parseFloat(element.style.top);
    startMouseX = startPos.x;
    startMouseY = startPos.y;

    if (onDragStart) {
      onDragStart({ x: startPos.x, y: startPos.y });
    }
  }

  // on drag
  const dragFn = (e: MouseEvent | TouchEvent) => {
    if (isDragging) {
      let dragPos: KHPoint = { x: 0, y: 0 };

      if (e instanceof MouseEvent) {
        dragPos = { x: e.clientX, y: e.clientY };
      } else if (e instanceof TouchEvent) {
        dragPos = { x: e.touches[0].clientX, y: e.touches[0].clientY };
      }

      element.style.left = `${startX + (dragPos.x - startMouseX)}px`;
      element.style.top = `${startY - (startMouseY - dragPos.y)}px`;

      if (onDrag) {
        onDrag({ x: dragPos.x, y: dragPos.y });
      }
    }
  }

  // drag end
  const dragEndFn = (e: MouseEvent | TouchEvent) => {
    if (isDragging && onDragEnd && (e instanceof MouseEvent || e instanceof TouchEvent)) {
      onDragEnd({ x: parseFloat(element.style.left), y: parseFloat(element.style.top) });
    }
    isDragging = false;
  }

  // hook up all the events
  element.addEventListener('mousedown', dragStartFn);
  element.addEventListener('touchstart', dragStartFn);
  document.addEventListener('mousemove', dragFn);
  document.addEventListener('touchmove', dragFn);
  document.addEventListener('mouseup', dragEndFn);
  document.addEventListener('touchend', dragEndFn);
  document.addEventListener('touchcancel', dragEndFn);
}