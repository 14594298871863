import { fabric } from 'fabric';

export interface IRegionOptions extends fabric.IRectOptions {
  outline?: boolean;
  itemOrder: string;
  background?: string;
  width: number;
  height: number;
  dotImageSrc?: string;
  ignoreInScore?: boolean;
  defaultValue?: string;
}
