<div #region class="position-absolute" [attr.key]="key" [ngStyle]="{
    'top.px': box.top,
    'width.px': box.width,
    'height.px': box.height,
    'left.px': box.left,
    'background-color': background,
    'border-radius': borderRadius,
    'background-image': 'url('+ clickedImage +')',
     'border' : border,
  }"
  (click)="onChangeValue($event)"></div>
  