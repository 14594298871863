/* eslint-disable indent */
export type MinecartTermType = 'sandbag' | 'balloon' | 'none';

export class MinecartTerm {
    public img: HTMLDivElement;
    public type: MinecartTermType;
    public textElement: Text;
    public value = 0;
    public element: HTMLElement;
    public onSelected?: (term: MinecartTerm) => void;
    public inUse = false;
    public useSymbols = false; // when true instead of saying "add" or "remove" it just says + and -
    public showImages = false;

    /**
     * @param val The numeric value of this term
     * @param parent The parent to insert the HTML DOM elements
     * @param index The index of this term. Used in the onSelected callback
     * @param onSelected Called when this term is selected
     */
    public constructor(val: number, parent: HTMLElement, type: MinecartTermType, useSymbols: boolean, showImages: boolean, onSelected?: (term: MinecartTerm) => void) {
      this.value = val;
      this.type = type;
      this.useSymbols = useSymbols;
      this.showImages = showImages;
      this.onSelected = onSelected;

      // create the HTML elements for this node
      this.element = document.createElement('li');
      this.textElement = document.createTextNode('');
      this.img = document.createElement('div');
      this.element.setAttribute('class', 'minecart-default-term');

      this.updateImage();
      this.updateText();

      this.element.appendChild(this.textElement);
      this.element.appendChild(this.img);
      parent.appendChild(this.element);

      this.element.addEventListener('click', (e) => {
        e.stopPropagation();
        if (this.onSelected) {
          this.onSelected(this);
        }
      });
    }

    /**
     * Set the visual style of this term as selected or unselected
     * @param selected If true this is considered selected
     */
    public setSelected(selected: boolean): void {
        this.element.setAttribute('class', selected ? 'minecart-selected-term' : 'minecart-default-term');
    }

     /**
     * Set the visual style of this term as error or not
     * @param error If true this is considered an error (used in the feedback animation)
     */
      public setAsError(isError: boolean): void {
        this.element.setAttribute('class', isError ? 'minecart-error-term' : 'minecart-default-term');
      }

    /**
     * Deletes the DOM node for this Term
     */
    public remove(): void {
      this.element.remove();
    }

    public isPositive(): boolean {
      return this.value > 0;
    }

    /**
     * Set the value of this Term. As a side effect this updates the visual image and text of the HTML elements
     * @param value 
     */
    public setValue(value: number): void {
      this.value = value;
      this.updateText();
    }

    public setType(type: MinecartTermType): void {
      this.type = type;
      this.updateImage();
    }

    public makeNegative(): void {
      this.setValue(Number(`-${Math.abs(this.value)}`));
    }

    public makePositive(): void {
      this.setValue(Math.abs(this.value));
    }

    /**
     * Eg, a positive sandbag type is actually a negative total number.
     * Removing a sandbag is thus a positive number
     */
    public getEffectiveValue(): number {
      const multiplier = (this.type === 'balloon' || this.type === 'none') ? 1 : -1;

      return this.value * multiplier;
    }

    /**
     * Update the image based off the value
     */
    private updateImage(): void {
      let imgStyle = '';

      if (this.showImages) {
        if (this.type === 'sandbag') {
          imgStyle = 'minecart-sandbag';
        } else if (this.type === 'balloon') {
          imgStyle = 'minecart-balloon';
        }
      }

      this.img.setAttribute('class', imgStyle);
    }

    /**
     * Update the text element based off of the value
     */
    private updateText(): void {
      let textValue = '';
      const displayValue = Math.abs(this.value);

      const addSymbol = this.useSymbols ? '+' : 'add';
      const subtractSymbol = this.useSymbols ? '-' : 'remove';
      const numberPrefix = this.showImages ? '' : this.type === 'balloon' ? '+' : '-';
      const curSymbol = this.value > 0 ? addSymbol : subtractSymbol;

      if (displayValue !== 0) {
        if (this.showImages) {
          textValue = `${curSymbol} ${numberPrefix}${displayValue}`;
        } else {
          textValue = `${curSymbol} (${numberPrefix}${displayValue})`;
        }
      }


      this.textElement.textContent = textValue;
    }
}
