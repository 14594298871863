import { Howl } from 'howler';

export class KhAudio {
  private audio!: Howl;

  constructor(path: string) {
    this.audio = new Howl({
      src: [path]
    });
  }

  public stop(): void {
    this.audio.stop();
  }

  public loop(isLoop: boolean): void {
    this.audio.loop(isLoop);
  }

  public play(): void {
    this.audio.play();
  }

  public pause(): void {
    this.audio.pause();
  }

  public get volume(): number {
    return this.audio.volume();
  }

  public set volume(volume: number) {
    this.audio.volume(volume);
  }
}