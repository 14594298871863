import { KHPoint } from '../../../../../LearningWorlds/pixi-puzzle-framework/PuzzleEngine/KHPoint';
import { ManipFrame } from '../../../../../utils/utils';
import { ScorableDragRegion } from './ScorableDragRegion';

/**
 * While this is a "group" it isn't an actual DIV element or anything - it just positions the draggables with some logic.
 */
export class ScorableDragGroup {
  private draggables: ScorableDragRegion[];
  private framePosition: ManipFrame;
  private draggablePadding = 10;

  public constructor(parent: HTMLElement, topCenterPosition: KHPoint, draggables: ScorableDragRegion[]) {
    this.draggables = draggables;

    this.framePosition = this.calculateGroupSize(topCenterPosition);
    this.positionDraggables();
  }


  private calculateGroupSize(topCenter: KHPoint): ManipFrame {
    let width = 0;
    let height = 0;

    for (const draggable of this.draggables) {
      const rect = draggable.dragElement.getBoundingClientRect();
      width += rect.width;
      height += rect.height;
    }

    width += this.draggablePadding * (this.draggables.length - 1);

    return { height, width, x: topCenter.x - (width / 2), y: topCenter.y };
  }

  private positionDraggables(): void {
    let leftTotal = 0;

    for (let i = 0; i < this.draggables.length; i++) {
      const draggable = this.draggables[i];
      const rect = draggable.dragElement.getBoundingClientRect();

      draggable.updateAnchorPosition(leftTotal + this.framePosition.x, this.framePosition.y);

      leftTotal += rect.width + this.draggablePadding;
    }
  }
}
