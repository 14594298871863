
import UseSprite from '../../../../KhDecorators/Pattern/UseSprite';
import SvgText from './SvgText';

class SymbolText extends SvgText{
  public symbol: UseSprite;
  public constructor(svgParent: SVGSVGElement|SVGGElement, useSprite: UseSprite, paddingX: number, paddingY: number, fontSize: string) {
    super(svgParent, paddingX, paddingY, fontSize);
    this.symbol = useSprite;

    this.group.append(this.symbol.element);
  }

  public override setText(text: string): void {
    this.text.textContent = text;
    const bbox = this.text.getBBox();
    const rightOfText = bbox.x + bbox.width - this.paddingX;
    const middleOfText = bbox.y;
    this.symbol.setX(rightOfText, 0);
    this.symbol.setY(middleOfText, 0);
  }

  public moveSymbol(x: number|null, y: number|null){
    if (x) {
      this.symbol.setX(x, 0);
    }
    if (y) {
      this.symbol.setY(y, 0);
    }
  }
} export default SymbolText
