import { PuzzleBase } from "../../puzzle/puzzleBase";
import { FarmPuzzleConfig, FarmScene } from "./FarmScene";
import { getUnitValue } from "./FarmTypes";

export class FarmPuzzleBase extends PuzzleBase {
  private config: FarmPuzzleConfig;
  private targetValue: number;
  private farmScene: FarmScene | undefined;

  public constructor(config: FarmPuzzleConfig) {
    super({attempts: []}, config.parent);

    this.config = config;
    this.targetValue = this.determineTargetValue();
  }

  private onAnswerComplete(correct: boolean): void {
    this.recordAnswerAttempt(correct);

    if (this.onPuzzleAnimationEnd) {
      this.onPuzzleAnimationEnd();
    }
  }

  public onPuzzleOverlayConnect(): void {
    super.onPuzzleOverlayConnect();

    if (this.onPlayEnable) {
      this.onPlayEnable(false);
    }

    this.config.overlayDiv = this.getPuzzleOverlayElement();
    this.config.onInputChange = this.onInputChange.bind(this);
    this.config.onAnswerComplete = this.onAnswerComplete.bind(this);
    this.farmScene = new FarmScene(this.config);
  }

  public isDoneLoading(): boolean {
    if (this.farmScene) {
      this.farmScene.isDoneLoading();
    }

    return false;    
  }

  public onPuzzlePlay(): void {
    if (this.farmScene) {
      this.farmScene.animate();
    }
  }

  public onPuzzleTryAgain(): void {
    this.farmScene?.restart();
  }

  public onPuzzleHelp(): void {
    console.log('farm help');
  }

  private onInputChange(value: number): void {
    if (this.onPlayEnable) {
      this.onPlayEnable(true);
    }
  }

  private determineTargetValue(): number {
    let count = 0;

    this.config.farmData.forEach(element => {
      if (element.unit) {
        count += getUnitValue(element.unit) * element.count;
      }
    });

    return count;
  }

  public destroy(): void {
    super.destroy();

    if (this.farmScene) {
      this.farmScene.destroyInstance();
    }
  }
}
