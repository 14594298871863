import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { OnDynamicMount } from 'ngx-dynamic-hooks';
import { ScorableData } from '../kh-manip-scorable/IScorable';
import { applyFrameToElement, parseFrameString } from '../../../../utils/utils';

@Component({
  selector: 'kh-manipulative-image',
  template: '<img #imageElement>'
})
export class KhManipulativeImage implements OnDynamicMount, AfterViewInit {
  public onChangeCallback: undefined | ((data: ScorableData) => void); // callback
  @ViewChild('imageElement') imageElement: ElementRef<HTMLImageElement> | undefined;
  @Input('url') public url = '';
  @Input('frame') public frame = '';

  public onDynamicMount(): void {
    this.imageElement?.nativeElement?.setAttribute('src', this.url);
    if (this.imageElement?.nativeElement) {
      applyFrameToElement(this.imageElement?.nativeElement, this.frame);
      const frameData = parseFrameString(this.frame);

      this.imageElement.nativeElement.style.minWidth = `${frameData.width}px`;
      this.imageElement.nativeElement.style.minHeight = `${frameData.height}px`;
    }
  }
  ngAfterViewInit(): void {
    this.imageElement?.nativeElement?.setAttribute('src', this.url);
    if (this.imageElement?.nativeElement) {
      applyFrameToElement(this.imageElement?.nativeElement, this.frame);
      const frameData = parseFrameString(this.frame);
      this.imageElement.nativeElement.style.minWidth = `${frameData.width}px`;
      this.imageElement.nativeElement.style.minHeight = `${frameData.height}px`;
    }
  }
}
