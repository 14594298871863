import { Injectable } from '@angular/core';
import { mapDuration } from 'app/core/kh-utils/time.utils';
import { IStudentTimeState } from 'app/student/services/time-checker/dto/student-time-state.dto';
import { IStudentSchoolTimerData } from 'app/student/services/time-checker/models/student-school-timer-data';
import { StudentTimeCheckerHttpService } from 'app/student/services/time-checker/student-time-checker-http.service';
import { BehaviorSubject, interval, Observable, of, Subject, Subscription } from 'rxjs';
import { map, switchMap, takeWhile, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class StudentSchoolTimeCheckerService {
  private readonly TIMER_TICK_IN_MS = 1000;
  private readonly MIN_SYNC_STATE_PERIOD_IN_MS = 1000;

  private schoolTimerTickSubscription?: Subscription | null;
  private lastTimeState?: IStudentTimeState;
  private lastSyncTime?: number;
  private updatingStateSubject: Subject<IStudentTimeState> = new Subject<IStudentTimeState>();
  private syncInProgress = false;

  private timerTick$: BehaviorSubject<IStudentSchoolTimerData> = new BehaviorSubject<IStudentSchoolTimerData>({
    isActive: false,
    remainingSchoolTime: null
  });

  public constructor(private readonly httpService: StudentTimeCheckerHttpService) {
  }

  public syncState(): Observable<IStudentTimeState> {
    if (!!this.lastTimeState && !this.canSyncState()) {
      return of(this.lastTimeState);
    }
    if (!this.syncInProgress) {
      this.syncInProgress = true;
      this.updatingStateSubject = new Subject<IStudentTimeState>();
      this.httpService.getInfo()
        .pipe(
          tap(() => this.lastSyncTime = Date.now()),
          tap(state => this.updateState(state)),
          tap(() => this.createTimer()),
          tap(() => this.syncInProgress = false)
        )
        .subscribe(this.updatingStateSubject);
    }

    return this.updatingStateSubject;
  }

  public getCooldownTimer(): Observable<IStudentSchoolTimerData> {
    return !this.lastTimeState
      ? this.syncState().pipe(switchMap(() => this.timerTick$))
      : this.timerTick$;
  }

  public isSchoolHours(): boolean {
    return !!this.lastTimeState
      && this.lastTimeState.IsSchoolHours
      && this.lastTimeState.SchoolHoursEndMs > 0;
  }

  public isSchoolHoursObservable(): Observable<boolean> {
    return !!this.lastTimeState
      ? of(this.isSchoolHours())
      : this.syncState().pipe(map(studentTimeState => studentTimeState.IsSchoolHours));
  }

  private createTimer(): void {
    if (this.schoolTimerTickSubscription) {
      this.schoolTimerTickSubscription.unsubscribe();
    }
    this.schoolTimerTickSubscription = interval(this.TIMER_TICK_IN_MS)
      .pipe(
        tap(tickNumber => !!tickNumber && this.updateState({
          SchoolHoursEndMs: this.lastTimeState?.SchoolHoursEndMs
            ? this.lastTimeState?.SchoolHoursEndMs - this.TIMER_TICK_IN_MS
            : 0
        })),
        tap(() => this.updateState({ IsSchoolHours: this.isSchoolHours() })),
        tap(() => this.timerTick$.next({
          isActive: !!this.lastTimeState?.IsSchoolHours,
          remainingSchoolTime: this.lastTimeState?.IsSchoolHours && this.lastTimeState?.SchoolHoursEndMs
            ? mapDuration(this.lastTimeState?.SchoolHoursEndMs)
            : null
        })),
        tap(() => {
          if (!this.lastTimeState?.IsSchoolHours) {
            this.schoolTimerTickSubscription = null;
          }
        }),
        takeWhile(() => !!this.lastTimeState?.IsSchoolHours)
      )
      .subscribe();
  }

  private updateState(newState: Partial<IStudentTimeState>): IStudentTimeState | undefined {
    if (newState) {
      this.lastTimeState = {
        ...this.lastTimeState as IStudentTimeState,
        ...newState
      };
    }

    return this.lastTimeState;
  }


  private canSyncState(): boolean {
    return !this.lastSyncTime || Math.abs(Date.now() - this.lastSyncTime) > this.MIN_SYNC_STATE_PERIOD_IN_MS;
  }

}
