export type RollbotLevel = {
    allowPartitionEdits: boolean;
    batteries: RollbotBattery[];
    input: string;
    max: number;
    min: number;
    mode: string;
    numberLineDenominator: number;
    shipLocation: number;
    shipWidth: number;
    timeStep: number;
    rollbot: string;
    buttons: string[] | null;
    initialGearCount: number | null;
    hint: number | null;
}

export type RollbotBattery = {
    denominator: number;
    direction: boolean;
    editable: boolean;
    numerator: number;
}
