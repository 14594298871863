/* eslint-disable newline-per-chained-call */
import { ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, ElementRef, Input, ViewChild, Inject } from '@angular/core';
import { RollbotBattery, RollbotLevel } from './rollbot.types';
import { RollbotPuzzle } from './kh-radial-linear';
import { PuzzleBaseComponent } from '../puzzle/puzzle.component';
import { PuzzleEventService } from '../services/puzzle-event.service';
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from '../../services/IQuestionEvents';
import { ANALYTICS_SERVICE_TOKEN, IAnalyticsService } from '../../services/IAnalyticsService';
import { AUTH_DATA_SERVICE_TOKEN, IAuthenticationDataService } from '../../services/IAuthenticationDataService';


@Component({
  selector: 'kh-puzzle-rollbot',
  styleUrls: ['./radial-linear.component.css'],
  templateUrl: './radial-linear.component.html'
})
export class RollbotPuzzleComponent extends PuzzleBaseComponent implements AfterViewInit {
  @ViewChild('arenaMain') public arenaMain?: ElementRef<SVGSVGElement>;
  @ViewChild('controls') public controls?: ElementRef<HTMLElement>;
  @ViewChild('assets') public assets?: ElementRef<HTMLElement>;
  @ViewChild('elbackground') public background?: ElementRef<HTMLElement>;
  @ViewChild('batteryDiv') public batteryDiv?: ElementRef<HTMLElement>;
  @ViewChild('goButton') public goButton?: ElementRef<HTMLElement>;
  @ViewChild('nextButton') public nextButton?: ElementRef<HTMLElement>;

  @Input() private allowPartitionEdits = true;
  @Input() private input = 'gear';
  @Input() private max = 3;
  @Input() private min = 0;
  @Input() private mode = 'combining';
  @Input() private numberLineDenominator = 1;
  @Input() private shipLocation = 1.5;
  @Input() private shipWidth = 0.2;
  @Input() private timeStep = 1;
  @Input() private rollbot = "rollbot1"
  @Input() private buttons = "all"
  @Input() private hint: number | null = null 
  @Input() private initialGearCount: number | null = null

  public constructor(
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService,
    protected puzzleEventService: PuzzleEventService,
    @Inject(ANALYTICS_SERVICE_TOKEN) protected readonly analyitcsService: IAnalyticsService,
    @Inject(AUTH_DATA_SERVICE_TOKEN) protected readonly authenticationDataService: IAuthenticationDataService,
    private route: ActivatedRoute) {
      super(questionEventsService, puzzleEventService, analyitcsService, authenticationDataService);
  }

  public ngAfterViewInit(): void {
    // const puzzles = Levels[this.id] as Array<any>
    const puzzles = this.buildLevelJSON()

    if (this.arenaMain) {
      const dom = {
        arena: this.arenaMain?.nativeElement,
        assets: this.assets?.nativeElement,
        background: this.background?.nativeElement,
        controls: this.controls?.nativeElement,
        goButton: this.goButton?.nativeElement,
        nextButton: this.nextButton?.nativeElement,
      };

      const api = new RollbotPuzzle(dom, puzzles);
      this.setPuzzle(api);
    }
  }

  private parseBooleanString(s: string): boolean {
    if (s) {
      return s.toLowerCase() === 'true';
    }

    return false;
  }

  private buildLevelJSON(): RollbotLevel {

    let b = this.buttons.split(",").map(b=>{return b})

    // robot roll call
    const rollbotLevel: RollbotLevel = {
      allowPartitionEdits: this.allowPartitionEdits,
      batteries: [],
      input: this.input,
      max: this.max,
      min: this.min,
      mode: this.mode,
      numberLineDenominator: this.numberLineDenominator,
      shipLocation: this.shipLocation,
      shipWidth: this.shipWidth,
      timeStep: this.timeStep,
      rollbot: this.rollbot,
      buttons: b,
      initialGearCount: this.initialGearCount,
      hint: this.hint,
    };

    const batteries = this.batteryDiv?.nativeElement.children;

    const defaultBattery: RollbotBattery = {
      denominator: 2,
      direction: false,
      editable: false,
      numerator: 1
    };

    if (batteries?.length)
    {
      for (let i = 0; i < batteries?.length; i++) {
        const batteryEl = batteries[i];

        // find any tag with "gear" (batteries are gears - they used to be batteris then got switched to gear but the code still looks for batteries)
        // if we find one then replace the defaults of a default battery if the tag exists and push it
        if (batteryEl.tagName.toLowerCase() === 'gear') {
          const newBattery = {...defaultBattery};

          const denominator = batteryEl.getAttribute('[denominator]')?.trim().replace(/'/g, '');
          const direction = batteryEl.getAttribute('[direction]')?.trim().replace(/'/g, '');
          const editable = batteryEl.getAttribute('[editable]')?.trim().replace(/'/g, '');
          const numerator = batteryEl.getAttribute('[numerator]')?.trim().replace(/'/g, '');

          newBattery.denominator = denominator ? Number(denominator) : newBattery.denominator;
          newBattery.numerator = numerator ? Number(numerator) : newBattery.numerator;
          newBattery.editable = editable ? this.parseBooleanString(editable) : newBattery.editable;
          newBattery.direction = direction ? this.parseBooleanString(direction) : newBattery.direction;

          rollbotLevel.batteries.push(newBattery);
        }
      }
    }

    // if no batteries were found, add a default one (CE-139 https://knowledgehook.atlassian.net/browse/CE-139)
    if (rollbotLevel.batteries.length === 0) {
      rollbotLevel.batteries.push(defaultBattery);
    }

    return rollbotLevel;
  }
}
