import { Injectable } from '@angular/core';
import { environment } from 'env/environment';
import { KhAudio } from 'app/core/audio/kh-audio';

@Injectable({
  providedIn: 'root'
})
export class MusicService {
  private soundsBasePath = environment.soundsBasePath;
  protected preloadedAudios: { [key: string]: KhAudio } = { };

  private getSoundUrl(path: string): string {
    if (path.startsWith('http')) {
      return path;
    }

    const separator = path.trim().indexOf('/') === 0 ? '' : '/';

    return `${this.soundsBasePath}${separator}${path}`;
  }

  public playSound(path: string, volume?: number): KhAudio {
    const audio = this.createAudio(path, volume);
    audio.play();

    return audio;
  }

  public playSoundInfinitely(path: string, volume?: number): KhAudio {
    const audio = this.playSound(path, volume);
    audio.loop(true);

    return audio;
  }

  public createAudio(path: string, volume?: number): KhAudio {
    const audio = this.preloadedAudios && this.preloadedAudios[path]
      ? this.preloadedAudios[path]
      : new KhAudio(this.getSoundUrl(path));

    if (volume) {
      audio.volume = volume;
    }

    return audio;
  }

  public preloadAudio(audios: string[]): void {
    audios.forEach(audio => this.preloadedAudios[audio] = this.createAudio(audio));
  }
}
