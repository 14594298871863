<div class="ManipMultiply">
    <div class="block_image" [ngStyle]="{minHeight: minHeight}" (click)="showControlModal()" role="button">
        <ng-container *ngFor="let image of imageSources; index as ind">
            <kh-img [path]="image" [hidden]="!canShowImage(ind)"></kh-img>
        </ng-container>
    </div>

    <div class="control-bar-tip-container control-bar-tip-container--use-buttons" *ngIf="displayControlBarTour && !reportMode">
        <div class="old-popover popover bs-popover-top TourTips manip-tour-tips animated bounceIn center" (click)="hideControlBarTour()">
            <div class="arrow"></div>
            <div class="popover-inner">
                <div class="popover-content">
                    Use these buttons to answer this question
                </div>
            </div>
        </div>
    </div>
</div>
