import { Component, Input } from '@angular/core';
import { TEMPLATE_PREFIX } from '../../../../environments/locale-config';
import { FabricOvalRegion } from '../../../../utils/fabric-utils/fabric-oval-region';
import { FabricRectRegion } from '../../../../utils/fabric-utils/fabric-rect-region';
import { FabricRegion } from '../../../../utils/fabric-utils/models/fabric-region';
import { RegionObject } from '../region-object';

@Component({
  selector: 'kh-region',
  styleUrls: ['./region.component.less'],
  templateUrl: TEMPLATE_PREFIX + 'region.component.html'
})
export class RegionComponent implements RegionObject {
  @Input() public x = 0;
  @Input() public y = 0;
  @Input() public width = 0;
  @Input() public height = 0;
  @Input() public position = '';
  @Input() public hasOutline = false;
  @Input() public itemOrder = '';
  @Input() public background = '';
  @Input() public shape = 'rect';
  @Input() public dotImage?: string;
  @Input() public ignoreInScore = false;
  @Input() public defaultValue?: string;

  public getRegion(height: number): FabricRegion {
    const arrayPosition = this.position.split(',');
    this.x = parseInt(arrayPosition[0], 10);
    this.y = parseInt(arrayPosition[1], 10);
    const regionClass = this.shape === 'oval' ? FabricOvalRegion : FabricRectRegion;

    return new regionClass({
      background: this.background,
      dotImageSrc: this.dotImage,
      height: this.height,
      itemOrder: this.itemOrder,
      left: this.x,
      outline: this.hasOutline,
      top: height - this.y - this.height / 2,
      width: this.width,
      ignoreInScore: this.ignoreInScore,
      defaultValue: this.defaultValue
    });
  }

}
