/* eslint-disable indent */
import { Matrix, Point, Svg } from '@svgdotjs/svg.js';
import { KHPoint } from '../pixi-puzzle-framework/PuzzleEngine/KHPoint';

export class Camera {
    private static listenerId = 0;
    private svg: Svg;
    private viewbox: Matrix = new Matrix();
    private isPanning: boolean;
    private panStartPoint: Point;
    private panStartViewbox: Matrix;
    private zoomSensitivity = 0.05;
    public originalSize = {x: 1280, y: 720};
    public canPan = true;
    private scale = 1;
    private onScaleChangeListeners: Map<number, (n: number) => void> = new Map();
    private onPositionChangeListeners: Map<number, (n: KHPoint) => void> = new Map();

    public constructor(svg: Svg, viewBoxWidth: number, viewBoxHeight: number) {
        this.svg = svg;
        this.isPanning = false;
        this.panStartPoint = new Point();
        this.panStartViewbox = new Matrix();
        this.originalSize = {x: viewBoxWidth, y: viewBoxHeight};

        this.setViewBox(0, 0, viewBoxWidth, viewBoxHeight);
        // this.registerEventListeners();
        this.recalcViewbox();
    }

    public addOnScaleChangeListener(func: (n: number) => void): number {
        const id = Camera.listenerId++;
        this.onScaleChangeListeners.set(id, func);

        return id;
    }

    public addOnPositionChangeListener(func: (p: KHPoint)=> void): number {
        const id = Camera.listenerId++;
        this.onPositionChangeListeners.set(id, func);

        return id;
    }

    public removeOnScaleChangeListener(index: number): void {
        if (this.onScaleChangeListeners) {
            this.onScaleChangeListeners.delete(index);
        }
    }

    public removeOnPositionChangeListener(index: number): void {
        if (this.onPositionChangeListeners) {
            this.onPositionChangeListeners.delete(index);
        }
    }

    public enableFreeCamera() {
        this.svg.on('mousedown', (event: Event) => this.onMouseDown(event as MouseEvent));
        this.svg.on('mousemove', (event: Event) => this.onMouseMove(event as MouseEvent));
        this.svg.on('mouseup', () => this.onMouseUp());
        this.svg.on('mouseleave', () => this.onMouseUp());
        this.svg.on('wheel', (event: Event) => this.onMouseWheel(event as WheelEvent));
    }

    public setPosition(point: KHPoint): void {
        this.viewbox.e = point.x;
        this.viewbox.f = point.y;
        this.recalcViewbox();
    }

    public getPosition(): KHPoint {
        return {x: this.viewbox.e, y: this.viewbox.f};
    }

    /**
     * Sets the scale of the camera and keeps centered on what it's looking at
     * @param scale
     */
    public setScale(scale: number): void {
        const curWidth = this.originalSize.x * this.scale;
        const curHeight = this.originalSize.y * this.scale;
        const curCenterX = this.viewbox.e + (curWidth / 2);
        const curCenterY = this.viewbox.f + (curHeight / 2);
        const newWidth = this.originalSize.x * scale;
        const newHeight = this.originalSize.y * scale;

        const newX = curCenterX - (newWidth / 2);
        const newY = curCenterY - (newHeight / 2);

        this.scale = scale;
        this.viewbox.a = scale;
        this.viewbox.d = scale;
        this.viewbox.e = newX;
        this.viewbox.f = newY;

        this.recalcViewbox();

        this.onScaleChangeListeners.forEach(element => {
            element(this.scale);
        });
    }

    public getCenter(): KHPoint {
        const curWidth = this.originalSize.x * this.scale;
        const curHeight = this.originalSize.y * this.scale;
        const newX = this.viewbox.e + (curWidth / 2);
        const newY = this.viewbox.f + (curHeight / 2);

        return {x: newX, y: newY};
    }

    public centerOn(point: KHPoint): void {
        const curWidth = this.originalSize.x * this.scale;
        const curHeight = this.originalSize.y * this.scale;
        const newX = point.x - (curWidth / 2);
        const newY = point.y - (curHeight / 2);

        this.viewbox.e = newX;
        this.viewbox.f = newY;

        this.recalcViewbox();
    }

    private doOnPositionChange(): void {
        this.onPositionChangeListeners.forEach(element => {
            element(this.getPosition());
        });
    }

    private onMouseDown(event: MouseEvent) {
        if (this.canPan) {
            this.isPanning = true;
            this.panStartPoint = new Point(event.clientX, event.clientY);
            this.panStartViewbox = this.viewbox.clone();
        }
    }

    private onMouseMove(event: MouseEvent) {
        if (!this.isPanning) {
            return;
        }

        const currentPoint = new Point(event.clientX, event.clientY);
        const delta = {
            x: currentPoint.x - this.panStartPoint.x,
            y: currentPoint.y - this.panStartPoint.y
        };

        const panScale = this.viewbox.a;

        const deltaX = delta.x * panScale;
        const deltaY = delta.y * panScale;

        const newViewbox = this.panStartViewbox.translate(-deltaX, -deltaY);
        this.viewbox = newViewbox;
        this.recalcViewbox();
    }

    private onMouseUp() {
        this.isPanning = false;
    }

    private onMouseWheel(event: WheelEvent) {
        const wheelDelta = Math.sign(event.deltaY);
        this.setScale(this.scale * (1 + (wheelDelta * this.zoomSensitivity)));
    }

    public recalcViewbox(): void {
        this.viewbox.a = this.scale;
        this.viewbox.d = this.scale;
        this.svg.viewbox(this.viewbox.e, this.viewbox.f, this.originalSize.x * this.viewbox.a, this.originalSize.y * this.viewbox.d);

        this.doOnPositionChange();
    }

    public setViewBox(x: number, y: number, width: number, height: number) {
      this.svg.viewbox(x, y, width, height);
    }

    public getWorldCoordinates(clientCoordinates: KHPoint): KHPoint {
        const svgElement = this.svg.node;
        
        // Get the transformation matrix
        var matrix = svgElement.getScreenCTM();

        // Get the mouse click coordinates relative to the SVG
        var point = svgElement.createSVGPoint();
        point.x = clientCoordinates.x;
        point.y = clientCoordinates.y;
        var svgPoint = point.matrixTransform(matrix?.inverse());

        return svgPoint;
    }

    public getScale(): number {
        return this.scale;
    }

    public worldToScreen(point: KHPoint) {
        const svgPoint = this.svg.node.createSVGPoint();
        svgPoint.x = point.x;
        svgPoint.y = point.y;
        const matrix = this.svg.node.getScreenCTM();
        if (matrix) {
            const screenPoint = svgPoint.matrixTransform(matrix);

            return { x: screenPoint.x, y: screenPoint.y };
        }

        return {x: point.x, y: point.y};
    }

    public screenToWorld(point: KHPoint) {
        const svgPoint = this.svg.node.createSVGPoint();
        svgPoint.x = point.x;
        svgPoint.y = point.y;
        const matrix = this.svg.node.getScreenCTM()?.inverse();
        const worldPoint = svgPoint.matrixTransform(matrix);

        return { x: worldPoint.x, y: worldPoint.y };
    }
}
