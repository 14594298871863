import { Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fabric } from 'fabric';
import { Group, IEvent } from 'fabric/fabric-impl';
import { forkJoin, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DRAGGABLE_DEFAULT_OPTIONS } from './constants/draggable-default-options';
import { IDraggableOptions } from './models/draggable-options';

@UntilDestroy()
export class FabricDraggable extends fabric.Group {
  public index = 0;
  public type = 'draggableGroup';
  public insideDraggableArea = true;
  public rect = null;
  public img!: fabric.Image | fabric.Object;
  public active = false;
  public left = 0;
  public top = 0;
  public _id?: number | string;
  public stickToRegion = false;
  public region: any | null = null;
  public cloned = false;
  public originalLeft?: number;
  public originalTop?: number;
  public src: string;
  public hasOutline: boolean;
  public initialPosition?: string;
  public positionLeft: number = 0;
  public positionTop: number = 0;
  public value!: string;

  @Input() public returnUnregistered: boolean = true;

  private animateToPosition: Record<string, number> | null = null;
  private alreadyMoved = false;
  private BGRect: fabric.Rect;
  private OutlineRect: fabric.Rect;

  private options: Partial<IDraggableOptions> = {};

  constructor(options: Partial<IDraggableOptions> = {}) {
    super([(options.BGRect as fabric.Rect), (options.OutlineRect as fabric.Rect)], {...DRAGGABLE_DEFAULT_OPTIONS, ...options});

    let positionArr;
    if(this.initialPosition){
      positionArr = this.initialPosition.split(',').map(Number);
      this.positionLeft = positionArr[0];
      this.positionTop = positionArr[1];
    }
    
    this.src = options.src ?? '';
    this.value = options.value ?? '';
    this.hasOutline = options.hasOutline ?? false;
    this.BGRect = (options.BGRect as fabric.Rect);
    this.OutlineRect = (options.OutlineRect as fabric.Rect);

    this.options = {...DRAGGABLE_DEFAULT_OPTIONS, ...options};


    if (this.options.img) {
      const imgClone = fabric.util.object.clone(this.options.img);
      this.setImage(imgClone);
    }
    
    this._id = this.options._id;
    this.on('moving', (e) => this.moving(e));
    this.on('modified', (e) => this.modified(e));
    this.on('mousedown:before', (e) => {
      //@ts-ignore
      e.e.isPrimary = true;
    });
  }

  public static fromObject(object: Partial<IDraggableOptions>, callback: (group: Group) => any): void {
    object.opacity = 1;
    object.cloned = false;
    const OutlineRect = new fabric.Rect(object.OutlineRect);
    const BGRect = new fabric.Rect(object.BGRect);
    return callback(new FabricDraggable({...object, OutlineRect, BGRect}));
	};

	public initialPositionModifieds(item?: FabricDraggable, initialHeight?: number): void {
		let top;
		let left;
    if (item && !item.stickToRegion && item.initialPosition && item.height && initialHeight) {
      left = item.positionLeft;
      top = initialHeight - item.positionTop - item.height / 2;
      item.animateTo(left, top)
    }
	}

  public animateTo(x: number, y: number, completeCb?: () => void): void {
    const nextPos = this.animateToPosition ?? {
      x: this.left,
      y: this.top
    };

    const movedVertical$ = new Subject<void>();
    const movedHorizontal$ = new Subject<void>();

    if ((x !== nextPos.x || y !== nextPos.y) && this.canvas) {
      this.evented = false;
      this.animateToPosition = {x: x, y: y};
      this.animate('top', y, {
        duration: 200,
        onChange: () => this.canvas?.renderAll(),
        onComplete: () => {
          movedVertical$.next();
          movedVertical$.complete();
        },
        //@ts-ignore
        abort: () => {
          if (this.animateToPosition && this.animateToPosition.x === x && this.animateToPosition.y === y) {
            return !this.animateToPosition || x !== this.animateToPosition.x || y !== this.animateToPosition.y;
          }
        }
      });
      this.animate('left', x, {
        duration: 200,
        onChange: () => this.canvas?.renderAll(),
        onComplete: () => {
          movedHorizontal$.next();
          movedHorizontal$.complete();
        },
        //@ts-ignore
        abort: () => {
          if (this.animateToPosition && this.animateToPosition.x === x && this.animateToPosition.y === y) {
            return !this.animateToPosition || x !== this.animateToPosition.x || y !== this.animateToPosition.y;
          }
        }
      });
      forkJoin([movedVertical$, movedHorizontal$])
      .pipe(
        tap(() => {
          this.animateToPosition = null;
          this.originalLeft = x;
          this.originalTop = y;
          this.canvas?.renderAll();
          this.evented = true;
          completeCb && completeCb();
        }),
        untilDestroyed(this)
      )
      .subscribe();
    }
  }

  public modified(e: IEvent): void {
    this.alreadyMoved = false;
    this.opacity = 1;
    this.active = false;
  }

  public setImage(img: fabric.Object): void {
    this.img = img;
    this.insertAt(img, 1, false);
    this.addWithUpdate();

    const left = -(img.width ?? 0) / 2;
    const top = -(img.height ?? 0) / 2;
    this.img.left = left;
    this.img.top = top;
    this.width = img.width;
    this.height = img.height;
    this.BGRect.left = left;
    this.BGRect.top = top;
    this.BGRect.width = img.width;
    this.BGRect.height = img.height;
    this.OutlineRect.left = left;
    this.OutlineRect.top = top;
    this.OutlineRect.width = img.width;
    this.OutlineRect.height = img.height;
  }

  public load(isSvg: boolean): Observable<any> {
    const loadedObject$ = new Subject();

    if (isSvg) {
      fabric.loadSVGFromURL(this.src, (paths, options) => {
          const graphic = fabric.util.groupSVGElements(paths);
          graphic.width = options.width;
          graphic.height = options.height;
          this.setImage(graphic);

          loadedObject$.next(graphic);
          loadedObject$.complete();
        },
        () => {
        },
        {
          crossOrigin: 'true'
        });
    }
    else {
      fabric.Image.fromURL(this.src, (img) => {
        this.setImage(img);

        loadedObject$.next(img);
        loadedObject$.complete();
      });
    }

    return loadedObject$;
  }

  public wiggle(): Observable<void> {
    const event$ = new Subject<void>();

    let amplitude = Math.random() * 10 - 5;
    amplitude = 5 * Math.sign(amplitude) + amplitude;
    this.animate('left', this.left, {
      duration: 1200,
      onChange: () => this.canvas?.renderAll(),
      easing: (t: any, b: any, c: any, d: any) => {
        t = Math.min(t, d);
        const rad = 2 * Math.PI * t / (d / 2);
        const ret = b + Math.sin(rad) * amplitude * (1 - t / d);
        return ret;
      },
      onComplete: () => {
        event$.next();
        event$.complete();
      }
    });

    return event$;
  }

  public reset(): void {
    this.animateToPosition = null;
    this.alreadyMoved = false;
  }

  public toObject(originOptions: string[] = []): any {
    const keys = Object.keys(originOptions);
    const options = super.toObject(keys);

    options.index = this.index;
    options.img = this.img;
    options.hasOutline = this.hasOutline;
    options._id = this._id;
    options.name = this.value ? this.value : this.name;
    options.value = this.value;
    options.BGRect = this.BGRect.toObject();
    options.OutlineRect = this.OutlineRect.toObject();

    return options;
  }

  private moving(e: IEvent): void {
    if (!this.alreadyMoved) {
      this.alreadyMoved = true;
      return;
    }

    this.opacity = 0.8;
  }
}
