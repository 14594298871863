/* eslint-disable indent */
import { setElementAttrs } from '../puzzle/PuzzleUtils';
import { SvgRenderer } from './SvgRenderer';

export type SvgNumberInputProperties = {
    width: number,
    height: number,
    minNumber: number,
    maxLength: number,
    ignoreDecimalsInLength: boolean,
    x: number,
    y: number,
    defaultValue?: number,
    style: string;
    backgroundImage?: string
}
/**
 * In a push to move puzzle logic and SVG manipulations out of Angular this is specifically not an angular component.
 * While templating would be quite nice isolation is our current goal
 */
export class SvgNumberInput {
    public input: HTMLInputElement;
    public foreignObject: SVGForeignObjectElement;
    public value: number;
    public onChange?: (value: number) => void;
    private properties: SvgNumberInputProperties;

    public constructor(parent: HTMLElement | SVGElement, properties: SvgNumberInputProperties) {
        this.properties = properties;
        this.foreignObject = document.createElementNS(SvgRenderer.svgns, 'foreignObject');
        this.input = document.createElement('input');
        this.input.setAttribute('style', properties.style + `width: ${properties.width}px;  height: ${properties.height}px; --`);
        this.value = Number(properties.defaultValue);

        setElementAttrs(this.input, {
            min: properties.minNumber.toString(),
            value: (properties.defaultValue !== null && properties.defaultValue !== undefined) ? properties.defaultValue.toString() : '',
            type: 'number'
        });

        this.input.addEventListener('input', evt => {
            const newValue = Number((evt.target as HTMLInputElement).value);

            // do not allow invalid numbers
            if (Number.isNaN(newValue)) {
                return;
            }

            if (this.getValueStringLength(newValue) > properties.maxLength) {
                (evt.target as HTMLInputElement).value = this.value.toString();
            } else {
                this.value = newValue;

                if (this.onChange) {
                    this.onChange(this.value);
                }
            }
        });

        setElementAttrs(this.foreignObject, {
            height: properties.height.toString(),
            width: properties.width.toString(),
            x: properties.x.toString(),
            y: properties.y.toString()
        });

        this.foreignObject.innerHTML = `<style>
        input {
            font-size: 40px;
            text-align: center;
            ${this.getBgImageString()}
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
  
        input[type="number"] {
          -moz-appearance: textfield;
        }

        input:focus{
            outline: none;
        }
      </style>`;

        this.foreignObject.appendChild(this.input);
        parent.appendChild(this.foreignObject);
    }

    private getBgImageString(): string {
        if (this.properties.backgroundImage) {
            return `background-image: url("${this.properties.backgroundImage}");
            background-position: center;
            background-repeat: no-repeat;`;
        }

        return '';
    }

    private getValueStringLength(num: number): number {
        let stringNumber = num.toString();
        // if we're ingoring decimals, count the number of numbers in the string
        if (this.properties.ignoreDecimalsInLength) {
            stringNumber = stringNumber.replace('.', '');
        }

        return stringNumber.length;
    }
}
