import { SvgRenderElement, SvgRenderElementType } from './SvgRenderElement';
import { SvgRenderer } from './SvgRenderer';

export type SvgRasterImageConfig = SvgRenderElementType & {
    x: number;
    y: number;
    width?: number;
    height?: number;
    imgUrl: string;
}

const svgLineConfigDefault: Partial<SvgRasterImageConfig> = {
  maintainScale: false,
  x: 0,
  y: 0
}

export class SvgRasterImage extends SvgRenderElement<SvgRasterImageConfig> {
  public constructor(renderer: SvgRenderer, parent: SVGElement, config: SvgRasterImageConfig) {
    super(renderer, parent, config, svgLineConfigDefault);
  }

  public createElement(): void {
    this.element = document.createElementNS(SvgRenderer.svgns, 'image');
  }

  protected onMaintainScale(newScale: number): void {
    // not implemented
  }

  public applyConfigProperties(): void {
    this.setElementAttribute('x', 'x');
    this.setElementAttribute('y', 'y');
    this.setElementAttribute('href', 'imgUrl');

    this.setElementAttribute('width', 'width');
    this.setElementAttribute('height', 'height');
  }
}
