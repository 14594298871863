import { G, Line, StrokeData } from '@svgdotjs/svg.js';
import { SvgRenderer } from '../svg-components/SvgRenderer';
import { KHPoint } from '../pixi-puzzle-framework/PuzzleEngine/KHPoint';

export type LineMarkerConfig = {
    value: string;
    position: KHPoint;
    lineLength: number;
    stroke: StrokeData;
    fontSize: number;
}

export class NumberLineMarker {
    private config: LineMarkerConfig;
    private line: Line;
    private renderer: SvgRenderer;
    private textGroup: G;
    private labels: SVGTextElement[] = [];

    public constructor(renderer: SvgRenderer, config: LineMarkerConfig) {
        this.config = config;
        this.renderer = renderer;
        if (!this.config.fontSize) {
            this.config.fontSize = 30;
        }

        this.setupCameraListener();
    }

    private createLineMarker(): void {
        const half = this.config.lineLength / 2;

        this.line = this.renderer.svg.line(
            this.config.position.x, this.config.position.y - half, this.config.position.x, this.config.position.y + half);
        this.line.stroke(this.config.stroke);
        // this.line.node.setAttribute('vector-effect', 'non-scaling-stroke');
    }

    private createNormalLabel(): SVGTextElement {
        this.makeText(this.config.value.toString(), {x: 0, y: 0});
        return document.createElementNS('http://www.w3.org/2000/svg', 'text');
    }

    private createFractionLabel() {
        const values = this.config.value.split('/');
        const numerator = values[0];
        const denominator = values[1];

        const textNum = this.makeText(numerator, {x: 0, y: 0});
        const textDen = this.makeText(denominator, {x: 0, y: this.config.fontSize + 5});
        const width = Math.max(textNum.clientWidth, textDen.clientWidth);
        const divider = this.renderer.svg.line(-width, textNum.clientHeight, width, textNum.clientHeight);
        divider.stroke({color: this.config.stroke.color, width: 3});
        this.textGroup.add(divider);
    }

    private makeText(value: string, position: KHPoint): SVGTextElement {
        const t = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        if (value) {
            t.innerHTML = Number(value).toFixed(2);
            // Use regular expressions to remove trailing zeros after the decimal point
            t.innerHTML = t.innerHTML.replace(/(\.0*|0+)$/, '');
        }
        
        t.setAttribute('font-size', this.config.fontSize.toString());
        t.setAttribute('text-anchor', 'middle');
        t.setAttribute('font-family', 'Quicksand');
        t.setAttribute('fill', 'white');
        t.setAttribute('x', position.x.toString());
        t.setAttribute('y', position.y.toString());
        this.textGroup.node.appendChild(t);
        this.labels.push(t);
        return t;
    }

    public render(): void {
        this.textGroup = this.renderer.svg.group();
        this.createLineMarker();

        if (this.config.value.includes('/')) {
            this.createFractionLabel();
        } else {
            this.createNormalLabel();
        }

        this.renderer.svg.node.appendChild(this.textGroup.node);
        const xPos = this.config.position.x;
        const yPos = this.config.position.y + (this.config.lineLength * 2);
        this.textGroup.attr('transform', this.getTransformString(1, xPos, yPos));
    }

    public setupCameraListener(): void {
        this.renderer.camera.addOnScaleChangeListener(this.onScaleChange.bind(this));
    }

    private getTransformString(scale: number, x: number, y: number): string {
        return `translate(${x} ${y}) scale(${scale} ${scale})`;
    }

    private onScaleChange(newScale: number): void {
        const lineLengthScaled = this.config.lineLength * newScale;
        const xPos = this.config.position.x;
        const newStrokeData = {...this.config.stroke};

        if (newStrokeData.width) {
            newStrokeData.width *= newScale;
        }

         // this is done as a sort of anchoring to the bottom of the line
         // eg if the label is 5 visual pixels away when we scale and zoom everything we need to maintain a 5px visual spacing
        const yPos = this.config.position.y + (lineLengthScaled * 2);

        this.textGroup.attr('transform', this.getTransformString(newScale, xPos, yPos));
        this.line.transform({scale: newScale});
    }
}
