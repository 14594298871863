import { ActivatedRoute } from '@angular/router';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';

import { IEvent } from 'fabric/fabric-impl';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TEMPLATE_PREFIX } from '../../../../environments/locale-config';
import { FabricCanvas } from '../../../../services/fabric-canvas.service';
import { FabricConstantsService } from '../../../../services/fabric-constants.service';
import { FabricExtendedCanvas, ExtendedObject } from '../../../fabric/angular-fabric';
import { AngularFabricService } from '../../../fabric/angular-fabric.service';
import { AlgebraTileAddComponent } from '../../../modals';
import { IStudentMusicService, STUDENT_MUSIC_SERVICE_TOKEN } from '../../../../services/IStudentMusicService';
import { IMusicService, MUSIC_SERVICE_TOKEN } from '../../../../services/IMusicService';
import { Inject } from '@angular/core';
import { IMathRenderingService, MATH_RENDERING_SERVICE_TOKEN } from '../../../../services/IMathRenderingService';
import { IModalService, MODAL_SERVICE_TOKEN } from '../../../../services/IModalService';
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from '../../../../services/IQuestionEvents';

const MIN_SCALE = 1;

@UntilDestroy()
@Component({
  providers: [FabricCanvas, AngularFabricService],
  selector: 'kh-manip-algebra-tiles',
  styleUrls: ['./manip-algebra-tiles.component.less'],
  templateUrl: TEMPLATE_PREFIX + 'manip-algebra-tiles.component.html'
})
export class ManipAlgebraTilesComponent implements OnInit, AfterViewInit {
  @ViewChild('container') public parentContainer?: ElementRef<HTMLDivElement>;
  @ViewChild('canvas') public canvasEl?: ElementRef<HTMLCanvasElement>;

  public questionClosed = false;
  public randomId = Math.floor(Math.random() * 9999);
  public initValue?: string;

  @Input() public displaySummary = false;
  @Output() public expressionChange = new EventEmitter<string>();
  @Output() public submitAnswer = new EventEmitter<string>();

  private expressionValue = '';
  private algetileCanvasWidth = 300;
  private algetileCanvasHeight = 210;
  private allTiles = ['-x^2', 'x^2', '-x', 'x', '-1', '1'];
  private internalExpression = '``';
  private canvas!: FabricExtendedCanvas;
  private musicService!: IMusicService | IStudentMusicService;

  public hideStudentManipControlBar = this.questionEventsService.hideStudentManipControlBar.asObservable();

  public constructor(
    @Inject(MATH_RENDERING_SERVICE_TOKEN) private readonly mathRenderingService: IMathRenderingService,
    @Inject(MODAL_SERVICE_TOKEN) private readonly modalService: IModalService,
    private readonly hostElement: ElementRef,
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) private readonly questionEventsService: IQuestionEventsService,
    private readonly fabricConstants: FabricConstantsService,
    private readonly angularFabric: AngularFabricService,
    private readonly renderer: Renderer2,
    @Inject(STUDENT_MUSIC_SERVICE_TOKEN) private readonly studentMusicService: IStudentMusicService,
    @Inject(MUSIC_SERVICE_TOKEN) private readonly sharedMusicService: IMusicService,
    private readonly route: ActivatedRoute) {
  }

  @HostListener('window:resize')
  public onResize() {
    this.resizeWorks();
  }

  public resizeWorks(): void {
    const elemParentNode = this.hostElement.nativeElement.parentNode;
    (this.hostElement.nativeElement as HTMLDivElement).hidden = true;
    this.renderer.setStyle(this.hostElement.nativeElement, 'display', 'none');

    const scale = this.calculateScale(elemParentNode.clientWidth);

    this.renderer.setStyle(this.hostElement.nativeElement, 'display', 'flex');
    (this.hostElement.nativeElement as HTMLDivElement).hidden = false;
    this.angularFabric.setCanvasSize(this.algetileCanvasWidth * scale, this.algetileCanvasHeight * scale);
    this.canvas.setZoom(scale);
    this.canvas.renderAll();
  }

  private calculateScale(clientWidth: number): number {
    const scale = Math.min(MIN_SCALE, clientWidth / this.algetileCanvasWidth);

    return scale || MIN_SCALE;
  }

  public get selectedObject(): ExtendedObject | undefined {
    return this.angularFabric.selectedObject.value;
  }

  public get expression(): string {
    return this.expressionValue;
  }

  @Input()
  public set expression(value: string) {
    this.initValue = value;
  }

  public ngOnInit(): void {
    this.questionEventsService.hideAllInputForManip.next(true);
    this.questionEventsService.questionClosed
      .pipe(
        tap(() => {
          this.questionClosed = true;
        }),
        untilDestroyed(this)
      )
      .subscribe();

    // HACK: Temporary solution. Should be refactored to receive component mode (isPreview: true/false) as an input.
    this.musicService = this.route.snapshot.url.find(segment => segment.path === 'student')
      ? this.studentMusicService
      : this.sharedMusicService;
  }

  public ngAfterViewInit(): void {
    this.initializeFabric();
    this.canvas = this.angularFabric.getCanvas();

    this.angularFabric.canvas.on('object:moving', (options: IEvent) => this.onChange(options));

    this.angularFabric.canvas.on({
      'selection:created': (options: IEvent) => this.onSelectionChanged(options),
      'selection:updated': (options: IEvent) => this.onSelectionChanged(options)
    });

    this.angularFabric.selectedObject.pipe().subscribe();

    this.canvas.on('mouse:up', () => this.onMouseUp());

    this.resizeWorks();

    if(this.initValue)
    {
      this.onExpressionSet(this.initValue);
    }
  }

  public updateExpression(): void {
    this.internalExpression = this.getExpression();
    this.expressionValue = this.internalExpression;

    this.mathRenderingService.renderContent(true);
  }

  public onChange(options: IEvent): void {
    options.target?.setCoords();

    const sourceTile = this.getTileType(options.target);
    let hasIntersection = false;

    const allObjects = this.canvas.getObjects();

    for (let i = 0; i < allObjects.length; i++) {
      const obj = allObjects[i];

      if (obj === options.target) {
        break;
      }

      if (hasIntersection) {
        obj.opacity = 1;
        break;
      }

      if (options.target?.intersectsWithObject(obj)) {
        const TargetTile = this.getTileType(obj);

        if (((sourceTile[0] === '-') && (TargetTile[0] !== '-')) || ((sourceTile[0] !== '-') && (TargetTile[0] === '-'))) {
          if (sourceTile[sourceTile.length - 1] === TargetTile[TargetTile.length - 1]) {
            hasIntersection = true;

            obj.opacity = 0.4;
            options.target.opacity = 0.4;
          }
        }
      }
      else {
        obj.opacity = 1;
      }
    }

    if (!hasIntersection && options.target) {
      options.target.opacity = 1;
    }
  }

  public rotate(): void {
    const selectedTile = this.angularFabric.selectedObject.value;

    if (!selectedTile) {
      return;
    }

    selectedTile.animate('angle', (selectedTile.angle ?? 0) + 90, {
      onChange: () => this.canvas.renderAll()
    });
  }

  public toggleNegative(): void {
    const tile = this.angularFabric.selectedObject.value as any;

    if (!tile) {
      return;
    }

    const tileType = this.getTileType(tile);

    if (tileType[0] === '-') {
      tile.text = tileType.substring(1);
    }
    else {
      tile.text = '-' + tileType;
    }

    tile.animate('opacity', 0, {
      duration: 100,
      onChange: () => this.canvas.renderAll(),
      onComplete: () => {
        tile.setSrc('images/tiles/' + tile.text.replace('^', '') + '.svg', () => { /**/ }, {
          crossOrigin: 'true',
          filters: []
        });
        tile.animate('opacity', 100, {
          duration: 100,
          onChange: () => this.canvas.renderAll(),
          onComplete: () => { /**/ }
        });
      }
    });


    this.angularFabric.setDirty(true);
    this.updateExpression();
    this.canvas.renderAll();
  }

  public delete(): void {
    this.angularFabric.selectedObject.value?.animate('opacity', 0, {
      duration: 400,
      onChange: () => this.canvas.renderAll(),
      onComplete: () => {
        this.angularFabric.deleteActiveObject();
        this.angularFabric.setDirty(true);
        this.updateExpression();
      }
    });
  }

  public duplicate(): void {
    const selectedTile = this.angularFabric.selectedObject.value as any; // TODO: need to set correct class

    selectedTile?.cloneAsImage((cloned: any) => {
      const NewTile = cloned;
      NewTile.left += 15;
      NewTile.top += 15;
      NewTile.setCoords();
      NewTile.text = selectedTile.text;

      NewTile.hasControls = false;

      this.angularFabric.addObjectToCanvas(NewTile);

      this.angularFabric.setDirty(true);

      this.angularFabric.forceRefresh();

      this.updateExpression();
    });

    return;
  }

  public openAddTileModal(): void {
    this.musicService.playSound('pop.mp3');

    const modal = this.modalService.displayModal<AlgebraTileAddComponent>(AlgebraTileAddComponent, {allTiles: this.allTiles}, 'md', true);

    modal.content?.add.pipe(
      tap((tile: string) => this.addTile(tile)),
      untilDestroyed(this)
    ).subscribe();
  }

  public submit(expression: string): void {
    this.submitAnswer.emit(expression);
  }

  private initializeFabric(): void {
    const options = {
      JSONExportProperties: this.fabricConstants.JSONExportProperties,
      shapeDefaults: this.fabricConstants.shapeDefaults,
      textDefaults: this.fabricConstants.textDefaults
    };

    const canvas = this.canvasEl?.nativeElement as HTMLCanvasElement;

    this.angularFabric.initialize(options, canvas);

    this.angularFabric.setCanvasSize(this.algetileCanvasWidth, this.algetileCanvasHeight);
  }

  private onExpressionSet(expression: string): void {
    const isExternallyUpdated = expression !== null && (this.internalExpression !== expression);
    if (!isExternallyUpdated || !(expression.length > 2 && expression[0] === '`' && expression[expression.length - 1] === '`')) {
      return;
    }

    let tempExpression = expression
      .split(' ')
      .join('')
      .split('`')
      .join('');

    for (let i = 0; i < this.allTiles.length; i++) {
      const itemLocation = tempExpression.indexOf(this.allTiles[i]);
      if (itemLocation >= 0) { // Exponent exists
        let NumInstances = 1;

        let j = 1;
        while (itemLocation >= j && !isNaN(+tempExpression[itemLocation - j])) {
          NumInstances = parseInt(tempExpression.substring(itemLocation - j, itemLocation), 10);
          j++;
        }
        tempExpression = tempExpression.replace(NumInstances + this.allTiles[i], '').replace(this.allTiles[i], '');
        for (j = 0; j < NumInstances; j++) {
          this.addTile(this.allTiles[i]);
        }
      }
    }
  }

  private getTileType(tile: any): string {
    return tile.text;
  }

  private addTile(tile: string): void {
    this.musicService.playSound('pop.mp3');

    this.angularFabric.addImage('images/tiles/' + tile.replace('^', '') + '.svg', (newTile: any) => {
      newTile.text = tile;
      newTile.top = Math.floor((Math.random() * 150) + 40);
      newTile.left = Math.floor((Math.random() * 200) + 40);

      newTile.hasControls = false;

      this.angularFabric.forceRefresh();

      this.updateExpression();
    });

    this.canvas.renderAll();
  }

  private getExpression(): string {
    const numTiles = [0, 0, 0, 0, 0, 0];
    this.canvas.forEachObject((obj: any) => {
      numTiles[this.allTiles.indexOf(this.getTileType(obj))]++;
    });

    let expression = '';

    for (let i = 0; i < numTiles.length; i++) {
      if (numTiles[i] >= 1) {
        if (this.allTiles[i][0] === '-') {
          expression += ' - ' +
            (numTiles[i] === 1 ? '' : numTiles[i]) +
            (numTiles[i] > 1 && this.allTiles[i] === '-1' ? '' : this.allTiles[i].substring(1, 256));
        }
        else {
          expression += ' + ' +
            (numTiles[i] === 1 ? '' : numTiles[i]) +
            (numTiles[i] > 1 && this.allTiles[i] === '1' ? '' : this.allTiles[i]);
        }
      }
    }

    if (expression.length > 0) {
      if (expression[1] === '-') {
        expression = '-' + expression.substring(3, 1027);
      }
      else {
        expression = expression.substring(3, 1027);
      }
    }

    return expression === '' ? expression : '`' + expression + '`';
  }

  private onMouseUp(): void {
    const objectsToDelete: any[] = [];

    const allObjects = this.canvas.getObjects();
    for (let i = 0; i < allObjects.length; i++) {
      const obj = allObjects[i];

      if (obj.opacity === 0.4) {
        objectsToDelete.push(obj);
      }

      if (obj.left && this.canvas.width && (obj.left > this.canvas.width || obj.left < 0)) {
        objectsToDelete.push(obj); // Changed, if you drag off the workspace it gets deleted
      }

      if (obj.top && this.canvas.height && (obj.top > this.canvas.height || obj.top < 0)) {
        objectsToDelete.push(obj);
      }
    }

    if (objectsToDelete.length === 2) {
      this.angularFabric.addImage('images/poof.png', (negateImage: any) => {
        negateImage.top = objectsToDelete[0].top - 15;
        negateImage.left = objectsToDelete[0].left;

        negateImage.hasControls = false;
        negateImage.setOptions({
          borderColor: 'transparent'
        });

        this.angularFabric.deselectActiveObject();

        this.musicService.playSound('negative.mp3');

        negateImage.animate('opacity', 0, {
          duration: 600,
          onChange: () => this.canvas.renderAll(),
          onComplete: () => {
            this.canvas.remove(negateImage);
          }
        });

        negateImage.animate('top', '-=20', {
          duration: 600,
          onChange: () => this.canvas.renderAll()
        });
      });

      objectsToDelete[0].animate('opacity', 0, {
        duration: 400,
        onChange: () => this.canvas.renderAll(),
        onComplete: () => {
          this.canvas.remove(objectsToDelete[0]);
          this.updateExpression();
        }
      });
      objectsToDelete[1].animate('opacity', 0, {
        duration: 400,
        onChange: () => this.canvas.renderAll(),
        onComplete: () => {
          this.canvas.remove(objectsToDelete[1]);
          this.updateExpression();
        }
      });
    }

    if (objectsToDelete.length === 1) {
      this.canvas.remove(objectsToDelete[0]);
      setTimeout(() => {
        this.updateExpression();
      });
    }
  }

  private onSelectionChanged(options: IEvent): void {
    options.target?.bringToFront();
    this.angularFabric.selectedObject.next(options.target as ExtendedObject);
  }
}
