import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface IQuestionEventsService {
  submitAnswerCompleted: Subject<any>;
  submitAnswerFailed: Subject<any | void>;
  questionClosed: Subject<any>;

  hideInputOnlyForManip: BehaviorSubject<boolean>;
  hideAllInputForManip: BehaviorSubject<boolean>;
  hideStudentManipControlBar: BehaviorSubject<boolean>;

  disableButtonForPuzzle: BehaviorSubject<boolean>;
  puzzleSubmit$: Observable<void>;

  puzzleSubmitButtonClicked(): void;
}

export const QUESTION_EVENTS_SERVICE_TOKEN = new InjectionToken<IQuestionEventsService>('IQuestionEventsService');