<div class="ManipAddingBlocks">
    <div class="animate-show" *ngIf="topRow.length > 0 || bottomRow.length > 0">
        <div [ngClass]="{Squeeze: rowTiles > 5}">
            <div class="TopRow">
                <div class="block_counter" [ngClass]="{IsNeg: topValue < 0}">
					<span *ngIf="noZeroValue(topValue)">
						{{ topValue | numWithSign }}
					</span>
                </div>
                <div class="BlockContainer" *ngFor=" let block of topRow; index as ind"  [ngClass]="{'IsZeroPair': ind < numZeroBlocks}" (click)="removeBlock(topRow, ind)">
                    <div class="Block animated bounceIn" [ngClass]="{'IsNeg': block.value === -1}">{{block.value | numWithSign | minusToDash}}</div>
                </div>
            </div>
            <div class="BottomRow">
                <div class="block_counter" [ngClass]="{IsNeg: bottomValue < 0}">
                    <span *ngIf="noZeroValue(bottomValue)">{{bottomValue | numWithSign | minusToDash}}</span>
                </div>
                <div class="BlockContainer" *ngFor=" let block of bottomRow; index as ind" [ngClass]="{'IsZeroPair': ind < numZeroBlocks}" (click)="removeBlock(bottomRow, ind)">
                    <div class="Block animated bounceIn" [ngClass]="{'IsNeg': block.value === -1}">{{block.value | numWithSign | minusToDash}}</div>
                </div>
            </div>
            <div class="BlockZeroSum IsZeroPair BlockZeroSum-{{numZeroBlocks}}" *ngIf="numZeroBlocks > 0">
                <span *ngIf="numZeroBlocks > 1">{{numZeroBlocks}} + (–{{numZeroBlocks}})</span> = 0
            </div>
        </div>

        <div class="BlockSummary">
            {{value | minusToDash}} <!--= {{Total}}-->
        </div>
    </div>

    <button *ngIf="isOppositeSigns" (click)="AddBlock(topRow, 1)" class="btn btn-sm btn-primary">Add <b>+1</b></button>
    <button *ngIf="isOppositeSigns" (click)="AddBlock(bottomRow, -1)" class="btn btn-sm btn-primary">Add <b>&ndash;1</b></button>
    <button *ngIf="isSameSigns" (click)="AddBlock(bottomRow, blockValue)" class="btn btn-sm btn-primary">Add <b>{{blockValue | numWithSign}}</b></button>
    <a (click)="Reset()" class="cursor reset-btn">Reset</a>
</div>
