import { Draggable } from "gsap/Draggable";
import { gsap, Linear, Power1 } from "gsap";
import { PuzzleBase } from "../puzzle/puzzleBase";
const svgns = "http://www.w3.org/2000/svg";

gsap.registerPlugin(Draggable);

// Data Interface
interface GearData {
  editable: boolean;
  numerator: number;
  denominator: number;
  direction: boolean;
}

interface Puzzle {
  mode: string;
  input: string;
  shipWidth: number;
  min: number;
  max: number;
  attempts: Array<boolean>;
  numberLineDenominator: number;
  allowPartitionEdits: boolean;
  batteries: Array<GearData>;
  timeStep: number;
  shipLocation: number;
}

// UI Interface
interface Gear extends SVGGElement {
  wheel: SVGGElement;
  ribbon: SVGCircleElement;
  texture: SVGUseElement;
  direction: boolean;
  wrench: SVGUseElement;
  fraction: Fraction;
  editing: boolean;
  data: GearData;
  inPlay: boolean;
  _x: number;
  set: (data?: GearData) => void;
}

interface ShipGroup extends SVGGElement {
  ship: SVGUseElement;
  beam: SVGUseElement;
}

interface Frame {
  width: number;
  height: number;
}

// MOO. Hey ben here's the explanations for each property
interface RollbotData {
  width: number;
  height: number;
  center: number; // x value of the center of the wheel. (good for scoring)
  offset: number; // starting x position of the robot
  diameter?: number; // diamater of the wheel.
  fraction?: number; // Fraction that the whole is supposed to represent.
  id: string;
}

interface Fraction extends SVGGElement {
  ticks: Array<SVGGElement>;
  lineGroup: SVGGElement;
  innerCircle: SVGCircleElement;
  outerCircle: SVGCircleElement;
  setTicks: (a: number) => void;
}

export class RollbotPuzzle extends PuzzleBase {
  // #region CLASS PROPERTIES

  // UI Elements
  private arena: SVGSVGElement;
  private gearsInPlay: Array<Gear> = [];
  private robot: SVGUseElement;
  private ground: SVGUseElement;
  private curtain: SVGRectElement;
  private ticks: Array<SVGLineElement> = [];
  private pad: SVGGElement;
  private gearsInEditing: Array<Gear> = [];
  private background: SVGGElement;
  private spaceShip: SVGUseElement;
  private beam: SVGUseElement;
  private gearPool: Array<Gear> = [];
  private shipGroup: ShipGroup = (document.createElementNS(
    svgns,
    "g"
  ) as any) as ShipGroup;
  private radioCircle: SVGCircleElement;
  private numberLine: SVGLineElement;
  private arrow: SVGUseElement;
  private arrowGroup: SVGGElement;
  private correct: boolean = false
  private freeze: boolean = true;

  // Initial Bounding Boxes
  private shipFrame: Frame = { height: 144, width: 300 };
  private beamFrame: Frame = { height: 0, width: 60 };


  // Timelines
  private timeline: gsap.core.Timeline;
  private wandtimeline: gsap.core.Timeline;
  private shiptimeline: gsap.core.Timeline;
  private scoringtimeline: gsap.core.Timeline;
  private arrowtimeline: gsap.core.Timeline;


  private currentPuzzle: Puzzle;
  private point: SVGPoint;
  private shipLocation: number;
  private props: any


  // Transient State
  private feedbackEnded = false;

  // Constants
  private PADDING = 110;
  private CIRCUMFRENCE: number = (1280 - 2 * this.PADDING) / 3;
  private DIAMETER: number = this.CIRCUMFRENCE / Math.PI;
  private RIBBON_WIDTH = 10;
  private BATTERY_RADIUS = 25;
  // Ribbon offset because it doesn't scale with object (and we don't want itinit to)
  private BATTERY_SCALE: number =
    (this.DIAMETER / 2 - this.RIBBON_WIDTH) / this.BATTERY_RADIUS;
  private STROKE_FRACTION = "#ffffff";
  private STROKE_NUMBER_LINE = "#d547f5";
  private STROKE_TICKS = "#d547f5";
  private STROKE_MAJOR_TICKS = "#d547f5";
  private COLOR_BATTERY_FORWARD = "#2bff43";
  private COLOR_BATTERY_BACKWARD = "red";
  private NUMBER_LINE_Y = 550;
  private CENTER_X = 640;

  // "rollbot12" would be the half rollbot, rollbot23 would two thirds etc.
  // Offset is calculated by subtracting the center value from the distance to the first tick (i.e. 0) which is as of this writing equal to 110.
  private ROLLBOT_PROPS: { [key: string]: RollbotData } = {
    rollbot1: {
      width: 213,
      height: 340.8,
      center: 85.1,
      offset: 24.9,
      diameter: 112.5,
      fraction: 1,
      id: "rollbot1",
    },
    rollbot2: {
      width: 325.6,
      height: 484.3,
      center: 122.2,
      offset: -12,
      diameter: 225,
      fraction: 2,
      id: "rollbot2",
    },
    rollbot34: {
      width: 180.7,
      height: 310.5,
      center: 72,
      offset: 38,
      diameter: 84.5,
      fraction: 0.75,
      id: "rollbot34",
    },
    rollbot112: {
      width: 225.3,
      height: 413.8,
      center: 88.5,
      offset: 21.5,
      diameter: 168.7,
      fraction: 1.5,
      id: "rollbot112",
    },
    rollbot12: {
      width: 131.7,
      height: 221.6,
      center: 54.9,
      offset: 54.1,
      diameter: 56.25,
      fraction: 0.5,
      id: "rollbot12",
    },
  };
  public currentBot = this.ROLLBOT_PROPS.rollbotOne as any;
  

  // #endregion

  public constructor(dom: any, props: any) {
    super({ attempts: [] });

    let botTitle = props.rollbot;

    this.currentBot = this.ROLLBOT_PROPS[botTitle] as RollbotData;

    this.props = props

    this.BATTERY_SCALE =
      this.currentBot.diameter / (2 * this.BATTERY_RADIUS + 10);

    this.arena = dom.arena;
    this.background = dom.background;

    // Initialize puzzles
    this.currentPuzzle = JSON.parse(JSON.stringify(props));
    this.shipLocation = this.currentPuzzle.shipLocation;

    // Constants
    this.timeline = gsap.timeline({
      onComplete: this.onFeedBackEnded.bind(this),
      paused: true,
    });
    this.wandtimeline = gsap.timeline({
      onComplete: this.onWandComplete.bind(this),
      paused: true,
    });

    this.shiptimeline = gsap.timeline({ paused: true,onComplete: this.onShipTimelineComplete.bind(this) });
    this.scoringtimeline = gsap.timeline({
      onComplete: this.onFinalFeedbackEnded.bind(this),
      paused: true,
    });

    this.arrowtimeline = gsap.timeline({repeat: 2, paused: true});

    this.point = this.arena.createSVGPoint();

    this.init();
  }

  // #region GENERATOR FUNCTIONS

  // we might not need offset anumore
  public createFraction(r: number, hole: number, den: number, offset = 0) {
    const sf = this.STROKE_FRACTION;
    const g = document.createElementNS(svgns, "g") as Fraction;
    g.ticks = [];
    const innerCircleOrigin = {
      x: r - hole * r,
      y: r - hole * r,
    };

    const _strokeWidth = r / 20;

    g.innerCircle = document.createElementNS(svgns, "circle");
    gsap.set(g.innerCircle, {
      attr: { r: r * hole },
      fillOpacity: 0,
      stroke: sf,
      strokeWidth: _strokeWidth,
    });

    g.outerCircle = document.createElementNS(svgns, "circle");
    gsap.set(g.outerCircle, {
      attr: { r: r },
      fillOpacity: 0,
      stroke: sf,
      strokeWidth: _strokeWidth,
    });

    for (let i = 0; i < 12; i++) {
      g.lineGroup = document.createElementNS(svgns, "g");

      const shortline = document.createElementNS(svgns, "line");
      gsap.set(shortline, {
        attr: { x1: 0, y1: r * hole, y2: r },
        stroke: sf,
        strokeWidth: _strokeWidth,
      });

      const longline = document.createElementNS(svgns, "line");
      gsap.set(longline, {
        attr: { x1: 0, y1: r },
        stroke: "white",
        strokeOpacity: 0.001,
        strokeWidth: _strokeWidth,
      });

      g.lineGroup.appendChild(shortline);
      g.lineGroup.appendChild(longline);

      g.ticks.push(g.lineGroup);

      g.appendChild(g.lineGroup);
    }
    g.appendChild(g.innerCircle);
    g.appendChild(g.outerCircle);

    g.setTicks = (den) => {
      g.ticks.forEach((t, i) => {
        if (den == 1) {
          gsap.set(t, { alpha: 0 });
        } else if (i > den) {
          gsap.set(t, { alpha: 0, rotation: offset });
        } else {
          g.appendChild(t);
          gsap.set(t, { alpha: 1, rotation: offset + (360 / den) * i });
        }
      });
    };

    return g;
  }

  public createPad(keys: string[], padding: number) {

    if (keys[0] == "all"){
      keys = ["subpiece","addpiece","reverse","subpart","addpart"]
    } else if (keys[0] == "parts"){
      keys = ["subpart","addpart"]
    } else if (keys[0]== "pieces"){
      keys = ["subpiece","addpiece"]
    } else if (keys[0]== "gears"){
      keys = ["subgear","addgear"]
    } else if (keys[0]== "noreverse"){
      keys = ["subpiece","addpiece","subpart","addpart"]
    } else if (keys[0]== "gearparts"){
      keys = ["subgear","addgear","subpart","addpart"]
    } 

    const w = 80;
    let l = keys.length;
    let delta = w + padding;

    let p = document.createElementNS(svgns, "g");

    // Create Background Rect.
    let b = document.createElementNS(svgns, "rect");

    b.setAttribute("id", "backgroundrect");
    
    gsap.set(b, {
      width: l * delta,
      height: delta,
      fill: "#23A3FF",
      transformOrigin: "50% 50%",
      attr: { rx: delta / 3, ry: delta / 3 },
    });

    p.appendChild(b);

    keys.forEach((k, i) => {
      let key = document.createElementNS(svgns, "use");
      key.setAttribute("href", "#" + k);
      key.setAttribute("id", k);
      gsap.set(key, { y: padding, x: padding + i * delta });
      p.appendChild(key);
    });

    p.addEventListener("pointerdown", this.controlPadDown.bind(this));

    this.arena.appendChild(p);

    gsap.set(p, {transformOrigin: "50% 50%", scale: 1.5, x: 450, y: 100 });

    return p;
  }

  // Assums for now that spaceshipt and beam have already been created.
  public createShipGroup() {
    const g = (document.createElementNS(svgns, "g") as any) as ShipGroup;
    g.appendChild(this.spaceShip);
    g.appendChild(this.beam);

    return g;
  }

  public createTick(height = 30, strokeWidth = 5, color = this.STROKE_TICKS) {
    const t = document.createElementNS(svgns, "line");
    gsap.set(t, {
      attr: { y2: height },
      stroke: color,
      strokeLinecap: "round",
      pointerEvents: "none",
      strokeWidth: strokeWidth,
    });

    return t;
  }

  public getGear(setup: GearData) {
    // Update Battery State

    const g = document.createElementNS(svgns, "g") as Gear;
    g.data = setup;

    const _wheel = document.createElementNS(svgns, "g");

    const _texture = document.createElementNS(svgns, "use");
    _texture.setAttribute("href", "#battery");

    //const _wrench = document.createElementNS(svgns, 'use');
    //_wrench.setAttribute('href', '#wrench');
    //gsap.set(_wrench, { alpha: 0, scale: 0.85, transformOrigin: '50% 50%', x: -36, y: -35 });

    // MOOOOOOOO - Manual texture offset. Based on geometry of the battery/gear.
    gsap.set(_texture, { x: -40, y: -40, transformOrigin: "50% 50%" });

    const _circumfrence = 2 * this.BATTERY_RADIUS * Math.PI;

    const _ribbon = document.createElementNS(svgns, "circle");

    g.fraction = this.createFraction(
      this.BATTERY_RADIUS + this.RIBBON_WIDTH / 2,
      0.65,
      setup.denominator
    );

    //_wheel.appendChild(_texture)
    _wheel.appendChild(_ribbon);
    _wheel.appendChild(g.fraction);

    //_wheel.appendChild(_wrench);

    gsap.set(_wheel, { scale: 1, transformOrigin: "50% 50%" });

    g.ribbon = _ribbon;
    g.wheel = _wheel;
    g.texture = _texture;
    //g.wrench = _wrench;

    g.appendChild(_texture);
    g.appendChild(_ribbon);
    g.appendChild(_wheel);

    g.set = (newData?: GearData | null) => {
      if (newData) {
        g.data = newData;
      }

      const a = Math.abs(g.data.numerator);
      const b = Math.abs(g.data.denominator);

      g.fraction.setTicks(b);

      // Direction is boolean. (Forward,true,backward, false)
      const _color =
        g.data.numerator >= 0
          ? this.COLOR_BATTERY_FORWARD
          : this.COLOR_BATTERY_BACKWARD;

      const arc = _circumfrence * (1 - a / b);

      gsap.set([g.wheel, g.texture], { rotation: 0 });

      if (g.data.numerator >= 0) {
        gsap.set(_ribbon, {
          attr: { r: this.BATTERY_RADIUS },
          fillOpacity: 0,
          rotation: 90,
          scaleX: 1,
          scaleY: -1,
          stroke: _color,
          strokeDasharray: _circumfrence,
          strokeDashoffset: arc,
          strokeWidth: 10,
        });
      } else {
        gsap.set(_ribbon, {
          attr: { r: this.BATTERY_RADIUS },
          fillOpacity: 0,
          rotation: 90,
          scaleX: 1,
          scaleY: 1,
          stroke: _color,
          strokeDasharray: _circumfrence,
          strokeDashoffset: arc,
          strokeWidth: 10,
        });
      }
    };

    g.set();

    return g;
  }

  public poolTicks() {
    const tickCount = 36;
    for (let i = 0; i <= tickCount; i++) {
      const _t = this.createTick();
      this.ticks.push(_t);
    }
  }

  // #endregion

  // #region LIFECYCLE METHODS

  public scorePuzzle() {
    const rbox = {
      width: this.currentBot.width,
      height: this.currentBot.height,
    };
    const rx = Number(gsap.getProperty(this.robot, "x"));
    const ry = Number(gsap.getProperty(this.robot, "y"));

    // Supposed to be at

    // new score method
    const c = this.shipLocation as number;
    const w = this.currentPuzzle.shipWidth as number;

    const valMin = 1 * c - w / 2;
    const valMax = 1 * c + w / 2;

    const centerVal = this.gearSum() * this.currentBot.fraction; // because robot starts at zero - THIS DOESN"T WORK ANYMORE (now it does with offset)
    const centerX = rx + this.currentBot.center;

    this.scoringtimeline?.clear();

    if (centerVal <= valMax && centerVal >= valMin) {
      this.correct = true
      this.scoringtimeline.to(this.robot, {
        duration: 2,
        y: -rbox.height,
        ease: Linear.easeNone,
      });
      this.scoringtimeline.to(this.beam, { duration: 0.5, scaleY: 0 });
      this.scoringtimeline.to(this.shipGroup, { duration: 1, y: 250 });
      this.scoringtimeline.to(this.shipGroup, {
        duration: 1.5,
        ease: "elastic",
        x: 200,
        y: 100,
      });
      this.scoringtimeline.to(this.shipGroup, { duration: 0.7, scale: 0 }, "<");
    } else {
      this.correct = false

      gsap.set(this.radioCircle, { alpha: 1, scale: 0, x: centerX, y: ry });
      this.scoringtimeline.to(this.radioCircle, {
        alpha: 0,
        duration: 1,
        scale: 20,
      });
      this.scoringtimeline.set(this.radioCircle, { alpha: 1, scale: 1 });
      this.scoringtimeline.to(this.radioCircle, {
        alpha: 0,
        duration: 1,
        scale: 20,
      });
      this.scoringtimeline.set(this.radioCircle, { alpha: 1, scale: 1 });
      this.scoringtimeline.to(this.radioCircle, {
        alpha: 0,
        duration: 1,
        scale: 20,
      });
      this.scoringtimeline.set(this.radioCircle, { alpha: 1, scale: 1 });
      this.scoringtimeline.to(this.radioCircle, {
        alpha: 0,
        duration: 1,
        scale: 20,
      });
      this.scoringtimeline.set(this.radioCircle, { alpha: 1, scale: 0 });
    }

    this.scoringtimeline.play();
  }

  public setInteractivity(freeze: boolean) {
    const elements = [this.shipGroup, ...this.gearsInPlay, this.arena];
    if (freeze) {
      gsap.set(elements, { pointerEvents: "none" });
    } else {
      gsap.set(elements, { pointerEvents: "auto" });
    }
  }

  // On curtain pointer down. Should abaondon all editing.
  public abandonEditing(emergency: Boolean | void) {
    let rate = emergency ? 0 : 0.5;
    this.gearsInPlay.forEach((b, i) => {
      gsap.to(b, {
        onComplete: () => {
          gsap.set(this.curtain, { pointerEvents: "none" });
        },
        scale: 1,
        duration: rate,
        y: this.NUMBER_LINE_Y + 75,
        x: b._x,
      });
      b.editing = false;
    });

    gsap.to(this.curtain, { alpha: 0, duration: rate });
    gsap.to(this.pad, { y: 1000, duration: rate });
  }

  public feedbackPlay() {
    this.buildTimeline();
    setTimeout(() => {
      this.timeline.play();
    }, 1000);
  }

  public onFeedBackEnded() {
    gsap.set(this.arena, { pointerEvents: "none" });
    // Use to put "feedbackEnded = true" here, but moved to "final feedback ended"
    this.scorePuzzle();
  }

  public onFinalFeedbackEnded() {
    if (this.onPuzzleAnimationEnd) {
      this.onPuzzleAnimationEnd();
      this.feedbackEnded = true; // Since the "play" function depends on all feedback being over.
      this.recordAnswerAttempt(this.correct);
    }
  }

  public onShipTimelineComplete(){
    console.log("ship timeline complete")
    this.freeze = false
    this.pointArrow()
  }

  public pointArrow(){
    this.arrowtimeline.restart()
  }

  public loadPuzzle(puzzle: Puzzle) {

    this.correct = false
    this.freeze = true

    this.shipLocation = puzzle.shipLocation;

    // Prepare Arena
    gsap.set(this.arena, { pointerEvents: "auto" });
    gsap.set(this.curtain, { alpha: 0, pointerEvents: "none" });
    gsap.set(this.robot, {
      pointerEvents: "none",
      x: this.currentBot.offset,
      y: this.NUMBER_LINE_Y - this.currentBot.height,
    });

    gsap.set(this.shipGroup, { y: -300 });
    gsap.set(this.beam, { scaleY: 0 });

    if (puzzle.input === "ship") {
      gsap.set(this.shipGroup, { pointerEvents: "auto" });
      Draggable.create(this.shipGroup, {
        onDragEnd: this.onDragEnd.bind(this),
        onDragStart: this.onDragStart.bind(this),
        type: "x",
      });
    } else {
      gsap.set(this.shipGroup, { pointerEvents: "none" });
    }

    this.feedbackEnded = false;
    this.gearsInPlay = [];
    this.currentPuzzle = puzzle;


    console.log(this.currentPuzzle,"current puzzle")

    const newBatteries = this.currentPuzzle.batteries;
    const numOfBatteries = this.currentPuzzle.batteries.length;
    const batteryLength = 100 * (numOfBatteries - 1);
    const batteryX = 1280 / 2 - batteryLength / 2;

    this.drawTicks();
    this.arena.appendChild(this.numberLine);

// Pool gear assets
    this.gearPool.forEach((b, i) => {
      if (i < numOfBatteries) {
        gsap.set(b, {
          transformOrigin: "50% 50%",
          x: batteryX + 100 * i,
          y: this.NUMBER_LINE_Y + 75,
        });
        b.set(newBatteries[i]);
        this.arena.append(b);
        this.gearsInPlay.push(b);
        b.inPlay = true;
      } else {
        b.inPlay && this.arena.removeChild(b);
        b.inPlay = false;
      }
    });

    // Formerly Refresh

    this.wandtimeline.clear();
    this.buildWandTimeline();

    // Reset Timelines
    const location =
      this.PADDING + this.CIRCUMFRENCE * this.currentPuzzle.shipLocation;
    this.shiptimeline.clear();
    this.buildSpaceShipTimeline(location);

    this.timeline.clear();

    // Refresh batteries
    this.gearsInPlay.forEach((b, i) => {
      b._x = batteryX + 100 * i; // Save original position so we can reset after editing.
      gsap.set(b, {
        alpha: 1,
        scale: 1,
        x: batteryX + 100 * i,
        y: this.NUMBER_LINE_Y + 75,
      });
      gsap.set([b.wheel, b.texture], { alpha: 1 });
      gsap.set(b.wheel, { scale: 1 });
      gsap.set(b.wrench, { alpha: 0 });
      b.set(b.data);
    });

    if (this.currentPuzzle.mode == "mult"){
      this.gearsInEditing = [...this.gearsInPlay]
    }

    this.playShipIntro(1);
    this.arena.appendChild(this.pad)
    this.arena.appendChild(this.arrowGroup)

    this.incrementGears(this.props.initialGearCount)
    this.abandonEditing(true)
  }

  // API Hook
  public onPuzzlePlay(): void {
    this.goButtonClicked();
  }

  public onPuzzleTryAgain(): void {
    this.goButtonClicked();
  }

  public onPuzzleHelp(): void {}

  // #endregion LIFECYCLE METHODS




  // #region POINTER EVENTS


  public controlPadDown(e: PointerEvent) {

    const totalGears = this.currentPuzzle.batteries.length;
    const gearsInPlay = this.gearsInPlay.length

    const _id = gsap.getProperty(e.target, "id");


    if (_id != "backgroundrect"){
      gsap.to(e.target,{duration: 0.1,transformOrigin: "50% 50%", scale: 1.2,onComplete:()=>{gsap.to(e.target,{duration: 0.3,scale: 1,ease: "bounce"})}})
    }
    // eslint-disable-next-line complexity
    this.gearsInEditing.forEach((g) => {
      let num = g.data.numerator;
      let den = g.data.denominator;

      const absNum = Math.abs(num);
      const absDen = Math.abs(den);
      

      switch (_id) {
        case "addpiece":
          num = num < den || num === -den ? num + 1 : num;
          break;
        case "subpiece":
          num = absNum < den || num === den ? num - 1 : num;
          break;
        case "addpart":
          den = den < 12 ? den + 1 : den;
          break;
        case "subpart":
          if (den === num) {
            den = den === 1 ? 1 : den - 1;
            num = num === 1 ? 1 : num - 1;
          } else if (absNum === den) {
            den = den === 1 ? 1 : den - 1;
            num = num === 1 ? 1 : num + 1;
          } else {
            den = den > 0 ? den - 1 : den;
          }
          break;
        case "reverse":
          g.data.direction = !g.data.direction;
          num = -num;
          break;
        default:
          console.log("no gear mutation actions found");
      }

      g.data.numerator = num;
      g.data.denominator = den;

      g.set();
    });

    switch (_id) {
    case "addgear":
      totalGears > gearsInPlay ? this.incrementGears(gearsInPlay+1) : null
      break;
    case "subgear":
      1 < gearsInPlay ? this.incrementGears(gearsInPlay-1) : null
      break;
    }

  }

  public onDragStart() {
    this.abandonEditing();
  }

  public onDragEnd(e: PointerEvent) {
    this.cursorPoint(e);
    this.buildWandTimeline();
  }

  public cursorPoint(evt: PointerEvent) {
    this.point.x = evt.clientX;
    this.point.y = evt.clientY;

    const cursor = this.point.matrixTransform(
      this.arena.getScreenCTM()?.inverse()
    );
    this.shipLocation = this.getValForX(cursor.x);

    return cursor;
  }

  // #endregion POINTER EVENTS

  // #region UI MANIPULATION

  public focusGear(e: PointerEvent, i: number) {

    if (this.freeze) return

    const b = this.gearsInPlay[i];

    this.wandtimeline.kill();
    if (this.currentPuzzle.mode === "mult") {
      this.presentPad();

      gsap.set(this.curtain, { pointerEvents: "auto" });
      const gcount = this.gearsInPlay.length;

      const spreadWidth = 1.5 * this.DIAMETER * (gcount - 1);

      this.gearsInEditing.forEach((g, i) => {
        gsap.to(g, {
          scale: 2,
          transformOrigin: "50% 50%",
          x: this.CENTER_X - spreadWidth / 2 + i * 1.5 * this.DIAMETER,
          y: this.NUMBER_LINE_Y - 250,
        });
        this.arena.appendChild(g);
      });

      gsap.to(this.curtain, { alpha: 0.5 });
    } else if (!b.editing && b.data.editable) {
      this.presentPad();
      gsap.set(this.curtain, { pointerEvents: "auto" });
      gsap.to(b, {
        scale: 2,
        transformOrigin: "50% 50%",
        x: 640,
        y: this.NUMBER_LINE_Y - 250,
      });
      b.editing = true;
      this.gearsInEditing = [b];
      gsap.to(this.curtain, { alpha: 0.5 });
    } else if (!b.data.editable) {
      gsap.fromTo(b, {
        rotation: -60,
      },{
        duration: 0.5,
        rotation: 0,
        ease: "elastic",
      });
    }
  }

  public drawTicks() {
    const step = this.CIRCUMFRENCE / this.currentPuzzle.numberLineDenominator;
    this.ticks.forEach((t, i) => {
      gsap.set(t, { transformOrigin: "50% 0%" });

      if (i > this.currentPuzzle.numberLineDenominator * 3) {
        gsap.set(t, { alpha: 0, scaleX: 1, scaleY: 1 });
      } else if (i % this.currentPuzzle.numberLineDenominator === 0) {
        gsap.set(t, {
          alpha: 1,
          scaleX: (19 - this.currentPuzzle.numberLineDenominator) / 12,
          stroke: this.STROKE_MAJOR_TICKS,
          scaleY: 1.1,
          x: this.PADDING + i * step,
          y: this.NUMBER_LINE_Y+3,
        });
      } else {
        gsap.set(t, {
          alpha: 1,
          scaleX: (18 - this.currentPuzzle.numberLineDenominator) / 12,
          scaleY: 0.6,
          stroke: this.STROKE_TICKS,
          x: this.PADDING + i * step,
          y: this.NUMBER_LINE_Y+3,
        });
      }
      this.arena.appendChild(t);
    });
  }

  public presentPad(animated: boolean = true) {
    let _w = this.pad.getBBox().width;
    let _x = this.CENTER_X - _w / 2;
    let _y = 420;
    if (animated) {
      gsap.to(this.pad, { duration: 0.5, x: _x, y: _y });
    } else {
      gsap.set(this.pad, { duration: 0.5, x: _x, y: _y });
    }
  }

  public hidePad(animated: boolean = true) {
    let _w = this.pad.getBBox().width;
    let _x = this.CENTER_X - _w / 2;
    let _y = this.NUMBER_LINE_Y + 400;

    if (animated) {
      gsap.to(this.pad, { duration: 0.5, x: _x, y: _y });
    } else {
      gsap.set(this.pad, { duration: 0.5, x: _x, y: _y });
    }
  }

  public incrementGears(to: number) {


    to = to === null ? this.currentPuzzle.batteries.length : to


    let w = 1.5 * this.DIAMETER * (to - 1);
    let sw = this.DIAMETER * (to - 1);

    this.gearsInPlay = [];

    this.gearPool.forEach((g, i) => {
      if (i < to) {
        g.inPlay = true;
        this.gearsInPlay.push(g); // Need for feedback timeline (maybe we should just do this before the timline builds
        gsap.set(g, {
          alpha: 1,
          pointerEvents: "auto",
          scale: 2,
          y: this.NUMBER_LINE_Y - 250,
          x: this.CENTER_X - w / 2 + i * 1.5 * this.DIAMETER,
        });
        g._x = this.CENTER_X - sw / 2 + i * this.DIAMETER;
      } else {
        g.inPlay = false;
        gsap.set(g, {
          alpha: 0,
          pointerEvents: "none",
          scale: 2,
          y: this.NUMBER_LINE_Y - 250,
          x: this.CENTER_X - w / 2 + i * 1.5 * this.DIAMETER,
        });
      }
    });
  }

  // #endregion UI MANIPULATION

  // #region TIMELINES

  public onWandComplete() {
    gsap.set(this.curtain, { pointerEvents: "auto" });
  }

  public buildArrowTimeline(){
        // Building the arrow timeline. Move this to a build timeline fucntion if you could please. 
        let h = 48
        let w = 40
        let hintAt = this.props.hint
    
        gsap.set(this.arrowGroup,{x: this.PADDING+this.CIRCUMFRENCE*hintAt-w/2,y:this.NUMBER_LINE_Y-3*h})
        gsap.set(this.arrow,{alpha: 0,x: 0,y: 0,transformOrigin: "50% 100%"})
        this.arrowtimeline.to(this.arrow,{duration: 0.5,alpha:1,ease: Linear.easeNone})
        this.arrowtimeline.to(this.arrow,{duration: 2,y: 2*h,ease: "bounce"},"<")
        this.arrowtimeline.to(this.arrow,{duration: 0.2,scaleX: 1.2,scaleY: 0.75,transformOrigin: "50% 100%"},"-=1.4")
        this.arrowtimeline.to(this.arrow,{duration: 1,scaleY: 1,scaleX: 1,ease: "elastic"},"-=1.2")
        this.arrowtimeline.to(this.arrow,{alpha: 0,duration: 0.5})
  }

  public buildWandTimeline() {
    this.arena.appendChild(this.shipGroup);
    const editableBatteries = this.gearsInPlay.filter(
      (e) => e.data.editable && this.arena.appendChild(e)
    );
    if (editableBatteries.length != 0) {
      this.wandtimeline.clear();
      this.wandtimeline.to(this.curtain, { alpha: 0.5, duration: 0.5 });
      this.wandtimeline.to(
        editableBatteries,
        { duration: 0.3, scaleX: 0.7,
          scaleY: 1.2,},
        "<"
      );
      this.wandtimeline.to(editableBatteries, {
        duration: 0.1,
        scaleX: 1.3,
        scaleY: 0.7,
      });
      this.wandtimeline.to(editableBatteries, {
        duration: 0.5,
        ease: "elastic",
        scale: 1,
      });
      this.wandtimeline.pause();
    } else if (this.currentPuzzle.input == "ship") {
      gsap.set(this.curtain, { pointerEvents: "none" });
      this.wandtimeline.clear();
      this.wandtimeline.to(this.curtain, { alpha: 0.5, duration: 0.2 });
      this.wandtimeline.to(
        this.shipGroup,
        { duration: 0.1, ease: Linear.easeInOut, x: "+=10" },
        "<"
      );
      this.wandtimeline.to(
        this.shipGroup,
        { duration: 0.2, ease: Linear.easeInOut, x: "-=40" },
      );
      this.wandtimeline.to(this.shipGroup, {
        duration: 0.4,
        ease: "elastic.out(2,0.5)",
        x: "+=30",
      });

      this.wandtimeline.pause();
    }
  }

  public playShipIntro(pause: number) {
    setTimeout(() => {
      this.shiptimeline.play();
    }, pause * 1000);
  }

  public buildTimeline() {
    // Convenience Constants:
    const bs = this.BATTERY_SCALE;
    const bc = this.BATTERY_RADIUS * 2 * Math.PI;
    const ts = this.currentPuzzle.timeStep;

    let head = this.PADDING;

    const sizeOfFullRotation = this.currentBot.diameter * Math.PI;

    this.gearsInPlay.forEach((b, i) => {
      // Testing Functions

      this.arena.appendChild(b);

      // MOOO this data might change (fixed)
      const bData = b.data;
      const { numerator, denominator } = bData;
      const frac = numerator / denominator;
      const currentRotation = Number(gsap.getProperty(b.wheel, "rotation"));

      const gearY = this.NUMBER_LINE_Y - this.currentBot.diameter / 2;

      this.timeline.to(b, { duration: ts / 3, x: head, y: gearY });
      this.timeline.to(b, { duration: ts / 4, scale: bs });

      this.timeline.to(b.texture, { scale: 0.35, duration: ts / 4 }, "<");

      // Adjusted time step so small fractions go at the fsame rate as large ones.
      const adjts = Math.abs(frac * ts* Math.sqrt(this.currentBot.fraction));

      this.timeline.to(b, {
        duration: adjts,
        ease: Linear.easeNone,
        x: head + frac * sizeOfFullRotation,
      });
      this.timeline.to(
        this.robot,
        {
          duration: adjts,
          ease: Linear.easeNone,
          x:
            head +
            this.currentBot.offset +
            frac * sizeOfFullRotation -
            this.PADDING,
        },
        "<"
      );
      this.timeline.to(
        [b.wheel, b.texture],
        {
          duration: adjts,
          ease: Linear.easeNone,
          rotation: currentRotation + frac * 360,
        },
        "<"
      );
      head = head + frac * sizeOfFullRotation;

      this.timeline.to(
        b.ribbon,
        { duration: adjts, ease: Linear.easeNone, strokeDashoffset: bc },
        "<"
      );

      this.timeline.to(b.wheel, { alpha: 0 });
      this.timeline.to(b.texture, { duration: 0.1, alpha: 0, scale: 1 }, "<");
    });
  }

  public buildSpaceShipTimeline(to: number) {
    const w = this.currentPuzzle.shipWidth * this.CIRCUMFRENCE;
    const s = w / this.beamFrame.width;

    this.shiptimeline.clear();
    gsap.set(this.shipGroup, { scale: 1, x: 0, y: -this.shipFrame.height });
    gsap.set(this.beam, {
      scaleX: s,
      scaleY: 0,
      x: this.shipFrame.width / 2 - w / 2,
      y: this.shipFrame.height,
    });
    this.shiptimeline.to(this.shipGroup, { duration: 1, y: 0 });
    this.shiptimeline.to(this.shipGroup, {
      duration: 1.5,
      ease: "elastic",
      x: to - this.shipFrame.width / 2,
    });
    this.shiptimeline.to(this.beam, { duration: 1, scaleY: 1 });
    this.shiptimeline.to(
      this.shipGroup,
      { duration: 1, y: -1.05 * this.shipFrame.height },
      "<"
    );
  }

  // #endregion TIMELINES

  // #region HELPERS

  public gearSum() {
    let t = 0;
    this.gearsInPlay.forEach((g) => {
      if (g.inPlay) {
        let v = g.data.numerator / g.data.denominator;
        t = t + v;
      }
    });
    return t;
  }

  public getValForX(x: number) {
    return (x - this.PADDING) / this.CIRCUMFRENCE;
  }

  // #endregion

  // #region POINTER EVENTS

  public onGroundDown(e: PointerEvent) {
    const sw = this.shipFrame.width;
    if (this.currentPuzzle.input === "ship") {
      this.buildWandTimeline();
      const _x = this.cursorPoint(e).x;
      this.shipLocation = this.getValForX(_x);
      gsap.set(this.shipGroup, { x: _x - sw / 2 });
    }
  }

  public onBackgroundDown(e: PointerEvent) {
    if (this.freeze) return 
    this.wandtimeline.restart();
  }

  public goButtonClicked() {
    this.abandonEditing(true);

    if (this.feedbackEnded) {
      this.currentPuzzle = JSON.parse(JSON.stringify(this.props));
      this.loadPuzzle(this.currentPuzzle);
      this.feedbackEnded = false;
      this.playShipIntro(1.5);
      this.setInteractivity(false);
    } else {
      this.arena.appendChild(this.robot);
      //gsap.set(this.arena, { pointerEvents: 'none' });
      this.setInteractivity(true);
      this.feedbackPlay();
    }
  }

  // #endregion

  public init() {
    // ------- CONSTANTS ----------

    // MOO you might not need this anymore since it's done in the layout batteries funciton
    const numOfBatteries = this.currentPuzzle.batteries.length;
    const batteryLength = 100 * (numOfBatteries - 1); // why 100?
    const batteryX = 1280 / 2 - batteryLength / 2;

    // ------- CREATE ----------

    // Radio Circle
    this.radioCircle = document.createElementNS(svgns, "circle");

    // Gears
    const dum = this.currentPuzzle.batteries[0]; // Initiatlize pool with dummy gear.
    for (let i = 0; i < 10; i++) {
      const g = this.getGear(dum);
      gsap.set(g, { transformOrigin: "50% 50%" });
      this.gearPool.push(g);
      g.addEventListener("pointerdown", (e) => this.focusGear(e, i));
    }


    // Gears (Formerly Battery)
    this.currentPuzzle.batteries.forEach((d, i) => {
      const b = this.gearPool[i];
      b.set(d);
      gsap.set(b, {
        transformOrigin: "50% 50%",
        x: batteryX + 100 * i,
        y: this.NUMBER_LINE_Y + 75,
      });
      this.gearsInPlay.push(b);
    });

  

    // Ground
    this.ground = document.createElementNS(svgns, "use");
    this.ground.setAttribute("href", "#ground");

    // Robot
    this.robot = document.createElementNS(svgns, "use");
    this.spaceShip = document.createElementNS(svgns, "use");

    // Beam
    this.beam = document.createElementNS(svgns, "use");

    // ShipGroup
    this.shipGroup = this.createShipGroup();

    // Arrow
    this.arrow = document.createElementNS(svgns, "use");
    this.arrowGroup = document.createElementNS(svgns, "g");
    this.arrow.setAttribute("href", "#arrow");
    this.arena.appendChild(this.arrowGroup)
    this.arrowGroup.appendChild(this.arrow)
    gsap.set(this.arrow,{transformOrigin: "50% 50%",pointerEvents: "none"})

    // Ticks
    this.poolTicks();

    // ------- LAYERING ----------

    // Group
    this.arena.appendChild(this.shipGroup);

    // Radio
    this.arena.appendChild(this.radioCircle);

    // Robot
    this.arena.appendChild(this.robot);

    // Ground
    this.arena.appendChild(this.ground);

    //  -------  INITIALIZE -------

    gsap.set(this.ground, { scaleY: 1.55, y: this.NUMBER_LINE_Y });
    gsap.set(this.beam, { scale: 0, transformOrigin: "50% 0%" });
    gsap.set(this.shipGroup, { y: -1.1 * this.shipFrame.height });
    gsap.set(this.radioCircle, {
      attr: { r: 4 },
      scale: 0,
      stroke: "red",
      strokeWidth: 4,
      transformOrigin: "50% 50%",
    });
    gsap.set(this.robot, { y: this.NUMBER_LINE_Y - this.currentBot.height });

    // SpaceShip and Beam
    this.spaceShip.setAttribute("href", "#spaceship");
    this.beam.setAttribute("href", "#beam");

    // Robot set
    this.robot.setAttribute("href", "#" + this.currentBot.id);

    // Also in load puzzle

    // --------- EVENTS ---------
    this.background.addEventListener(
      "pointerdown",
      this.onBackgroundDown.bind(this)
    );

    this.ground.addEventListener("pointerdown", this.onGroundDown.bind(this));

    const controlsImage = document.createElementNS(svgns, "use");
    controlsImage.setAttribute("href", "#controlsImage");

    this.curtain = document.createElementNS(svgns, "rect");
    gsap.set(this.curtain, {
      alpha: 0,
      fill: "#000000",
      height: 720,
      pointerEvents: "none",
      width: 1280,
    });

    this.curtain.addEventListener("pointerdown", () => this.abandonEditing());
    this.arena.appendChild(this.curtain);

    // ----- SETTING PROPERTIES

    this.numberLine = document.createElementNS(svgns, "line");
    gsap.set(this.numberLine, {
      attr: { x1: 0, x2: 1280, y1: 0, y2: 0 },
      pointerEvents: 'none',
      stroke: this.STROKE_NUMBER_LINE,
      strokeWidth: 6,
      y: this.NUMBER_LINE_Y+3,
    });

    gsap.set(controlsImage, { pointerEvents: "none" });
    gsap.set(this.pad, { scale: 1, x: 200, y: 1000 });

    // Layering Number Line / Ticks
    this.drawTicks();
    this.arena.appendChild(this.numberLine);

    this.gearsInPlay.forEach((b) => {
      this.arena.append(b);
    });

    this.pad = this.createPad(
      this.props.buttons,
      10
    );

    this.hidePad(false);

    // This only needs to be done once per puzzle. 
    if (this.props.hint){
      this.arena.appendChild(this.arrowGroup)
      this.buildArrowTimeline()
    } else {
      gsap.set(this.arrowGroup,{opacity: 0})
    }

    this.loadPuzzle(this.currentPuzzle);

  }
}
