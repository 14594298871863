import { Component, Input } from "@angular/core";
import { OnDynamicData, OnDynamicMount } from "ngx-dynamic-hooks";
@Component({
  selector: "kh-pane",
  templateUrl: "kh-manip-pane.component.html",
  styleUrls: ["kh-manip-pane.component.less"],
})
export class KhManipulativePaneComponent implements OnDynamicMount {
  @Input("key") public key: string = "";
  @Input("value") public value: string = "";
  @Input("image") public image: string = "";
  @Input("frame") public frame = "0 0 100 100";
  @Input("order") public order!: number;
  @Input("visibility") public visibility: string = "hidden";
  @Input("ignore-in-score") public ignoreInScore : boolean = false;

  public box = { left: 0, top: 0, width: 0, height: 0, };

  onDynamicMount(data: OnDynamicData): void {
    const [left, top, width, height] = this.frame.split(" ").map(Number);
    this.box = { left, top, width, height };
  }

}
