import { fabric } from 'fabric';
import { BehaviorSubject } from 'rxjs';

export interface IInputControlOpts {
  img?: string;
  frame: number[];
  order: number;
  id: string;
}


export class FabricWindowPane extends fabric.Image {
  public order: number;
  public id: string;

  public isLoading$ = new BehaviorSubject(false);

  public constructor(
    options: IInputControlOpts
  ) {
    super()
    const [left, top, width, height] = options.frame;

    this.setSrc(options.img ?? '', () => {
      (this as FabricWindowPane).set({
        hasBorders: false,
        hasControls: false,
        height: this.height,
        hoverCursor: 'default',
        left,
        lockMovementX: true,
        lockMovementY: true,
        top,
        width: this.width
      })
      if (this.width && this.height) {
        this.scaleY = this.scaleX = Math.min(width / this.width, height / this.height);
      }
      this.isLoading$.next(false);
    });

    this.order = options.order;
    this.id = options.id;

  }
}
