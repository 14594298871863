import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'test-shared-component',
    template: '<div>This is a test component - hot reload</div>'
  })
  export class TestSharedComponent implements OnInit {
    // @Input() public message!: string;

    public constructor() {
      console.log('constructor');
    }
    
    ngOnInit(): void {
      console.log('eggs');
    }
  }