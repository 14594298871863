import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PuzzleEventService {
  private puzzleSubmitSubject = new Subject<void>();
  private puzzlePlaySubject = new Subject<void>();
  private puzzleHelpSubject = new Subject<void>();
  private puzzleTryAgainSubject = new Subject<void>();
  private puzzleReplaySubject = new Subject<void>();
  public showNextButton = new BehaviorSubject<boolean>(false);
  public isPuzzle = new BehaviorSubject<boolean>(false);
  public isPixi = new BehaviorSubject<boolean>(false);
  public isPlaying = new BehaviorSubject<boolean>(false);
  public isDisplayingResults = new BehaviorSubject<boolean>(false);
  public isCorrect = new BehaviorSubject<boolean>(false);
  public disablePlay = new BehaviorSubject<boolean>(false);
  public awaitingPixiInitialize = new BehaviorSubject<boolean>(false);

  public puzzleSubmit$ = this.puzzleSubmitSubject.asObservable();
  public puzzlePlay$ = this.puzzlePlaySubject.asObservable();
  public puzzleHelp$ = this.puzzleHelpSubject.asObservable();
  public puzzleTryAgain$ = this.puzzleTryAgainSubject.asObservable();
  public puzzleReplay$ = this.puzzleReplaySubject.asObservable();
  public isPuzzle$ = this.isPuzzle.asObservable();
  public isSuccess$ = this.showNextButton.asObservable();
  public isPlaying$ = this.isPlaying.asObservable();
  public isDisplayingResults$ = this.isDisplayingResults.asObservable();
  public isCorrect$ = this.isCorrect.asObservable();
  public disablePlay$ = this.disablePlay.asObservable();
  public awaitingPixiInitialize$ = this.awaitingPixiInitialize.asObservable();
  public isPixi$ = this.isPixi.asObservable();

  public puzzleSubmitButtonClicked(): void {
    this.puzzleSubmitSubject.next();
  }

  public puzzlePlayButtonClicked(): void {
    this.puzzlePlaySubject.next();
  }

  public puzzleTryAgainClicked(): void {
    this.puzzleTryAgainSubject.next();
    this.isCorrect.next(false);
  }

  public puzzleReplayButtonClicked(): void {
    this.puzzleReplaySubject.next();
    this.isCorrect.next(false);
  }

  public puzzleHelpClicked(): void {
    this.puzzleHelpSubject.next();
  }
}
