import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';

export type DateType = Moment | Date | number | string;

export interface ITimeInfo {
  days: number;
  hours: number;
  minutes: number;
  seconds?: number
}

export function getDurationBetween(startDate?: DateType, endDate?: DateType): ITimeInfo {
  return mapDuration(getDate(startDate).diff(getDate(endDate)));
}

export function mapDuration(diff?: number): ITimeInfo {
  const duration = moment.duration(diff);

  return {
    days: Math.trunc(duration.asDays()),
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds()
  };
}

export function getDate(date?: DateType): Moment {
  return !!date && moment(date).isValid() ? moment(date) : moment();
}

export function fromServerDate(dateString: string): Date {
  // want to display date string regardless of their web browser locale
  return DateTime.fromISO(dateString.split("T")[0]).toJSDate();
}

//TODO: changed 
export function toServerDate(localDate: Date): string {
  // just care about the date string and not the time
  return DateTime.fromJSDate(localDate).toISODate() ?? DateTime.now.toString();
}
