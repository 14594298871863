import { KHPoint } from '../../../../../LearningWorlds/pixi-puzzle-framework/PuzzleEngine/KHPoint';
import { applyFrameToElement, MANIP_FRAME_IDLE } from '../../../../../utils/utils';
import { ScorableDragRegion } from './ScorableDragRegion';

export type DropRegionConfig = {
  parent: HTMLElement,
  frame: string,
  key: string,
  hasoutline: boolean,
  issinglemode: boolean,
  ignoreinscore?: boolean;
  defaultvalue?: string;
  shape:string;
  backgroundcolor:string;
}

export class ScorableDropRegion {
  public config: DropRegionConfig;
  public dropRegion: HTMLDivElement;
  public occupiedDragRegions: ScorableDragRegion[] = [];
  public onChangeCallback: (() => void) | undefined;

  public constructor(config: DropRegionConfig) {
    this.dropRegion = document.createElement('div');
    config.parent.appendChild(this.dropRegion);

    this.config = config;

    if (this.dropRegion) {
      applyFrameToElement(this.dropRegion, this.config.frame)
    }

    const isOval = this.config.shape?.toLocaleLowerCase() === "oval";

    this.dropRegion.style.borderRadius = isOval ? "50%" : "";
    this.dropRegion.style.backgroundColor = this.config.backgroundcolor;
    this.dropRegion.style.border = this.config.hasoutline?.toString() === "true" ? MANIP_FRAME_IDLE : 'none';   
    
    // if it's not an oval but has an outline, we want to round the corners a bit
    if (!isOval && this.config.hasoutline) {
      this.dropRegion.style.borderRadius = '5%';
    }
  }

  public addOccupiedRegion(region: ScorableDragRegion): void {
    this.occupiedDragRegions.push(region);

    if (this.onChangeCallback) {
      this.onChangeCallback();
    }
  }

  public removeOccupiedRegion(region: ScorableDragRegion): void {
    this.occupiedDragRegions = this.occupiedDragRegions.filter(r => r !== region);

    if (this.onChangeCallback) {
      this.onChangeCallback();
    }
  }

  public getRect(): DOMRect {
    return this.dropRegion.getBoundingClientRect();
  }

  public getValueKeypair(): { key: string, value: string } {
    let value = '';

    if (this.occupiedDragRegions.length === 1) {
      value = this.occupiedDragRegions[0].config.key;
    } else {
      value = this.occupiedDragRegions.map(r => r.config.key).join(',');
    }

    if (!value && this.config.defaultvalue) {
      value = this.config.defaultvalue ? this.config.defaultvalue : '';
    }

    return {
      key: this.config.key,
      value: value
    };
  }

  public getManipulativePosition(): KHPoint {
    return {
      x: parseInt(this.dropRegion.style.left, 10),
      y: parseInt(this.dropRegion.style.top, 10)
    }
  }

  public getCenterPosition(): KHPoint {
    const halfWidth = parseInt(this.dropRegion.style.width) / 2;
    const halfHeight = parseInt(this.dropRegion.style.height) / 2;

    return {
      x: parseInt(this.dropRegion.style.left, 10) + halfWidth,
      y: parseInt(this.dropRegion.style.top, 10) + halfHeight
    }
  }

  public isValidDrop(): boolean {
    if (this.config.issinglemode && this.occupiedDragRegions.length >= 1) {
      return false;
    }

    return true;
  }

  public isSingle(): boolean {
    return this.config.issinglemode;
  }

  public setIsHoverTarget(isHovering: boolean): void {
    if (this.config.hasoutline) {
      this.dropRegion.style.border = isHovering ? '3px solid #0fade1' : MANIP_FRAME_IDLE;
    }
  }
}
