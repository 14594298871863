import { fabric } from 'fabric';
import { fontLoader } from './fabric-utils';

export interface IInputTextOpts extends Partial<fabric.Textbox>{
  backgroundColor?: string;
  fontFamily?: string;
  strokeColor?: string;
  strokeWidth?: number;
  frame: number[];
  fontSize?: number;
}


const RECT_DEFAULTS = {
  fill: '#000',
  hasBorders: false,
  hasControls: false,
  originX: 'left',
  originY: 'top',
  selectable: false
}

const TEXT_DEFAULTS = {
  hasBorders: false,
  hasControls: false,
  selectable: false,
  originX: 'left',
  originY: 'top'
};

export class FabricManipText extends fabric.Group {
  public fabricText: fabric.Text;
  private fabricRect: fabric.Rect;
  public activeDraggables: { name: string }[];

  public constructor(
    text: string,
    options: IInputTextOpts
  ) {

    const [left, top, width, height] = options.frame;

    const rectOptions = {...RECT_DEFAULTS,
      fill: options.backgroundColor ?? RECT_DEFAULTS.fill,
      height,
      left: 0,
      stroke: options.strokeColor,
      strokeWidth: options.strokeWidth,
      top: 0,
      width
    }

    const textOptions = {
      ...TEXT_DEFAULTS,
      breakWords: true,
      fill: '#000',
      fontSize: options.fontSize,
      height,
      width,
      left: options.strokeWidth ?? 0,
      top: options.strokeWidth ?? 0
    }
    const fabricRect = new fabric.Rect(rectOptions);
    const fabricText = new fabric.Text(text, textOptions);
    super([fabricRect, fabricText], {height, left, top, width});
    this.activeDraggables = [];
    this.fabricText = fabricText;
    this.fabricRect = fabricRect;
    this.hasBorders = false;
    this.hasControls = false;
    this.lockMovementX = true;
    this.lockMovementY = true;
    this.hoverCursor = 'default';
    this.subTargetCheck = true;
    if(this.fabricText.fontSize){
      this.fabricText.set('left', 0);
      this.fabricText.set('top', this.fabricText.fontSize * 0.03);
      this.fabricText.set({
        originX: 'center',
        originY: 'center',
      })
    }
    
    fontLoader(options.fontFamily).then(() => {
      this.fabricText.set('fontFamily', options.fontFamily);
    });
    this.addWithUpdate();
  }
}
